/**
 * @flow
 * @relayHash 9f7a42dcb4f94f7c2b2d6f8dabb2d812
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type deletePCLogMutationVariables = {|
  inputid: string
|};
export type deletePCLogMutationResponse = {|
  +deletePCLog: ?string
|};
export type deletePCLogMutation = {|
  variables: deletePCLogMutationVariables,
  response: deletePCLogMutationResponse,
|};
*/


/*
mutation deletePCLogMutation(
  $inputid: String!
) {
  deletePCLog(id: $inputid)
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "inputid"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "inputid"
      }
    ],
    "kind": "ScalarField",
    "name": "deletePCLog",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "deletePCLogMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "deletePCLogMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "9f7a42dcb4f94f7c2b2d6f8dabb2d812",
    "metadata": {},
    "name": "deletePCLogMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '168d8b2fef0e00095873f761132872cd';

module.exports = node;
