import React from "react";
import { createRefetchContainer } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { withSnackbar } from "material-ui-snackbar-provider";
import compose from "recompose/compose";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import CloseIcon from "@material-ui/icons/Close";
import SettingsIcon from "@material-ui/icons/Settings";
import EditIcon from "@material-ui/icons/Edit";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import DeleteIcon from "@material-ui/icons/Delete";
import ClearIcon from "@material-ui/icons/Clear";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import Fade from "@material-ui/core/Fade";

import SM from "../subscriptions/index";
import AppDetails from "./deviceControl/AppDetails";
import Options from "./deviceControl/Options";
import Logs from "./deviceControl/Logs";
import NowPlaying from "./deviceControl/NowPlaying";
import SystemDetailsControls from "./deviceControl/SystemDetailsControls";
import User from "./deviceControl/User";
import Tag from "./deviceControl/Tag";
import permissions2 from "../authentication/UIPermissions";
import updateAudioDeviceNameMutation from "../mutations/updateAudioDeviceNameMutation";
import Draggable from "react-draggable";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import deleteAudioDeviceMutation from "../mutations/deleteAudioDeviceMutation";

const styles = (theme) => ({
  root: theme.mixins.gutters({
    padding: "0px 8px 8px 8px",
    margin: "8px 8px 8px 8px",
    flex: "auto",
    minWidth: "30vw",
  }),
  horizontalContainer: {
    display: "flex",
    flexWrap: "nowrap",
  },
  verticalContainer: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
  },
  closeIcon: {
    height: 24,
    color: "#000000",
    width: 24,
  },
  close: {
    position: "relative",
    direction: "rtl",
    marginTop: 6,
  },
  typo1: {
    padding: 0,
  },
  typo2: {
    paddingLeft: "15px",
    color: "#000000",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  expansion: {
    marginBottom: 8,
    flex: "auto",
    boxShadow:
      "0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12)",
    "::before": { height: 0 },
  },
  expansionDetails: {
    flex: "auto",
  },
  checked: {
    color: "primary",
  },
  expansionSummary: {
    padding: "0px 24px 0px 0px",
    backgroundColor: "#7d7d7d",
  },
  expantionDiv: {
    alignItems: "center",
    display: "flex",
  },
  modal: {
    top: "45%",
  },
  paper: {
    position: "absolute",
    width: theme.spacing(50),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
  },
  dialog: {
    background: "secondary",
    margin: theme.spacing(3),
  },
  button: {
    marginRight: theme.spacing(1),
  },
  dialogTitle: {
    padding: "0px 24px 0px 24px",
    minWidth: 250,
  },
  textField: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
  },
  playerName: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});

class AudioDeviceControl extends React.Component {
  constructor(props) {
    super(props);
    const userSettings =
      props.viewer.user.consoleSettings &&
      props.viewer.user.consoleSettings.audioDevices
        ? props.viewer.user.consoleSettings.audioDevices
        : {
            nowPlaying: true,
            appDetails: false,
            logs: false,
            systemDetailsControls: false,
            user: false,
            tags: false,
          };

    this.state = {
      ...userSettings,
      changeNameModal: false,
      deviceName: "",
      deviceDescription: "",
      asked: false,
      optionPressed: false,
    };
    this.handleButton = this.handleButton.bind(this);
    this.handleCloseAsk = this.handleCloseAsk.bind(this);
    this.handleAsk = this.handleAsk.bind(this);
    this.toggleClose = this.toggleClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeName = this.handleChangeName.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.openModal = this.openModal.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
    this.enteroptionPressed = this.enteroptionPressed.bind(this);
  }

  componentDidMount() {
    const loginProcedure = async () => {
      if (SM.token) {
        this.subscription = await SM.AudioDeviceSubscription(
          SM.token,
          this.props.deviceControl.id
        );
      }
    };
    loginProcedure();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.deviceControl.id) {
      if (this.props.deviceControl.id !== nextProps.deviceControl.id) {
        const handleSub = async () => {
          this.subscription = await SM.AudioDeviceSubscription(
            SM.token,
            nextProps.deviceControl.id
          );
        };
        handleSub();
        this.setState({ asked: false });
        this.props.relay.refetch(
          {
            deviceId: {
              _id_contains: this.props.deviceControl.id,
            },
          }, // Our refetchQuery needs to know the `itemID`
          null, // We can use the refetchVariables as renderVariables
          () => {
            console.log("Refetch done");
          },
          { force: true } // Assuming we've configured a network layer cache, we want to ensure we fetch the latest data.
        );
      }
      // const refetchVariables = fragmentVariables => ({
      //   name_contains: nextProps.deviceControl.name,
      // });
      // this.props.relay.refetch(refetchVariables);
      return true;
    }
  }

  componentWillUnmount() {
    try {
      this.subscription?.dispose();
    } catch (e) {
      console.log("componentWillUnmount AudioDeviceConstrol Error:", e);
    }
  }

  handleButton() {
    this.setState({ optionPressed: !this.state.optionPressed });
  }

  handleCloseAsk() {
    this.setState({ asked: false });
  }

  handleAsk() {
    this.setState({ asked: true });
  }

  toggleClose() {
    this.props.toggleClose();
  }

  handleChange(name) {
    this.setState({ [name]: !this.state[name] });
  }

  handleChangeName(event, name) {
    this.setState({
      [name]: event.target.value,
    });
  }

  handleClose() {
    this.setState({ changeNameModal: false });
  }

  openModal() {
    this.setState({ changeNameModal: true });
    this.setState({
      deviceName: this.props.deviceControl.name,
      deviceDescription: this.props.deviceControl.description,
    });
  }

  handleConfirm() {
    updateAudioDeviceNameMutation(
      this.props.deviceControl.id,
      this.state.deviceName,
      this.state.deviceDescription,
      () => {
        this.props.snackbar.showMessage("Device name or description changed");
        this.setState({ changeNameModal: false });
      }
    );
  }

  enteroptionPressed(event) {
    const code = event.keyCode || event.which;
    if (code === 13) {
      updateAudioDeviceNameMutation(
        this.props.deviceControl.id,
        this.state.deviceName,
        this.state.deviceDescription,
        () => {
          this.props.snackbar.showMessage("Device name/description changed");
          this.setState({ changeNameModal: false });
        }
      );
    }
  }

  render() {
    const { classes, viewer, methods, deviceControl, clientId } = this.props;
    const {
      appDetails,
      logs,
      nowPlaying,
      systemDetailsControls,
      user,
      tags,
      changeNameModal,
      deviceName,
      deviceDescription,
      asked,
      optionPressed,
    } = this.state;

    const deviceNode = viewer.allAudioDevices?.edges?.find(
      (item) => item?.node?.id === deviceControl.id
    );

    const deviceQuery = deviceNode?.node;

    const currentClient = viewer.allClients?.edges?.find(
      (item) => item?.node.id === clientId
    )?.node;

    const allPlaylists = viewer.allPlaylists?.edges
      ?.filter((item) => item?.node)
      .filter((item) =>
        clientId !== "ALL"
          ? currentClient?.playlistIds?.includes(item.node.id)
          : true
      );

    const allSchedule = viewer.allSchedule?.edges
      ?.filter((item) => item?.node)
      .filter((schedule) => {
        if (schedule.node.clientId === clientId) {
          return schedule;
        }
        return false;
      });

    const allScenes = viewer.allScenes?.edges
      ?.filter((item) => item?.node)
      .filter((item) =>
        clientId !== "ALL"
          ? currentClient?.sceneIds?.includes(item.node.id)
          : true
      );

    const playlistExists = allPlaylists.find(
      (playlist) => playlist.node?.id === deviceQuery?.currentPlaylistId
    );

    const sceneExists = allScenes.find(
      (scene) => scene.node.id === deviceQuery?.currentSceneId
    );

    const selectedSchedule = allSchedule.filter((schedule) =>
      schedule.node.id
        ? deviceQuery.scheduleIds.includes(schedule.node.id)
        : false
    );

    return (
      <Draggable handle=".handle">
        <Paper
          className={classes.root}
          elevation={1}
          style={{
            background: "#c4c4c4",
            top: "150px",
            right: "90px",
            bottom: "0px",
            position: "fixed",
            overflow: "hidden scroll",
            maxWidth: "50vw",
            zIndex: 100,
          }}
        >
          <div
            className={classes.horizontalContainer}
            style={{
              position: "sticky",
              zIndex: 9999999,
              backgroundColor: "#c4c4c4",
              top: 0,
            }}
          >
            <div
              className={classes.horizontalContainer}
              style={{
                flex: "2",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <div>
                <Typography
                  className={classes.typo2}
                  variant="body1"
                  color="textPrimary"
                >
                  {deviceQuery.name}
                </Typography>
                <Typography
                  className={classes.typo2}
                  variant="body1"
                  color="textSecondary"
                >
                  {deviceQuery.description}
                </Typography>
              </div>
            </div>
            <div
              className={classes.horizontalContainer}
              style={{ flex: "1", justifyContent: "flex-end" }}
            >
              {" "}
              <div className={classes.close}>
                <div className="handle">
                  <IconButton onClick={() => ""} color="primary">
                    <DragIndicatorIcon style={{ color: "#000" }} />
                  </IconButton>
                </div>
              </div>
              <div className={classes.close}>
                <IconButton onClick={this.handleButton} color="primary">
                  <Tooltip
                    id="tooltip-bottom"
                    title="Options"
                    placement="bottom"
                    enterTouchDelay={200}
                  >
                    {!optionPressed ? (
                      <SettingsIcon
                        className={classes.closeIcon}
                        color="primary"
                      />
                    ) : (
                      <SettingsOutlinedIcon
                        className={classes.closeIcon}
                        color="primary"
                      />
                    )}
                  </Tooltip>
                </IconButton>
              </div>
              {!asked ? (
                <div className={classes.close}>
                  <IconButton color="primary" onClick={this.handleAsk}>
                    <Tooltip
                      id="tooltip-bottom"
                      title="Delete"
                      placement="bottom"
                      enterTouchDelay={200}
                    >
                      <DeleteIcon
                        color="primary"
                        className={classes.closeIcon}
                      />
                    </Tooltip>
                  </IconButton>
                </div>
              ) : (
                <Fade in={asked} timeout={250}>
                  <div className={classes.horizontalContainer}>
                    <div className={classes.close}>
                      <Typography
                        style={{
                          width: "100px",
                          paddingTop: "10px",
                          paddingLeft: "15px",
                        }}
                        className={classes.typo3}
                        variant="subtitle1"
                        color="primary"
                        align="center"
                      >
                        Are you sure?
                      </Typography>
                    </div>
                    <div className={classes.close}>
                      <Tooltip
                        id="tooltip-bottom"
                        title="Cancel Delete"
                        placement="bottom"
                        enterTouchDelay={200}
                      >
                        <IconButton
                          color="primary"
                          onClick={this.handleCloseAsk}
                        >
                          <ClearIcon
                            color="primary"
                            className={classes.closeIcon}
                          />
                        </IconButton>
                      </Tooltip>
                    </div>
                    <div className={classes.close}>
                      <Tooltip
                        id="tooltip-bottom"
                        title="Delete Player"
                        placement="bottom"
                        enterTouchDelay={200}
                      >
                        <IconButton
                          color="primary"
                          onClick={() =>
                            deleteAudioDeviceMutation(deviceQuery.id, () =>
                              console.log("deleted")
                            )
                          }
                        >
                          <DeleteIcon
                            color="primary"
                            className={classes.closeIcon}
                            style={{ color: "tomato" }}
                          />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </div>
                </Fade>
              )}
              {permissions2("5cab49de95a3bc0a8a1ae928", methods) && (
                <div className={classes.close}>
                  <IconButton onClick={this.openModal} color="primary">
                    <Tooltip
                      id="tooltip-bottom"
                      title="Change Device Name"
                      placement="bottom"
                      enterTouchDelay={200}
                    >
                      <EditIcon className={classes.closeIcon} />
                    </Tooltip>
                  </IconButton>
                </div>
              )}
              <div className={classes.close}>
                <Tooltip
                  id="tooltip-bottom"
                  title="Close"
                  placement="bottom"
                  enterTouchDelay={200}
                >
                  <IconButton
                    aria-label="Close"
                    onClick={this.toggleClose}
                    color="primary"
                  >
                    <CloseIcon className={classes.closeIcon} />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
          </div>

          {optionPressed && (
            <Options
              viewer={viewer}
              handleChange={this.handleChange}
              nowPlaying={nowPlaying}
              appDetails={appDetails}
              systemDetailsControls={systemDetailsControls}
              user={user}
              tags={tags}
              logs={logs}
              optionPressed={optionPressed}
            />
          )}
          {permissions2("5cab344a95a3bc0a8a1ae91e", methods) && appDetails && (
            <AppDetails deviceControl={deviceQuery} />
          )}
          {permissions2("5cab38e895a3bc0a8a1ae920", methods) && logs && (
            <Logs
              deviceControl={deviceQuery}
              name={deviceQuery.name}
              viewer={viewer}
              deviceId={deviceQuery.id}
            />
          )}
          {permissions2("5cab311e95a3bc0a8a1ae91b", methods) && nowPlaying && (
            <>
              <NowPlaying
                viewer={viewer}
                deviceQuery={deviceQuery}
                deviceId={deviceQuery.id}
                methods={methods}
                allPlaylists={allPlaylists}
                allSchedule={allSchedule}
                allScenes={allScenes}
                playlistExists={playlistExists}
                sceneExists={sceneExists}
                selectedSchedule={selectedSchedule}
                clientId={clientId}
              />
            </>
          )}
          {permissions2("5cab3cc595a3bc0a8a1ae922", methods) &&
            systemDetailsControls && (
              <SystemDetailsControls
                longitude={deviceQuery.longitude}
                latitude={deviceQuery.latitude}
                serialNumber={deviceQuery.serialNumber}
                lastOnline={deviceQuery.lastOnline}
                deviceId={deviceQuery.id}
                viewer={viewer}
                deviceControl={deviceQuery}
                methods={methods}
                webkeyId={deviceQuery.webkeyId || ""}
                webkeyBackupId={deviceQuery.webkeyBackupId || ""}
              />
            )}
          {permissions2("5cab3d3395a3bc0a8a1ae924", methods) && user && (
            <User
              viewer={viewer}
              deviceId={deviceQuery.id}
              clientId={deviceQuery.clientId}
              usersId={deviceQuery.usersId}
            />
          )}
          {permissions2("5cab475095a3bc0a8a1ae926", methods) && tags && (
            <Tag
              viewer={viewer}
              deviceId={deviceQuery.id}
              tagList={deviceQuery.tags}
            />
          )}
          {permissions2("5cab49de95a3bc0a8a1ae928", methods) && (
            <Dialog open={changeNameModal} className={classes.dialog}>
              <div className={classes.close}>
                <IconButton aria-label="Close" className={classes.close}>
                  <CloseIcon
                    className={classes.closeIcon}
                    onClick={this.handleClose}
                  />
                </IconButton>
              </div>
              <DialogTitle className={classes.dialogTitle}>
                Edit name for current device
              </DialogTitle>
              <div className={classes.verticalContainer}>
                <TextField
                  id="outlined-name"
                  label="Device name"
                  className={classes.textField}
                  value={deviceName}
                  onChange={(event) =>
                    this.handleChangeName(event, "deviceName")
                  }
                  onKeyPress={this.enteroptionPressed}
                  margin="normal"
                  variant="outlined"
                />
                <TextField
                  id="outlined-description"
                  label="Device description"
                  className={classes.textField}
                  value={deviceDescription}
                  onChange={(event) =>
                    this.handleChangeName(event, "deviceDescription")
                  }
                  onKeyPress={this.enteroptionPressed}
                  margin="normal"
                  variant="outlined"
                />
              </div>
              <div className={classes.dialog}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={this.handleClose}
                  className={classes.button}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.handleConfirm}
                  className={classes.button}
                >
                  Confirm Change
                </Button>
              </div>
            </Dialog>
          )}
        </Paper>
      </Draggable>
    );
  }
}

AudioDeviceControl.propTypes = {
  classes: PropTypes.object.isRequired, //eslint-disable-line
  viewer: PropTypes.object.isRequired, //eslint-disable-line
  snackbar: PropTypes.object, //eslint-disable-line
  clientId: PropTypes.string.isRequired,
  deviceIds: PropTypes.array.isRequired, //eslint-disable-line
  deviceControl: PropTypes.object, //eslint-disable-line
  toggleClose: PropTypes.func, //eslint-disable-line
  userRoot: PropTypes.object, //eslint-disable-line
};

export default compose(
  withStyles(styles),
  withSnackbar()
)(
  createRefetchContainer(
    AudioDeviceControl,
    {
      node: graphql`
        fragment AudioDeviceControl_node on AudioDeviceEdge {
          node {
            id
            isPlaying
            name
            appStatus {
              status
              message
              time
              description
            }
            lastSyncTime
            clientId
            appUpTime
            currentM3uName
            appVersion
            uid
            description
            volume
            remoteVolume
            remoteVolumeMode
            requestPCLogs
            requestNPLogs
            lastOnline
            currentPlaylistId
            currentSceneId
            currentSongId
            currentAlbum
            currentArtist
            currentArtwork
            remotePlaylistMode
            isOnline
            currentMd5
            currentSongFilename
            totalSdStorage
            usedSdStorage
            availableSdStorage
            m3uFilesOnDevice
            songFilesOnDevice
            latitude
            longitude
            skipCount
            skipDisabled
            likedSong
            scheduleIds
            usersId {
              id
              profile {
                name
                clientId
              }
              emails {
                address
              }
            }
            playlistsId
            playbackIds
            tags
            enrollmentDate
            deviceStatus
            makeAndModel
            macAddress
            ethernetMacAddress
            internalIpAddress
            publicIpAddress
            webkeyId
            webkeyBackupId
            appVersionDS
            kpi1
            kpi3
            kpi30
            crossfade
            maximumDaysOffline
            maximumSongsOffline
            serialNumber
          }
        }
      `,
    },
    graphql`
      query AudioDeviceControlRefetchQuery($deviceId: AudioDeviceFilter!) {
        viewer {
          allAudioDevices(filter: $deviceId) {
            edges {
              ...AudioDeviceControl_node
            }
          }
        }
      }
    `
  )
);
