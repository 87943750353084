/**
 * @flow
 * @relayHash 7df0b462f7e286d95d0fba774fd5b12c
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type _ModelMutationType = "CREATED" | "DELETED" | "UPDATED" | "UPDATEDCLIENT" | "%future added value";
export type VideoProfileSubscriptionVariables = {||};
export type VideoProfileSubscriptionResponse = {|
  +VideoProfileSubscription: {|
    +mutation: _ModelMutationType,
    +node: {|
      +id: string,
      +name: string,
      +format: string,
      +volume: ?number,
      +url: ?string,
      +syncTime: ?string,
      +rebootTime: ?string,
      +clientId: ?string,
    |},
  |}
|};
export type VideoProfileSubscription = {|
  variables: VideoProfileSubscriptionVariables,
  response: VideoProfileSubscriptionResponse,
|};
*/


/*
subscription VideoProfileSubscription {
  VideoProfileSubscription {
    mutation
    node {
      id
      name
      format
      volume
      url
      syncTime
      rebootTime
      clientId
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "VideoProfileSubscriptionPayload",
    "kind": "LinkedField",
    "name": "VideoProfileSubscription",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "mutation",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "VideoProfile",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "format",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "volume",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "url",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "syncTime",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "rebootTime",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "clientId",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "VideoProfileSubscription",
    "selections": (v0/*: any*/),
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "VideoProfileSubscription",
    "selections": (v0/*: any*/)
  },
  "params": {
    "id": "7df0b462f7e286d95d0fba774fd5b12c",
    "metadata": {},
    "name": "VideoProfileSubscription",
    "operationKind": "subscription",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ff93b54a1e76097b137e3341fd1de298';

module.exports = node;
