/**
 * External dependencies
 */
import React, { useState, useContext } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  List,
  TextField,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";

/**
 * Internal dependencies
 */

import MenuModal from "../menuColumn/MenuModal";
import RouteListNone from "./RouteListNone";
import RouteListCategory from "./RouteListCategory";
import RouteListSubCategory from "./RouteListSubCategory";
import RouteListGenres from "./RouteListGenres";
import { CurrentClientContext } from "../../hooks/currentClientProvider";
import { CurrentMenuContext } from "../../hooks/currentMenuProvider";

const styles = () => ({
  root: {
    height: "calc(100vh - 64px)",
  },
  list: {
    width: "30%",
    height: "100%",
    minWidth: 300,
    padding: 4,
  },
  menusView: {
    height: "calc(100% - 64px)",
    overflowY: "scroll",
    padding: "0px 8px 0px 8px",
    marginTop: 2,
    borderWidth: 2,
    borderStyle: "solid",
    borderColor: "lightgrey",
    borderRadius: 16,
  },
  textField: {
    width: "100%",
  },
  typo: {
    textAlign: "center",
    margin: 24,
  },
  typo2: {
    margin: 8,
  },
});

const RouteColumn = ({ classes, currentRoute, disabled }) => {
  const { playableList } = useContext(CurrentClientContext);
  const [selection, setSelection] = React.useState("playlist");
  const [searchValue, setSearchValue] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const { currentMenu, currentMenuExtension } = useContext(CurrentMenuContext);

  const explicit =
    currentMenuExtension?.explicit ?? currentMenu?.explicit ?? true;

  const handleChange = (event) => {
    setSearchValue(event.target.value);
  };

  const handleSelect = (event) => {
    setSelection(event.target.value);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const body = (autopopulate) => {
    switch (autopopulate) {
      case "NONE":
        return (
          <>
            <FormControl component="fieldset">
              <RadioGroup
                row
                aria-label="playables"
                name="playables"
                value={selection}
                onChange={handleSelect}
              >
                <FormControlLabel
                  value="playlist"
                  control={<Radio />}
                  label="Playlist"
                />
                <FormControlLabel
                  value="scene"
                  control={<Radio />}
                  label="Scene"
                />
                <FormControlLabel value="all" control={<Radio />} label="All" />
              </RadioGroup>
            </FormControl>
            {playableList(selection, explicit) && (
              <RouteListNone
                playableList={playableList(selection, explicit)}
                searchValue={searchValue}
                route={currentRoute}
              />
            )}
          </>
        );
      case "CATEGORY":
        return (
          <>
            <Typography
              className={classes.typo2}
              variant="subtitle1"
              gutterBottom
            >
              Select by category:
            </Typography>
            <RouteListCategory
              playableList={playableList("scene", explicit) || []}
              searchValue={searchValue}
              route={currentRoute}
            />
          </>
        );
      case "SUBCATEGORY":
        return (
          <>
            <Typography
              className={classes.typo2}
              variant="subtitle1"
              gutterBottom
            >
              Select by sub-category:
            </Typography>
            {playableList("scene", explicit) && (
              <RouteListSubCategory
                playableList={playableList("scene", explicit) || []}
                searchValue={searchValue}
                route={currentRoute}
              />
            )}
          </>
        );
      case "GENRE": {
        let playableType = "all";
        switch (currentRoute.playableType) {
          case "PLAYLISTS":
            playableType = "playlist";
            break;
          case "SCENES":
            playableType = "scene";
            break;
          default:
            playableType = "all";
        }
        const playables = playableList(playableType, explicit).filter(
          (item) => {
            const cat = item.newCategory || item.category || [];
            return !cat
              ?.map((item2) => item2.category.toUpperCase())
              .includes("CUSTOM");
          }
        );
        return (
          <>
            <Typography
              className={classes.typo2}
              variant="subtitle1"
              gutterBottom
            >
              Select by genre:
            </Typography>
            {playableList(playableType, explicit) && (
              <RouteListGenres
                playableList={playables}
                searchValue={searchValue}
                route={currentRoute}
              />
            )}
          </>
        );
      }
      default:
        return (
          <Typography className={classes.typo} variant="h6" gutterBottom>
            Unavailable view for this type of path.
          </Typography>
        );
    }
  };

  return (
    <div className={classes.list}>
      <TextField
        className={classes.textField}
        id="search-menu"
        label="Search"
        value={searchValue}
        onChange={handleChange}
      />
      <List component="nav" className={classes.menusView} aria-label="contacts">
        {disabled ? (
          <Typography
            className={classes.typo}
            variant="h6"
            gutterBottom
            style={{ color: "#737373" }}
          >
            Cannot edit basic menu
          </Typography>
        ) : (
          body(currentRoute?.autoPopulateType)
        )}
      </List>
      <MenuModal openModal={openModal} callbackModal={handleClose} />
    </div>
  );
};

export default withStyles(styles)(RouteColumn);
