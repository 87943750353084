/**
 * @flow
 * @relayHash 87eda9208cc4f0c3035bcbd76bcb1649
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type deleteAllEventsMutationVariables = {||};
export type deleteAllEventsMutationResponse = {|
  +deleteAllEvents: ?{|
    +id: string
  |}
|};
export type deleteAllEventsMutation = {|
  variables: deleteAllEventsMutationVariables,
  response: deleteAllEventsMutationResponse,
|};
*/


/*
mutation deleteAllEventsMutation {
  deleteAllEvents {
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Event",
    "kind": "LinkedField",
    "name": "deleteAllEvents",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "deleteAllEventsMutation",
    "selections": (v0/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "deleteAllEventsMutation",
    "selections": (v0/*: any*/)
  },
  "params": {
    "id": "87eda9208cc4f0c3035bcbd76bcb1649",
    "metadata": {},
    "name": "deleteAllEventsMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f6843aca95132d80c8a331545a0ccbb9';

module.exports = node;
