import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import compose from "recompose/compose";
import { withSnackbar } from "material-ui-snackbar-provider";
import moment from "moment";
import IconButton from "@material-ui/core/IconButton";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import PlaylistInfoButton from "./components/playlistInfoButton2";
import togglePlaylistToClientMutation from "../mutations/togglePlaylistToClientMutation";
import toggleFeaturedToClientMutation from "../mutations/toggleFeaturedToClientMutation";
import syncPlaylistMutation from "../mutations/syncPlaylistMutation";
import permissions from "../authentication/isPermited";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Switch from "@material-ui/core/Switch";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import InfoIcon from "@material-ui/icons/Info";
import StarIcon from "@material-ui/icons/Star";
import SyncIcon from "@material-ui/icons/Sync";
import { checkPlaylistsBeforeRemove } from "./utils/checkPlaylist";
import PlaylistToggleModal from "./components/playlistToggleModal";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(1),
    minWidth: 170,
    width: 200,
    maxWidth: 230,
  },
  card: {
    maxWidth: 200,
  },
  media: {
    height: 180,
    padding: 10,
  },
  expand: {
    transform: "rotate(0deg)",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: "#387f86",
  },
  flexGrow: {
    flex: "1 1 auto",
  },
  playIcon: {
    height: 28,
    width: 28,
  },
  typo: {
    marginBottom: 8,
  },
  header: {
    height: 60,
    padding: 10,
  },
  label1: {
    margin: 2,
    opacity: 0.5,
  },
  label2: {
    margin: 2,
  },
  labelsTop: {
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    margin: 4,
    alignItems: "flex-start",
  },
  labelsBottom: {
    position: "absolute",
    margin: "150px 0px 0px 150px",
    opacity: 0.6,
  },
  verticalContainer: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    flex: "auto",
  },
  cardActions: {
    padding: 5,
  },
  info: {
    backgroundColor: "#387f86",
  },
  button: {
    marginRight: 0,
  },
});

class PlaylistListView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
      imageHash: Date.now(),
      count: 1,
      verifyModal: false,
      playlistsArray: [],
    };
    this.handleExpandClick = this.handleExpandClick.bind(this);
    this.myCallBack = this.myCallBack.bind(this);
    this.togglePlaylist = this.togglePlaylist.bind(this);
    this.toggleFeatured = this.toggleFeatured.bind(this);
    this.toggleSync = this.toggleSync.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.verifyPlaylist = this.verifyPlaylist.bind(this);
  }

  handleExpandClick() {
    this.setState({ expanded: !this.state.expanded });
  }

  myCallBack(data) {
    this.setState({
      data,
    });
  }

  handleClose() {
    this.setState((state) => ({ verifyModal: !state.verifyModal }));
  }

  verifyPlaylist() {
    const { allPlaybacks, allSchedule, client } = this.props;
    const playlistsArray = checkPlaylistsBeforeRemove(
      [this.props.node],
      allPlaybacks,
      allSchedule,
      client?.id
    );
    this.setState({ playlistsArray: playlistsArray });
    if (playlistsArray.length === 0) {
      this.togglePlaylist();
    } else {
      this.setState((state) => ({ verifyModal: !state.verifyModal }));
    }
  }

  togglePlaylist() {
    const { checked } = this.props;
    const message = checked ? "Playlist removed" : "Playlist added";
    togglePlaylistToClientMutation(
      [this.props.node.id],
      checked,
      this.props.client.id,
      () => this.props.snackbar.showMessage(message)
    );
    this.setState((state) => ({ count: state.count + 1 }));
  }

  toggleFeatured(checkedFeatured) {
    const message = checkedFeatured
      ? "Featured Playlist removed."
      : "This Playlist is now featured.";
    toggleFeaturedToClientMutation(
      [this.props.node.id],
      checkedFeatured,
      this.props.client.id,
      "playlist",
      () => this.props.snackbar.showMessage(message)
    );

    this.setState((state) => ({ count: state.count + 1 }));
  }

  toggleSync() {
    syncPlaylistMutation(this.props.node?.id, () =>
      this.props.snackbar.showMessage("Playlist sync started")
    );
  }

  render() {
    const {
      classes,
      node,
      checked,
      featuredChecked,
      viewer,
      allPlaybacks,
      allSchedule,
      allAudioClients,
      allAudioDevices,
    } = this.props;
    const { imageHash, verifyModal, playlistsArray } = this.state;
    const defaultImage =
      "https://artwork.activaire.com/playlists/playlist_default.jpg";

    if (node) {
      const coverImage =
        node.cover === "" ? defaultImage : `${node.cover}?${imageHash}`;
      return (
        <ListItem key={node.id}>
          <ListItemAvatar>
            <Avatar
              alt="Remy Sharp"
              src={coverImage.replace("artwork", "thumbnails")}
            />
          </ListItemAvatar>
          <ListItemText
            id="switch-list-label-wifi"
            primary={
              <div
                style={{ dispaly: "flex", flexDirection: "row", fontSize: 16 }}
              >
                <span style={{ fontWeight: "bold" }}>{node.name}</span> ◦{" "}
                <span style={{ color: "grey", fontSize: 14 }}>
                  Last update: {new Date(node.updatedAt).toDateString()}{" "}
                </span>
                {node.isRemoved && (
                  <span style={{ color: "red", fontSize: 14 }}>
                    {"◦ S3 DELETED"}
                  </span>
                )}
              </div>
            }
          />
          <ListItemSecondaryAction>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                width: 220,
                justifyContent: "space-around",
              }}
            >
              <Tooltip title="Toggle featured playlist">
                <span>
                  <IconButton
                    aria-label="featured"
                    disabled={!checked}
                    style={{ padding: 0 }}
                    onClick={() =>
                      checked && this.toggleFeatured(featuredChecked)
                    }
                  >
                    <StarIcon
                      fontSize="inherit"
                      style={{ color: featuredChecked ? "#387f86" : "#aaaaaa" }}
                    />
                  </IconButton>
                </span>
              </Tooltip>
              {permissions([], [1]) && (
                <Tooltip title="Sync playlist with devices">
                  <span>
                    <IconButton
                      aria-label="sync"
                      disabled={!checked}
                      style={{ padding: 0 }}
                      onClick={() => checked && this.toggleSync()}
                    >
                      <SyncIcon
                        fontSize="inherit"
                        style={{ color: "#387f86" }}
                      />
                    </IconButton>
                  </span>
                </Tooltip>
              )}
              <Tooltip
                title={
                  <div>
                    <Typography paragraph className={classes.typo}>
                      <b>Last update: </b>
                      {moment(node.updatedAt).format("LLL")}
                    </Typography>
                    <Typography paragraph className={classes.typo}>
                      <b>Size: </b>
                      {(node.size / 1000000000).toFixed(2)} GB
                    </Typography>
                    <Typography paragraph className={classes.typo}>
                      <b>Total tracks: </b>
                      {node.tracks}
                    </Typography>
                    <Typography paragraph className={classes.typo}>
                      <b>Live tracks: </b>
                      {node.files.filter((item2) => item2.status === 1).length}
                    </Typography>
                  </div>
                }
              >
                <InfoIcon
                  style={{ color: "#757575", marginLeft: 8, marginRight: 8 }}
                />
              </Tooltip>
              {permissions([], [1]) && (
                <PlaylistInfoButton
                  style={{ marginLeft: 8, marginRight: 8 }}
                  usedInListView
                  viewer={viewer}
                  playlistId={node.id}
                  updatedAt={node.updatedAt}
                  allPlaybacks={allPlaybacks}
                  allSchedule={allSchedule}
                  allAudioClients={allAudioClients}
                  allAudioDevices={allAudioDevices}
                />
              )}
              <Switch
                edge="end"
                onChange={() => this.verifyPlaylist()}
                checked={checked}
                inputProps={{ "aria-labelledby": "switch-list-label-wifi" }}
              />
              {playlistsArray.length > 0 && (
                <PlaylistToggleModal
                  open={verifyModal}
                  callback={() => this.togglePlaylist()}
                  handleClose={() => this.handleClose()}
                  playlistsArray={playlistsArray}
                />
              )}
            </div>
          </ListItemSecondaryAction>
        </ListItem>
      );
    }
    return null;
  }
}

PlaylistListView.propTypes = {
  classes: PropTypes.object.isRequired,
  viewer: PropTypes.object,
  node: PropTypes.object,
  snackbar: PropTypes.object,
  client: PropTypes.object,
  checked: PropTypes.bool,
  featuredChecked: PropTypes.bool,
  userRoot: PropTypes.object,
  allPlaybacks: PropTypes.array,
  allSchedule: PropTypes.array,
  allAudioClients: PropTypes.array,
  allAudioDevices: PropTypes.array,
};

export default compose(withStyles(styles), withSnackbar())(PlaylistListView);
