import React from "react";
import PropTypes from "prop-types";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
// import _ from 'lodash';

import { withStyles } from "@material-ui/core/styles";
import compose from "recompose/compose";
import { withSnackbar } from "material-ui-snackbar-provider";

import deleteDevicePackageMutation from "../mutations/deleteDevicePackageMutation";

const styles = (theme) => ({
  root: {},
  horizontalContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  verticalContainer: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  button: {
    width: "fit-content",
    margin: "8px 0px 8px 8px",
  },
  typo: {
    width: "fit-content",
    margin: 8,
  },
});

class BatchInstallModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      searchField: "",
      selectedPackage: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.selectItem = this.selectItem.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.installOrUnistallPackage = this.installOrUnistallPackage.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state !== nextState) {
      return true;
    }
    return false;
  }

  handleChange(name, event) {
    this.setState({ [name]: event.target.value.toLowerCase() });
  }

  selectItem(item) {
    this.setState({
      open: true,
      selectedPackage: item,
    });
  }

  handleClose() {
    this.setState({ open: false });
  }

  installOrUnistallPackage(item) {
    deleteDevicePackageMutation(
      this.props.webkeyIds,
      item.packageName,
      this.props.instance,
      (response) => {
        return this.props.snackbar.showMessage("Command executed successfully");
      }
    );
    this.setState({ open: false });
    this.props.callback();
  }

  render() {
    const { classes, packages, webkeyIds } = this.props;
    const { searchField, selectedPackage, open } = this.state;

    // const uniqPackages = _.uniqBy(packages, 'packageName');

    return (
      <div className={classes.root}>
        <DialogContent className={classes.verticalContainer}>
          <Dialog
            onClose={() => this.setState({ open: false })}
            aria-labelledby="Uninstall app"
            open={open}
          >
            <Typography className={classes.typo} align="left">
              {`Are you sure you want to uninstall ${selectedPackage.packageName} app on ${webkeyIds.length} devices?`}
            </Typography>
            <div className={classes.horizontalContainer}>
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={() => this.installOrUnistallPackage(selectedPackage)}
              >
                Yes
              </Button>
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={this.handleClose}
              >
                No
              </Button>
            </div>
          </Dialog>
          <TextField
            id="device-search-field"
            label="Search"
            className={classes.textField}
            value={searchField}
            onChange={(event) => this.handleChange("searchField", event)}
            margin="normal"
          />
          <List dense>
            {packages
              .filter(
                (item) =>
                  item.packageName &&
                  item.packageName.toLowerCase().includes(searchField)
              )
              .map((item) => (
                <ListItem
                  key={item.keyName}
                  selected={selectedPackage === item.packageName}
                >
                  <ListItemText primary={item.packageName} />
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="Uninstall App"
                      onClick={() => this.selectItem(item)}
                    >
                      <ArrowForwardIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
          </List>
        </DialogContent>
      </div>
    );
  }
}

BatchInstallModal.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(withStyles(styles), withSnackbar())(BatchInstallModal);
