import { commitMutation } from "react-relay";
import graphql from 'babel-plugin-relay/macro';
import Environment from "../../environment";

const mutation = graphql`
  mutation unpairDeviceMutation(
    $deviceId: String!
    $serverInstance: ServerInstance!
  ) {
    unpairDevice(deviceId: $deviceId, serverInstance: $serverInstance) {
      id
      webkeyId
      webkeyBackupId
    }
  }
`;

export default (deviceId, serverInstance, callback) => {
  const variables = {
    deviceId,
    serverInstance,
  };

  commitMutation(Environment, {
    mutation,
    variables,
    onCompleted: () => null,
    onError: (err) => console.error(err), // eslint-disable-line
  });
};
