/* eslint-disable no-underscore-dangle, no-return-assign */

import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import compose from "recompose/compose";
import { withSnackbar } from "material-ui-snackbar-provider";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import checkboxHOC from "react-table/lib/hoc/selectTable";
import ReactTable from "react-table";
import "react-table/react-table.css";
import updateVideoDeviceProfileMutation from "../../mutations/updateVideoDeviceProfileMutation";

const CheckboxTable = checkboxHOC(ReactTable);

const styles = {
  horizontalContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  button: {
    margin: "8px 8px 8px 8px",
    maxWidth: 140,
  },
  container: {
    height: 500,
    overflow: "auto",
  },
  checkbox: {
    marginLeft: 8,
  },
};

class pushProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selection: [],
      initialSelection: [],
      selectAll: false,
      checked: false,
    };
    this.toggleSelection = this.toggleSelection.bind(this);
    this.toggleAll = this.toggleAll.bind(this);
    this.isSelected = this.isSelected.bind(this);
    this.handlePush = this.handlePush.bind(this);
    this.handleCheck = this.handleCheck.bind(this);
  }

  componentWillMount() {
    const data = this.props.viewer.allVideoDevices.edges
      .filter((item) => item && item.node)
      .filter(
        (item) =>
          item.node.clientId === this.props.selectedProfile.clientId &&
          item.node.format === this.props.selectedProfile.format
      )
      .map((device) => device.node);

    const selection = data
      .filter((device) => device.profile)
      .filter((device) =>
        device.profile.profileId
          ? device.profile.profileId === this.props.selectedProfile.id
          : false
      )
      .map((device) => device.id);

    this.setState({ selection, initialSelection: selection });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const data = nextProps.viewer.allVideoDevices.edges
      .filter((item) => item && item.node)
      .filter(
        (item) =>
          item.node.clientId === this.props.selectedProfile.clientId &&
          item.node.format === this.props.selectedProfile.format
      )
      .map((device) => device.node);
    const selection = data
      .filter((device) => device.profile)
      .filter((device) =>
        device.profile.profileId
          ? device.profile.profileId === this.props.selectedProfile.id
          : false
      )
      .map((device) => device.id);
    const check1 = selection !== this.state.initialSelection;
    if (check1) {
      this.setState({ selection, initialSelection: selection });
    }
  }

  handlePush() {
    const { selection } = this.state;
    selection.map((deviceId) =>
      updateVideoDeviceProfileMutation(
        deviceId,
        this.props.selectedProfile,
        this.state.checked,
        () => {
          this.props.handleClose();
          return this.props.snackbar.showMessage("Profile updated on devices");
        }
      )
    );
  }

  handleCheck() {
    this.setState({ checked: !this.state.checked });
  }

  toggleSelection(bruteKey /* , shift, row */) {
    const key = bruteKey.replace("select-", "");
    let selection = [...this.state.selection];
    const keyIndex = selection.indexOf(key);
    if (keyIndex >= 0) {
      selection = [
        ...selection.slice(0, keyIndex),
        ...selection.slice(keyIndex + 1),
      ];
    } else {
      selection.push(key);
    }
    this.setState({ selection });
  }

  toggleAll() {
    const selectAll = !this.state.selectAll;
    const selection = [];
    if (selectAll) {
      const wrappedInstance = this.checkboxTable.getWrappedInstance();
      const currentRecords = wrappedInstance.getResolvedState().sortedData;
      currentRecords.forEach((item) => {
        selection.push(item._original.id);
      });
    }
    this.setState({ selectAll, selection });
  }

  isSelected(key) {
    return this.state.selection.includes(key);
  }

  render() {
    const { classes, selectedProfile, viewer } = this.props;
    const { toggleSelection, toggleAll, isSelected } = this;
    const { selectAll } = this.state;

    const data = viewer.allVideoDevices.edges
      .filter((item) => item && item.node)
      .filter(
        (item) =>
          item.node.clientId === selectedProfile.clientId &&
          item.node.format === selectedProfile.format
      )
      .map((device) => device.node);

    const columns = [
      {
        Header: "Name",
        accessor: "name",
        maxWidth: 400,
      },
    ];

    const checkboxProps = {
      selectAll,
      isSelected,
      toggleSelection,
      toggleAll,
      selectType: "checkbox",
    };

    return (
      <div className={classes.container}>
        <Typography variant="subtitle1">
          Push profile to video devices:
        </Typography>
        <Typography variant="h6">{`${selectedProfile.name}`}</Typography>
        <Divider />
        <CheckboxTable
          ref={(r) => (this.checkboxTable = r)}
          data={data}
          columns={columns}
          showPagination={false}
          defaultPageSize={data.length}
          keyField="id"
          filterable
          defaultFilterMethod={(filter, row) =>
            String(row[filter.id])
              .toLowerCase()
              .includes(filter.value.toLowerCase())
          }
          className="-striped -highlight"
          style={{
            display: "block",
            fontSize: "12px",
            flex: "auto",
          }}
          {...checkboxProps}
        />
        <div className={classes.horizontalContainer}>
          <Button
            variant="contained"
            color="primary"
            onClick={this.handlePush}
            className={classes.button}
          >
            Push
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={this.props.handleClose}
            className={classes.button}
          >
            Cancel
          </Button>
          <FormControlLabel
            className={classes.checkbox}
            control={
              <Checkbox
                checked={this.state.checked}
                onChange={this.handleCheck}
                value="checked"
                color="primary"
              />
            }
            label="Sync now"
          />
        </div>
      </div>
    );
  }
}

pushProfile.propTypes = {
  classes: PropTypes.object.isRequired, //eslint-disable-line
  viewer: PropTypes.object.isRequired, //eslint-disable-line
  selectedProfile: PropTypes.object.isRequired, //eslint-disable-line
  snackbar: PropTypes.object, //eslint-disable-line
  handleClose: PropTypes.func, //eslint-disable-line
};

export default compose(withStyles(styles), withSnackbar())(pushProfile);
