import { commitMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import Environment from "../../environmentLogin";

const mutation = graphql`
  mutation createUserMutation(
    $inputname: String!
    $input: AuthProviderSignupData!
  ) {
    createUser(name: $inputname, authProvider: $input) {
      id
    }
  }
`;

export default (name, email, password, callback) => {
  const variables = {
    inputname: name,
    input: {
      email: {
        email,
        password,
      },
    },
    clientMutationId: "",
  };

  commitMutation(Environment, {
    mutation,
    variables,
    onCompleted: () => {
      callback();
    },
    onError: (err) => console.error(err), // eslint-disable-line
  });
};
