import React from "react";
import { QueryRenderer } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";
import * as XLSX from "xlsx";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import ReactTable from "react-table";
import "react-table/react-table.css";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Environment from "../../../environment";

const Query = graphql`
  query LogsDialogQuery($input1: NPLogFilter!, $input2: PCLogFilter!) {
    viewer {
      allNPLogs(filter: $input1) {
        edges {
          node {
            id
            deviceId
            date
            data {
              eventType
              readableTimeStamp
              songTitleKey
              artistNameKey
              songFileNameKey
              parentM3uName
            }
          }
        }
      }
      allPCLogs(filter: $input2) {
        edges {
          node {
            id
            deviceId
            date
            data {
              composer
              songMd5
              songTitle
              isrc
              album
              label
              downloadDate
              timesPlayed
              artist
              year
              songFileName
            }
          }
        }
      }
    }
  }
`;

const styles = {
  horizontalContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  verticalContainer: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    flex: "auto",
  },
  card: {
    display: "flex",
    flexDirection: "column",
  },
  dialog: {
    maxWidth: "80vw",
  },
};

class LogsDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: this.props.openLogsDialog,
    };
    this.handleClose = this.handleClose.bind(this);
    this.handleExport = this.handleExport.bind(this);
  }

  handleClose() {
    this.setState({ open: false });
    this.props.callback();
  }

  handleExport(data, name, date, logType) {
    const newData =
      logType === "NPLogs"
        ? data.map((header) => ({
            Date: header.readableTimeStamp,
            "Song's Title": header.songTitleKey,
            "Artist's Name": header.artistNameKey,
            "Song Filename": header.songFileNameKey,
            "M3u filename": header.parentM3uName,
            "Event Type": header.eventType,
          }))
        : data.map((header) => ({
            "Download Date": header.downloadDate,
            "Times Played": header.timesPlayed,
            Artist: header.artist,
            "Song Title": header.songTitle,
            Album: header.album,
            Composer: header.composer,
            ISRC: header.isrc,
            Year: header.year,
            Label: header.label,
            "Song File Name": header.songFileName,
            "Song's md5": header.songMd5,
          }));
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(newData);
    ws["!cols"] = [];
    const n = logType === "NPLogs" ? 6 : 11;
    for (let i = 0; i < n; i += 1) {
      ws["!cols"][i] = { width: 28 };
    }
    XLSX.utils.book_append_sheet(wb, ws, "LogData");
    XLSX.writeFile(wb, `${logType}--${name}--${date}.xlsx`);
    this.handleClose();
  }

  render() {
    const { classes, fullScreen, logType, logId, name } = this.props;
    const { open } = this.state;
    const pageSize = 20;

    const NPLogsColumns = [
      {
        Header: "Date",
        accessor: "readableTimeStamp",
        width: 180,
      },
      {
        Header: "Song's Title",
        accessor: "songTitleKey",
        width: 100,
      },
      {
        Header: "Artist's Name",
        accessor: "artistNameKey",
        width: 100,
      },
      {
        Header: "Song Filename",
        accessor: "songFileNameKey",
        width: 130,
      },
      {
        Header: "M3u filename",
        accessor: "parentM3uName",
        width: 130,
      },
      {
        Header: "Event Type",
        accessor: "eventType",
        width: 100,
      },
    ];

    const PCLogsColumns = [
      {
        Header: "Download Date",
        accessor: "downloadDate",
        width: 100,
      },
      {
        Header: "Times Played",
        accessor: "timesPlayed",
        width: 75,
      },
      {
        Header: "Artist",
        accessor: "artist",
        width: 100,
      },
      {
        Header: "Song Title",
        accessor: "songTitle",
        width: 165,
      },
      {
        Header: "Album",
        accessor: "album",
        width: 150,
      },
      {
        Header: "Composer",
        accessor: "composer",
        width: 75,
      },
      {
        Header: "ISRC",
        accessor: "isrc",
        width: 40,
      },
      {
        Header: "Year",
        accessor: "year",
        width: 40,
      },
      {
        Header: "Label",
        accessor: "label",
        width: 75,
      },
      {
        Header: "Song File Name",
        accessor: "songFileName",
        width: 125,
      },
      {
        Header: "Song's md5",
        accessor: "songMd5",
        width: 75,
      },
    ];

    return (
      <div>
        <Dialog
          fullScreen={fullScreen}
          open={open}
          onClose={this.handleClose}
          aria-labelledby="responsive-dialog-title"
          maxWidth={false}
        >
          <QueryRenderer
            variables={{
              input1: {
                _id_contains: logType === "NPLogs" ? logId : "no data",
              },
              input2: {
                _id_contains: logType === "PCLogs" ? logId : "no data",
              },
            }}
            environment={Environment}
            query={Query}
            render={({ error, props }) => {
              if (error) {
                return <div>{error.message}</div>;
              } else if (props) {
                const log = props.viewer.allNPLogs.edges[0]
                  ? props.viewer.allNPLogs.edges[0]
                  : props.viewer.allPCLogs.edges[0];
                return (
                  <div>
                    <DialogTitle
                      id="responsive-dialog-title"
                      style={{ padding: 8 }}
                    >
                      <div className={classes.horizontalContainer}>
                        <div className={classes.verticalContainer}>
                          <Typography variant="body2">
                            {logType === "NPLogs"
                              ? "Now Playing Logs -\u00A0"
                              : "Playing Counts Logs -\u00A0"}
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            {name}
                          </Typography>
                        </div>
                        <div
                          className={classes.verticalContainer}
                          style={{ alignItems: "flex-end" }}
                        >
                          <Typography variant="body2" color="textSecondary">
                            {moment.unix(log.node.date / 1000).format()}
                          </Typography>
                        </div>
                      </div>
                    </DialogTitle>
                    <DialogContent style={{ padding: 8 }}>
                      {logType === "NPLogs" ? (
                        <ReactTable
                          data={log.node.data}
                          columns={NPLogsColumns}
                          defaultPageSize={pageSize}
                          showPagination
                          filterable
                          defaultFilterMethod={(filter, row) =>
                            String(row[filter.id])
                              .toLowerCase()
                              .includes(filter.value.toLowerCase())
                          }
                          className="-striped -highlight"
                          style={{
                            display: "block",
                            fontSize: "10px",
                            height: "800px",
                            width: "100%",
                          }}
                        />
                      ) : (
                        <ReactTable
                          data={log.node.data}
                          columns={PCLogsColumns}
                          defaultPageSize={pageSize}
                          showPagination
                          filterable
                          defaultFilterMethod={(filter, row) =>
                            String(row[filter.id])
                              .toLowerCase()
                              .includes(filter.value.toLowerCase())
                          }
                          className="-striped -highlight"
                          style={{
                            display: "block",
                            fontSize: "10px",
                            height: "800px",
                            width: "100%",
                          }}
                        />
                      )}
                    </DialogContent>
                    <DialogActions>
                      <Button
                        onClick={() =>
                          this.handleExport(
                            log.node.data,
                            name,
                            log.node.date,
                            logType
                          )
                        }
                        color="primary"
                      >
                        export log
                      </Button>
                    </DialogActions>
                  </div>
                );
              }
              return (
                <DialogContent style={{ padding: 8 }}>
                  Please wait...
                </DialogContent>
              );
            }}
          />
        </Dialog>
      </div>
    );
  }
}

LogsDialog.propTypes = {
  classes: PropTypes.object.isRequired, //eslint-disable-line
  openLogsDialog: PropTypes.bool,
  callback: PropTypes.func,
  fullScreen: PropTypes.bool,
  logType: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  logId: PropTypes.string.isRequired,
};

LogsDialog.defaultProps = {
  openLogsDialog: false,
  callback: null,
  fullScreen: false,
};

export default withStyles(styles)(LogsDialog);
