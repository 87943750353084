/**
 * @flow
 * @relayHash b234795ee8fd23521a5cfc674776ecaa
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type toggleHideSonosVolumeBarMutationVariables = {|
  userId: string,
  value?: ?boolean,
|};
export type toggleHideSonosVolumeBarMutationResponse = {|
  +toggleHideSonosVolumeBar: {|
    +id: string,
    +sonos: ?{|
      +hideVolumeBar: ?boolean
    |},
  |}
|};
export type toggleHideSonosVolumeBarMutation = {|
  variables: toggleHideSonosVolumeBarMutationVariables,
  response: toggleHideSonosVolumeBarMutationResponse,
|};
*/


/*
mutation toggleHideSonosVolumeBarMutation(
  $userId: String!
  $value: Boolean
) {
  toggleHideSonosVolumeBar(userId: $userId, value: $value) {
    id
    sonos {
      hideVolumeBar
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "userId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "value"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "userId",
        "variableName": "userId"
      },
      {
        "kind": "Variable",
        "name": "value",
        "variableName": "value"
      }
    ],
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "toggleHideSonosVolumeBar",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "SonosObject",
        "kind": "LinkedField",
        "name": "sonos",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hideVolumeBar",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "toggleHideSonosVolumeBarMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "toggleHideSonosVolumeBarMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "b234795ee8fd23521a5cfc674776ecaa",
    "metadata": {},
    "name": "toggleHideSonosVolumeBarMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '778787b434f5ee71ffd44874eb020d18';

module.exports = node;
