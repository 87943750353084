import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

const styles = (theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    background: theme.palette.background.paper,
  },
});

const arraySort = (a, b) => {
  try {
    const nameA = a?.toUpperCase(); // ignore upper and lowercase
    const nameB = b?.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    // names must be equal
    return 0;
  } catch (e) {
    console.log("AudioDevices/AudioClients:", e);
  }
};

class AudioClients extends React.Component {
  constructor(props) {
    super(props);
    this.callBack = this.callBack.bind(this);
  }

  shouldComponentUpdate(nextProps) {
    if (
      nextProps.viewer.allClients.edges !== this.props.viewer.allClients.edges
    ) {
      return true;
    }
    return false;
  }

  callBack(e) {
    this.props.myCallBack(e.currentTarget.id);
  }

  render() {
    const { classes, viewer } = this.props;

    const allClientsSorted = [
      ...viewer.allClients.edges.filter((item) => item?.node?.name),
    ].sort((a, b) =>
      a.node && b.node ? arraySort(a.node.name, b.node.name) : null
    );
    return (
      <div className={classes.root}>
        {allClientsSorted.map(({ node }) =>
          node ? (
            <ListItem key={node.id} id={node.id} button onClick={this.callBack}>
              <ListItemText primary={node.name} />
            </ListItem>
          ) : null
        )}
      </div>
    );
  }
}

AudioClients.propTypes = {
  classes: PropTypes.object.isRequired, //eslint-disable-line
  viewer: PropTypes.object.isRequired, //eslint-disable-line
  myCallBack: PropTypes.func, //eslint-disable-line
};

export default withStyles(styles)(AudioClients);
