/**
 * External dependencies
 */
import React, { useState, useContext } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemSecondaryAction,
  IconButton,
} from "@material-ui/core";
import { Delete, Edit, FileCopy, DragHandle } from "@material-ui/icons";

/**
 * Internal dependencies
 */

import MenuModal from "./MenuModal";
import { CurrentMenuContext } from "../../hooks/currentMenuProvider";
import { CurrentRouteContext } from "../../hooks/currentRouteProvider";

const styles = {
  root: {},
  nested: {
    paddingLeft: 16,
  },
};

const MenuListItemExtension = ({ classes, menu, parentMenu }) => {
  const [openModal, setOpenModal] = useState(false);

  const { currentMenuExtension, setCurrentMenuExtension, setCurrentMenu } =
    useContext(CurrentMenuContext);
  const { setCurrentRoute } = useContext(CurrentRouteContext);

  const handleOpen = (modalType) => {
    setOpenModal(modalType);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleSetMenu = () => {
    setCurrentMenu(parentMenu);
    setCurrentMenuExtension(menu);
    setCurrentRoute();
  };

  return (
    <>
      <ListItem
        component="nav"
        className={classes.root}
        aria-label="menu-list-item"
        button
        onClick={handleSetMenu}
        selected={currentMenuExtension?.id === menu?.id}
      >
        <ListItemIcon>
          <DragHandle />
        </ListItemIcon>
        <ListItemText primary={menu?.name} secondary={menu?.description} />
        <ListItemSecondaryAction>
          <IconButton
            edge="end"
            aria-label="edit-menu-button"
            onClick={() => handleOpen("edit")}
          >
            <Edit />
          </IconButton>
          <IconButton
            edge="end"
            aria-label="duplicate-menu-button"
            onClick={() => handleOpen("duplicate")}
          >
            <FileCopy />
          </IconButton>
          <IconButton
            edge="end"
            aria-label="delete-menu-button"
            onClick={() => handleOpen("delete")}
          >
            <Delete />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      <MenuModal
        openModal={openModal}
        callbackModal={handleClose}
        menu={menu}
      />
    </>
  );
};

export default withStyles(styles)(MenuListItemExtension);
