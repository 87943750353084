import React from "react";

import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Fade from "@material-ui/core/Fade";

import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

const styles = (theme) => ({
  root: {
    margin: "4px 0px 4px 0px",
  },
  content: {
    display: "flex",
    background: "#f9f9f9",
  },
  card: {
    marginBottom: "6px",
  },
});

class Options extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      classes,
      nowPlaying,
      appDetails,
      logs,
      systemDetailsControls,
      user,
      tags,
      optionPressed,
    } = this.props;

    return (
      <div className={classes.root}>
        <Fade in={optionPressed} timeout={400}>
          <Card className={classes.card}>
            <CardContent className={classes.content}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={nowPlaying}
                      onClick={() => this.props.handleChange("nowPlaying")}
                      classes={{
                        checked: classes.checked,
                      }}
                      value="Now Playing"
                    />
                  }
                  label="Now Playing"
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={appDetails}
                      onClick={() => this.props.handleChange("appDetails")}
                      classes={{
                        checked: classes.checked,
                      }}
                      value="Application Details"
                    />
                  }
                  label="Application Details"
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={logs}
                      onClick={() => this.props.handleChange("logs")}
                      classes={{
                        checked: classes.checked,
                      }}
                      value="Device Logs"
                    />
                  }
                  label="Device Logs"
                />
              </FormGroup>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={systemDetailsControls}
                      onClick={() =>
                        this.props.handleChange("systemDetailsControls")
                      }
                      classes={{
                        checked: classes.checked,
                      }}
                      value="System Details & Controls"
                    />
                  }
                  label="System Details & Controls"
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={user}
                      onClick={() => this.props.handleChange("user")}
                      classes={{
                        checked: classes.checked,
                      }}
                      value="User Settings"
                    />
                  }
                  label="User Settings"
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={tags}
                      onClick={() => this.props.handleChange("tags")}
                      classes={{
                        checked: classes.checked,
                      }}
                      value="Tag Settings"
                    />
                  }
                  label="Tag Settings"
                />
              </FormGroup>
            </CardContent>
          </Card>
        </Fade>
      </div>
    );
  }
}

export default withStyles(styles)(Options);
