import { commitMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import Environment from "../../environment";

const mutation = graphql`
  mutation assignSonosMenuToUserMutation(
    $userId: String!
    $extensionMenuId: String
  ) {
    assignSonosMenuToUser(userId: $userId, extensionMenuId: $extensionMenuId) {
      id
      sonos {
        extensionMenuId
      }
    }
  }
`;

export default (userId, extensionMenuId, callback) => {
  const variables = {
    userId,
    extensionMenuId,
  };

  commitMutation(Environment, {
    mutation,
    variables,
    onCompleted: () => {
      callback();
    },
    onError: (err) => console.error(err), // eslint-disable-line
  });
};
