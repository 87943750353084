/* eslint-disable react/prop-types */
import React, { Component } from "react";
import { withRouter } from "found";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";

const styles = (theme) => ({
  card: {
    display: "flex",
    flexDirection: "column",
    background: theme.palette.background.paper,
    margin: 4,
  },
  horizontalContainer: {
    padding: 4,
    display: "flex",
    flexWrap: "wrap",
  },
  verticalContainer: {
    padding: 4,
    display: "flex",
    flexDirection: "column",
  },
  title: {
    fontSize: 20,
  },
  pos: {
    marginBottom: 12,
  },
});

class CampaignPanel extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    const { classes, viewer } = this.props;
    const futureCampaigns = viewer.allVideoCampaigns.edges
      .filter((item) => item.node)
      .filter((item) => moment(item.node.goLiveDate) >= moment());
    return (
      <Card className={classes.card} raised>
        <CardHeader
          title="Campaign Schedule"
          subheader="Future campaigns list"
        />
        <Divider />
        <CardContent className={classes.horizontalContainer}>
          <div className={classes.verticalContainer}>
            {futureCampaigns.map((item) => (
              <Card key={item.node.id} className={classes.card}>
                <CardContent>
                  <Typography className={classes.title} variant="subtitle1">
                    {item.node.name}
                  </Typography>
                  <Typography
                    className={classes.pos}
                    color="textSecondary"
                    variant="body1"
                  >
                    {item.node.description}
                  </Typography>
                  <Typography component="p">
                    Devices enrolled: {item.node.deviceIds.length}
                  </Typography>
                  <Typography component="p">
                    Go live date:{" "}
                    {moment(item.node.goLiveDate).format("MMMM Do YYYY, HH:mm")}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button
                    size="medium"
                    onClick={() =>
                      this.props.router.push("/home/video_campaigns")
                    }
                  >
                    Details
                  </Button>
                </CardActions>
              </Card>
            ))}
          </div>
        </CardContent>
        <Divider />
      </Card>
    );
  }
}

CampaignPanel.propTypes = {
  classes: PropTypes.object.isRequired, //eslint-disable-line
  viewer: PropTypes.object.isRequired, // eslint-disable-line
};

export default withRouter(withStyles(styles)(CampaignPanel));
