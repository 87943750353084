/* eslint-disable react/no-array-index-key */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Playback from "./Playback";
import ViewListIcon from "@material-ui/icons/ViewList";
import ViewComfyIcon from "@material-ui/icons/ViewComfy";
import PlaybackListView from "./PlaybackListView";
import List from "@material-ui/core/List";

const styles = (theme) => ({
  root: {
    display: "flex",
    width: "100%",
    flexWrap: "wrap",
    backgroundColor: theme.palette.background.paper,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  formControl: {
    margin: "16px 8px 8px 8px",
    minWidth: 200,
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
});

class PlaybackList extends Component {
  constructor() {
    super();
    this.state = {
      searchField: "",
      displayNumber: 20,
      viewMode: "list",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleChange2 = this.handleChange2.bind(this);
  }

  componentDidMount() {
    const totalDisplayNumber = this.props.viewer.allPlaybacks.edges.filter(
      (item) => item && item.node
    );
    this.setState({
      displayNumber: totalDisplayNumber.length,
    });
  }

  handleChange(name, event) {
    this.setState({ [name]: event.target.value });
  }
  handleChange2(event) {
    this.setState({ displayNumber: event.target.value });
  }

  render() {
    const { classes, viewer, clientId } = this.props;
    const { searchField, displayNumber } = this.state;
    const allPlaybacks = viewer.allPlaybacks.edges
      .filter((item) => item?.node)
      .filter((item) =>
        clientId === "" ? true : item.node.clientId === clientId
      );
    const noToDisplay = Math.ceil(allPlaybacks.length / 20);
    let noToDisplayArray = [];
    for (let i = 0; i < noToDisplay; i += 1) {
      noToDisplayArray = noToDisplayArray.concat("");
    }
    return (
      <div>
        <form className={classes.container} noValidate autoComplete="off">
          <TextField
            label="Search smart playlist"
            type="search"
            value={searchField}
            className={classes.textField}
            onChange={(event) => this.handleChange("searchField", event)}
            margin="normal"
          />
          <FormControl className={classes.formControl}>
            <InputLabel>Smart playlist display number</InputLabel>
            <Select
              value={displayNumber}
              onChange={(event) => this.handleChange2(event)}
            >
              {noToDisplayArray.map((item, i) => (
                <MenuItem key={i} value={20 * (i + 1)}>
                  {20 * (i + 1)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </form>
        <div
          style={{
            marginLeft: "12px",
            marginTop: "24px",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Typography color="textSecondary" variant="subtitle1">
            View mode: &nbsp;
          </Typography>
          <ViewListIcon
            onClick={() => this.setState({ viewMode: "list" })}
            style={{ color: this.state.viewMode === "list" ? "#000" : "grey" }}
          />
          <ViewComfyIcon
            onClick={() => this.setState({ viewMode: "grid" })}
            style={{ color: this.state.viewMode === "grid" ? "#000" : "grey" }}
          />
        </div>
        <div
          className={classes.root}
          style={
            this.state.viewMode === "list"
              ? { display: "flex", flexDirection: "column" }
              : {}
          }
        >
          {this.state.viewMode === "list" ? (
            <List>
              {allPlaybacks
                .filter((item) =>
                  item.node.name
                    .toLowerCase()
                    .includes(searchField.toLowerCase())
                )
                .slice(0, displayNumber)
                .map(({ node }) => (
                  <PlaybackListView
                    key={node.id}
                    node={node}
                    allPlaylists={viewer.allPlaylists.edges.filter(
                      (item) => item && item.node
                    )}
                    viewer={viewer}
                  />
                ))}
            </List>
          ) : (
            allPlaybacks
              .filter((item) =>
                item.node.name.toLowerCase().includes(searchField.toLowerCase())
              )
              .slice(0, displayNumber)
              .map(({ node }) => (
                <Playback
                  key={node.id}
                  node={node}
                  allPlaylists={viewer.allPlaylists.edges.filter(
                    (item) => item && item.node
                  )}
                  viewer={viewer}
                />
              ))
          )}
        </div>
      </div>
    );
  }
}

PlaybackList.propTypes = {
  clientId: PropTypes.string.isRequired,
  viewer: PropTypes.object.isRequired, // eslint-disable-line
  classes: PropTypes.object.isRequired, // eslint-disable-line
};

export default withStyles(styles)(PlaybackList);
