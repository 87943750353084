const baseUrl = "https://artwork.activaire.com/poc/";

export const folderCovers = [
  {
    label: "AllPlaylists",
    cover_40: `${baseUrl}AllPlaylists_main_40.svg`,
    cover_290: `${baseUrl}AllPlaylists_main_290.svg`,
    cover_legacy: `${baseUrl}AllPlaylists_main_legacy.png`,
  },
  {
    label: "AllScenes",
    cover_40: `${baseUrl}AllScenes_main_40.svg`,
    cover_290: `${baseUrl}AllScenes_main_290.svg`,
    cover_legacy: `${baseUrl}AllScenes_main_legacy.png`,
  },
  {
    label: "Featured",
    cover_40: `${baseUrl}Featured_main_40.svg`,
    cover_290: `${baseUrl}Featured_main_290.svg`,
    cover_legacy: `${baseUrl}Featured_main_legacy.png`,
  },
  {
    label: "Genre",
    cover_40: `${baseUrl}Genre_main_40.svg`,
    cover_290: `${baseUrl}Genre_main_290.svg`,
    cover_legacy: `${baseUrl}Genre_main_legacy.png`,
  },
  {
    label: "Loop",
    cover_40: `${baseUrl}Loop_main_40.svg`,
    cover_290: `${baseUrl}Loop_main_290.svg`,
    cover_legacy: `${baseUrl}Loop_main_legacy.png`,
  },
  {
    label: "Note-2",
    cover_40: `${baseUrl}Note-2_main_40.svg`,
    cover_290: `${baseUrl}Note-2_main_290.svg`,
    cover_legacy: `${baseUrl}Note-2_main_legacy.png`,
  },
  {
    label: "Note-3",
    cover_40: `${baseUrl}Note-3_main_40.svg`,
    cover_290: `${baseUrl}Note-3_main_290.svg`,
    cover_legacy: `${baseUrl}Note-3_main_legacy.png`,
  },
  {
    label: "Note-4",
    cover_40: `${baseUrl}Note-4_main_40.svg`,
    cover_290: `${baseUrl}Note-4_main_290.svg`,
    cover_legacy: `${baseUrl}Note-4_main_legacy.png`,
  },
  {
    label: "Playlist-Intensity",
    cover_40: `${baseUrl}Playlist-Intensity_main_40.svg`,
    cover_290: `${baseUrl}Playlist-Intensity_main_290.svg`,
    cover_legacy: `${baseUrl}Playlist-Intensity_main_legacy.png`,
  },
  {
    label: "Playlists-Main",
    cover_40: `${baseUrl}Playlists-Main_main_40.svg`,
    cover_290: `${baseUrl}Playlists-Main_main_290.svg`,
    cover_legacy: `${baseUrl}Playlists-Main_main_legacy.png`,
  },
  {
    label: "Scene-Intensity",
    cover_40: `${baseUrl}Scene-Intensity_main_40.svg`,
    cover_290: `${baseUrl}Scene-Intensity_main_290.svg`,
    cover_legacy: `${baseUrl}Scene-Intensity_main_legacy.png`,
  },
  {
    label: "Scenes-Main",
    cover_40: `${baseUrl}Scenes-Main_main_40.svg`,
    cover_290: `${baseUrl}Scenes-Main_main_290.svg`,
    cover_legacy: `${baseUrl}Scenes-Main_main_legacy.png`,
  },
  {
    label: "Star-2",
    cover_40: `${baseUrl}Star-2_main_40.svg`,
    cover_290: `${baseUrl}Star-2_main_290.svg`,
    cover_legacy: `${baseUrl}Star-2_main_legacy.png`,
  },
  {
    label: "Star-3",
    cover_40: `${baseUrl}Star-3_main_40.svg`,
    cover_290: `${baseUrl}Star-3_main_290.svg`,
    cover_legacy: `${baseUrl}Star-3_main_legacy.png`,
  },
  {
    label: "Taste",
    cover_40: `${baseUrl}Taste_main_40.svg`,
    cover_290: `${baseUrl}Taste_main_290.svg`,
    cover_legacy: `${baseUrl}Taste_main_legacy.png`,
  },
  {
    label: "Wave-1",
    cover_40: `${baseUrl}Wave-1_main_40.svg`,
    cover_290: `${baseUrl}Wave-1_main_290.svg`,
    cover_legacy: `${baseUrl}Wave-1_main_legacy.png`,
  },
  {
    label: "Weather",
    cover_40: `${baseUrl}Weather_main_40.svg`,
    cover_290: `${baseUrl}Weather_main_290.svg`,
    cover_legacy: `${baseUrl}Weather_main_legacy.png`,
  },
  {
    label: "10-Note-01-01CECA",
    cover_40: `${baseUrl}10-Note-01-01CECA_main_40.svg`,
    cover_290: `${baseUrl}10-Note-01-01CECA_main_290.svg`,
    cover_legacy: `${baseUrl}10-Note-01-01CECA_main_legacy.png`,
  },
  {
    label: "10-Note-01-2-C1F72E",
    cover_40: `${baseUrl}10-Note-01-2-C1F72E_main_40.svg`,
    cover_290: `${baseUrl}10-Note-01-2-C1F72E_main_290.svg`,
    cover_legacy: `${baseUrl}10-Note-01-2-C1F72E_main_legacy.png`,
  },
  {
    label: "10-Note-01-0134CE",
    cover_40: `${baseUrl}10-Note-01-0134CE_main_40.svg`,
    cover_290: `${baseUrl}10-Note-01-0134CE_main_290.svg`,
    cover_legacy: `${baseUrl}10-Note-01-0134CE_main_legacy.png`,
  },
  {
    label: "10-Note-01-C1F72E",
    cover_40: `${baseUrl}10-Note-01-C1F72E_main_40.svg`,
    cover_290: `${baseUrl}10-Note-01-C1F72E_main_290.svg`,
    cover_legacy: `${baseUrl}10-Note-01-C1F72E_main_legacy.png`,
  },
  {
    label: "10-Note-01-C361E9",
    cover_40: `${baseUrl}10-Note-01-C361E9_main_40.svg`,
    cover_290: `${baseUrl}10-Note-01-C361E9_main_290.svg`,
    cover_legacy: `${baseUrl}10-Note-01-C361E9_main_legacy.png`,
  },
  {
    label: "10-Note-01-FF4001",
    cover_40: `${baseUrl}10-Note-01-FF4001_main_40.svg`,
    cover_290: `${baseUrl}10-Note-01-FF4001_main_290.svg`,
    cover_legacy: `${baseUrl}10-Note-01-FF4001_main_legacy.png`,
  },
  {
    label: "10-Note-01-FFBF11",
    cover_40: `${baseUrl}10-Note-01-FFBF11_main_40.svg`,
    cover_290: `${baseUrl}10-Note-01-FFBF11_main_290.svg`,
    cover_legacy: `${baseUrl}10-Note-01-FFBF11_main_legacy.png`,
  },
  {
    label: "10-Note-02-01CECA",
    cover_40: `${baseUrl}10-Note-02-01CECA_main_40.svg`,
    cover_290: `${baseUrl}10-Note-02-01CECA_main_290.svg`,
    cover_legacy: `${baseUrl}10-Note-02-01CECA_main_legacy.png`,
  },
  {
    label: "10-Note-02-0134CE",
    cover_40: `${baseUrl}10-Note-02-0134CE_main_40.svg`,
    cover_290: `${baseUrl}10-Note-02-0134CE_main_290.svg`,
    cover_legacy: `${baseUrl}10-Note-02-0134CE_main_legacy.png`,
  },
  {
    label: "10-Note-02-C1F72E",
    cover_40: `${baseUrl}10-Note-02-C1F72E_main_40.svg`,
    cover_290: `${baseUrl}10-Note-02-C1F72E_main_290.svg`,
    cover_legacy: `${baseUrl}10-Note-02-C1F72E_main_legacy.png`,
  },
  {
    label: "10-Note-02-FF4001",
    cover_40: `${baseUrl}10-Note-02-FF4001_main_40.svg`,
    cover_290: `${baseUrl}10-Note-02-FF4001_main_290.svg`,
    cover_legacy: `${baseUrl}10-Note-02-FF4001_main_legacy.png`,
  },
  {
    label: "10-Note-02-FFBF11",
    cover_40: `${baseUrl}10-Note-02-FFBF11_main_40.svg`,
    cover_290: `${baseUrl}10-Note-02-FFBF11_main_290.svg`,
    cover_legacy: `${baseUrl}10-Note-02-FFBF11_main_legacy.png`,
  },
  {
    label: "10-Note-03-01CECA",
    cover_40: `${baseUrl}10-Note-03-01CECA_main_40.svg`,
    cover_290: `${baseUrl}10-Note-03-01CECA_main_290.svg`,
    cover_legacy: `${baseUrl}10-Note-03-01CECA_main_legacy.png`,
  },
  {
    label: "10-Note-03-2-0134CE",
    cover_40: `${baseUrl}10-Note-03-2-0134CE_main_40.svg`,
    cover_290: `${baseUrl}10-Note-03-2-0134CE_main_290.svg`,
    cover_legacy: `${baseUrl}10-Note-03-2-0134CE_main_legacy.png`,
  },
  {
    label: "10-Note-03-0134CE",
    cover_40: `${baseUrl}10-Note-03-0134CE_main_40.svg`,
    cover_290: `${baseUrl}10-Note-03-0134CE_main_290.svg`,
    cover_legacy: `${baseUrl}10-Note-03-0134CE_main_legacy.png`,
  },
  {
    label: "10-Note-03-C1F72E",
    cover_40: `${baseUrl}10-Note-03-C1F72E_main_40.svg`,
    cover_290: `${baseUrl}10-Note-03-C1F72E_main_290.svg`,
    cover_legacy: `${baseUrl}10-Note-03-C1F72E_main_legacy.png`,
  },
  {
    label: "10-Note-03-C361E9",
    cover_40: `${baseUrl}10-Note-03-C361E9_main_40.svg`,
    cover_290: `${baseUrl}10-Note-03-C361E9_main_290.svg`,
    cover_legacy: `${baseUrl}10-Note-03-C361E9_main_legacy.png`,
  },
  {
    label: "10-Note-03-FF4001",
    cover_40: `${baseUrl}10-Note-03-FF4001_main_40.svg`,
    cover_290: `${baseUrl}10-Note-03-FF4001_main_290.svg`,
    cover_legacy: `${baseUrl}10-Note-03-FF4001_main_legacy.png`,
  },
  {
    label: "10-Note-03-FFBF11",
    cover_40: `${baseUrl}10-Note-03-FFBF11_main_40.svg`,
    cover_290: `${baseUrl}10-Note-03-FFBF11_main_290.svg`,
    cover_legacy: `${baseUrl}10-Note-03-FFBF11_main_legacy.png`,
  },
  {
    label: "10-Note-04-01CECA",
    cover_40: `${baseUrl}10-Note-04-01CECA_main_40.svg`,
    cover_290: `${baseUrl}10-Note-04-01CECA_main_290.svg`,
    cover_legacy: `${baseUrl}10-Note-04-01CECA_main_legacy.png`,
  },
  {
    label: "10-Note-04-2-0134CE",
    cover_40: `${baseUrl}10-Note-04-2-0134CE_main_40.svg`,
    cover_290: `${baseUrl}10-Note-04-2-0134CE_main_290.svg`,
    cover_legacy: `${baseUrl}10-Note-04-2-0134CE_main_legacy.png`,
  },
  {
    label: "10-Note-04-2-FF4001",
    cover_40: `${baseUrl}10-Note-04-2-FF4001_main_40.svg`,
    cover_290: `${baseUrl}10-Note-04-2-FF4001_main_290.svg`,
    cover_legacy: `${baseUrl}10-Note-04-2-FF4001_main_legacy.png`,
  },
  {
    label: "10-Note-04-0134CE",
    cover_40: `${baseUrl}10-Note-04-0134CE_main_40.svg`,
    cover_290: `${baseUrl}10-Note-04-0134CE_main_290.svg`,
    cover_legacy: `${baseUrl}10-Note-04-0134CE_main_legacy.png`,
  },
  {
    label: "10-Note-04-C1F72E",
    cover_40: `${baseUrl}10-Note-04-C1F72E_main_40.svg`,
    cover_290: `${baseUrl}10-Note-04-C1F72E_main_290.svg`,
    cover_legacy: `${baseUrl}10-Note-04-C1F72E_main_legacy.png`,
  },
  {
    label: "10-Note-04-FF4001",
    cover_40: `${baseUrl}10-Note-04-FF4001_main_40.svg`,
    cover_290: `${baseUrl}10-Note-04-FF4001_main_290.svg`,
    cover_legacy: `${baseUrl}10-Note-04-FF4001_main_legacy.png`,
  },
  {
    label: "10-Note-04-FFBF11",
    cover_40: `${baseUrl}10-Note-04-FFBF11_main_40.svg`,
    cover_290: `${baseUrl}10-Note-04-FFBF11_main_290.svg`,
    cover_legacy: `${baseUrl}10-Note-04-FFBF11_main_legacy.png`,
  },
  {
    label: "10-Star-01-C1F72E",
    cover_40: `${baseUrl}10-Star-01-C1F72E_main_40.svg`,
    cover_290: `${baseUrl}10-Star-01-C1F72E_main_290.svg`,
    cover_legacy: `${baseUrl}10-Star-01-C1F72E_main_legacy.png`,
  },
  {
    label: "10-Star-02-2-0134CE",
    cover_40: `${baseUrl}10-Star-02-2-0134CE_main_40.svg`,
    cover_290: `${baseUrl}10-Star-02-2-0134CE_main_290.svg`,
    cover_legacy: `${baseUrl}10-Star-02-2-0134CE_main_legacy.png`,
  },
  {
    label: "10-Star-02-0134CE",
    cover_40: `${baseUrl}10-Star-02-0134CE_main_40.svg`,
    cover_290: `${baseUrl}10-Star-02-0134CE_main_290.svg`,
    cover_legacy: `${baseUrl}10-Star-02-0134CE_main_legacy.png`,
  },
  {
    label: "10-Star-02-C361E9",
    cover_40: `${baseUrl}10-Star-02-C361E9_main_40.svg`,
    cover_290: `${baseUrl}10-Star-02-C361E9_main_290.svg`,
    cover_legacy: `${baseUrl}10-Star-02-C361E9_main_legacy.png`,
  },
  {
    label: "10-Wave-01CECA",
    cover_40: `${baseUrl}10-Wave-01CECA_main_40.svg`,
    cover_290: `${baseUrl}10-Wave-01CECA_main_290.svg`,
    cover_legacy: `${baseUrl}10-Wave-01CECA_main_legacy.png`,
  },
  {
    label: "10-Wave-0134CE",
    cover_40: `${baseUrl}10-Wave-0134CE_main_40.svg`,
    cover_290: `${baseUrl}10-Wave-0134CE_main_290.svg`,
    cover_legacy: `${baseUrl}10-Wave-0134CE_main_legacy.png`,
  },
  {
    label: "10-Wave-C1F72E",
    cover_40: `${baseUrl}10-Wave-C1F72E_main_40.svg`,
    cover_290: `${baseUrl}10-Wave-C1F72E_main_290.svg`,
    cover_legacy: `${baseUrl}10-Wave-C1F72E_main_legacy.png`,
  },
  {
    label: "10-Wave-C361E9",
    cover_40: `${baseUrl}10-Wave-C361E9_main_40.svg`,
    cover_290: `${baseUrl}10-Wave-C361E9_main_290.svg`,
    cover_legacy: `${baseUrl}10-Wave-C361E9_main_legacy.png`,
  },
  {
    label: "10-Wave-FF4001",
    cover_40: `${baseUrl}10-Wave-FF4001_main_40.svg`,
    cover_290: `${baseUrl}10-Wave-FF4001_main_290.svg`,
    cover_legacy: `${baseUrl}10-Wave-FF4001_main_legacy.png`,
  },
  {
    label: "10-Wave-FFBF11",
    cover_40: `${baseUrl}10-Wave-FFBF11_main_40.svg`,
    cover_290: `${baseUrl}10-Wave-FFBF11_main_290.svg`,
    cover_legacy: `${baseUrl}10-Wave-FFBF11_main_legacy.png
`,
  },
];
