/**
 * @flow
 * @relayHash 038f9a36dca4e94e56ecbc0c2b794f0f
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type _ModelMutationType = "CREATED" | "DELETED" | "UPDATED" | "UPDATEDCLIENT" | "%future added value";
export type ScheduleSubscriptionVariables = {||};
export type ScheduleSubscriptionResponse = {|
  +ScheduleSubscription: {|
    +mutation: _ModelMutationType,
    +node: {|
      +id: string,
      +name: ?string,
      +description: ?string,
      +size: ?number,
      +injectable: ?boolean,
      +startDate: ?string,
      +endDate: ?string,
      +clientId: ?string,
      +playingPlaylists: ?$ReadOnlyArray<?{|
        +playlistId: ?string,
        +name: ?string,
        +volume: ?string,
        +startTime: ?number,
        +endTime: ?number,
        +startTimeSeconds: ?number,
        +endTimeSeconds: ?number,
        +days: ?$ReadOnlyArray<?boolean>,
        +shuffle: ?boolean,
        +filenames: ?$ReadOnlyArray<?{|
          +mixId: ?string,
          +nrOfSongs: ?string,
        |}>,
      |}>,
    |},
  |}
|};
export type ScheduleSubscription = {|
  variables: ScheduleSubscriptionVariables,
  response: ScheduleSubscriptionResponse,
|};
*/


/*
subscription ScheduleSubscription {
  ScheduleSubscription {
    mutation
    node {
      id
      name
      description
      size
      injectable
      startDate
      endDate
      clientId
      playingPlaylists {
        playlistId
        name
        volume
        startTime
        endTime
        startTimeSeconds
        endTimeSeconds
        days
        shuffle
        filenames {
          mixId
          nrOfSongs
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ScheduleSubscriptionPayload",
    "kind": "LinkedField",
    "name": "ScheduleSubscription",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "mutation",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Schedule",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "size",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "injectable",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "startDate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "endDate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "clientId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PlayingPlaylist",
            "kind": "LinkedField",
            "name": "playingPlaylists",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "playlistId",
                "storageKey": null
              },
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "volume",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startTime",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endTime",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startTimeSeconds",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endTimeSeconds",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "days",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "shuffle",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Filenames",
                "kind": "LinkedField",
                "name": "filenames",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "mixId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "nrOfSongs",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ScheduleSubscription",
    "selections": (v1/*: any*/),
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ScheduleSubscription",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "038f9a36dca4e94e56ecbc0c2b794f0f",
    "metadata": {},
    "name": "ScheduleSubscription",
    "operationKind": "subscription",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c9e3b523702da0ecf63c8c2f4d93a52d';

module.exports = node;
