/**
 * @flow
 * @relayHash 2e4aa2f22d302e1ddb291d6ba1fdb29e
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ServerInstance = "backup" | "primary" | "%future added value";
export type WebkeyTasksRefetchQueryVariables = {||};
export type WebkeyTasksRefetchQueryResponse = {|
  +viewer: {|
    +allWebkeyTasks: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: {|
          +id: string,
          +instance: ?ServerInstance,
          +userId: ?string,
          +clientId: ?string,
          +packageId: ?string,
          +type: ?string,
          +reports: ?$ReadOnlyArray<?{|
            +deviceid: ?string,
            +state: ?string,
          |}>,
          +date: ?any,
        |}
      |}>
    |}
  |}
|};
export type WebkeyTasksRefetchQuery = {|
  variables: WebkeyTasksRefetchQueryVariables,
  response: WebkeyTasksRefetchQueryResponse,
|};
*/


/*
query WebkeyTasksRefetchQuery {
  viewer {
    allWebkeyTasks {
      edges {
        node {
          id
          instance
          userId
          clientId
          packageId
          type
          reports {
            deviceid
            state
          }
          date
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Viewer",
    "kind": "LinkedField",
    "name": "viewer",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "WebkeyTaskConnection",
        "kind": "LinkedField",
        "name": "allWebkeyTasks",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "WebkeyTaskEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "WebkeyTask",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "instance",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "userId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "clientId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "packageId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "type",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "WebkeyReport",
                    "kind": "LinkedField",
                    "name": "reports",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "deviceid",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "state",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "date",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "WebkeyTasksRefetchQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "WebkeyTasksRefetchQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "id": "2e4aa2f22d302e1ddb291d6ba1fdb29e",
    "metadata": {},
    "name": "WebkeyTasksRefetchQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '872d10e921b49ddbf1a04364aefc8fa3';

module.exports = node;
