import React from "react";
import PropTypes from "prop-types";
import DialogContent from "@material-ui/core/DialogContent";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import AudiotrackIcon from "@material-ui/icons/AudiotrackRounded";
import VideocamIcon from "@material-ui/icons/VideocamRounded";
import { withStyles } from "@material-ui/core/styles";
import compose from "recompose/compose";
import { withSnackbar } from "material-ui-snackbar-provider";

import pairDeviceMutation from "../mutations/pairDeviceMutation";

const styles = (theme) => ({
  root: {},
  horizontalContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  verticalContainer: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  button: {
    margin: theme.spacing(1),
  },
});

class WebkeyPairingModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchField: "",
      selectedDeviceId: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.selectItem = this.selectItem.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state !== nextState) {
      return true;
    }
    return false;
  }

  handleChange(name, event) {
    this.setState({ [name]: event.target.value.toLowerCase() });
  }

  selectItem(item) {
    this.setState({
      selectedDeviceId: item.id,
    });
  }

  render() {
    const {
      classes,
      audioDevices,
      videoDevices,
      webkeyId,
      instance,
    } = this.props;
    const { searchField, selectedDeviceId } = this.state;
    return (
      <div className={classes.root}>
        <DialogContent className={classes.verticalContainer}>
          <TextField
            id="device-search-field"
            label="Search"
            className={classes.textField}
            value={searchField}
            onChange={(event) => this.handleChange("searchField", event)}
            margin="normal"
          />
          <List dense>
            {audioDevices
              .concat(videoDevices)
              .filter(
                (item) =>
                  (item.name &&
                    item.name.toLowerCase().includes(searchField)) ||
                  (item.macAddress &&
                    item.macAddress.toLowerCase().includes(searchField)) ||
                  (item.ethernetMacAddress &&
                    item.ethernetMacAddress
                      .toLowerCase()
                      .includes(searchField)) ||
                  (item.lanMacAddress &&
                    item.lanMacAddress.toLowerCase().includes(searchField))
              )
              .map((item) => (
                <ListItem
                  key={item.id}
                  onClick={() => this.selectItem(item)}
                  selected={selectedDeviceId === item.id}
                >
                  <ListItemIcon>
                    {item.deviceType === "Audio" ? (
                      <AudiotrackIcon />
                    ) : (
                      <VideocamIcon />
                    )}
                  </ListItemIcon>
                  <ListItemText
                    primary={item.name}
                    secondary={`lan: ${
                      item.ethernetMacAddress || item.lanMacAddress
                    }/wlan: ${item.macAddress}`}
                  />
                </ListItem>
              ))}
          </List>
        </DialogContent>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          disabled={selectedDeviceId === ""}
          onClick={() =>
            pairDeviceMutation(selectedDeviceId, webkeyId, instance, () =>
              this.props.callback()
            )
          }
        >
          Pair device
        </Button>
      </div>
    );
  }
}

WebkeyPairingModal.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(withStyles(styles), withSnackbar())(WebkeyPairingModal);
