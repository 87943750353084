import React from "react";
import { createRefetchContainer, fetchQuery } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import checkboxHOC from "react-table/lib/hoc/selectTable";
import ReactTable from "react-table";
import "react-table/react-table.css";
import CloseIcon from "@material-ui/icons/Close";
import SyncIcon from "@material-ui/icons/SyncRounded";
import DehazeIcon from "@material-ui/icons/DehazeRounded";
import DeleteSweepIcon from "@material-ui/icons/DeleteSweepRounded";
import StorageIcon from "@material-ui/icons/StorageRounded";
import PeopleIcon from "@material-ui/icons/PeopleRounded";
import PeopleOutlineIcon from "@material-ui/icons/PeopleOutlineRounded";
import ClearOutlineIcon from "@material-ui/icons/ClearRounded";
import SettingsRemoteIcon from "@material-ui/icons/SettingsRemoteRounded";
import MenuIcon from "@material-ui/icons/MenuRounded";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import SettingsInputComponentIcon from "@material-ui/icons/SettingsInputComponentRounded";

import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

import moment from "moment";

import environment from "../../environment";
import getAllDeviceCollectionMutation from "../mutations/getAllDeviceCollectionMutation";
import getNewWebkeyTokenMutation from "../mutations/getNewWebkeyTokenMutation";
// import deleteWebkeyDeviceMutation from "../mutations/deleteWebkeyDeviceMutation";
import config from "../../config.js";
import WebkeyPairingModal from "./WebkeyPairingModal";
import WebkeyPackagesModal from "./WebkeyPackagesModal";
import BatchInstallModal from "./BatchInstallModal";
import BatchUninstallModal from "./BatchUninstallModal";
import CustomActionsModal from "./CustomActionsModal";
import BatchActionsModal from "./BatchActionsModal";
import InfoModal from "./InfoModal";
import DeleteDeviceModal from "./DeleteDeviceModal";
import unpairDeviceMutation from "../mutations/unpairDeviceMutation";
import SM from "../subscriptions/index";

const CheckboxTable = checkboxHOC(ReactTable);

const styles = (theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  typo: {
    padding: 0,
  },
  typoClient: {
    padding: "0px 0px 0px 0px",
    color: "secondary",
  },
  button: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    marginLeft: "9px",
    "&:hover": {
      background: "#e3fd59",
    },
  },
  horizontalContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  horizontalContainer2: {
    display: "flex",
    flexWrap: "wrap",
    margin: 8,
  },
  paper: {
    margin: "auto",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: 16,
  },
  text1: {
    alignSelf: "center",
    marginLeft: 16,
  },
  button2: {
    margin: theme.spacing(1),
    height: 40,
  },
  margin: {
    margin: theme.spacing(1),
  },
});

const openRow = "#DCDCDC";

const query = graphql`
  query WebkeyDashboardRefetchQuery {
    viewer {
      ...WebkeyDashboard_viewer
    }
  }
`;

class WebkeyDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRow: "",
      webkeyDeviceId: "",
      pairDialog: false,
      packageDialog: false,
      batchInstallDialog: false,
      batchUninstallDialog: false,
      customActionsDialog: false,
      batchActionsDialog: false,
      deleteDeviceIdDialog: false,
      buttonPushed: 3,
      anchorEl: null,
      selection: [],
      infoModal: false,
      pendingValue: null,
    };
    this._refetch = this._refetch.bind(this);
    this.selectRow = this.selectRow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleClickMenu = this.handleClickMenu.bind(this);
    this.handleCloseMenu = this.handleCloseMenu.bind(this);
    this.handleSync = this.handleSync.bind(this);
    this.toggleSelection = this.toggleSelection.bind(this);
    this.toggleAll = this.toggleAll.bind(this);
    this.isSelected = this.isSelected.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      this.props.match.location.pathname === window.location.pathname ||
      this.props.match.route.instance !== nextProps.match.route.instance
    ) {
      return true;
    }
    return false;
  }

  componentDidMount() {
    const loginProcedure = async () => {
      if (SM.token) {
        // this.subscription1 = await SM.AudioDeviceActiveSubscription(
        //   SM.token,
        //   null,
        //   ["isOnline", "isPlaying", "appVersion", "clientId"]
        // );
      }
    };
    loginProcedure();
  }

  componentWillUnmount() {
    try {
      //this.subscription1.dispose();
    } catch (e) {
      console.log("componentWillUnmount miniDrawer Error:", e);
    }
  }

  selectRow(selectedRow) {
    this.setState({ selectedRow });
  }

  handleClickMenu(event) {
    this.setState({ anchorEl: event.currentTarget });
  }

  handleClose() {
    this.setState({
      pairDialog: false,
      packageDialog: false,
      batchInstallDialog: false,
      batchUninstallDialog: false,
      batchActionsDialog: false,
      customActionsDialog: false,
      infoModal: false,
      deleteDeviceIdDialog: false,
    });
  }

  handleCloseMenu() {
    this.setState({ anchorEl: null });
  }

  handleSync() {
    getAllDeviceCollectionMutation(this.props.match.route.instance, () =>
      fetchQuery(environment, query)
    );
    this.setState({ anchorEl: null });
  }

  _refetch() {
    this.props.relay.refetch();
  }

  toggleSelection(bruteKey /* , shift, row */) {
    const key = bruteKey.replace("select-", "");
    let selection = [...this.state.selection];
    const keyIndex = selection.indexOf(key);
    if (keyIndex >= 0) {
      selection = [
        ...selection.slice(0, keyIndex),
        ...selection.slice(keyIndex + 1),
      ];
    } else {
      selection.push(key);
    }
    this.setState({ selection });
  }

  toggleAll() {
    const selectAll = !this.state.selectAll;
    const selection = [];
    if (selectAll) {
      const wrappedInstance = this.checkboxTable.getWrappedInstance();
      const currentRecords = wrappedInstance.getResolvedState().sortedData;
      currentRecords.forEach((item) => {
        selection.push(item._original.id);
      });
    }
    this.setState({ selectAll, selection });
  }

  isSelected(key) {
    return this.state.selection.includes(key);
  }

  render() {
    const { classes, viewer } = this.props;
    const { toggleSelection, toggleAll, isSelected } = this;
    const {
      selectedRow,
      pairDialog,
      customActionsDialog,
      packageDialog,
      batchInstallDialog,
      batchUninstallDialog,
      batchActionsDialog,
      deleteDeviceIdDialog,
      webkeyDeviceId,
      buttonPushed,
      anchorEl,
      selectAll,
      selection,
      infoModal,
      pendingValue,
    } = this.state;

    const instance = this.props.match.route.instance;

    const checkboxProps = {
      selectAll,
      isSelected,
      toggleSelection,
      toggleAll,
      selectType: "checkbox",
    };

    const fixedColumns = [
      {
        Header: "Remote name",
        accessor: "name",
        minWidth: 200,
        maxWidth: 400,
      },
      {
        id: "LastConnected",
        Header: "Last seen",
        accessor: (a) =>
          a.LastConnected
            ? moment(a.LastConnected * 1000).format("YYYY-MM-DD")
            : "N/A",
        width: 90,
      },
      {
        Header: "Mac Address",
        columns: [
          {
            Header: "Serial",
            accessor: "Serial",
            width: 150,
          },
          {
            Header: "WLAN",
            accessor: "macAddress",
            width: 150,
            Cell: (row) => {
              return (
                <Typography
                  fontSize={12}
                  align="left"
                  style={{
                    color:
                      row.original.macAddress ===
                      row.original.DisplayName.toLowerCase()
                        ? "#387f86"
                        : "#707070",
                  }}
                >
                  {row.original.macAddress}
                </Typography>
              );
            },
          },
          {
            Header: "LAN",
            accessor: "ethernetMacAddress",
            width: 150,
            Cell: (row) => {
              return (
                <Typography
                  fontSize={12}
                  align="left"
                  style={{
                    color:
                      row.original.ethernetMacAddress ===
                      row.original.DisplayName.toLowerCase()
                        ? "#387f86"
                        : "#707070",
                  }}
                >
                  {row.original.ethernetMacAddress}
                </Typography>
              );
            },
          },
          {
            id: "duplicate",
            Header: "duplicate",
            accessor: (a) => (a.duplicate ? "Yes" : "No"),
            width: 50,
          },
        ],
      },
      {
        Header: "Device Details",
        columns: [
          {
            Header: "Client Name",
            accessor: "clientName",
            width: 100, // initial 150
          },
          {
            id: "deviceType",
            Header: "Type",
            accessor: (a) => a.deviceType,
            width: 50,
          },
          {
            Header: "Make and model",
            accessor: "makeAndModel",
            width: 140,
          },
        ],
      },
      {
        Header: "Version",
        columns: [
          {
            Header: instance === "primary" ? "C" : "R",
            accessor: "WebkeyVersion",
            maxWidth: 50,
          },
          {
            Header: instance === "primary" ? "R" : "C",
            accessor: "otherWebkeyVersion",
            maxWidth: 50,
          },
          {
            Header: "App",
            accessor: "appVersion",
            maxWidth: 60,
          },
        ],
      },
      {
        Header: "Online Status",
        id: "onlineStatus",
        columns: [
          {
            id: "online",
            Header: instance === "primary" ? "C" : "R",
            accessor: (a) => (a.Online && a.Online !== "N/A" ? "Yes" : "No"),
            width: 70,
          },
          {
            id: "otherOnline",
            Header: instance === "primary" ? "R" : "C",
            accessor: (a) =>
              a.onlineInOther && a.onlineInOther !== "N/A" ? "Yes" : "No",
            width: 70,
          },
          {
            id: "onlineMeteor",
            Header: "Remote Up",
            accessor: (a) => (a.isOnline ? "Yes" : "No"),
            width: 70,
          },
        ],
      },
      {
        Header: "Actions",
        width: 250,
        filterable: false,
        sortable: false,
        Cell: (row) => {
          return (
            <div
              id={`menu-${row.original.id}`}
              className={classes.horizontalContainer}
            >
              <Tooltip
                id="tooltip-bottom"
                title={"Remote connect"}
                placement="bottom"
                enterTouchDelay={200}
              >
                <IconButton
                  variant="text"
                  size="small"
                  style={{ color: row.original.Online ? "#387f86" : "#bdbdbd" }}
                  className={classes.button}
                  onClick={() =>
                    getNewWebkeyTokenMutation(
                      row.original.id,
                      instance,
                      (token) =>
                        window.open(
                          `${
                            instance === "primary"
                              ? config.WEBKEY_URL
                              : config.WEBKEY_URL_BACKUP
                          }mgm?publicid=${row.original.id}&ratoken=${token}`
                        )
                    )
                  }
                >
                  <SettingsRemoteIcon />
                </IconButton>
              </Tooltip>
              <Tooltip
                title="Device packages"
                placement="bottom"
                enterTouchDelay={200}
                onClick={() =>
                  this.setState({
                    packageDialog: true,
                    webkeyDeviceId: row.original.id,
                  })
                }
              >
                <IconButton
                  variant="text"
                  size="small"
                  color="primary"
                  className={classes.button}
                >
                  <StorageIcon />
                </IconButton>
              </Tooltip>
              <Tooltip
                title="Pair Device"
                placement="bottom"
                enterTouchDelay={200}
                onClick={() =>
                  this.setState({
                    pairDialog: true,
                    webkeyDeviceId: row.original.id,
                  })
                }
              >
                <IconButton
                  variant="text"
                  size="small"
                  color="primary"
                  className={classes.button}
                >
                  <PeopleIcon />
                </IconButton>
              </Tooltip>
              <Tooltip
                id="tooltip-bottom"
                title="Unpair device"
                placement="bottom"
                enterTouchDelay={200}
              >
                <span>
                  <IconButton
                    variant="text"
                    size="small"
                    disabled={!row.original.deviceId}
                    style={{
                      color: row.original.deviceId ? "#ff4c4c" : "#bdbdbd",
                    }}
                    className={classes.button}
                    onClick={() =>
                      unpairDeviceMutation(row.original.deviceId, instance)
                    }
                  >
                    <PeopleOutlineIcon />
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip
                title="Custom Actions"
                placement="bottom"
                enterTouchDelay={200}
                onClick={() =>
                  this.setState({
                    customActionsDialog: true,
                    webkeyDeviceId: row.original.id,
                  })
                }
              >
                <IconButton
                  variant="text"
                  size="small"
                  color="primary"
                  className={classes.button}
                >
                  <MenuIcon />
                </IconButton>
              </Tooltip>
              <Tooltip
                id="tooltip-bottom"
                title="Delete device"
                placement="bottom"
                enterTouchDelay={200}
              >
                <span>
                  <IconButton
                    variant="text"
                    size="small"
                    className={classes.button}
                    style={{ color: "#ff4c4c" }}
                    onClick={() =>
                      this.setState({ deleteDeviceIdDialog: row.original.id })
                    }
                  >
                    <ClearOutlineIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </div>
          );
        },
      },
    ];

    const allAudioDevices = viewer.allAudioDevices.edges
      .filter((item) => item && item.node)
      .map(({ node }) => {
        const deviceType = { deviceType: "Audio" };
        return Object.assign({}, node, deviceType);
      });

    const allVideoDevices = viewer.allVideoDevices.edges
      .filter((item) => item && item.node)
      .map(({ node }) => {
        const deviceType = { deviceType: "Video" };
        return Object.assign({}, node, deviceType);
      });

    const allWebkeyDevices = viewer.allWebkeyDevices.edges
      .filter((item) => item && item.node)
      .map(({ node }) => node);
    const allWebkeyDevicesBackup = viewer.allWebkeyDevicesBackup.edges
      .filter((item) => item && item.node)
      .map(({ node }) => node);

    let allWebkey = [];

    const wkKey = instance === "primary" ? "webkeyId" : "webkeyBackupId";

    const wkKeyOther = instance !== "primary" ? "webkeyId" : "webkeyBackupId";

    if (instance === "primary") {
      allWebkey = allWebkeyDevices;
    } else {
      allWebkey = allWebkeyDevicesBackup;
    }

    const allWebkeyPackages = viewer.allWebkeyPackages.edges
      .filter((item) => item && item.node)
      .map(({ node }) => node);

    const allWebkeyCustomActions = viewer.allWebkeyCustomActions.edges
      .filter((item) => item && item.node)
      .map(({ node }) => node);

    const fullData = allWebkey.map((item) => {
      const newObject = allAudioDevices
        .concat(allVideoDevices)
        .find((item2) => {
          return item2[wkKey] === item.id;
        });

      if (newObject) {
        const existsInOther =
          instance === "primary"
            ? allWebkeyDevicesBackup.find(
                (item) => item.id === newObject[wkKeyOther]
              )
            : allWebkeyDevices.find(
                (item) => item.id === newObject[wkKeyOther]
              );
        const onlineInOther = existsInOther ? existsInOther.Online : "N/A";
        const otherWebkeyVersion = existsInOther
          ? existsInOther.WebkeyVersion
          : "N/A";
        newObject.onlineInOther = onlineInOther;
        newObject.otherWebkeyVersion = otherWebkeyVersion;
      }

      const client =
        newObject &&
        (newObject.deviceType === "Audio"
          ? viewer.allClients.edges.find(
              (item2) =>
                item2 && item2.node && item2.node.id === newObject.clientId
            )
          : viewer.allVideoClients.edges.find(
              (item2) =>
                item2 && item2.node && item2.node.id === newObject.clientId
            ));
      return Object.assign(
        {},
        newObject,
        item,
        { clientName: client && client.node ? client.node.name : "N/A" },
        { deviceId: newObject ? newObject.id : "" },
        {
          appVersion:
            newObject && (newObject.appVersionDS || newObject.appVersion)
              ? newObject.appVersionDS || newObject.appVersion
              : "N/A",
        },
        { isOnline: newObject && newObject.isOnline }
      );
    });
    let renderedData;

    const pendingData = fullData.filter((node) => {
      if (pendingValue && node.DisplayName === pendingValue.DisplayName) {
        return pendingValue;
      }
    });

    switch (buttonPushed) {
      case 1:
        renderedData = fullData.filter((item) => item.deviceId !== "");
        break;
      case 2:
        renderedData = fullData.filter((item) => item.deviceId === "");
        break;
      default:
        renderedData = fullData;
    }

    const pageSize = 50;
    return (
      <div className={classes.root}>
        <h2 style={{ marginLeft: "12px" }}>{this.props.match.route.name}</h2>
        <div className={classes.horizontalContainer}>
          <Button
            variant="contained"
            color="primary"
            className={classes.button2}
            aria-controls="Actions Menu"
            aria-haspopup="true"
            onClick={this.handleClickMenu}
          >
            Actions
          </Button>
          <Menu
            id="action-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={this.handleCloseMenu}
            transitionDuration={{ enter: 0, exit: 50 }}
          >
            <MenuItem onClick={this.handleSync}>
              <ListItemIcon>
                <SyncIcon />
              </ListItemIcon>
              <ListItemText primary="Sync Devices" />
            </MenuItem>
            <MenuItem
              onClick={() =>
                this.setState({ batchInstallDialog: true, anchorEl: null })
              }
              disabled={selection.length === 0}
            >
              <ListItemIcon>
                <DehazeIcon />
              </ListItemIcon>
              <ListItemText primary="Batch install" />
            </MenuItem>
            <MenuItem
              onClick={() =>
                this.setState({ batchUninstallDialog: true, anchorEl: null })
              }
              disabled={selection.length === 0}
            >
              <ListItemIcon>
                <DeleteSweepIcon />
              </ListItemIcon>
              <ListItemText primary="Batch uninstall" />
            </MenuItem>
            <MenuItem
              onClick={() =>
                this.setState({ batchActionsDialog: true, anchorEl: null })
              }
              disabled={selection.length === 0}
            >
              <ListItemIcon>
                <SettingsInputComponentIcon />
              </ListItemIcon>
              <ListItemText primary="Batch Actions" />
            </MenuItem>
          </Menu>
          <Button
            variant={buttonPushed === 1 ? "contained" : "outlined"}
            color="primary"
            className={classes.button2}
            onClick={() => this.setState({ buttonPushed: 1 })}
          >
            {`Paired devices: ${
              fullData.filter((item) => item.deviceId !== "").length
            }`}
          </Button>
          <Button
            variant={buttonPushed === 2 ? "contained" : "outlined"}
            color="primary"
            className={classes.button2}
            onClick={() => this.setState({ buttonPushed: 2 })}
          >
            {`Unpaired devices: ${
              fullData.filter((item) => item.deviceId === "").length
            }`}
          </Button>
          <Button
            variant={buttonPushed === 3 ? "contained" : "outlined"}
            color="primary"
            className={classes.button2}
            onClick={() => this.setState({ buttonPushed: 3 })}
          >
            {`All devices: ${fullData.length}`}
          </Button>
          {instance === "primary" && (
            <Tooltip
              title="Activaire Control stats"
              aria-label="info"
              onClick={() => this.setState({ infoModal: true })}
            >
              <IconButton
                aria-label="info"
                className={classes.margin}
                fontSize="small"
              >
                <InfoIcon />
              </IconButton>
            </Tooltip>
          )}

          <Autocomplete
            id="search-player"
            options={renderedData}
            getOptionLabel={(option) =>
              option.name ? option.name : option.DisplayName
            }
            style={{ width: 300, marginLeft: "50px" }}
            value={pendingValue}
            onChange={(event, newValue) => {
              this.setState({ pendingValue: newValue });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Search player"
                variant="outlined"
                fullWidth
              />
            )}
          />

          <Typography
            fontSize={16}
            id="modal-edit-title"
            align="right"
            style={{
              flex: "auto",
              alignSelf: "center",
              margin: 8,
            }}
          >
            {selection.length !== 0 && `Devices selected:  ${selection.length}`}
          </Typography>
        </div>
        <div className={classes.horizontalContainer}>
          <CheckboxTable
            ref={(r) => (this.checkboxTable = r)}
            data={pendingValue ? pendingData : renderedData}
            columns={fixedColumns}
            defaultPageSize={pageSize}
            showPagination={renderedData.length > pageSize}
            keyField="id"
            filterable
            defaultFilterMethod={(filter, row) =>
              String(row[filter.id])
                .toLowerCase()
                .includes(filter.value.toLowerCase())
            }
            className="-striped -highlight"
            style={{
              margin: "8px 0px 8px 8px",
              fontSize: "13px",
              height: "800px",
              width: "100%",
            }}
            getTrProps={(state, rowInfo) => {
              const selectedId = rowInfo ? rowInfo.original.id : null;
              return {
                onClick: () => this.selectRow(rowInfo.original.id),
                style: {
                  background: selectedId === selectedRow ? openRow : "#FFFFFF",
                },
              };
            }}
            {...checkboxProps}
          />
        </div>
        <Typography
          variant="caption"
          color="secondary"
          className={classes.typo}
          style={{ paddingLeft: 8 }}
          align="left"
        >
          Tip: Hold shift when sorting to multi-sort!
        </Typography>
        <Dialog
          open={
            pairDialog ||
            packageDialog ||
            batchInstallDialog ||
            batchUninstallDialog ||
            customActionsDialog ||
            infoModal ||
            batchActionsDialog ||
            Boolean(deleteDeviceIdDialog)
          }
          onClose={this.handleClose}
          maxWidth="lg"
        >
          <div className={classes.horizontalContainer}>
            <Typography
              variant="h6"
              id="modal-edit-title"
              align="center"
              style={{
                flex: "auto",
                alignSelf: "center",
                margin: 8,
              }}
            >
              {pairDialog && "Pair Webkey devices"}
              {packageDialog && "Installed packages"}
              {batchInstallDialog && "Batch package install"}
              {batchUninstallDialog && "Batch package uninstall"}
              {batchActionsDialog && "Batch Actions"}
              {customActionsDialog && "Custom actions"}
              {infoModal && "Control Stats"}
              {deleteDeviceIdDialog &&
                `Delete device from ${
                  instance === "primary" ? "Control" : "Recovery"
                }`}
            </Typography>
            <Tooltip title="Close" placement="bottom" enterTouchDelay={200}>
              <IconButton onClick={this.handleClose}>
                <CloseIcon className={classes.closeIcon} />
              </IconButton>
            </Tooltip>
          </div>
          {pairDialog && (
            <WebkeyPairingModal
              status={pairDialog}
              webkeyDevices={allWebkeyDevices}
              audioDevices={allAudioDevices.filter((item) =>
                instance === "primary"
                  ? !item.webkeyId || item.webkeyId === ""
                  : !item.webkeyBackupId || item.webkeyBackupId === ""
              )}
              videoDevices={allVideoDevices.filter((item) =>
                instance === "primary"
                  ? !item.webkeyId || item.webkeyId === ""
                  : !item.webkeyBackupId || item.webkeyBackupId === ""
              )}
              webkeyId={webkeyDeviceId}
              callback={this.handleClose}
              instance={instance}
            />
          )}
          {packageDialog && (
            <WebkeyPackagesModal
              status={packageDialog}
              packages={allWebkeyPackages}
              webkeyId={webkeyDeviceId}
              callback={this.handleClose}
              instance={instance}
            />
          )}
          {batchInstallDialog && (
            <BatchInstallModal
              status={batchInstallDialog}
              packages={allWebkeyPackages}
              webkeyIds={selection}
              callback={this.handleClose}
              instance={instance}
            />
          )}
          {batchUninstallDialog && (
            <BatchUninstallModal
              status={batchUninstallDialog}
              packages={allWebkeyPackages}
              webkeyIds={selection}
              callback={this.handleClose}
              instance={instance}
            />
          )}
          {batchActionsDialog && (
            <BatchActionsModal
              status={batchActionsDialog}
              actions={allWebkeyCustomActions}
              webkeyIds={selection}
              callback={this.handleClose}
              instance={instance}
            />
          )}
          {customActionsDialog && (
            <CustomActionsModal
              status={customActionsDialog}
              packages={allWebkeyPackages}
              webkeyId={webkeyDeviceId}
              callback={this.handleClose}
              instance={instance}
            />
          )}
          {infoModal && (
            <InfoModal
              status={infoModal}
              devicesStats={renderedData}
              callback={this.handleClose}
              instance={instance}
            />
          )}
          {deleteDeviceIdDialog && (
            <DeleteDeviceModal
              deviceId={deleteDeviceIdDialog}
              callback={this.handleClose}
              instance={instance}
            />
          )}
        </Dialog>
      </div>
    );
  }
}

WebkeyDashboard.propTypes = {
  classes: PropTypes.object.isRequired, //eslint-disable-line
  viewer: PropTypes.object.isRequired, //eslint-disable-line
};

export default withStyles(styles)(
  createRefetchContainer(WebkeyDashboard, {
    viewer: graphql`
      fragment WebkeyDashboard_viewer on Viewer {
        allWebkeyDevices {
          edges {
            node {
              id
              DisplayName
              Serial
              DeviceNick
              DeviceBrand
              DeviceModel
              WebkeyVersion
              Location
              LastConnected
              AndroidAPI
              AndroidVersion
              Rooted
              Online
              duplicate
            }
          }
        }
        allWebkeyDevicesBackup {
          edges {
            node {
              id
              DisplayName
              Serial
              DeviceNick
              DeviceBrand
              DeviceModel
              WebkeyVersion
              Location
              LastConnected
              AndroidAPI
              AndroidVersion
              Rooted
              Online
              duplicate
            }
          }
        }
        allAudioDevices {
          edges {
            node {
              id
              name
              macAddress
              ethernetMacAddress
              clientId
              webkeyId
              webkeyBackupId
              appVersion
              appVersionDS
              isOnline
              makeAndModel
            }
          }
        }
        allVideoDevices {
          edges {
            node {
              id
              name
              macAddress
              lanMacAddress
              clientId
              webkeyId
              webkeyBackupId
              appVersion
            }
          }
        }
        allWebkeyPackages {
          edges {
            node {
              id
              appName
              packageName
              keyName
              description
              source
              versionCode
              versionName
              icon
            }
          }
        }
        allWebkeyCustomActions {
          edges {
            node {
              id
              name
              actionCode
            }
          }
        }
        allVideoClients {
          edges {
            node {
              id
              name
            }
          }
        }
        allClients {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    `,
  })
);
