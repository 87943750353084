import { commitMutation } from "react-relay";
import graphql from 'babel-plugin-relay/macro';
import Environment from "../../environmentLogin";

const mutation = graphql`
  mutation signinUserMutation($input: AUTH_PROVIDER_EMAIL) {
    signinUser(email: $input) {
      token
      user {
        id
        profile {
          clientId
          videoClientId
        }
      }
    }
  }
`;

export default (email, password, callback) => {
  const variables = {
    input: { email, password },
    clientMutationId: "",
  };

  commitMutation(Environment, {
    mutation,
    variables,
    onCompleted: (response) => {
      if (response && response.signinUser && response.signinUser.token) {
        const id = response.signinUser.user.id;
        const token = response.signinUser.token;
        const audioClientId = response.signinUser.user.profile.clientId;
        const videoClientId =
          response.signinUser.user.profile.videoClientId || "none";
        callback(id, token, audioClientId, videoClientId);
      } else {
        callback("error");
      }
    },
  });
};
