import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import compose from "recompose/compose";
import { withSnackbar } from "material-ui-snackbar-provider";
import TextField from "@material-ui/core/TextField";
import Input from "@material-ui/core/Input";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import FingerprintIcon from "@material-ui/icons/Fingerprint";
import Validator from "validator";
import crypto from "crypto-browserify";
import InlineError from "../ui/inlineError";
import createUserMutation from "../mutations/createUserMutation";
import LoginLogo from "./LoginLogo";

const paperWidth = 270;

const styles = (theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    width: paperWidth,
  },
  root: theme.mixins.gutters({
    position: "relative",
    margin: "auto",
    marginTop: 100,
    width: paperWidth,
    paddingTop: 16,
    paddingBottom: 16,
    background: theme.palette.background.paper,
  }),
  button: {
    "&:hover": {
      background: "linear-gradient(45deg, #387f86 30%, #00bce4 90%)",
    },
    width: paperWidth,
    marginTop: 16,
    marginBottom: 25,
  },
  typography: {
    width: paperWidth,
  },
});

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submit: false,
      data: {
        first: "",
        last: "",
        email: "",
        password: "",
        passwordRetype: "",
      },
      errors: {},
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.validate = this.validate.bind(this);
  }

  componentWillMount() {
    localStorage.removeItem("user_id");
    localStorage.removeItem("authentication_token");
  }

  handleClick(event) {
    event.preventDefault();
    const { first, last, email, password, passwordRetype } = this.state.data;
    const errors = this.validate(this.state.data);
    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      if (password !== passwordRetype) {
        this.setState({ errors: { password: "*password fields don't match" } });
      } else {
        // eslint-disable-next-line
        const name = `${first} ${last}`;
        createUserMutation(
          name,
          email,
          crypto.createHash("sha256").update(password).digest("hex"),
          () => {
            this.setState({
              submit: true,
            });
            this.props.router.replace("/login");
            this.props.snackbar.showMessage(
              "You need admin approval, please wait for user validation"
            );
          }
        );
      }
    }
  }

  validate() {
    const errors = {};
    if (!Validator.isEmail(this.state.data.email))
      errors.email = "*invalid e-mail address";
    if (!Validator.isAlpha(this.state.data.first))
      errors.first = "*this field is mandatory, remove numbers if any";
    if (!Validator.isAlpha(this.state.data.last))
      errors.last = "*this field is mandatory, remove numbers if any";
    if (
      !Validator.isLength(this.state.data.password, { min: 8, max: undefined })
    )
      errors.password = "*please use minimum 8 characters";
    return errors;
  }

  handleChange(e) {
    e.preventDefault();
    this.setState({
      data: { ...this.state.data, [e.target.id]: e.target.value },
    });
  }

  render() {
    const { classes } = this.props;
    const { data, errors } = this.state;
    return (
      <LoginLogo>
        <form>
          <Paper className={classes.root} elevation={10}>
            <Typography variant="h5" component="h3" color="secondary">
              Register form
            </Typography>
            <TextField
              id="first"
              label="First name"
              className={classes.textField}
              value={data.first}
              onChange={this.handleChange}
              margin="normal"
            />
            <Typography variant="caption" color="error">
              {errors.first && <InlineError text={errors.first} />}
            </Typography>
            <TextField
              id="last"
              label="Last name"
              className={classes.textField}
              value={data.last}
              onChange={this.handleChange}
              margin="normal"
            />
            <Typography variant="caption" color="error">
              {errors.last && <InlineError text={errors.last} />}
            </Typography>
            <TextField
              id="email"
              label="Email"
              className={classes.textField}
              value={data.email}
              onChange={this.handleChange}
              margin="normal"
            />
            <Typography variant="caption" color="error">
              {errors.email && <InlineError text={errors.email} />}
            </Typography>
            <TextField
              id="password"
              label="Password"
              className={classes.textField}
              type="password"
              value={data.password}
              onChange={this.handleChange}
              margin="normal"
            />
            <Typography variant="caption" color="error">
              {errors.password && <InlineError text={errors.password} />}
            </Typography>
            <TextField
              id="passwordRetype"
              label="Retype password"
              className={classes.textField}
              type="password"
              value={data.passwordRetype}
              onChange={this.handleChange}
              margin="normal"
            />
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              onClick={this.handleClick}
            >
              <Input
                type="submit"
                onSubmit={this.handleClick}
                value="REGISTER"
                disableUnderline
                style={{ fontSize: "large" }}
              />
              <FingerprintIcon />
            </Button>
          </Paper>
        </form>
      </LoginLogo>
    );
  }
}

Register.propTypes = {
  classes: PropTypes.object.isRequired, //eslint-disable-line
  snackbar: PropTypes.object, //eslint-disable-line
  router: PropTypes.object, //eslint-disable-line
};

export default compose(withStyles(styles), withSnackbar())(Register);
