/**
 * @flow
 * @relayHash 77828bb8fd5c40f7b249c5d3aad629aa
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type editUsernameMutationVariables = {|
  userId: string,
  username: string,
|};
export type editUsernameMutationResponse = {|
  +editUsername: ?{|
    +id: string,
    +profile: ?{|
      +name: ?string
    |},
  |}
|};
export type editUsernameMutation = {|
  variables: editUsernameMutationVariables,
  response: editUsernameMutationResponse,
|};
*/


/*
mutation editUsernameMutation(
  $userId: String!
  $username: String!
) {
  editUsername(userId: $userId, username: $username) {
    id
    profile {
      name
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "userId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "username"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "userId",
        "variableName": "userId"
      },
      {
        "kind": "Variable",
        "name": "username",
        "variableName": "username"
      }
    ],
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "editUsername",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UserProfile",
        "kind": "LinkedField",
        "name": "profile",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "editUsernameMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "editUsernameMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "77828bb8fd5c40f7b249c5d3aad629aa",
    "metadata": {},
    "name": "editUsernameMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a0e0fa7a4b62b77474cc73e6211af093';

module.exports = node;
