/**
 * External dependencies
 */

import React, { useState, createContext } from "react";
export const CurrentMenuContext = createContext();

const MenuContextProvider = ({ children }) => {
  const [currentMenu, setCurrentMenu2] = useState();
  const [currentMenuExtension, setCurrentMenuExtension] = useState();

  const setCurrentMenu = (value) => {
    setCurrentMenu2(value);
    setCurrentMenuExtension();
  };

  return (
    <CurrentMenuContext.Provider
      value={{
        currentMenu,
        setCurrentMenu,
        currentMenuExtension,
        setCurrentMenuExtension,
      }}
    >
      {children}
    </CurrentMenuContext.Provider>
  );
};
export default MenuContextProvider;
