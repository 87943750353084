import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import compose from "recompose/compose";
import { withSnackbar } from "material-ui-snackbar-provider";
import updateUserRoleAndClientMutation from "../../mutations/updateUserRoleAndClientMutation";
import deleteUserMutation from "../../mutations/deleteUserMutation";
import roles from "../userRoles";
import permissions from "../../authentication/isPermited";

const styles = (theme) => ({
  root: {
    margin: "4px 0px 4px 0px",
  },
  card: {
    display: "flex",
    flexDirection: "column",
  },
  cardContent: {
    padding: 16,
  },
  button: {
    marginRight: theme.spacing(1),
  },
  horizontalContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  verticalContainer: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    margin: 4,
  },
  typo1: {
    paddingBottom: 4,
    display: "flex",
    alignItems: "center",
  },
  list: {
    flex: "auto",
    marginRight: 0,
    padding: 0,
  },
  button2: {
    marginRight: theme.spacing(1),
    alignSelf: "center",
  },
  menuItem: {
    fontSize: "0.85rem",
    boxSizing: "border-box",
  },
  actionsContainer: {
    marginTop: 2,
  },
  dialog: {
    background: "secondary",
    margin: theme.spacing(3),
  },
  dialogTitle: {
    padding: "0px 24px 0px 24px",
    minWidth: 250,
  },
  closeIcon: {
    height: 28,
    width: 28,
  },
  close: {
    position: "relative",
    float: "right",
  },
});

const arraySort = (a, b) => {
  try {
    const nameA = a.toUpperCase(); // ignore upper and lowercase
    const nameB = b.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    // names must be equal
    return 0;
  } catch (e) {
    console.log("UserActions:", e);
  }
};

class UserActions extends React.Component {
  constructor(props) {
    super(props);
    const { user } = this.props;
    this.state = {
      openDialog: false,
      anchorEl: null,
      anchorElVideo: null,
      assignedClientId: user.profile.clientId,
      assignedVideoClientId: user.profile.videoClientId,
      anchorElAccessRole: null,
      assignedAccessRole:
        user.rolesConsole && user.rolesConsole.roleAccess
          ? user.rolesConsole.roleAccess
          : 0,
      anchorElAudioRole: null,
      assignedAudioRole:
        user.rolesConsole && user.rolesConsole.roleAudio
          ? user.rolesConsole.roleAudio
          : 0,
      anchorElVideoRole: null,
      assignedVideoRole:
        user.rolesConsole && user.rolesConsole.roleVideo
          ? user.rolesConsole.roleVideo
          : 0,
      serviceTypeArray: user?.serviceType || [],
      defaultServiceType:
        user?.serviceType?.length === 1
          ? user?.serviceType[0]
          : user?.defaultServiceType,
    };
    this.handleClose = this.handleClose.bind(this);
    this.handleData = this.handleData.bind(this);
    this.handleDataClient = this.handleDataClient.bind(this);
    this.handleClickAnchor = this.handleClickAnchor.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
    this.handleDeleteUser = this.handleDeleteUser.bind(this);
    this.handleCloseDialog = this.handleCloseDialog.bind(this);
    this.handleChangeServiceType = this.handleChangeServiceType.bind(this);
    this.handleChangeDefaultServiceType =
      this.handleChangeDefaultServiceType.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.user !== nextProps.user || nextState !== this.state) {
      return true;
    }
    return false;
  }

  componentDidUpdate(prevProps) {
    const { user } = this.props;
    if (user.id !== prevProps.user.id) {
      this.setState({
        assignedClientId: user.profile.clientId,
        assignedVideoClientId: user.profile.videoClientId,
        serviceTypeArray: user?.serviceType || [],
        defaultServiceType:
          user?.serviceType?.length === 1
            ? user?.serviceType[0]
            : user?.defaultServiceType,
        assignedMyRole: user.roles ? user.roles[0] : "user-unauth",
        assignedAccessRole: user?.rolesConsole?.roleAccess || 0,
        assignedAudioRole: user?.rolesConsole?.roleAudio || 0,
        assignedVideoRole: user?.rolesConsole?.roleVideo || 0,
      });
    }
  }

  handleClose() {
    this.setState({
      anchorElAccessRole: null,
      anchorElAudioRole: null,
      anchorElVideoRole: null,
      anchorElMyRole: null,
      anchorEl: null,
      anchorElVideo: null,
    });
  }

  handleClickAnchor(anchor, event) {
    this.setState({ [anchor]: event.currentTarget });
  }

  handleChangeServiceType(service, serviceAvailable) {
    let newArray = [...this.state.serviceTypeArray];
    if (serviceAvailable) {
      newArray = newArray?.filter((item) => item !== service);
    } else {
      newArray.push(service);
    }
    this.setState({ serviceTypeArray: newArray });
  }

  handleChangeDefaultServiceType(event) {
    this.setState({ defaultServiceType: event.target.value });
  }

  handleData(role, id) {
    this.setState({ [role]: id });
    this.handleClose();
  }

  handleDataClient(client, state) {
    this.setState({ [state]: client });
    this.handleClose();
  }

  handleConfirm() {
    const { assignedAccessRole, assignedAudioRole, assignedVideoRole } =
      this.state;
    const roleArray = [
      assignedAccessRole,
      assignedAudioRole,
      assignedVideoRole,
    ];
    updateUserRoleAndClientMutation(
      this.props.user.id,
      roleArray,
      this.state.assignedClientId,
      this.state.assignedVideoClientId || "None",
      this.state.serviceTypeArray,
      this.state.defaultServiceType,
      (data) =>
        data
          ? this.props.snackbar.showMessage("Changes performed succesfully!")
          : this.props.snackbar.showMessage("User permissions update fail!")
    );
  }

  handleCloseDialog(bool) {
    this.setState({
      openDialog: bool,
    });
  }

  handleDeleteUser() {
    this.props.closeCallback();
    deleteUserMutation(this.props.user.id, () => {
      this.props.snackbar.showMessage("User Deleted");
    });
  }

  render() {
    const { classes, viewer, user } = this.props;
    const {
      anchorEl,
      anchorElVideo,
      anchorElAccessRole,
      anchorElAudioRole,
      anchorElVideoRole,
      assignedAccessRole,
      assignedAudioRole,
      assignedVideoRole,
      serviceTypeArray,
      defaultServiceType,
      openDialog,
      assignedClientId,
      assignedVideoClientId,
    } = this.state;

    const allClientsSorted = [...viewer.allClients.edges]?.sort((a, b) =>
      arraySort(a?.node?.name, b?.node?.name)
    );

    const allVideoClientsSorted = [...viewer.allVideoClients.edges]
      ?.filter((item) => item && item.node && item.node.name)
      .sort((a, b) => arraySort(a.node.name, b.node.name));
    return (
      <div className={classes.root}>
        <Card className={classes.card}>
          <CardContent className={classes.cardContent}>
            <Typography className={classes.typo1} variant="h6">
              User Actions
            </Typography>
            <Divider />
            <div className={classes.horizontalContainer}>
              {/* Audio Client select */}
              <List dense component="nav" className={classes.list}>
                <ListItem
                  button
                  aria-haspopup="true"
                  aria-controls="clients-menu"
                  aria-label="Clients"
                  onClick={(event) => this.handleClickAnchor("anchorEl", event)}
                  disabled={
                    user.id === viewer.user.id ||
                    viewer.user.rolesConsole.roleAccess !== 1
                  }
                >
                  <ListItemText
                    primary="Audio Client Name"
                    secondary={
                      allClientsSorted?.filter(
                        (client) =>
                          client.node && client.node.id === assignedClientId
                      )[0]
                        ? allClientsSorted?.filter(
                            (client) =>
                              client.node && client.node.id === assignedClientId
                          )[0].node.name
                        : "None"
                    }
                  />
                </ListItem>
              </List>
              <Menu
                id="lock-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={this.handleClose}
              >
                {viewer.allClients &&
                  allClientsSorted.map((client) =>
                    client.node ? (
                      <MenuItem
                        id={client.node.id}
                        className={classes.menuItem}
                        key={client.node.id}
                        dense
                        selected={client.node.id === assignedClientId}
                        value={client.node.name}
                        onClick={() =>
                          this.handleDataClient(
                            client.node.id,
                            "assignedClientId"
                          )
                        }
                      >
                        <b>{client.node.name}</b>
                      </MenuItem>
                    ) : null
                  )}
              </Menu>
              {/* Video Client select */}
              <List dense component="nav" className={classes.list}>
                <ListItem
                  button
                  aria-haspopup="true"
                  aria-controls="video-clients-menu"
                  aria-label="VideoClients"
                  onClick={(event) =>
                    this.handleClickAnchor("anchorElVideo", event)
                  }
                  disabled={
                    user.id === viewer.user.id ||
                    viewer.user.rolesConsole.roleAccess !== 1
                  }
                >
                  <ListItemText
                    primary="Video Client Name"
                    secondary={
                      allVideoClientsSorted?.filter(
                        (client) =>
                          client.node &&
                          client.node.id === assignedVideoClientId
                      )[0]
                        ? allVideoClientsSorted?.filter(
                            (client) =>
                              client.node &&
                              client.node.id === assignedVideoClientId
                          )[0].node.name
                        : "None"
                    }
                  />
                </ListItem>
              </List>
              <Menu
                id="lock-menu"
                anchorEl={anchorElVideo}
                open={Boolean(anchorElVideo)}
                onClose={this.handleClose}
              >
                <MenuItem
                  className={classes.menuItem}
                  dense
                  value="None"
                  onClick={() =>
                    this.handleDataClient("None", "assignedVideoClientId")
                  }
                >
                  <b>None</b>
                </MenuItem>
                {viewer.allVideoClients &&
                  allVideoClientsSorted.map((client) =>
                    client.node ? (
                      <MenuItem
                        id={client.node.id}
                        className={classes.menuItem}
                        key={client.node.id}
                        dense
                        selected={client.node.id === assignedVideoClientId}
                        value={client.node.name}
                        onClick={() =>
                          this.handleDataClient(
                            client.node.id,
                            "assignedVideoClientId"
                          )
                        }
                      >
                        <b>{client.node.name}</b>
                      </MenuItem>
                    ) : null
                  )}
              </Menu>
            </div>
            <Divider />
            <div className={classes.horizontalContainer}>
              {/* Access Role select */}
              <List dense component="nav" className={classes.list}>
                <ListItem
                  button
                  aria-haspopup="true"
                  aria-controls="accessRole-menu"
                  aria-label="AccessRole"
                  onClick={(event) =>
                    this.handleClickAnchor("anchorElAccessRole", event)
                  }
                  disabled={
                    user.id === viewer.user.id ||
                    viewer.user.rolesConsole.roleAccess >
                      (user.rolesConsole && user.rolesConsole.roleAccess !== 0
                        ? user.rolesConsole.roleAccess
                        : 1000)
                  }
                >
                  <ListItemText
                    primary="Access role"
                    secondary={
                      roles?.filter((role) => assignedAccessRole === role.id)[0]
                        .state
                    }
                  />
                </ListItem>
              </List>
              <Menu
                id="lock-menu"
                anchorEl={anchorElAccessRole}
                open={Boolean(anchorElAccessRole)}
                onClose={this.handleClose}
              >
                {roles
                  ?.filter(
                    (item) =>
                      (viewer.user.rolesConsole.roleAccess > 0 &&
                        viewer.user.rolesConsole.roleAccess < item.id) ||
                      item.id === 0 ||
                      (viewer.user.rolesConsole.roleAccess === 1 &&
                        item.id === 1)
                  )
                  .map((item) => (
                    <MenuItem
                      id={item.id}
                      className={classes.menuItem}
                      key={item.id}
                      dense
                      value={item.state}
                      onClick={() =>
                        this.handleData("assignedAccessRole", item.id)
                      }
                    >
                      <b>{item.state}</b>
                    </MenuItem>
                  ))}
              </Menu>
              {/* Audio Role select */}
              <List dense component="nav" className={classes.list}>
                <ListItem
                  button
                  aria-haspopup="true"
                  aria-controls="audioRole-menu"
                  aria-label="AudioRole"
                  onClick={(event) =>
                    this.handleClickAnchor("anchorElAudioRole", event)
                  }
                  disabled={
                    user.id === viewer.user.id ||
                    viewer.user.rolesConsole.roleAudio >
                      (user.rolesConsole && user.rolesConsole.roleAudio !== 0
                        ? user.rolesConsole.roleAudio
                        : 1000)
                  }
                >
                  <ListItemText
                    primary="Audio role"
                    secondary={
                      roles?.filter((role) => assignedAudioRole === role.id)[0]
                        .state
                    }
                  />
                </ListItem>
              </List>
              <Menu
                id="lock-menu"
                anchorEl={anchorElAudioRole}
                open={Boolean(anchorElAudioRole)}
                onClose={this.handleClose}
              >
                {roles
                  ?.filter(
                    (item) =>
                      (viewer.user.rolesConsole.roleAudio > 0 &&
                        viewer.user.rolesConsole.roleAudio < item.id) ||
                      item.id === 0 ||
                      (viewer.user.rolesConsole.roleAudio === 1 &&
                        item.id === 1)
                  )
                  .map((item) => (
                    <MenuItem
                      id={item.id}
                      className={classes.menuItem}
                      key={item.id}
                      dense
                      value={item.state}
                      onClick={() =>
                        this.handleData("assignedAudioRole", item.id)
                      }
                    >
                      <b>{item.state}</b>
                    </MenuItem>
                  ))}
              </Menu>
              {/* Video Role select */}
              <List dense component="nav" className={classes.list}>
                <ListItem
                  button
                  aria-haspopup="true"
                  aria-controls="videoRole-menu"
                  aria-label="VideoRole"
                  onClick={(event) =>
                    this.handleClickAnchor("anchorElVideoRole", event)
                  }
                  disabled={
                    user.id === viewer.user.id ||
                    viewer.user.rolesConsole.roleVideo >
                      (user.rolesConsole && user.rolesConsole.roleVideo !== 0
                        ? user.rolesConsole.roleVideo
                        : 1000)
                  }
                >
                  <ListItemText
                    primary="Video role"
                    secondary={
                      roles?.filter((role) => assignedVideoRole === role.id)[0]
                        .state
                    }
                  />
                </ListItem>
              </List>
              <Menu
                id="lock-menu"
                anchorEl={anchorElVideoRole}
                open={Boolean(anchorElVideoRole)}
                onClose={this.handleClose}
              >
                {roles
                  ?.filter(
                    (item) =>
                      (viewer.user.rolesConsole.roleVideo > 0 &&
                        viewer.user.rolesConsole.roleVideo < item.id) ||
                      item.id === 0 ||
                      (viewer.user.rolesConsole.roleVideo === 1 &&
                        item.id === 1)
                  )
                  .map((item) => (
                    <MenuItem
                      id={item.id}
                      className={classes.menuItem}
                      key={item.id}
                      dense
                      value={item.state}
                      onClick={() =>
                        this.handleData("assignedVideoRole", item.id)
                      }
                    >
                      <b>{item.state}</b>
                    </MenuItem>
                  ))}
              </Menu>
            </div>
            {permissions([1]) && (
              <>
                <Divider />
                <div className={classes.horizontalContainer}>
                  {/* Sonos/Curator Role select */}
                  <Typography
                    style={{
                      alignSelf: "center",
                      marginRight: 10,
                    }}
                    variant="body2"
                    color="textSecondary"
                  >
                    <b>Services available: </b>
                  </Typography>
                  <FormGroup row>
                    {["CURATOR", "SONOS", "QSYS"].map((item) => (
                      <FormControlLabel
                        key={item}
                        control={
                          <Checkbox
                            checked={serviceTypeArray.includes(item)}
                            onChange={() =>
                              this.handleChangeServiceType(
                                item,
                                serviceTypeArray.includes(item)
                              )
                            }
                            name={item}
                          />
                        }
                        label={item}
                      />
                    ))}
                  </FormGroup>
                  <Divider orientation="vertical" flexItem />
                  <Typography
                    style={{
                      alignSelf: "center",
                      marginLeft: 10,
                      marginRight: 10,
                    }}
                    variant="body2"
                    color="textSecondary"
                  >
                    <b>Default service: </b>
                  </Typography>
                  <FormControl component="fieldset">
                    <RadioGroup
                      row
                      aria-label="default-service"
                      name="defaultService"
                      value={defaultServiceType}
                      onChange={this.handleChangeDefaultServiceType}
                    >
                      <FormControlLabel
                        value="CURATOR"
                        control={<Radio />}
                        label="Curator"
                      />
                      <FormControlLabel
                        value="SONOS"
                        control={<Radio />}
                        label="Sonos"
                      />
                      <FormControlLabel
                        value="QSYS"
                        control={<Radio />}
                        label="Qsys"
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
              </>
            )}
            <Divider />
            <div className={classes.actionsContainer}>
              <Button
                variant="contained"
                color="primary"
                className={classes.button2}
                onClick={this.handleConfirm}
                disabled={
                  assignedClientId === null || user.id === viewer.user.id
                }
              >
                Confirm
              </Button>
              <Button
                variant="contained"
                color="primary"
                className={classes.button2}
                onClick={() => this.handleCloseDialog(true)}
                disabled={user.id === viewer.user.id}
              >
                Delete User
              </Button>
            </div>
          </CardContent>
          {assignedClientId ? null : (
            <Typography
              variant="caption"
              color="secondary"
              style={{ paddingLeft: 8 }}
              align="left"
            >
              Tip: Assign Client to user before confirm
            </Typography>
          )}
          <Dialog open={openDialog} className={classes.dialog}>
            <div className={classes.close}>
              <IconButton aria-label="Close" className={classes.close}>
                <CloseIcon
                  className={classes.closeIcon}
                  onClick={() => this.handleCloseDialog(false)}
                />
              </IconButton>
            </div>
            <DialogTitle className={classes.dialogTitle}>
              Delete this user? Are you sure?
            </DialogTitle>
            <div className={classes.dialog}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => this.handleCloseDialog(false)}
                className={classes.button}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={this.handleDeleteUser}
                className={classes.button}
              >
                Confirm Delete
              </Button>
            </div>
          </Dialog>
        </Card>
      </div>
    );
  }
}

UserActions.defaultProps = {};

UserActions.propTypes = {
  classes: PropTypes.object.isRequired, //eslint-disable-line
  viewer: PropTypes.object.isRequired, //eslint-disable-line
  user: PropTypes.object.isRequired, //eslint-disable-line
  closeCallback: PropTypes.func, //eslint-disable-line
};

export default compose(withStyles(styles), withSnackbar())(UserActions);
