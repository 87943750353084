import { commitMutation } from "react-relay";
import graphql from 'babel-plugin-relay/macro';
import Environment from "../../environmentLogin";

const mutation = graphql`
  mutation handleFacebookMutation(
    $inputname: String!
    $inputemail: String!
    $inputFacebookData: FacebookData!
  ) {
    handleFacebook(
      name: $inputname
      email: $inputemail
      facebookData: $inputFacebookData
    ) {
      token
      user {
        id
        profile {
          clientId
          videoClientId
        }
      }
    }
  }
`;

export default (name, email, FacebookData, callback) => {
  const variables = {
    inputname: name,
    inputemail: email,
    inputFacebookData: FacebookData,
    clientMutationId: "",
  };

  commitMutation(Environment, {
    mutation,
    variables,
    onCompleted: (response) => {
      if (
        response &&
        response.handleFacebook &&
        response.handleFacebook.token
      ) {
        const id = response.handleFacebook.user.id;
        const token = response.handleFacebook.token;
        const audioClientId = response.handleFacebook.user.profile.clientId;
        const videoClientId =
          response.handleFacebook.user.profile.videoClientId || "none";
        callback(id, token, audioClientId, videoClientId);
      } else {
        callback("error");
      }
    },
  });
};
