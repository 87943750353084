/**
 * @flow
 * @relayHash f2905c052e2f3aa1c894a740a67b2a91
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type _deviceStatusType = "ACTIVE" | "INACTIVE" | "REGISTERED" | "UNREGISTERED" | "%future added value";
export type statusAudioDeviceMutationVariables = {|
  inputid: string,
  inputStatus: _deviceStatusType,
|};
export type statusAudioDeviceMutationResponse = {|
  +statusAudioDevice: ?{|
    +id: string,
    +deviceStatus: ?_deviceStatusType,
  |}
|};
export type statusAudioDeviceMutation = {|
  variables: statusAudioDeviceMutationVariables,
  response: statusAudioDeviceMutationResponse,
|};
*/


/*
mutation statusAudioDeviceMutation(
  $inputid: String!
  $inputStatus: _deviceStatusType!
) {
  statusAudioDevice(id: $inputid, deviceStatus: $inputStatus) {
    id
    deviceStatus
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "inputStatus"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "inputid"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "deviceStatus",
        "variableName": "inputStatus"
      },
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "inputid"
      }
    ],
    "concreteType": "AudioDevice",
    "kind": "LinkedField",
    "name": "statusAudioDevice",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "deviceStatus",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "statusAudioDeviceMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "statusAudioDeviceMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "id": "f2905c052e2f3aa1c894a740a67b2a91",
    "metadata": {},
    "name": "statusAudioDeviceMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '62b0a1fbc2c70fb5a71b4c143c3b686f';

module.exports = node;
