import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const styles = (theme) => ({
  dialog: {
    background: "secondary",
  },
  dialogTitle: {
    padding: "0px 24px 0px 24px",
    minWidth: 300,
  },
  formControl: {
    margin: theme.spacing(3),
    minWidth: 150,
  },
});

class AudioClientEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        name: this.props?.data?.name,
        selectedFile: this.props.data?.logo || "",
        useExplicit: !!this.props.data?.useExplicit,
        noVolumeBar: !!this.props.data?.noVolumeBar,
        noPlayPause: !!this.props.data?.noPlayPause,
      },
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleChange2 = this.handleChange2.bind(this);
    this.handleUseExplicit = this.handleUseExplicit.bind(this);
    this.handleNoVolumeBar = this.handleNoVolumeBar.bind(this);
    this.handleNoPlayPause = this.handleNoPlayPause.bind(this);
  }

  componentDidMount() {
    this.props.myCallBack(this.state.data);
  }

  handleChange(e) {
    this.setState({
      data: { ...this.state.data, [e.target.id]: e.target.value },
    });
    this.props.myCallBack({
      ...this.state.data,
      [e.target.id]: e.target.value,
    });
  }

  handleChange2(e) {
    this.setState({
      data: { ...this.state.data, selectedFile: e.target.files[0] },
    });
    this.props.myCallBack({
      ...this.state.data,
      selectedFile: e.target.files[0],
    });
  }

  handleUseExplicit() {
    const useExplicit = !this.state.data?.useExplicit;
    this.setState({
      data: { ...this.state.data, useExplicit },
    });
    this.props.myCallBack({
      ...this.state.data,
      useExplicit,
    });
  }

  handleNoVolumeBar() {
    const noVolumeBar = !this.state.data?.noVolumeBar;
    this.setState({
      data: { ...this.state.data, noVolumeBar },
    });
    this.props.myCallBack({
      ...this.state.data,
      noVolumeBar,
    });
  }

  handleNoPlayPause() {
    const noPlayPause = !this.state.data?.noPlayPause;
    this.setState({
      data: { ...this.state.data, noPlayPause },
    });
    this.props.myCallBack({
      ...this.state.data,
      noPlayPause,
    });
  }

  render() {
    const { classes, logo } = this.props;
    const { selectedFile } = this.state.data;
    return (
      <div>
        <DialogTitle className={classes.dialogTitle}>
          Edit audio client
        </DialogTitle>
        <FormControl className={classes.formControl}>
          <TextField
            id="name"
            label="Name"
            value={this.state.data.name}
            onChange={this.handleChange}
            margin="normal"
          />
          {logo && logo !== "" && (
            <div>
              {" "}
              <p style={{ fontSize: "x-small", marginTop: 8, marginBottom: 8 }}>
                Actual logo:
              </p>
              <img src={logo} alt={"Client logo"} style={{ height: 60 }} />{" "}
            </div>
          )}
          <label htmlFor="contained-button-file">
            <Input
              accept="image/*"
              id="contained-button-file"
              type="file"
              style={{ display: "none" }}
              onChange={this.handleChange2}
            />
            <Button
              variant="contained"
              component="span"
              color="primary"
              onClick={this.fileUpload}
              style={{
                marginTop: 8,
                marginBottom: 8,
              }}
            >
              update logo
            </Button>
            <p style={{ fontSize: "x-small" }}>Recommended size: 165x50</p>
            <p style={{ fontSize: "x-small" }}>
              {selectedFile && selectedFile.name}
            </p>
          </label>
          <FormControlLabel
            id="use-explicit"
            className={classes.formControlLabel}
            control={
              <Checkbox
                checked={this.state.data.useExplicit}
                onClick={this.handleUseExplicit}
                value="useExplicit"
              />
            }
            label="Use explicit content for this Client?"
          />
          <FormControlLabel
            id="no-volume-bar"
            className={classes.formControlLabel}
            control={
              <Checkbox
                checked={this.state.data.noVolumeBar}
                onClick={this.handleNoVolumeBar}
                value="noVolumeBar"
              />
            }
            label="Remove volume bar for Curator users"
          />
          <FormControlLabel
            id="no-play-pause"
            className={classes.formControlLabel}
            control={
              <Checkbox
                checked={this.state.data.noPlayPause}
                onClick={this.handleNoPlayPause}
                value="noPlayPause"
              />
            }
            label="Remove play/pause button for users"
          />
        </FormControl>
      </div>
    );
  }
}

AudioClientEdit.propTypes = {
  classes: PropTypes.object.isRequired, //eslint-disable-line
  onRequestClose: PropTypes.func, //eslint-disable-line
  data: PropTypes.object, //eslint-disable-line
  myCallBack: PropTypes.func, //eslint-disable-line
};

export default withStyles(styles)(AudioClientEdit);
