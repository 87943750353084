/**
 * @flow
 * @relayHash 3514de57e8692d5f47a67592c71231a0
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type createScheduleProviderData = {|
  name: string,
  description?: ?string,
  injectable: boolean,
  startDate: string,
  endDate: string,
  clientId: string,
  size: number,
  playingPlaylists: $ReadOnlyArray<?PlayingPlaylistProviderData>,
|};
export type PlayingPlaylistProviderData = {|
  playlistId: string,
  name: string,
  volume?: ?string,
  startTime: number,
  endTime: number,
  startTimeSeconds: number,
  endTimeSeconds: number,
  days?: ?$ReadOnlyArray<?boolean>,
  shuffle: boolean,
  filenames?: ?$ReadOnlyArray<?filenamesProviderData>,
|};
export type filenamesProviderData = {|
  nrOfSongs?: ?string,
  id?: ?string,
|};
export type createScheduleMutationVariables = {|
  input: createScheduleProviderData
|};
export type createScheduleMutationResponse = {|
  +createSchedule: ?{|
    +id: string,
    +name: ?string,
    +description: ?string,
    +injectable: ?boolean,
    +startDate: ?string,
    +endDate: ?string,
    +clientId: ?string,
    +size: ?number,
    +playingPlaylists: ?$ReadOnlyArray<?{|
      +playlistId: ?string,
      +name: ?string,
      +volume: ?string,
      +startTime: ?number,
      +endTime: ?number,
      +startTimeSeconds: ?number,
      +endTimeSeconds: ?number,
      +days: ?$ReadOnlyArray<?boolean>,
      +shuffle: ?boolean,
      +filenames: ?$ReadOnlyArray<?{|
        +mixId: ?string,
        +nrOfSongs: ?string,
      |}>,
    |}>,
  |}
|};
export type createScheduleMutation = {|
  variables: createScheduleMutationVariables,
  response: createScheduleMutationResponse,
|};
*/


/*
mutation createScheduleMutation(
  $input: createScheduleProviderData!
) {
  createSchedule(createScheduleProvider: $input) {
    id
    name
    description
    injectable
    startDate
    endDate
    clientId
    size
    playingPlaylists {
      playlistId
      name
      volume
      startTime
      endTime
      startTimeSeconds
      endTimeSeconds
      days
      shuffle
      filenames {
        mixId
        nrOfSongs
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "createScheduleProvider",
        "variableName": "input"
      }
    ],
    "concreteType": "Schedule",
    "kind": "LinkedField",
    "name": "createSchedule",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "description",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "injectable",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "startDate",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "endDate",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "size",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "PlayingPlaylist",
        "kind": "LinkedField",
        "name": "playingPlaylists",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "playlistId",
            "storageKey": null
          },
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "volume",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "startTime",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "endTime",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "startTimeSeconds",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "endTimeSeconds",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "days",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "shuffle",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Filenames",
            "kind": "LinkedField",
            "name": "filenames",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "mixId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "nrOfSongs",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "createScheduleMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "createScheduleMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "id": "3514de57e8692d5f47a67592c71231a0",
    "metadata": {},
    "name": "createScheduleMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e46601012e2f670906a60cd05df12bf1';

module.exports = node;
