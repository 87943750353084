import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import compose from "recompose/compose";
import { withSnackbar } from "material-ui-snackbar-provider";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import editTagMutation from "../mutations/editTagMutation";

const styles = (theme) => ({
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    flex: "auto",
    minWidth: 100,
  },
  horizontalContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  verticalContainer: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
  },
  button: {
    margin: "8px 8px 8px 8px",
    maxWidth: 140,
  },
});

class editTag extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTag: this.props.selectedTag,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }

  handleChange(event, name) {
    this.setState({
      selectedTag: {
        ...this.state.selectedTag,
        [name]: event.target.value,
      },
    });
  }

  handleSave() {
    editTagMutation(
      this.state.selectedTag.id,
      this.state.selectedTag.name,
      this.state.selectedTag.description,
      () => {
        this.props.handleClose();
        return this.props.snackbar.showMessage("Tag edit successfully");
      }
    );
  }

  render() {
    const { classes } = this.props;
    const { selectedTag } = this.state;
    return (
      <div>
        <Typography variant="h6" id="modal-edit-title">
          {`Edit Tag - ${selectedTag.name}`}
        </Typography>
        <Divider />
        <form
          className={classes.verticalContainer}
          noValidate
          autoComplete="off"
        >
          <div className={classes.horizontalContainer}>
            <TextField
              id="name"
              label="Name"
              className={classes.textField}
              value={selectedTag.name}
              onChange={(event) => this.handleChange(event, "name")}
              margin="normal"
            />
            <TextField
              id="description"
              label="Description"
              className={classes.textField}
              value={selectedTag.description}
              onChange={(event) => this.handleChange(event, "description")}
              margin="normal"
            />
          </div>
          <Divider />
          <div className={classes.horizontalContainer}>
            <Button
              variant="contained"
              color="primary"
              onClick={this.handleSave}
              className={classes.button}
            >
              Save
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={this.props.handleClose}
              className={classes.button}
            >
              Cancel
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

editTag.propTypes = {
  classes: PropTypes.object.isRequired, //eslint-disable-line
  selectedTag: PropTypes.object.isRequired, //eslint-disable-line
  snackbar: PropTypes.object, //eslint-disable-line
  handleClose: PropTypes.func, //eslint-disable-line
};

export default compose(withStyles(styles), withSnackbar())(editTag);
