import { commitMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import Environment from "../../environment";

const mutation = graphql`
  mutation deleteQsysDeviceMutation($deviceId: String!) {
    deleteQsysDevice(deviceId: $deviceId)
  }
`;

export default (deviceId, callback) => {
  const variables = {
    deviceId,
  };

  commitMutation(Environment, {
    mutation,
    variables,
    onCompleted: (response) => {
      callback();
    },
    onError: (err) => console.error(err), // eslint-disable-line
    updater: (store, data) => {
      store.delete(data.deleteQsysDevice);
    },
  });
};
