/**
 * @flow
 * @relayHash d0b1c6b38ede02a02d55b6f61661c55e
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type togglePlaylistToClientMutationVariables = {|
  playlistIds: $ReadOnlyArray<string>,
  type: boolean,
  clientId: string,
|};
export type togglePlaylistToClientMutationResponse = {|
  +togglePlaylistToClient: ?{|
    +id: string,
    +name: ?string,
    +playlistIds: ?$ReadOnlyArray<?string>,
    +playbackIds: ?$ReadOnlyArray<?string>,
    +sceneIds: ?$ReadOnlyArray<?string>,
    +logo: ?string,
    +signedUrl: ?string,
    +preview: ?{|
      +previewId: ?string,
      +created: ?any,
      +ending: ?any,
      +isEnded: ?boolean,
      +isEndless: ?boolean,
    |},
    +viewableCategories: ?$ReadOnlyArray<?{|
      +name: string,
      +subCategories: ?$ReadOnlyArray<?string>,
    |}>,
  |}
|};
export type togglePlaylistToClientMutation = {|
  variables: togglePlaylistToClientMutationVariables,
  response: togglePlaylistToClientMutationResponse,
|};
*/


/*
mutation togglePlaylistToClientMutation(
  $playlistIds: [String!]!
  $type: Boolean!
  $clientId: String!
) {
  togglePlaylistToClient(playlistIds: $playlistIds, type: $type, clientId: $clientId) {
    id
    name
    playlistIds
    playbackIds
    sceneIds
    logo
    signedUrl
    preview {
      previewId
      created
      ending
      isEnded
      isEndless
    }
    viewableCategories {
      name
      subCategories
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "clientId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "playlistIds"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "type"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "clientId",
        "variableName": "clientId"
      },
      {
        "kind": "Variable",
        "name": "playlistIds",
        "variableName": "playlistIds"
      },
      {
        "kind": "Variable",
        "name": "type",
        "variableName": "type"
      }
    ],
    "concreteType": "AudioClient",
    "kind": "LinkedField",
    "name": "togglePlaylistToClient",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      (v3/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "playlistIds",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "playbackIds",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "sceneIds",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "logo",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "signedUrl",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Preview",
        "kind": "LinkedField",
        "name": "preview",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "previewId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "created",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "ending",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isEnded",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isEndless",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ViewableCategory",
        "kind": "LinkedField",
        "name": "viewableCategories",
        "plural": true,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "subCategories",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "togglePlaylistToClientMutation",
    "selections": (v4/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "togglePlaylistToClientMutation",
    "selections": (v4/*: any*/)
  },
  "params": {
    "id": "d0b1c6b38ede02a02d55b6f61661c55e",
    "metadata": {},
    "name": "togglePlaylistToClientMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '14bc7ec42dec6197fbca0dec2ab0d45a';

module.exports = node;
