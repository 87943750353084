/**
 * @flow
 * @relayHash 7487f5a35a79f5bebe763f4336bf6b2d
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type downloadSongReportMutationVariables = {|
  reportId: string
|};
export type downloadSongReportMutationResponse = {|
  +downloadSongReport: ?string
|};
export type downloadSongReportMutation = {|
  variables: downloadSongReportMutationVariables,
  response: downloadSongReportMutationResponse,
|};
*/


/*
mutation downloadSongReportMutation(
  $reportId: String!
) {
  downloadSongReport(reportId: $reportId)
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "reportId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "reportId",
        "variableName": "reportId"
      }
    ],
    "kind": "ScalarField",
    "name": "downloadSongReport",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "downloadSongReportMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "downloadSongReportMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "7487f5a35a79f5bebe763f4336bf6b2d",
    "metadata": {},
    "name": "downloadSongReportMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '50740ea4e1262557e1a5db6483f67954';

module.exports = node;
