/**
 * @flow
 * @relayHash 253671e25a77c92dbd4e34f5e37f6bcd
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type createTagProviderData = {|
  name: string,
  description?: ?string,
|};
export type editTagMutationVariables = {|
  inputId: string,
  input: createTagProviderData,
|};
export type editTagMutationResponse = {|
  +editTag: ?{|
    +id: string,
    +name: string,
    +description: ?string,
  |}
|};
export type editTagMutation = {|
  variables: editTagMutationVariables,
  response: editTagMutationResponse,
|};
*/


/*
mutation editTagMutation(
  $inputId: String!
  $input: createTagProviderData!
) {
  editTag(id: $inputId, createTagProvider: $input) {
    id
    name
    description
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "inputId"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "createTagProvider",
        "variableName": "input"
      },
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "inputId"
      }
    ],
    "concreteType": "Tag",
    "kind": "LinkedField",
    "name": "editTag",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "description",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "editTagMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "editTagMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "id": "253671e25a77c92dbd4e34f5e37f6bcd",
    "metadata": {},
    "name": "editTagMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a85d623922211572ce08f1fd75e2ac0c';

module.exports = node;
