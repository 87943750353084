/**
 * @flow
 * @relayHash bfeb544abb72792514b5572f2d807c20
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type FacebookData = {|
  accessToken?: ?string,
  expiresAt?: ?string,
  id?: ?string,
  email?: ?string,
  name?: ?string,
  first_name?: ?string,
  last_name?: ?string,
  link?: ?string,
  gender?: ?string,
  locale?: ?string,
  verified?: ?boolean,
|};
export type handleFacebookMutationVariables = {|
  inputname: string,
  inputemail: string,
  inputFacebookData: FacebookData,
|};
export type handleFacebookMutationResponse = {|
  +handleFacebook: {|
    +token: ?string,
    +user: ?{|
      +id: string,
      +profile: ?{|
        +clientId: ?string,
        +videoClientId: ?string,
      |},
    |},
  |}
|};
export type handleFacebookMutation = {|
  variables: handleFacebookMutationVariables,
  response: handleFacebookMutationResponse,
|};
*/


/*
mutation handleFacebookMutation(
  $inputname: String!
  $inputemail: String!
  $inputFacebookData: FacebookData!
) {
  handleFacebook(name: $inputname, email: $inputemail, facebookData: $inputFacebookData) {
    token
    user {
      id
      profile {
        clientId
        videoClientId
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "inputFacebookData"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "inputemail"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "inputname"
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "email",
        "variableName": "inputemail"
      },
      {
        "kind": "Variable",
        "name": "facebookData",
        "variableName": "inputFacebookData"
      },
      {
        "kind": "Variable",
        "name": "name",
        "variableName": "inputname"
      }
    ],
    "concreteType": "SigninPayload",
    "kind": "LinkedField",
    "name": "handleFacebook",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "token",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UserProfile",
            "kind": "LinkedField",
            "name": "profile",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "clientId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "videoClientId",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "handleFacebookMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "handleFacebookMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "id": "bfeb544abb72792514b5572f2d807c20",
    "metadata": {},
    "name": "handleFacebookMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9911cb7721c025fda984b2fab71a81f1';

module.exports = node;
