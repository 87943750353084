/**
 * @flow
 * @relayHash d1563a8dd22a7110f2b7b5a1a112d06e
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ProfileDetailsInput = {|
  id: string,
  name?: ?string,
  format?: ?string,
  volume?: ?number,
  url?: ?string,
  syncTime?: ?string,
  rebootTime?: ?string,
  clientId?: ?string,
|};
export type updateVideoDeviceProfileMutationVariables = {|
  deviceId: string,
  profile: ProfileDetailsInput,
  sync: boolean,
|};
export type updateVideoDeviceProfileMutationResponse = {|
  +updateVideoDeviceProfile: ?{|
    +id: string,
    +name: ?string,
    +uid: ?string,
    +description: ?string,
    +macAddress: ?string,
    +currentlyPlaying: ?string,
    +volume: ?number,
    +lastSync: ?string,
    +lanMacAddress: ?string,
    +internalIp: ?string,
    +publicIp: ?string,
    +lastOnline: ?number,
    +appVersion: ?string,
    +reboot: ?boolean,
    +takeScreenShot: ?boolean,
    +sync: ?boolean,
    +format: ?string,
    +screenShot: ?string,
    +uploadLogs: ?boolean,
    +model: ?string,
    +serialNumber: ?string,
    +usedStorage: ?string,
    +totalStorage: ?string,
    +availableStorage: ?string,
    +hdmiStatus: ?boolean,
    +downloading: ?string,
    +appUpTime: ?string,
    +isPlaying: ?boolean,
    +clientId: ?string,
    +profile: ?{|
      +profileId: ?string,
      +name: ?string,
      +format: ?string,
      +volume: ?number,
      +url: ?string,
      +syncTime: ?string,
      +rebootTime: ?string,
      +clientId: ?string,
    |},
  |}
|};
export type updateVideoDeviceProfileMutation = {|
  variables: updateVideoDeviceProfileMutationVariables,
  response: updateVideoDeviceProfileMutationResponse,
|};
*/


/*
mutation updateVideoDeviceProfileMutation(
  $deviceId: String!
  $profile: ProfileDetailsInput!
  $sync: Boolean!
) {
  updateVideoDeviceProfile(deviceId: $deviceId, profile: $profile, sync: $sync) {
    id
    name
    uid
    description
    macAddress
    currentlyPlaying
    volume
    lastSync
    lanMacAddress
    internalIp
    publicIp
    lastOnline
    appVersion
    reboot
    takeScreenShot
    sync
    format
    screenShot
    uploadLogs
    model
    serialNumber
    usedStorage
    totalStorage
    availableStorage
    hdmiStatus
    downloading
    appUpTime
    isPlaying
    clientId
    profile {
      profileId
      name
      format
      volume
      url
      syncTime
      rebootTime
      clientId
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "deviceId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "profile"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "sync"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "volume",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "format",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "clientId",
  "storageKey": null
},
v5 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "deviceId",
        "variableName": "deviceId"
      },
      {
        "kind": "Variable",
        "name": "profile",
        "variableName": "profile"
      },
      {
        "kind": "Variable",
        "name": "sync",
        "variableName": "sync"
      }
    ],
    "concreteType": "VideoDevice",
    "kind": "LinkedField",
    "name": "updateVideoDeviceProfile",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "uid",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "description",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "macAddress",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "currentlyPlaying",
        "storageKey": null
      },
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lastSync",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lanMacAddress",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "internalIp",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "publicIp",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lastOnline",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "appVersion",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "reboot",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "takeScreenShot",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "sync",
        "storageKey": null
      },
      (v3/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "screenShot",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "uploadLogs",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "model",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "serialNumber",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "usedStorage",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "totalStorage",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "availableStorage",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hdmiStatus",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "downloading",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "appUpTime",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isPlaying",
        "storageKey": null
      },
      (v4/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "ProfileDetails",
        "kind": "LinkedField",
        "name": "profile",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "profileId",
            "storageKey": null
          },
          (v1/*: any*/),
          (v3/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "url",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "syncTime",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "rebootTime",
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "updateVideoDeviceProfileMutation",
    "selections": (v5/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "updateVideoDeviceProfileMutation",
    "selections": (v5/*: any*/)
  },
  "params": {
    "id": "d1563a8dd22a7110f2b7b5a1a112d06e",
    "metadata": {},
    "name": "updateVideoDeviceProfileMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c0db78dde55aaa4ab10bf5e41a04ac39';

module.exports = node;
