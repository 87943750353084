import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Switch from "@material-ui/core/Switch";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import List from "@material-ui/core/List";
import Button from "@material-ui/core/Button";
import SceneListView from "./SceneListView";
import CategoriesModal from "./components/categoriesModal";
import toggleSceneToClientMutation from "../mutations/toggleSceneToClientMutation";

const styles = (theme) => ({
  root: {
    display: "block",
    width: "100%",
    maxHeight: window.innerHeight - 310,
    overflowY: "auto",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  formControl: {
    margin: "16px 8px 8px 8px",
    minWidth: 200,
  },
  button: {
    margin: "0px 0px 8px 16px",
    height: 40,
    alignSelf: "flex-end",
  },
  horizontalContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  typo3: {
    alignSelf: "center",
    paddingLeft: 16,
  },
  list: {
    flexWrap: "wrap",
    flexDirection: "column",
    height: 300,
    width: 300,
    borderRadius: 5,
    overflowY: "scroll",
  },
});

const arraySort = (a, b) => {
  try {
    const nameA = a?.toUpperCase(); // ignore upper and lowercase
    const nameB = b?.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    // names must be equal
    return 0;
  } catch (e) {
    console.log("Scene sorting:", e);
  }
};

class SceneList extends Component {
  constructor() {
    super();
    this.state = {
      searchField: "",
      displayNumber: 20,
      expanded: {},
      data: {
        id: {},
        name: {},
        description: {},
        shuffle: {},
        image: {},
        size: {},
        createdAt: {},
        playlists: {},
        clientIds: {},
        isPublic: {},
      },
      imageHash: Date.now(),
      sortBy: "Category",
      filterBy: "None",
      loadedCategories: 2,
      openModal: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleChange2 = this.handleChange2.bind(this);
    this.handleChange3 = this.handleChange3.bind(this);
    this.toggleScene = this.toggleScene.bind(this);
    this.handleChangeFilterType = this.handleChangeFilterType.bind(this);
    this.scrollCheck = this.scrollCheck.bind(this);
    this.toggleSetCategoriesModal = this.toggleSetCategoriesModal.bind(this);
  }

  componentDidMount() {
    const totalDisplayNumber = this.props.viewer.allScenes.edges.filter(
      (item) => item && item.node
    );
    this.setState({
      displayNumber: totalDisplayNumber.length,
    });
  }

  handleChange(name, event) {
    this.setState({ [name]: event.target.value });
  }
  handleChange2(event) {
    this.setState({ displayNumber: event.target.value });
  }
  handleChange3(event) {
    this.setState({ sortBy: event.target.value });
  }
  handleChangeFilterType(event) {
    this.setState({ filterBy: event.target.value });
  }

  toggleScene(category, type, allScenes) {
    const message = !type ? "Scenes removed" : "Scenes added";
    const sceneArray = allScenes.filter((item2) =>
      item2.node?.newCategory
        ?.map((item3) => item3.category)
        ?.includes(category)
    );
    const sceneIdsArray = sceneArray.map((item) => item.node.id);
    toggleSceneToClientMutation(sceneIdsArray, type, this.props.clientId, () =>
      console.log(message)
    );
  }

  scrollCheck(event) {
    const bottom =
      event.target.scrollHeight - event.target.scrollTop <=
      event.target.clientHeight + 4000;
    if (bottom) {
      this.setState({ loadedCategories: this.state.loadedCategories + 2 });
    }
  }

  toggleSetCategoriesModal() {
    this.setState({ openModal: !this.state.openModal });
  }

  render() {
    const { classes, viewer, clientId } = this.props;
    const {
      searchField,
      displayNumber,
      sortBy,
      filterBy,
      loadedCategories,
      openModal,
    } = this.state;

    const allScenes = viewer.allScenes?.edges
      .filter((item) => item?.node)
      .sort((a, b) => arraySort(a.node?.name, b.node?.name));

    const allCategories = viewer.allCategories?.edges
      .filter((item) => item?.node)
      .map((item) => item.node)
      .sort((a, b) => a.sortOrder - b.sortOrder);

    const allPlaylists = viewer.allPlaylists?.edges
      .filter((item) => item?.node)
      .map(({ node }) => node);

    const allPlaybacks = viewer.allPlaybacks?.edges
      .filter((item) => item?.node)
      .map(({ node }) => node);

    const allSchedule = viewer.allSchedule?.edges
      .filter((item) => item?.node)
      .map(({ node }) => node);

    const allAudioClients = viewer.allClients?.edges
      .filter((item) => item?.node)
      .map(({ node }) => node);

    const currentClient =
      allAudioClients.find((item) => item.id === clientId) || {};

    const allAudioDevices = viewer.allAudioDevices.edges
      .filter((item) => item && item.node)
      .map(({ node }) => node);

    const sortType = ["Name", "Category"];
    const filterType = ["None", "Featured"];

    const displayedCategories = currentClient?.id
      ? currentClient?.viewableCategories?.filter(
          (item) => item.subCategories.length > 0
        ) || []
      : allCategories;

    const sortSubType = {
      Name: [{ name: "Assigned to Client" }, { name: "Unassigned" }],
      Category: [...displayedCategories, { name: "Unassigned" }],
    };

    const noToDisplay = Math.ceil(allScenes.length / 20);
    let noToDisplayArray = [];
    for (let i = 0; i < noToDisplay; i += 1) {
      noToDisplayArray = noToDisplayArray.concat("");
    }

    const sortFilter = (subTypeItem, scene, subcategory) => {
      if (sortBy === "Name") {
        if (
          subTypeItem === "Assigned to Client" &&
          currentClient?.sceneIds?.includes(scene?.node?.id)
        ) {
          return true;
        }
        if (
          subTypeItem === "Unassigned" &&
          !currentClient?.sceneIds?.includes(scene?.node?.id)
        ) {
          return true;
        }
      }
      if (sortBy === "Category") {
        if (
          scene.node?.newCategory?.findIndex(
            (item) =>
              item.category === subTypeItem &&
              item.subCategories.includes(subcategory)
          ) !== -1
        ) {
          return true;
        }
        if (
          subTypeItem === "Unassigned" &&
          scene.node?.newCategory?.map((item) => item.category)?.length === 0
        ) {
          return true;
        }
      }
      return false;
    };

    const filterFunction = (item) => {
      switch (filterBy) {
        case "None":
          return true;
        case "Featured":
          return currentClient?.featuredIds?.includes(item?.node?.id);
        default:
          return true;
      }
    };

    return (
      <div
        className={classes.root}
        onScroll={(event) => this.scrollCheck(event)}
      >
        <form
          className={classes.horizontalContainer}
          noValidate
          autoComplete="off"
        >
          <TextField
            label="Search Scene"
            type="search"
            value={searchField}
            className={classes.textField}
            onChange={(event) => this.handleChange("searchField", event)}
            margin="normal"
          />
          <FormControl className={classes.formControl}>
            <InputLabel>Scene display number</InputLabel>
            <Select
              value={displayNumber}
              onChange={(event) => this.handleChange2(event)}
            >
              {noToDisplayArray.map((item, i) => (
                <MenuItem key={i} value={20 * (i + 1)}>
                  {20 * (i + 1)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel>Sort scene by</InputLabel>
            <Select
              value={sortBy}
              onChange={(event) => this.handleChange3(event)}
            >
              {sortType.map((item, i) => (
                <MenuItem key={i} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel>Filter scene by</InputLabel>
            <Select
              value={filterBy}
              onChange={(event) => this.handleChangeFilterType(event)}
            >
              {filterType.map((item, i) => (
                <MenuItem key={i} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button
            variant="outlined"
            color="primary"
            className={classes.button}
            onClick={this.toggleSetCategoriesModal}
            disabled={clientId === "ALL"}
          >
            Set Categories
          </Button>
        </form>
        {clientId && (
          <div
            style={{
              marginRight: 16,
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
            }}
          >
            <div
              className={classes.horizontalContainer}
              style={{
                alignItems: "center",
              }}
            >
              <p
                style={{
                  fontWeight: "bold",
                  color: "#c361e9",
                  fontSize: 19,
                  marginLeft: "12px",
                }}
              >
                Select all
              </p>
              <Switch
                edge="end"
                onChange={() =>
                  toggleSceneToClientMutation(
                    allScenes.map((item) => item.node.id),
                    currentClient?.sceneIds?.length >= allScenes.length,
                    clientId,
                    () => console.log("select all completed")
                  )
                }
                checked={currentClient?.sceneIds?.length >= allScenes.length}
                inputProps={{
                  "aria-labelledby": "switch-select-all-playlists",
                }}
              />
            </div>
            <div
              className={classes.horizontalContainer}
              style={{
                alignItems: "center",
                marginTop: -30,
              }}
            >
              <p
                style={{
                  fontWeight: "bold",
                  color: "#c361e9",
                  fontSize: 10,
                  marginLeft: "12px",
                }}
              >
                (Please proceed carefully)
              </p>
            </div>
          </div>
        )}
        {sortSubType[sortBy].map(
          (item, index) =>
            index < loadedCategories && (
              <div key={item.name} className={classes.verticalContainer}>
                <div
                  className={classes.horizontalContainer}
                  style={{
                    alignItems: "center",
                  }}
                >
                  <p
                    style={{
                      fontWeight: "bold",
                      color: "#1d1b1b",
                      fontSize: 21,
                      marginLeft: 12,
                    }}
                  >
                    {item.name}
                  </p>
                  <div
                    style={{
                      marginRight: 16,
                    }}
                  >
                    {sortBy === "Category" && item.name !== "Unassigned" && (
                      <Switch
                        edge="end"
                        onChange={() =>
                          this.toggleScene(
                            item.name,
                            allScenes.filter(
                              (item2) =>
                                item2.node?.newCategory
                                  ?.map((item3) => item3.category)
                                  ?.includes(item.name) &&
                                currentClient?.sceneIds?.includes(
                                  item2?.node?.id
                                )
                            ).length > 0,
                            allScenes
                          )
                        }
                        checked={
                          allScenes.filter(
                            (item2) =>
                              item2.node?.newCategory
                                ?.map((item3) => item3.category)
                                ?.includes(item.name) &&
                              currentClient?.sceneIds?.includes(item2?.node?.id)
                          ).length > 0
                        }
                        inputProps={{
                          "aria-labelledby": "switch-list-label-wifi",
                        }}
                      />
                    )}
                    {sortBy === "Category" && item.name === "Unassigned" && (
                      <Switch
                        edge="end"
                        onChange={() =>
                          this.toggleScene(
                            "",
                            allScenes.filter(
                              (item2) =>
                                item2.node?.category === "" &&
                                !currentClient?.sceneIds?.includes(
                                  item2?.node?.id
                                )
                            ).length > 0,
                            allScenes
                          )
                        }
                        checked={
                          allScenes.filter(
                            (item2) =>
                              !item2.node?.newCategory &&
                              item2.node?.newCategory.length === 0 &&
                              currentClient?.sceneIds?.includes(item2?.node?.id)
                          ).length > 0
                        }
                        inputProps={{
                          "aria-labelledby": "switch-list-label-wifi",
                        }}
                      />
                    )}
                  </div>
                </div>
                {sortBy === "Category" ? (
                  item?.subCategories?.map((subcategory) => (
                    <div
                      className={classes.horizontalContainer}
                      style={{
                        flexDirection: "column",
                      }}
                      key={subcategory}
                    >
                      <p
                        style={{
                          fontWeight: "bold",
                          color: "#1d1b1b",
                          fontSize: 17,
                          marginLeft: 20,
                        }}
                      >
                        {subcategory}
                      </p>
                      <List component="nav" aria-label="Scene List">
                        {allScenes
                          .filter((item2b) =>
                            item2b.node.name
                              .toLowerCase()
                              .includes(searchField.toLowerCase())
                          )
                          .slice(0, displayNumber)
                          .filter((item2b) =>
                            sortFilter(item.name, item2b, subcategory)
                          )
                          .filter((item2b) => filterFunction(item2b))
                          .map(({ node }) => (
                            <SceneListView
                              clientId={clientId}
                              checked={currentClient?.sceneIds?.includes(
                                node?.id
                              )}
                              featuredChecked={
                                currentClient?.featuredIds?.includes(
                                  node?.id
                                ) || false
                              }
                              viewer={viewer}
                              allPlaybacks={allPlaybacks}
                              allSchedule={allSchedule}
                              allAudioClients={allAudioClients}
                              allAudioDevices={allAudioDevices}
                              allPlaylists={allPlaylists}
                              node={node}
                              key={node.id}
                            />
                          ))}
                      </List>
                    </div>
                  ))
                ) : (
                  <List component="nav" aria-label="Scene List">
                    {allScenes
                      .filter((item2) =>
                        item2.node.name
                          .toLowerCase()
                          .includes(searchField.toLowerCase())
                      )
                      .slice(0, displayNumber)
                      .filter((item2) => sortFilter(item.name, item2))
                      .filter((item2) => filterFunction(item2))
                      .map(({ node }) => (
                        <SceneListView
                          clientId={clientId}
                          checked={currentClient?.sceneIds?.includes(node?.id)}
                          featuredChecked={
                            currentClient?.featuredIds?.includes(node?.id) ||
                            false
                          }
                          viewer={viewer}
                          allPlaybacks={allPlaybacks}
                          allSchedule={allSchedule}
                          allAudioClients={allAudioClients}
                          allAudioDevices={allAudioDevices}
                          allPlaylists={allPlaylists}
                          node={node}
                          key={node.id}
                        />
                      ))}
                  </List>
                )}
              </div>
            )
        )}
        <CategoriesModal
          openModal={openModal}
          callbackModal={this.toggleSetCategoriesModal}
          allCategories={allCategories}
          currentClient={currentClient}
        />
      </div>
    );
  }
}

SceneList.propTypes = {
  clientId: PropTypes.string.isRequired,
  viewer: PropTypes.object.isRequired, // eslint-disable-line
  classes: PropTypes.object.isRequired, // eslint-disable-line
};

export default withStyles(styles)(SceneList);
