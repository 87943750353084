/**
 * External dependencies
 */

import graphql from "babel-plugin-relay/macro";

export const ALL_CLIENTS = graphql`
  query allAudioClientsQuery {
    viewer {
      allClients {
        edges {
          node {
            id
            name
            playlistIds
            sceneIds
          }
        }
      }
    }
  }
`;
