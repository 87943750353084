/* eslint-disable no-underscore-dangle, no-return-assign */

import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import ReactTable from "react-table";
import checkboxHOC from "react-table/lib/hoc/selectTable";
import "react-table/react-table.css";
import compose from "recompose/compose";
import { withSnackbar } from "material-ui-snackbar-provider";
import Typography from "@material-ui/core/Typography";
import _ from "lodash";

import updateAudioDeviceUsersMutation from "../../mutations/updateAudioDeviceUsersMutation";

const CheckboxTable = checkboxHOC(ReactTable);

const styles = (theme) => ({
  root: {
    margin: "4px 0px 4px 0px",
  },
  card: {
    display: "flex",
    flexDirection: "column",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  horizontalContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  verticalContainer: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    margin: 4,
  },
  typo1: {
    paddingBottom: 4,
    display: "flex",
    alignItems: "center",
  },
  button2: {
    margin: "2px 0px 2px 0px",
  },
});

class UserDevicePermissions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selection: [],
      initialSelection: [],
      selectAll: false,
    };
    this.toggleSelection = this.toggleSelection.bind(this);
    this.toggleAll = this.toggleAll.bind(this);
    this.isSelected = this.isSelected.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    // if (this.props.user !== nextProps.user || nextState !== this.state) {
    //   return true;
    // }
    return true;
  }

  componentDidMount() {
    const data = this.props.viewer.allAudioDevices.edges
      .filter((item) => item && item.node)
      .filter((item) => item.node.deviceStatus === "ACTIVE")
      .map((item) => item.node);
    const filteredData = [...data]?.filter(
      (device) => device.clientId === this.props.user.profile.clientId
    );
    const selection = filteredData
      ?.filter((device) => {
        return device.usersId
          ? _.intersectionBy(device.usersId, [this.props.user], "id").length
          : false;
      })
      .map((device) => device.id);
    this.setState({ selection, initialSelection: selection });
  }

  componentDidUpdate(prevProps) {
    if (this.props.user !== prevProps.user) {
      const data = this.props.viewer.allAudioDevices.edges
        .filter((item) => item?.node)
        .filter((item) => item.node.deviceStatus === "ACTIVE")
        .map((item) => item.node);

      const filteredData = [...data]?.filter(
        (device) => device.clientId === this.props.user.profile.clientId
      );
      const selection = filteredData
        ?.filter((device) => {
          return device.usersId
            ? _.intersectionBy(device.usersId, [this.props.user], "id").length
            : false;
        })
        .map((device) => device.id);
      this.setState({ selection, initialSelection: selection });
    }
  }

  toggleSelection(bruteKey /* , shift, row */) {
    const key = bruteKey.replace("select-", "");
    let selection = [...this.state.selection];
    const keyIndex = selection.indexOf(key);
    if (keyIndex >= 0) {
      selection = [
        ...selection.slice(0, keyIndex),
        ...selection.slice(keyIndex + 1),
      ];
    } else {
      selection.push(key);
    }
    this.setState({ selection });
  }

  toggleAll() {
    const selectAll = !this.state.selectAll;
    const selection = [];
    if (selectAll) {
      const wrappedInstance = this.checkboxTable.getWrappedInstance();
      const currentRecords = wrappedInstance.getResolvedState().sortedData;
      currentRecords.forEach((item) => {
        selection.push(item._original.id);
      });
    }
    this.setState({ selectAll, selection });
  }

  isSelected(key) {
    return this.state.selection.includes(key);
  }

  handleConfirm() {
    const { initialSelection, selection } = this.state;
    selection.map((device) => {
      if (!initialSelection.includes(device)) {
        return updateAudioDeviceUsersMutation(
          device,
          this.props.user.id,
          "ADD",
          (id) => console.log("ADDED ID:", id)
        );
      }
      return null;
    });
    initialSelection.map((device) => {
      if (!selection.includes(device)) {
        return updateAudioDeviceUsersMutation(
          device,
          this.props.user.id,
          "REMOVE",
          (id) => console.log("REMOVED ID:", id)
        );
      }
      return null;
    });
    this.setState({ initialSelection: selection });
    this.props.snackbar.showMessage("Users's device permissions changed!");
  }

  render() {
    const { classes, user } = this.props;

    const columns = [
      {
        id: "name",
        Header: "Name",
        accessor: (a) => a.name,
        maxWidth: 500,
        width: 200,
      },
      {
        id: "selection",
        Header: "Assigned",
        accessor: (a) =>
          a.usersId &&
          a.usersId.find((item) => item?.id === this.props?.user?.id)
            ? "Yes"
            : "No",
        maxWidth: 100,
      },
      {
        id: "isOnline",
        Header: "Online",
        accessor: (a) => (a.isOnline ? "Yes" : "No"),
        maxWidth: 70,
      },
      {
        id: "isPlaying",
        Header: "Playing",
        accessor: (a) => (a.isPlaying ? "Yes" : "No"),
        maxWidth: 70,
      },
      {
        Header: "Public IP",
        accessor: "publicIpAddress",
        maxWidth: 1000,
      },
    ];

    const { toggleSelection, toggleAll, isSelected } = this;
    const { selectAll } = this.state;

    const checkboxProps = {
      selectAll,
      isSelected,
      toggleSelection,
      toggleAll,
      selectType: "checkbox",
    };

    const data = this.props.viewer.allAudioDevices.edges
      .filter((item) => item && item.node)
      .filter((item) => item.node.deviceStatus === "ACTIVE")
      .map((item) => item.node);

    const filteredData = [...data]?.filter(
      (device) => device.clientId === this.props.user.profile.clientId
    );

    return (
      <div className={classes.root}>
        {![1, 2].includes(
          user.rolesConsole ? user.rolesConsole.roleAudio : 0
        ) ? (
          <Card className={classes.card}>
            <CardContent>
              <Typography className={classes.typo1} variant="h6">
                {"User's Device Permissions"}
              </Typography>
              <CheckboxTable
                ref={(r) => (this.checkboxTable = r)}
                keyField="id"
                data={filteredData}
                columns={columns}
                filterable
                defaultFilterMethod={(filter, row) =>
                  String(row[filter.id])
                    .toLowerCase()
                    .includes(filter.value.toLowerCase())
                }
                defaultPageSize={10}
                className="-striped -highlight"
                style={{
                  display: "block",
                  fontSize: "12px",
                  flex: "auto",
                }}
                {...checkboxProps}
              />
              <Button
                variant="contained"
                color="primary"
                className={classes.button2}
                onClick={this.handleConfirm}
              >
                Confirm
              </Button>
            </CardContent>
          </Card>
        ) : (
          <Card className={classes.card}>
            <CardContent>
              <Typography className={classes.typo1} variant="h6">
                {"User's Device Permissions"}
              </Typography>
              <Typography className={classes.typo1} variant="body1">
                {"This type of user has access to all devices"}
              </Typography>
            </CardContent>
          </Card>
        )}
      </div>
    );
  }
}

UserDevicePermissions.defaultProps = {};

UserDevicePermissions.propTypes = {
  classes: PropTypes.object.isRequired, //eslint-disable-line
  user: PropTypes.object.isRequired, //eslint-disable-line
  viewer: PropTypes.object.isRequired, //eslint-disable-line
  snackbar: PropTypes.object, //eslint-disable-line
};

export default compose(
  withStyles(styles),
  withSnackbar()
)(UserDevicePermissions);
