/**
 * @flow
 * @relayHash 13c428d36da7bff408ec9ef175656cbd
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type _deviceStatusType = "ACTIVE" | "INACTIVE" | "REGISTERED" | "UNREGISTERED" | "%future added value";
export type _statusType = "COMPLETED" | "RECEIVED" | "SENT" | "%future added value";
export type DeviceLogsQueryVariables = {||};
export type DeviceLogsQueryResponse = {|
  +viewer: {|
    +allAudioDevices: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: {|
          +id: string,
          +name: ?string,
          +uid: ?string,
          +isOnline: ?boolean,
          +deviceStatus: ?_deviceStatusType,
          +appVersionDS: ?string,
        |}
      |}>
    |},
    +allDsBlobs: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: {|
          +id: string,
          +deviceId: ?string,
          +randomBlob: string,
          +deviceTimestamp: ?number,
          +serverTimestamp: ?number,
          +deviceUid: string,
        |}
      |}>
    |},
    +allDeviceLogs: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: {|
          +deviceId: ?string,
          +log: string,
          +deviceTimestamp: ?number,
          +serverTimestamp: ?number,
          +deviceUid: string,
          +isLogging: ?boolean,
        |}
      |}>
    |},
    +allEvents: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: {|
          +methods: ?$ReadOnlyArray<?string>,
          +deviceUid: ?string,
          +status: ?_statusType,
          +date: ?any,
        |}
      |}>
    |},
  |}
|};
export type DeviceLogsQuery = {|
  variables: DeviceLogsQueryVariables,
  response: DeviceLogsQueryResponse,
|};
*/


/*
query DeviceLogsQuery {
  viewer {
    allAudioDevices {
      edges {
        node {
          id
          name
          uid
          isOnline
          deviceStatus
          appVersionDS
        }
      }
    }
    allDsBlobs {
      edges {
        node {
          id
          deviceId
          randomBlob
          deviceTimestamp
          serverTimestamp
          deviceUid
        }
      }
    }
    allDeviceLogs {
      edges {
        node {
          deviceId
          log
          deviceTimestamp
          serverTimestamp
          deviceUid
          isLogging
          id
        }
      }
    }
    allEvents {
      edges {
        node {
          methods
          deviceUid
          status
          date
          id
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "AudioDeviceConnection",
  "kind": "LinkedField",
  "name": "allAudioDevices",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AudioDeviceEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AudioDevice",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "uid",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isOnline",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "deviceStatus",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "appVersionDS",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deviceId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deviceTimestamp",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "serverTimestamp",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deviceUid",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "DsBlobConnection",
  "kind": "LinkedField",
  "name": "allDsBlobs",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "DsBlobEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "DsBlob",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "randomBlob",
              "storageKey": null
            },
            (v3/*: any*/),
            (v4/*: any*/),
            (v5/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "log",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isLogging",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "methods",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "date",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "DeviceLogsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "DeviceLogConnection",
            "kind": "LinkedField",
            "name": "allDeviceLogs",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "DeviceLogEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DeviceLog",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v7/*: any*/),
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v8/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "EventConnection",
            "kind": "LinkedField",
            "name": "allEvents",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "EventEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Event",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v9/*: any*/),
                      (v5/*: any*/),
                      (v10/*: any*/),
                      (v11/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "DeviceLogsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "DeviceLogConnection",
            "kind": "LinkedField",
            "name": "allDeviceLogs",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "DeviceLogEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DeviceLog",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v7/*: any*/),
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v8/*: any*/),
                      (v0/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "EventConnection",
            "kind": "LinkedField",
            "name": "allEvents",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "EventEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Event",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v9/*: any*/),
                      (v5/*: any*/),
                      (v10/*: any*/),
                      (v11/*: any*/),
                      (v0/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "13c428d36da7bff408ec9ef175656cbd",
    "metadata": {},
    "name": "DeviceLogsQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '4446d313c216ff679a1d443b8cc4f163';

module.exports = node;
