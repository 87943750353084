/**
 * @flow
 * @relayHash a602fbc6686ba693665790e35e1f0450
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type deleteNPLogMutationVariables = {|
  inputid: string
|};
export type deleteNPLogMutationResponse = {|
  +deleteNPLog: ?string
|};
export type deleteNPLogMutation = {|
  variables: deleteNPLogMutationVariables,
  response: deleteNPLogMutationResponse,
|};
*/


/*
mutation deleteNPLogMutation(
  $inputid: String!
) {
  deleteNPLog(id: $inputid)
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "inputid"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "inputid"
      }
    ],
    "kind": "ScalarField",
    "name": "deleteNPLog",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "deleteNPLogMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "deleteNPLogMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "a602fbc6686ba693665790e35e1f0450",
    "metadata": {},
    "name": "deleteNPLogMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '8bfa7d931939ab35994f66f2eb7c9069';

module.exports = node;
