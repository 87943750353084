import {
  Environment,
  Network,
  RecordSource,
  QueryResponseCache,
  Store,
  Observable,
} from "relay-runtime";
import { SubscriptionClient } from "subscriptions-transport-ws";
import fetch from "isomorphic-fetch";
import config from "./config";
import persistedQueries from "./relay/persisted-queries";

const ttl = 60 * 1000; //one minute
const cache = new QueryResponseCache({ size: 250, ttl });

function fetchQuery(operation, variables, cacheConfig) {
  const queryID = operation.id;
  const isMutation = operation.operationKind === "mutation";
  const isQuery = operation.operationKind === "query";
  const forceFetch = cacheConfig && cacheConfig.force;
  const fromCache = cache.get(queryID, variables);
  if (isQuery && fromCache !== null && !forceFetch) {
    return fromCache;
  }

  const request = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("authentication_token")}`,
    },
    body: JSON.stringify({
      // extensions: {
      //   persistedQuery: {
      //     version: 1,
      //     sha256Hash: operation.id,
      //   },
      // },
      operationName: operation.name,
      // documentId: operation.id, // NOTE: pass md5 hash to the server
      query: operation.id, // this is now obsolete because text is null
      variables,
    }),
  };
  return fetch(config.URL_PATH, request)
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      // Update cache on queries
      if (isQuery && json) {
        cache.set(queryID, variables, json);
      }
      // Clear cache on mutations
      if (isMutation) {
        cache.clear();
      }
      return json;
    })
    .catch((error) => console.log(error));
}

const subscriptionClient = new SubscriptionClient(config.SUBSCRIPTION_PATH, {
  reconnect: true,
});

const networkSubscriptions = (request, variables) => {
  const subscribeObservable = subscriptionClient.request({
    query: persistedQueries[request.id],
    operationName: request.name,
    variables,
  });
  // Important: Convert subscriptions-transport-ws observable type to Relay's
  return Observable.from(subscribeObservable);
};

// with the store and network available you can instantiate the actual Environment
const network = Network.create(fetchQuery, networkSubscriptions);
const source = new RecordSource();
const store = new Store(source, { queryCacheExpirationTime: 5 * 1000 });
const handlerProvider = null;
// export the environment from this module

export default new Environment({
  handlerProvider,
  network,
  store,
  gcReleaseBufferSize: 10,
});
