/**
 * @flow
 * @relayHash faafd55a2742361a688a73ae5965e07b
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AudioClientEditQueryVariables = {|
  ID: string
|};
export type AudioClientEditQueryResponse = {|
  +viewer: {|
    +client: ?{|
      +id: string,
      +name: ?string,
      +useExplicit: ?boolean,
      +noVolumeBar: ?boolean,
      +noPlayPause: ?boolean,
    |}
  |}
|};
export type AudioClientEditQuery = {|
  variables: AudioClientEditQueryVariables,
  response: AudioClientEditQueryResponse,
|};
*/


/*
query AudioClientEditQuery(
  $ID: ID!
) {
  viewer {
    client(_id: $ID) {
      id
      name
      useExplicit
      noVolumeBar
      noPlayPause
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "ID"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Viewer",
    "kind": "LinkedField",
    "name": "viewer",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "_id",
            "variableName": "ID"
          }
        ],
        "concreteType": "AudioClient",
        "kind": "LinkedField",
        "name": "client",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "useExplicit",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "noVolumeBar",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "noPlayPause",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AudioClientEditQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AudioClientEditQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "faafd55a2742361a688a73ae5965e07b",
    "metadata": {},
    "name": "AudioClientEditQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '01cdfa1e7f809dda120c16667fb7d08b';

module.exports = node;
