/**
 * @flow
 * @relayHash 31e01726ade32bd5d89d1a2be701a8c7
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type audioClientProviderData = {|
  name: string,
  logoFilename?: ?string,
  playlistIds?: ?$ReadOnlyArray<?string>,
  useExplicit?: ?boolean,
  noVolumeBar?: ?boolean,
  noPlayPause?: ?boolean,
|};
export type createAudioClientMutationVariables = {|
  input: audioClientProviderData
|};
export type createAudioClientMutationResponse = {|
  +createAudioClient: ?{|
    +id: string,
    +name: ?string,
    +playlistIds: ?$ReadOnlyArray<?string>,
    +playbackIds: ?$ReadOnlyArray<?string>,
    +sceneIds: ?$ReadOnlyArray<?string>,
    +logo: ?string,
    +useExplicit: ?boolean,
    +noVolumeBar: ?boolean,
    +noPlayPause: ?boolean,
  |}
|};
export type createAudioClientMutation = {|
  variables: createAudioClientMutationVariables,
  response: createAudioClientMutationResponse,
|};
*/


/*
mutation createAudioClientMutation(
  $input: audioClientProviderData!
) {
  createAudioClient(audioClientProvider: $input) {
    id
    name
    playlistIds
    playbackIds
    sceneIds
    logo
    useExplicit
    noVolumeBar
    noPlayPause
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "audioClientProvider",
        "variableName": "input"
      }
    ],
    "concreteType": "AudioClient",
    "kind": "LinkedField",
    "name": "createAudioClient",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "playlistIds",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "playbackIds",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "sceneIds",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "logo",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "useExplicit",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "noVolumeBar",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "noPlayPause",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "createAudioClientMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "createAudioClientMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "31e01726ade32bd5d89d1a2be701a8c7",
    "metadata": {},
    "name": "createAudioClientMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'aa843bf90434c1336ba69ef2ffdade66';

module.exports = node;
