import { commitMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import Environment from "../../environment";

const mutation = graphql`
  mutation toggleCategoriesMutation(
    $clientId: String!
    $category: String!
    $subCategory: String
    $checked: Boolean!
  ) {
    toggleCategories(
      clientId: $clientId
      category: $category
      subCategory: $subCategory
      checked: $checked
    ) {
      id
      name
      playlistIds
      playbackIds
      sceneIds
      logo
      signedUrl
      preview {
        previewId
        created
        ending
        isEnded
        isEndless
      }
      viewableCategories {
        name
        subCategories
      }
    }
  }
`;

export default (clientId, category, subCategory, checked, callback) => {
  const variables = {
    clientId,
    category,
    subCategory,
    checked,
  };

  commitMutation(Environment, {
    mutation,
    variables,
    onCompleted: (response) => {
      callback(response?.toggleCategories);
    },
    onError: (err) => console.error(err), // eslint-disable-line
  });
};
