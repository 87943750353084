import jwt from "jsonwebtoken";

const isAuthenticated = () => {
  const user = jwt.decode(localStorage.getItem("authentication_token"));
  const currentTime = new Date().getTime() / 1000;
  if (user && currentTime < user.exp) {
    return true;
  }
  return false;
};

export default isAuthenticated;
