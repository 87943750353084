/**
 * External dependencies
 */
import React, { useState, useContext } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemSecondaryAction,
  Divider,
  IconButton,
} from "@material-ui/core";
import {
  Star,
  StarBorder,
  Delete,
  Edit,
  FileCopy,
  AddCircle,
} from "@material-ui/icons";

/**
 * Internal dependencies
 */

import MenuModal from "./MenuModal";
import MenuListItemExtension from "./MenuListItemExtension";
import { CurrentMenuContext } from "../../hooks/currentMenuProvider";
import { CurrentClientContext } from "../../hooks/currentClientProvider";

const styles = {
  root: {},
  nested: {
    paddingLeft: 16,
  },
};

const MenuListItem = ({ classes, menu, regex }) => {
  const [openModal, setOpenModal] = useState(false);
  const { audioProvider } = useContext(CurrentClientContext);
  const menuExtensions =
    menu?.childrenMenus?.length > 0
      ? [...menu.childrenMenus].sort((a, b) => {
          if (a?.name?.toLowerCase() < b?.name?.toLowerCase()) {
            return -1;
          }
          if (a?.name?.toLowerCase() > b?.name?.toLowerCase()) {
            return 1;
          }
          return 0;
        })
      : [];

  const { currentMenu, setCurrentMenu } = useContext(CurrentMenuContext);

  const handleOpen = (modalType) => {
    setOpenModal(modalType);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleSetMenu = () => {
    setCurrentMenu(menu);
  };

  return (
    <>
      <ListItem
        component="nav"
        className={classes.root}
        aria-label="menu-list-item"
        button
        onClick={handleSetMenu}
        selected={currentMenu?.id === menu.id}
      >
        <ListItemIcon>{menu?.active ? <Star /> : <StarBorder />}</ListItemIcon>
        <ListItemText primary={menu.name} secondary={menu.description} />
        <ListItemSecondaryAction>
          <IconButton
            edge="end"
            aria-label="create-menu-button"
            onClick={() => handleOpen("createMenuExtension")}
          >
            <AddCircle />
          </IconButton>
          <IconButton
            edge="end"
            aria-label="edit-menu-button"
            onClick={() => handleOpen("edit")}
          >
            <Edit />
          </IconButton>
          <IconButton
            edge="end"
            aria-label="duplicate-menu-button"
            onClick={() => handleOpen("duplicate")}
          >
            <FileCopy />
          </IconButton>
          <IconButton
            edge="end"
            aria-label="delete-menu-button"
            onClick={() => handleOpen("delete")}
          >
            <Delete />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      {menu?.childrenMenus?.length > 0 && (
        <List component="nav" style={{ paddingLeft: 16 }} dense>
          {menuExtensions
            .filter(
              (item) =>
                item?.name?.search(regex) !== -1 ||
                item?.description?.search(regex) !== -1
            )
            .map((item) => (
              <MenuListItemExtension
                key={item?.id}
                menu={item}
                parentMenu={menu}
              />
            )) || null}
        </List>
      )}
      <MenuModal
        openModal={openModal}
        callbackModal={handleClose}
        menu={menu}
      />
      <Divider />
    </>
  );
};

export default withStyles(styles)(MenuListItem);
