import { commitMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import Environment from "../../environment";

const mutation = graphql`
  mutation updateAudioClientLogoMutation(
    $inputid: String!
    $logoFilename: String
  ) {
    updateAudioClientLogo(id: $inputid, logoFilename: $logoFilename) {
      id
      name
      playlistIds
      playbackIds
      sceneIds
      logo
    }
  }
`;

export default (id2, logoFilename, callback) => {
  const variables = {
    inputid: id2,
    logoFilename,
    clientMutationId: "",
  };

  commitMutation(Environment, {
    mutation,
    variables,
    onCompleted: () => {
      callback();
    },
    onError: (err) => console.error(err), // eslint-disable-line
  });
};
