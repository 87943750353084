import { commitMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import Environment from "../../environment";

const mutation = graphql`
  mutation deleteAudioClientMutation($inputid: String!) {
    deleteAudioClient(id: $inputid) {
      id
    }
  }
`;

export default (id, callback) => {
  const variables = {
    inputid: id,
  };

  commitMutation(Environment, {
    mutation,
    variables,
    onCompleted: () => {
      console.log("Audio Client deleted");
      callback();
    },
    onError: (err) => console.error(err), // eslint-disable-line
  });
};
