import React from "react";
import { createRefetchContainer, fetchQuery } from "react-relay";
import graphql from 'babel-plugin-relay/macro';
import { withSnackbar } from "material-ui-snackbar-provider";
import compose from "recompose/compose";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import ReactTable from "react-table";
import "react-table/react-table.css";
import SyncIcon from "@material-ui/icons/SyncRounded";
import DeleteIcon from "@material-ui/icons/DeleteRounded";
import Fade from "@material-ui/core/Fade";
import ClearIcon from "@material-ui/icons/Clear";

import environment from "../../environment";
import getTaskStatusMutation from "../mutations/getTaskStatusMutation";
import deleteTaskMutation from "../mutations/deleteTaskMutation";
import deleteAllTasksMutation from "../mutations/deleteAllTasksMutation";

const styles = (theme) => ({
  typo: {
    paddingLeft: 8,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  button: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    color: "#ff4c4c",
    "&:hover": {
      background: "#e3fd59",
    },
  },
  button2: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    marginLeft: "9px",
    "&:hover": {
      background: "#e3fd59",
    },
    minWidth: 150,
    margin: 5,
    marginBottom: 9,
  },
  button4: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    "&:hover": {
      background: "#e3fd59",
    },
  },
  horizontalContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  verticalContainer: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
  },
  text1: {
    alignSelf: "center",
    marginLeft: 16,
  },
  input: {
    display: "none",
    marginLeft: 8,
  },
});

const query = graphql`
  query WebkeyTasksRefetchQuery {
    viewer {
      allWebkeyTasks {
        edges {
          node {
            id
            instance
            userId
            clientId
            packageId
            type
            reports {
              deviceid
              state
            }
            date
          }
        }
      }
    }
  }
`;

class WebkeyTasks extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showAllDates: false,
      showAllUsers: false,
      asked: false,
    };
    this.handleDates = this.handleDates.bind(this);
    this.handleUsers = this.handleUsers.bind(this);
  }

  componentDidMount() {
    fetchQuery(environment, query);
  }

  handleDates() {
    this.setState({ showAllDates: !this.state.showAllDates });
  }
  handleUsers() {
    this.setState({ showAllUsers: !this.state.showAllUsers });
  }
  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.match.location.pathname === window.location.pathname) {
      return true;
    }
    return false;
  }

  render() {
    const { classes, viewer } = this.props;
    const { asked, showAllUsers, showAllDates } = this.state;

    const allWebkeyCustomActions = viewer.allWebkeyCustomActions.edges
      .filter((item) => item && item.node)
      .map(({ node }) => node);

    const localId = localStorage.getItem("user_id");

    const columns = [
      {
        id: "type",
        Header: "Action type",
        accessor: (a) => {
          if (a.type === "packageinstall") {
            return "Package install";
          } else if (a.type === "packageuninstall") {
            return "Package uninstall";
          }

          const action = allWebkeyCustomActions.find(
            (item) => item.actionCode === a.type
          );
          return action ? action.name : "Unknown";
        },
        width: 150,
      },
      {
        accessor: "instance",
        Header: "Instance",
        width: 100,
      },
      {
        id: "details",
        Header: "Details",
        accessor: (a) =>
          a.packageName ? `${a.packageName} - v${a.packageVersion}` : a.type,
        width: 250,
      },
      {
        id: "userName",
        Header: "User Name",
        accessor: "userName",
        width: 200,
      },
      {
        id: "date",
        Header: "Date",
        accessor: (a) => moment(a.date).format("MMM Do YYYY, h:mm:ss a"),
        width: 200,
      },
      {
        Header: "Actions",
        width: 320,
        filterable: false,
        sortable: false,
        Cell: (row) => {
          return (
            <div className={classes.horizontalContainer}>
              <Tooltip
                title="Refresh task status"
                placement="bottom"
                enterTouchDelay={200}
                onClick={() =>
                  getTaskStatusMutation(row.original.id, () =>
                    this.props.snackbar.showMessage("Refreshed")
                  )
                }
              >
                <Button
                  variant="text"
                  size="small"
                  color="primary"
                  className={classes.button4}
                >
                  <SyncIcon />
                </Button>
              </Tooltip>
              <Tooltip
                title="Delete task"
                placement="bottom"
                enterTouchDelay={200}
                onClick={() =>
                  deleteTaskMutation(row.original.id, () =>
                    this.props.snackbar.showMessage("Refreshed")
                  )
                }
              >
                <Button
                  variant="text"
                  size="small"
                  color="primary"
                  className={classes.button}
                >
                  <DeleteIcon />
                </Button>
              </Tooltip>
            </div>
          );
        },
      },
    ];

    const columns2 = [
      {
        Header: "Activaire Control ID",
        accessor: "deviceid",
        width: 300,
      },
      {
        id: "deviceName",
        Header: "Device Name",
        accessor: (a) => {
          return a.deviceName ? a.deviceName : "Unknown";
        },
        width: 250,
      },
      {
        Header: "state",
        accessor: "state",
        width: 100,
      },
    ];

    const allUsers = viewer.allUsers.edges
      .filter((item) => item && item.node)
      .map(({ node }) => node);

    const allAudioDevices = viewer.allAudioDevices.edges
      .filter((item) => item && item.node)
      .map(({ node }) => node);

    const allVideoDevices = viewer.allVideoDevices.edges
      .filter((item) => item && item.node)
      .map(({ node }) => node);

    const allDevices = allAudioDevices.concat(allVideoDevices);

    const allWebkeyPackages = viewer.allWebkeyPackages.edges
      .filter((item) => item && item.node)
      .map(({ node }) => node);

    const allWebkeyTasks = viewer.allWebkeyTasks.edges
      .filter((item) => item && item.node)
      .map(({ node }) => {
        const pack = allWebkeyPackages.find(
          (item) => item.id === node.packageId
        );

        const user = allUsers.find((item) => item.id === node.userId);

        const targetObject = {};
        if (pack) {
          targetObject.packageName = pack.packageName;
          targetObject.packageVersion = pack.versionName;
        }
        if (user && user.profile) {
          targetObject.userName = user.profile.name;
        }

        return Object.assign(targetObject, node);
      });

    let renderedData;

    if (showAllUsers) {
      renderedData = allWebkeyTasks.filter((data) => {
        return (
          data && moment(data.date).format("Y M D") === moment().format("Y M D")
        );
      });
    }

    if (showAllDates) {
      renderedData = allWebkeyTasks.filter((data) => {
        return data && data.userId === localId;
      });
    }

    if (showAllUsers && showAllDates) {
      renderedData = allWebkeyTasks;
    }

    if (!showAllUsers && !showAllDates) {
      renderedData = allWebkeyTasks.filter((data) => {
        return (
          data &&
          data.userId === localId &&
          moment(data.date).format("Y M D") === moment().format("Y M D")
        );
      });
    }

    const pageSize = 20;

    return (
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          flexDirection: "column",
          maxWidth: "200px",
        }}
      >
        <h2 style={{ marginLeft: "12px", paddingTop: "12px" }}>
          {this.props.match.route.name}
        </h2>
        {!asked ? (
          <Button
            variant="outlined"
            color="primary"
            size="small"
            className={classes.button2}
            onClick={() => this.setState({ asked: true })}
          >
            Delete all
          </Button>
        ) : (
          <Fade in={asked} timeout={250}>
            <div className={classes.horizontalContainer}>
              <div className={classes.close}>
                <Typography
                  style={{
                    width: "100px",
                    paddingTop: "10px",
                    paddingLeft: "15px",
                  }}
                  className={classes.typo3}
                  variant="subtitle1"
                  color="primary"
                  align="center"
                >
                  Are you sure?
                </Typography>
              </div>
              <div className={classes.close}>
                <Tooltip
                  id="tooltip-bottom"
                  title="Cancel Delete"
                  placement="bottom"
                  enterTouchDelay={200}
                >
                  <IconButton
                    color="primary"
                    onClick={() => this.setState({ asked: false })}
                  >
                    <ClearIcon color="primary" className={classes.closeIcon} />
                  </IconButton>
                </Tooltip>
              </div>
              <div className={classes.close}>
                <Tooltip
                  id="tooltip-bottom"
                  title="Delete all tasks"
                  placement="bottom"
                  enterTouchDelay={200}
                >
                  <IconButton
                    color="primary"
                    onClick={() =>
                      deleteAllTasksMutation(() => {
                        this.setState({ asked: false });
                        fetchQuery(environment, query);
                        console.log("deleted");
                      })
                    }
                  >
                    <DeleteIcon
                      color="primary"
                      className={classes.closeIcon}
                      style={{ color: "tomato" }}
                    />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
          </Fade>
        )}
        <FormGroup row style={{ marginLeft: "11px" }}>
          <FormControlLabel
            control={
              <Switch
                checked={showAllUsers}
                value="Show All Users"
                onChange={this.handleUsers}
              />
            }
            label="Show All Users"
          />
          <FormControlLabel
            control={
              <Switch
                checked={this.showAllDates}
                value="Show All Dates"
                onChange={this.handleDates}
              />
            }
            label="Show All Dates"
          />
        </FormGroup>

        <div className={classes.verticalContainer}>
          <div className={classes.horizontalContainer}>
            <ReactTable
              data={renderedData}
              columns={columns}
              defaultPageSize={pageSize}
              showPagination={renderedData.length > pageSize}
              filterable
              defaultFilterMethod={(filter, row) =>
                String(row[filter.id])
                  .toLowerCase()
                  .includes(filter.value.toLowerCase())
              }
              className="-striped -highlight"
              style={{
                display: "block",
                margin: "8px 0px 8px 8px",
                fontSize: "13px",
                flex: "auto",
                minWidth: "35vw",
              }}
              SubComponent={(row) => {
                const rawData = row.original.reports;

                if (rawData) {
                  const data2 = rawData.map((device) => {
                    const targetObject2 = {};
                    const foundDevice =
                      row.original.instance === "primary"
                        ? allDevices.find(
                            (item) => item.webkeyId === device.deviceid
                          )
                        : allDevices.find(
                            (item) => item.webkeyBackupId === device.deviceid
                          );
                    if (foundDevice) {
                      targetObject2.deviceName = foundDevice.name;
                      targetObject2.state = device.state;
                      targetObject2.deviceid = device.deviceid;
                    }
                    return foundDevice ? targetObject2 : device;
                  });
                  return (
                    <div style={{ padding: "20px" }}>
                      <ReactTable
                        data={data2.length > 0 ? data2 : {}}
                        columns={columns2}
                        defaultPageSize={data2.length > 10 ? 10 : data2.length}
                        showPagination={data2.length > 10}
                      />
                    </div>
                  );
                }
                return (
                  <div style={{ padding: "20px" }}>Refresh to get report</div>
                );
              }}
            />
          </div>
          <Typography
            variant="caption"
            color="secondary"
            className={classes.typo}
            align="left"
          >
            Tip: Hold shift when sorting to multi-sort!
          </Typography>
        </div>
      </div>
    );
  }
}

WebkeyTasks.propTypes = {
  classes: PropTypes.object.isRequired, //eslint-disable-line
  viewer: PropTypes.object.isRequired, //eslint-disable-line
};

export default compose(
  withStyles(styles),
  withSnackbar()
)(
  createRefetchContainer(WebkeyTasks, {
    viewer: graphql`
      fragment WebkeyTasks_viewer on Viewer {
        allWebkeyDevices {
          edges {
            node {
              id
              DisplayName
              Serial
              DeviceNick
              DeviceBrand
              DeviceModel
              WebkeyVersion
              Location
              LastConnected
              AndroidAPI
              AndroidVersion
              Rooted
              Online
            }
          }
        }
        allWebkeyTasks {
          edges {
            node {
              id
              instance
              userId
              clientId
              packageId
              type
              reports {
                deviceid
                state
              }
              date
            }
          }
        }
        allAudioDevices {
          edges {
            node {
              id
              name
              webkeyId
              webkeyBackupId
            }
          }
        }
        allVideoDevices {
          edges {
            node {
              id
              name
              webkeyId
              webkeyBackupId
            }
          }
        }
        allWebkeyPackages {
          edges {
            node {
              id
              appName
              keyName
              source
              packageName
              description
              versionCode
              versionName
              icon
            }
          }
        }
        allWebkeyCustomActions {
          edges {
            node {
              id
              name
              actionCode
            }
          }
        }
        allUsers {
          edges {
            node {
              id
              emails {
                address
                verified
              }
              profile {
                name
                clientId
                videoClientId
              }
            }
          }
        }
      }
    `,
  })
);
