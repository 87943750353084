/**
 * External dependencies
 */
import React, { useContext } from "react";
import { withStyles } from "@material-ui/core/styles";
import { MusicNote, FolderSpecial } from "@material-ui/icons";
import { Droppable, Draggable } from "react-beautiful-dnd";

/**
 * Internal dependencies
 */
import StyledTreePlayable from "./StyledTreePlayable";
import { CurrentClientContext } from "../../hooks/currentClientProvider";
import flattenArrays from "../utils/flattenArrays";
import { CurrentMenuContext } from "../../hooks/currentMenuProvider";

const styles = (theme) => {};

const playable = (item, route, index, disabled) => {
  return (
    <Draggable
      key={item.id}
      draggableId={`${route.pathId}_${item.id}`}
      index={index}
      isDragDisabled={disabled}
    >
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <StyledTreePlayable
            nodeId={`${route.pathId}_${item.id}`}
            labelText={item.name}
            labelInfo={""}
            labelicon={MusicNote}
            color="#a250f5"
            bgColor="#f3e8fd"
            route={item}
          />
        </div>
      )}
    </Draggable>
  );
};

const folder = (item, route, playableList, index, disabled, explicit) => {
  let playableType = "all";
  switch (route.playableType) {
    case "PLAYLISTS":
      playableType = "playlist";
      break;
    case "SCENES":
      playableType = "scene";
      break;
    default:
      playableType = "all";
  }
  return (
    <Draggable
      key={`${route.pathId}_${item}`}
      draggableId={`${route.pathId}_${item}`}
      index={index}
      isDragDisabled={disabled}
    >
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <StyledTreePlayable
            nodeId={`${route.pathId}_${item}`}
            labelText={item}
            labelInfo={""}
            labelicon={FolderSpecial}
            color="#a250f5"
            bgColor="#f3e8fd"
            route={item}
          >
            {playableList(playableType, explicit)
              .filter(
                (item2) =>
                  item2.genres
                    ?.map((item3) => item3.toUpperCase())
                    .includes(item) ||
                  item2.genre
                    ?.map((item3) => item3.toUpperCase())
                    .includes(item)
              )
              .filter((item) => {
                const cat = item.newCategory || item.category || [];
                return !cat
                  ?.map((item2) => item2.category.toUpperCase())
                  .includes("CUSTOM");
              })
              .map((item2) => playable(item2, route))}
          </StyledTreePlayable>
        </div>
      )}
    </Draggable>
  );
};

const RenderPlayables = ({ route, disabled, path }) => {
  const { playableList } = useContext(CurrentClientContext);
  const { currentMenu, currentMenuExtension } = useContext(CurrentMenuContext);
  const explicit =
    currentMenuExtension?.explicit ?? currentMenu?.explicit ?? true;
  switch (route.autoPopulateType) {
    case "NONE": {
      const allIds = route?.mixesArray?.map((item) => item.mixId) || [];
      return (
        <Droppable droppableId={`PLAYABLES${path}`} type={`PLAYABLES${path}`}>
          {(provided, snapshot) => (
            <div ref={provided.innerRef}>
              {playableList("all", explicit)
                .filter((item) => allIds.includes(item.id))
                .sort((a, b) => {
                  return allIds.indexOf(a.id) - allIds.indexOf(b.id);
                })
                .map((item, index) => playable(item, route, index, disabled))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      );
    }
    case "GENRE": {
      const allGenre = route.genres || [];
      return (
        <Droppable droppableId={`GENRE${path}`} type={`GENRE${path}`}>
          {(provided) => (
            <div ref={provided.innerRef}>
              {allGenre.map((item, index) =>
                folder(item, route, playableList, index, disabled, explicit)
              )}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      );
    }
    case "CATEGORY": {
      const category = route?.category || "Unassigned";
      return (
        <>
          {playableList("scene", explicit)
            .filter(
              (item) =>
                item?.newCategory?.filter(
                  (item2) => item2?.category === category
                )?.length > 0 || false
            )
            .map((item) => playable(item, route))}
        </>
      );
    }
    case "SUBCATEGORY": {
      const subCategory = route?.subCategory || "Unassigned";
      const category = route?.category || "Unassigned";
      return (
        <>
          {playableList("scene", explicit)
            .filter((item) =>
              flattenArrays(
                item?.newCategory?.map((item2) =>
                  item2.category === category ? item2.subCategories : []
                )
              )?.includes(subCategory)
            )
            .map((item) => playable(item, route))}
        </>
      );
    }
    default:
      return <></>;
  }
};

export default withStyles(styles)(RenderPlayables);
