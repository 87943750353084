/**
 * @flow
 * @relayHash 32b6f5af9ee58b95e7023bd38f11c652
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type _ModelMutationType = "CREATED" | "DELETED" | "UPDATED" | "UPDATEDCLIENT" | "%future added value";
export type _deviceStatusType = "ACTIVE" | "INACTIVE" | "REGISTERED" | "UNREGISTERED" | "%future added value";
export type AudioDeviceSubscriptionVariables = {|
  token: string,
  updatedId?: ?string,
  updatedFields?: ?$ReadOnlyArray<?string>,
|};
export type AudioDeviceSubscriptionResponse = {|
  +AudioDeviceSubscription: ?{|
    +mutation: _ModelMutationType,
    +node: {|
      +id: string,
      +name: ?string,
      +uid: ?string,
      +description: ?string,
      +volume: ?number,
      +remoteVolume: ?number,
      +remoteVolumeMode: ?boolean,
      +requestPCLogs: ?boolean,
      +requestNPLogs: ?boolean,
      +lastOnline: ?number,
      +appUpTime: ?string,
      +clientId: ?string,
      +currentPlaylistId: ?string,
      +currentSceneId: ?string,
      +currentSongId: ?string,
      +remotePlaylistMode: ?boolean,
      +isPlaying: ?boolean,
      +isOnline: ?boolean,
      +currentMd5: ?string,
      +make: ?string,
      +model: ?string,
      +wlan: ?string,
      +serialNumber: ?string,
      +lan: ?string,
      +IP_local: ?string,
      +IP_public: ?string,
      +totalSdStorage: ?number,
      +usedSdStorage: ?number,
      +availableSdStorage: ?number,
      +appVersion: ?string,
      +m3uFilesOnDevice: ?number,
      +songFilesOnDevice: ?number,
      +lastSyncTime: ?string,
      +latitude: ?number,
      +longitude: ?number,
      +skipCount: ?number,
      +skipDisabled: ?boolean,
      +likedSong: ?string,
      +goLiveDate: ?number,
      +appStatus: ?{|
        +message: ?string,
        +status: ?string,
        +description: ?string,
        +time: ?string,
      |},
      +scheduleIds: ?$ReadOnlyArray<?string>,
      +usersId: ?$ReadOnlyArray<?{|
        +id: string
      |}>,
      +playlistsId: ?$ReadOnlyArray<?string>,
      +playbackIds: ?$ReadOnlyArray<?string>,
      +tags: ?$ReadOnlyArray<?string>,
      +enrollmentDate: ?any,
      +deviceStatus: ?_deviceStatusType,
      +makeAndModel: ?string,
      +macAddress: ?string,
      +ethernetMacAddress: ?string,
      +internalIpAddress: ?string,
      +publicIpAddress: ?string,
      +appVersionDS: ?string,
      +webkeyId: ?string,
      +webkeyBackupId: ?string,
      +crossfade: ?number,
      +maximumDaysOffline: ?number,
      +maximumSongsOffline: ?number,
    |},
  |}
|};
export type AudioDeviceSubscription = {|
  variables: AudioDeviceSubscriptionVariables,
  response: AudioDeviceSubscriptionResponse,
|};
*/


/*
subscription AudioDeviceSubscription(
  $token: String!
  $updatedId: ID
  $updatedFields: [String]
) {
  AudioDeviceSubscription(token: $token, updatedId: $updatedId, updatedFields: $updatedFields) {
    mutation
    node {
      id
      name
      uid
      description
      volume
      remoteVolume
      remoteVolumeMode
      requestPCLogs
      requestNPLogs
      lastOnline
      appUpTime
      clientId
      currentPlaylistId
      currentSceneId
      currentSongId
      remotePlaylistMode
      isPlaying
      isOnline
      currentMd5
      make
      model
      wlan
      serialNumber
      lan
      IP_local
      IP_public
      totalSdStorage
      usedSdStorage
      availableSdStorage
      appVersion
      m3uFilesOnDevice
      songFilesOnDevice
      lastSyncTime
      latitude
      longitude
      skipCount
      skipDisabled
      likedSong
      goLiveDate
      appStatus {
        message
        status
        description
        time
      }
      scheduleIds
      usersId {
        id
      }
      playlistsId
      playbackIds
      tags
      enrollmentDate
      deviceStatus
      makeAndModel
      macAddress
      ethernetMacAddress
      internalIpAddress
      publicIpAddress
      appVersionDS
      webkeyId
      webkeyBackupId
      crossfade
      maximumDaysOffline
      maximumSongsOffline
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "token"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "updatedFields"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "updatedId"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v5 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "token",
        "variableName": "token"
      },
      {
        "kind": "Variable",
        "name": "updatedFields",
        "variableName": "updatedFields"
      },
      {
        "kind": "Variable",
        "name": "updatedId",
        "variableName": "updatedId"
      }
    ],
    "concreteType": "AudioDeviceSubscriptionPayload",
    "kind": "LinkedField",
    "name": "AudioDeviceSubscription",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "mutation",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "AudioDevice",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "uid",
            "storageKey": null
          },
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "volume",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "remoteVolume",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "remoteVolumeMode",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "requestPCLogs",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "requestNPLogs",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastOnline",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "appUpTime",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "clientId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "currentPlaylistId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "currentSceneId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "currentSongId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "remotePlaylistMode",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isPlaying",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isOnline",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "currentMd5",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "make",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "model",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "wlan",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "serialNumber",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lan",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "IP_local",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "IP_public",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalSdStorage",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "usedSdStorage",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "availableSdStorage",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "appVersion",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "m3uFilesOnDevice",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "songFilesOnDevice",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastSyncTime",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "latitude",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "longitude",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "skipCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "skipDisabled",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "likedSong",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "goLiveDate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AppStatus",
            "kind": "LinkedField",
            "name": "appStatus",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "message",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              },
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "time",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "scheduleIds",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "usersId",
            "plural": true,
            "selections": [
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "playlistsId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "playbackIds",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "tags",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "enrollmentDate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "deviceStatus",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "makeAndModel",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "macAddress",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "ethernetMacAddress",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "internalIpAddress",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "publicIpAddress",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "appVersionDS",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "webkeyId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "webkeyBackupId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "crossfade",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "maximumDaysOffline",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "maximumSongsOffline",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AudioDeviceSubscription",
    "selections": (v5/*: any*/),
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "AudioDeviceSubscription",
    "selections": (v5/*: any*/)
  },
  "params": {
    "id": "32b6f5af9ee58b95e7023bd38f11c652",
    "metadata": {},
    "name": "AudioDeviceSubscription",
    "operationKind": "subscription",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '2360cd3979713abee798d856f27b96cd';

module.exports = node;
