import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { withSnackbar } from "material-ui-snackbar-provider";
import compose from "recompose/compose";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Tooltip from "@material-ui/core/Tooltip";
import Checkbox from "@material-ui/core/Checkbox";
import CloseIcon from "@material-ui/icons/Close";
import SettingsIcon from "@material-ui/icons/Settings";
import EditIcon from "@material-ui/icons/Edit";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import AppDetails from "./deviceControl/AppDetails";
import DeviceActions from "./deviceControl/DeviceActions";
import SystemDetailsControls from "./deviceControl/SystemDetailsControls";
import Tag from "./deviceControl/Tag";
import permissions from "../authentication/isPermited";
import updateVideoDeviceNameMutation from "../mutations/updateVideoDeviceNameMutation";

const styles = (theme) => ({
  root: theme.mixins.gutters({
    padding: "8px 8px 8px 8px",
    margin: "8px 8px 8px 8px",
    flex: "auto",
    minWidth: "30vw",
  }),
  horizontalContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  verticalContainer: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
  },
  closeIcon: {
    height: 24,
    width: 24,
  },
  close: {
    position: "relative",
    direction: "rtl",
    marginTop: 6,
  },
  typo1: {
    padding: 0,
  },
  typo2: {
    padding: 0,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  expansion: {
    marginBottom: 8,
    flex: "auto",
    boxShadow:
      "0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12)",
  },
  expansionDetails: {
    flex: "auto",
  },
  expansionSummary: {
    padding: "0px 24px 0px 0px",
  },
  checked: {
    color: "primary",
  },
  dialog: {
    background: "secondary",
    margin: theme.spacing(3),
  },
  dialogTitle: {
    padding: "0px 24px 0px 24px",
    minWidth: 250,
  },
  textField: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
  },
  button: {
    marginRight: theme.spacing(1),
  },
});

class VideoDeviceControl extends React.Component {
  constructor(props) {
    super(props);
    const userSettings =
      props.viewer.user.consoleSettings &&
      props.viewer.user.consoleSettings.videoDevices
        ? props.viewer.user.consoleSettings.videoDevices
        : {
            appDetails: true,
            deviceActions: false,
            systemDetailsControls: false,
            tags: false,
          };
    this.state = {
      ...userSettings,
      clientId: this.props.clientId,
      changeNameModal: false,
      deviceName: this.props.deviceControl.name,
      deviceDescription: this.props.deviceControl.description,
    };
    this.toggleClose = this.toggleClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeName = this.handleChangeName.bind(this);
    this.openModal = this.openModal.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
    this.enterPressed = this.enterPressed.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const userSettings =
      nextProps.viewer.user.consoleSettings &&
      nextProps.viewer.user.consoleSettings.videoDevices
        ? nextProps.viewer.user.consoleSettings.videoDevices
        : {
            appDetails: true,
            deviceActions: false,
            systemDetailsControls: false,
            tags: false,
          };
    this.setState({
      ...userSettings,
      clientId: nextProps.clientId,
      deviceName: nextProps.deviceControl.name,
      deviceDescription: nextProps.deviceControl.description,
    });
  }
  toggleClose() {
    this.props.toggleClose();
  }

  openModal() {
    this.setState({ changeNameModal: true });
  }

  handleChange(name) {
    this.setState({ [name]: !this.state[name] });
  }

  handleChangeName(event, name) {
    this.setState({
      [name]: event.target.value,
    });
  }

  handleClose() {
    this.setState({ changeNameModal: false });
  }

  handleConfirm() {
    updateVideoDeviceNameMutation(
      this.props.deviceControl.id,
      this.state.deviceName,
      this.state.deviceDescription,
      () => {
        this.props.snackbar.showMessage("Device name/description changed");
        this.setState({ changeNameModal: false });
      }
    );
  }

  enterPressed(event) {
    const code = event.keyCode || event.which;
    if (code === 13) {
      updateVideoDeviceNameMutation(
        this.props.deviceControl.id,
        this.state.deviceName,
        this.state.deviceDescription,
        () => {
          this.props.snackbar.showMessage("Device name/description changed");
          this.setState({ changeNameModal: false });
        }
      );
    }
  }

  render() {
    const { classes, deviceControl, viewer, userRoot } = this.props;
    const {
      appDetails,
      deviceActions,
      systemDetailsControls,
      tags,
      changeNameModal,
      deviceName,
      deviceDescription,
    } = this.state;
    return (
      <Paper className={classes.root} elevation={1}>
        <div className={classes.horizontalContainer}>
          <Accordion className={classes.expansion}>
            <AccordionSummary
              className={classes.expansionSummary}
              expandIcon={
                <Tooltip
                  id="tooltip-bottom"
                  title="Options"
                  placement="bottom"
                  enterTouchDelay={200}
                >
                  <SettingsIcon />
                </Tooltip>
              }
            >
              <div className={classes.verticalContainer}>
                <Typography
                  className={classes.typo2}
                  variant="body1"
                  color="textPrimary"
                >
                  {deviceControl.name}
                </Typography>
                <Typography
                  className={classes.typo2}
                  variant="body1"
                  color="textSecondary"
                >
                  {deviceControl.description || "No description"}
                </Typography>
              </div>
            </AccordionSummary>
            <AccordionDetails className={classes.expansionDetails}>
              <FormGroup>
                {permissions([], [], [1, 2]) && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.deviceActions}
                        onClick={() => this.handleChange("deviceActions")}
                        classes={{
                          checked: classes.checked,
                        }}
                        value="Device Actions"
                      />
                    }
                    label="Device Actions"
                  />
                )}
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.appDetails}
                      onClick={() => this.handleChange("appDetails")}
                      classes={{
                        checked: classes.checked,
                      }}
                      value="Application Details"
                    />
                  }
                  label="Application Details"
                />
              </FormGroup>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.systemDetailsControls}
                      onClick={() => this.handleChange("systemDetailsControls")}
                      classes={{
                        checked: classes.checked,
                      }}
                      value="System Details & Controls"
                    />
                  }
                  label="System Details & Controls"
                />
                {permissions([], [], [1, 2]) && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={tags}
                        onClick={() => this.handleChange("tags")}
                        classes={{
                          checked: classes.checked,
                        }}
                        value="Tag Settings"
                      />
                    }
                    label="Tag Settings"
                  />
                )}
              </FormGroup>
            </AccordionDetails>
          </Accordion>
          {permissions([], [], [1]) && (
            <div className={classes.close}>
              <IconButton onClick={this.openModal}>
                <Tooltip
                  id="tooltip-bottom"
                  title="Change Device Name"
                  placement="bottom"
                  enterTouchDelay={200}
                >
                  <EditIcon className={classes.closeIcon} />
                </Tooltip>
              </IconButton>
            </div>
          )}
          <div className={classes.close}>
            <Tooltip
              id="tooltip-bottom"
              title="Close"
              placement="bottom"
              enterTouchDelay={200}
            >
              <IconButton aria-label="Close">
                <CloseIcon
                  className={classes.closeIcon}
                  onClick={this.toggleClose}
                />
              </IconButton>
            </Tooltip>
          </div>
        </div>
        {appDetails && (
          <AppDetails
            viewer={viewer}
            deviceId={deviceControl.id}
            lastSync={deviceControl.lastSync}
            appUpTime={deviceControl.appUpTime}
            appVersion={deviceControl.appVersion}
            volume={deviceControl.volume}
            currentlyPlaying={deviceControl.currentlyPlaying}
            totalStorage={deviceControl.totalStorage}
            usedStorage={deviceControl.usedStorage}
            availableStorage={deviceControl.availableStorage}
            userRoot={userRoot}
          />
        )}
        {permissions([], [], [1, 2]) && deviceActions && (
          <DeviceActions
            viewer={viewer}
            deviceControl={deviceControl}
            userRoot={userRoot}
          />
        )}
        {systemDetailsControls && (
          <SystemDetailsControls
            viewer={viewer}
            model={deviceControl.model}
            lastOnline={deviceControl.lastOnline}
            serialNumber={deviceControl.serialNumber}
            lanMacAddress={deviceControl.lanMacAddress}
            macAddress={deviceControl.macAddress}
            internalIp={deviceControl.internalIp}
            publicIp={deviceControl.publicIp}
            hdmiStatus={deviceControl.hdmiStatus}
            userRoot={userRoot}
          />
        )}
        {permissions([], [], [1, 2]) && tags && (
          <Tag
            viewer={viewer}
            deviceId={deviceControl.id}
            tagList={deviceControl.tags}
          />
        )}
        {permissions([], [], [1]) && (
          <Dialog open={changeNameModal} className={classes.dialog}>
            <div className={classes.close}>
              <IconButton aria-label="Close" className={classes.close}>
                <CloseIcon
                  className={classes.closeIcon}
                  onClick={this.handleClose}
                />
              </IconButton>
            </div>
            <DialogTitle className={classes.dialogTitle}>
              Edit name for current device
            </DialogTitle>
            <div className={classes.verticalContainer}>
              <TextField
                id="outlined-name"
                label="Device name"
                className={classes.textField}
                value={deviceName}
                onChange={(event) => this.handleChangeName(event, "deviceName")}
                onKeyPress={this.enterPressed}
                margin="normal"
                variant="outlined"
              />
              <TextField
                id="outlined-description"
                label="Device description"
                className={classes.textField}
                value={deviceDescription}
                onChange={(event) =>
                  this.handleChangeName(event, "deviceDescription")
                }
                onKeyPress={this.enterPressed}
                margin="normal"
                variant="outlined"
              />
            </div>
            <div className={classes.dialog}>
              <Button
                variant="contained"
                color="secondary"
                onClick={this.handleClose}
                className={classes.button}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={this.handleConfirm}
                className={classes.button}
              >
                Confirm Change
              </Button>
            </div>
          </Dialog>
        )}
      </Paper>
    );
  }
}

VideoDeviceControl.propTypes = {
  classes: PropTypes.object.isRequired, //eslint-disable-line
  viewer: PropTypes.object.isRequired, //eslint-disable-line
  snackbar: PropTypes.object, //eslint-disable-line
  clientId: PropTypes.string.isRequired,
  deviceIds: PropTypes.array.isRequired, //eslint-disable-line
  deviceControl: PropTypes.object, //eslint-disable-line
  toggleClose: PropTypes.func, //eslint-disable-line
  userRoot: PropTypes.object, //eslint-disable-line
};

export default compose(withStyles(styles), withSnackbar())(VideoDeviceControl);
