/* eslint-disable no-underscore-dangle, no-return-assign */

import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import compose from "recompose/compose";
import { withSnackbar } from "material-ui-snackbar-provider";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import deleteVideoClientMutation from "../../mutations/deleteVideoClientMutation";

const styles = {
  horizontalContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  button: {
    margin: "8px 8px 8px 8px",
    maxWidth: 140,
  },
  container: {
    overflow: "auto",
  },
  checkbox: {
    marginLeft: 8,
  },
  textField: {
    margin: 8,
    flex: "auto",
    minWidth: 100,
  },
};

class EditVideoClient extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: this.props.viewer.allVideoClients.edges
        .filter((item) => item && item.node)
        .filter((item) => item.node.id === this.props.clientId)[0].node.name,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleSubmit() {
    deleteVideoClientMutation(this.props.clientId, this.state.name, () => {
      this.props.handleClose();
      return this.props.snackbar.showMessage("Video client edited.");
    });
  }
  handleChange(event, name) {
    this.setState({
      [name]: event.target.value,
    });
  }

  render() {
    const { classes, viewer } = this.props;
    const { name } = this.state;
    const error =
      viewer.allVideoClients.edges
        .filter((item) => item && item.node)
        .filter((item) => item.node.name.toLowerCase() === name.toLowerCase())
        .length > 0;
    return (
      <div className={classes.container}>
        <Typography variant="subtitle1">Edit video client</Typography>
        <Divider />
        <TextField
          id="name"
          label="Name"
          error={error}
          className={classes.textField}
          value={name}
          onChange={(event) => this.handleChange(event, "name")}
          margin="normal"
        />
        <Divider />
        <div className={classes.horizontalContainer}>
          <Button
            variant="contained"
            color="primary"
            onClick={this.handleSubmit}
            className={classes.button}
          >
            Create
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={this.props.handleClose}
            className={classes.button}
          >
            Cancel
          </Button>
        </div>
      </div>
    );
  }
}

EditVideoClient.propTypes = {
  classes: PropTypes.object.isRequired, //eslint-disable-line
  viewer: PropTypes.object.isRequired, //eslint-disable-line
  clientId: PropTypes.string.isRequired, //eslint-disable-line
  snackbar: PropTypes.object, //eslint-disable-line
  handleClose: PropTypes.func, //eslint-disable-line
};

export default compose(withStyles(styles), withSnackbar())(EditVideoClient);
