import { commitMutation } from "react-relay";
import graphql from 'babel-plugin-relay/macro';
import Environment from "../../environmentLogin";

const mutation = graphql`
  mutation forgotPasswordMutation($inputemail: String!) {
    forgotPassword(email: $inputemail) {
      id
    }
  }
`;

export default (email, callback) => {
  const variables = {
    inputemail: email,
    clientMutationId: "",
  };

  commitMutation(Environment, {
    mutation,
    variables,
    onCompleted: (response) => {
      const id = response.id;
      callback(id);
    },
    onError: (err) => console.error(err), // eslint-disable-line
  });
};
