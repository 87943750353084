import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { withSnackbar } from "material-ui-snackbar-provider";
import compose from "recompose/compose";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import SettingsRemoteIcon from "@material-ui/icons/SettingsRemoteRounded";
import DesktopAccessDisabledIcon from "@material-ui/icons/DesktopAccessDisabled";
import CachedIcon from "@material-ui/icons/Cached";
import RefreshIcon from "@material-ui/icons/Refresh";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import EditIcon from "@material-ui/icons/Edit";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import moment from "moment";
import config from "../../../config.js";
import statusAudioDeviceMutation from "../../mutations/statusAudioDeviceMutation";
import rebootAudioDeviceMutation from "../../mutations/rebootAudioDeviceMutation";
import refreshWebkeyMutation from "../../mutations/refreshWebkeyMutation";
import getNewWebkeyTokenMutation from "../../mutations/getNewWebkeyTokenMutation";
import updateOfflineLimitsMutation from "../../mutations/updateOfflineLimitsMutation";
import updateNewLocationMutation from "../../mutations/updateNewLocationMutation";
import permissions2 from "../../authentication/UIPermissions";
import FileCopyIcon from "@material-ui/icons/FileCopy";
const styles = (theme) => ({
  root: {
    margin: "4px 0px 4px 0px",
  },
  card: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "6px",
  },
  content: {
    background: "#f9f9f9",
  },
  expansion: {
    flex: "auto",
    boxShadow:
      "0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12)",
  },
  expansionSummary: {
    minHeight: 16,
    height: 24,
  },
  expansionDetails: {
    padding: "8px 8px 8px 8px",
    display: "flex",
    flexDirection: "column",
  },
  actionsContainer: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
  },
  button2: {
    width: 100,
    margin: theme.spacing(1),
  },
  horizontalContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  verticalContainer: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    margin: 4,
  },
  typo1: {
    display: "flex",
    alignItems: "center",
    color: "#222831",
  },
  typo2: {
    paddingLeft: 4,
    color: "#222831",
    fontWeight: "lighter",
    letterSpacing: "0.06em",
    marginBottom: "3px",
  },
  typo3: {
    alignSelf: "center",
    paddingLeft: 16,
  },
  textField: {
    margin: 8,
    width: 62,
  },
  textField2: {
    margin: 8,
    width: 200,
  },
  closeIcon: {
    height: 16,
    width: 16,
  },
  modal: {
    top: "45%",
  },
  dialog: {
    background: "secondary",
    margin: theme.spacing(3),
  },
  dialogTitle: {
    padding: "0px 24px 0px 24px",
    minWidth: 250,
  },
});

class SystemDetailsControls extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ask: false,
      ask2: false,
      modal: false,
      maximumDaysOffline: this.props.deviceControl.maximumDaysOffline || 90,
      maximumSongsOffline:
        this.props.deviceControl.maximumSongsOffline || 50000,
      latitude: this.props.deviceControl.latitude || "0",
      longitude: this.props.deviceControl.longitude || "0",
    };
    this.handleDelete = this.handleDelete.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeLocation = this.handleChangeLocation.bind(this);
    this.handleUpdateLimits = this.handleUpdateLimits.bind(this);
    this.openModal = this.openModal.bind(this);
    this.enteroptionPressed = this.enteroptionPressed.bind(this);
    this.handleNewLocation = this.handleNewLocation.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.deviceId !== nextProps.deviceId) {
      this.setState({
        maximumDaysOffline: nextProps.deviceControl.maximumDaysOffline || 90,
        maximumSongsOffline:
          nextProps.deviceControl.maximumSongsOffline || 50000,
        latitude: nextProps.latitude,
        longitude: nextProps.longitude,
      });
    }
    return true;
  }

  handleDelete() {
    statusAudioDeviceMutation(this.props.deviceId, "INACTIVE", () =>
      this.props.snackbar.showMessage("Device blackilsted.")
    );
  }

  handleChange(event, field) {
    const valueParsed = parseInt(event.target.value, 10);
    let value = 0;
    if (valueParsed > 99999) {
      value = 99999;
    } else if (valueParsed < 1) {
      value = 1;
    } else {
      value = valueParsed;
    }
    this.setState({ [field]: value });
  }

  handleChangeLocation(event, field) {
    this.setState({ [field]: event.target.value.toString() });
  }

  handleUpdateLimits() {
    updateOfflineLimitsMutation(
      this.props.deviceId,
      this.state.maximumDaysOffline,
      this.state.maximumSongsOffline,
      () => this.props.snackbar.showMessage("Limits updated.")
    );
  }

  openModal() {
    this.setState({
      modal: true,
    });
  }

  handleNewLocation() {
    updateNewLocationMutation(
      this.props.deviceId,
      this.state.latitude.toString(),
      this.state.longitude.toString(),
      () => {
        this.props.snackbar.showMessage("Device location changed");
        this.setState({ modal: false });
      }
    );
  }

  enteroptionPressed(event) {
    const code = event.keyCode || event.which;
    if (code === 13) {
      updateNewLocationMutation(
        this.props.deviceId,
        this.state.latitude.toString(),
        this.state.longitude.toString(),
        () => {
          this.props.snackbar.showMessage("Device location changed");
          this.setState({ modal: false });
        }
      );
    }
  }

  render() {
    const { classes, methods, webkeyId, webkeyBackupId } = this.props;
    const {
      id,
      makeAndModel,
      macAddress,
      lastOnline,
      serialNumber,
      ethernetMacAddress,
      internalIpAddress,
      publicIpAddress,
    } = this.props.deviceControl;

    const {
      ask,
      ask2,
      maximumDaysOffline,
      maximumSongsOffline,
      modal,
      latitude,
      longitude,
    } = this.state;

    return (
      <div className={classes.root}>
        <Card className={classes.card}>
          <CardContent className={classes.content}>
            <Typography className={classes.typo1} variant="h6">
              System Details & Controls
            </Typography>
            <div className={classes.horizontalContainer}>
              <div className={classes.verticalContainer}>
                <Typography
                  className={classes.typo2}
                  variant="body2"
                  color="textSecondary"
                >
                  <b>Make and model: </b>
                  {makeAndModel}
                </Typography>
                <Typography
                  className={classes.typo2}
                  variant="body2"
                  color="textSecondary"
                >
                  <b>MAC Address: </b>
                  {ethernetMacAddress}
                  <span>
                    <IconButton
                      className={classes.button}
                      onClick={() => {
                        navigator.clipboard.writeText(ethernetMacAddress);
                      }}
                    >
                      <FileCopyIcon style={{ width: "12px", height: "12px" }} />
                    </IconButton>
                  </span>
                </Typography>
                <Typography
                  className={classes.typo2}
                  variant="body2"
                  color="textSecondary"
                >
                  <b>WLAN MAC Address: </b>
                  {macAddress}
                  <span>
                    <IconButton
                      className={classes.button}
                      onClick={() => {
                        navigator.clipboard.writeText(macAddress);
                      }}
                    >
                      <FileCopyIcon style={{ width: "12px", height: "12px" }} />
                    </IconButton>
                  </span>
                </Typography>
                <Typography
                  className={classes.typo2}
                  variant="body2"
                  color="textSecondary"
                >
                  <b>Serial Number: </b>
                  {serialNumber}
                </Typography>
              </div>
              <div
                className={classes.verticalContainer}
                style={{ marginLeft: "30px" }}
              >
                <Typography
                  className={classes.typo2}
                  variant="body2"
                  color="textSecondary"
                >
                  <b>Device internal IP: </b>
                  {internalIpAddress}
                </Typography>
                <Typography
                  className={classes.typo2}
                  variant="body2"
                  color="textSecondary"
                >
                  <b>Device public IP: </b>
                  {publicIpAddress}
                </Typography>
                <div
                  className={classes.horizontalContainer}
                  style={{ alignItems: "center" }}
                >
                  <Typography
                    className={classes.typo2}
                    variant="body2"
                    color="textSecondary"
                  >
                    <b>Device location: </b>
                    {latitude && longitude ? (
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <Typography>{this.props.latitude}</Typography>
                        <Typography>{this.props.longitude}</Typography>
                      </div>
                    ) : (
                      "Unavailable"
                    )}
                  </Typography>
                  <IconButton onClick={this.openModal} color="primary">
                    <Tooltip
                      id="tooltip-bottom"
                      title="Edit device location"
                      placement="bottom"
                      enterTouchDelay={200}
                    >
                      <EditIcon className={classes.closeIcon} />
                    </Tooltip>
                  </IconButton>
                </div>
                <Typography
                  className={classes.typo2}
                  variant="body2"
                  color="textSecondary"
                >
                  <b>Last Online: </b>
                  {moment.unix(Math.round(lastOnline / 1000)).format("lll")}
                </Typography>
              </div>
            </div>
          </CardContent>
          {permissions2("5cab57642d3280154e4e536e", methods) && <Divider />}
          <Typography
            className={classes.typo2}
            variant="body2"
            color="textSecondary"
          >
            Set offline device play limits:
          </Typography>
          <div className={classes.horizontalContainer}>
            <TextField
              className={classes.textField}
              id="maximum-days-offline"
              label="Days"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              value={maximumDaysOffline}
              onChange={(event) =>
                this.handleChange(event, "maximumDaysOffline")
              }
            />
            <TextField
              className={classes.textField}
              id="maximum-songs-offline"
              label="Songs"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              value={maximumSongsOffline}
              onChange={(event) =>
                this.handleChange(event, "maximumSongsOffline")
              }
            />
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={this.handleUpdateLimits}
              style={{ marginLeft: "12px" }}
              margin="dense"
            >
              Save
            </Button>
          </div>
          {permissions2("5cab57642d3280154e4e536e", methods) && <Divider />}
          <div className={classes.horizontalContainer}>
            <Tooltip
              title="Control dashboard remote connect"
              placement="bottom"
              enterTouchDelay={200}
            >
              <span>
                <IconButton
                  className={classes.button}
                  disabled={!webkeyId}
                  // color={webkeyId ? "primary" : "secondary"}
                  style={webkeyId ? { color: "#000" } : {}}
                  onClick={() =>
                    getNewWebkeyTokenMutation(webkeyId, "primary", (token) =>
                      window.open(
                        `${config.WEBKEY_URL}mgm?publicid=${webkeyId}&ratoken=${token}`
                      )
                    )
                  }
                >
                  <SettingsRemoteIcon />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip
              title="Backup dashboard remote connect"
              placement="bottom"
              enterTouchDelay={200}
            >
              <span>
                <IconButton
                  className={classes.button}
                  style={webkeyBackupId ? { color: "#f9a602" } : {}}
                  disabled={!webkeyBackupId}
                  onClick={() =>
                    getNewWebkeyTokenMutation(
                      webkeyBackupId,
                      "backup",
                      (token) =>
                        window.open(
                          `${config.WEBKEY_URL_BACKUP}mgm?publicid=${webkeyBackupId}&ratoken=${token}`
                        )
                    )
                  }
                >
                  <SettingsRemoteIcon />
                </IconButton>
              </span>
            </Tooltip>
            <Divider orientation="vertical" flexItem />
            <Tooltip
              title="Control Refresh"
              placement="bottom"
              enterTouchDelay={200}
            >
              <span>
                <IconButton
                  className={classes.button}
                  disabled={!webkeyId}
                  // color={webkeyId ? "primary" : "secondary"}
                  style={webkeyId ? { color: "#000" } : {}}
                  onClick={() =>
                    refreshWebkeyMutation(id, "primary", () =>
                      this.props.snackbar.showMessage(
                        "Control dashboard refreshed"
                      )
                    )
                  }
                >
                  <CachedIcon />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip
              title="Recovery Refresh"
              placement="bottom"
              enterTouchDelay={200}
            >
              <span>
                <IconButton
                  className={classes.button}
                  style={webkeyBackupId ? { color: "#f9a602" } : {}}
                  disabled={!webkeyBackupId}
                  onClick={() =>
                    refreshWebkeyMutation(id, "backup", () =>
                      this.props.snackbar.showMessage(
                        "Recovery dashboard refreshed"
                      )
                    )
                  }
                >
                  <CachedIcon />
                </IconButton>
              </span>
            </Tooltip>
            <Divider orientation="vertical" flexItem />
            {permissions2("5db6c9e33c6f6a66317db8e8", methods) && !ask2 && (
              <Tooltip
                title="Reboot device"
                placement="bottom"
                enterTouchDelay={200}
              >
                <span>
                  <IconButton
                    className={classes.button}
                    style={{ color: "#000" }}
                    onClick={() => this.setState({ ask2: !ask2 })}
                  >
                    <RefreshIcon />
                  </IconButton>
                </span>
              </Tooltip>
            )}
            {permissions2("5cab57642d3280154e4e536e", methods) && !ask && (
              <Tooltip
                title="Blackist device"
                placement="bottom"
                enterTouchDelay={200}
              >
                <span>
                  <IconButton
                    className={classes.button}
                    style={{ color: "#f9a602" }}
                    onClick={() => this.setState({ ask: !ask })}
                  >
                    <DesktopAccessDisabledIcon />
                  </IconButton>
                </span>
              </Tooltip>
            )}
            <Divider orientation="vertical" flexItem />
          </div>
          {permissions2("5cab57642d3280154e4e536e", methods) && ask && (
            <div className={classes.horizontalContainer}>
              <Typography
                className={classes.typo3}
                variant="subtitle1"
                color="textSecondary"
                align="center"
              >
                Are you sure you want to blacklist this device?
              </Typography>
              <Button
                variant="contained"
                color="primary"
                className={classes.button2}
                onClick={() => this.setState({ ask: !ask })}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="secondary"
                className={classes.button2}
                onClick={this.handleDelete}
              >
                Yes
              </Button>
            </div>
          )}
          {permissions2("5db6c9e33c6f6a66317db8e8", methods) && ask2 && (
            <div className={classes.horizontalContainer}>
              <Typography
                className={classes.typo3}
                variant="subtitle1"
                color="textSecondary"
                align="center"
              >
                Are you sure you want to reboot this device?
              </Typography>
              <Button
                variant="contained"
                color="primary"
                className={classes.button2}
                onClick={() => this.setState({ ask2: !ask2 })}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="secondary"
                className={classes.button2}
                onClick={() =>
                  rebootAudioDeviceMutation(id, () => {
                    this.setState({ ask2: !ask2 });
                    return this.props.snackbar.showMessage(
                      "Device reboot in progress"
                    );
                  })
                }
              >
                Yes
              </Button>
            </div>
          )}
        </Card>
        <Dialog
          open={modal}
          className={classes.dialog}
          style={{ zIndex: 999999 }}
        >
          <div className={classes.close}>
            <IconButton aria-label="Close" className={classes.close}>
              <CloseIcon
                className={classes.closeIcon}
                onClick={() =>
                  this.setState({
                    modal: false,
                  })
                }
              />
            </IconButton>
          </div>
          <DialogTitle className={classes.dialogTitle}>
            Edit location for current device
          </DialogTitle>
          <div className={classes.verticalContainer}>
            <TextField
              id="latitude-input"
              label="Latitude"
              value={latitude}
              className={classes.textField2}
              onChange={(event) => this.handleChangeLocation(event, "latitude")}
              onKeyPress={this.enteroptionPressed}
              margin="normal"
            />
            <TextField
              id="longitude-input"
              label="Longitude"
              className={classes.textField2}
              value={longitude}
              onChange={(event) =>
                this.handleChangeLocation(event, "longitude")
              }
              onKeyPress={this.enteroptionPressed}
              margin="normal"
            />
          </div>
          <div className={classes.dialog}>
            <Button
              variant="contained"
              color="primary"
              onClick={this.handleNewLocation}
              className={classes.button}
            >
              Save
            </Button>
          </div>
        </Dialog>
      </div>
    );
  }
}

SystemDetailsControls.propTypes = {
  classes: PropTypes.object,
  userRoot: PropTypes.object,
  deviceId: PropTypes.string,
  make: PropTypes.string,
  model: PropTypes.string,
  wlan: PropTypes.string,
  lastOnline: PropTypes.number,
  serialNumber: PropTypes.string,
  lan: PropTypes.string,
  IP_local: PropTypes.string,
  IP_public: PropTypes.string,
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  snackbar: PropTypes.object,
};

export default compose(
  withStyles(styles),
  withSnackbar()
)(SystemDetailsControls);
