import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import compose from "recompose/compose";
import { withSnackbar } from "material-ui-snackbar-provider";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import newVideoProfileMutation from "../../mutations/newVideoProfileMutation";

const styles = (theme) => ({
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    flex: "auto",
    minWidth: 100,
  },
  horizontalContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  verticalContainer: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
  },
  formControl: {
    margin: "16px 8px 0px 8px",
    minWidth: 120,
  },
  button: {
    margin: "8px 8px 8px 8px",
    maxWidth: 140,
  },
});

class newProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedProfile: this.props.selectedProfile,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }
  componentWillMount() {
    this.setState({
      selectedProfile: {
        ...this.state.selectedProfile,
        clientId: this.props.clientId === "ALL" ? "" : this.props.clientId,
      },
    });
  }

  handleChange(event, name) {
    this.setState({
      selectedProfile: {
        ...this.state.selectedProfile,
        [name]: event.target.value,
      },
    });
  }

  handleSave() {
    newVideoProfileMutation(
      this.state.selectedProfile.name,
      this.state.selectedProfile.format,
      this.state.selectedProfile.volume,
      this.state.selectedProfile.url,
      this.state.selectedProfile.syncTime,
      this.state.selectedProfile.rebootTime,
      this.state.selectedProfile.clientId,
      () => {
        this.props.handleClose();
        return this.props.snackbar.showMessage("Profile created successfully");
      }
    );
  }

  render() {
    const { classes, viewer, videoProfiles } = this.props;
    const { selectedProfile } = this.state;
    return (
      <div>
        <Typography variant="h6" id="modal-edit-title">
          {`New profile - ${selectedProfile.name}`}
        </Typography>
        <Divider />
        <form
          className={classes.verticalContainer}
          noValidate
          autoComplete="off"
        >
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="format-native-simple">Video Client</InputLabel>
            <Select
              native
              value={selectedProfile.clientId}
              onChange={(event) => this.handleChange(event, "clientId")}
              inputProps={{
                id: "clientId-native-simple",
              }}
            >
              <option value="" />
              {viewer.allVideoClients.edges
                .filter((item) => item && item.node)
                .map((client) => (
                  <option
                    key={client.node.id}
                    value={client.node.id}
                  >{`${client.node.name}`}</option>
                ))}
            </Select>
          </FormControl>
          <div className={classes.horizontalContainer}>
            <TextField
              id="name"
              label="Name"
              className={classes.textField}
              value={selectedProfile.name}
              onChange={(event) => this.handleChange(event, "name")}
              margin="normal"
            />
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="format-native-simple">Format</InputLabel>
              <Select
                native
                value={selectedProfile.format}
                onChange={(event) => this.handleChange(event, "format")}
                inputProps={{
                  id: "format-native-simple",
                }}
              >
                <option value="" />
                {videoProfiles.map((format) => (
                  <option key={format} value={format}>{`${format}`}</option>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className={classes.horizontalContainer}>
            <TextField
              id="url"
              label="URL"
              className={classes.textField}
              value={selectedProfile.url}
              onChange={(event) => this.handleChange(event, "url")}
              margin="normal"
            />
            <TextField
              id="volume"
              label="Volume"
              error={selectedProfile.volume < 0 || selectedProfile.volume > 15}
              className={classes.textField}
              value={selectedProfile.volume}
              type="number"
              onChange={(event) => this.handleChange(event, "volume")}
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              style={{
                width: 50,
                minWidth: 30,
                flex: "none",
              }}
            />
          </div>
          <div className={classes.horizontalContainer}>
            <TextField
              id="sync"
              label="Sync Time"
              type="time"
              className={classes.textField}
              value={selectedProfile.syncTime}
              onChange={(event) => this.handleChange(event, "syncTime")}
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                step: 900, // 15 min
              }}
            />
            <TextField
              id="reboot"
              label="Reboot Time"
              type="time"
              className={classes.textField}
              value={selectedProfile.rebootTime}
              onChange={(event) => this.handleChange(event, "rebootTime")}
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                step: 900, // 15 min
              }}
            />
          </div>
          <Divider />
          <div className={classes.horizontalContainer}>
            <Button
              variant="contained"
              color="primary"
              onClick={this.handleSave}
              className={classes.button}
            >
              Save
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={this.props.handleClose}
              className={classes.button}
            >
              Cancel
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

newProfile.propTypes = {
  classes: PropTypes.object.isRequired, //eslint-disable-line
  viewer: PropTypes.object.isRequired, //eslint-disable-line
  clientId: PropTypes.string.isRequired,
  videoProfiles: PropTypes.array.isRequired, //eslint-disable-line
  selectedProfile: PropTypes.object.isRequired, //eslint-disable-line
  snackbar: PropTypes.object, //eslint-disable-line
  handleClose: PropTypes.func, //eslint-disable-line
};

export default compose(withStyles(styles), withSnackbar())(newProfile);
