/**
 * External dependencies
 */
import graphql from "babel-plugin-relay/macro";

export const SONOS_GET_MENU = graphql`
  query getSonosMenuQuery($menuId: String!) {
    viewer {
      getSonosMenu(menuId: $menuId) {
        id
        name
        description
        schema {
          name
          pathId
          parentId
          coverName
          cover_40
          cover_290
          cover_legacy
          playlistIds
          sceneIds
          mixesArray {
            mixId
            type
          }
          autoPopulateType
          category
          subCategory
          genres
          routeItems
          playableType
        }
        active
        dateCreated
        dateModified
        clientId
        parentMenuId
        explicit
        childrenMenus {
          id
          name
          description
          schema {
            name
            pathId
            parentId
            coverName
            cover_40
            cover_290
            cover_legacy
            playlistIds
            sceneIds
            mixesArray {
              mixId
              type
            }
            autoPopulateType
            category
            subCategory
            genres
            routeItems
            playableType
          }
          explicit
          active
          dateCreated
          dateModified
          clientId
          parentMenuId
        }
      }
    }
  }
`;
