import React from "react";
import { createFragmentContainer } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import compose from "recompose/compose";
import { withSnackbar } from "material-ui-snackbar-provider";
import moment from "moment";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import TextField from "@material-ui/core/TextField";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import CheckIcon from "@material-ui/icons/Check";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import IndeterminateCheckBoxIcon from "@material-ui/icons/IndeterminateCheckBox";
import updateReadStatusMutation from "../mutations/updateReadStatusMutation";
import updateReadStatusForAllMutation from "../mutations/updateReadStatusForAllMutation";

const styles = (theme) => ({
  root: {
    width: 360,
    background: theme.palette.background.paper,
  },
  button: {
    margin: 0,
  },
  textField: {
    marginTop: 4,
    marginBottom: 4,
    marginLeft: 8,
  },
});

const arraySort = (a, b) => {
  try {
    const nameA = a.toUpperCase(); // ignore upper and lowercase
    const nameB = b.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    // names must be equal
    return 0;
  } catch (e) {
    console.log("Notifications:", e);
  }
};

class Notifications extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchField: "",
      checkBox: 0,
    };
    this.handleChange = this.handleChange.bind(this);
    this.filterChecked = this.filterChecked.bind(this);
  }

  handleChange(name, event) {
    this.setState({ [name]: event.target.value });
  }

  filterChecked() {
    const number = this.state.checkBox >= 2 ? 0 : this.state.checkBox + 1;
    this.setState({
      checkBox: number,
    });
  }

  render() {
    const { classes, viewer, userId } = this.props;
    const { searchField, checkBox } = this.state;
    const iconSelection = [
      <IndeterminateCheckBoxIcon key={1} />,
      <CheckBoxIcon key={2} />,
      <CheckBoxOutlineBlankIcon key={3} />,
    ];

    const tooltipSelection = [
      "All notifications",
      "All seen notifications",
      "All unseen notifications",
    ];
    return (
      <div className={classes.root}>
        <Tooltip
          id="tooltip-bottom"
          title="Check all"
          placement="bottom"
          enterTouchDelay={200}
        >
          <Button
            variant="text"
            size="small"
            aria-label="check-all"
            className={classes.button}
            onClick={() => updateReadStatusForAllMutation(userId)}
          >
            <DoneAllIcon />
          </Button>
        </Tooltip>
        <Tooltip
          id="tooltip-bottom"
          title={tooltipSelection[checkBox]}
          placement="bottom"
          enterTouchDelay={200}
        >
          <Button
            variant="text"
            size="small"
            aria-label="check-all"
            className={classes.button}
            onClick={this.filterChecked}
          >
            {iconSelection[checkBox]}
          </Button>
        </Tooltip>
        <TextField
          label="Search notifications"
          type="search"
          value={searchField}
          className={classes.textField}
          onChange={(event) => this.handleChange("searchField", event)}
          margin="normal"
        />
        <Divider style={{ padding: 1 }} />
        <List component="nav" dense>
          {viewer.allNotifications.edges
            .filter((item) => item && item.node)
            .sort((a, b) => arraySort(b.node.dateCreated, a.node.dateCreated))
            .filter((item) =>
              item.node.message
                .toLowerCase()
                .includes(searchField.toLowerCase())
            )
            .filter((item) => {
              if (checkBox === 1) {
                return item.node.seenBy.includes(userId);
              } else if (checkBox === 2) {
                return !item.node.seenBy.includes(userId);
              }
              return true;
            })
            .map((item) => {
              const check = item.node.seenBy.includes(userId);
              return (
                <div key={item.node.id}>
                  <ListItem>
                    <ListItemText
                      primary={item.node.message}
                      secondary={moment(item.node.dateCreated).format("LLLL")}
                    />
                    <ListItemSecondaryAction>
                      <IconButton
                        color={check ? "primary" : "default"}
                        aria-label="read-toggle"
                        onClick={() =>
                          updateReadStatusMutation(item.node.id, userId, check)
                        }
                      >
                        <CheckIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                </div>
              );
            })}
        </List>
      </div>
    );
  }
}

Notifications.propTypes = {
  classes: PropTypes.object.isRequired, //eslint-disable-line
  viewer: PropTypes.object.isRequired, //eslint-disable-line
  snackbar: PropTypes.object, //eslint-disable-line
  userId: PropTypes.string.isRequired,
};

export default compose(
  withStyles(styles),
  withSnackbar()
)(
  createFragmentContainer(Notifications, {
    viewer: graphql`
      fragment Notifications_viewer on Viewer {
        allNotifications {
          edges {
            node {
              id
              type
              message
              dateCreated
              seenBy
            }
          }
        }
      }
    `,
  })
);
