import { commitMutation } from "react-relay";
import graphql from 'babel-plugin-relay/macro';
import Environment from "../../environmentLogin";

const mutation = graphql`
  mutation resetPasswordMutation(
    $inputemail: String!
    $inputpassword: String!
    $inputtoken: String!
  ) {
    resetPassword(
      email: $inputemail
      password: $inputpassword
      token: $inputtoken
    ) {
      id
    }
  }
`;

export default (email, password, token, callback) => {
  const variables = {
    inputemail: email,
    inputpassword: password,
    inputtoken: token,
  };

  commitMutation(Environment, {
    mutation,
    variables,
    onCompleted: (response) => {
      if (response.resetPassword) {
        const id = response.resetPassword.id;
        callback(id);
      } else {
        const id = null;
        callback(id);
      }
    },
    onError: () => console.error("Invalid token"), // eslint-disable-line
  });
};
