/**
 * @flow
 * @relayHash 0a02c33059b422aaadf4b926c3677071
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type updateAudioClientLogoMutationVariables = {|
  inputid: string,
  logoFilename?: ?string,
|};
export type updateAudioClientLogoMutationResponse = {|
  +updateAudioClientLogo: ?{|
    +id: string,
    +name: ?string,
    +playlistIds: ?$ReadOnlyArray<?string>,
    +playbackIds: ?$ReadOnlyArray<?string>,
    +sceneIds: ?$ReadOnlyArray<?string>,
    +logo: ?string,
  |}
|};
export type updateAudioClientLogoMutation = {|
  variables: updateAudioClientLogoMutationVariables,
  response: updateAudioClientLogoMutationResponse,
|};
*/


/*
mutation updateAudioClientLogoMutation(
  $inputid: String!
  $logoFilename: String
) {
  updateAudioClientLogo(id: $inputid, logoFilename: $logoFilename) {
    id
    name
    playlistIds
    playbackIds
    sceneIds
    logo
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "inputid"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "logoFilename"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "inputid"
      },
      {
        "kind": "Variable",
        "name": "logoFilename",
        "variableName": "logoFilename"
      }
    ],
    "concreteType": "AudioClient",
    "kind": "LinkedField",
    "name": "updateAudioClientLogo",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "playlistIds",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "playbackIds",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "sceneIds",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "logo",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "updateAudioClientLogoMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "updateAudioClientLogoMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "0a02c33059b422aaadf4b926c3677071",
    "metadata": {},
    "name": "updateAudioClientLogoMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e6053e60df569d1e37ee679fdd1ae2ac';

module.exports = node;
