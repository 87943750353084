import React, { useEffect, useState } from "react";
import { useLazyLoadQuery } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import { withStyles } from "@material-ui/core/styles";
import compose from "recompose/compose";
import { withSnackbar } from "material-ui-snackbar-provider";
import { List, ListItem, ListItemText, ListItemIcon } from "@material-ui/core";
import { ListAlt } from "@material-ui/icons";
import downloadSongReportMutation from "../mutations/downloadSongReportMutation";

const query = graphql`
  query SongReportingListQuery {
    viewer {
      allSongsReports {
        id
        name
        filename
        createdAt
      }
    }
  }
`;

const styles = {
  horizontalContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  verticalContainer: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
  },
  h2Style: {
    marginLeft: "12px",
    paddingTop: "12px",
  },
  list: {
    width: "30%",
    height: "100%",
    minWidth: 370,
    padding: 4,
  },
  menusView: {
    height: "calc(100% - 64px)",
    overflowY: "scroll",
    padding: 0,
    marginTop: 2,
    borderWidth: 2,
    borderStyle: "solid",
    borderColor: "lightgrey",
  },
  textField: {
    width: "100%",
  },
  listItem: {},
};

const SongReportingList = (props) => {
  const { classes, routeName } = props;
  const [reports, setReports] = useState([]);

  const variables = {};
  const refetch = new Date();
  const data = useLazyLoadQuery(query, variables, {
    fetchPolicy: "store-or-network",
    networkCacheConfig: { force: true },
    fetchKey: refetch,
  });

  useEffect(() => {
    setReports(data?.viewer?.allSongsReports);
  }, [data]);

  const handleExport = (item) => {
    downloadSongReportMutation(item.id, (response) => {
      window.open(response?.downloadSongReport);
    });
  };

  return (
    <>
      <div className={classes.verticalContainer}>
        <h2 className={classes.h2Style}>
          <span>{routeName}</span>
        </h2>
        <List
          component="nav"
          className={classes.menusView}
          aria-label="contacts"
        >
          {reports.map((item) => (
            <ListItem
              key={item.id}
              component="nav"
              className={classes.root}
              aria-label="menu-list-item"
              button
              onClick={() => handleExport(item)}
            >
              <ListItemIcon>
                <ListAlt />
              </ListItemIcon>
              <ListItemText primary={item.name} secondary={item.filename} />
            </ListItem>
          ))}
        </List>
      </div>
    </>
  );
};

export default compose(withStyles(styles), withSnackbar())(SongReportingList);
