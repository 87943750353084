/**
 * @flow
 * @relayHash 92ac4cc2510641627167522f33184066
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type _AutoPopulateTypes = "CATEGORY" | "GENRE" | "NONE" | "SUBCATEGORY" | "%future added value";
export type _PlayableTypes = "BOTH" | "PLAYLISTS" | "SCENES" | "%future added value";
export type editGenreSonosRouteFolderOrderMutationVariables = {|
  menuId: string,
  pathId: number,
  sourceIndex: number,
  destinationIndex: number,
|};
export type editGenreSonosRouteFolderOrderMutationResponse = {|
  +editGenreSonosRouteFolderOrder: ?{|
    +__typename: string,
    +id: string,
    +name: ?string,
    +description: ?string,
    +schema: ?$ReadOnlyArray<?{|
      +name: string,
      +pathId: number,
      +parentId: number,
      +coverName: ?string,
      +cover_40: ?string,
      +cover_290: ?string,
      +cover_legacy: ?string,
      +playlistIds: ?$ReadOnlyArray<?string>,
      +sceneIds: ?$ReadOnlyArray<?string>,
      +autoPopulateType: ?_AutoPopulateTypes,
      +category: ?string,
      +subCategory: ?string,
      +genres: ?$ReadOnlyArray<?string>,
      +routeItems: ?number,
      +playableType: ?_PlayableTypes,
    |}>,
    +active: ?boolean,
    +dateCreated: ?any,
    +dateModified: ?any,
    +clientId: ?string,
  |}
|};
export type editGenreSonosRouteFolderOrderMutation = {|
  variables: editGenreSonosRouteFolderOrderMutationVariables,
  response: editGenreSonosRouteFolderOrderMutationResponse,
|};
*/


/*
mutation editGenreSonosRouteFolderOrderMutation(
  $menuId: String!
  $pathId: Int!
  $sourceIndex: Int!
  $destinationIndex: Int!
) {
  editGenreSonosRouteFolderOrder(menuId: $menuId, pathId: $pathId, sourceIndex: $sourceIndex, destinationIndex: $destinationIndex) {
    __typename
    id
    name
    description
    schema {
      name
      pathId
      parentId
      coverName
      cover_40
      cover_290
      cover_legacy
      playlistIds
      sceneIds
      autoPopulateType
      category
      subCategory
      genres
      routeItems
      playableType
    }
    active
    dateCreated
    dateModified
    clientId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "destinationIndex"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "menuId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "pathId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sourceIndex"
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "destinationIndex",
        "variableName": "destinationIndex"
      },
      {
        "kind": "Variable",
        "name": "menuId",
        "variableName": "menuId"
      },
      {
        "kind": "Variable",
        "name": "pathId",
        "variableName": "pathId"
      },
      {
        "kind": "Variable",
        "name": "sourceIndex",
        "variableName": "sourceIndex"
      }
    ],
    "concreteType": "SonosMenu",
    "kind": "LinkedField",
    "name": "editGenreSonosRouteFolderOrder",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      (v4/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "description",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Route",
        "kind": "LinkedField",
        "name": "schema",
        "plural": true,
        "selections": [
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "pathId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "parentId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "coverName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "cover_40",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "cover_290",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "cover_legacy",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "playlistIds",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "sceneIds",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "autoPopulateType",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "category",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "subCategory",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "genres",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "routeItems",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "playableType",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "active",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "dateCreated",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "dateModified",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "editGenreSonosRouteFolderOrderMutation",
    "selections": (v5/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "editGenreSonosRouteFolderOrderMutation",
    "selections": (v5/*: any*/)
  },
  "params": {
    "id": "92ac4cc2510641627167522f33184066",
    "metadata": {},
    "name": "editGenreSonosRouteFolderOrderMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'aa32e8695060ea9fa09660097e7fb046';

module.exports = node;
