import { commitMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import Environment from "../../environment";

const mutation = graphql`
  mutation deleteQsysZoneMutation($deviceId: String!, $zoneId: Int!) {
    deleteQsysDeviceZone(deviceId: $deviceId, zoneId: $zoneId) {
      id
      name
      description
      dateCreated
      currentLoginToken
      deviceId
      systemVersion
      generalSchedule
      isGeneralScheduleActive
      systemIsEmulating
      coreUptime
      coreDate
      coreTime
      ipAddress
      totalStorage
      availableStorage
      currentUser {
        id
        profile {
          name
          clientId
        }
        emails {
          address
        }
      }
      countryCode
      userIds {
        id
        profile {
          name
        }
        emails {
          address
        }
      }
      noOfZones
      lastHeartbeat
      client {
        id
        name
      }
      zones {
        zoneId
        zoneName
        songCount
        songId
        currentAlbum
        currentTitle
        currentArtist
        currentArtwork
        reason
        positionMillis
        durationMillis
        isScheduleActive
        schedule
        updateTrigger
        songReaction
        mixType
        mixId
        mixName
        volume
      }
    }
  }
`;

export default (deviceId, zoneId, callback) => {
  const variables = {
    deviceId,
    zoneId,
  };

  commitMutation(Environment, {
    mutation,
    variables,
    onCompleted: () => {
      callback();
    },
    onError: (err) => console.error(err), // eslint-disable-line
  });
};
