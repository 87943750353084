import { commitMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import Environment from "../../environment";

const mutation = graphql`
  mutation requestVDScreenshotSyncRebootLogsMutation(
    $inputid: String!
    $inputType: String!
  ) {
    requestVDScreenshotSyncRebootLogs(id: $inputid, type: $inputType) {
      id
      reboot
      takeScreenShot
      sync
      uploadLogs
    }
  }
`;

export default (id, type, callback) => {
  const variables = {
    inputid: id,
    inputType: type,
  };

  commitMutation(Environment, {
    mutation,
    variables,
    onCompleted: (response) => {
      const returnedId = response.requestVDScreenshotSyncRebootLogs.id;
      callback(returnedId);
    },
    onError: (err) => console.error(err), // eslint-disable-line
  });
};
