/* eslint-disable
no-unused-expressions,
no-return-assign,
react/no-array-index-key */

import React from "react";
import { createFragmentContainer } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import DeleteIcon from "@material-ui/icons/Delete";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import ReactTable from "react-table";
import "react-table/react-table.css";

import testEventMutation from "../mutations/testEventMutation";
import deleteAllEventsMutation from "../mutations/deleteAllEventsMutation";
import permissions2 from "../authentication/UIPermissions";

const styles = (theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    margin: "0px 0px 0px 0px",
  },
  content: {
    flex: "0 1 auto",
  },
  typo: {
    padding: 0,
  },
  typoClient: {
    padding: "0px 0px 0px 0px",
    color: "secondary",
  },
  button: {
    width: 70,
    marginLeft: 8,
  },
  horizontalContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  actions: {
    flexWrap: "wrap",
    display: "flex",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  textField2: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 350,
  },
  paper: {
    margin: "auto",
    width: 500,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: 16,
  },
});

// const arraySort = (a, b) => {
//   const nameA = a && a.toUpperCase(); // ignore upper and lowercase
//   const nameB = b && b.toUpperCase(); // ignore upper and lowercase
//   if (nameA < nameB) {
//     return -1;
//   }
//   if (nameA > nameB) {
//     return 1;
//   }
//   // names must be equal
//   return 0;
// };

class Events extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tag: "",
      deviceId: "",
      methodsAndroid: "",
    };
    this.handleChange = this.handleChange.bind(this);
  }

  shouldComponentUpdate() {
    if (this.props.match.location.pathname === window.location.pathname) {
      return true;
    }
    return false;
  }

  handleChange(name, event) {
    this.setState({
      [name]: event.target.value,
    });
  }

  render() {
    const { classes, viewer, methods } = this.props;
    const { tag, deviceId, methodsAndroid } = this.state;
    const data = viewer.allEvents.edges
      .filter((item) => item && item.node)
      .map(({ node }) => node);
    const allDevices = viewer.allAudioDevices.edges
      .filter((item) => item && item.node)
      .map(({ node }) => node);
    const pageSize = 20;
    const fixedColumns = [
      {
        id: "deviceUid",
        Header: "Device Name",
        accessor: (a) => {
          const device = allDevices.find((item) => item.uid === a.deviceUid);
          return device ? device.name : "Unknown device";
        },
        maxWidth: 200,
      },
      {
        id: "methods",
        Header: "Methods",
        accessor: (a) => a.methods && a.methods.join(","),
        maxWidth: 200,
      },
      {
        id: "userId",
        Header: "User",
        accessor: (a) => {
          const userObj = viewer.allUsers.edges
            .filter((item) => item && item.node)
            .filter((item) => item.node.id === a.userId)[0];
          return userObj ? userObj.node.profile.name : "Unknown user";
        },
        maxWidth: 200,
      },
      {
        id: "clientId",
        Header: "Client",
        accessor: (a) => {
          const clientObj = viewer.allClients.edges
            .filter((item) => item && item.node)
            .filter((item) => item.node.id === a.clientId)[0];
          return clientObj ? clientObj.node.name : "Unknown client";
        },
        maxWidth: 200,
      },
      {
        Header: "Tag",
        accessor: "tag",
        width: 100,
      },
      {
        Header: "Date",
        accessor: "date",
        maxWidth: 100,
      },
      {
        Header: "Status",
        accessor: "status",
        maxWidth: 70,
      },
    ];

    return permissions2("5cade9708d33061673675063", methods) ? (
      <div className={classes.root}>
        <div className={classes.horizontalContainer}>
          {permissions2("5caf273ad441fa3a6a706853", methods) && (
            <Tooltip
              id="tooltip-bottom"
              title="Run methods"
              placement="bottom"
              enterTouchDelay={200}
            >
              <Button
                variant="text"
                size="small"
                aria-label="edit"
                className={classes.button}
                disabled={!deviceId || deviceId === ""}
                onClick={() => testEventMutation(tag, deviceId, methodsAndroid)}
              >
                <PlayArrowIcon />
              </Button>
            </Tooltip>
          )}
          {permissions2("5caf285ed441fa3a6a706854", methods) && (
            <Tooltip
              id="tooltip-bottom"
              title="Delete all events"
              placement="bottom"
              enterTouchDelay={200}
            >
              <Button
                variant="text"
                size="small"
                aria-label="edit"
                className={classes.button}
                onClick={() => deleteAllEventsMutation()}
              >
                <DeleteIcon />
              </Button>
            </Tooltip>
          )}
          {permissions2("5caf273ad441fa3a6a706853", methods) && (
            <TextField
              id="outlined-name"
              label="Insert number"
              className={classes.textField}
              value={tag}
              onChange={(event) => this.handleChange("tag", event)}
              margin="normal"
              variant="outlined"
            />
          )}
          {permissions2("5caf273ad441fa3a6a706853", methods) && (
            <TextField
              id="outlined-name"
              label="Add device ID here"
              className={classes.textField2}
              value={deviceId}
              error={!deviceId || deviceId === ""}
              onChange={(event) => this.handleChange("deviceId", event)}
              margin="normal"
              variant="outlined"
            />
          )}
          {permissions2("5caf273ad441fa3a6a706853", methods) && (
            <TextField
              id="outlined-name"
              label="Insert methods divided by   | "
              className={classes.textField2}
              value={methodsAndroid}
              error={!methodsAndroid || methodsAndroid === ""}
              onChange={(event) => this.handleChange("methodsAndroid", event)}
              margin="normal"
              variant="outlined"
            />
          )}
        </div>
        <div className={classes.horizontalContainer}>
          <ReactTable
            data={data}
            columns={fixedColumns}
            defaultPageSize={pageSize}
            showPagination={data.length > pageSize}
            filterable
            defaultFilterMethod={(filter, row) =>
              String(row[filter.id])
                .toLowerCase()
                .includes(filter.value.toLowerCase())
            }
            className="-striped -highlight"
            style={{
              display: "block",
              margin: "8px 0px 8px 8px",
              fontSize: "13px",
              flex: "auto",
              minWidth: "35vw",
            }}
          />
        </div>
        <Typography
          variant="caption"
          color="secondary"
          className={classes.typo}
          style={{ paddingLeft: 8 }}
          align="left"
        >
          Tip: Hold shift when sorting to multi-sort!
        </Typography>
      </div>
    ) : null;
  }
}

Events.propTypes = {
  classes: PropTypes.object.isRequired, //eslint-disable-line
  viewer: PropTypes.object.isRequired, //eslint-disable-line
};

export default withStyles(styles)(
  createFragmentContainer(Events, {
    viewer: graphql`
      fragment Events_viewer on Viewer {
        allEvents {
          edges {
            node {
              id
              userId
              clientId
              methods
              tag
              deviceUid
              date
              status
            }
          }
        }
        allUsers {
          edges {
            node {
              id
              emails {
                address
                verified
              }
              profile {
                name
                clientId
                videoClientId
              }
            }
          }
        }
        allClients {
          edges {
            node {
              id
              name
            }
          }
        }
        allAudioDevices {
          edges {
            node {
              id
              uid
              name
            }
          }
        }
      }
    `,
  })
);
