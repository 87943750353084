import { commitMutation } from "react-relay";
import graphql from 'babel-plugin-relay/macro';
import Environment from "../../environment";

const mutation = graphql`
  mutation getNewWebkeyTokenMutation(
    $input: String!
    $serverInstance: ServerInstance!
  ) {
    getNewWebkeyToken(publicid: $input, serverInstance: $serverInstance) {
      id
      tokenId
    }
  }
`;

export default (input, serverInstance, callback) => {
  const variables = {
    input,
    serverInstance,
  };

  commitMutation(Environment, {
    mutation,
    variables,
    onCompleted: (response) => {
      if (
        response &&
        response.getNewWebkeyToken &&
        response.getNewWebkeyToken.tokenId
      ) {
        callback(response.getNewWebkeyToken.tokenId);
      }
    },
    onError: (err) => console.error(err), // eslint-disable-line
  });
};
