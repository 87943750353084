export const autoPopulateType = [
  {
    value: "NONE",
    label: "NONE",
  },
  {
    value: "GENRE",
    label: "GENRE",
  },
  {
    value: "CATEGORY",
    label: "CATEGORY",
  },
  {
    value: "SUBCATEGORY",
    label: "SUBCATEGORY",
  },
];
