import { requestSubscription } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import { ConnectionHandler } from "relay-runtime";
import Environment from "../../environment";

const subscription = graphql`
  subscription PlaylistSubscription {
    PlaylistSubscription {
      mutation
      node {
        id
        name
        bucket
        description
        cover
        tracks
        size
        failed
        updatedAt
        createdAt
        signedUrl
        genre
        subGenre
        tempo
        clientsId
        duration
      }
    }
  }
`;

export default (user, onNext) => {
  const subscriptionConfig = {
    subscription,
    refetch: (options) => console.log(options),
    updater: (store) => {
      const getRootField = store.getRootField("PlaylistSubscription");
      const mutation = getRootField.getValue("mutation");
      const node = getRootField.getLinkedRecord("node");
      switch (mutation) {
        case "CREATED":
          {
            const clientProxy = store.get("client:root:viewer:allPlaylists");
            if (clientProxy) {
              const newEdge = ConnectionHandler.createEdge(
                store,
                clientProxy,
                node,
                "PlaylistEdge"
              );
              ConnectionHandler.insertEdgeBefore(clientProxy, newEdge);
            }
          }
          break;
        case "UPDATED":
          break;
        case "DELETED":
          {
            const nodeId = node.getDataID();
            store.delete(nodeId);
          }
          break;
        default:
          console.log("Something is wrong"); //eslint-disable-line
      }
    },
    onNext,
    onCompleted: () => console.log("Playlist Done"),
    onError: (error) => console.log("An error occured:", error),
  };
  return requestSubscription(Environment, subscriptionConfig);
};
