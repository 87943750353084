/**
 * @flow
 * @relayHash 4bb66a488660c5b495664d019d111e7f
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type GoogleData = {|
  accessToken?: ?string,
  idToken?: ?string,
  id?: ?string,
  email?: ?string,
  verified_email?: ?boolean,
  name?: ?string,
  given_name?: ?string,
  family_name?: ?string,
  picture?: ?string,
|};
export type handleGoogleMutationVariables = {|
  inputname: string,
  inputemail: string,
  inputGoogleData: GoogleData,
|};
export type handleGoogleMutationResponse = {|
  +handleGoogle: {|
    +token: ?string,
    +user: ?{|
      +id: string,
      +profile: ?{|
        +clientId: ?string,
        +videoClientId: ?string,
      |},
    |},
  |}
|};
export type handleGoogleMutation = {|
  variables: handleGoogleMutationVariables,
  response: handleGoogleMutationResponse,
|};
*/


/*
mutation handleGoogleMutation(
  $inputname: String!
  $inputemail: String!
  $inputGoogleData: GoogleData!
) {
  handleGoogle(name: $inputname, email: $inputemail, googleData: $inputGoogleData) {
    token
    user {
      id
      profile {
        clientId
        videoClientId
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "inputGoogleData"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "inputemail"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "inputname"
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "email",
        "variableName": "inputemail"
      },
      {
        "kind": "Variable",
        "name": "googleData",
        "variableName": "inputGoogleData"
      },
      {
        "kind": "Variable",
        "name": "name",
        "variableName": "inputname"
      }
    ],
    "concreteType": "SigninPayload",
    "kind": "LinkedField",
    "name": "handleGoogle",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "token",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UserProfile",
            "kind": "LinkedField",
            "name": "profile",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "clientId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "videoClientId",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "handleGoogleMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "handleGoogleMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "id": "4bb66a488660c5b495664d019d111e7f",
    "metadata": {},
    "name": "handleGoogleMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ce2a7b2893aa0bef21e4644002f34252';

module.exports = node;
