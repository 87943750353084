/* eslint-disable no-unused-vars */
import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import DeleteIcon from "@material-ui/icons/Delete";
import VisibilityIcon from "@material-ui/icons/Visibility";
import compose from "recompose/compose";
import moment from "moment";
import { withSnackbar } from "material-ui-snackbar-provider";
import LogsDialog from "./LogsDialog";
import requestLogsMutation from "../../mutations/requestLogsMutation";
import deleteNPLogMutation from "../../mutations/deleteNPLogMutation";
import deletePCLogMutation from "../../mutations/deletePCLogMutation";

const styles = (theme) => ({
  root: {
    margin: "4px 0px 4px 0px",
  },
  card: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "6px",
  },
  expansion: {
    flex: "auto",
    boxShadow:
      "0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12)",
  },
  expansionSummary: {
    minHeight: 16,
    height: 24,
  },
  expansionDetails: {
    padding: "8px 8px 8px 8px",
    display: "flex",
    flexDirection: "column",
  },
  actionsContainer: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  button: {
    margin: "8px 8px 2px 8px",
    width: 175,
  },
  horizontalContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  verticalContainer: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    margin: 4,
    flex: "auto",
    textAlign: "center",
  },
  typo: {
    color: "#222831",
  },
  typo1: {
    display: "flex",
    alignItems: "center",
    color: "#222831",
  },
  typo2: {
    paddingLeft: 24,
    alignSelf: "left",
    color: "#222831",
  },
  list: {
    padding: 0,
  },
  content: {
    padding: "16px 16px 0px 16px",
    background: "#f9f9f9",
  },
  infoText: {
    color: "#222831",
  },
});

class Logs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      NPLogs: this.props.viewer.allNPLogs.edges
        .filter((item) => item && item.node)
        .filter((log) => {
          if (log?.node?.deviceId?.includes(this.props.deviceId)) {
            return log;
          }
          return false;
        }),
      PCLogs: this.props.viewer.allPCLogs.edges
        .filter((item) => item && item.node)
        .filter((log) => {
          if (log?.node?.deviceId?.includes(this.props.deviceId)) {
            return log;
          }
          return false;
        }),
      logType: "none",
      openLogsDialog: false,
      logId: "no data",
      isRequireNpLogsDisabled: false,
    };
    this.requestLogs = this.requestLogs.bind(this);
    this.handleDeleteLog = this.handleDeleteLog.bind(this);
    this.handleViewLog = this.handleViewLog.bind(this);
    this.myCallback = this.myCallback.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const NPLogs = nextProps.viewer.allNPLogs.edges
      .filter((item) => item && item.node)
      .filter((log) => {
        if (log?.node?.deviceId?.includes(nextProps.deviceId)) {
          return log;
        }
        return false;
      });
    const PCLogs = nextProps.viewer.allPCLogs.edges
      .filter((item) => item && item.node)
      .filter((log) => {
        if (log?.node?.deviceId?.includes(nextProps.deviceId)) {
          return log;
        }
        return false;
      });
    this.setState({
      NPLogs,
      PCLogs,
    });
  }

  requestLogs(logType) {
    requestLogsMutation(this.props.deviceId, logType, () =>
      this.props.snackbar.showMessage("Logs request performed")
    );
  }

  handleDeleteLog(logType, index, id) {
    if (logType === "NPLogs") {
      deleteNPLogMutation(id, () =>
        this.props.snackbar.showMessage("Logs deleted")
      );
    } else {
      deletePCLogMutation(id, () =>
        this.props.snackbar.showMessage("Logs deleted")
      );
    }
    const logs = this.state[logType];
    logs.splice(index, 1);
    this.setState({ [logType]: logs });
  }

  handleViewLog(logType, logId) {
    this.setState({
      logId,
      logType,
      openLogsDialog: true,
    });
  }

  myCallback() {
    this.setState({
      openLogsDialog: false,
    });
  }

  render() {
    const { classes, name, deviceControl } = this.props;
    const { NPLogs, PCLogs, logType, openLogsDialog, logId } = this.state;
    return (
      <div className={classes.root}>
        {openLogsDialog ? (
          <LogsDialog
            openLogsDialog={openLogsDialog}
            logType={logType}
            logId={logId}
            callback={this.myCallback}
            name={name}
          />
        ) : null}
        <Card className={classes.card}>
          <CardContent className={classes.content}>
            <Typography className={classes.typo1} variant="h6">
              Device Logs
            </Typography>
            <div className={classes.horizontalContainer}>
              <div className={classes.verticalContainer}>
                <Button
                  style={{ marginBottom: "8px" }}
                  disabled={deviceControl.requestNPLogs}
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={() => this.requestLogs("requestNPLogs")}
                >
                  Request NP Logs
                </Button>
                <Typography
                  variant="caption"
                  className={classes.typo}
                  style={{ paddingLeft: 8 }}
                  align="left"
                >
                  * Now Playing Logs
                </Typography>
                <Divider />
                {NPLogs[0] ? (
                  <List dense className={classes.list}>
                    {NPLogs.map((log, index) => (
                      <ListItem key={log.node.id}>
                        <ListItemText
                          classes={{
                            primary: classes.infoText,
                            secondary: classes.infoText,
                          }}
                          primary={moment(Number(log.node.date)).format(
                            "MMMM Do YYYY"
                          )}
                          secondary={moment(Number(log.node.date)).format(
                            "h:mm:ss a"
                          )}
                        />
                        <ListItemSecondaryAction>
                          <Tooltip
                            id="tooltip-bottom"
                            title="View Log"
                            placement="bottom"
                            enterTouchDelay={200}
                          >
                            <IconButton
                              aria-label="Download"
                              onClick={() =>
                                this.handleViewLog("NPLogs", log.node.id)
                              }
                            >
                              <VisibilityIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip
                            id="tooltip-bottom"
                            title="Delete Log"
                            placement="bottom"
                            enterTouchDelay={200}
                          >
                            <IconButton
                              aria-label="Delete"
                              onClick={() =>
                                this.handleDeleteLog(
                                  "NPLogs",
                                  index,
                                  log.node.id
                                )
                              }
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </ListItemSecondaryAction>
                      </ListItem>
                    ))}
                  </List>
                ) : (
                  <Typography
                    className={classes.typo2}
                    variant="body2"
                    color="textSecondary"
                    align="center"
                  >
                    <b>No logs available</b>
                  </Typography>
                )}
              </div>
              <div className={classes.verticalContainer}>
                <Button
                  style={{ marginBottom: "8px" }}
                  variant="contained"
                  disabled={deviceControl.requestPCLogs}
                  color="primary"
                  className={classes.button}
                  onClick={() => this.requestLogs("requestPCLogs")}
                >
                  Request PC Logs
                </Button>
                <Typography
                  variant="caption"
                  className={classes.typo}
                  style={{ paddingLeft: 8 }}
                  align="left"
                >
                  * Playing Counts Logs
                </Typography>
                <Divider />
                {PCLogs[0] ? (
                  <List dense className={classes.list}>
                    {PCLogs.map((log, index) => (
                      <ListItem key={log.node.id}>
                        <ListItemText
                          classes={{
                            primary: classes.infoText,
                            secondary: classes.infoText,
                          }}
                          primary={moment(Number(log.node.date)).format(
                            "MMMM Do YYYY"
                          )}
                          secondary={moment(Number(log.node.date)).format(
                            "h:mm:ss a"
                          )}
                        />
                        <ListItemSecondaryAction>
                          <Tooltip
                            id="tooltip-bottom"
                            title="View Log"
                            placement="bottom"
                            enterTouchDelay={200}
                          >
                            <IconButton
                              aria-label="Download"
                              onClick={() =>
                                this.handleViewLog("PCLogs", log.node.id)
                              }
                            >
                              <VisibilityIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip
                            id="tooltip-bottom"
                            title="Delete Log"
                            placement="bottom"
                            enterTouchDelay={200}
                          >
                            <IconButton
                              aria-label="Delete"
                              onClick={() =>
                                this.handleDeleteLog(
                                  "PCLogs",
                                  index,
                                  log.node.id
                                )
                              }
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </ListItemSecondaryAction>
                      </ListItem>
                    ))}
                  </List>
                ) : (
                  <Typography
                    className={classes.typo2}
                    variant="body2"
                    color="textSecondary"
                    align="center"
                  >
                    <b>No logs available</b>
                  </Typography>
                )}
              </div>
            </div>
          </CardContent>
          <Accordion disabled className={classes.expansion}>
            <AccordionSummary
              className={classes.expansionSummary}
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography className={classes.none}>Settings</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.expansionDetails}>
              <Divider />
              <div className={classes.actionsContainer}>Something here!</div>
            </AccordionDetails>
          </Accordion>
        </Card>
      </div>
    );
  }
}

Logs.propTypes = {
  classes: PropTypes.object.isRequired, //eslint-disable-line
  deviceId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  viewer: PropTypes.object.isRequired, //eslint-disable-line
  snackbar: PropTypes.object, //eslint-disable-line
};

export default compose(withStyles(styles), withSnackbar())(Logs);
