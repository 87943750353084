import { commitMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import Environment from "../../environment";

const mutation = graphql`
  mutation toggleSonosPlayablesMutation(
    $menuId: String!
    $pathId: Int!
    $playlistIds: [String]!
    $sceneIds: [String]!
    $mixesArray: [MixInput]!
  ) {
    toggleSonosPlayables(
      menuId: $menuId
      pathId: $pathId
      playlistIds: $playlistIds
      sceneIds: $sceneIds
      mixesArray: $mixesArray
    ) {
      __typename
      id
      name
      description
      schema {
        name
        pathId
        parentId
        coverName
        cover_40
        cover_290
        cover_legacy
        playlistIds
        sceneIds
        mixesArray {
          mixId
          type
        }
        autoPopulateType
        category
        subCategory
        genres
        routeItems
      }
      active
      dateCreated
      dateModified
      clientId
    }
  }
`;

export default (
  menuId,
  pathId,
  playlistIds,
  sceneIds,
  mixesArray,
  callback
) => {
  const variables = {
    menuId,
    pathId,
    playlistIds,
    sceneIds,
    mixesArray,
  };

  commitMutation(Environment, {
    mutation,
    variables,
    onCompleted: (response) => {
      callback();
    },
    onError: (err) => console.error(err), // eslint-disable-line
  });
};
