/**
 * @flow
 * @relayHash 57bd1029689aa6ece1b4965d3d8ca5df
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type _ModelMutationType = "CREATED" | "DELETED" | "UPDATED" | "UPDATEDCLIENT" | "%future added value";
export type VideoCampaignSubscriptionVariables = {||};
export type VideoCampaignSubscriptionResponse = {|
  +VideoCampaignSubscription: {|
    +mutation: _ModelMutationType,
    +node: ?{|
      +id: string,
      +name: string,
      +description: ?string,
      +clientId: string,
      +goLiveDate: any,
      +deviceIds: ?$ReadOnlyArray<string>,
      +profileIds: ?$ReadOnlyArray<string>,
      +onHold: boolean,
    |},
  |}
|};
export type VideoCampaignSubscription = {|
  variables: VideoCampaignSubscriptionVariables,
  response: VideoCampaignSubscriptionResponse,
|};
*/


/*
subscription VideoCampaignSubscription {
  VideoCampaignSubscription {
    mutation
    node {
      id
      name
      description
      clientId
      goLiveDate
      deviceIds
      profileIds
      onHold
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "VideoCampaignSubscriptionPayload",
    "kind": "LinkedField",
    "name": "VideoCampaignSubscription",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "mutation",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "VideoCampaign",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "clientId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "goLiveDate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "deviceIds",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "profileIds",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "onHold",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "VideoCampaignSubscription",
    "selections": (v0/*: any*/),
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "VideoCampaignSubscription",
    "selections": (v0/*: any*/)
  },
  "params": {
    "id": "57bd1029689aa6ece1b4965d3d8ca5df",
    "metadata": {},
    "name": "VideoCampaignSubscription",
    "operationKind": "subscription",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '441d5bd1abfada8f32aede7e420f80ec';

module.exports = node;
