/* eslint-disable no-unused-vars */
import React from "react";
import { QueryRenderer, fetchQuery } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Dialog from "@material-ui/core/Dialog";
import QueueMusicIcon from "@material-ui/icons/QueueMusic";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import LinearProgress from "@material-ui/core/LinearProgress";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Slider from "react-rangeslider";
import Divider from "@material-ui/core/Divider";
import SkipNextIcon from "@material-ui/icons/SkipNext";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import ThumbDownIcon from "@material-ui/icons/ThumbDown";
import DeleteIcon from "@material-ui/icons/Delete";
import compose from "recompose/compose";
import { withSnackbar } from "material-ui-snackbar-provider";
import { Paper } from "@material-ui/core";
import { Palette } from "react-palette";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Tooltip from "@material-ui/core/Tooltip";
import WatchIcon from "@material-ui/icons/Watch";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Chip from "@material-ui/core/Chip";
import moment from "moment";

import "../../../media/slider.css";
import updateAudioDeviceMutation from "../../mutations/updateAudioDeviceMutation";
import updateAudioDeviceNextSongMutation from "../../mutations/updateAudioDeviceNextSongMutation";
import crossfadeUpdateMutation from "../../mutations/crossfadeUpdateMutation";
import blacklistSongMutation from "../../mutations/blacklistSongMutation";
import permissions2 from "../../authentication/UIPermissions";
import Environment from "../../../environment";
import SongPlaying from "./SongPlaying";

const query = graphql`
  query NowPlayingQuery($deviceId: ID) {
    viewer {
      allSchedule(deviceId: $deviceId) {
        edges {
          node {
            id
            name
            size
            injectable
            startDate
            endDate
            clientId
            playingPlaylists {
              playlistId
              name
              volume
              startTime
              endTime
              days
              shuffle
              filenames {
                mixId
                nrOfSongs
              }
            }
          }
        }
      }
    }
  }
`;

const styles = (theme) => ({
  root: {
    margin: "4px 0px 4px 0px",
  },
  card: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "6px",
  },
  details: {
    display: "flex",
    flexDirection: "column",
    flex: "auto",
  },
  content: {
    flex: "1 0 auto",
    padding: "8px 8px 0px 8px",
    background: "#f9f9f9",
  },
  cover: {
    width: 132,
    height: 132,
  },
  controls: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(1),
  },
  playIcon: {
    height: 24,
    width: 24,
  },
  container1: {
    display: "flex",
  },
  container2: {
    display: "flex",
    margin: "-8px 8px 8px 16px",
  },
  container3: {
    display: "flex",
  },
  expansion: {
    flex: "auto",
    boxShadow:
      "0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12)",
  },
  expansionSummary: {
    minHeight: 16,
    height: 24,
    paddingTop: 12,
    paddingBottom: 12,
  },
  typo1: {
    display: "flex",
    alignItems: "center",
    color: "#222831",
  },
  typo2: {
    paddingLeft: 4,
    color: "#222831",
  },
  typo3: {
    paddingLeft: 4,
    paddingTop: 2,
    position: "absolute",
    zIndex: 1,
    alignSelf: "center",
  },
  typo4: {
    paddingLeft: 4,
    zIndex: 1,
    alignSelf: "center",
  },
  volume: {
    width: 110,
    paddingLeft: 12,
  },
  volume2: {
    width: "30%",
    alignSelf: "center",
    paddingLeft: 12,
    paddingTop: -6,
  },
  crossfade: {
    width: 210,
    marginBottom: 16,
    paddingLeft: 12,
    paddingTop: -6,
  },
  progress: {
    height: 30,
    borderRadius: "5px",
  },
  progressRoot: {
    backgroundColor: "#c4c4c4",
  },
  playlist: {
    width: "60%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  schedule: {
    width: "95%",
    backgroundColor: theme.palette.background.paper,
  },
  expansionDetails: {
    padding: "8px 8px 8px 8px",
    display: "flex",
    flexDirection: "column",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  menu: {},
  loading: {
    width: 250,
    margin: 16,
  },
  menuItem: {
    fontSize: "0.85rem",
    boxSizing: "border-box",
  },
});

const arraySort = (a, b) => {
  try {
    const nameA = a.toUpperCase(); // ignore upper and lowercase
    const nameB = b.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    // names must be equal
    return 0;
  } catch (e) {
    console.log("NowPlaying:", e);
  }
};

const getWeekDays = (weekdaysArr) => {
  const weekdays = ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"];

  return weekdaysArr
    .map((item, index) => (item ? weekdays[index] : null))
    .filter((item) => (item ? true : false))
    .join(" ");
};

class NowPlaying extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      play: false,
      playlist: "",
      anchorEl: null,
      anchorElSchedule: null,
      anchorElScene: null,
      anchorElChoose: null,
      remoteVolume: null,
      remoteVolumeMode: null,
      remotePlaylistMode: null,
      currentPlaylist: {},
      currentScene: {},
      selectedSchedule: null,
      selectedPlaylistOrScene: null,
      showDeviceSchedulesModal: false,
      crossfade: this.props.deviceQuery?.crossfade || 4,
    };
    this.playlistStatus = this.playlistStatus.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleChangeVolume = this.handleChangeVolume.bind(this);
    this.handleData = this.handleData.bind(this);
    this.handleDataScenes = this.handleDataScenes.bind(this);
    this.handleSchedule = this.handleSchedule.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleDeleteSchedule = this.handleDeleteSchedule.bind(this);
    this.handleLike = this.handleLike.bind(this);
    this.handleDislike = this.handleDislike.bind(this);
    this.handleNextTrack = this.handleNextTrack.bind(this);
    this.handleShowSchedulesPress = this.handleShowSchedulesPress.bind(this);
    this.handleChangeCrossfade = this.handleChangeCrossfade.bind(this);
  }

  handleShowSchedulesPress() {
    this.setState({ showDeviceSchedulesModal: true });
  }

  handleCloseSchedulePress() {
    this.setState({ showDeviceSchedulesModal: false });
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      this.props.deviceQuery?.currentSongId !==
      nextProps.deviceQuery?.currentSongId
    ) {
      fetchQuery(Environment, query, {
        songId: nextProps.deviceQuery?.currentSongId,
        deviceId: nextProps.deviceQuery?.id,
      });
    }

    if (this.props.deviceId !== nextProps.deviceId) {
      this.setState({
        play: false,
        playlist: "",
        anchorEl: null,
        anchorElSchedule: null,
        anchorElScene: null,
        anchorElChoose: null,
        remoteVolume: null,
        remoteVolumeMode: null,
        remotePlaylistMode: null,
        currentPlaylist: {},
        currentScene: {},
        selectedSchedule: null,
        selectedPlaylistOrScene: null,
        crossfade: nextProps.deviceQuery?.crossfade || 4,
      });
    }
    return true;
  }

  handleSave() {
    const { deviceQuery, playlistExists, sceneExists, clientId } = this.props;
    const { remoteVolume, selectedPlaylistOrScene } = this.state;

    const selectedSchedule =
      this.state.selectedSchedule || this.props.selectedSchedule || [];

    const newSchedule = selectedSchedule.map((schedule) => schedule.node.id);

    const rvm =
      this.state.remoteVolumeMode === null
        ? deviceQuery.remoteVolumeMode
        : this.state.remoteVolumeMode;

    let currentPlaylist = (playlistExists && playlistExists.node) || {};

    let currentScene = (sceneExists && sceneExists.node) || {};

    if (selectedPlaylistOrScene === "playlist") {
      currentPlaylist = this.state.currentPlaylist;
      currentScene = {};
    } else if (selectedPlaylistOrScene === "scene") {
      currentPlaylist = {};
      currentScene = this.state.currentScene;
    }

    const remotePlaylistMode =
      this.state.remotePlaylistMode === null
        ? deviceQuery.remotePlaylistMode
        : this.state.remotePlaylistMode;

    const scheduleMutation = clientId === "ALL" ? null : newSchedule;

    updateAudioDeviceMutation(
      deviceQuery.id,
      remoteVolume || deviceQuery.remoteVolume,
      currentPlaylist.id || "",
      currentScene.id || "",
      scheduleMutation,
      rvm,
      remotePlaylistMode,
      () => {
        this.setState({
          remoteVolume: null,
          remoteVolumeMode: null,
          remotePlaylistMode: null,
          currentPlaylist: {},
          currentScene: {},
          selectedSchedule: null,
          selectedPlaylistOrScene: null,
        });

        fetchQuery(Environment, query, {
          deviceId: deviceQuery.id,
          songId: deviceQuery?.currentSongId,
        });

        return this.props.snackbar.showMessage("Changes performed");
      }
    );
  }

  handleChangeVolume(remoteVolume) {
    this.setState({ remoteVolume });
  }

  handleChangeCrossfade(value) {
    this.setState({ crossfade: value });
    crossfadeUpdateMutation(this.props.deviceQuery?.id, value, () =>
      console.log("Crossfade set to:", value)
    );
  }

  playlistStatus(e) {
    this.setState({ playlist: e.target.value });
  }

  handleClose() {
    this.setState({
      anchorEl: null,
      anchorElSchedule: null,
      anchorElScene: null,
      anchorElChoose: null,
    });
  }

  handleData(playlist) {
    this.setState({
      currentPlaylist: playlist,
      currentScene: {},
      selectedPlaylistOrScene: "playlist",
    });
    this.handleClose();
  }

  handleDataScenes(scene) {
    this.setState({
      currentPlaylist: {},
      currentScene: scene,
      selectedPlaylistOrScene: "scene",
    });
    this.handleClose();
  }

  handleSchedule(schedule) {
    const selectedSchedule =
      this.state.selectedSchedule || this.props.selectedSchedule || [];

    if (schedule.node.injectable) {
      const pattern = /(\d{2})\.(\d{2})\.(\d{4})/;
      const newFirstDate = new Date(
        schedule.node.startDate.replace(pattern, "$3-$1-$2")
      );

      const newSecondDate = new Date(
        schedule.node.endDate.replace(pattern, "$3-$1-$2")
      );

      const scheduleCheck = selectedSchedule.map((sched) => {
        if (sched.node.injectable) {
          const firstDate = new Date(
            sched.node.startDate.replace(pattern, "$3-$1-$2")
          );

          const secondDate = new Date(
            sched.node.endDate.replace(pattern, "$3-$1-$2")
          );

          if (
            (firstDate < newSecondDate && secondDate > newSecondDate) ||
            (secondDate > newFirstDate && firstDate < newFirstDate) ||
            (secondDate >= newSecondDate && firstDate <= newFirstDate) ||
            (secondDate < newSecondDate && firstDate > newFirstDate)
          ) {
            this.props.snackbar.showMessage("Schedule is overlaping");
            return false;
          }
          return true;
        }
        return true;
      });
      if (!scheduleCheck.includes(false)) {
        selectedSchedule.push(schedule);
        this.setState({ selectedSchedule });
      }
    } else {
      const newSchedule = selectedSchedule.filter(
        (sched) => sched.node.injectable
      );
      newSchedule.push(schedule);
      this.setState({ selectedSchedule: newSchedule });
    }
    this.handleClose();
  }

  handleDeleteSchedule(index) {
    const selectedSchedule =
      this.state.selectedSchedule || this.props.selectedSchedule || [];

    selectedSchedule.splice(index, 1);
    this.setState({ selectedSchedule });
  }

  handleLike() {
    this.props.snackbar.showMessage("I knew you would like this.");
  }

  handleDislike() {
    blacklistSongMutation(this.props.deviceId, (response) =>
      this.props.snackbar.showMessage(response)
    );
  }

  handleNextTrack() {
    updateAudioDeviceNextSongMutation(this.props.deviceQuery.id, () =>
      this.props.snackbar.showMessage("Next track coming up.")
    );
  }

  render() {
    const {
      classes,
      deviceQuery,
      methods,
      allPlaylists,
      allSchedule,
      allScenes,
      playlistExists,
      sceneExists,
    } = this.props;
    const {
      play,
      anchorEl,
      anchorElSchedule,
      remoteVolume,
      anchorElScene,
      anchorElChoose,
      selectedPlaylistOrScene,
      crossfade,
    } = this.state;
    const defaultImage = "https://artwork.activaire.com/default.jpeg";

    let currentPlaylist = playlistExists?.node || {};

    let currentScene = sceneExists?.node || {};

    if (!playlistExists && !sceneExists) {
      currentPlaylist = {
        id: "n/a",
        name: this.props.currentM3uName || "n/a",
        filename: this.props.currentM3uName || "n/a",
      };
    }

    if (selectedPlaylistOrScene === "playlist") {
      currentPlaylist = this.state.currentPlaylist;
      currentScene = {};
    } else if (selectedPlaylistOrScene === "scene") {
      currentPlaylist = {};
      currentScene = this.state.currentScene;
    }

    const remotePlaylistMode =
      this.state.remotePlaylistMode === null
        ? deviceQuery.remotePlaylistMode
        : this.state.remotePlaylistMode;

    const remoteVolumeMode =
      this.state.remoteVolumeMode === null
        ? deviceQuery.remoteVolumeMode
        : this.state.remoteVolumeMode;

    const selectedSchedule =
      this.state.selectedSchedule || this.props.selectedSchedule || [];

    const allPlaylistsSorted = [...allPlaylists].sort((a, b) =>
      a.node && b.node ? arraySort(a.node.name, b.node.name) : null
    );

    const allScenesSorted = [...allScenes].sort((a, b) =>
      a.node && b.node ? arraySort(a.node.name, b.node.name) : null
    );

    const allScheduleSorted = [...allSchedule].sort((a, b) =>
      a.node && b.node ? arraySort(a.node.name, b.node.name) : null
    );

    const currentPlaylistName =
      deviceQuery.currentM3uName &&
      allPlaylists.find(
        (item) => item.node.filename === deviceQuery.currentM3uName
      );

    const currentPlaylistNameSafe =
      currentPlaylistName && currentPlaylistName.node
        ? currentPlaylistName.node.name
        : "N/A";

    return (
      <>
        <div className={classes.root}>
          <Card className={classes.card}>
            <div className={classes.container1}>
              <div className={classes.details}>
                <CardContent className={classes.content}>
                  <Palette
                    src={
                      deviceQuery.currentArtwork !== "default" &&
                      deviceQuery.currentArtwork
                        ? `https://artwork.activaire.com/${deviceQuery.currentArtwork}.jpeg`
                        : defaultImage
                    }
                  >
                    {({ data, loading, erroradd3Dots }) => (
                      <SongPlaying
                        songId={deviceQuery?.currentSongId}
                        data={data}
                        play={play}
                      />
                    )}
                  </Palette>
                </CardContent>
                <div className={classes.controls} style={{ marginTop: "12px" }}>
                  {permissions2("5cab59402d3280154e4e536f", methods) && (
                    <Tooltip
                      id="tooltip-bottom"
                      title="Next Track"
                      placement="bottom"
                      enterTouchDelay={200}
                    >
                      <IconButton
                        aria-label="Next"
                        onClick={this.handleNextTrack}
                      >
                        <SkipNextIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                  <Tooltip
                    id="tooltip-bottom"
                    title="I like it!"
                    placement="bottom"
                    enterTouchDelay={200}
                  >
                    <IconButton aria-label="Like" onClick={this.handleLike}>
                      <ThumbUpIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    id="tooltip-bottom"
                    title="Bleah"
                    placement="bottom"
                    enterTouchDelay={200}
                  >
                    <IconButton
                      aria-label="Dislike"
                      onClick={this.handleDislike}
                    >
                      <ThumbDownIcon />
                    </IconButton>
                  </Tooltip>
                  <div className={classes.volume} style={{ marginLeft: "4px" }}>
                    <Typography
                      className={classes.typo3}
                      variant="subtitle1"
                      color="textSecondary"
                    >
                      {`Volume ${deviceQuery.volume}/15`}
                    </Typography>
                    <LinearProgress
                      color="primary"
                      classes={{ root: classes.progressRoot }}
                      variant="determinate"
                      value={deviceQuery.volume * 6.67}
                      className={classes.progress}
                    />
                  </div>
                  {permissions2("5cab54232d3280154e4e5369", methods) && (
                    <div
                      className={classes.volume2}
                      style={{ marginLeft: "6px" }}
                    >
                      {remoteVolumeMode ? (
                        <>
                          <Typography
                            className={classes.typo4}
                            variant="subtitle1"
                          >
                            {`Volume ${
                              remoteVolume || deviceQuery.remoteVolume
                            }/15`}
                          </Typography>
                          <div>
                            <Slider
                              min={0}
                              max={15}
                              value={remoteVolume || deviceQuery.remoteVolume}
                              orientation="horizontal"
                              onChange={this.handleChangeVolume}
                            />
                          </div>
                        </>
                      ) : null}
                    </div>
                  )}
                </div>
              </div>
            </div>

            <>
              {remotePlaylistMode ? (
                <div className={classes.container3}>
                  {permissions2("5cab4f7c2d3280154e4e5367", methods) && (
                    <div className={classes.playlist}>
                      <List component="nav">
                        <ListItem
                          disabled={!remotePlaylistMode}
                          button
                          onClick={(event) =>
                            this.setState({
                              anchorElChoose: event.currentTarget,
                            })
                          }
                        >
                          <ListItemText
                            primary={
                              currentPlaylist.name ? "Playlist" : "Scene"
                            }
                            secondary={
                              currentPlaylist.name || currentScene.name
                            }
                          />
                        </ListItem>
                      </List>
                      <Menu
                        className={classes.menu}
                        id="lock-menu"
                        anchorEl={anchorElChoose}
                        open={Boolean(anchorElChoose)}
                        onClose={this.handleClose}
                      >
                        <MenuItem
                          className={classes.menuItem}
                          onClick={(event) =>
                            this.setState({ anchorEl: event.currentTarget })
                          }
                        >
                          <b>Regular</b>
                        </MenuItem>
                        <MenuItem
                          className={classes.menuItem}
                          onClick={(event) =>
                            this.setState({
                              anchorElScene: event.currentTarget,
                            })
                          }
                        >
                          <b>Scene</b>
                        </MenuItem>
                      </Menu>
                      <Menu
                        className={classes.menu}
                        id="lock-menu"
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={this.handleClose}
                      >
                        {allPlaylists &&
                          allPlaylistsSorted.map((playlist, index) => {
                            return playlist.node ? (
                              <MenuItem
                                id={playlist.node.id}
                                className={classes.menuItem}
                                key={playlist.node.id}
                                dense
                                selected={
                                  playlist.node.name === currentPlaylist.name
                                }
                                value={playlist.node.name}
                                onClick={() => this.handleData(playlist.node)}
                              >
                                <b>{playlist.node.name}</b>
                                {`\u00A0/\u00A0${playlist.node.description}`}
                              </MenuItem>
                            ) : null;
                          })}
                      </Menu>
                      <Menu
                        className={classes.menu}
                        id="lock-menu"
                        anchorEl={anchorElScene}
                        open={Boolean(anchorElScene)}
                        onClose={this.handleClose}
                      >
                        {allScenes &&
                          allScenesSorted.map((scene, index) =>
                            scene.node ? (
                              <MenuItem
                                id={scene.node.id}
                                className={classes.menuItem}
                                key={scene.node.id}
                                dense
                                selected={
                                  scene.node.name === currentPlaylist.name
                                }
                                value={scene.node.name}
                                onClick={() =>
                                  this.handleDataScenes(scene.node)
                                }
                              >
                                <b>{scene.node.name}</b>
                                {`\u00A0/\u00A0${
                                  scene.node.description || "no description"
                                }`}
                              </MenuItem>
                            ) : null
                          )}
                      </Menu>
                    </div>
                  )}
                </div>
              ) : null}

              <div className={classes.container2} style={{ marginTop: "12px" }}>
                {permissions2("5cab551f2d3280154e4e536a", methods) && (
                  <FormControlLabel
                    control={
                      <Switch
                        color="primary"
                        checked={remotePlaylistMode}
                        onChange={(event, checked) =>
                          this.setState({ remotePlaylistMode: checked })
                        }
                      />
                    }
                    label="Custom Playlist"
                  />
                )}
                {permissions2("5cab55282d3280154e4e536b", methods) && (
                  <FormControlLabel
                    control={
                      <Switch
                        color="primary"
                        checked={remoteVolumeMode}
                        onChange={(event, checked) =>
                          this.setState({ remoteVolumeMode: checked })
                        }
                      />
                    }
                    label="Custom Volume"
                  />
                )}
                {permissions2("5cab55282d3280154e4e536b", methods) && (
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={() => this.handleShowSchedulesPress()}
                  >
                    View schedule details
                  </Button>
                )}
              </div>
              {permissions2("5cab55282d3280154e4e536b", methods) && (
                <div
                  className={classes.crossfade}
                  style={{ marginLeft: "6px" }}
                >
                  <>
                    <Typography className={classes.typo4} variant="subtitle1">
                      {`Crossfade set to ${
                        crossfade || deviceQuery.crossfade
                      }  seconds`}
                    </Typography>
                    <div>
                      <Slider
                        min={0}
                        max={20}
                        value={crossfade || deviceQuery.crossfade}
                        orientation="horizontal"
                        onChange={this.handleChangeCrossfade}
                      />
                    </div>
                  </>
                </div>
              )}
            </>
            {permissions2("5cab569f2d3280154e4e536d", methods) && <Divider />}
            {permissions2("5cab569f2d3280154e4e536d", methods) && (
              <div className={classes.actionsContainer}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={this.handleSave}
                  style={{ marginLeft: "12px" }}
                >
                  Save
                </Button>
              </div>
            )}
          </Card>
        </div>
        <Paper>
          <Accordion className={classes.expansion}>
            <AccordionSummary
              className={classes.expansionSummary}
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography className={classes.typo1} variant="h6">
                Schedule Settings
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.expansionDetails}>
              {permissions2("5cab563e2d3280154e4e536c", methods) && <Divider />}
              {permissions2("5cab563e2d3280154e4e536c", methods) && (
                <div className={classes.schedule}>
                  <List component="nav">
                    <ListItem
                      button
                      aria-haspopup="true"
                      aria-controls="schedule-menu"
                      aria-label="Schedule"
                      onClick={(event) =>
                        this.setState({ anchorElSchedule: event.currentTarget })
                      }
                    >
                      <ListItemText
                        primary="Add Schedule"
                        secondary="Click here to select additional schedule"
                      />
                    </ListItem>
                  </List>
                  <Menu
                    className={classes.menu}
                    id="lock-menu"
                    anchorEl={anchorElSchedule}
                    open={Boolean(anchorElSchedule)}
                    onClose={this.handleClose}
                  >
                    {allSchedule &&
                      allScheduleSorted.map((schedule) =>
                        schedule.node ? (
                          <MenuItem
                            id={schedule.node.id}
                            className={classes.menuItem}
                            key={schedule.node.id}
                            dense
                            disabled={selectedSchedule.includes(schedule)}
                            value={schedule.node.name}
                            onClick={() => this.handleSchedule(schedule)}
                          >
                            <b>{schedule.node.name}</b>{" "}
                            {`\u00A0/\u00A0${
                              schedule.node.injectable ? "Injectable" : "Normal"
                            }`}
                          </MenuItem>
                        ) : null
                      )}
                  </Menu>
                  <List dense className={classes.list}>
                    {selectedSchedule.map((schedule, index) => (
                      <ListItem key={schedule.node.id}>
                        <ListItemText
                          primary={schedule.node.name}
                          secondary={`Time frame: ${
                            schedule.node.startDate
                          } - ${schedule.node.endDate} ${
                            schedule.node.injectable
                              ? "Injectable"
                              : "Not injectable"
                          }`}
                        />
                        <ListItemSecondaryAction>
                          <Tooltip
                            id="tooltip-left"
                            title="Remove User"
                            placement="left"
                            enterTouchDelay={200}
                          >
                            <IconButton
                              aria-label="Delete"
                              onClick={() => this.handleDeleteSchedule(index)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </ListItemSecondaryAction>
                      </ListItem>
                    ))}
                  </List>
                </div>
              )}
              {permissions2("5cab569f2d3280154e4e536d", methods) && <Divider />}
              {permissions2("5cab569f2d3280154e4e536d", methods) && (
                <div className={classes.actionsContainer}>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={this.handleSave}
                  >
                    Save
                  </Button>
                </div>
              )}
            </AccordionDetails>
          </Accordion>
          <QueryRenderer
            variables={{ deviceId: deviceQuery.id }}
            environment={Environment}
            query={query}
            render={({ error, props }) => {
              if (error) {
                return <div>{error.message}</div>;
              } else if (props) {
                const currentSchedulesData =
                  (props.viewer.allSchedule.edges[0] &&
                    props.viewer.allSchedule.edges) ||
                  [];
                return (
                  <Dialog
                    fullWidth={true}
                    maxWidth={"sm"}
                    open={this.state.showDeviceSchedulesModal}
                    onClose={() => this.handleCloseSchedulePress()}
                    aria-labelledby="max-width-dialog-title"
                  >
                    <DialogTitle id="max-width-dialog-title">
                      Scheduled playlists
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText>
                        {currentSchedulesData.map((item) => {
                          const currentScheduleData = item.node;
                          return (
                            <Accordion key={item.node.id}>
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                              >
                                <Typography
                                  className={classes.heading}
                                  style={{ fontWeight: "bold" }}
                                >
                                  {currentScheduleData &&
                                    currentScheduleData.name}
                                  :{" "}
                                  <span style={{ fontWeight: 400 }}>
                                    {currentScheduleData.startDate}
                                  </span>
                                  <span> - </span>
                                  <span style={{ fontWeight: 400 }}>
                                    {currentScheduleData &&
                                      currentScheduleData.endDate}
                                  </span>
                                </Typography>
                              </AccordionSummary>
                              <Divider />
                              <AccordionDetails
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <List>
                                  {currentScheduleData &&
                                    currentScheduleData.playingPlaylists &&
                                    currentScheduleData.playingPlaylists.map(
                                      (item, index) => {
                                        return (
                                          <ListItem key={index}>
                                            <ListItemIcon>
                                              <QueueMusicIcon />
                                            </ListItemIcon>
                                            <ListItemText
                                              primary={
                                                <div
                                                  style={{ marginTop: "6px" }}
                                                >
                                                  <span
                                                    style={{
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    {item.name} -{" "}
                                                    <span
                                                      style={{
                                                        fontWeight: "bold",
                                                        fontSize: "14px",
                                                        color: "#636060",
                                                      }}
                                                    >
                                                      {moment()
                                                        .clone()
                                                        .startOf("day")
                                                        .add(
                                                          item.startTime,
                                                          "minutes"
                                                        )
                                                        .format("HH:mm")}{" "}
                                                      -{" "}
                                                      {moment()
                                                        .clone()
                                                        .startOf("day")
                                                        .add(
                                                          item.endTime,
                                                          "minutes"
                                                        )
                                                        .format("HH:mm")}
                                                    </span>
                                                  </span>
                                                  <span></span>
                                                </div>
                                              }
                                              secondary={
                                                <div
                                                  style={{ marginTop: "6px" }}
                                                >
                                                  {item.shuffle ? (
                                                    <Chip
                                                      size="small"
                                                      label="Shufling"
                                                    />
                                                  ) : null}
                                                  <Chip
                                                    style={{
                                                      marginLeft: "3px",
                                                    }}
                                                    size="small"
                                                    label={`Volume ${item.volume}/15`}
                                                  />
                                                  <Chip
                                                    style={{
                                                      marginLeft: "3px",
                                                    }}
                                                    size="small"
                                                    label={getWeekDays(
                                                      item.days
                                                    )}
                                                  />
                                                </div>
                                              }
                                            />
                                          </ListItem>
                                        );
                                      }
                                    )}

                                  <Divider />
                                </List>
                              </AccordionDetails>
                            </Accordion>
                          );
                        })}
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        onClick={() => this.handleCloseSchedulePress()}
                        color="primary"
                      >
                        Close
                      </Button>
                    </DialogActions>
                  </Dialog>
                );
              }
              return <div>{}</div>;
            }}
          />
        </Paper>
      </>
    );
  }
}

export default compose(withStyles(styles), withSnackbar())(NowPlaying);
