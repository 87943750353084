/* eslint-disable no-underscore-dangle, no-return-assign */

import React from "react";
import { createFragmentContainer } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import compose from "recompose/compose";
import { withSnackbar } from "material-ui-snackbar-provider";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import ReactTable from "react-table";
import checkboxHOC from "react-table/lib/hoc/selectTable";
import "react-table/react-table.css";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import ActivationWizard from "./ActivationWizard";
import permissions2 from "../authentication/UIPermissions";

const CheckboxTable = checkboxHOC(ReactTable);

const styles = (theme) => ({
  root: {
    margin: "4px 0px 4px 0px",
  },
  card: {
    display: "flex",
    flexDirection: "column",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  horizontalContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  verticalContainer: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    margin: 4,
  },
  typo1: {
    paddingBottom: 4,
    display: "flex",
    alignItems: "center",
  },
  button2: {
    margin: "2px 0px 2px 0px",
  },
});

class AudioDeviceRegistration extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selection: [],
      selectAll: false,
      open: false,
    };
    this.toggleSelection = this.toggleSelection.bind(this);
    this.toggleAll = this.toggleAll.bind(this);
    this.isSelected = this.isSelected.bind(this);
    this.handleActivate = this.handleActivate.bind(this);
  }

  toggleSelection(bruteKey /* , shift, row */) {
    const key = bruteKey.replace("select-", "");
    let selection = [...this.state.selection];
    const keyIndex = selection.indexOf(key);
    if (keyIndex >= 0) {
      selection = [
        ...selection.slice(0, keyIndex),
        ...selection.slice(keyIndex + 1),
      ];
    } else {
      selection.push(key);
    }
    this.setState({ selection });
  }

  toggleAll() {
    const selectAll = !this.state.selectAll;
    const selection = [];
    if (selectAll) {
      const wrappedInstance = this.checkboxTable.getWrappedInstance();
      const currentRecords = wrappedInstance.getResolvedState().sortedData;
      currentRecords.forEach((item) => {
        selection.push(item._original.id);
      });
    }
    this.setState({ selectAll, selection });
  }

  isSelected(key) {
    return this.state.selection.includes(key);
  }

  handleActivate(bool) {
    this.setState({ open: bool });
    if (!bool) {
      this.setState({ selection: [] });
    }
  }

  render() {
    const { classes, viewer, methods } = this.props;

    const columns = [
      {
        id: "deviceStatus",
        Header: "Device Status",
        accessor: (a) => a.deviceStatus,
        maxWidth: 150,
      },
      {
        id: "clientId",
        Header: "Client",
        accessor: (a) =>
          a.clientId
            ? viewer.allClients.edges.filter(
                (item) => item.node.id === a.clientId
              )[0].node.name
            : "",
        maxWidth: 150,
      },
      {
        id: "scheduleIds",
        Header: "Schedule",
        accessor: (a) =>
          a.scheduleIds && a.scheduleIds.length > 0
            ? viewer.allSchedule.edges.filter(
                (item) => item.node.id === a.scheduleIds[0]
              )[0].node.name
            : "",
        maxWidth: 150,
      },
      {
        id: "name",
        Header: "Name",
        accessor: (a) => a.name,
        maxWidth: 400,
      },
      {
        id: "uid",
        Header: "UID",
        accessor: (a) => a.uid,
        maxWidth: 170,
      },
      {
        id: "make",
        Header: "Make & Model",
        accessor: (a) => a.makeAndModel,
        maxWidth: 150,
      },
      {
        id: "appVersion",
        Header: "App version",
        accessor: (a) => a.appVersionDS,
        maxWidth: 90,
      },
      {
        id: "wlan",
        Header: "MAC Address",
        accessor: (a) => a.macAddress,
        maxWidth: 150,
      },
      {
        id: "publicIpAddress",
        Header: "Public IP",
        maxWidth: 150,
        Cell: (props) => {
          const exceptIPs = [
            { ip: "184.74.207.243", name: "Activaire NY Office" },
            { ip: "82.76.160.5", name: "Activaire BU Office" },
          ];
          const ip =
            exceptIPs.filter(
              (item) => item.ip === props.original.publicIpAddress
            ).length > 0
              ? exceptIPs.filter(
                  (item) => item.ip === props.original.publicIpAddress
                )[0].name
              : props.original.publicIpAddress;
          return <span style={{ cursor: "pointer" }}>{ip}</span>;
        },
      },
    ];

    const { toggleSelection, toggleAll, isSelected } = this;
    const { selectAll, selection, open } = this.state;

    const checkboxProps = {
      selectAll,
      isSelected,
      toggleSelection,
      toggleAll,
      selectType: "checkbox",
    };

    const allAudioDevicesOld = viewer?.allAudioDevicesOld;

    const data = viewer.allAudioDevices.edges
      .filter((item) => item?.node)
      .filter((item) =>
        ["UNREGISTERED", "REGISTERED"].includes(item.node?.deviceStatus)
      )
      .map((item) => {
        const found =
          allAudioDevicesOld?.find(
            (item2) => item2.macAddress === item.node.macAddress
          ) || [];
        return { ...item.node, name: found?.name || item.node.macAddress };
      });

    return permissions2("5cac99893630643c2a423813", methods) ? (
      <div className={classes.root}>
        <Card className={classes.card}>
          <CardContent>
            <Typography className={classes.typo1} variant="h6">
              {"User's Device Permissions"}
            </Typography>
            <CheckboxTable
              ref={(r) => (this.checkboxTable = r)}
              keyField="id"
              data={data}
              columns={columns}
              filterable
              defaultFilterMethod={(filter, row) =>
                String(row[filter.id])
                  .toLowerCase()
                  .includes(filter.value.toLowerCase())
              }
              defaultPageSize={10}
              className="-striped -highlight"
              style={{
                display: "block",
                fontSize: "12px",
                flex: "auto",
              }}
              {...checkboxProps}
            />
            <Button
              variant="contained"
              color="primary"
              className={classes.button2}
              disabled={selection.length === 0}
              onClick={() => this.handleActivate(true)}
            >
              Activate Devices
            </Button>
          </CardContent>
          <Dialog open={open} fullWidth>
            <div className={classes.horizontalContainer}>
              <Typography
                variant="h6"
                id="modal-edit-title"
                align="center"
                style={{
                  flex: "auto",
                  alignSelf: "center",
                  marginTop: 8,
                }}
              >
                Audio device registration
              </Typography>
              <Tooltip
                id="tooltip-bottom"
                title="Close"
                placement="bottom"
                enterTouchDelay={200}
              >
                <IconButton aria-label="Close">
                  <CloseIcon
                    className={classes.closeIcon}
                    onClick={() => this.handleActivate(false)}
                  />
                </IconButton>
              </Tooltip>
            </div>
            <DialogContent>
              <ActivationWizard
                handleClose={() => this.handleActivate(false)}
                viewer={viewer}
                selectedDevices={selection}
                selection={data.filter((item) => selection.includes(item.id))}
                allAudioDevicesOld={allAudioDevicesOld}
              />
            </DialogContent>
          </Dialog>
        </Card>
      </div>
    ) : null;
  }
}

AudioDeviceRegistration.defaultProps = {};

AudioDeviceRegistration.propTypes = {
  classes: PropTypes.object.isRequired, //eslint-disable-line
  viewer: PropTypes.object.isRequired, //eslint-disable-line
  snackbar: PropTypes.object, //eslint-disable-line
};

export default compose(
  withStyles(styles),
  withSnackbar()
)(
  createFragmentContainer(AudioDeviceRegistration, {
    viewer: graphql`
      fragment AudioDeviceRegistration_viewer on Viewer {
        allTags {
          edges {
            node {
              id
              name
              description
            }
          }
        }
        allClients {
          edges {
            node {
              id
              name
            }
          }
        }
        allSchedule {
          edges {
            node {
              id
              name
              injectable
              clientId
              startDate
              endDate
            }
          }
        }
        allAudioDevices {
          edges {
            node {
              id
              name
              clientId
              deviceStatus
              wlan
              scheduleIds
              uid
              makeAndModel
              make
              appVersionDS
              publicIpAddress
              macAddress
            }
          }
        }
        allAudioDevicesOld {
          id
          name
          macAddress
        }
      }
    `,
  })
);
