/**
 * @flow
 * @relayHash 82f404c1068699f33eb1f03e21318a73
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type activateAudioDeviceMutationVariables = {|
  deviceId: string,
  deviceName: string,
  deviceTags?: ?$ReadOnlyArray<?string>,
  deviceGoLiveDate?: ?number,
|};
export type activateAudioDeviceMutationResponse = {|
  +activateAudioDevice: ?{|
    +id: string
  |}
|};
export type activateAudioDeviceMutation = {|
  variables: activateAudioDeviceMutationVariables,
  response: activateAudioDeviceMutationResponse,
|};
*/


/*
mutation activateAudioDeviceMutation(
  $deviceId: String!
  $deviceName: String!
  $deviceTags: [String]
  $deviceGoLiveDate: Float
) {
  activateAudioDevice(deviceId: $deviceId, deviceName: $deviceName, deviceTags: $deviceTags, deviceGoLiveDate: $deviceGoLiveDate) {
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "deviceGoLiveDate"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "deviceId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "deviceName"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "deviceTags"
},
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "deviceGoLiveDate",
        "variableName": "deviceGoLiveDate"
      },
      {
        "kind": "Variable",
        "name": "deviceId",
        "variableName": "deviceId"
      },
      {
        "kind": "Variable",
        "name": "deviceName",
        "variableName": "deviceName"
      },
      {
        "kind": "Variable",
        "name": "deviceTags",
        "variableName": "deviceTags"
      }
    ],
    "concreteType": "AudioDevice",
    "kind": "LinkedField",
    "name": "activateAudioDevice",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "activateAudioDeviceMutation",
    "selections": (v4/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "activateAudioDeviceMutation",
    "selections": (v4/*: any*/)
  },
  "params": {
    "id": "82f404c1068699f33eb1f03e21318a73",
    "metadata": {},
    "name": "activateAudioDeviceMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '96dfdd6cdc87be19d1fe35af8f9ece4d';

module.exports = node;
