import { commitMutation} from "react-relay";
import graphql from 'babel-plugin-relay/macro';
import Environment from "../../environment";

const mutation = graphql`
  mutation pairDeviceMutation(
    $deviceId: String!
    $publicid: String!
    $serverInstance: ServerInstance!
  ) {
    pairDevice(
      deviceId: $deviceId
      publicid: $publicid
      serverInstance: $serverInstance
    ) {
      id
      webkeyId
      webkeyBackupId
    }
  }
`;

export default (deviceId, publicid, serverInstance, callback) => {
  const variables = {
    deviceId,
    publicid,
    serverInstance,
  };

  commitMutation(Environment, {
    mutation,
    variables,
    onCompleted: (response) => {
      if (response && response.pairDevice && response.pairDevice.id) {
        callback(response.pairDevice.webkeyId);
      }
    },
    onError: (err) => console.error(err), // eslint-disable-line
  });
};
