/**
 * @flow
 * @relayHash eb6a9ce6f6ef180e39abddec5bec0b66
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type _AutoPopulateTypes = "CATEGORY" | "GENRE" | "NONE" | "SUBCATEGORY" | "%future added value";
export type _PlayableTypes = "BOTH" | "PLAYLISTS" | "SCENES" | "%future added value";
export type editSonosRouteFolderMutationVariables = {|
  menuId: string,
  pathId: number,
  name: string,
  autoPopulateType: _AutoPopulateTypes,
  coverName?: ?string,
  cover_40?: ?string,
  cover_290?: ?string,
  cover_legacy?: ?string,
  playableType?: ?_PlayableTypes,
  routeItems?: ?number,
|};
export type editSonosRouteFolderMutationResponse = {|
  +editSonosRouteFolder: ?{|
    +__typename: string,
    +id: string,
    +name: ?string,
    +description: ?string,
    +schema: ?$ReadOnlyArray<?{|
      +name: string,
      +pathId: number,
      +parentId: number,
      +coverName: ?string,
      +cover_40: ?string,
      +cover_290: ?string,
      +cover_legacy: ?string,
      +playlistIds: ?$ReadOnlyArray<?string>,
      +sceneIds: ?$ReadOnlyArray<?string>,
      +autoPopulateType: ?_AutoPopulateTypes,
      +category: ?string,
      +subCategory: ?string,
      +genres: ?$ReadOnlyArray<?string>,
      +routeItems: ?number,
      +playableType: ?_PlayableTypes,
    |}>,
    +active: ?boolean,
    +dateCreated: ?any,
    +dateModified: ?any,
    +clientId: ?string,
  |}
|};
export type editSonosRouteFolderMutation = {|
  variables: editSonosRouteFolderMutationVariables,
  response: editSonosRouteFolderMutationResponse,
|};
*/


/*
mutation editSonosRouteFolderMutation(
  $menuId: String!
  $pathId: Int!
  $name: String!
  $autoPopulateType: _AutoPopulateTypes!
  $coverName: String
  $cover_40: String
  $cover_290: String
  $cover_legacy: String
  $playableType: _PlayableTypes
  $routeItems: Int
) {
  editSonosRouteFolder(menuId: $menuId, pathId: $pathId, name: $name, autoPopulateType: $autoPopulateType, coverName: $coverName, cover_40: $cover_40, cover_290: $cover_290, cover_legacy: $cover_legacy, playableType: $playableType, routeItems: $routeItems) {
    __typename
    id
    name
    description
    schema {
      name
      pathId
      parentId
      coverName
      cover_40
      cover_290
      cover_legacy
      playlistIds
      sceneIds
      autoPopulateType
      category
      subCategory
      genres
      routeItems
      playableType
    }
    active
    dateCreated
    dateModified
    clientId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "autoPopulateType"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "coverName"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cover_290"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cover_40"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cover_legacy"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "menuId"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "name"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "pathId"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "playableType"
},
v9 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "routeItems"
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v11 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "autoPopulateType",
        "variableName": "autoPopulateType"
      },
      {
        "kind": "Variable",
        "name": "coverName",
        "variableName": "coverName"
      },
      {
        "kind": "Variable",
        "name": "cover_290",
        "variableName": "cover_290"
      },
      {
        "kind": "Variable",
        "name": "cover_40",
        "variableName": "cover_40"
      },
      {
        "kind": "Variable",
        "name": "cover_legacy",
        "variableName": "cover_legacy"
      },
      {
        "kind": "Variable",
        "name": "menuId",
        "variableName": "menuId"
      },
      {
        "kind": "Variable",
        "name": "name",
        "variableName": "name"
      },
      {
        "kind": "Variable",
        "name": "pathId",
        "variableName": "pathId"
      },
      {
        "kind": "Variable",
        "name": "playableType",
        "variableName": "playableType"
      },
      {
        "kind": "Variable",
        "name": "routeItems",
        "variableName": "routeItems"
      }
    ],
    "concreteType": "SonosMenu",
    "kind": "LinkedField",
    "name": "editSonosRouteFolder",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      (v10/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "description",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Route",
        "kind": "LinkedField",
        "name": "schema",
        "plural": true,
        "selections": [
          (v10/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "pathId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "parentId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "coverName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "cover_40",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "cover_290",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "cover_legacy",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "playlistIds",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "sceneIds",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "autoPopulateType",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "category",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "subCategory",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "genres",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "routeItems",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "playableType",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "active",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "dateCreated",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "dateModified",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "editSonosRouteFolderMutation",
    "selections": (v11/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v5/*: any*/),
      (v7/*: any*/),
      (v6/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/),
      (v4/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/)
    ],
    "kind": "Operation",
    "name": "editSonosRouteFolderMutation",
    "selections": (v11/*: any*/)
  },
  "params": {
    "id": "eb6a9ce6f6ef180e39abddec5bec0b66",
    "metadata": {},
    "name": "editSonosRouteFolderMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '78f2844ea39c4fd8ea45132744239eb5';

module.exports = node;
