import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import compose from "recompose/compose";
import { withSnackbar } from "material-ui-snackbar-provider";
import TextField from "@material-ui/core/TextField";
import Input from "@material-ui/core/Input";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import FingerprintIcon from "@material-ui/icons/Fingerprint";
import Validator from "validator";
import jwt from "jsonwebtoken";
import crypto from "crypto-browserify";
import InlineError from "../ui/inlineError";
import resetPasswordMutation from "../mutations/resetPasswordMutation";
import LoginLogo from "./LoginLogo";

const paperWidth = 270;

const styles = (theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    width: paperWidth,
  },
  root: theme.mixins.gutters({
    position: "relative",
    margin: "auto",
    marginTop: 100,
    width: paperWidth,
    paddingTop: 16,
    paddingBottom: 16,
    background: theme.palette.background.paper,
  }),
  button: {
    "&:hover": {
      background: "linear-gradient(45deg, #387f86 30%, #00bce4 90%)",
    },
    width: paperWidth,
    marginTop: 16,
    marginBottom: 25,
  },
  typography: {
    width: paperWidth,
  },
});

class ResetPass extends Component {
  static tokenFromHref() {
    const link = window.location.href;
    const pos = link.lastIndexOf("/");
    const token = link.slice(pos + 1);
    const user = jwt.decode(token);
    if (!user) {
      return console.log("Invalid link. Please request another one.");
    }
    return [user.email, token];
  }
  constructor(props) {
    super(props);
    this.state = {
      submit: false,
      data: {
        tokenInfo: ResetPass.tokenFromHref(),
        password: "",
        passwordRetype: "",
      },
      errors: {},
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentWillMount() {
    localStorage.removeItem("user_id");
    localStorage.removeItem("authentication_token");
  }

  handleClick(event) {
    event.preventDefault();
    const { password, passwordRetype } = this.state.data;
    const email = this.state.data.tokenInfo[0];
    const token = this.state.data.tokenInfo[1];
    const errors = this.validate(this.state.data);
    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      if (password !== passwordRetype) {
        this.setState({ errors: { password: "*password fields don't match" } });
      } else {
        resetPasswordMutation(
          email,
          crypto.createHash("sha256").update(password).digest("hex"),
          token,
          (id) => {
            if (id) {
              this.setState({
                submit: true,
              });
              this.props.router.replace("/login");
              this.props.snackbar.showMessage(
                "Please retry login with your new password"
              );
            } else {
              this.props.snackbar.showMessage(
                "Invalid link. Please request another one."
              );
            }
          }
        );
      }
    }
  }

  validate() {
    const errors = {};
    if (
      !Validator.isLength(this.state.data.password, { min: 8, max: undefined })
    )
      errors.password = "*please use minimum 8 characters";
    return errors;
  }

  handleChange(e) {
    e.preventDefault();
    this.setState({
      data: { ...this.state.data, [e.target.id]: e.target.value },
    });
  }

  render() {
    const { classes } = this.props;
    return (
      <LoginLogo>
        <div>
          <Paper className={classes.root} elevation={10}>
            <Typography variant="h5" component="h3" color="secondary">
              Password reset
            </Typography>
            <TextField
              id="password"
              label="Password"
              className={classes.textField}
              type="password"
              value={this.state.data.password}
              onChange={this.handleChange}
              margin="normal"
            />
            <TextField
              id="passwordRetype"
              label="Retype password"
              className={classes.textField}
              type="password"
              value={this.state.data.passwordRetype}
              onChange={this.handleChange}
              margin="normal"
            />
            <Typography variant="caption" color="error">
              {this.state.errors.password && (
                <InlineError text={this.state.errors.password} />
              )}
            </Typography>
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              onClick={this.handleClick}
            >
              <Input
                type="submit"
                onSubmit={this.handleClick}
                value="RESET PASSWORD"
                disableUnderline
                style={{ fontSize: "large" }}
              />
              <FingerprintIcon />
            </Button>
          </Paper>
        </div>
      </LoginLogo>
    );
  }
}

ResetPass.propTypes = {
  classes: PropTypes.object.isRequired, //eslint-disable-line
  snackbar: PropTypes.object, //eslint-disable-line
  router: PropTypes.object, //eslint-disable-line
};

export default compose(withStyles(styles), withSnackbar())(ResetPass);
