import { commitMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import Environment from "../../environment";

const mutation = graphql`
  mutation createVideoCampaignMutation(
    $input: createVideoCampaignProviderData!
  ) {
    createVideoCampaign(createVideoCampaignProvider: $input) {
      id
      name
    }
  }
`;

export default (
  name,
  description,
  clientId,
  goLiveDate,
  deviceIds,
  profileIds,
  onHold,
  callback
) => {
  const variables = {
    input: {
      name,
      description,
      clientId,
      goLiveDate,
      deviceIds,
      profileIds,
      onHold,
    },
  };

  commitMutation(Environment, {
    mutation,
    variables,
    onCompleted: (response) => {
      const id = response.createVideoCampaign.id;
      callback(id);
    },
    onError: (err) => console.error(err), // eslint-disable-line
  });
};
