import { commitMutation } from "react-relay";
import graphql from 'babel-plugin-relay/macro';
import Environment from "../../environment";

const mutation = graphql`
  mutation deleteSonosMenuMutation($menuId: String!) {
    deleteSonosMenu(menuId: $menuId)
  }
`;

export default (menuId, callback) => {
  const variables = {
    menuId,
  };

  commitMutation(Environment, {
    mutation,
    variables,
    onCompleted: (response) => {
      callback();
    },
    onError: (err) => console.error(err), // eslint-disable-line
    updater: (store, data) => {
      store.delete(data.deleteSonosMenu);
    },
  });
};
