import { commitMutation } from "react-relay";
import graphql from 'babel-plugin-relay/macro';
import Environment from "../../environment";

const mutation = graphql`
  mutation testEventMutation(
    $tag: String
    $deviceId: String!
    $methods: String!
  ) {
    testEvent(tag: $tag, deviceId: $deviceId, methods: $methods) {
      id
    }
  }
`;

export default (tag, deviceId, methods, callback) => {
  const variables = {
    tag,
    deviceId,
    methods,
  };

  commitMutation(Environment, {
    mutation,
    variables,
    onCompleted: () => callback,
    onError: (err) => console.error(err), // eslint-disable-line
  });
};
