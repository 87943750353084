import { requestSubscription } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import { ConnectionHandler } from "relay-runtime";
import Environment from "../../environment";

const subscription = graphql`
  subscription VideoDeviceSubscription {
    VideoDeviceSubscription {
      mutation
      node {
        id
        name
        uid
        description
        macAddress
        currentlyPlaying
        volume
        lastSync
        lanMacAddress
        internalIp
        publicIp
        lastOnline
        appVersion
        reboot
        takeScreenShot
        sync
        format
        uploadLogs
        model
        serialNumber
        usedStorage
        totalStorage
        availableStorage
        hdmiStatus
        downloading
        appUpTime
        isPlaying
        clientId
        tags
        profile {
          profileId
          name
          format
          volume
          url
          syncTime
          rebootTime
          clientId
        }
        deviceStatus
        webkeyId
        webkeyBackupId
      }
    }
  }
`;

export default (user, onNext) => {
  const subscriptionConfig = {
    subscription,
    variables: {},
    refetch: (options) => console.log(options),
    updater: (store, data) => {
      const getRootField = store.getRootField("VideoDeviceSubscription");
      const node = getRootField.getLinkedRecord("node");
      const nodeId = node.getDataID();
      const APImutation = data.VideoDeviceSubscription.mutation;

      const mutation = () => {
        if (
          user.roleVideo >= 2 &&
          APImutation === "UPDATEDCLIENT" &&
          data.VideoDeviceSubscription.node.clientId === user.videoClientId
        ) {
          return "CREATED";
        } else if (
          user.roleVideo >= 2 &&
          APImutation === "UPDATEDCLIENT" &&
          data.VideoDeviceSubscription.node.clientId !== user.videoClientId
        ) {
          return "DELETED";
        }
        return getRootField.getValue("mutation");
      };

      switch (mutation()) {
        case "CREATED":
          {
            const clientProxy = store.get("client:root:viewer:allVideoDevices");
            const newEdge = ConnectionHandler.createEdge(
              store,
              clientProxy,
              node,
              "VideoProfileEdge"
            );
            ConnectionHandler.insertEdgeBefore(clientProxy, newEdge);
          }
          break;
        case "UPDATED":
          break;
        case "DELETED":
          store.delete(nodeId);
          break;
        default:
          console.log("Something is wrong"); //eslint-disable-line
      }
    },
    onNext,
    onCompleted: () => console.log("done"),
    onError: (error) => console.log("An error occured:", error),
  };
  return requestSubscription(Environment, subscriptionConfig);
};
