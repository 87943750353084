import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import compose from "recompose/compose";
import { withSnackbar } from "material-ui-snackbar-provider";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
// import Typography from '@material-ui/core/Typography';
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import moment from "moment";
import registerAudioDeviceMutation from "../mutations/registerAudioDeviceMutation";
import activateAudioDeviceMutation from "../mutations/activateAudioDeviceMutation";

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    margin: "0px 16px 8px 16px",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 180,
  },
  containerCase3: {
    display: "flex",
    alignItems: "center",
  },
  dateContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const arraySort = (a, b) => {
  try {
    const nameA = a.toUpperCase(); // ignore upper and lowercase
    const nameB = b.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    // names must be equal
    return 0;
  } catch (e) {
    console.log("ActivationWizard:", e);
  }
};

function getSteps() {
  return [
    "Select audio client",
    "Select schedule",
    "Name your devices",
    "Add tags",
    "Set Go live date",
  ];
}

class ActivationWizard extends React.Component {
  constructor(props) {
    const deviceDetails = props.selectedDevices.map((deviceId) => {
      const device = props.selection.find((item) => item.id === deviceId);
      const found = props?.allAudioDevicesOld?.find(
        (item) => item.macAddress === device?.macAddress
      );
      return {
        deviceId,
        name: found?.name || device?.name || "Add name here",
        description: "",
        tags: [],
      };
    });

    const goLiveDates = props.selectedDevices.map(() =>
      moment().add(7, "days").unix()
    );
    super(props);
    this.state = {
      activeStep: 0,
      clientId: "",
      scheduleId: "",
      deviceDetails,
      goLiveDates,
    };
    this.handleNext = this.handleNext.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleDeviceDetails = this.handleDeviceDetails.bind(this);
    this.handleDeviceTags = this.handleDeviceTags.bind(this);
    this.handleChangeGoLiveDate = this.handleChangeGoLiveDate.bind(this);
  }

  handleNext() {
    const { deviceDetails, clientId, scheduleId } = this.state;
    if (this.state.activeStep === 1) {
      deviceDetails.map((item) =>
        registerAudioDeviceMutation(
          item.deviceId,
          clientId,
          scheduleId,
          () => null
        )
      );
      this.props.snackbar.showMessage(
        deviceDetails.length > 1
          ? "Sync started for selected devices"
          : "Sync started for selected device"
      );
    }
    this.setState((state) => ({
      activeStep: state.activeStep + 1,
    }));
    const steps = getSteps();
    if (this.state.activeStep === steps.length - 1) {
      this.props.handleClose();
      this.props.snackbar.showMessage("Registration done.");
    }
    if (this.state.activeStep === 4) {
      const allTags = this.props.viewer.allTags.edges
        .filter((item) => item && item.node)
        .map((item) => item.node);
      deviceDetails.map((item) => {
        const tags = item.tags.map(
          (item2) => allTags.find((item3) => item3.name === item2).id
        );
        return activateAudioDeviceMutation(
          item.deviceId,
          item.name,
          tags,
          item.goLiveDate,
          () => null
        );
      });
      this.props.snackbar.showMessage(
        deviceDetails.length > 1 ? "Devices registered" : "Device registered"
      );
    }
  }

  handleBack() {
    this.setState((state) => ({
      activeStep: state.activeStep - 1,
    }));
  }

  handleChange(key, event) {
    this.setState({ [key]: event.target.value });
  }

  handleDeviceDetails(event, index, key) {
    const { deviceDetails } = this.state;
    const newDeviceDetails = deviceDetails;
    newDeviceDetails[index][key] = event.target.value;
    this.setState({ deviceDetails });
  }

  handleDeviceTags(event, index) {
    const { deviceDetails } = this.state;
    const newDeviceDetails = deviceDetails;
    newDeviceDetails[index].tags = event.target.value;
    this.setState({ deviceDetails });
  }

  handleChangeGoLiveDate(event, index) {
    const { goLiveDates } = this.state;
    goLiveDates[index] = moment(event.target.value).unix();
    this.setState({ goLiveDates });
  }

  render() {
    const { classes, viewer, selectedDevices, allAudioDevicesOld } = this.props;
    const steps = getSteps();
    const { activeStep, clientId, scheduleId, deviceDetails, goLiveDates } =
      this.state;
    const getStepContent = (stepIndex) => {
      switch (stepIndex) {
        case 0:
          return (
            <FormControl className={classes.formControl}>
              <InputLabel>Audio Client</InputLabel>
              <Select
                value={clientId}
                onChange={(event) => this.handleChange("clientId", event)}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {viewer.allClients.edges
                  .filter((item) => item && item.node)
                  .sort((a, b) =>
                    a.node.name && b.node.name
                      ? arraySort(a.node.name, b.node.name)
                      : null
                  )
                  .map((item) => (
                    <MenuItem key={item.node.id} value={item.node.id}>
                      {item.node.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          );
        case 1:
          return (
            <FormControl className={classes.formControl}>
              <InputLabel>Schedule</InputLabel>
              <Select
                value={scheduleId}
                onChange={(event) => this.handleChange("scheduleId", event)}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {viewer.allSchedule.edges
                  .filter((item) => item && item.node)
                  .sort((a, b) =>
                    a.node.name && b.node.name
                      ? arraySort(a.node.name, b.node.name)
                      : null
                  )
                  .filter((item) => item.node.clientId === clientId)
                  .map((item) => (
                    <MenuItem key={item.node.id} value={item.node.id}>
                      {item.node.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          );
        case 2:
          return (
            <form className={classes.container} noValidate autoComplete="off">
              {viewer.allAudioDevices.edges
                .filter((item) => item && item.node)
                .filter((item) =>
                  ["UNREGISTERED", "REGISTERED"].includes(
                    item.node.deviceStatus
                  )
                )
                .filter((item) => selectedDevices.includes(item.node.id))
                .map((item, index) => {
                  const actualMacAddress = item?.node?.macAddress;
                  return (
                    <div key={item.node.id}>
                      <TextField
                        id="standard-name"
                        label={`Mac: ${actualMacAddress}`}
                        className={classes.textField}
                        value={deviceDetails[index]?.name || ""}
                        error={deviceDetails[index]?.name === ""}
                        onChange={(event) =>
                          this.handleDeviceDetails(event, index, "name")
                        }
                        margin="normal"
                      />
                    </div>
                  );
                })}
            </form>
          );
        case 3:
          return (
            <form className={classes.container} noValidate autoComplete="off">
              {deviceDetails.map((item, index) => (
                <div key={item.deviceId} className={classes.containerCase3}>
                  <FormControl
                    className={classes.formControl}
                    style={{ flex: "auto" }}
                  >
                    <InputLabel>{`Device: ${item.name}`}</InputLabel>
                    <Select
                      multiple
                      value={deviceDetails[index].tags}
                      onChange={(event) => this.handleDeviceTags(event, index)}
                      input={<Input id="select-multiple-checkbox" />}
                      renderValue={(selected) => selected.join(", ")}
                      MenuProps={MenuProps}
                    >
                      {viewer.allTags.edges
                        .filter((item2) => item2 && item2.node)
                        .sort((a, b) =>
                          a.node.name && b.node.name
                            ? arraySort(a.node.name, b.node.name)
                            : null
                        )
                        .map((item2) => (
                          <MenuItem key={item2.node.id} value={item2.node.name}>
                            <Checkbox
                              checked={
                                deviceDetails[index].tags.indexOf(
                                  item2.node.name
                                ) > -1
                              }
                            />
                            <ListItemText primary={item2.node.name} />
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </div>
              ))}
            </form>
          );
        case 4:
          return (
            <div className={classes.container}>
              {deviceDetails.map((item, index) => (
                <form
                  key={item.deviceId}
                  className={classes.dateContainer}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    label={`Device: ${item.name}`}
                    style={{ width: "auto" }}
                    type="datetime-local"
                    onChange={(event) =>
                      this.handleChangeGoLiveDate(event, index)
                    }
                    className={classes.textField}
                    error={goLiveDates[index] === ""}
                    value={moment(goLiveDates[index] * 1000).format(
                      "YYYY-MM-DDThh:mm"
                    )}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </form>
              ))}
            </div>
          );
        default:
          return "Unknown stepIndex";
      }
    };

    return (
      <div className={classes.root}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <div>
          <div className={classes.instructions}>
            {getStepContent(activeStep)}
          </div>
          <div>
            <Button
              disabled={activeStep === 0}
              onClick={this.handleBack}
              className={classes.backButton}
            >
              Back
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={this.handleNext}
              disabled={
                (activeStep === 0 && clientId === "") ||
                (activeStep === 1 && scheduleId === "") ||
                (activeStep === 2 &&
                  deviceDetails.filter((item) => item.name === "").length !== 0)
              }
            >
              {activeStep === steps.length - 1 ? "Finish" : "Next"}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

ActivationWizard.propTypes = {
  classes: PropTypes.object,
  viewer: PropTypes.object,
  handleClose: PropTypes.func,
  snackbar: PropTypes.object,
  selectedDevices: PropTypes.array.isRequired,
  selection: PropTypes.array.isRequired,
};

export default compose(withStyles(styles), withSnackbar())(ActivationWizard);
