/**
 * @flow
 * @relayHash a44f3013b8501cc74ffd1b18a8d09b1c
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type updateVideoDeviceTagsMutationVariables = {|
  inputDeviceId: string,
  inputTagId: string,
  inputType: string,
|};
export type updateVideoDeviceTagsMutationResponse = {|
  +updateVideoDeviceTags: ?{|
    +id: string
  |}
|};
export type updateVideoDeviceTagsMutation = {|
  variables: updateVideoDeviceTagsMutationVariables,
  response: updateVideoDeviceTagsMutationResponse,
|};
*/


/*
mutation updateVideoDeviceTagsMutation(
  $inputDeviceId: String!
  $inputTagId: String!
  $inputType: String!
) {
  updateVideoDeviceTags(deviceId: $inputDeviceId, tagId: $inputTagId, type: $inputType) {
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "inputDeviceId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "inputTagId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "inputType"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "deviceId",
        "variableName": "inputDeviceId"
      },
      {
        "kind": "Variable",
        "name": "tagId",
        "variableName": "inputTagId"
      },
      {
        "kind": "Variable",
        "name": "type",
        "variableName": "inputType"
      }
    ],
    "concreteType": "VideoDevice",
    "kind": "LinkedField",
    "name": "updateVideoDeviceTags",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "updateVideoDeviceTagsMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "updateVideoDeviceTagsMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "a44f3013b8501cc74ffd1b18a8d09b1c",
    "metadata": {},
    "name": "updateVideoDeviceTagsMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'bad16eb2063d91d25490a15736f40ee7';

module.exports = node;
