/**
 * @flow
 * @relayHash d41068a055028c4ac9ec9a466d584307
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type addAPKSourceMutationVariables = {|
  keyName: string
|};
export type addAPKSourceMutationResponse = {|
  +addAPKSource: {|
    +id: string,
    +appName: string,
    +keyName: string,
    +source: string,
    +packageName: ?string,
    +description: ?string,
    +versionCode: ?number,
    +versionName: ?string,
    +icon: ?string,
  |}
|};
export type addAPKSourceMutation = {|
  variables: addAPKSourceMutationVariables,
  response: addAPKSourceMutationResponse,
|};
*/


/*
mutation addAPKSourceMutation(
  $keyName: String!
) {
  addAPKSource(keyName: $keyName) {
    id
    appName
    keyName
    source
    packageName
    description
    versionCode
    versionName
    icon
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "keyName"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "keyName",
        "variableName": "keyName"
      }
    ],
    "concreteType": "WebkeyPackage",
    "kind": "LinkedField",
    "name": "addAPKSource",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "appName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "keyName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "source",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "packageName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "description",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "versionCode",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "versionName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "icon",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "addAPKSourceMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "addAPKSourceMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "d41068a055028c4ac9ec9a466d584307",
    "metadata": {},
    "name": "addAPKSourceMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1487146359342657341ba5b773c15ba7';

module.exports = node;
