import React from "react";
import PropTypes from "prop-types";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import { withStyles } from "@material-ui/core/styles";
import compose from "recompose/compose";
import { withSnackbar } from "material-ui-snackbar-provider";

import deleteWebkeyDeviceMutation from "../mutations/deleteWebkeyDeviceMutation";

const styles = (theme) => ({
  root: {},
  horizontalContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  verticalContainer: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  button: {
    width: "fit-content",
    margin: "8px 0px 8px 8px",
  },
  typo: {
    width: "fit-content",
    margin: 8,
  },
});

class BatchInstallModal extends React.Component {
  constructor(props) {
    super(props);
    this.handleClose = this.handleClose.bind(this);
    this.handleMutation = this.handleMutation.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state !== nextState) {
      return true;
    }
    return false;
  }
  handleClose() {
    this.setState({ open: false });
  }

  handleMutation() {
    console.log();
    deleteWebkeyDeviceMutation(
      this.props.deviceId,
      this.props.instance,
      (response) => {
        return this.props.snackbar.showMessage("Device Deleted");
      }
    );
    this.setState({ open: false });
    this.props.callback();
  }

  render() {
    const { classes, deviceId } = this.props;

    return (
      <div className={classes.root}>
        <DialogContent className={classes.verticalContainer}>
          <Typography className={classes.typo} align="left">
            {`Are you sure you want to delete device with ID: ${deviceId}?`}
          </Typography>
          <div className={classes.horizontalContainer}>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={() => this.handleMutation()}
            >
              Yes
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={() => this.props.callback()}
            >
              No
            </Button>
          </div>
        </DialogContent>
      </div>
    );
  }
}

BatchInstallModal.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(withStyles(styles), withSnackbar())(BatchInstallModal);
