import React from "react";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import { withStyles } from "@material-ui/core/styles";
import editQsysDeviceNameMutation from "../../mutations/editQsysDeviceNameMutation";

const styles = (theme) => ({
  dialog: {
    background: "secondary",
    margin: theme.spacing(3),
  },
  textField: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
  },
  closeIcon: {
    height: 24,
    color: "#000000",
    width: 24,
  },
  close: {
    position: "relative",
    direction: "rtl",
    marginTop: 6,
  },
  verticalContainer: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
  },
  button: {
    marginRight: theme.spacing(1),
  },
});

const ChangeNameModal = (props) => {
  const { classes, open, close, device } = props;
  const [deviceName, setDeviceName] = React.useState(device?.name || "");
  const [deviceDescription, setDeviceDescription] = React.useState(
    device?.description || ""
  );
  return (
    <Dialog open={open} className={classes.dialog}>
      <div className={classes.close}>
        <IconButton aria-label="Close" className={classes.close}>
          <CloseIcon className={classes.closeIcon} onClick={() => close()} />
        </IconButton>
      </div>
      <DialogTitle className={classes.dialogTitle}>
        Edit name for current device
      </DialogTitle>
      <div className={classes.verticalContainer}>
        <TextField
          id="outlined-name"
          label="Device name"
          className={classes.textField}
          value={deviceName}
          onChange={(event) => setDeviceName(event.target.value)}
          margin="normal"
          variant="outlined"
        />
        <TextField
          id="outlined-description"
          label="Device description"
          className={classes.textField}
          value={deviceDescription}
          onChange={(event) => setDeviceDescription(event.target.value)}
          margin="normal"
          variant="outlined"
        />
      </div>
      <div className={classes.dialog}>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => close()}
          className={classes.button}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() =>
            editQsysDeviceNameMutation(
              device.id,
              deviceName,
              deviceDescription,
              () => close()
            )
          }
          className={classes.button}
        >
          Confirm Change
        </Button>
      </div>
    </Dialog>
  );
};

export default withStyles(styles)(ChangeNameModal);
