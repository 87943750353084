import { commitMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import Environment from "../../environment";

const mutation = graphql`
  mutation deleteQsysRouteFolderMutation($menuId: String!, $pathIds: [Int!]) {
    deleteQsysRouteFolder(menuId: $menuId, pathIds: $pathIds) {
      __typename
      id
      name
      description
      schema {
        name
        pathId
        parentId
        coverName
        cover_40
        cover_290
        cover_legacy
        playlistIds
        sceneIds
        autoPopulateType
        category
        subCategory
        genres
        routeItems
      }
      active
      dateCreated
      dateModified
      clientId
    }
  }
`;

export default (menuId, pathIds, callback) => {
  const variables = {
    menuId,
    pathIds,
  };

  commitMutation(Environment, {
    mutation,
    variables,
    onCompleted: (response) => {
      callback(response);
    },
    onError: (err) => console.error(err), // eslint-disable-line
  });
};
