/**
 * @flow
 * @relayHash 6181351f4c810f399a0dd967da118123
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ServerInstance = "backup" | "primary" | "%future added value";
export type deleteDevicePackageMutationVariables = {|
  devices: $ReadOnlyArray<?string>,
  packageName: string,
  serverInstance: ServerInstance,
|};
export type deleteDevicePackageMutationResponse = {|
  +deleteDevicePackage: ?{|
    +id: string,
    +userId: ?string,
    +clientId: ?string,
    +type: ?string,
    +packageId: ?string,
    +reports: ?$ReadOnlyArray<?{|
      +deviceid: ?string,
      +state: ?string,
    |}>,
    +date: ?any,
  |}
|};
export type deleteDevicePackageMutation = {|
  variables: deleteDevicePackageMutationVariables,
  response: deleteDevicePackageMutationResponse,
|};
*/


/*
mutation deleteDevicePackageMutation(
  $devices: [String]!
  $packageName: String!
  $serverInstance: ServerInstance!
) {
  deleteDevicePackage(devices: $devices, packageName: $packageName, serverInstance: $serverInstance) {
    id
    userId
    clientId
    type
    packageId
    reports {
      deviceid
      state
    }
    date
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "devices"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "packageName"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "serverInstance"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "devices",
        "variableName": "devices"
      },
      {
        "kind": "Variable",
        "name": "packageName",
        "variableName": "packageName"
      },
      {
        "kind": "Variable",
        "name": "serverInstance",
        "variableName": "serverInstance"
      }
    ],
    "concreteType": "WebkeyTask",
    "kind": "LinkedField",
    "name": "deleteDevicePackage",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "userId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "type",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "packageId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "WebkeyReport",
        "kind": "LinkedField",
        "name": "reports",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "deviceid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "state",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "date",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "deleteDevicePackageMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "deleteDevicePackageMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "6181351f4c810f399a0dd967da118123",
    "metadata": {},
    "name": "deleteDevicePackageMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '8d5914d977873270eb4cecf598402268';

module.exports = node;
