/**
 * External dependencies
 */
import React, { useState, useContext, useMemo, useEffect } from "react";
import { useLazyLoadQuery, useSubscription } from "react-relay";
import { withStyles } from "@material-ui/core/styles";
import {
  List,
  TextField,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Divider,
} from "@material-ui/core";
import { AddCircle } from "@material-ui/icons";
import _ from "lodash";

/**
 * Internal dependencies
 */

import { SONOS_ALL_MENUS } from "../../queries/allSonosMenus";
import { QSYS_ALL_MENUS } from "../../queries/allQsysMenus";
import { SONOS_MENUS_SUBSCRIPTION } from "../../subscriptions/sonosMenusSubscription";
import { QSYS_MENUS_SUBSCRIPTION } from "../../subscriptions/qsysMenusSubscription";
import MenuListItem from "./MenuListItem";
import MenuModal from "./MenuModal";
import { CurrentClientContext } from "../../hooks/currentClientProvider";

const styles = () => ({
  root: {
    height: "calc(100vh - 64px)",
  },
  list: {
    width: "30%",
    height: "100%",
    minWidth: 370,
    padding: 4,
  },
  menusView: {
    height: "calc(100% - 64px)",
    overflowY: "scroll",
    padding: "0px 8px 0px 8px",
    marginTop: 2,
    borderWidth: 2,
    borderStyle: "solid",
    borderColor: "lightgrey",
    borderRadius: 16,
  },
  textField: {
    width: "100%",
  },
  listItem: {},
});

const MenuColumn = ({ classes, allClients, audioProvider }) => {
  const clientId = localStorage.getItem("audioClientId");
  const variables = !clientId || clientId === "ALL" ? {} : { clientId };
  const [refetch, setRefetch] = useState(new Date());
  const {
    currentClient,
    setAllPlaylists,
    setAllScenes,
    setAllClients,
    setAllMenus,
    setAudioProvider,
  } = useContext(CurrentClientContext);
  const loadedQuery =
    audioProvider === "sonos" ? SONOS_ALL_MENUS : QSYS_ALL_MENUS;
  const { viewer } = useLazyLoadQuery(loadedQuery, variables, {
    fetchPolicy: "store-and-network",
    fetchKey: refetch,
  });
  //generate const queriedMenus using audioProvider
  const queriedMenus =
    audioProvider === "sonos" ? viewer?.allSonosMenu : viewer?.allQsysMenu;

  const allPlaylists = useMemo(
    () =>
      viewer?.allPlaylists?.edges
        .filter((item) => item?.node)
        .map(({ node }) => node) || [],
    [refetch, currentClient]
  );
  const allScenes = useMemo(
    () =>
      viewer?.allScenes?.edges
        .filter((item) => item?.node)
        .map(({ node }) => node) || [],
    [refetch, currentClient]
  );

  useEffect(() => {
    const allMenus = queriedMenus || [];
    setAllPlaylists(allPlaylists);
    setAllScenes(allScenes);
    setAllClients(allClients);
    setAllMenus(allMenus);
    setAudioProvider(audioProvider);
  }, [viewer]);

  const loadedSubscription =
    audioProvider === "sonos"
      ? SONOS_MENUS_SUBSCRIPTION
      : QSYS_MENUS_SUBSCRIPTION;

  const config = useMemo(
    () => ({
      variables: { token: localStorage.getItem("authentication_token") },
      subscription: loadedSubscription,
      updater: (store) => {
        const getRootField =
          audioProvider === "sonos"
            ? store.getRootField("SonosMenuSubscription")
            : store.getRootField("QsysMenuSubscription");
        const mutation = getRootField.getValue("mutation");
        const node = getRootField.getLinkedRecord("node");
        switch (mutation) {
          case "CREATED":
            {
              setRefetch(new Date());
            }
            break;
          case "UPDATED":
            break;
          case "DELETED":
            {
              const nodeId = node.getDataID();
              setRefetch(new Date());
              store.delete(nodeId);
            }
            break;
          default:
            console.log("Something is wrong"); //eslint-disable-line
        }
      },
    }),
    []
  );
  useSubscription(config);
  const [searchValue, setSearchValue] = useState("");
  const [openModal, setOpenModal] = useState(false);

  const handleChange = (event) => {
    setSearchValue(_.escapeRegExp(event.target.value));
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const regex = new RegExp(searchValue, "gi");

  return (
    <div className={classes.list}>
      <TextField
        className={classes.textField}
        id="search-menu"
        label="Search menu"
        value={searchValue}
        onChange={handleChange}
      />
      <List component="nav" className={classes.menusView} aria-label="contacts">
        <ListItem
          component="nav"
          className={classes.listItem}
          aria-label="create-menu"
        >
          <ListItemText primary="Create new basic menu" />
          <ListItemSecondaryAction>
            <IconButton
              edge="end"
              aria-label="create-menu-button"
              onClick={() => setOpenModal("create")}
              disabled={currentClient === "ALL"}
            >
              <AddCircle />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
        <Divider />
        {queriedMenus
          ?.filter((item) => !item?.parentMenuId)
          .filter(
            (item) =>
              currentClient === "ALL" || item?.clientId === currentClient?.id
          )
          .filter((item) => {
            const checkChildrenMenus = (childrenMenus) =>
              childrenMenus.some(
                (item2) =>
                  item2.name.match(regex) || item2.description.match(regex)
              );
            return (
              item?.name?.search(regex) !== -1 ||
              item?.description?.search(regex) !== -1 ||
              checkChildrenMenus(item?.childrenMenus)
            );
          })
          .sort((a, b) => {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          })
          .map((item) => (
            <MenuListItem
              key={item?.id}
              regex={regex}
              menu={item}
              currentClient={currentClient === "ALL" || currentClient?.id}
              audioProvider={audioProvider}
            />
          ))}
      </List>
      <MenuModal openModal={openModal} callbackModal={handleClose} />
    </div>
  );
};

export default withStyles(styles)(MenuColumn);
