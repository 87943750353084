import { commitMutation } from "react-relay";
import graphql from 'babel-plugin-relay/macro';
import Environment from "../../environment";

const mutation = graphql`
  mutation deleteNPLogMutation($inputid: String!) {
    deleteNPLog(id: $inputid)
  }
`;

export default (id2, callback) => {
  const variables = {
    inputid: id2,
    clientMutationId: "",
  };

  commitMutation(Environment, {
    mutation,
    variables,
    updater: (store) => {
      store.delete(id2);
    },
    onCompleted: (response) => callback(response),
    onError: (err) => console.error(err), // eslint-disable-line
  });
};
