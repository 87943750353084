/**
 * @flow
 * @relayHash 6613a19c56f1fdc31679b7059f4ccf9b
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type deleteTaskMutationVariables = {|
  taskId: string
|};
export type deleteTaskMutationResponse = {|
  +deleteTask: ?{|
    +id: string
  |}
|};
export type deleteTaskMutation = {|
  variables: deleteTaskMutationVariables,
  response: deleteTaskMutationResponse,
|};
*/


/*
mutation deleteTaskMutation(
  $taskId: String!
) {
  deleteTask(taskId: $taskId) {
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "taskId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "taskId",
        "variableName": "taskId"
      }
    ],
    "concreteType": "WebkeyTask",
    "kind": "LinkedField",
    "name": "deleteTask",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "deleteTaskMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "deleteTaskMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "6613a19c56f1fdc31679b7059f4ccf9b",
    "metadata": {},
    "name": "deleteTaskMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd94e7a4fd08da5029d1886f9b2cff067';

module.exports = node;
