/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type Notifications_viewer$ref: FragmentReference;
declare export opaque type Notifications_viewer$fragmentType: Notifications_viewer$ref;
export type Notifications_viewer = {|
  +allNotifications: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: {|
        +id: string,
        +type: string,
        +message: string,
        +dateCreated: any,
        +seenBy: ?$ReadOnlyArray<?string>,
      |}
    |}>
  |},
  +$refType: Notifications_viewer$ref,
|};
export type Notifications_viewer$data = Notifications_viewer;
export type Notifications_viewer$key = {
  +$data?: Notifications_viewer$data,
  +$fragmentRefs: Notifications_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Notifications_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "NotificationConnection",
      "kind": "LinkedField",
      "name": "allNotifications",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "NotificationEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Notification",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "type",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "message",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "dateCreated",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "seenBy",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '7dca2d37a9bfc9bc056f3b5192b3172b';

module.exports = node;
