/**
 * @flow
 * @relayHash 87b61f2bb64e30af7b15ef592c39179e
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type removeUserFromQsysDeviceMutationVariables = {|
  deviceId: string,
  userId: string,
|};
export type removeUserFromQsysDeviceMutationResponse = {|
  +removeUserFromQsysDevice: ?{|
    +id: string,
    +deviceId: string,
    +name: ?string,
    +description: ?string,
    +ipAddress: ?string,
    +systemVersion: ?string,
    +generalSchedule: ?string,
    +isGeneralScheduleActive: ?boolean,
    +dateCreated: string,
    +userIds: ?$ReadOnlyArray<?{|
      +id: string,
      +profile: ?{|
        +name: ?string
      |},
      +emails: ?$ReadOnlyArray<?{|
        +address: ?string
      |}>,
    |}>,
    +noOfZones: ?number,
    +lastHeartbeat: ?string,
    +client: ?{|
      +id: string,
      +name: ?string,
    |},
  |}
|};
export type removeUserFromQsysDeviceMutation = {|
  variables: removeUserFromQsysDeviceMutationVariables,
  response: removeUserFromQsysDeviceMutationResponse,
|};
*/


/*
mutation removeUserFromQsysDeviceMutation(
  $deviceId: String!
  $userId: String!
) {
  removeUserFromQsysDevice(deviceId: $deviceId, userId: $userId) {
    id
    deviceId
    name
    description
    ipAddress
    systemVersion
    generalSchedule
    isGeneralScheduleActive
    dateCreated
    userIds {
      id
      profile {
        name
      }
      emails {
        address
      }
    }
    noOfZones
    lastHeartbeat
    client {
      id
      name
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "deviceId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "userId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "deviceId",
        "variableName": "deviceId"
      },
      {
        "kind": "Variable",
        "name": "userId",
        "variableName": "userId"
      }
    ],
    "concreteType": "QsysDevice",
    "kind": "LinkedField",
    "name": "removeUserFromQsysDevice",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "deviceId",
        "storageKey": null
      },
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "description",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ipAddress",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "systemVersion",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "generalSchedule",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isGeneralScheduleActive",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "dateCreated",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "userIds",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "UserProfile",
            "kind": "LinkedField",
            "name": "profile",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UserEmails",
            "kind": "LinkedField",
            "name": "emails",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "address",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "noOfZones",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lastHeartbeat",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "AudioClient",
        "kind": "LinkedField",
        "name": "client",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "removeUserFromQsysDeviceMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "removeUserFromQsysDeviceMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "id": "87b61f2bb64e30af7b15ef592c39179e",
    "metadata": {},
    "name": "removeUserFromQsysDeviceMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1c0d7cae96b26dced625fa71bd54377d';

module.exports = node;
