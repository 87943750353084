import React from "react";
import PropTypes from "prop-types";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Avatar from "@material-ui/core/Avatar";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import StayCurrentPortraitIcon from "@material-ui/icons/StayCurrentPortrait";
import SyncIcon from "@material-ui/icons/SyncRounded";
import { green } from "@material-ui/core/colors";

import { withStyles } from "@material-ui/core/styles";
import compose from "recompose/compose";
import { withSnackbar } from "material-ui-snackbar-provider";
import _ from "lodash";

import getDevicePackagesMutation from "../mutations/getDevicePackagesMutation";
import deleteDevicePackageMutation from "../mutations/deleteDevicePackageMutation";
import installDevicePackageMutation from "../mutations/installDevicePackageMutation";

const styles = (theme) => ({
  root: {},
  horizontalContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  verticalContainer: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  button: {
    width: "fit-content",
  },
  button2: {
    width: "fit-content",
    marginLeft: 8,
  },
  greenAvatar: {
    color: "#fff",
    backgroundColor: green[500],
  },
  avatar: {},
  typo: {
    width: "fit-content",
    margin: 8,
  },
});

class WebkeyPackagesModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      open2: false,
      searchField: "",
      selectedPackage: "",
      webkeyDevicePackages: [],
    };
    this.handleChange = this.handleChange.bind(this);
    this.selectItem = this.selectItem.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.installPackage = this.installPackage.bind(this);
    this.deletePackage = this.deletePackage.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state !== nextState) {
      return true;
    }
    return false;
  }

  componentDidMount() {
    getDevicePackagesMutation(
      this.props.webkeyId,
      this.props.instance,
      (response) => {
        return this.setState({ webkeyDevicePackages: response });
      }
    );
  }

  handleChange(name, event) {
    this.setState({ [name]: event.target.value.toLowerCase() });
  }

  selectItem(item) {
    this.setState({
      selectedPackage: item,
    });
  }

  handleClose() {
    this.setState({ open: false });
  }

  installPackage(item) {
    installDevicePackageMutation(
      [this.props.webkeyId],
      item.id,
      this.props.instance,
      (response) => {
        return this.setState({ open: false });
      }
    );
  }

  deletePackage(item) {
    deleteDevicePackageMutation(
      [this.props.webkeyId],
      item.packageName,
      this.props.instance,
      (response) =>
        this.props.snackbar.showMessage("Command executed successfully")
    );
    this.setState({ open2: false });
    this.props.callback();
  }

  render() {
    const { classes, webkeyId, packages, instance } = this.props;
    const {
      searchField,
      selectedPackage,
      webkeyDevicePackages,
      open,
      open2,
    } = this.state;
    console.log(selectedPackage);
    const installedPackages = _.intersectionWith(
      packages,
      webkeyDevicePackages,
      (first, second) =>
        first.packageName === second.packageName &&
        first.versionCode === second.versionCode
    );
    // const notInstalledPackages = _.differenceBy(packages,webkeyDevicePackages,'packageName');
    const restOfPackages = _.differenceBy(
      webkeyDevicePackages,
      installedPackages,
      "packageName"
    );

    return (
      <div className={classes.root}>
        <DialogContent className={classes.verticalContainer}>
          <div className={classes.horizontalContainer}>
            <Tooltip
              title="Add application"
              placement="bottom"
              enterTouchDelay={200}
              onClick={() => this.setState({ open: true })}
            >
              <Button
                variant="contained"
                color="primary"
                disabled={packages.length === 0}
                className={classes.button}
              >
                Add Application
              </Button>
            </Tooltip>
            <Tooltip
              title="Refresh App list"
              placement="bottom"
              enterTouchDelay={200}
              onClick={() =>
                getDevicePackagesMutation(webkeyId, instance, (response) =>
                  this.setState({ webkeyDevicePackages: response })
                )
              }
            >
              <Button
                variant="contained"
                color="primary"
                className={classes.button2}
              >
                <SyncIcon />
              </Button>
            </Tooltip>
          </div>
          <Dialog
            onClose={() => this.setState({ open: false })}
            aria-labelledby="simple-dialog-title"
            open={open}
          >
            <DialogTitle id="simple-dialog-title">
              Install application
            </DialogTitle>
            <List>
              {packages.map((item) => {
                const installed = installedPackages.find(
                  (item2) => item2.id === item.id
                );
                return (
                  <ListItem
                    button
                    onClick={() => this.installPackage(item)}
                    key={item.packageName}
                  >
                    <ListItemAvatar>
                      <Avatar
                        className={
                          installed ? classes.greenAvatar : classes.avatar
                        }
                      >
                        <StayCurrentPortraitIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={item.appName}
                      secondary={`packageName:\u00A0${item.packageName}\u00A0/\u00A0version:\u00A0${item.versionName}`}
                    />
                  </ListItem>
                );
              })}
            </List>
          </Dialog>
          <TextField
            id="device-search-field"
            label="Search"
            className={classes.textField}
            value={searchField}
            onChange={(event) => this.handleChange("searchField", event)}
            margin="normal"
          />
          <List dense>
            {_.concat(installedPackages, restOfPackages)
              .filter(
                (item) =>
                  (item.appName &&
                    item.appName.toLowerCase().includes(searchField)) ||
                  (item.packageName &&
                    item.packageName.toLowerCase().includes(searchField)) ||
                  (item.versionName &&
                    item.versionName.toLowerCase().includes(searchField))
              )
              .map((item) => (
                <ListItem
                  key={item.packageName}
                  onClick={() => this.selectItem(item.packageName)}
                  selected={selectedPackage === item.packageName}
                >
                  <ListItemText
                    primary={item.appName}
                    secondary={`packageName:\u00A0${item.packageName}\u00A0/\u00A0version:\u00A0${item.versionName}`}
                  />
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() =>
                        this.setState({
                          open2: true,
                          selectedPackage: item,
                        })
                      }
                    >
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
          </List>
          <Dialog
            onClose={() => this.setState({ open2: false })}
            aria-labelledby="Delete app"
            open={open2}
          >
            <Typography className={classes.typo} align="left">
              Are you sure you want to delete{" "}
              <b>{` ${selectedPackage.appName}`}</b>?
            </Typography>
            <div className={classes.horizontalContainer} style={{ margin: 8 }}>
              <Button
                variant="contained"
                color="primary"
                className={classes.button2}
                onClick={() => this.deletePackage(selectedPackage)}
              >
                Yes
              </Button>
              <Button
                variant="contained"
                color="primary"
                className={classes.button2}
                onClick={() => this.setState({ open2: false })}
              >
                No
              </Button>
            </div>
          </Dialog>
        </DialogContent>
      </div>
    );
  }
}

WebkeyPackagesModal.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(withStyles(styles), withSnackbar())(WebkeyPackagesModal);
