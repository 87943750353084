import { commitMutation } from "react-relay";
import graphql from 'babel-plugin-relay/macro';
import Environment from "../../environment";

const mutation = graphql`
  mutation deleteVideoClientMutation($inputId: String!) {
    deleteVideoClient(id: $inputId) {
      id
    }
  }
`;

export default (id2, callback) => {
  const variables = {
    inputId: id2,
  };

  commitMutation(Environment, {
    mutation,
    variables,
    updater: (store) => {
      store.delete(id2);
    },
    onCompleted: () => {
      callback();
    },
    onError: (err) => console.error(err), // eslint-disable-line
  });
};
