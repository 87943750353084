import { commitMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import Environment from "../../environment";

const mutation = graphql`
  mutation updateAudioDeviceTagsMutation(
    $inputDeviceId: String!
    $inputTagId: String!
    $inputType: String!
  ) {
    updateAudioDeviceTags(
      deviceId: $inputDeviceId
      tagId: $inputTagId
      type: $inputType
    ) {
      id
    }
  }
`;

export default (deviceId, tagId, type, callback) => {
  const variables = {
    inputDeviceId: deviceId,
    inputTagId: tagId,
    inputType: type,
  };

  commitMutation(Environment, {
    mutation,
    variables,
    onCompleted: (response) => {
      const id = response.updateAudioDeviceTags.id;
      callback(id);
    },
    onError: (err) => console.error(err), // eslint-disable-line
  });
};
