import { commitMutation } from "react-relay";
import graphql from 'babel-plugin-relay/macro';
import Environment from "../../environment";

const mutation = graphql`
  mutation registerAudioDeviceMutation(
    $deviceId: String!
    $clientId: String!
    $scheduleIds: String!
  ) {
    registerAudioDevice(
      deviceId: $deviceId
      clientId: $clientId
      scheduleIds: $scheduleIds
    ) {
      id
    }
  }
`;

export default (deviceId, clientId, scheduleIds, callback) => {
  const variables = {
    deviceId,
    clientId,
    scheduleIds,
  };

  commitMutation(Environment, {
    mutation,
    variables,
    onCompleted: () => {
      callback();
    },
    onError: (err) => console.error(err), // eslint-disable-line
  });
};
