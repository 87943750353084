import { requestSubscription } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import { ConnectionHandler } from "relay-runtime";
import Environment from "../../environment";

const subscription = graphql`
  subscription AudioDeviceActiveSubscription(
    $token: String!
    $updatedId: ID
    $updatedFields: [String]
  ) {
    AudioDeviceActiveSubscription(
      token: $token
      updatedId: $updatedId
      updatedFields: $updatedFields
    ) {
      mutation
      node {
        id
        lastOnline
        appStatus {
          status
        }
        isPlaying
        clientId
        macAddress
        name
        currentPlaylistId
        currentSceneId
        tags
        lastSyncTime
        scheduleIds
        isOnline
        deviceStatus
        maximumDaysOffline
        maximumSongsOffline
      }
    }
  }
`;

export default (token, updatedId, updatedFields, onNext) => {
  const subscriptionConfig = {
    subscription,
    variables: {
      token,
      updatedId,
      updatedFields,
    },
    refetch: (options) => console.log(options),
    updater: (store) => {
      try {
        const getRootField = store.getRootField(
          "AudioDeviceActiveSubscription"
        );
        const mutation = getRootField.getValue("mutation");
        const node = getRootField.getLinkedRecord("node");
        switch (mutation) {
          case "CREATED":
            {
              const clientProxy = store.get(
                "client:root:viewer:allAudioDevices"
              );
              const newEdge = ConnectionHandler.createEdge(
                store,
                clientProxy,
                node,
                "AudioDeviceEdge"
              );
              ConnectionHandler.insertEdgeBefore(clientProxy, newEdge);
            }
            break;
          case "UPDATED":
            break;
          case "DELETED":
            {
              const nodeId = node.getDataID();
              store.delete(nodeId);
            }
            break;
          default:
            console.log("Something is wrong"); //eslint-disable-line
        }
      } catch (e) {
        console.log("Audio Device Subscription error:", e);
      }
    },
    onNext,
    onCompleted: () => console.log("Audio Device Done"),
    onError: (error) => console.log("An error occured:", error),
  };
  return requestSubscription(Environment, subscriptionConfig);
};
