import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { withSnackbar } from "material-ui-snackbar-provider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Divider from "@material-ui/core/Divider";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import DeleteIcon from "@material-ui/icons/Delete";
import compose from "recompose/compose";
import updateVideoDeviceTagsMutation from "../../mutations/updateVideoDeviceTagsMutation";

const styles = {
  root: {
    margin: "4px 0px 4px 0px",
  },
  card: {
    display: "flex",
    flexDirection: "column",
  },
  content: {
    padding: "16px 16px 0px 16px",
  },
  horizontalContainer: {
    display: "flex",
    flexWrap: "wrap",
    flex: "auto",
  },
  verticalContainer: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    flex: "auto",
  },
  typo1: {
    display: "flex",
    alignItems: "center",
  },
  menuItem: {
    fontSize: "0.85rem",
    boxSizing: "border-box",
  },
  list: {
    padding: 8,
  },
};

const arraySort = (a, b) => {
  try {
    const nameA = a.toUpperCase(); // ignore upper and lowercase
    const nameB = b.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    // names must be equal
    return 0;
  } catch (e) {
    console.log("DeviceControl/Tags:", e);
  }
};

class Tag extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorElTags: null,
    };
    this.handleClose = this.handleClose.bind(this);
    this.handleClickListTags = this.handleClickListTags.bind(this);
    this.handleData = this.handleData.bind(this);
    this.handleAddTag = this.handleAddTag.bind(this);
    this.handleDeleteTag = this.handleDeleteTag.bind(this);
  }

  handleClose() {
    this.setState({ anchorElTags: null });
  }
  handleClickListTags(event) {
    this.setState({ anchorElTags: event.currentTarget });
  }

  handleData(client) {
    this.setState({ currentClient: [client] });
    this.handleClose();
  }

  handleAddTag(id) {
    updateVideoDeviceTagsMutation(
      this.props.deviceId,
      id,
      "ADD",
      (responseId) =>
        responseId
          ? this.props.snackbar.showMessage("Tag added to device")
          : null
    );
    this.handleClose();
  }

  handleDeleteTag(id) {
    updateVideoDeviceTagsMutation(
      this.props.deviceId,
      id,
      "REMOVE",
      (responseId) =>
        responseId
          ? this.props.snackbar.showMessage("Tag removed from device")
          : null
    );
    this.handleClose();
  }

  render() {
    const { anchorElTags } = this.state;
    const { classes, viewer } = this.props;
    const tagList = this.props.tagList || [];
    const allTagsSorted = [...viewer.allTags.edges]
      .filter((item) => item && item.node)
      .sort((a, b) =>
        a.node && b.node ? arraySort(a.node.name, b.node.name) : null
      );
    return (
      <div className={classes.root}>
        <Card className={classes.card}>
          <CardContent className={classes.content}>
            <Typography className={classes.typo1} variant="h6">
              Tag Settings
            </Typography>
            <div className={classes.verticalContainer}>
              <List className={classes.list2}>
                <ListItem
                  button
                  aria-haspopup="true"
                  aria-controls="tags-menu"
                  aria-label="Tags"
                  onClick={this.handleClickListTags}
                >
                  <ListItemText
                    primary="Tags"
                    secondary="Click here to add tags"
                  />
                </ListItem>
              </List>
              <Menu
                id="lock-menu"
                anchorEl={anchorElTags}
                open={Boolean(anchorElTags)}
                onClose={this.handleClose}
              >
                {viewer.allTags &&
                  allTagsSorted.map((tag) => (
                    <MenuItem
                      id={tag.node.id}
                      className={classes.menuItem}
                      key={tag.node.id}
                      disabled={tagList.includes(tag.node.id)}
                      value={tag.node.name}
                      onClick={() => this.handleAddTag(tag.node.id)}
                    >
                      <b>{tag.node.name}</b>
                    </MenuItem>
                  ))}
              </Menu>
            </div>
          </CardContent>
          <Divider />
          <List dense>
            {viewer.allTags &&
              viewer.allTags.edges
                .filter((item) => item && item.node)
                .map((tag) =>
                  tagList.includes(tag.node.id) ? (
                    <ListItem key={tag.node.id}>
                      <ListItemText
                        primary={tag.node.name}
                        secondary={tag.node.description}
                      />
                      <ListItemSecondaryAction>
                        <Tooltip
                          id="tooltip-left"
                          title="Remove Tag"
                          placement="left"
                          enterTouchDelay={200}
                        >
                          <IconButton
                            aria-label="Delete"
                            onClick={() => this.handleDeleteTag(tag.node.id)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ) : null
                )}
          </List>
        </Card>
      </div>
    );
  }
}

Tag.defaultProps = {
  usersId: [],
};

Tag.propTypes = {
  classes: PropTypes.object.isRequired, //eslint-disable-line
  viewer: PropTypes.object.isRequired, //eslint-disable-line
  usersId: PropTypes.array, //eslint-disable-line
  deviceId: PropTypes.string.isRequired,
  snackbar: PropTypes.object, //eslint-disable-line
  tagList: PropTypes.array, //eslint-disable-line
};

export default compose(withStyles(styles), withSnackbar())(Tag);
