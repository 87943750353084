/**
 * @flow
 * @relayHash bd56f241d582554791f59c6a328daff8
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type updateNewLocationMutationVariables = {|
  deviceId: string,
  latitude?: ?string,
  longitude?: ?string,
|};
export type updateNewLocationMutationResponse = {|
  +updateNewLocation: ?{|
    +id: string,
    +latitude: ?number,
    +longitude: ?number,
  |}
|};
export type updateNewLocationMutation = {|
  variables: updateNewLocationMutationVariables,
  response: updateNewLocationMutationResponse,
|};
*/


/*
mutation updateNewLocationMutation(
  $deviceId: String!
  $latitude: String
  $longitude: String
) {
  updateNewLocation(deviceId: $deviceId, latitude: $latitude, longitude: $longitude) {
    id
    latitude
    longitude
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "deviceId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "latitude"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "longitude"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "deviceId",
        "variableName": "deviceId"
      },
      {
        "kind": "Variable",
        "name": "latitude",
        "variableName": "latitude"
      },
      {
        "kind": "Variable",
        "name": "longitude",
        "variableName": "longitude"
      }
    ],
    "concreteType": "AudioDevice",
    "kind": "LinkedField",
    "name": "updateNewLocation",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "latitude",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "longitude",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "updateNewLocationMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "updateNewLocationMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "bd56f241d582554791f59c6a328daff8",
    "metadata": {},
    "name": "updateNewLocationMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'dfd33ec6b7c5f29cb3ea6b7defff7cdf';

module.exports = node;
