import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import compose from "recompose/compose";
import { withSnackbar } from "material-ui-snackbar-provider";

const styles = {
  horizontalContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
};

class WebkeyDashboardModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    // const { classes } = this.props;
    // const {  } = this.state;
    return <div>Add componrnt here</div>;
  }
}

WebkeyDashboardModal.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(
  withStyles(styles),
  withSnackbar()
)(WebkeyDashboardModal);
