/**
 * External dependencies
 */
import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";

/**
 * Internal dependencies
 */
import CreateMenuModal from "./CreateMenuModal";
import EditMenuModal from "./EditMenuModal";
import DuplicateMenuModal from "./DuplicateMenuModal";
import DeleteMenuModal from "./DeleteMenuModal";

import CreateRouteModal from "../schemaColumn/CreateRouteModal";
import EditRouteModal from "../schemaColumn/EditRouteModal";
import DeleteRouteModal from "../schemaColumn/DeleteRouteModal";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 500,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: "35vh",
    margin: "auto",
    left: 0,
    right: 0,
    borderRadius: 19,
  },
}));

export default function MenuModal({ openModal, callbackModal, menu, route }) {
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
    callbackModal();
  };

  useEffect(() => {
    if (openModal === "delete" && menu?.active) {
      setOpen("deleteActive");
    } else {
      setOpen(openModal);
    }
  }, [openModal]);

  const useBody = (modalType) => {
    switch (modalType) {
      case "create":
        return <CreateMenuModal handleClose={handleClose} />;
      case "createMenuExtension":
        return (
          <CreateMenuModal handleClose={handleClose} extendedMenu={menu} />
        );
      case "edit":
        return <EditMenuModal menu={menu} handleClose={handleClose} />;
      case "duplicate":
        return <DuplicateMenuModal menu={menu} handleClose={handleClose} />;
      case "delete":
        return <DeleteMenuModal menu={menu} handleClose={handleClose} />;
      case "deleteActive":
        return (
          <div className={classes.paper}>
            <p id="simple-modal-description">
              Cannot delete this is a default menu!
            </p>
          </div>
        );
      case "createFolder":
        return <CreateRouteModal handleClose={handleClose} route={route} />;
      case "editFolder":
        return <EditRouteModal handleClose={handleClose} route={route} />;
      case "deleteFolder":
        return <DeleteRouteModal handleClose={handleClose} route={route} />;
      default:
        <div className={classes.paper}>
          <h2 id="simple-modal-title">An error occurred!</h2>
          <p id="simple-modal-description">None</p>
        </div>;
    }
  };

  return (
    <Modal
      open={!!open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      {useBody(open)}
    </Modal>
  );
}
