import { commitMutation } from "react-relay";
import graphql from 'babel-plugin-relay/macro';
import Environment from "../../environment";

const mutation = graphql`
  mutation deleteWebkeyDeviceMutation(
    $deviceId: String!
    $serverInstance: ServerInstance!
  ) {
    deleteWebkeyDevice(deviceId: $deviceId, serverInstance: $serverInstance) {
      id
    }
  }
`;

export default (deviceId, serverInstance, callback) => {
  const variables = {
    deviceId,
    serverInstance,
  };

  commitMutation(Environment, {
    mutation,
    variables,
    onCompleted: (response) => {
      if (
        response &&
        response.deleteWebkeyDevice &&
        response.deleteWebkeyDevice.id
      ) {
        callback(response.deleteWebkeyDevice.webkeyId);
      }
    },
    onError: (err) => console.error(err), // eslint-disable-line
  });
};
