import { commitMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import Environment from "../../environment";

const mutation = graphql`
  mutation getAllDeviceCollectionMutation($serverInstance: ServerInstance!) {
    getAllDeviceCollection(serverInstance: $serverInstance) {
      id
      DisplayName
      Serial
      DeviceNick
      DeviceBrand
      DeviceModel
      WebkeyVersion
      Location
      LastConnected
      AndroidAPI
      AndroidVersion
      Rooted
      Online
    }
  }
`;

export default (serverInstance, callback) => {
  const variables = {
    serverInstance,
  };

  commitMutation(Environment, {
    mutation,
    variables,
    onCompleted: () => {
      callback();
    },
    onError: (err) => console.error(err), // eslint-disable-line
  });
};
