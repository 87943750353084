import { commitMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import environment from "../../environment";

const mutation = graphql`
  mutation removeUserFromQsysDeviceMutation(
    $deviceId: String!
    $userId: String!
  ) {
    removeUserFromQsysDevice(deviceId: $deviceId, userId: $userId) {
      id
      deviceId
      name
      description
      ipAddress
      systemVersion
      generalSchedule
      isGeneralScheduleActive
      dateCreated
      userIds {
        id
        profile {
          name
        }
        emails {
          address
        }
      }
      noOfZones
      lastHeartbeat
      client {
        id
        name
      }
    }
  }
`;

function removeUserFromQsysDeviceMutation(
  deviceId,
  userId,
  callback,
  errorCallback
) {
  const variables = {
    deviceId,
    userId,
  };

  commitMutation(environment, {
    mutation,
    variables,
    updater: (store) => {
      const root = store.getRoot();
      const viewer = root.getLinkedRecord("viewer");
      const allQsysDevices = viewer.getLinkedRecords("allQsysDevices") || [];
      const removedDevice = store.getRootField("removeUserFromQsysDevice");

      if (removedDevice) {
        const updatedDevices = allQsysDevices.filter(
          (device) => device.getDataID() !== removedDevice.getDataID()
        );
        viewer.setLinkedRecords(updatedDevices, "allQsysDevices");

        // Update user's qsysData
        const user = store.get(userId);
        if (user) {
          const qsysData = user.getOrCreateLinkedRecord("qsysData");
          const currentCores = qsysData.getValue("noOfCores") || 0;
          const currentZones = qsysData.getValue("noOfZones") || 0;
          const currentDeviceIds = qsysData.getValue("qsysDeviceIds") || [];

          qsysData.setValue(Math.max(0, currentCores - 1), "noOfCores");
          qsysData.setValue(
            Math.max(
              0,
              currentZones -
                (removedDevice.getLinkedRecords("zones")?.length || 0)
            ),
            "noOfZones"
          );
          qsysData.setValue(
            currentDeviceIds.filter(
              (id) => id !== removedDevice.getValue("id")
            ),
            "qsysDeviceIds"
          );
        }
      }
    },
    onCompleted: (response, errors) => {
      if (errors) {
        errorCallback?.(errors);
        return;
      }
      callback?.();
    },
    onError: (err) => {
      errorCallback?.(err);
      console.error(err);
    },
  });
}

export default removeUserFromQsysDeviceMutation;
