/**
 * External dependencies
 */
import React, { useEffect, useContext } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Divider,
  Checkbox,
} from "@material-ui/core";
import _ from "lodash";

/**
 * Internal dependencies
 */

import { CurrentMenuContext } from "../../hooks/currentMenuProvider";
import toggleRouteGenresMutation from "../../../mutations/toggleRouteGenresMutation";
import toggleQsysRouteGenresMutation from "../../../mutations/toggleQsysRouteGenresMutation";
import { CurrentClientContext } from "../../hooks/currentClientProvider";

const styles = {
  root: {},
};

const RouteListGenres = ({ classes, playableList, route, searchValue }) => {
  const { audioProvider } = useContext(CurrentClientContext);
  const [checked, setChecked] = React.useState([]);

  const { currentMenu, currentMenuExtension } = useContext(CurrentMenuContext);

  const handledMenu = currentMenuExtension || currentMenu;

  useEffect(() => {
    route.genres ? setChecked(route.genres) : setChecked([]);
  }, [route]);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
    let routeItems = 0;

    if (newChecked.length > 0) {
      routeItems = playableList.filter(
        (item) =>
          item?.genres?.some((item2) =>
            newChecked.includes(item2.toUpperCase())
          ) ||
          item?.genre?.some((item2) => newChecked.includes(item2.toUpperCase()))
      ).length;
    }
    audioProvider === "sonos"
      ? toggleRouteGenresMutation(
          handledMenu.id,
          route.pathId,
          newChecked || [],
          routeItems,
          () => console.log("Done!")
        )
      : toggleQsysRouteGenresMutation(
          handledMenu.id,
          route.pathId,
          newChecked || [],
          routeItems,
          () => console.log("Done!")
        );
  };

  const listOfGenres =
    (playableList.filter((item) => item?.genres || item?.genre).length > 0 && [
      ...new Set(
        playableList
          .filter((item) => item?.genres || item?.genre)
          .map((item) => item?.genres || item?.genre)
          .reduce((accumulator, item) => accumulator.concat(item))
          .map((item) => item?.toUpperCase())
      ),
    ]) ||
    [];
  const handleToggleAll = () => {
    let newChecked = [...checked];
    if (_.difference(listOfGenres, checked).length === 0) {
      newChecked = [];
    } else {
      newChecked = listOfGenres;
    }
    setChecked(newChecked);
    let routeItems = 0;

    if (newChecked.length > 0) {
      routeItems = playableList.filter(
        (item) =>
          item?.genres?.some((item2) =>
            newChecked.includes(item2.toUpperCase())
          ) ||
          item?.genre?.some((item2) => newChecked.includes(item2.toUpperCase()))
      ).length;
    }
    toggleRouteGenresMutation(
      handledMenu.id,
      route.pathId,
      newChecked,
      routeItems,
      () => console.log("Done!")
    );
  };

  const regex = new RegExp(searchValue, "gi");
  return (
    <>
      <ListItem
        component="nav"
        className={classes.root}
        aria-label="menu-list-item"
      >
        <ListItemText primary="Select all" />
        <ListItemSecondaryAction>
          <Checkbox
            edge="end"
            onChange={handleToggleAll}
            checked={_.difference(listOfGenres, checked).length === 0}
            inputProps={{ "aria-labelledby": "select-all" }}
          />
        </ListItemSecondaryAction>
      </ListItem>
      <Divider />
      {listOfGenres
        .filter((item) =>
          searchValue === "" ? true : item?.search(regex) !== -1
        )
        .map((item) => (
          <div key={item}>
            <ListItem
              component="nav"
              className={classes.root}
              aria-label="menu-list-item"
            >
              <ListItemText primary={item} />
              <ListItemSecondaryAction>
                <Checkbox
                  edge="end"
                  onChange={handleToggle(item)}
                  checked={checked.indexOf(item) !== -1}
                  inputProps={{ "aria-labelledby": item }}
                />
              </ListItemSecondaryAction>
            </ListItem>
            <Divider />
          </div>
        ))}
    </>
  );
};

export default withStyles(styles)(RouteListGenres);
