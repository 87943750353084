import { commitMutation} from "react-relay";
import graphql from 'babel-plugin-relay/macro';
import Environment from "../../environment";

const mutation = graphql`
  mutation editScheduleMutation(
    $inputId: String!
    $input: createScheduleProviderData!
  ) {
    editSchedule(id: $inputId, createScheduleProvider: $input) {
      id
      name
      description
      injectable
      startDate
      endDate
      clientId
      size
      playingPlaylists {
        playlistId
        name
        volume
        startTime
        endTime
        startTimeSeconds
        endTimeSeconds
        days
        shuffle
        filenames {
          mixId
          nrOfSongs
        }
      }
    }
  }
`;

export default (
  id2,
  name,
  description,
  injectable,
  startDate,
  endDate,
  clientId,
  size,
  playingPlaylists,
  callback
) => {
  const variables = {
    inputId: id2,
    input: {
      name,
      description,
      injectable,
      startDate,
      endDate,
      clientId,
      size: size || 0,
      playingPlaylists,
    },
  };

  commitMutation(Environment, {
    mutation,
    variables,
    onCompleted: (response) => {
      const id = response && response.editSchedule && response.editSchedule.id;
      callback(id);
    },
    onError: (err) => console.error(err), // eslint-disable-line
  });
};
