/**
 * @flow
 * @relayHash 7fac9463fe8b5808e1e3e9f7b53bb84d
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type _ModelMutationType = "CREATED" | "DELETED" | "UPDATED" | "UPDATEDCLIENT" | "%future added value";
export type _deviceStatusType = "ACTIVE" | "INACTIVE" | "REGISTERED" | "UNREGISTERED" | "%future added value";
export type VideoDeviceSubscriptionVariables = {||};
export type VideoDeviceSubscriptionResponse = {|
  +VideoDeviceSubscription: {|
    +mutation: _ModelMutationType,
    +node: {|
      +id: string,
      +name: ?string,
      +uid: ?string,
      +description: ?string,
      +macAddress: ?string,
      +currentlyPlaying: ?string,
      +volume: ?number,
      +lastSync: ?string,
      +lanMacAddress: ?string,
      +internalIp: ?string,
      +publicIp: ?string,
      +lastOnline: ?number,
      +appVersion: ?string,
      +reboot: ?boolean,
      +takeScreenShot: ?boolean,
      +sync: ?boolean,
      +format: ?string,
      +uploadLogs: ?boolean,
      +model: ?string,
      +serialNumber: ?string,
      +usedStorage: ?string,
      +totalStorage: ?string,
      +availableStorage: ?string,
      +hdmiStatus: ?boolean,
      +downloading: ?string,
      +appUpTime: ?string,
      +isPlaying: ?boolean,
      +clientId: ?string,
      +tags: ?$ReadOnlyArray<?string>,
      +profile: ?{|
        +profileId: ?string,
        +name: ?string,
        +format: ?string,
        +volume: ?number,
        +url: ?string,
        +syncTime: ?string,
        +rebootTime: ?string,
        +clientId: ?string,
      |},
      +deviceStatus: ?_deviceStatusType,
      +webkeyId: ?string,
      +webkeyBackupId: ?string,
    |},
  |}
|};
export type VideoDeviceSubscription = {|
  variables: VideoDeviceSubscriptionVariables,
  response: VideoDeviceSubscriptionResponse,
|};
*/


/*
subscription VideoDeviceSubscription {
  VideoDeviceSubscription {
    mutation
    node {
      id
      name
      uid
      description
      macAddress
      currentlyPlaying
      volume
      lastSync
      lanMacAddress
      internalIp
      publicIp
      lastOnline
      appVersion
      reboot
      takeScreenShot
      sync
      format
      uploadLogs
      model
      serialNumber
      usedStorage
      totalStorage
      availableStorage
      hdmiStatus
      downloading
      appUpTime
      isPlaying
      clientId
      tags
      profile {
        profileId
        name
        format
        volume
        url
        syncTime
        rebootTime
        clientId
      }
      deviceStatus
      webkeyId
      webkeyBackupId
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "volume",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "format",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "clientId",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "VideoDeviceSubscriptionPayload",
    "kind": "LinkedField",
    "name": "VideoDeviceSubscription",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "mutation",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "VideoDevice",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "uid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "macAddress",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "currentlyPlaying",
            "storageKey": null
          },
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastSync",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lanMacAddress",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "internalIp",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "publicIp",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastOnline",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "appVersion",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "reboot",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "takeScreenShot",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "sync",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "uploadLogs",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "model",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "serialNumber",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "usedStorage",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalStorage",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "availableStorage",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hdmiStatus",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "downloading",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "appUpTime",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isPlaying",
            "storageKey": null
          },
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "tags",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ProfileDetails",
            "kind": "LinkedField",
            "name": "profile",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "profileId",
                "storageKey": null
              },
              (v0/*: any*/),
              (v2/*: any*/),
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "url",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "syncTime",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "rebootTime",
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "deviceStatus",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "webkeyId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "webkeyBackupId",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "VideoDeviceSubscription",
    "selections": (v4/*: any*/),
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "VideoDeviceSubscription",
    "selections": (v4/*: any*/)
  },
  "params": {
    "id": "7fac9463fe8b5808e1e3e9f7b53bb84d",
    "metadata": {},
    "name": "VideoDeviceSubscription",
    "operationKind": "subscription",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '055b29ef659bfebc33003efe1bf845a6';

module.exports = node;
