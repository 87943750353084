import React from "react";
import { createFragmentContainer } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import compose from "recompose/compose";
import { withSnackbar } from "material-ui-snackbar-provider";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import permissions from "../authentication/isPermited";
import editUserSettingsMutation from "../mutations/editUserSettingsMutation";

const styles = (theme) => ({
  root: {
    width: 400,
    background: theme.palette.background.paper,
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
  horizontalContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  verticalContainer: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
  },
  checked: {
    color: "primary",
  },
  typo: {
    padding: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
  },
});

class UserSettings extends React.Component {
  constructor(props) {
    super(props);
    const userSettings = props.viewer.user.consoleSettings
      ? props.viewer.user.consoleSettings
      : {
          audioDevices: {
            nowPlaying: true,
            appDetails: true,
            logs: true,
            systemDetailsControls: true,
            user: true,
            tags: true,
          },
          videoDevices: {
            appDetails: true,
            deviceActions: true,
            systemDetailsControls: true,
            tags: true,
          },
          users: {
            details: true,
            actions: true,
            permissions: true,
            logs: true,
          },
        };
    this.state = {
      ...userSettings,
    };
    this.filterChecked = this.filterChecked.bind(this);
    this.handleCheck = this.handleCheck.bind(this);
    this.handleSetAsDefault = this.handleSetAsDefault.bind(this);
  }

  filterChecked() {
    const number = this.state.checkBox >= 2 ? 0 : this.state.checkBox + 1;
    this.setState({
      checkBox: number,
    });
  }

  handleCheck(name) {
    const array = name.split(".");
    this.setState({
      [array[0]]: {
        ...this.state[array[0]],
        [array[1]]: !this.state[array[0]][array[1]],
      },
    });
  }

  handleSetAsDefault() {
    const { audioDevices, videoDevices, users } = this.state;
    editUserSettingsMutation({ audioDevices, videoDevices, users }, () =>
      this.props.snackbar.showMessage("Changes performed")
    );
  }

  render() {
    const { classes } = this.props;
    const { audioDevices, videoDevices, users } = this.state;

    return (
      <div className={classes.root}>
        <Typography
          variant="subtitle1"
          color="primary"
          className={classes.typo}
        >
          Audio Devices toggle default
        </Typography>
        <div className={classes.horizontalContainer}>
          <FormGroup>
            {permissions([], [1, 2, 3, 4]) && (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={audioDevices.nowPlaying}
                    onClick={() => this.handleCheck("audioDevices.nowPlaying")}
                    classes={{
                      checked: classes.checked,
                    }}
                    value="Now Playing"
                  />
                }
                label="Now Playing"
              />
            )}
            {permissions([], [1, 2, 3, 4]) && (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={audioDevices.appDetails}
                    onClick={() => this.handleCheck("audioDevices.appDetails")}
                    classes={{
                      checked: classes.checked,
                    }}
                    value="Application Details"
                  />
                }
                label="Application Details"
              />
            )}
            {permissions([], [1, 2]) && (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={audioDevices.logs}
                    onClick={() => this.handleCheck("audioDevices.logs")}
                    classes={{
                      checked: classes.checked,
                    }}
                    value="Device Logs"
                  />
                }
                label="Device Logs"
              />
            )}
          </FormGroup>
          <FormGroup>
            {permissions([], [1, 2, 3, 4]) && (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={audioDevices.systemDetailsControls}
                    onClick={() =>
                      this.handleCheck("audioDevices.systemDetailsControls")
                    }
                    classes={{
                      checked: classes.checked,
                    }}
                    value="System Details & Controls"
                  />
                }
                label="System Details & Controls"
              />
            )}
            {permissions([], [1, 2]) && (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={audioDevices.user}
                    onClick={() => this.handleCheck("audioDevices.user")}
                    classes={{
                      checked: classes.checked,
                    }}
                    value="User Settings"
                  />
                }
                label="User Settings"
              />
            )}
            {permissions([], [1, 2]) && (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={audioDevices.tags}
                    onClick={() => this.handleCheck("audioDevices.tags")}
                    classes={{
                      checked: classes.checked,
                    }}
                    value="Tag Settings"
                  />
                }
                label="Tag Settings"
              />
            )}
          </FormGroup>
        </div>
        <Divider />
        <Typography
          variant="subtitle1"
          color="primary"
          className={classes.typo}
        >
          Video Devices toggle default
        </Typography>
        <div className={classes.horizontalContainer}>
          <FormGroup>
            {permissions([], [], [1, 2]) && (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={videoDevices.deviceActions}
                    onClick={() =>
                      this.handleCheck("videoDevices.deviceActions")
                    }
                    classes={{
                      checked: classes.checked,
                    }}
                    value="Device Actions"
                  />
                }
                label="Device Actions"
              />
            )}
            <FormControlLabel
              control={
                <Checkbox
                  checked={videoDevices.appDetails}
                  onClick={() => this.handleCheck("videoDevices.appDetails")}
                  classes={{
                    checked: classes.checked,
                  }}
                  value="Application Details"
                />
              }
              label="Application Details"
            />
          </FormGroup>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={videoDevices.systemDetailsControls}
                  onClick={() =>
                    this.handleCheck("videoDevices.systemDetailsControls")
                  }
                  classes={{
                    checked: classes.checked,
                  }}
                  value="System Details & Controls"
                />
              }
              label="System Details & Controls"
            />
            {permissions([], [], [1, 2]) && (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={videoDevices.tags}
                    onClick={() => this.handleCheck("videoDevices.tags")}
                    classes={{
                      checked: classes.checked,
                    }}
                    value="Tag Settings"
                  />
                }
                label="Tag Settings"
              />
            )}
          </FormGroup>
        </div>
        <Divider />
        {permissions([1], [], []) && (
          <Typography
            variant="subtitle1"
            color="primary"
            className={classes.typo}
          >
            Users toggle default
          </Typography>
        )}
        {permissions([1], [], []) && (
          <div className={classes.horizontalContainer}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={users.details}
                    onClick={() => this.handleCheck("users.details")}
                    classes={{
                      checked: classes.checked,
                    }}
                    value="User Details"
                  />
                }
                label="User Details"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={users.actions}
                    onClick={() => this.handleCheck("users.actions")}
                    classes={{
                      checked: classes.checked,
                    }}
                    value="User Actions"
                  />
                }
                label="User Actions"
              />
            </FormGroup>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={users.permissions}
                    onClick={() => this.handleCheck("users.permissions")}
                    classes={{
                      checked: classes.checked,
                    }}
                    value="User's Devices"
                  />
                }
                label="User's Devices"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={users.logs}
                    onClick={() => this.handleCheck("users.logs")}
                    classes={{
                      checked: classes.checked,
                    }}
                    value="User's Logs"
                  />
                }
                label="User's Logs"
              />
            </FormGroup>
          </div>
        )}
        {permissions([1], [], []) && <Divider />}
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={this.handleSetAsDefault}
        >
          Set As Default
        </Button>
      </div>
    );
  }
}

UserSettings.propTypes = {
  classes: PropTypes.object.isRequired, //eslint-disable-line
  viewer: PropTypes.object.isRequired, //eslint-disable-line
  snackbar: PropTypes.object, //eslint-disable-line
  userId: PropTypes.string.isRequired,
};

export default compose(
  withStyles(styles),
  withSnackbar()
)(
  createFragmentContainer(UserSettings, {
    viewer: graphql`
      fragment UserSettings_viewer on Viewer {
        user {
          id
          createdAt
          services {
            google {
              accessToken
              idToken
              id
              email
              name
              given_name
              family_name
              picture
            }
            facebook {
              accessToken
              id
              email
              name
              first_name
              last_name
              link
              gender
              locale
              verified
            }
          }
          emails {
            address
            verified
          }
          profile {
            name
            clientId
            videoClientId
          }
          status {
            online
            lastLogin {
              date
              ipAddr
              userAgent
            }
          }
          consoleSettings {
            audioDevices {
              appDetails
              logs
              nowPlaying
              systemDetailsControls
              user
              tags
            }
            videoDevices {
              appDetails
              deviceActions
              systemDetailsControls
              tags
            }
            users {
              details
              actions
              permissions
              logs
            }
          }
        }
      }
    `,
  })
);
