import { commitMutation } from "react-relay";
import graphql from 'babel-plugin-relay/macro';
import Environment from "../../environment";

const mutation = graphql`
  mutation generateAPIKEYMutation($userId: String!) {
    generateAPIKEY(userId: $userId) {
      id
      apiKey
    }
  }
`;

export default (userId, callback) => {
  const variables = {
    userId,
  };

  commitMutation(Environment, {
    mutation,
    variables,
    onCompleted: () => {
      callback();
    },
    onError: (err) => console.error(err), // eslint-disable-line
  });
};
