import { commitMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import Environment from "../../environment";

const mutation = graphql`
  mutation toggleHideQsysVolumeBarMutation($userId: String!, $value: Boolean) {
    toggleHideQsysVolumeBar(userId: $userId, value: $value) {
      id
      qsys {
        hideVolumeBar
      }
    }
  }
`;

export default (userId, value, callback) => {
  const variables = {
    userId,
    value,
  };

  commitMutation(Environment, {
    mutation,
    variables,
    onCompleted: () => {
      callback();
    },
    onError: (err) => console.error(err), // eslint-disable-line
  });
};
