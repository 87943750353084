import React from "react";
import moment from "moment";
import PropTypes from "prop-types";
import axios from "axios";
import { withStyles } from "@material-ui/core/styles";
import compose from "recompose/compose";
import { withSnackbar } from "material-ui-snackbar-provider";
import classnames from "classnames";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import EditIcon from "@material-ui/icons/Edit";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ClearIcon from "@material-ui/icons/Clear";
import PlaybackEdit from "./PlaybackEdit";
import PlaybackDelete from "./PlaybackDelete";
import duplicateSmartPlaylistMutation from "../mutations/duplicateSmartPlaylistMutation";
import permissions from "../authentication/isPermited";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
    minWidth: 170,
    width: 200,
    maxWidth: 230,
  },
  card: {
    maxWidth: 200,
  },
  media: {
    height: 180,
    padding: 10,
  },
  expand: {
    transform: "rotate(0deg)",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: "secondary",
  },
  flexGrow: {
    flex: "1 1 auto",
  },
  playIcon: {
    height: 28,
    width: 28,
  },
  typo: {
    marginBottom: 8,
  },
  header: {
    height: 60,
    padding: 10,
  },
  cardActions: {
    padding: 5,
  },
});

class Playback extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
      showPlaybackEdit: false,
      showPlaybackDelete: false,
      anchorElEdit: false,
      responseImage: "",
      imageHash: Date.now(),
    };
    this.handleExpandClick = this.handleExpandClick.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  getData(url) {
    axios
      .get(url)
      .then((response) => {
        this.setState({
          responseImage: response.data,
          imageHash: Date.now(),
        });
      })
      .catch((error) => {
        if (error instanceof Error) {
          console.log(error);
        } else {
          console.log(error.data);
        }
      });
  }

  componentDidMount() {
    const url = this.props.node.image;
    axios
      .get(url)
      .then((response) => {
        this.setState({
          responseImage: response.data,
          imageHash: Date.now(),
        });
      })
      .catch((error) => setTimeout(() => this.getData(url), 10000));
  }

  handleOpen(data) {
    this.setState({
      [data]: true,
    });
  }

  handleClose() {
    this.setState({
      showPlaybackEdit: false,
      showPlaybackDelete: false,
      imageHash: Date.now(),
    });
  }

  handleExpandClick() {
    this.setState({ expanded: !this.state.expanded });
  }

  render() {
    const { classes, node, allPlaylists, viewer } = this.props;
    const { showPlaybackEdit, expanded, showPlaybackDelete, imageHash } =
      this.state;
    const size = node.size / 1000000000;

    let image =
      node.image !== ""
        ? `${node.image}?${imageHash}`
        : "https://artwork.activaire.com/playlists/playlist_default.jpg";

    return (
      <div className={classes.root}>
        <Card className={classes.card}>
          <CardHeader
            className={classes.header}
            title={node.name}
            titleTypographyProps={{
              variant: "body2",
            }}
          />
          <CardMedia
            className={classes.media}
            image={image}
            title={node.name}
          />
          <CardActions className={classes.cardActions}>
            {permissions([], [1]) && (
              <Tooltip
                id="tooltip-bottom"
                title="Edit Smart Playlist"
                placement="bottom"
                enterTouchDelay={200}
              >
                <IconButton
                  size="small"
                  onClick={() => this.handleOpen("showPlaybackEdit")}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
            )}
            {permissions([], [1]) && showPlaybackEdit && (
              <PlaybackEdit
                id={node.id}
                handleRequestClose={(data) => this.handleClose(data)}
                open={showPlaybackEdit}
                data={node}
                allPlaylists={allPlaylists}
                viewer={viewer}
              />
            )}
            {permissions([], [1]) && (
              <Tooltip
                id="tooltip-bottom"
                title="Duplicate Smart Playlist"
                placement="bottom"
                enterTouchDelay={200}
              >
                <IconButton
                  size="small"
                  onClick={() =>
                    duplicateSmartPlaylistMutation(node.id, () =>
                      this.props.snackbar.showMessage("Playback Duplicated")
                    )
                  }
                >
                  <FileCopyIcon />
                </IconButton>
              </Tooltip>
            )}
            {permissions([], [1, 2]) && (
              <Tooltip
                id="tooltip-bottom"
                title="Delete Smart Playlist"
                placement="bottom"
                enterTouchDelay={200}
              >
                <IconButton
                  size="small"
                  onClick={() => this.handleOpen("showPlaybackDelete")}
                >
                  <ClearIcon />
                </IconButton>
              </Tooltip>
            )}
            {permissions([], [1, 2]) && (
              <PlaybackDelete
                id={node.id}
                handleRequestClose={(data) => this.handleClose(data)}
                open={showPlaybackDelete}
              />
            )}
            <div className={classes.flexGrow} />
            <Tooltip
              id="tooltip-bottom"
              title="Expand Toggle"
              placement="bottom"
              enterTouchDelay={200}
            >
              <IconButton
                className={classnames(classes.expand, {
                  [classes.expandOpen]: expanded,
                })}
                onClick={this.handleExpandClick}
                aria-expanded={expanded}
                size="small"
              >
                <ExpandMoreIcon />
              </IconButton>
            </Tooltip>
          </CardActions>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardContent>
              <Typography paragraph type="subheading" className={classes.typo}>
                Playback details:
              </Typography>
              <Typography paragraph className={classes.typo}>
                Size:
                <br />
                {size.toFixed(2)} GB
              </Typography>
              <Typography className={classes.typo}>
                Last update:
                <br />
                {moment(node.createdAt).format("DD MMM YYYY")}
              </Typography>
            </CardContent>
          </Collapse>
        </Card>
      </div>
    );
  }
}

Playback.propTypes = {
  classes: PropTypes.object.isRequired, // eslint-disable-line
  node: PropTypes.object.isRequired, // eslint-disable-line
  snackbar: PropTypes.object, //eslint-disable-line
  allPlaylists: PropTypes.array.isRequired, // eslint-disable-line
  userRoot: PropTypes.object, // eslint-disable-line
  viewer: PropTypes.object.isRequired, // eslint-disable-line
};

export default compose(withStyles(styles), withSnackbar())(Playback);
