import React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

const styles = {
  root: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginTop: "40vh",
  },
  loadingImage: {
    alignSelf: "center",
  },
  circle: {
    color: "#c935ed",
    marginTop: 40,
  },
};

function LinearIndeterminate(props) {
  const { classes, timer } = props;
  return (
    <div className={classes.root}>
      <Typography variant="h4" color="textSecondary" align="center">
        Retrying Connection...{timer}
      </Typography>
      <div className={classes.loadingImage}>
        <CircularProgress
          color="secondary"
          className={classes.circle}
          size={100}
        />
      </div>
    </div>
  );
}

LinearIndeterminate.propTypes = {
  classes: PropTypes.object.isRequired, // eslint-disable-line
};

export default withStyles(styles)(LinearIndeterminate);
