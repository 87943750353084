import React from "react";
import { QueryRenderer } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import moment from "moment";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import ReactTable from "react-table";
import "react-table/react-table.css";
import CircularProgress from "@material-ui/core/CircularProgress";

import Environment from "../../environment";
import { withStyles } from "@material-ui/core/styles";
import * as XLSX from "xlsx";

const queryClient = graphql`
  query SearchReportsQuery {
    viewer {
      allSearchReports {
        id
        clientId
        clientName
        userId
        userEmail
        deviceId
        deviceName
        countryCode
        platform
        searchString
        timestamp
      }
    }
  }
`;

const styles = (theme) => ({
  icon: {
    fontSize: 16,
  },
  buttonReport: {
    marginRight: theme.spacing(1),
    alignSelf: "center",
  },
  buttonReportContainer: {
    display: "flex",
    alignSelf: "center",
    flex: "auto",
    flexDirection: "row-reverse",
    marginRight: 8,
  },
  horizontalContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  verticalContainer: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
  },
});

const SearchReports = (props) => {
  const { classes } = props;

  const reactTable = React.useRef(null);
  const handleExportReport = () => {
    const filteredData = reactTable.current.getResolvedState().sortedData;
    const date = moment().format("YYYYMMDDHHMMSS");
    const data = filteredData.map((item) => {
      return {
        Platform: item.platform,
        "Client ID": item.clientId,
        "Client Name": item.clientName,
        "User Email": item.userEmail,
        "Device Name": item.deviceName,
        "Search String": item.searchString,
        Timestamp: moment(item.timestamp).format("MM/DD/YYYY HH:mm:ss"),
      };
    });
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(data);
    ws["!cols"] = [];
    const n = 4;
    for (let i = 0; i < n; i += 1) {
      ws["!cols"][i] = { width: 28 };
    }
    XLSX.utils.book_append_sheet(wb, ws, "LogData");
    XLSX.writeFile(wb, `${date}-search-report.xlsx`);
  };

  return (
    <QueryRenderer
      variables={{}}
      environment={Environment}
      query={queryClient}
      render={({ error, props }) => {
        if (error) {
          console.log(error);
        } else if (props && props.viewer) {
          const allReports = props.viewer.allSearchReports;

          const columns = [
            {
              Header: "Platform",
              accessor: "platform",
              maxWidth: 100,
            },
            {
              Header: "Client ID",
              accessor: "clientId",
              maxWidth: 200,
            },
            {
              Header: "Client Name",
              accessor: "clientName",
              maxWidth: 100,
            },
            {
              Header: "User Email",
              accessor: "userEmail",
              maxWidth: 200,
            },
            {
              Header: "Device Name",
              accessor: "deviceName",
              maxWidth: 100,
            },
            {
              Header: "Search String",
              accessor: "searchString",
              maxWidth: 120,
            },
            {
              Header: "Timestamp",
              accessor: "timestamp",
              maxWidth: 150,
            },
          ];

          return (
            <>
              <div className={classes.verticalContainer}>
                <div className={classes.horizontalContainer}>
                  <div className={classes.buttonReportContainer}>
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.buttonReport}
                      onClick={handleExportReport}
                    >
                      Export Report
                    </Button>
                  </div>
                </div>
                <ReactTable
                  ref={reactTable}
                  loading={!allReports}
                  className="-striped -highlight"
                  data={allReports || []}
                  filterable
                  defaultFilterMethod={(filters, row) =>
                    String(row[filters.id])
                      .toLowerCase()
                      .includes(filters.value.toLowerCase())
                  }
                  columns={columns}
                  style={{
                    display: "block",
                    margin: 8,
                    width: "100%",
                    fontSize: 11,
                  }}
                />
              </div>
            </>
          );
        }
        return (
          <div
            style={{
              zIndex: "-1",
              position: "absolute",
              top: "50%",
              left: "50%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography
              variant="h4"
              color="#fafafa"
              align="center"
              style={{ color: "#3BABA1" }}
            >
              Fetching data...
            </Typography>
            <CircularProgress
              size={70}
              style={{
                color: "#3BABA1",
                marginTop: "40px",
                alignSelf: "center",
              }}
            />
          </div>
        );
      }}
    />
  );
};

export default withStyles(styles)(SearchReports);
