/**
 * External dependencies
 */

import React, { useState, createContext } from "react";
export const CurrentRouteContext = createContext();

const RouteContextProvider = ({ children }) => {
  const [currentRoute, setCurrentRoute] = useState();
  const [routeDisabled, setRouteDisabled] = useState();
  return (
    <CurrentRouteContext.Provider
      value={{
        currentRoute,
        setCurrentRoute,
        routeDisabled,
        setRouteDisabled,
      }}
    >
      {children}
    </CurrentRouteContext.Provider>
  );
};
export default RouteContextProvider;
