import { commitMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import Environment from "../../environment";

const mutation = graphql`
  mutation addAPKtoS3AndDBMutation(
    $keyName: String!
    $appName: String!
    $packageName: String
    $description: String
    $versionCode: Int
    $versionName: String
    $icon: String
  ) {
    addAPKtoS3AndDB(
      keyName: $keyName
      appName: $appName
      packageName: $packageName
      description: $description
      versionCode: $versionCode
      versionName: $versionName
      icon: $icon
    )
  }
`;

export default (
  keyName,
  appName,
  packageName,
  description,
  versionCode,
  versionName,
  icon,
  callback
) => {
  const variables = {
    keyName,
    appName,
    packageName,
    description,
    versionCode,
    versionName,
    icon,
  };

  commitMutation(Environment, {
    mutation,
    variables,
    onCompleted: (response) => {
      if (response && response.addAPKtoS3AndDB) {
        callback(response.addAPKtoS3AndDB);
      }
    },
    onError: (err) => console.error(err), // eslint-disable-line
  });
};
