/**
 * @flow
 * @relayHash a6bab0ae0964d0cd139e3d10646e871a
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type VideoCampaignsQueryVariables = {||};
export type VideoCampaignsQueryResponse = {|
  +viewer: {|
    +allVideoCampaigns: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: {|
          +id: string,
          +name: string,
          +description: ?string,
          +clientId: string,
          +goLiveDate: any,
          +deviceIds: ?$ReadOnlyArray<string>,
          +profileIds: ?$ReadOnlyArray<string>,
          +onHold: boolean,
        |}
      |}>
    |},
    +allTags: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: {|
          +id: string,
          +name: string,
          +description: ?string,
        |}
      |}>
    |},
    +allVideoDevices: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: {|
          +id: string,
          +name: ?string,
          +tags: ?$ReadOnlyArray<?string>,
          +clientId: ?string,
          +format: ?string,
          +currentlyPlaying: ?string,
        |}
      |}>
    |},
    +allVideoClients: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: {|
          +id: string,
          +name: string,
        |}
      |}>
    |},
    +allVideoProfiles: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: {|
          +id: string,
          +name: string,
          +format: string,
          +clientId: ?string,
        |}
      |}>
    |},
  |}
|};
export type VideoCampaignsQuery = {|
  variables: VideoCampaignsQueryVariables,
  response: VideoCampaignsQueryResponse,
|};
*/


/*
query VideoCampaignsQuery {
  viewer {
    allVideoCampaigns {
      edges {
        node {
          id
          name
          description
          clientId
          goLiveDate
          deviceIds
          profileIds
          onHold
        }
      }
    }
    allTags {
      edges {
        node {
          id
          name
          description
        }
      }
    }
    allVideoDevices {
      edges {
        node {
          id
          name
          tags
          clientId
          format
          currentlyPlaying
        }
      }
    }
    allVideoClients {
      edges {
        node {
          id
          name
        }
      }
    }
    allVideoProfiles {
      edges {
        node {
          id
          name
          format
          clientId
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "clientId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "format",
  "storageKey": null
},
v5 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Viewer",
    "kind": "LinkedField",
    "name": "viewer",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "VideoCampaignConnection",
        "kind": "LinkedField",
        "name": "allVideoCampaigns",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "VideoCampaignEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "VideoCampaign",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  (v1/*: any*/),
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "goLiveDate",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "deviceIds",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "profileIds",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "onHold",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "TagConnection",
        "kind": "LinkedField",
        "name": "allTags",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TagEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Tag",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  (v1/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "VideoDeviceConnection",
        "kind": "LinkedField",
        "name": "allVideoDevices",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "VideoDeviceEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "VideoDevice",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "tags",
                    "storageKey": null
                  },
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "currentlyPlaying",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "VideoClientConnection",
        "kind": "LinkedField",
        "name": "allVideoClients",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "VideoClientEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "VideoClient",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  (v1/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "VideoProfileConnection",
        "kind": "LinkedField",
        "name": "allVideoProfiles",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "VideoProfileEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "VideoProfile",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  (v1/*: any*/),
                  (v4/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "VideoCampaignsQuery",
    "selections": (v5/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "VideoCampaignsQuery",
    "selections": (v5/*: any*/)
  },
  "params": {
    "id": "a6bab0ae0964d0cd139e3d10646e871a",
    "metadata": {},
    "name": "VideoCampaignsQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '7ca90c779ad5809eaa707b3ab2659249';

module.exports = node;
