import React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

const styles = {
  root: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginTop: "40vh",
  },
  loadingImage: {
    alignSelf: "center",
  },
  circle: {
    color: "#3BABA1",
    marginTop: 40,
  },
};

function LinearIndeterminate(props) {
  const { classes } = props;
  return (
    <div className={classes.root}>
      <Typography
        variant="h6"
        color="textSecondary"
        align="center"
        style={{ color: "#3BABA1" }}
      >
        Fetching data...
      </Typography>
      <div className={classes.loadingImage}>
        <CircularProgress
          color="secondary"
          className={classes.circle}
          size={50}
        />
      </div>
    </div>
  );
}

LinearIndeterminate.propTypes = {
  classes: PropTypes.object.isRequired, // eslint-disable-line
};

export default withStyles(styles)(LinearIndeterminate);
