import { commitMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import Environment from "../../environment";

const mutation = graphql`
  mutation addAPKSourceMutation($keyName: String!) {
    addAPKSource(keyName: $keyName) {
      id
      appName
      keyName
      source
      packageName
      description
      versionCode
      versionName
      icon
    }
  }
`;

export default (keyName, callback) => {
  const variables = {
    keyName,
  };

  commitMutation(Environment, {
    mutation,
    variables,
    onCompleted: (response) => {
      if (response && response.addAPKSource) {
        callback(response.addAPKSource);
      }
    },
    onError: (err) => console.error(err), // eslint-disable-line
  });
};
