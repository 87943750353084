/* eslint-disable
no-unused-expressions,
no-return-assign,
react/no-array-index-key */

import React from "react";
import { QueryRenderer } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import Environment from "../../environment";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { withSnackbar } from "material-ui-snackbar-provider";
import compose from "recompose/compose";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import SwapHorizontalCircleIcon from "@material-ui/icons/SwapHorizontalCircle";
import SendIcon from "@material-ui/icons/Send";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import InputAdornment from "@material-ui/core/InputAdornment";
import ReactTable from "react-table";
import moment from "moment";
import jwt from "jsonwebtoken";
import "react-table/react-table.css";

import DeviceLogSubscription from "../subscriptions/DeviceLogSubscription";
import DsBlobSubscription from "../subscriptions/DsBlobSubscription";
import toggleLogsMutation from "../mutations/toggleLogsMutation";
import requestBlobMutation from "../mutations/requestBlobMutation";
import { Paper } from "@material-ui/core";

const childQueryRender = graphql`
  query DeviceLogsQuery {
    viewer {
      allAudioDevices {
        edges {
          node {
            id
            name
            uid
            isOnline
            deviceStatus
            appVersionDS
          }
        }
      }
      allDsBlobs {
        edges {
          node {
            id
            deviceId
            randomBlob
            deviceTimestamp
            serverTimestamp
            deviceUid
          }
        }
      }
      allDeviceLogs {
        edges {
          node {
            deviceId
            log
            deviceTimestamp
            serverTimestamp
            deviceUid
            isLogging
          }
        }
      }
      allEvents {
        edges {
          node {
            methods
            deviceUid
            status
            date
          }
        }
      }
    }
  }
`;

const styles = (theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    margin: "0px 0px 0px 0px",
  },
  content: {
    flex: "0 1 auto",
  },
  typo: {
    padding: 0,
  },
  typoClient: {
    padding: "0px 0px 0px 0px",
    color: "secondary",
  },
  button: {
    width: 70,
    marginLeft: 8,
  },
  horizontalContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  actions: {
    display: "flex",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  textField2: {
    width: 100,
  },
  margin: {
    margin: theme.spacing(1),
  },
  paper: {
    margin: "auto",
    width: 500,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: 16,
  },
});

class DeviceLogs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: jwt.decode(localStorage.getItem("authentication_token")),
      blob: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.enterPressed = this.enterPressed.bind(this);
  }

  shouldComponentUpdate() {
    if (this.props.match.location.pathname === window.location.pathname) {
      return true;
    }
    return false;
  }

  componentDidMount() {
    const { user } = this.state;
    const loginProcedure = async () => {
      if (user) {
        this.subscription1 = await DeviceLogSubscription(user);
        this.subscription2 = await DsBlobSubscription(user);
      }
    };
    loginProcedure();
  }

  componentWillUnmount() {
    try {
      this.subscription1?.dispose();
      this.subscription2?.dispose();
    } catch (e) {
      console.log("componentWillUnmount Permissions Error:", e);
    }
  }

  handleChange(event, name) {
    this.setState({
      [name]: event.target.value,
    });
  }

  enterPressed(event) {
    const code = event.keyCode || event.which;
    if (code === 13) {
      console.log("works");
    }
  }

  render() {
    const { classes } = this.props;
    const { blob } = this.state;
    return (
      <QueryRenderer
        environment={Environment}
        query={childQueryRender}
        render={({ error, props }) => {
          if (error) {
            return <div>{error.message}</div>;
          } else if (props) {
            const { viewer } = props;
            const allLogs = viewer.allDeviceLogs.edges
              .filter((item) => item && item.node)
              .map((item) => item.node);
            const allBlobs = viewer.allDsBlobs.edges
              .filter((item) => item && item.node)
              .map((item) => item.node);
            const allEvents = viewer.allEvents.edges
              .filter((item) => item && item.node)
              .map((item) => item.node);
            const data = viewer.allAudioDevices.edges
              .filter(
                (item) =>
                  item &&
                  item.node &&
                  item.node.appVersionDS &&
                  item.node.deviceStatus === "ACTIVE"
              )
              .map((item) => item.node);
            const fixedColumns = [
              {
                id: "isOnline",
                Header: "Online",
                accessor: (a) => (a.isOnline ? "Yes" : "No"),
                maxWidth: 70,
              },
              {
                id: "name",
                Header: "Device Name",
                accessor: (a) => a.name,
                maxWidth: 200,
              },
              {
                id: "uid",
                Header: "Device UID",
                accessor: (a) => a.uid,
                maxWidth: 300,
              },
              {
                id: "deviceTime",
                Header: "Device Time",
                accessor: (a) => {
                  try {
                    const log = allLogs.filter(
                      (item) => item.deviceUid === a.uid
                    )[0];
                    const deviceTime = log
                      ? moment(log.deviceTimestamp).format(
                          "MMMM Do YYYY, HH:mm:ss"
                        )
                      : "N/A";
                    return deviceTime;
                  } catch (e) {
                    console.log("Device time error: ", e);
                  }
                },
                maxWidth: 160,
              },
              {
                id: "serverime",
                Header: "Server Time",
                accessor: (a) => {
                  try {
                    const log = allLogs.filter(
                      (item) => item.deviceUid === a.uid
                    )[0];
                    const serverTime = log
                      ? moment(log.serverTimestamp).format(
                          "MMMM Do YYYY, HH:mm:ss"
                        )
                      : "N/A";
                    return serverTime;
                  } catch (e) {
                    console.log("Server time error: ", e);
                  }
                },
                maxWidth: 160,
              },
              {
                id: "log",
                Header: "Log",
                accessor: (a) => {
                  try {
                    const log = allLogs.filter(
                      (item) => item.deviceUid === a.uid
                    )[0];
                    return log ? log.log : "N/A";
                  } catch (e) {
                    console.log("Device time error: ", e);
                  }
                },
                maxWidth: 500,
              },
              {
                Header: "Blob status",
                columns: [
                  {
                    id: "blobTimestamp",
                    Header: "Blob timestamp",
                    accessor: (a) => {
                      try {
                        const blobs = allBlobs.filter(
                          (item) => item.deviceUid === a.uid
                        );
                        let time =
                          blobs[0] &&
                          Math.max(...blobs.map((s) => s.serverTimestamp));
                        return blobs[0]
                          ? moment(time).format("MMMM Do YYYY, HH:mm:ss")
                          : "N/A";
                      } catch (e) {
                        console.log("Device time error: ", e);
                      }
                    },
                    maxWidth: 500,
                  },
                  {
                    id: "blobStatus",
                    Header: "Blob status",
                    accessor: (a) => {
                      try {
                        const events = allEvents.filter(
                          (item) => item.deviceUid === a.uid
                        );
                        if (events[0]) {
                          const maxDate = Math.max(
                            ...events.map((item) => item.date)
                          );
                          const status = events.find(
                            (item) => item.date === maxDate
                          );
                          return status ? status.status : "N/A";
                        } else {
                          return "N/A";
                        }
                      } catch (e) {
                        console.log("Device time error: ", e);
                      }
                    },
                    maxWidth: 500,
                  },
                ],
              },
              {
                Header: "Actions",
                width: 160,
                filterable: false,
                sortable: false,
                Cell: (row) => {
                  const log = allLogs.filter(
                    (item) => item.deviceId === row.original.id
                  )[0];
                  return (
                    <div className={classes.actions}>
                      <Button
                        variant="text"
                        size="small"
                        disabled={!row.original.isOnline}
                        color={log && log.isLogging ? "primary" : "secondary"}
                        className={classes.button}
                        onClick={() =>
                          toggleLogsMutation(row.original.uid, () =>
                            this.props.snackbar.showMessage("Done!")
                          )
                        }
                      >
                        <SwapHorizontalCircleIcon />
                      </Button>
                    </div>
                  );
                },
              },
            ];
            const pageSize = 10;
            return (
              <>
                <div className={classes.root}>
                  <h2 style={{ marginLeft: "12px", paddingTop: "12px" }}>
                    {this.props.match.route.name}
                  </h2>
                  <div className={classes.horizontalContainer}>
                    <TextField
                      label="Package size"
                      className={classNames(classes.margin, classes.textField2)}
                      margin="dense"
                      value={blob}
                      onChange={(event) => this.handleChange(event, "blob")}
                      onKeyPress={(event) => this.enterPressed(event)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">Kb</InputAdornment>
                        ),
                      }}
                    />
                    <Tooltip
                      id="tooltip-bottom"
                      title="Bulk request blob"
                      placement="bottom"
                      enterTouchDelay={200}
                    >
                      <Button
                        variant="text"
                        size="small"
                        color="primary"
                        className={classes.button}
                        onClick={() => requestBlobMutation(Number(blob))}
                      >
                        <SendIcon />
                      </Button>
                    </Tooltip>
                  </div>
                  <div className={classes.horizontalContainer}>
                    <ReactTable
                      data={data}
                      columns={fixedColumns}
                      defaultPageSize={pageSize}
                      showPagination={data.length > pageSize}
                      filterable
                      defaultFilterMethod={(filter, row) =>
                        String(row[filter.id])
                          .toLowerCase()
                          .includes(filter.value.toLowerCase())
                      }
                      className="-striped -highlight"
                      style={{
                        display: "block",
                        margin: "8px 0px 8px 8px",
                        fontSize: "13px",
                        flex: "auto",
                        minWidth: "35vw",
                        Paper,
                      }}
                    />
                  </div>
                  <Typography
                    variant="caption"
                    color="secondary"
                    className={classes.typo}
                    style={{ paddingLeft: 8 }}
                    align="left"
                  >
                    Tip: Hold shift when sorting to multi-sort!
                  </Typography>
                </div>
              </>
            );
          }
          return null;
        }}
      />
    );
  }
}

DeviceLogs.propTypes = {
  classes: PropTypes.object.isRequired,
  viewer: PropTypes.object.isRequired,
};

export default compose(withStyles(styles), withSnackbar())(DeviceLogs);
