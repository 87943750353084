/**
 * External dependencies
 */
import React, { useEffect, useContext } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Divider,
  Checkbox,
  Collapse,
  List,
  ListItemIcon,
} from "@material-ui/core";
import { ExpandLess, ExpandMore, Remove, ClearAll } from "@material-ui/icons";

/**
 * Internal dependencies
 */

import { CurrentMenuContext } from "../../hooks/currentMenuProvider";
import toggleRouteCategoryMutation from "../../../mutations/toggleRouteCategoryMutation";
import flattenArrays from "../utils/flattenArrays";
import toggleQsysRouteCategoryMutation from "../../../mutations/toggleQsysRouteCategoryMutation";
import { CurrentClientContext } from "../../hooks/currentClientProvider";

const styles = (theme) => ({
  root: {},
  nested: {
    paddingLeft: theme.spacing(4),
  },
});

const RouteListSubCategory = ({
  classes,
  playableList,
  route,
  searchValue,
}) => {
  const { audioProvider } = useContext(CurrentClientContext);
  const [checked, setChecked] = React.useState([""]);
  const [open, setOpen] = React.useState([]);
  const { currentMenu, currentMenuExtension } = useContext(CurrentMenuContext);

  const handledMenu = currentMenuExtension || currentMenu;

  useEffect(() => {
    route.subCategory && route.subCategory !== ""
      ? setChecked([route.subCategory])
      : setChecked([]);
  }, [route]);

  const handleOpen = (category) => {
    const newOpen = [...new Set(open)];
    if (newOpen.includes(category)) {
      const index = newOpen.indexOf((item) => item === category);
      newOpen.splice(index, 1);
      setOpen(newOpen);
    } else {
      newOpen.push(category);
      setOpen(newOpen);
    }
  };

  const handleToggle = (subCategory, category) => () => {
    const currentIndex = checked.indexOf(subCategory);
    console.log(subCategory);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(subCategory);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
    let routeItems = 0;

    if (newChecked[0]) {
      routeItems = playableList.filter(
        (item) => item?.subCategory === newChecked[0]
      ).length;
      routeItems = playableList.filter((item) =>
        flattenArrays(
          item?.newCategory?.map((item2) =>
            item2.category === category ? item2.subCategories : []
          )
        )?.includes(subCategory)
      ).length;
    }
    audioProvider === "sonos"
      ? toggleRouteCategoryMutation(
          handledMenu.id,
          route.pathId,
          "subCategory",
          category,
          newChecked[0] || "",
          routeItems,
          () => console.log("Done!")
        )
      : toggleQsysRouteCategoryMutation(
          handledMenu.id,
          route.pathId,
          "subCategory",
          category,
          newChecked[0] || "",
          routeItems,
          () => console.log("Done!")
        );
  };

  const listOfCategories =
    (playableList && [
      ...new Set(
        flattenArrays(
          playableList
            .filter((item) => item?.newCategory?.length > 0)
            .map((item) =>
              flattenArrays(item.newCategory.map((item2) => item2?.category))
            ) || []
        )
      ),
    ]) ||
    [];

  const listOfSubCategories =
    listOfCategories.length > 0
      ? listOfCategories.map((item) => {
          const returnObj = { category: item };
          let subCategory = [];
          playableList.forEach((item2) => {
            const subCategoriesArray = flattenArrays(
              item2.newCategory
                .filter((item3) => item3.category === item)
                .map((item3) => item3.subCategories || []) || []
            );
            if (subCategoriesArray.length > 0) {
              subCategory = subCategory.concat(subCategoriesArray);
            }
          });
          Object.assign(returnObj, { subCategory: [...new Set(subCategory)] });
          return returnObj;
        })
      : [];
  const regex = new RegExp(searchValue, "gi");
  return listOfSubCategories
    .filter((item) =>
      searchValue === ""
        ? true
        : item?.category.search(regex) !== -1 ||
          item?.subCategory.some((item2) => item2.search(regex) !== -1)
    )
    .map((item) => (
      <div key={item.category}>
        <ListItem
          component="nav"
          className={classes.root}
          aria-label="menu-list-item"
          button
          onClick={() => handleOpen(item.category)}
          style={{
            ...(item?.subCategory?.includes(checked[0]) && {
              background: "#e1e1e1",
            }),
          }}
        >
          <ListItemIcon>
            <ClearAll />
          </ListItemIcon>
          <ListItemText primary={item.category} />
          {open.includes(item.category) ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Divider />
        {item.subCategory
          .filter((item2) => item2.search(regex) !== -1)
          .map((item2) => (
            <Collapse
              key={item2}
              in={open.includes(item.category)}
              timeout="auto"
              unmountOnExit
            >
              <List component="div" disablePadding>
                <ListItem button className={classes.nested}>
                  <ListItemIcon>
                    <Remove />
                  </ListItemIcon>
                  <ListItemText primary={item2} />
                  <ListItemSecondaryAction>
                    <Checkbox
                      edge="end"
                      onChange={handleToggle(item2, item.category)}
                      checked={checked.indexOf(item2) !== -1}
                      disabled={checked.length === 1 && checked[0] !== item2}
                      inputProps={{ "aria-labelledby": item2 }}
                    />
                  </ListItemSecondaryAction>
                </ListItem>
              </List>
            </Collapse>
          ))}
      </div>
    ));
};

export default withStyles(styles)(RouteListSubCategory);
