import { commitMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import Environment from "../../environment";

const mutation = graphql`
  mutation updateAudioDeviceNextSongMutation($inputDeviceId: String!) {
    updateAudioDeviceNextSong(deviceId: $inputDeviceId) {
      id
    }
  }
`;

export default (deviceId, callback) => {
  const variables = {
    inputDeviceId: deviceId,
  };

  commitMutation(Environment, {
    mutation,
    variables,
    onCompleted: () => {
      callback();
    },
    onError: (err) => console.error(err), // eslint-disable-line
  });
};
