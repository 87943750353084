import { commitMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import Environment from "../../environment";

const mutation = graphql`
  mutation createScheduleMutation($input: createScheduleProviderData!) {
    createSchedule(createScheduleProvider: $input) {
      id
      name
      description
      injectable
      startDate
      endDate
      clientId
      size
      playingPlaylists {
        playlistId
        name
        volume
        startTime
        endTime
        startTimeSeconds
        endTimeSeconds
        days
        shuffle
        filenames {
          mixId
          nrOfSongs
        }
      }
    }
  }
`;

export default (
  name,
  description,
  injectable,
  startDate,
  endDate,
  clientId,
  size,
  playingPlaylists,
  callback
) => {
  const variables = {
    input: {
      name,
      description,
      injectable,
      startDate,
      endDate,
      clientId,
      size: size || 0,
      playingPlaylists,
    },
  };

  commitMutation(Environment, {
    mutation,
    variables,
    onCompleted: (response) => {
      callback();
    },
    onError: (err) => console.error(err), // eslint-disable-line
  });
};
