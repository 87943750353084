/**
 * External dependencies
 */
import React, { useState, useContext, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  TextField,
  Button,
  MenuItem,
  ListItemAvatar,
  Avatar,
} from "@material-ui/core";

/**
 * Internal dependencies
 */

import createSonosRouteFolderMutation from "../../../mutations/createSonosRouteFolderMutation";
import { CurrentMenuContext } from "../../hooks/currentMenuProvider";
import { autoPopulateType } from "../utils/autoPopulateType";
import { playableTypes } from "../utils/playableTypes";
import { folderCovers } from "../utils/folderCovers";
import createQsysRouteFolderMutation from "../../../mutations/createQsysRouteFolderMutation";
import { CurrentClientContext } from "../../hooks/currentClientProvider";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 300,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: "10vh",
    margin: "auto",
    left: 0,
    right: 0,
    borderRadius: 19,
  },
  form: {
    display: "flex",
    flexDirection: "column",
  },
  textField: {
    margin: 8,
  },
  button: {
    margin: 8,
  },
  switch: {
    margin: 8,
  },
  imageList: {
    flexWrap: "nowrap",
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: "translateZ(0)",
  },
  title: {
    color: "white",
  },
  titleBar: {
    background:
      "linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
  },
}));

export default function CreateRouteModal({ handleClose, route }) {
  const { audioProvider } = useContext(CurrentClientContext);
  const classes = useStyles();
  const [routeName, setRouteName] = useState("");
  const [routePopulate, setRoutePopulate] = useState("NONE");
  const [routePlayableTypes, setRoutePlayableTypes] = useState("BOTH");
  const [coverLabel, setCoverLabel] = useState("Playlists-Main");
  const [coverSet, setCoverSet] = useState(
    folderCovers.find((item) => item.label === "Playlists-Main")
  );
  const defaultArtwork = folderCovers.find(
    (item) => item.label === "Playlists-Main"
  );

  const { currentMenu, currentMenuExtension } = useContext(CurrentMenuContext);

  const handleChange = (event, field) => {
    // eslint-disable-next-line default-case
    switch (field) {
      case "route":
        setRouteName(event.target.value);
        break;
    }
  };

  const handlePopulate = (event) => {
    setRoutePopulate(event.target.value);
  };

  const handlePlayableTypes = (event) => {
    setRoutePlayableTypes(event.target.value);
  };

  const handleCovers = (event) => {
    setCoverLabel(event.target.value);
  };

  const createRoute = () => {
    const parentId = route?.pathId || 0;
    audioProvider === "sonos"
      ? createSonosRouteFolderMutation(
          currentMenuExtension?.id || currentMenu.id,
          routeName,
          parentId,
          routePopulate,
          coverSet?.label,
          coverSet?.cover_40,
          coverSet?.cover_290,
          coverSet?.cover_legacy,
          routePlayableTypes,
          () => {
            handleClose(!!currentMenuExtension?.id);
          }
        )
      : createQsysRouteFolderMutation(
          currentMenuExtension?.id || currentMenu.id,
          routeName,
          parentId,
          routePopulate,
          coverSet?.label,
          coverSet?.cover_40,
          coverSet?.cover_290,
          coverSet?.cover_legacy,
          routePlayableTypes,
          () => {
            handleClose(!!currentMenuExtension?.id);
          }
        );
  };

  useEffect(() => {
    const allCovers = folderCovers.find((item) => item.label === coverLabel);
    setCoverSet(allCovers);
  }, [coverLabel]);

  return (
    <>
      <div className={classes.paper}>
        <h2 id="edit-route-modal-title">Create route folder:</h2>
        <form className={classes.form} noValidate autoComplete="off">
          <TextField
            id="route-edit"
            label="Path Name"
            onChange={(event) => handleChange(event, "route")}
            value={routeName}
            className={classes.textField}
            error={routeName.length < 2 && routeName.length > 30}
            helperText="The name is caps sensitive. Max 30 characters and more then 3"
          />
          <TextField
            id="select-populate-type"
            className={classes.textField}
            select
            label="Select"
            value={routePopulate}
            onChange={handlePopulate}
            helperText="Select how to populate this folder"
          >
            {autoPopulateType.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          {routePopulate === "GENRE" && (
            <TextField
              id="select-playble-types-genre"
              className={classes.textField}
              select
              label="Playable types"
              value={routePlayableTypes}
              onChange={handlePlayableTypes}
              helperText="Select playable types"
            >
              {playableTypes.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          )}
          <TextField
            id="select-cover-type"
            className={classes.textField}
            select
            label="Select folder artwork"
            value={coverLabel}
            onChange={handleCovers}
            helperText="Select cover set for this folder"
          >
            {folderCovers.map((option) => (
              <MenuItem key={option.label} value={option.label}>
                <ListItemAvatar>
                  <Avatar
                    variant="rounded"
                    alt={option.label}
                    src={option?.cover_40 || defaultArtwork.cover_40}
                  />
                </ListItemAvatar>{" "}
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            disabled={routeName.length < 2 && routeName.length > 30}
            onClick={createRoute}
          >
            Create path
          </Button>
        </form>
      </div>
    </>
  );
}
