/**
 * @flow
 * @relayHash 0b10ed9ca31bb476972d7dce441f21b1
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type generateAPIKEYMutationVariables = {|
  userId: string
|};
export type generateAPIKEYMutationResponse = {|
  +generateAPIKEY: {|
    +id: string,
    +apiKey: ?string,
  |}
|};
export type generateAPIKEYMutation = {|
  variables: generateAPIKEYMutationVariables,
  response: generateAPIKEYMutationResponse,
|};
*/


/*
mutation generateAPIKEYMutation(
  $userId: String!
) {
  generateAPIKEY(userId: $userId) {
    id
    apiKey
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "userId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "userId",
        "variableName": "userId"
      }
    ],
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "generateAPIKEY",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "apiKey",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "generateAPIKEYMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "generateAPIKEYMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "0b10ed9ca31bb476972d7dce441f21b1",
    "metadata": {},
    "name": "generateAPIKEYMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b4bb6770f706470d8745114159179a0b';

module.exports = node;
