/**
 * @flow
 * @relayHash e4bf603326faa11910bfbba2f46a2b60
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ServerInstance = "backup" | "primary" | "%future added value";
export type getNewWebkeyTokenMutationVariables = {|
  input: string,
  serverInstance: ServerInstance,
|};
export type getNewWebkeyTokenMutationResponse = {|
  +getNewWebkeyToken: {|
    +id: string,
    +tokenId: string,
  |}
|};
export type getNewWebkeyTokenMutation = {|
  variables: getNewWebkeyTokenMutationVariables,
  response: getNewWebkeyTokenMutationResponse,
|};
*/


/*
mutation getNewWebkeyTokenMutation(
  $input: String!
  $serverInstance: ServerInstance!
) {
  getNewWebkeyToken(publicid: $input, serverInstance: $serverInstance) {
    id
    tokenId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "serverInstance"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "publicid",
        "variableName": "input"
      },
      {
        "kind": "Variable",
        "name": "serverInstance",
        "variableName": "serverInstance"
      }
    ],
    "concreteType": "WebkeyToken",
    "kind": "LinkedField",
    "name": "getNewWebkeyToken",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "tokenId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "getNewWebkeyTokenMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "getNewWebkeyTokenMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "e4bf603326faa11910bfbba2f46a2b60",
    "metadata": {},
    "name": "getNewWebkeyTokenMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f4d27928d1c275442e34077af4ba355c';

module.exports = node;
