/**
 * @flow
 * @relayHash 4895d3ce9c57a8ad85f7a805f6d6946e
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type _ModelMutationType = "CREATED" | "DELETED" | "UPDATED" | "UPDATEDCLIENT" | "%future added value";
export type NotificationSubscriptionVariables = {||};
export type NotificationSubscriptionResponse = {|
  +NotificationSubscription: {|
    +mutation: _ModelMutationType,
    +node: ?{|
      +id: string,
      +type: string,
      +message: string,
      +dateCreated: any,
      +seenBy: ?$ReadOnlyArray<?string>,
    |},
  |}
|};
export type NotificationSubscription = {|
  variables: NotificationSubscriptionVariables,
  response: NotificationSubscriptionResponse,
|};
*/


/*
subscription NotificationSubscription {
  NotificationSubscription {
    mutation
    node {
      id
      type
      message
      dateCreated
      seenBy
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "NotificationSubscriptionPayload",
    "kind": "LinkedField",
    "name": "NotificationSubscription",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "mutation",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Notification",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "type",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "dateCreated",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "seenBy",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "NotificationSubscription",
    "selections": (v0/*: any*/),
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "NotificationSubscription",
    "selections": (v0/*: any*/)
  },
  "params": {
    "id": "4895d3ce9c57a8ad85f7a805f6d6946e",
    "metadata": {},
    "name": "NotificationSubscription",
    "operationKind": "subscription",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'cad06821a3e90ba143f7a294abbc2e60';

module.exports = node;
