import React from "react";
import { QueryRenderer, fetchQuery } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import PropTypes from "prop-types";
import jwt from "jsonwebtoken";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import LoopIcon from "@material-ui/icons/LoopRounded";

import Environment from "./../environment";
import SettingsIcon from "@material-ui/icons/Settings";
import TileData from "./tileData";
import Notifications from "./notifications/Notifications";
import UserSettings from "./userSettings/UserSettings";
import isAuthenticated from "./authentication/isAuthenticated";
import LoadingPage from "./ui/LoadingPage";
import LoadingPageRetry from "./ui/LoadingPageRetry";
import SM from "./subscriptions/index";
import packageInfo from "../../package.json";
import Logo from "../media/logoComponent";
import AudioDevices from "./audio_devices/AudioDevices";
const drawerWidth = 250;

const parentQueryRender = graphql`
  query miniDrawerQuery {
    viewer {
      user {
        id
        profile {
          name
          clientId
          videoClientId
        }
        consoleSettings {
          audioDevices {
            appDetails
            logs
            nowPlaying
            systemDetailsControls
            user
            tags
          }
          videoDevices {
            appDetails
            deviceActions
            systemDetailsControls
            tags
          }
          users {
            details
            actions
            permissions
            logs
          }
        }
      }
      allNotifications {
        edges {
          node {
            id
            type
            message
            dateCreated
            seenBy
          }
        }
      }
      allEvents {
        edges {
          node {
            id
            userId
            clientId
            methods
            tag
            deviceUid
            date
            status
          }
        }
      }
      allAudioDevices {
        edges {
          node {
            id
            deviceStatus
          }
        }
      }
      allMethods {
        edges {
          node {
            id
            displayName
            methodName
            description
            UIElementsID
            roleType
            userType
          }
        }
      }
      ...Notifications_viewer
      ...UserSettings_viewer
      ...AudioDeviceRegistration_viewer

      ...WebkeyDashboard_viewer
      ...WebkeyAppManagement_viewer
      ...WebkeyTasks_viewer

      ...PlaybackTabsQuery_viewer

      ...AudioDevices_viewer

      ...Users_viewer

      ...Tags_viewer
    }
  }
`;

const styles = (theme) => ({
  root: {
    width: "100%",
    height: "100%",
    marginTop: theme.spacing(0),
    zIndex: 1,
    overflow: "hidden",
  },
  appFrame: {
    position: "relative",
    display: "flex",
    width: "100%",
    height: "100%",
  },
  appBar: {
    background: "#030416",
    position: "fixed",
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 12,
  },
  hide: {
    display: "none",
  },
  drawerPaper: {
    position: "relative",
    "min-height": "100%",
    height: "100vh",
    borderColor: "transparent",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    width: 60,
    overflowX: "hidden",
    borderColor: "transparent",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  drawerInner: {
    // Make the items inside not wrap when transitioning:
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  content: {
    width: "200px",
    flexGrow: 1,
    marginRight: "12px",
    marginTop: "24px",
    padding: "12px",
    borderRadius: "12px",
    backgroundColor: theme.palette.background.default,
    height: "calc(100% - 56px)",
    [theme.breakpoints.up("sm")]: {
      height: "calc(100% - 64px)",
      width: `calc(100% - 450px)`,
      borderRadius: "12px",
    },
  },
  media: {
    height: 70,
    width: "auto",
  },
  card: {
    maxWidth: 345,
  },
  typo: {
    fontWeight: 800,
    fontSize: "large",
    paddingRight: 8,
    display: "flex",
    flexDirection: "row-reverse",
  },
  avatar: {
    color: "#fff",
    backgroundColor: "#65c8d0",
  },
  avatarContainer: {
    display: "flex",
    flexDirection: "row-reverse",
    flex: "auto",
  },
  typo2: {
    alignItems: "center",
    fontWeight: 600,
    fontSize: "large",
    paddingRight: 8,
    alignSelf: "center",
  },
  badge: {
    top: 8,
    right: 27,
    // The border color match the background color.
  },
  badgeContainer: {
    alignItems: "center",
  },
  notificationContainer: {
    width: 300,
  },
  userSettingsContainer: {
    width: 300,
  },
  notificationButton: {
    fontSize: 36,
  },
});

class MiniDrawer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      auth: isAuthenticated(),
      open: true,
      userId: localStorage.getItem("user_id"),
      user: jwt.decode(localStorage.getItem("authentication_token")),
      notificationsDrawer: false,
      userSettingsDrawer: false,
      timer: 10,
      timer2: 0,
    };
    this.handleDrawerOpen = this.handleDrawerOpen.bind(this);
    this.handleDrawerClose = this.handleDrawerClose.bind(this);
    this.handleSelection = this.handleSelection.bind(this);
    this.toggleNotifications = this.toggleNotifications.bind(this);
    this.toggleUserSettings = this.toggleUserSettings.bind(this);
  }

  componentDidMount() {
    const loginProcedure = async () => {
      if (SM.token) {
        this.subscription1 = await SM.AudioDeviceActiveSubscription(
          SM.token,
          null,
          [
            "isOnline",
            "appStatus.status",
            "currentPlaylistId",
            "isPlaying",
            "clientId",
            "appVersion",
            "name",
            "tags.*",
            "tags",
            "lastSyncTime",
            "scheduleIds.*",
            "scheduleIds",
            "deviceStatus",
            "kpi1",
            "kpi3",
            "kpi30",
          ]
        );
      }
      this.subscription2 = await SM.ScheduleSubscription(SM.token);
      this.subscription3 = await SM.AudioClientSubscription(SM.token);
      this.subscription4 = await SM.NPLogSubscription(SM.token);
      this.subscription5 = await SM.PCLogSubscription(SM.token);
      this.subscription7 = await SM.PlaybackSubscription(SM.token);
    };
    loginProcedure();
    this.interval = setInterval(() => {
      this.setState({
        timer: this.state.timer - 1,
      });
    }, 1000);
    this.interval2 = setInterval(() => {
      this.setState({
        timer2: this.state.timer2 + 1,
      });
    }, 1000);
  }

  async componentWillUnmount() {
    try {
      clearInterval(this.interval);
      clearInterval(this.interval2);
      await this.subscription1?.dispose();
      await this.subscription2?.dispose();
      await this.subscription3?.dispose();
      await this.subscription4?.dispose();
      await this.subscription5?.dispose();
      await this.subscription7?.dispose();
    } catch (e) {
      console.log("componentWillUnmount miniDrawer Error:", e);
    }
  }

  handleDrawerOpen() {
    this.setState({ open: true });
  }

  handleDrawerClose() {
    this.setState({ open: false });
  }

  handleSelection() {
    this.setState({ open: false });
  }

  toggleNotifications(value) {
    this.setState({ notificationsDrawer: value });
  }

  toggleUserSettings(value) {
    this.setState({ userSettingsDrawer: value });
  }

  render() {
    const { classes, match } = this.props;
    const {
      notificationsDrawer,
      userSettingsDrawer,
      open,
      userId,
      user,
      timer,
      timer2,
    } = this.state;
    if (timer <= 0) {
      clearInterval(this.interval);
    }
    if (timer2 >= 121) {
      clearInterval(this.interval2);
    }

    return (
      <div className={classes.root}>
        <QueryRenderer
          environment={Environment}
          query={parentQueryRender}
          fetchPolicy="store-and-network"
          render={({ error, props }) => {
            if (!props && timer2 >= 120) {
              window.location.reload();
            }
            if (error) {
              if (timer <= 0) {
                window.location.reload();
              }
              return (
                <div className={classes.root}>
                  <div className={classes.appFrame}>
                    <AppBar
                      className={classNames(
                        classes.appBar,
                        false && classes.appBarShift
                      )}
                    >
                      <Toolbar disableGutters={!open}>
                        <Logo style={{ height: 70 }} />
                        <Button variant="outlined" disabled>
                          {packageInfo?.version}
                        </Button>
                      </Toolbar>
                    </AppBar>
                    <LoadingPageRetry timer={timer} />
                  </div>
                </div>
              );
            } else if (props) {
              clearInterval(this.interval2);
              const uregisteredADArray = props.viewer.allAudioDevices.edges
                .filter((item) => item && item.node)
                .filter(
                  (item) =>
                    item.node.deviceStatus === "UNREGISTERED" ||
                    item.node.deviceStatus === "REGISTERED"
                );
              const unregisteredADNo = uregisteredADArray.length || 0;
              const methods = props.viewer.allMethods.edges
                .filter((item) => item && item.node)
                .filter(
                  ({ node }) =>
                    (node.roleType === "access" &&
                      node.userType.includes(user.roleAccess)) ||
                    (node.roleType === "audio" &&
                      node.userType.includes(user.roleAudio)) ||
                    (node.roleType === "video" &&
                      node.userType.includes(user.roleVideo))
                );
              const children = (viewer) =>
                React.cloneElement(this.props.children, {
                  viewer: props.viewer,
                  methods,
                });
              return (
                <div className={classes.appFrame}>
                  <AppBar
                    className={classNames(
                      classes.appBar,
                      open && classes.appBarShift
                    )}
                  ></AppBar>
                  <Drawer
                    variant="permanent"
                    classes={{
                      paper: classNames(
                        classes.drawerPaper,
                        !open && classes.drawerPaperClose
                      ),
                    }}
                    open={open}
                    style={{
                      paddingTop: "24px",
                      marginTop: "24px",
                      marginLeft: "12px",
                      marginRight: "12px",
                      borderRadius: "12px",
                      backgroundColor: "#fff ",
                      overflowX: "hidden",
                      borderColor: "transparent",
                      width: this.state.open ? "250px" : "78px",
                      height: "1000px",
                    }}
                  >
                    <div
                      className={classes.drawerInner}
                      style={{ borderColor: "#fff" }}
                    >
                      <div
                        className={classes.drawerHeader}
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          flexDirection: "column",
                        }}
                      >
                        <Logo style={{ height: 70 }} />
                        <div
                          style={{
                            height: "40px",
                            width: "200px",
                            marginTop: "8px",
                            padding: "6px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "row",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              width: "45px",
                            }}
                          >
                            <p style={{ fontWeight: "bold" }}>
                              {open ? packageInfo?.version : ""}{" "}
                            </p>
                          </div>

                          <div
                            style={{
                              display: "flex",
                              width: "80px",
                              justifyContent: "space-around",
                              marginLeft: "12px",
                            }}
                          >
                            <LoopIcon
                              onClick={() =>
                                fetchQuery(Environment, parentQueryRender)
                              }
                              style={{ cursor: "pointer" }}
                            />
                            <SettingsIcon
                              style={{ cursor: "pointer" }}
                              onClick={() => this.toggleUserSettings(true)}
                            />
                          </div>
                        </div>
                        {this.state.open ? (
                          <ChevronLeftIcon
                            onClick={() => this.setState({ open: false })}
                            style={{ height: "32px", width: "32px" }}
                          />
                        ) : (
                          <ChevronRightIcon
                            onClick={() => this.setState({ open: true })}
                            style={{
                              position: "absolute",
                              left: "30px",
                              top: "60px",
                              height: "32px",
                              width: "32px",
                            }}
                          />
                        )}
                      </div>

                      <TileData
                        open={open}
                        selection={this.handleSelection}
                        userRoot={match.route.userRoot}
                        unregisteredADNo={unregisteredADNo}
                        methods={methods}
                      />
                    </div>
                  </Drawer>
                  <main className={classes.content}>
                    <Drawer
                      anchor="right"
                      open={notificationsDrawer}
                      onClose={() => this.toggleNotifications(false)}
                      className={classes.notificationContainer}
                    >
                      <div>
                        <Notifications viewer={props.viewer} userId={userId} />
                      </div>
                    </Drawer>
                    <Drawer
                      anchor="right"
                      open={userSettingsDrawer}
                      onClose={() => this.toggleUserSettings(false)}
                      className={classes.userSettingsContainer}
                    >
                      <div>
                        <UserSettings viewer={props.viewer} userId={userId} />
                      </div>
                    </Drawer>
                    {children(props.viewer)}
                  </main>
                </div>
              );
            }
            return (
              <div className={classes.root}>
                <div className={classes.appFrame}>
                  <AppBar
                    elevation={0}
                    className={classNames(
                      classes.appBar,
                      false && classes.appBarShift
                    )}
                  >
                    <Toolbar disableGutters={!open}>
                      <Logo style={{ height: 70 }} />
                    </Toolbar>
                  </AppBar>
                  <LoadingPage />
                </div>
              </div>
            );
          }}
        />
      </div>
    );
  }
}

MiniDrawer.defaultProps = {
  children: <AudioDevices />,
};

MiniDrawer.propTypes = {
  match: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  children: PropTypes.element,
};

export default withStyles(styles, { withTheme: true })(MiniDrawer);
