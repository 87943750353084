import React from "react";
import { QueryRenderer } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { withSnackbar } from "material-ui-snackbar-provider";
import compose from "recompose/compose";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";
import ReactTable from "react-table";
import "react-table/react-table.css";
import AddBoxIcon from "@material-ui/icons/AddBox";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import NewCampaign from "./newCampaign";
import DeleteCampaign from "./deleteCampaign";
import EditCampaign from "./editCampaign";
import permissions2 from "../authentication/UIPermissions";

import Environment from "../../environment";

const queryClient = graphql`
  query VideoCampaignsQuery {
    viewer {
      allVideoCampaigns {
        edges {
          node {
            id
            name
            description
            clientId
            goLiveDate
            deviceIds
            profileIds
            onHold
          }
        }
      }
      allTags {
        edges {
          node {
            id
            name
            description
          }
        }
      }
      allVideoDevices {
        edges {
          node {
            id
            name
            tags
            clientId
            format
            currentlyPlaying
          }
        }
      }
      allVideoClients {
        edges {
          node {
            id
            name
          }
        }
      }
      allVideoProfiles {
        edges {
          node {
            id
            name
            format
            clientId
          }
        }
      }
    }
  }
`;

const styles = (theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    margin: "0px 0px 0px 0px",
  },
  typo: {
    padding: 0,
  },
  typoClient: {
    padding: "0px 0px 0px 0px",
    color: "secondary",
  },
  button: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    marginLeft: "9px",
    "&:hover": {
      background: "#e3fd59",
    },
  },
  horizontalContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  horizontalContainer2: {
    display: "flex",
    flexWrap: "wrap",
  },
  paper: {
    margin: "auto",
    width: 700,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: "8px 16px 16px 16px",
  },
  actions: {
    flexWrap: "wrap",
    display: "flex",
  },
});

const openRow = "#DCDCDC";

class VideoCampaigns extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      deviceControlId: "none",
      selectedCampaign: {
        id: "",
        name: "",
        description: "",
        clientId: "",
        goLiveDate: "",
        devicesIds: [],
        profileIds: [],
      },
      selectedRow: "",
      modalType: false,
    };
    this.handleClose = this.handleClose.bind(this);
    this.handleClear = this.handleClear.bind(this);
    this.handleAction = this.handleAction.bind(this);
    this.selectRow = this.selectRow.bind(this);
  }

  shouldComponentUpdate() {
    if (this.props.match.location.pathname === window.location.pathname) {
      return true;
    }
    return false;
  }

  handleAction(data, type) {
    this.setState({
      selectedCampaign: data,
      modalType: type,
    });
  }

  handleClear() {
    this.setState({
      modalType: false,
      selectedCampaign: {
        id: "",
        name: "",
        description: "",
        clientId: "",
        goLiveDate: "",
        devicesIds: [],
        profileIds: [],
      },
    });
  }

  selectRow(userId) {
    this.setState({ selectedRow: userId });
  }

  handleClose() {
    this.setState({ anchorEl: null });
  }

  render() {
    const { classes, methods } = this.props;
    const { selectedCampaign, selectedRow, modalType } = this.state;

    const pageSize = 20;
    return permissions2("5caca4c53630643c2a423823", methods) ? (
      <QueryRenderer
        variables={{}}
        environment={Environment}
        query={queryClient}
        render={({ error, props }) => {
          if (error) {
            console.log(error);
          } else if (props && props.viewer) {
            const fixedColumns = [
              {
                Header: "Name",
                accessor: "name",
                maxWidth: 200,
              },
              {
                Header: "Description",
                accessor: "description",
                maxWidth: 500,
              },
              {
                id: "deviceIds",
                Header: "Video Devices",
                accessor: (a) => a.deviceIds.length,
                maxWidth: 80,
              },
              {
                id: "goLiveDate",
                Header: "Go Live Date",
                accessor: (a) => new Date(a.goLiveDate).toString(),
                maxWidth: 350,
              },
              {
                id: "onHold",
                Header: "On Hold",
                accessor: (a) => (a.onHold ? "Yes" : "No"),
                maxWidth: 80,
              },
              {
                Header: "Actions",
                width: 160,
                filterable: false,
                sortable: false,
                Cell: (row) => (
                  <div className={classes.actions}>
                    <Tooltip
                      id="tooltip-bottom"
                      title="Edit Campaign"
                      placement="bottom"
                      enterTouchDelay={200}
                    >
                      <Button
                        variant="text"
                        size="small"
                        color="primary"
                        className={classes.button}
                        onClick={() => this.handleAction(row.original, "edit")}
                      >
                        <EditIcon />
                      </Button>
                    </Tooltip>
                    <Tooltip
                      id="tooltip-bottom"
                      title="Delete Campaign"
                      placement="bottom"
                      enterTouchDelay={200}
                    >
                      <Button
                        variant="text"
                        size="small"
                        color="primary"
                        className={classes.button}
                        onClick={() =>
                          this.handleAction(row.original, "delete")
                        }
                      >
                        <DeleteIcon />
                      </Button>
                    </Tooltip>
                  </div>
                ),
              },
            ];

            const fullData = props.viewer.allVideoCampaigns.edges
              .filter((item) => item && item.node)
              .map(({ node }) => node);
            return (
              <>
                <div className={classes.root}>
                  <h2 style={{ marginLeft: "12px", paddingTop: "12px" }}>
                    {this.props.match.route.name}
                  </h2>
                  <div className={classes.horizontalContainer}>
                    <Tooltip
                      id="tooltip-bottom"
                      title="Add tag"
                      placement="bottom"
                      enterTouchDelay={200}
                    >
                      <Button
                        variant="text"
                        color="primary"
                        size="small"
                        aria-label="duplicate"
                        className={classes.button}
                        onClick={() =>
                          this.handleAction(selectedCampaign, "new")
                        }
                      >
                        <AddBoxIcon />
                      </Button>
                    </Tooltip>
                  </div>
                  <div className={classes.horizontalContainer2}>
                    <ReactTable
                      data={fullData}
                      columns={fixedColumns}
                      defaultPageSize={pageSize}
                      showPagination={fullData.length > pageSize}
                      filterable
                      defaultFilterMethod={(filter, row) =>
                        String(row[filter.id])
                          .toLowerCase()
                          .includes(filter.value.toLowerCase())
                      }
                      className="-striped -highlight"
                      style={{
                        display: "block",
                        margin: "8px 0px 8px 8px",
                        fontSize: "13px",
                        flex: "auto",
                        minWidth: "35vw",
                      }}
                      getTrProps={(state, rowInfo) => {
                        const selectedId = rowInfo ? rowInfo.original.id : null;
                        return {
                          onClick: () => this.selectRow(rowInfo.original.id),
                          style: {
                            background:
                              selectedId === selectedRow ? openRow : "#FFFFFF",
                          },
                        };
                      }}
                    />
                  </div>
                  <Typography
                    variant="caption"
                    color="secondary"
                    className={classes.typo}
                    style={{ paddingLeft: 8 }}
                    align="left"
                  >
                    Tip: Hold shift when sorting to multi-sort!
                  </Typography>
                  {modalType === "new" && (
                    <Modal
                      aria-labelledby="new-modal"
                      aria-describedby="new-modal"
                      open={!!modalType}
                    >
                      <div className={classes.paper}>
                        <NewCampaign
                          viewer={props.viewer}
                          selectedCampaign={selectedCampaign}
                          handleClose={this.handleClear}
                        />
                      </div>
                    </Modal>
                  )}
                  {modalType === "delete" && (
                    <Modal
                      aria-labelledby="delete-modal"
                      aria-describedby="delete-modal"
                      open={!!modalType}
                      onClose={this.handleClear}
                    >
                      <div className={classes.paper}>
                        <DeleteCampaign
                          selectedCampaign={selectedCampaign}
                          handleClose={this.handleClear}
                        />
                      </div>
                    </Modal>
                  )}
                  {modalType === "edit" && (
                    <Modal
                      aria-labelledby="edit-modal"
                      aria-describedby="edit-modal"
                      open={!!modalType}
                      onClose={this.handleClear}
                    >
                      <div className={classes.paper}>
                        <EditCampaign
                          viewer={props.viewer}
                          selectedCampaign={selectedCampaign}
                          handleClose={this.handleClear}
                        />
                      </div>
                    </Modal>
                  )}
                </div>
              </>
            );
          }
        }}
      />
    ) : null;
  }
}

VideoCampaigns.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(withStyles(styles), withSnackbar())(VideoCampaigns);
