/**
 * @flow
 * @relayHash 2b63e491d22c6b963af9b42108ed7a59
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type requestBlobMutationVariables = {|
  size: number
|};
export type requestBlobMutationResponse = {|
  +requestBlob: ?{|
    +id: string
  |}
|};
export type requestBlobMutation = {|
  variables: requestBlobMutationVariables,
  response: requestBlobMutationResponse,
|};
*/


/*
mutation requestBlobMutation(
  $size: Int!
) {
  requestBlob(size: $size) {
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "size"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "size",
        "variableName": "size"
      }
    ],
    "concreteType": "DsBlob",
    "kind": "LinkedField",
    "name": "requestBlob",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "requestBlobMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "requestBlobMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "2b63e491d22c6b963af9b42108ed7a59",
    "metadata": {},
    "name": "requestBlobMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '6e0d60762b0de422c038545206881b63';

module.exports = node;
