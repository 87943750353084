/**
 * @flow
 * @relayHash 71d7fde4e4f63397ccfb3b39a489f3f4
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type extendPreviewTokenMutationVariables = {|
  clientId: string
|};
export type extendPreviewTokenMutationResponse = {|
  +extendPreviewToken: {|
    +id: string,
    +name: ?string,
    +playlistIds: ?$ReadOnlyArray<?string>,
    +playbackIds: ?$ReadOnlyArray<?string>,
    +sceneIds: ?$ReadOnlyArray<?string>,
    +featuredIds: ?$ReadOnlyArray<?string>,
    +logo: ?string,
    +preview: ?{|
      +previewId: ?string,
      +created: ?any,
      +ending: ?any,
      +isEnded: ?boolean,
      +isEndless: ?boolean,
    |},
  |}
|};
export type extendPreviewTokenMutation = {|
  variables: extendPreviewTokenMutationVariables,
  response: extendPreviewTokenMutationResponse,
|};
*/


/*
mutation extendPreviewTokenMutation(
  $clientId: String!
) {
  extendPreviewToken(clientId: $clientId) {
    id
    name
    playlistIds
    playbackIds
    sceneIds
    featuredIds
    logo
    preview {
      previewId
      created
      ending
      isEnded
      isEndless
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "clientId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "clientId",
        "variableName": "clientId"
      }
    ],
    "concreteType": "AudioClient",
    "kind": "LinkedField",
    "name": "extendPreviewToken",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "playlistIds",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "playbackIds",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "sceneIds",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "featuredIds",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "logo",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Preview",
        "kind": "LinkedField",
        "name": "preview",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "previewId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "created",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "ending",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isEnded",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isEndless",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "extendPreviewTokenMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "extendPreviewTokenMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "71d7fde4e4f63397ccfb3b39a489f3f4",
    "metadata": {},
    "name": "extendPreviewTokenMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '99dd9972ccee8071ee6dfce85e2fadc4';

module.exports = node;
