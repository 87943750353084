import React from "react";
import Logo from "../../media/logoComponent";

function LoginLogo({ children }) {
  return (
    <>
      <Logo style={{ height: 70 }} />
      {children}
    </>
  );
}

export default LoginLogo;
