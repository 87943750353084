import { commitMutation } from "react-relay";
import graphql from 'babel-plugin-relay/macro';
import Environment from "../../environment";

const mutation = graphql`
  mutation updateOfflineLimitsMutation(
    $deviceId: String!
    $maximumDaysOffline: Int
    $maximumSongsOffline: Int
  ) {
    updateOfflineLimits(
      deviceId: $deviceId
      maximumDaysOffline: $maximumDaysOffline
      maximumSongsOffline: $maximumSongsOffline
    ) {
      id
      maximumDaysOffline
      maximumSongsOffline
    }
  }
`;

export default (
  deviceId,
  maximumDaysOffline,
  maximumSongsOffline,
  callback
) => {
  const variables = {
    deviceId,
    maximumDaysOffline,
    maximumSongsOffline,
  };

  commitMutation(Environment, {
    mutation,
    variables,
    onCompleted: () => callback(),
    onError: (err) => console.error(err), // eslint-disable-line
  });
};
