import { commitMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import Environment from "../../environment";

const mutation = graphql`
  mutation crossfadeUpdateMutation($deviceId: String!, $value: Int!) {
    crossfadeUpdate(deviceId: $deviceId, value: $value) {
      id
      crossfade
    }
  }
`;

export default (deviceId, value, callback) => {
  const variables = {
    deviceId,
    value,
  };

  commitMutation(Environment, {
    mutation,
    variables,
    onCompleted: () => callback(),
    onError: (err) => console.error(err), // eslint-disable-line
  });
};
