import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import compose from "recompose/compose";
import { withSnackbar } from "material-ui-snackbar-provider";
import TextField from "@material-ui/core/TextField";
import Input from "@material-ui/core/Input";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import FingerprintIcon from "@material-ui/icons/Fingerprint";
import { Link, withRouter } from "found";

import { GoogleLogin } from "react-google-login";
import { FacebookProvider, LoginButton } from "react-facebook";
import crypto from "crypto-browserify";

import Validator from "validator";
import InlineError from "../ui/inlineError";
import signinUserMutation from "../mutations/signinUserMutation";
import handleGoogleMutation from "../mutations/handleGoogleMutation";
import handleFacebookMutation from "../mutations/handleFacebookMutation";
import config from "../../config";
import LoginLogo from "./LoginLogo";

const paperWidth = 270;

const styles = (theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    width: paperWidth,
  },
  root: theme.mixins.gutters({
    position: "relative",
    margin: "auto",
    marginTop: 100,
    width: paperWidth,
    paddingTop: 16,
    paddingBottom: 16,
    background: theme.palette.background.paper,
  }),
  root2: theme.mixins.gutters({
    position: "relative",
    margin: "auto",
    marginTop: 10,
    width: paperWidth,
    paddingTop: 16,
    paddingBottom: 16,
    background: theme.palette.background.paper,
  }),
  button: {
    "&:hover": {
      background: "linear-gradient(45deg, #387f86 30%, #00bce4 90%)",
    },
    width: paperWidth,
    marginTop: 16,
    marginBottom: 25,
  },
  buttonGoogle: {
    borderRadius: 5,
    background: "#4285f4",
    "&:hover": {
      background: "linear-gradient(45deg, #387f86 30%, #00bce4 90%)",
    },
    color: "white",
    width: paperWidth,
    height: 45,
    margin: 4,
    boxShadow:
      "0px 2px 2px 1px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)",
    borderWidth: 0,
  },
  buttonFacebook: {
    borderRadius: 5,
    background: "#3b5998",
    "&:hover": {
      background: "linear-gradient(45deg, #387f86 30%, #00bce4 90%)",
    },
    color: "white",
    width: paperWidth,
    height: 45,
    margin: 4,
    boxShadow:
      "0px 2px 2px 1px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)",
    borderWidth: 0,
  },
  typography: {
    width: paperWidth,
  },
});

class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submit: false,
      data: {
        email: "",
        password: "",
      },
      errors: {},
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmitGoogle = this.handleSubmitGoogle.bind(this);
    this.handleSubmitFacebook = this.handleSubmitFacebook.bind(this);
    this.handleError = this.handleError.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
  }

  // When login component mounts all localstorage will be deleted
  componentDidMount() {
    localStorage.clear();
  }

  handleLogin(id, token, audioClientId, videoClientId) {
    localStorage.setItem("audioClientId", audioClientId);
    localStorage.setItem("authentication_token", token);
    localStorage.setItem("user_id", id);
    localStorage.setItem("videoClientId", videoClientId);
    this.setState({ submit: true });
    this.props.snackbar.showMessage("Login is going great, please wait!");
    this.props.router.replace("/home/audio_devices");
  }

  handleSubmit(e) {
    e.preventDefault();
    const { email, password } = this.state.data;
    const errors = this.validate(this.state.data);
    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      signinUserMutation(
        email.toLowerCase(),
        crypto.createHash("sha256").update(password).digest("hex"),
        (id, token, audioClientId, videoClientId) => {
          if (id && token && audioClientId) {
            this.handleLogin(id, token, audioClientId, videoClientId);
          } else {
            this.props.snackbar.showMessage(
              "Login failed, incorrect username or password"
            );
          }
        }
      );
    }
  }

  handleSubmitGoogle(response) {
    if (response) {
      const name = (response.profileObj && response.profileObj.name) || "N/A";
      const email = (response.profileObj && response.profileObj.email) || "N/A";
      const googleData = {
        accessToken: response.accessToken,
        idToken: response.tokenId,
        id: response.googleId,
        email,
        verified_email: response.session_state,
        name,
        given_name:
          (response.Qt && response.Qt.vW) ||
          (response.w3 && response.w3.givenName),
        family_name:
          (response.Qt && response.Qt.wU) ||
          (response.w3 && response.w3.familyName),
        picture: response.profileObj && response.profileObj.imageUrl,
      };
      handleGoogleMutation(
        name,
        email,
        googleData,
        (id, token, audioClientId, videoClientId) => {
          if (id && token && audioClientId) {
            this.handleLogin(id, token, audioClientId, videoClientId);
          } else {
            this.props.snackbar.showMessage(
              "Login failed, if your credentials are ok, please contact admin."
            );
          }
        }
      );
    }
  }

  handleSubmitFacebook(data) {
    const name = data.profile.name;
    const email = data.profile.email;
    const facebookData = {
      accessToken: data.tokenDetail.accessToken,
      expiresAt: data.profile.expiresAt,
      id: data.profile.id,
      email: data.profile.email,
      name: data.profile.name,
      first_name: data.profile.first_name,
      last_name: data.profile.last_name,
      link: data.profile.link,
      gender: data.profile.gender,
      locale: data.profile.locale,
      verified: data.profile.verified,
    };
    handleFacebookMutation(
      name,
      email,
      facebookData,
      (id, token, audioClientId, videoClientId) => {
        if (id && token && audioClientId) {
          this.handleLogin(id, token, audioClientId, videoClientId);
        } else {
          this.props.snackbar.showMessage(
            "Login failed, if your credentials are ok, please contact admin."
          );
        }
      }
    );
  }

  validate() {
    const errors = {};
    if (!Validator.isEmail(this.state.data.email))
      errors.email = "*invalid e-mail address";
    if (
      !Validator.isLength(this.state.data.password, { min: 8, max: undefined })
    )
      errors.password = "*please use minimum 8 characters";
    return errors;
  }

  handleChange(e) {
    e.preventDefault();
    this.setState({
      data: { ...this.state.data, [e.target.id]: e.target.value },
    });
  }

  handleError(error) {
    console.log(error);
  }

  render() {
    const { classes } = this.props;

    return (
      <LoginLogo>
        <div>
          <Paper className={classes.root} elevation={10}>
            <form>
              <Typography variant="h5">Login</Typography>
              <TextField
                id="email"
                label="Email"
                className={classes.textField}
                value={this.state.data.email}
                onChange={this.handleChange}
                margin="normal"
              />
              <Typography variant="caption" color="error">
                {this.state.errors.email && (
                  <InlineError text={this.state.errors.email} />
                )}
              </Typography>
              <TextField
                id="password"
                label="Password"
                className={classes.textField}
                type="password"
                autoComplete="current-password"
                value={this.state.data.password}
                onChange={this.handleChange}
                margin="normal"
              />
              <Typography variant="caption" color="error">
                {this.state.errors.password && (
                  <InlineError text={this.state.errors.password} />
                )}
              </Typography>
              <Button
                className={classes.button}
                variant="contained"
                color="primary"
                onClick={this.handleSubmit}
              >
                <Input
                  type="submit"
                  onSubmit={this.handleSubmit}
                  value="SUBMIT"
                  disableUnderline
                  style={{ fontSize: "large" }}
                />
                <FingerprintIcon />
              </Button>
              <Typography variant="body2" color="primary" gutterBottom>
                <Link to="/register">Register here!</Link>
                <br />
                <Link to="/whatismypass">Forgot password?</Link>
              </Typography>
            </form>
          </Paper>
          <Paper className={classes.root2} elevation={10}>
            <GoogleLogin
              clientId={config.GOOGLE_CLIENT_ID}
              onSuccess={this.handleSubmitGoogle}
              onFailure={(e) => console.log("Google Login Failure", e)}
              buttonText=""
              tag="div"
              style={{}}
              icon={true}
              render={(renderProps) => (
                <button
                  onClick={renderProps.onClick}
                  className={classes.buttonGoogle}
                >
                  <Typography variant="body1" style={{ color: "white" }}>
                    Login with Google
                  </Typography>
                </button>
              )}
            />

            <FacebookProvider appId="1818197905075490">
              <LoginButton
                scope="email"
                onCompleted={this.handleSubmitFacebook}
                onError={this.handleError}
                className={classes.buttonFacebook}
              >
                <Typography variant="body1" style={{ color: "white" }}>
                  Login with Facebook
                </Typography>
              </LoginButton>
            </FacebookProvider>
          </Paper>
        </div>
      </LoginLogo>
    );
  }
}

LoginForm.propTypes = {
  classes: PropTypes.object.isRequired,
  snackbar: PropTypes.object,
  router: PropTypes.object,
};

export default compose(
  withRouter,
  withStyles(styles),
  withSnackbar()
)(LoginForm);
