/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type ServerInstance = "backup" | "primary" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type WebkeyTasks_viewer$ref: FragmentReference;
declare export opaque type WebkeyTasks_viewer$fragmentType: WebkeyTasks_viewer$ref;
export type WebkeyTasks_viewer = {|
  +allWebkeyDevices: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: {|
        +id: string,
        +DisplayName: string,
        +Serial: string,
        +DeviceNick: ?string,
        +DeviceBrand: ?string,
        +DeviceModel: ?string,
        +WebkeyVersion: ?string,
        +Location: ?string,
        +LastConnected: ?number,
        +AndroidAPI: ?number,
        +AndroidVersion: ?string,
        +Rooted: ?boolean,
        +Online: ?boolean,
      |}
    |}>
  |},
  +allWebkeyTasks: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: {|
        +id: string,
        +instance: ?ServerInstance,
        +userId: ?string,
        +clientId: ?string,
        +packageId: ?string,
        +type: ?string,
        +reports: ?$ReadOnlyArray<?{|
          +deviceid: ?string,
          +state: ?string,
        |}>,
        +date: ?any,
      |}
    |}>
  |},
  +allAudioDevices: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: {|
        +id: string,
        +name: ?string,
        +webkeyId: ?string,
        +webkeyBackupId: ?string,
      |}
    |}>
  |},
  +allVideoDevices: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: {|
        +id: string,
        +name: ?string,
        +webkeyId: ?string,
        +webkeyBackupId: ?string,
      |}
    |}>
  |},
  +allWebkeyPackages: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: {|
        +id: string,
        +appName: string,
        +keyName: string,
        +source: string,
        +packageName: ?string,
        +description: ?string,
        +versionCode: ?number,
        +versionName: ?string,
        +icon: ?string,
      |}
    |}>
  |},
  +allWebkeyCustomActions: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: {|
        +id: string,
        +name: string,
        +actionCode: string,
      |}
    |}>
  |},
  +allUsers: {|
    +edges: ?$ReadOnlyArray<?{|
      +node: {|
        +id: string,
        +emails: ?$ReadOnlyArray<?{|
          +address: ?string,
          +verified: ?boolean,
        |}>,
        +profile: ?{|
          +name: ?string,
          +clientId: ?string,
          +videoClientId: ?string,
        |},
      |}
    |}>
  |},
  +$refType: WebkeyTasks_viewer$ref,
|};
export type WebkeyTasks_viewer$data = WebkeyTasks_viewer;
export type WebkeyTasks_viewer$key = {
  +$data?: WebkeyTasks_viewer$data,
  +$fragmentRefs: WebkeyTasks_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "clientId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  (v0/*: any*/),
  (v2/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "webkeyId",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "webkeyBackupId",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "WebkeyTasks_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "WebkeyDeviceConnection",
      "kind": "LinkedField",
      "name": "allWebkeyDevices",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "WebkeyDeviceEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "WebkeyDevice",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "DisplayName",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "Serial",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "DeviceNick",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "DeviceBrand",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "DeviceModel",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "WebkeyVersion",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "Location",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "LastConnected",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "AndroidAPI",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "AndroidVersion",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "Rooted",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "Online",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "WebkeyTaskConnection",
      "kind": "LinkedField",
      "name": "allWebkeyTasks",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "WebkeyTaskEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "WebkeyTask",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "instance",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "userId",
                  "storageKey": null
                },
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "packageId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "type",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "WebkeyReport",
                  "kind": "LinkedField",
                  "name": "reports",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "deviceid",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "state",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "date",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AudioDeviceConnection",
      "kind": "LinkedField",
      "name": "allAudioDevices",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AudioDeviceEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "AudioDevice",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": (v3/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "VideoDeviceConnection",
      "kind": "LinkedField",
      "name": "allVideoDevices",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "VideoDeviceEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "VideoDevice",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": (v3/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "WebkeyPackageConnection",
      "kind": "LinkedField",
      "name": "allWebkeyPackages",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "WebkeyPackageEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "WebkeyPackage",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "appName",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "keyName",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "source",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "packageName",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "description",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "versionCode",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "versionName",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "icon",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "WebkeyCustomActionsConnection",
      "kind": "LinkedField",
      "name": "allWebkeyCustomActions",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "WebkeyCustomActionsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "WebkeyCustomAction",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "actionCode",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserConnection",
      "kind": "LinkedField",
      "name": "allUsers",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "UserEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "User",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "UserEmails",
                  "kind": "LinkedField",
                  "name": "emails",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "address",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "verified",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "UserProfile",
                  "kind": "LinkedField",
                  "name": "profile",
                  "plural": false,
                  "selections": [
                    (v2/*: any*/),
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "videoClientId",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = '4000275301f57355611197e89b46c5f9';

module.exports = node;
