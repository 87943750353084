import { commitMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import Environment from "../../environment";

const mutation = graphql`
  mutation schemaQsysReorderMutation(
    $menuId: String!
    $sourceIndex: Int!
    $destinationIndex: Int!
  ) {
    schemaQsysReorder(
      menuId: $menuId
      sourceIndex: $sourceIndex
      destinationIndex: $destinationIndex
    ) {
      __typename
      id
      name
      description
      schema {
        name
        pathId
        parentId
        coverName
        cover_40
        cover_290
        cover_legacy
        playlistIds
        sceneIds
        autoPopulateType
        category
        subCategory
        genres
        routeItems
      }
      active
      dateCreated
      dateModified
      clientId
    }
  }
`;

export default (menuId, sourceIndex, destinationIndex, callback) => {
  const variables = {
    menuId,
    sourceIndex,
    destinationIndex,
  };

  commitMutation(Environment, {
    mutation,
    variables,
    // optimisticUpdater: (store) => {
    //   // Get the record for the Feedback object
    //   const menuRecord = store.get(menuId);
    //
    //   // Read the current value for the like_count
    //   const currentSchema = menuRecord.getLinkedRecords("schema");
    //   const editableSchema = [...currentSchema];
    //
    //   const arrayMove = (arr, fromIndex, toIndex) => {
    //     const element = arr[fromIndex];
    //     arr.splice(fromIndex, 1);
    //     arr.splice(toIndex, 0, element);
    //   };
    //   if (sourceIndex < editableSchema.length) {
    //     arrayMove(editableSchema, sourceIndex, destinationIndex);
    //   }
    //
    //   menuRecord.setLinkedRecords(editableSchema, "schema");
    // },
    onCompleted: (response) => {
      callback();
    },
    onError: (err) => console.error(err), // eslint-disable-line
  });
};
