import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import compose from "recompose/compose";
import { withSnackbar } from "material-ui-snackbar-provider";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import updateVideoDeviceFormatMutation from "../../mutations/updateVideoDeviceFormatMutation";

const styles = {
  horizontalContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  button: {
    margin: "8px 8px 8px 8px",
    maxWidth: 140,
  },
};

class NewFormat extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newFormat: "",
      extraConfirmation: false,
    };
    this.handleAddFormat = this.handleAddFormat.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleConfirmation = this.handleConfirmation.bind(this);
  }

  handleChange(e) {
    this.setState({ [e.target.id]: e.target.value });
  }

  handleAddFormat() {
    this.setState({ extraConfirmation: !this.state.extraConfirmation });
  }

  handleConfirmation() {
    updateVideoDeviceFormatMutation(
      this.props.deviceId,
      this.state.newFormat.toUpperCase(),
      () => this.props.snackbar.showMessage("New format in place")
    );
  }

  render() {
    const { classes, deviceName } = this.props;
    const { newFormat, extraConfirmation } = this.state;
    return (
      <div>
        <Typography variant="subtitle1">
          Insert new video format for this device:
        </Typography>
        <Typography variant="h6">{deviceName}</Typography>
        <Divider />
        <TextField
          id="newFormat"
          label="New Format"
          className={classes.textField}
          value={newFormat}
          onChange={this.handleChange}
          margin="normal"
        />
        <Divider />
        <div className={classes.horizontalContainer}>
          {!extraConfirmation && newFormat !== "" && (
            <Button
              variant="contained"
              color="primary"
              onClick={this.handleAddFormat}
              className={classes.button}
            >
              Add Format
            </Button>
          )}
          {!extraConfirmation && (
            <Button
              variant="contained"
              color="secondary"
              onClick={this.props.handleClose}
              className={classes.button}
            >
              Cancel
            </Button>
          )}
          {extraConfirmation && (
            <Button
              variant="contained"
              color="secondary"
              onClick={this.handleAddFormat}
              className={classes.button}
            >
              No
            </Button>
          )}
          {extraConfirmation && (
            <Typography
              align="center"
              variant="subtitle1"
              style={{ alignSelf: "center" }}
            >
              This action will stop the video rendering on screen:
            </Typography>
          )}
          {extraConfirmation && (
            <Button
              variant="contained"
              color="primary"
              onClick={this.handleConfirmation}
              className={classes.button}
            >
              Yes, Sure
            </Button>
          )}
        </div>
      </div>
    );
  }
}

NewFormat.propTypes = {
  classes: PropTypes.object.isRequired, //eslint-disable-line
  deviceName: PropTypes.string.isRequired,
  deviceId: PropTypes.string.isRequired,
  snackbar: PropTypes.object, //eslint-disable-line
  handleClose: PropTypes.func, //eslint-disable-line
};

export default compose(withStyles(styles), withSnackbar())(NewFormat);
