/**
 * @flow
 * @relayHash 9d9ffe3d712f121c8a8462a77d1e7c92
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type _AutoPopulateTypes = "CATEGORY" | "GENRE" | "NONE" | "SUBCATEGORY" | "%future added value";
export type toggleRouteCategoryMutationVariables = {|
  menuId: string,
  pathId: number,
  type: string,
  category: string,
  subCategory?: ?string,
  routeItems?: ?number,
|};
export type toggleRouteCategoryMutationResponse = {|
  +toggleRouteCategory: ?{|
    +__typename: string,
    +id: string,
    +name: ?string,
    +description: ?string,
    +schema: ?$ReadOnlyArray<?{|
      +name: string,
      +pathId: number,
      +parentId: number,
      +coverName: ?string,
      +cover_40: ?string,
      +cover_290: ?string,
      +cover_legacy: ?string,
      +playlistIds: ?$ReadOnlyArray<?string>,
      +sceneIds: ?$ReadOnlyArray<?string>,
      +mixesArray: ?$ReadOnlyArray<?{|
        +mixId: string,
        +type: string,
      |}>,
      +autoPopulateType: ?_AutoPopulateTypes,
      +category: ?string,
      +subCategory: ?string,
      +genres: ?$ReadOnlyArray<?string>,
      +routeItems: ?number,
    |}>,
    +active: ?boolean,
    +dateCreated: ?any,
    +dateModified: ?any,
    +clientId: ?string,
  |}
|};
export type toggleRouteCategoryMutation = {|
  variables: toggleRouteCategoryMutationVariables,
  response: toggleRouteCategoryMutationResponse,
|};
*/


/*
mutation toggleRouteCategoryMutation(
  $menuId: String!
  $pathId: Int!
  $type: String!
  $category: String!
  $subCategory: String
  $routeItems: Int
) {
  toggleRouteCategory(menuId: $menuId, pathId: $pathId, type: $type, category: $category, subCategory: $subCategory, routeItems: $routeItems) {
    __typename
    id
    name
    description
    schema {
      name
      pathId
      parentId
      coverName
      cover_40
      cover_290
      cover_legacy
      playlistIds
      sceneIds
      mixesArray {
        mixId
        type
      }
      autoPopulateType
      category
      subCategory
      genres
      routeItems
    }
    active
    dateCreated
    dateModified
    clientId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "category"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "menuId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "pathId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "routeItems"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "subCategory"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "type"
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v7 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "category",
        "variableName": "category"
      },
      {
        "kind": "Variable",
        "name": "menuId",
        "variableName": "menuId"
      },
      {
        "kind": "Variable",
        "name": "pathId",
        "variableName": "pathId"
      },
      {
        "kind": "Variable",
        "name": "routeItems",
        "variableName": "routeItems"
      },
      {
        "kind": "Variable",
        "name": "subCategory",
        "variableName": "subCategory"
      },
      {
        "kind": "Variable",
        "name": "type",
        "variableName": "type"
      }
    ],
    "concreteType": "SonosMenu",
    "kind": "LinkedField",
    "name": "toggleRouteCategory",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      (v6/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "description",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Route",
        "kind": "LinkedField",
        "name": "schema",
        "plural": true,
        "selections": [
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "pathId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "parentId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "coverName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "cover_40",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "cover_290",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "cover_legacy",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "playlistIds",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "sceneIds",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Mix",
            "kind": "LinkedField",
            "name": "mixesArray",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "mixId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "type",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "autoPopulateType",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "category",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "subCategory",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "genres",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "routeItems",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "active",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "dateCreated",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "dateModified",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "toggleRouteCategoryMutation",
    "selections": (v7/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v5/*: any*/),
      (v0/*: any*/),
      (v4/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "toggleRouteCategoryMutation",
    "selections": (v7/*: any*/)
  },
  "params": {
    "id": "9d9ffe3d712f121c8a8462a77d1e7c92",
    "metadata": {},
    "name": "toggleRouteCategoryMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '39858a4e74e832222f1d106bd13186a1';

module.exports = node;
