/**
 * @flow
 * @relayHash 41e4dee93ca2bad4eba8a7f783a288f0
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type InputConsoleSettings = {|
  audioDevices?: ?InputAudioDeviceSettings,
  videoDevices?: ?InputVideoDeviceSettings,
  users?: ?InputUsersSettings,
|};
export type InputAudioDeviceSettings = {|
  appDetails?: ?boolean,
  logs?: ?boolean,
  nowPlaying?: ?boolean,
  systemDetailsControls?: ?boolean,
  user?: ?boolean,
  tags?: ?boolean,
|};
export type InputVideoDeviceSettings = {|
  appDetails?: ?boolean,
  deviceActions?: ?boolean,
  systemDetailsControls?: ?boolean,
  tags?: ?boolean,
|};
export type InputUsersSettings = {|
  details?: ?boolean,
  actions?: ?boolean,
  permissions?: ?boolean,
  logs?: ?boolean,
|};
export type editUserSettingsMutationVariables = {|
  consoleSettings?: ?InputConsoleSettings
|};
export type editUserSettingsMutationResponse = {|
  +editUserSettings: {|
    +id: string,
    +profile: ?{|
      +name: ?string
    |},
    +consoleSettings: ?{|
      +audioDevices: ?{|
        +appDetails: ?boolean,
        +logs: ?boolean,
        +nowPlaying: ?boolean,
        +systemDetailsControls: ?boolean,
        +user: ?boolean,
        +tags: ?boolean,
      |},
      +videoDevices: ?{|
        +appDetails: ?boolean,
        +deviceActions: ?boolean,
        +systemDetailsControls: ?boolean,
        +tags: ?boolean,
      |},
      +users: ?{|
        +details: ?boolean,
        +actions: ?boolean,
        +permissions: ?boolean,
        +logs: ?boolean,
      |},
    |},
  |}
|};
export type editUserSettingsMutation = {|
  variables: editUserSettingsMutationVariables,
  response: editUserSettingsMutationResponse,
|};
*/


/*
mutation editUserSettingsMutation(
  $consoleSettings: InputConsoleSettings
) {
  editUserSettings(consoleSettings: $consoleSettings) {
    id
    profile {
      name
    }
    consoleSettings {
      audioDevices {
        appDetails
        logs
        nowPlaying
        systemDetailsControls
        user
        tags
      }
      videoDevices {
        appDetails
        deviceActions
        systemDetailsControls
        tags
      }
      users {
        details
        actions
        permissions
        logs
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "consoleSettings"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "appDetails",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "logs",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "systemDetailsControls",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tags",
  "storageKey": null
},
v5 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "consoleSettings",
        "variableName": "consoleSettings"
      }
    ],
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "editUserSettings",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UserProfile",
        "kind": "LinkedField",
        "name": "profile",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ConsoleSettings",
        "kind": "LinkedField",
        "name": "consoleSettings",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AudioDeviceSettings",
            "kind": "LinkedField",
            "name": "audioDevices",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "nowPlaying",
                "storageKey": null
              },
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "user",
                "storageKey": null
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "VideoDeviceSettings",
            "kind": "LinkedField",
            "name": "videoDevices",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "deviceActions",
                "storageKey": null
              },
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UsersSettings",
            "kind": "LinkedField",
            "name": "users",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "details",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "actions",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "permissions",
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "editUserSettingsMutation",
    "selections": (v5/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "editUserSettingsMutation",
    "selections": (v5/*: any*/)
  },
  "params": {
    "id": "41e4dee93ca2bad4eba8a7f783a288f0",
    "metadata": {},
    "name": "editUserSettingsMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '36afc068d21db9b9b615321c82cac12e';

module.exports = node;
