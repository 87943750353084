import { commitMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";

import Environment from "../../environment";

const mutation = graphql`
  mutation deleteAudioDeviceMutation($id: String!) {
    deleteAudioDevice(id: $id) {
      id
    }
  }
`;

export default (id, callback) => {
  const variables = {
    id,
  };

  const configs = [
    {
      type: "NODE_DELETE",
      deletedIDFieldName: "id",
    },
  ];

  commitMutation(Environment, {
    mutation,
    variables,
    configs,
    onCompleted: (response) => {
      console.log(response);
      if (response) {
        callback(response);
      }
    },
    onError: (err) => console.error(err), // eslint-disable-line
  });
};
