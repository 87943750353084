import React from "react";
import { QueryRenderer } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { withSnackbar } from "material-ui-snackbar-provider";
import compose from "recompose/compose";
import _ from "lodash";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Modal from "@material-ui/core/Modal";
import Tooltip from "@material-ui/core/Tooltip";
import Edit from "@material-ui/icons/Edit";
import PhonelinkSetup from "@material-ui/icons/PhonelinkSetup";
import Delete from "@material-ui/icons/Delete";
import ReactTable from "react-table";
import "react-table/react-table.css";
import Loupe from "@material-ui/icons/Loupe";

import VideoClients from "./VideoClients";
import EditProfile from "./profileForms/editProfile";
import NewProfile from "./profileForms/newProfile";
import DeleteProfile from "./profileForms/deleteProfile";
import PushProfile from "./profileForms/pushProfile";
import NewVideoClient from "./profileForms/newVideoClient";
import EditVideoClient from "./profileForms/editVideoClient";
import DeleteVideoClient from "./profileForms/deleteVideoClient";
import permissions from "../authentication/isPermited";
import permissions2 from "../authentication/UIPermissions";

import Environment from "../../environment";

const queryClient = graphql`
  query VideoProfilesQuery {
    viewer {
      allVideoClients {
        edges {
          node {
            id
            name
          }
        }
      }
      allVideoDevices {
        edges {
          node {
            id
            name
            uid
            description
            macAddress
            currentlyPlaying
            volume
            lastSync
            lanMacAddress
            internalIp
            publicIp
            lastOnline
            appVersion
            reboot
            takeScreenShot
            sync
            format
            screenShot
            uploadLogs
            model
            serialNumber
            usedStorage
            totalStorage
            availableStorage
            hdmiStatus
            downloading
            appUpTime
            isPlaying
            clientId
            profile {
              profileId
              name
              format
              volume
              url
              syncTime
              rebootTime
              clientId
            }
          }
        }
      }
      allVideoProfiles {
        edges {
          node {
            id
            name
            format
            volume
            url
            syncTime
            rebootTime
            clientId
          }
        }
      }
    }
  }
`;

const styles = (theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    margin: "0px 0px 0px 0px",
  },
  content: {
    flex: "0 1 auto",
  },
  typo: {
    padding: 0,
  },
  typoClient: {
    padding: "0px 0px 0px 0px",
    color: "secondary",
  },
  button: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    border: "1px solid #e3fd59",
    background: "#ECEFF1",
    marginLeft: "9px",
    "&:hover": {
      background: "#e3fd59",
    },
    minWidth: 150,
    margin: 5,
    marginBottom: 9,
  },
  horizontalContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  horizontalContainer2: {
    display: "flex",
    flexWrap: "wrap",
  },
  actions: {
    flexWrap: "wrap",
    display: "flex",
  },
  button2: {
    flex: "auto",
  },
  paper: {
    margin: "auto",
    width: 500,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: 16,
  },
});

const arraySort = (a, b) => {
  try {
    const nameA = a.toUpperCase(); // ignore upper and lowercase
    const nameB = b.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    // names must be equal
    return 0;
  } catch (e) {
    console.log("VideoProfiles:", e);
  }
};

class VideoProfiles extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      left: false,
      clientId: "ALL",
      selectedProfile: {
        name: "",
        format: "",
        volume: 0,
        url: "",
        syncTime: "03:00",
        rebootTime: "03:00",
        clientId: "",
      },
      modalType: false,
      clientModal: false,
    };
    this.toggleDrawer = this.toggleDrawer.bind(this);
    this.myCallBack = this.myCallBack.bind(this);
    this.handleAction = this.handleAction.bind(this);
    this.handleClear = this.handleClear.bind(this);
    this.clientModal = this.clientModal.bind(this);
  }

  shouldComponentUpdate() {
    if (this.props.match.location.pathname === window.location.pathname) {
      return true;
    }
    return false;
  }

  handleAction(data, type) {
    this.setState({
      selectedProfile: data,
      modalType: type,
    });
  }

  handleClear(clientDeleted) {
    if (clientDeleted) {
      this.setState({ clientId: "ALL" });
    }
    this.setState({
      selectedProfile: {
        name: "",
        format: "",
        volume: 0,
        url: "",
        syncTime: "03:00",
        rebootTime: "03:00",
        clientId: "",
      },
      modalType: false,
      clientModal: false,
    });
  }

  myCallBack(data) {
    this.setState({
      clientId: data,
    });
    return data;
  }

  toggleDrawer(open) {
    return this.setState({
      left: open,
    });
  }

  clientModal(type) {
    this.setState({
      clientModal: type,
    });
  }

  render() {
    const { classes, methods } = this.props;

    return permissions2("5caca42a3630643c2a423821", methods) ? (
      <QueryRenderer
        variables={{}}
        environment={Environment}
        query={queryClient}
        render={({ error, props }) => {
          if (error) {
            console.log(error);
          } else if (props && props.viewer) {
            const { clientId, modalType, selectedProfile, left, clientModal } =
              this.state;

            const fullData = props.viewer.allVideoProfiles.edges
              .filter((item) => item && item.node)
              .map(({ node }) => node);

            const fixedColumns = [
              {
                id: "clientId",
                Header: "Client",
                accessor: (a) =>
                  props.viewer.allVideoClients.edges
                    .filter((item) => item && item.node)
                    .filter((item) => item.node.id === a.clientId)[0]
                    ? props.viewer.allVideoClients.edges
                        .filter((item) => item && item.node)
                        .filter((item) => item.node.id === a.clientId)[0].node
                        .name
                    : null,
                maxWidth: 150,
              },
              {
                Header: "Name",
                accessor: "name",
                maxWidth: 400,
              },
              {
                Header: "Format",
                accessor: "format",
                maxWidth: 70,
              },
              {
                Header: "Volume",
                accessor: "volume",
                maxWidth: 70,
              },
              {
                Header: "Sync",
                accessor: "syncTime",
                maxWidth: 70,
              },
              {
                Header: "Reboot",
                accessor: "rebootTime",
                maxWidth: 70,
              },
              {
                id: "url",
                Header: "Video File",
                accessor: (a) => {
                  const index = a.url.lastIndexOf("/");
                  return a.url.substr(index + 1);
                },
                maxWidth: 450,
              },
              {
                id: "actions",
                Header: "Actions",
                accessor: (a) => (a.sync ? "Yes" : "No"),
                width: 205,
                filterable: false,
                sortable: false,
                Cell: (row) =>
                  permissions([], [], [1, 2]) ? (
                    <div className={classes.actions}>
                      <Tooltip
                        id="tooltip-bottom"
                        title="Edit Profile"
                        placement="bottom"
                        enterTouchDelay={200}
                      >
                        <Button
                          variant="text"
                          size="small"
                          color="primary"
                          className={classes.button2}
                          onClick={() =>
                            this.handleAction(row.original, "edit")
                          }
                        >
                          <Edit />
                        </Button>
                      </Tooltip>
                      <Tooltip
                        id="tooltip-bottom"
                        title="Push profile to devices"
                        placement="bottom"
                        enterTouchDelay={200}
                      >
                        <Button
                          variant="text"
                          size="small"
                          color="primary"
                          className={classes.button2}
                          onClick={() =>
                            this.handleAction(row.original, "push")
                          }
                        >
                          <PhonelinkSetup />
                        </Button>
                      </Tooltip>
                      {permissions([], [], [1]) && (
                        <Tooltip
                          id="tooltip-bottom"
                          title="Delete profile"
                          placement="bottom"
                          enterTouchDelay={200}
                        >
                          <Button
                            variant="text"
                            size="small"
                            color="primary"
                            className={classes.button2}
                            onClick={() =>
                              this.handleAction(row.original, "delete")
                            }
                          >
                            <Delete />
                          </Button>
                        </Tooltip>
                      )}
                    </div>
                  ) : (
                    <div className={classes.actions}>No actions permitted</div>
                  ),
              },
            ];
            const filteredData = fullData.filter(
              (node) => node.clientId === clientId
            );
            const data = clientId === "ALL" ? fullData : filteredData;
            const pageSize = 20;
            const getClient = props.viewer.allVideoClients.edges
              .filter((item) => item && item.node)
              .filter((item) => item.node.id === clientId)[0]
              ? props.viewer.allVideoClients.edges
                  .filter((item) => item && item.node)
                  .filter((item) => item.node.id === clientId)[0].node.name
              : "ALL";

            const videoProfiles = _.uniq(
              props.viewer.allVideoDevices.edges
                .filter((item) => item && item.node)
                .map((item) => item.node.format)
            ).sort((a, b) => arraySort(a, b));

            return (
              <>
                <div className={classes.root}>
                  <h2 style={{ marginLeft: "12px", paddingTop: "12px" }}>
                    <span>{this.props.match.route.name}</span>
                    <span style={{ fontSize: "18px", color: "grey" }}>
                      &nbsp; @ {clientId === "ALL" ? "ALL" : getClient}
                    </span>
                  </h2>
                  <div
                    className={classes.horizontalContainer}
                    style={{ marginLeft: "12px" }}
                  >
                    {permissions([], [], [1, 2]) && (
                      <>
                        <span
                          style={{
                            cursor: "pointer",
                            color: "#3BABA1",
                            fontSize: 16,
                            fontWeight: "bold",
                          }}
                          onClick={() => this.toggleDrawer(true)}
                        >
                          Change Client &nbsp;
                        </span>{" "}
                        |
                      </>
                    )}
                    {permissions([], [], [1, 2]) && clientId !== "ALL" && (
                      <>
                        <span
                          style={{
                            cursor: "pointer",
                            color: "#3BABA1",
                            fontSize: 16,
                            fontWeight: "bold",
                          }}
                          onClick={() => this.clientModal("edit")}
                        >
                          &nbsp;Edit Client &nbsp;
                        </span>{" "}
                        |
                      </>
                    )}
                    {permissions([], [], [1]) && clientId !== "ALL" && (
                      <>
                        <span
                          style={{
                            cursor: "pointer",
                            color: "#3BABA1",
                            fontSize: 16,
                            fontWeight: "bold",
                          }}
                          onClick={() => this.clientModal("delete")}
                        >
                          &nbsp;Delete Client &nbsp;
                        </span>{" "}
                        |
                      </>
                    )}
                    {permissions([], [], [1, 2]) && (
                      <>
                        <span
                          style={{
                            cursor: "pointer",
                            color: "#3BABA1",
                            fontSize: 16,
                            fontWeight: "bold",
                          }}
                          onClick={() =>
                            this.handleAction(selectedProfile, "new")
                          }
                        >
                          &nbsp;New Profile &nbsp;
                        </span>{" "}
                        |
                      </>
                    )}
                  </div>
                  {permissions([], [], [1, 2]) && (
                    <Drawer
                      open={left}
                      onClose={() => this.toggleDrawer(false)}
                    >
                      <div
                        tabIndex={0}
                        role="button"
                        onClick={() => this.toggleDrawer(false)}
                        onKeyDown={() => this.toggleDrawer(false)}
                      >
                        <List dense>
                          <ListItem
                            button
                            divider
                            onClick={() => this.clientModal("new")}
                          >
                            <Loupe color="primary" />
                            <ListItemText primary="Create new client" />
                          </ListItem>
                          <ListItem button>
                            <ListItemText
                              primary="ALL"
                              onClick={() => this.setState({ clientId: "ALL" })}
                            />
                          </ListItem>
                          <VideoClients
                            viewer={props.viewer}
                            myCallBack={this.myCallBack}
                          />
                        </List>
                      </div>
                    </Drawer>
                  )}
                  <div className={classes.horizontalContainer2}>
                    <ReactTable
                      data={data}
                      columns={fixedColumns}
                      defaultPageSize={pageSize}
                      showPagination={data.length > pageSize}
                      filterable
                      defaultFilterMethod={(filter, row) =>
                        String(row[filter.id])
                          .toLowerCase()
                          .includes(filter.value.toLowerCase())
                      }
                      className="-striped -highlight"
                      style={{
                        display: "block",
                        margin: "8px 0px 8px 8px",
                        fontSize: "13px",
                        flex: "auto",
                        minWidth: "35vw",
                      }}
                    />
                  </div>
                  <Typography
                    variant="caption"
                    color="secondary"
                    className={classes.typo}
                    style={{ paddingLeft: 8 }}
                    align="left"
                  >
                    Tip: Hold shift when sorting to multi-sort!
                  </Typography>
                  {permissions([], [], [1, 2]) && modalType === "edit" && (
                    <Modal
                      aria-labelledby="edit-modal"
                      aria-describedby="edit-modal"
                      open={!!modalType}
                      onClose={this.handleClear}
                    >
                      <div className={classes.paper}>
                        <EditProfile
                          selectedProfile={selectedProfile}
                          handleClose={this.handleClear}
                          videoProfiles={videoProfiles}
                        />
                      </div>
                    </Modal>
                  )}
                  {permissions([], [], [1, 2]) && modalType === "new" && (
                    <Modal
                      aria-labelledby="new-modal"
                      aria-describedby="new-modal"
                      open={!!modalType}
                      onClose={this.handleClear}
                    >
                      <div className={classes.paper}>
                        <NewProfile
                          viewer={props.viewer}
                          selectedProfile={selectedProfile}
                          handleClose={this.handleClear}
                          clientId={clientId}
                          videoProfiles={videoProfiles}
                        />
                      </div>
                    </Modal>
                  )}
                  {permissions([], [], [1]) && modalType === "delete" && (
                    <Modal
                      aria-labelledby="delete-modal"
                      aria-describedby="delete-modal"
                      open={!!modalType}
                      onClose={this.handleClear}
                    >
                      <div className={classes.paper}>
                        <DeleteProfile
                          selectedProfile={selectedProfile}
                          handleClose={this.handleClear}
                        />
                      </div>
                    </Modal>
                  )}
                  {permissions([], [], [1, 2]) && modalType === "push" && (
                    <Modal
                      aria-labelledby="push-modal"
                      aria-describedby="push-modal"
                      open={!!modalType}
                      onClose={this.handleClear}
                    >
                      <div className={classes.paper}>
                        <PushProfile
                          viewer={props.viewer}
                          selectedProfile={selectedProfile}
                          handleClose={this.handleClear}
                        />
                      </div>
                    </Modal>
                  )}
                  {permissions([], [], [1, 2]) && clientModal === "new" && (
                    <Modal
                      aria-labelledby="new-modal"
                      aria-describedby="new-modal"
                      open={!!clientModal}
                      onClose={this.handleClear}
                    >
                      <div className={classes.paper}>
                        <NewVideoClient
                          viewer={props.viewer}
                          handleClose={this.handleClear}
                        />
                      </div>
                    </Modal>
                  )}
                  {permissions([], [], [1, 2]) && clientModal === "edit" && (
                    <Modal
                      aria-labelledby="push-modal"
                      aria-describedby="push-modal"
                      open={!!clientModal}
                      onClose={this.handleClear}
                    >
                      <div className={classes.paper}>
                        <EditVideoClient
                          viewer={props.viewer}
                          clientId={clientId}
                          handleClose={this.handleClear}
                        />
                      </div>
                    </Modal>
                  )}
                  {permissions([], [], [1]) && clientModal === "delete" && (
                    <Modal
                      aria-labelledby="push-modal"
                      aria-describedby="push-modal"
                      open={!!clientModal}
                      onClose={this.handleClear}
                    >
                      <div className={classes.paper}>
                        <DeleteVideoClient
                          clientId={clientId}
                          handleClose={this.handleClear}
                        />
                      </div>
                    </Modal>
                  )}
                </div>
              </>
            );
          }
        }}
      />
    ) : null;
  }
}

VideoProfiles.propTypes = {
  classes: PropTypes.object.isRequired, //eslint-disable-line
  viewer: PropTypes.object.isRequired, //eslint-disable-line
};

export default compose(withStyles(styles), withSnackbar())(VideoProfiles);
