import jwt from "jsonwebtoken";

const permissions = (accessArray, audioArray, videoArray) => {
  const user = jwt.decode(localStorage.getItem("authentication_token"));
  if (user) {
    const access =
      accessArray?.length > 0 ? accessArray.includes(user.roleAccess) : true;
    const audio =
      audioArray?.length > 0 ? audioArray.includes(user.roleAudio) : true;
    const video =
      videoArray?.length > 0 ? videoArray.includes(user.roleVideo) : true;
    return access && audio && video;
  }
  return false;
};

export default permissions;
