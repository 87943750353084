import React, { Component } from "react";
import { createFragmentContainer } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Divider from "@material-ui/core/Divider";
// import GoogleMapReact from 'google-map-react';
// import Tooltip from '@material-ui/core/Tooltip';
// import Button from '@material-ui/core/Button';

import UsersDoughnut from "./dashboard_users/usersDoughnut";
import UserIssuePanel from "./dashboard_users/usersIssuePanel";
import OnlineUsers from "./dashboard_users/onlineUsers";
import AudioDevicesDoughnut from "./dashboard_audio/audioDevicesDoughnut";
import AudioDevicesIssuePanel from "./dashboard_audio/audioDevicesIssuePanel";
import VideoDevicesDoughnut from "./dashboard_video/videoDevicesDoughnut";
import CampaignPanel from "./dashboard_video/campaignPanel";
// import config from '../../config';
import permissions2 from "../authentication/UIPermissions";

const K_WIDTH = 10;
const K_HEIGHT = 10;

const styles = {
  horizontalContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  verticalContainer: {
    display: "flex",
    flexDirection: "column",
  },
  marker: {
    color: "#c935ed",
    "&:hover": {
      color: "#387f86",
    },
  },
  marker2: {
    position: "absolute",
    width: K_WIDTH,
    height: K_HEIGHT,
    left: -K_WIDTH / 2,
    top: -K_HEIGHT / 2,
    borderRadius: "50%",
    backgroundColor: "#c935ed",
    padding: 2,
  },
  button: {
    margin: 8,
  },
};

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      zoom: 5,
      center: { lat: 39, lng: -98 },
    };
    this.handleZoom = this.handleZoom.bind(this);
  }

  shouldComponentUpdate() {
    if (this.props.match.location.pathname === window.location.pathname) {
      return true;
    }
    return false;
  }

  handleZoom(lat, lng) {
    this.setState({
      zoom: 12,
      center: { lat, lng },
    });
  }

  render() {
    const { classes, viewer, methods } = this.props;
    // const { zoom, center } = this.state;
    // const allAudioDevices = viewer.allAudioDevices.edges
    //  .filter(item => item && item.node);
    return permissions2("5cac91adf078fb33b8832b35", methods) ? (
      <div className={classes.verticalContainer}>
        {/*<Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="subtitle1">Device Location (click on device to zoom)</Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.horizontalContainer}>
          <div style={{ height: '45vh', width: '100%' }}>
            <GoogleMapReact
              bootstrapURLKeys={{
                key: config.GOOGLE_MAPS_KEY,
              }}
              defaultZoom={5}
              zoom={zoom}
              center={center}
            >
              {allAudioDevices.map(
                ({ node }) => (
                  <Tooltip
                    key={node.id}
                    title={node.name}
                    placement="top"
                    lat={node.latitude}
                    lng={node.longitude}
                    onClick={() => this.handleZoom(node.latitude, node.longitude)}
                  >
                    <div
                      className={classes.marker2}
                    />
                  </Tooltip>
                )
              )}
            </GoogleMapReact>
          </div>
          <div className={classes.horizontalContainer}>
            <Button
              variant="contained"
              className={classes.button}
              onClick={() => this.setState({ zoom: 5 })}
            >
              Reset zoom
            </Button>
          </div>
          </AccordionDetails>
        </Accordion>*/}
        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="subtitle1">Audio Devices Section</Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.horizontalContainer}>
            <AudioDevicesDoughnut viewer={viewer} />
            <AudioDevicesIssuePanel viewer={viewer} />
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="subtitle1">
              Playlist & Schedule Section
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.horizontalContainer}>
            Playbacks & Schedule
          </AccordionDetails>
        </Accordion>
        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="subtitle1">Video Devices Section</Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.horizontalContainer}>
            <VideoDevicesDoughnut viewer={viewer} />
            <CampaignPanel viewer={viewer} />
          </AccordionDetails>
        </Accordion>
        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="subtitle1">User Control Section</Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.horizontalContainer}>
            <UsersDoughnut viewer={viewer} />
            <UserIssuePanel viewer={viewer} />
            <OnlineUsers viewer={viewer} />
          </AccordionDetails>
        </Accordion>
        <Divider />
      </div>
    ) : null;
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
  viewer: PropTypes.object.isRequired,
};

export default withStyles(styles)(
  createFragmentContainer(Dashboard, {
    viewer: graphql`
      fragment dashboard_viewer on Viewer {
        allUsers {
          edges {
            node {
              id
              status {
                online
              }
              profile {
                name
              }
              emails {
                address
              }
              rolesConsole {
                roleAccess
                roleAudio
                roleVideo
              }
              services {
                google {
                  email
                }
                facebook {
                  email
                }
              }
              emails {
                address
              }
              companyName
              serviceType
              defaultServiceType
            }
          }
        }
        allAudioDevices {
          edges {
            node {
              id
              name
              appUpTime
              lastOnline
              goLiveDate
              deviceStatus
              appStatus {
                status
                message
                time
                description
              }
              latitude
              longitude
            }
          }
        }
        allVideoDevices {
          edges {
            node {
              name
              lastOnline
            }
          }
        }
        allVideoCampaigns {
          edges {
            node {
              id
              name
              description
              clientId
              goLiveDate
              deviceIds
              profileIds
              onHold
            }
          }
        }
      }
    `,
  })
);
