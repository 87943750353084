/**
 * @flow
 * @relayHash 271933e1f37dcae370ca1c597cfa0cca
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type registerAudioDeviceMutationVariables = {|
  deviceId: string,
  clientId: string,
  scheduleIds: string,
|};
export type registerAudioDeviceMutationResponse = {|
  +registerAudioDevice: ?{|
    +id: string
  |}
|};
export type registerAudioDeviceMutation = {|
  variables: registerAudioDeviceMutationVariables,
  response: registerAudioDeviceMutationResponse,
|};
*/


/*
mutation registerAudioDeviceMutation(
  $deviceId: String!
  $clientId: String!
  $scheduleIds: String!
) {
  registerAudioDevice(deviceId: $deviceId, clientId: $clientId, scheduleIds: $scheduleIds) {
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "clientId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "deviceId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "scheduleIds"
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "clientId",
        "variableName": "clientId"
      },
      {
        "kind": "Variable",
        "name": "deviceId",
        "variableName": "deviceId"
      },
      {
        "kind": "Variable",
        "name": "scheduleIds",
        "variableName": "scheduleIds"
      }
    ],
    "concreteType": "AudioDevice",
    "kind": "LinkedField",
    "name": "registerAudioDevice",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "registerAudioDeviceMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "registerAudioDeviceMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "id": "271933e1f37dcae370ca1c597cfa0cca",
    "metadata": {},
    "name": "registerAudioDeviceMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '17a00eea0fab2e02f942bc3221476602';

module.exports = node;
