/**
 * External dependencies
 */
import React, { useState, useContext, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  TextField,
  Button,
  MenuItem,
  ListItemAvatar,
  Avatar,
} from "@material-ui/core";

/**
 * Internal dependencies
 */

import editSonosRouteFolderMutation from "../../../mutations/editSonosRouteFolderMutation";
import { CurrentMenuContext } from "../../hooks/currentMenuProvider";
import { CurrentClientContext } from "../../hooks/currentClientProvider";
import { CurrentRouteContext } from "../../hooks/currentRouteProvider";
import { autoPopulateType } from "../utils/autoPopulateType";
import { playableTypes } from "../utils/playableTypes";
import { folderCovers } from "../utils/folderCovers";
import editQsysRouteFolderMutation from "../../../mutations/editQsysRouteFolderMutation";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 300,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: "10vh",
    margin: "auto",
    left: 0,
    right: 0,
    borderRadius: 19,
  },
  form: {
    display: "flex",
    flexDirection: "column",
  },
  textField: {
    margin: 8,
  },
  button: {
    margin: 8,
  },
  imageList: {
    flexWrap: "nowrap",
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: "translateZ(0)",
  },
  title: {
    color: "white",
  },
  titleBar: {
    background:
      "linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
  },
}));

export default function EditRouteModal({ route, handleClose }) {
  const { audioProvider } = useContext(CurrentClientContext);
  const { currentMenu, currentMenuExtension } = useContext(CurrentMenuContext);
  const handledMenu = currentMenuExtension || currentMenu;
  const { setCurrentRoute } = useContext(CurrentRouteContext);
  const classes = useStyles();
  const [routeName, setRouteName] = useState(route.name);
  const [routePopulate, setRoutePopulate] = useState(route.autoPopulateType);
  const [routePlayableTypes, setRoutePlayableTypes] = useState(
    route.playableType || "BOTH"
  );
  const [coverLabel, setCoverLabel] = useState(route?.coverName);
  const [coverSet, setCoverSet] = useState(
    folderCovers.find((item) => item.label === route?.coverName)
  );

  const { playableList } = useContext(CurrentClientContext);
  const explicit =
    currentMenuExtension?.explicit ?? currentMenu?.explicit ?? true;

  const defaultArtwork = folderCovers.find(
    (item) => item.label === "Playlists-Main"
  );

  const handleChange = (event, field) => {
    switch (field) {
      case "route":
        setRouteName(event.target.value);
        break;
    }
  };

  const handlePopulate = (event) => {
    setRoutePopulate(event.target.value);
  };

  const handlePlayableTypes = (event) => {
    setRoutePlayableTypes(event.target.value);
  };

  const handleCovers = (event) => {
    setCoverLabel(event.target.value);
  };

  const editRoute = () => {
    let routeItems = 0;
    if (route.autoPopulateType === "GENRE" && routePopulate === "GENRE") {
      let playableType = "all";
      switch (routePlayableTypes) {
        case "PLAYLISTS":
          playableType = "playlist";
          break;
        case "SCENES":
          playableType = "scene";
          break;
        default:
          playableType = "all";
      }
      routeItems =
        playableList(playableType, explicit).filter(
          (item) =>
            item?.genres?.some((item2) =>
              route?.genres?.includes(item2.toUpperCase())
            ) ||
            item?.genre?.some((item2) =>
              route?.genres?.includes(item2.toUpperCase())
            )
        ).length || 0;
    }
    audioProvider === "sonos"
      ? editSonosRouteFolderMutation(
          handledMenu?.id,
          route.pathId,
          routeName,
          routePopulate,
          coverSet?.label || "Playlists-Main",
          coverSet?.cover_40 || defaultArtwork.cover_40,
          coverSet?.cover_290 || defaultArtwork.cover_290,
          coverSet?.cover_legacy || defaultArtwork.cover_legacy,
          routePlayableTypes,
          routeItems,
          (newMenu) => {
            const newRoute = newMenu?.schema.find(
              (item) => item.pathId === route.pathId
            );
            setCurrentRoute(newRoute);
            handleClose();
          }
        )
      : editQsysRouteFolderMutation(
          handledMenu?.id,
          route.pathId,
          routeName,
          routePopulate,
          coverSet?.label || "Playlists-Main",
          coverSet?.cover_40 || defaultArtwork.cover_40,
          coverSet?.cover_290 || defaultArtwork.cover_290,
          coverSet?.cover_legacy || defaultArtwork.cover_legacy,
          routePlayableTypes,
          routeItems,
          (newMenu) => {
            const newRoute = newMenu?.schema.find(
              (item) => item.pathId === route.pathId
            );
            setCurrentRoute(newRoute);
            handleClose();
          }
        );
  };

  useEffect(() => {
    const allCovers = folderCovers.find((item) => item.label === coverLabel);
    setCoverSet(allCovers);
  }, [coverLabel]);

  return (
    <>
      <div className={classes.paper}>
        <h2 id="edit-menu-modal-title">Edit route folder: {route.name}</h2>
        <form className={classes.form} noValidate autoComplete="off">
          <TextField
            id="menu-route-edit-name"
            label="Name"
            onChange={(event) => handleChange(event, "route")}
            value={routeName}
            className={classes.textField}
            helperText="The name is caps sensitive. Max 30 characters and more then 3"
            error={routeName.length < 2 && routeName.length > 30}
          />
          <TextField
            id="select-populate-type"
            className={classes.textField}
            select
            label="Select"
            value={routePopulate}
            onChange={handlePopulate}
            helperText="Select how to populate this folder"
          >
            {autoPopulateType.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          {routePopulate === "GENRE" && (
            <TextField
              id="select-playble-types-genre"
              className={classes.textField}
              select
              label="Playable types"
              value={routePlayableTypes}
              onChange={handlePlayableTypes}
              helperText="Select playable types"
            >
              {playableTypes.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          )}
          <TextField
            id="select-cover-type"
            className={classes.textField}
            select
            label="Select folder artwork"
            value={coverLabel || defaultArtwork.label}
            onChange={handleCovers}
            helperText="Select cover set for this folder"
          >
            {folderCovers.map((option) => (
              <MenuItem key={option.label} value={option.label}>
                <ListItemAvatar>
                  <Avatar
                    variant="rounded"
                    alt={option.label}
                    src={option?.cover_40 || defaultArtwork.cover_40}
                  />
                </ListItemAvatar>{" "}
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            disabled={routeName.length < 2 && routeName.length > 30}
            onClick={editRoute}
          >
            Edit path
          </Button>
        </form>
      </div>
    </>
  );
}
