/**
 * @flow
 * @relayHash 222c594538815472445561c2675fc874
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type createSmartPlaylistProviderData = {|
  name: string,
  description?: ?string,
  shuffle: boolean,
  size: number,
  createdAt?: ?any,
  playlists: $ReadOnlyArray<PlaylistNewQueue>,
  clientId: string,
|};
export type PlaylistNewQueue = {|
  id?: ?string,
  nrOfSongs?: ?string,
  name?: ?string,
  filename?: ?string,
|};
export type createSmartPlaylistMutationVariables = {|
  input: createSmartPlaylistProviderData,
  imageName: string,
|};
export type createSmartPlaylistMutationResponse = {|
  +createSmartPlaylist: ?{|
    +id: string,
    +name: string,
    +description: ?string,
    +shuffle: ?boolean,
    +image: ?string,
    +size: ?number,
    +createdAt: ?any,
    +updatedAt: ?any,
    +clientId: string,
    +playlists: ?$ReadOnlyArray<?{|
      +playlistId: ?string,
      +name: ?string,
      +nrOfSongs: ?string,
      +filename: ?string,
    |}>,
    +signedUrl: ?string,
  |}
|};
export type createSmartPlaylistMutation = {|
  variables: createSmartPlaylistMutationVariables,
  response: createSmartPlaylistMutationResponse,
|};
*/


/*
mutation createSmartPlaylistMutation(
  $input: createSmartPlaylistProviderData!
  $imageName: String!
) {
  createSmartPlaylist(createSmartPlaylistProvider: $input, imageName: $imageName) {
    id
    name
    description
    shuffle
    image
    size
    createdAt
    updatedAt
    clientId
    playlists {
      playlistId
      name
      nrOfSongs
      filename
    }
    signedUrl
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "imageName"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "createSmartPlaylistProvider",
        "variableName": "input"
      },
      {
        "kind": "Variable",
        "name": "imageName",
        "variableName": "imageName"
      }
    ],
    "concreteType": "Playback",
    "kind": "LinkedField",
    "name": "createSmartPlaylist",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "description",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "shuffle",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "image",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "size",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "createdAt",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "updatedAt",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "PlaylistQueue",
        "kind": "LinkedField",
        "name": "playlists",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "playlistId",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "nrOfSongs",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "filename",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "signedUrl",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "createSmartPlaylistMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "createSmartPlaylistMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "id": "222c594538815472445561c2675fc874",
    "metadata": {},
    "name": "createSmartPlaylistMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '470b0e0e40fbb8c4b7e421bb4cb0a109';

module.exports = node;
