import { commitMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import Environment from "../../environment";

const mutation = graphql`
  mutation extendPreviewTokenMutation($clientId: String!) {
    extendPreviewToken(clientId: $clientId) {
      id
      name
      playlistIds
      playbackIds
      sceneIds
      featuredIds
      logo
      preview {
        previewId
        created
        ending
        isEnded
        isEndless
      }
    }
  }
`;

export default (clientId, callback) => {
  const variables = {
    clientId,
  };

  commitMutation(Environment, {
    mutation,
    variables,
    onCompleted: () => {
      callback();
    },
    onError: (err) => console.error(err), // eslint-disable-line
  });
};
