/* eslint-disable no-unused-vars */
import React from "react";
import { createFragmentContainer } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";
import ReactTable from "react-table";
import "react-table/react-table.css";
import AddBoxIcon from "@material-ui/icons/AddBox";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import NewTag from "./newTag";
import DeleteTag from "./deleteTag";
import EditTag from "./editTag";
import permissions2 from "../authentication/UIPermissions";

const styles = (theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    margin: "0px 0px 0px 0px",
  },
  typo: {
    padding: 0,
  },
  typoClient: {
    padding: "0px 0px 0px 0px",
    color: "secondary",
  },
  button: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    marginLeft: "9px",
    "&:hover": {
      background: "#e3fd59",
    },
  },
  horizontalContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  horizontalContainer2: {
    display: "flex",
    flexWrap: "wrap",
  },
  paper: {
    margin: "auto",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: 16,
  },
  actions: {
    flexWrap: "wrap",
    display: "flex",
  },
});

const openRow = "#DCDCDC";

class Tags extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      deviceControlId: "none",
      selectedTag: {
        id: "",
        name: "",
        description: "",
      },
      selectedRow: "",
      modalType: false,
    };
    this.handleClose = this.handleClose.bind(this);
    this.handleClear = this.handleClear.bind(this);
    this.handleAction = this.handleAction.bind(this);
    this.selectRow = this.selectRow.bind(this);
  }

  shouldComponentUpdate() {
    if (this.props.match.location.pathname === window.location.pathname) {
      return true;
    }
    return false;
  }

  handleAction(data, type) {
    this.setState({
      selectedTag: data,
      modalType: type,
    });
  }

  handleClear() {
    this.setState({
      modalType: false,
      selectedTag: {
        id: "",
        name: "",
        description: "",
      },
    });
  }

  selectRow(userId) {
    this.setState({ selectedRow: userId });
  }

  handleClose() {
    this.setState({ anchorEl: null });
  }

  render() {
    const { classes, viewer, methods } = this.props;
    const { selectedTag, selectedRow, modalType } = this.state;

    const fixedColumns = [
      {
        Header: "Name",
        accessor: "name",
        maxWidth: 300,
      },
      {
        Header: "Description",
        accessor: "description",
        maxWidth: 700,
      },
      {
        Header: "Number of devices",
        columns: [
          {
            Header: "Audio",
            accessor: "noOfDevicesAudio",
            maxWidth: 80,
          },
          {
            Header: "Video",
            accessor: "noOfDevicesVideo",
            maxWidth: 80,
          },
        ],
      },
      {
        Header: "Actions",
        width: 160,
        filterable: false,
        sortable: false,
        Cell: (row) => (
          <div className={classes.actions}>
            <Tooltip
              id="tooltip-bottom"
              title="Edit Tag"
              placement="bottom"
              enterTouchDelay={200}
            >
              <Button
                variant="text"
                size="small"
                color="primary"
                className={classes.button}
                onClick={() => this.handleAction(row.original, "edit")}
              >
                <EditIcon />
              </Button>
            </Tooltip>
            <Tooltip
              id="tooltip-bottom"
              title="Delete Tag"
              placement="bottom"
              enterTouchDelay={200}
            >
              <Button
                variant="text"
                size="small"
                color="primary"
                className={classes.button}
                onClick={() => this.handleAction(row.original, "delete")}
              >
                <DeleteIcon />
              </Button>
            </Tooltip>
          </div>
        ),
      },
    ];

    const allAudioDevices = viewer.allAudioDevices.edges
      .filter((item) => item && item.node)
      .filter((item) => item.node.deviceStatus === "ACTIVE");

    const allVideoDevices = viewer.allVideoDevices.edges.filter(
      (item) => item && item.node
    );

    const fullData = viewer.allTags.edges
      .filter((item) => item && item.node)
      .map(({ node }) => {
        const noOfDevicesAudio = allAudioDevices
          .filter((item) => item.node.tags)
          .filter(
            (item) => item.node.tags.filter((item2) => item2 === node.id)[0]
          ).length;
        const noOfDevicesVideo = allVideoDevices
          .filter((item) => item.node.tags)
          .filter(
            (item) => item.node.tags.filter((item2) => item2 === node.id)[0]
          ).length;
        const node2 = Object.assign(
          { noOfDevicesAudio, noOfDevicesVideo },
          node
        );
        return node2;
      });
    const pageSize = 20;
    return permissions2("5caded6e8d33061673675065", methods) ? (
      <>
        <div className={classes.root}>
          <h2 style={{ marginLeft: "12px", paddingTop: "12px" }}>
            {this.props.match.route.name}
          </h2>
          <div className={classes.horizontalContainer}>
            <Tooltip
              id="tooltip-bottom"
              title="Add tag"
              placement="bottom"
              enterTouchDelay={200}
            >
              <Button
                variant="text"
                color="primary"
                size="small"
                aria-label="duplicate"
                className={classes.button}
                onClick={() => this.handleAction(selectedTag, "new")}
              >
                <AddBoxIcon />
              </Button>
            </Tooltip>
          </div>
          <div className={classes.horizontalContainer2}>
            <ReactTable
              data={fullData}
              columns={fixedColumns}
              defaultPageSize={pageSize}
              showPagination={fullData.length > pageSize}
              filterable
              defaultFilterMethod={(filter, row) =>
                String(row[filter.id])
                  .toLowerCase()
                  .includes(filter.value.toLowerCase())
              }
              className="-striped -highlight"
              style={{
                display: "block",
                margin: "8px 0px 8px 8px",
                fontSize: "13px",
                flex: "auto",
                minWidth: "35vw",
              }}
              getTrProps={(state, rowInfo) => {
                const selectedId = rowInfo ? rowInfo.original.id : null;
                return {
                  onClick: () => this.selectRow(rowInfo.original.id),
                  style: {
                    background:
                      selectedId === selectedRow ? openRow : "#FFFFFF",
                  },
                };
              }}
            />
          </div>
          <Typography
            variant="caption"
            color="secondary"
            className={classes.typo}
            style={{ paddingLeft: 8 }}
            align="left"
          >
            Tip: Hold shift when sorting to multi-sort!
          </Typography>
          {modalType === "new" && (
            <Modal
              aria-labelledby="new-modal"
              aria-describedby="new-modal"
              open={!!modalType}
              onClose={this.handleClear}
            >
              <div className={classes.paper}>
                <NewTag
                  viewer={viewer}
                  selectedTag={selectedTag}
                  handleClose={this.handleClear}
                />
              </div>
            </Modal>
          )}
          {modalType === "delete" && (
            <Modal
              aria-labelledby="delete-modal"
              aria-describedby="delete-modal"
              open={!!modalType}
              onClose={this.handleClear}
            >
              <div className={classes.paper}>
                <DeleteTag
                  selectedTag={selectedTag}
                  handleClose={this.handleClear}
                />
              </div>
            </Modal>
          )}
          {modalType === "edit" && (
            <Modal
              aria-labelledby="edit-modal"
              aria-describedby="edit-modal"
              open={!!modalType}
              onClose={this.handleClear}
            >
              <div className={classes.paper}>
                <EditTag
                  selectedTag={selectedTag}
                  handleClose={this.handleClear}
                />
              </div>
            </Modal>
          )}
        </div>
      </>
    ) : null;
  }
}

Tags.propTypes = {
  classes: PropTypes.object.isRequired, //eslint-disable-line
  viewer: PropTypes.object.isRequired, //eslint-disable-line
};

export default withStyles(styles)(
  createFragmentContainer(Tags, {
    viewer: graphql`
      fragment Tags_viewer on Viewer {
        allTags {
          edges {
            node {
              id
              name
              description
            }
          }
        }
        allVideoDevices {
          edges {
            node {
              id
              name
              tags
            }
          }
        }
        allAudioDevices {
          edges {
            node {
              id
              name
              tags
              deviceStatus
            }
          }
        }
      }
    `,
  })
);
