/* eslint-disable react/prop-types */
import React, { Component } from "react";
import { withRouter } from "found";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import { Doughnut } from "react-chartjs-2";

const styles = (theme) => ({
  card: {
    display: "flex",
    flexDirection: "column",
    background: theme.palette.background.paper,
    margin: 4,
  },
  horizontalContainer: {
    padding: 4,
    display: "flex",
    flexWrap: "wrap",
  },
  verticalContainer: {
    padding: 4,
    display: "flex",
    flexDirection: "column",
  },
});

class AudioDevicesDoughnut extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    const { classes, viewer } = this.props;
    const onlineUsers = viewer.allUsers.edges.filter(
      (item) => item?.node?.status?.online
    );
    const google = onlineUsers[0]
      ? onlineUsers.filter((item) => item.node.services.google !== null)
      : [];

    const onlineUsersWOGoogle = onlineUsers.filter(
      (item) => google.indexOf(item) === -1
    );
    const facebook = onlineUsersWOGoogle[0]
      ? onlineUsersWOGoogle.filter(
          (item) => item.node.services.facebook !== null
        )
      : [];

    const onlineUsersWOFacebook = onlineUsersWOGoogle.filter(
      (item) => facebook.indexOf(item) === -1
    );
    const email = onlineUsersWOFacebook[0]
      ? onlineUsersWOFacebook.filter(
          (item) => item.node.emails.address !== null
        )
      : [];
    const doughnut = {
      labels: ["Google", "Facebook", "Email"],
      datasets: [
        {
          data: [google.length, facebook.length, email.length],
          backgroundColor: ["#DB4437", "#3b5998", "#387f86"],
          hoverBackgroundColor: ["#e2695e", "#627aac", "#4ee79a"],
        },
      ],
    };
    return (
      <Card className={classes.card} raised>
        <CardHeader
          title="Connected users"
          subheader="Type of login used by online users"
        />
        <CardContent className={classes.horizontalContainer}>
          <Doughnut data={doughnut} width={320} height={320} />
        </CardContent>
      </Card>
    );
  }
}

AudioDevicesDoughnut.propTypes = {
  classes: PropTypes.object.isRequired, //eslint-disable-line
  viewer: PropTypes.object.isRequired, // eslint-disable-line
};

export default withRouter(withStyles(styles)(AudioDevicesDoughnut));
