import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import Switch from "@material-ui/core/Switch";
import PlaylistListView from "./PlaylistListView";

import togglePlaylistToClientMutation from "../mutations/togglePlaylistToClientMutation";
import PlaylistToggleModal from "./components/playlistToggleModal";
import { checkPlaylistsBeforeRemove } from "./utils/checkPlaylist";

const styles = (theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  formControl: {
    margin: "16px 8px 8px 8px",
    minWidth: 200,
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  dateTypo: {
    padding: 8,
    alignSelf: "center",
  },
  infoIcon: {
    alignSelf: "center",
  },
  verticalContainer: {
    padding: 4,
    display: "flex",
    flexDirection: "column",
  },
  horizontalContainer: {
    padding: 4,
    display: "flex",
    flexWrap: "wrap",
  },
});

const arraySort2 = (a, b) => {
  try {
    const nameA = a.toUpperCase(); // ignore upper and lowercase
    const nameB = b.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    // names must be equal
    return 0;
  } catch (e) {
    console.log("PlaylistList(arraySort2):", e);
  }
};

const arraySort = (a, b, checkedA, checkedB) => {
  try {
    let ratingA = 0;
    let ratingB = 0;
    const nameA = a.name.toUpperCase(); // ignore upper and lowercase
    const nameB = b.name.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      ratingA += 1;
    } else if (nameA > nameB) {
      ratingB += 1;
    }
    if (checkedA) {
      ratingA += 10;
    }
    if (checkedB) {
      ratingB += 10;
    }
    if (ratingA < ratingB) {
      return 1;
    }
    if (ratingA > ratingB) {
      return -1;
    }
    return 0;
  } catch (e) {
    console.log("PlaylistList(arraySort):", e);
  }
};

class PlaylistList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchField: "",
      displayNumber: 20,
      sortBy: "Importance",
      viewMode: "list",
      filterBy: "None",
      verifyModal: false,
      playlistsArray: [],
      playlistIdsToBeToggled: [],
      type: true,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleChange2 = this.handleChange2.bind(this);
    this.handleChange3 = this.handleChange3.bind(this);
    this.togglePlaylist = this.togglePlaylist.bind(this);
    this.handleChangeFilterType = this.handleChangeFilterType.bind(this);
    this.handleSelectGenre = this.handleSelectGenre.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.verifyPlaylist = this.verifyPlaylist.bind(this);
  }

  componentDidMount() {
    const totalDisplayNumber = this.props.viewer.allPlaylists.edges.filter(
      (item) => item && item.node
    );
    this.setState({
      displayNumber: totalDisplayNumber.length,
    });
  }

  handleChange(name, event) {
    this.setState({ [name]: event.target.value });
  }

  handleChange2(event) {
    this.setState({ displayNumber: event.target.value });
  }

  handleChange3(event) {
    this.setState({ sortBy: event.target.value });
  }

  handleChangeFilterType(event) {
    this.setState({ filterBy: event.target.value });
  }

  handleClose() {
    this.setState((state) => ({ verifyModal: !state.verifyModal }));
  }

  handleSelectGenre(allPlaybacks, allSchedule, client, allPlaylists, type) {
    if (type) {
      this.verifyPlaylist(
        allPlaybacks,
        allSchedule,
        client,
        allPlaylists,
        type
      );
    } else {
      this.togglePlaylist(allPlaylists, type);
    }
  }

  verifyPlaylist(allPlaybacks, allSchedule, client, allPlaylists, type) {
    const playlistsArray =
      checkPlaylistsBeforeRemove(
        allPlaylists,
        allPlaybacks,
        allSchedule,
        client?.id
      ) || [];
    this.setState({
      playlistsArray: playlistsArray,
      playlistIdsToBeToggled: allPlaylists,
      type,
    });
    if (playlistsArray.length === 0) {
      this.togglePlaylist(allPlaylists, type);
    } else {
      this.setState((state) => ({ verifyModal: !state.verifyModal }));
    }
  }

  togglePlaylist(allPlaylists, type) {
    const playlistIds = allPlaylists.map((item) => item.id);
    const message = type ? "Playlists removed" : "Playlists added";
    togglePlaylistToClientMutation(
      playlistIds,
      type,
      this.props.client.id,
      () => console.log(message)
    );
    this.setState({ verifyModal: false });
  }

  handleViewAllPress = (totalDisplayNumber) => {
    this.setState({
      displayNumber: totalDisplayNumber,
    });
  };
  render() {
    const { classes, viewer, client } = this.props;
    const {
      searchField,
      displayNumber,
      sortBy,
      filterBy,
      verifyModal,
      playlistsArray,
      playlistIdsToBeToggled,
      type,
    } = this.state;

    const allPlaylistsChecked = client?.playlistIds || [];
    const allPlaylistsFeaturedChecked = client?.featuredIds || [];

    const allPlaylistsAvailable = viewer.allPlaylists.edges
      .filter((item) => item?.node)
      .sort((a, b) =>
        a.node.name && b.node.name
          ? arraySort(
              a.node,
              b.node,
              allPlaylistsChecked.includes(a.node.id),
              allPlaylistsChecked.includes(b.node.id)
            )
          : null
      );

    const noToDisplay = Math.ceil(allPlaylistsAvailable.length / 20);
    let noToDisplayArray = [];
    for (let i = 0; i < noToDisplay; i += 1) {
      noToDisplayArray = noToDisplayArray.concat("");
    }

    const allGenreArray = _.compact(
      viewer.allPlaylists.edges
        .filter((item) => item?.node)
        .map((item) => item.node.genre)
    );

    const allGenre = _.union(...(allGenreArray || [])).sort((a, b) =>
      arraySort2(a, b)
    );

    const sortType = ["Importance", "Genre"];
    const filterType = ["None", "Featured"];

    const sortSubType = {
      Importance: ["Assigned to Client", "Unassigned"],
      Genre: [...allGenre, "Unassigned"],
    };

    const sortFilter = (item, item2) => {
      if (sortBy === "Importance") {
        if (
          item === "Assigned to Client" &&
          allPlaylistsChecked.includes(item2.node.id)
        ) {
          return true;
        }
        if (
          item === "Unassigned" &&
          !allPlaylistsChecked.includes(item2.node.id)
        ) {
          return true;
        }
      }
      if (sortBy === "Genre") {
        if (item2.node.genre && item2.node.genre.includes(item)) {
          return true;
        }
        if (
          item === "Unassigned" &&
          (!item2.node.genre || item2.node.genre.length === 0)
        ) {
          return true;
        }
      }
      return false;
    };

    const filterFunction = (item) => {
      switch (filterBy) {
        case "None":
          return true;
        case "Featured":
          return allPlaylistsFeaturedChecked.includes(item?.node?.id);
        default:
          return true;
      }
    };

    const allPlaybacks = viewer.allPlaybacks.edges
      .filter((item) => item?.node)
      .map(({ node }) => node);

    const allSchedule = viewer.allSchedule.edges
      .filter((item) => item?.node)
      .map(({ node }) => node);

    const allAudioClients = viewer.allClients.edges
      .filter((item) => item?.node)
      .map(({ node }) => node);

    const allAudioDevices = viewer.allAudioDevices.edges
      .filter((item) => item?.node)
      .map(({ node }) => node);

    return (
      <div>
        <div className={classes.container}>
          <TextField
            label="Search playlist"
            type="search"
            value={searchField}
            className={classes.textField}
            onChange={(event) => this.handleChange("searchField", event)}
            margin="normal"
          />
          <FormControl className={classes.formControl}>
            <InputLabel>Playlist display number</InputLabel>
            <Select
              value={displayNumber || allPlaylistsAvailable.length}
              onChange={(event) => this.handleChange2(event)}
            >
              {noToDisplayArray.map((item, i) => (
                <MenuItem key={i} value={20 * (i + 1)}>
                  {20 * (i + 1)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel>Sort playlist by</InputLabel>
            <Select
              value={sortBy}
              onChange={(event) => this.handleChange3(event)}
            >
              {sortType.map((item, i) => (
                <MenuItem key={i} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel>Filter playlist by</InputLabel>
            <Select
              value={filterBy}
              onChange={(event) => this.handleChangeFilterType(event)}
            >
              {filterType.map((item, i) => (
                <MenuItem key={i} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className={classes.root}>
          {client?.id && (
            <div
              style={{
                alignSelf: "flex-end",
                marginRight: 16,
              }}
            >
              <div
                className={classes.horizontalContainer}
                style={{
                  alignItems: "center",
                }}
              >
                <p
                  style={{
                    fontWeight: "bold",
                    color: "#c361e9",
                    fontSize: 19,
                    marginLeft: "12px",
                  }}
                >
                  Select all
                </p>
                <Switch
                  edge="end"
                  onChange={() =>
                    allPlaylistsChecked.length >= allPlaylistsAvailable.length
                      ? this.verifyPlaylist(
                          allPlaybacks,
                          allSchedule,
                          client,
                          allPlaylistsAvailable
                            .filter(({ node }) =>
                              allPlaylistsChecked.includes(node.id)
                            )
                            .map(({ node }) => node),
                          allPlaylistsChecked.length >=
                            allPlaylistsAvailable.length
                        )
                      : this.togglePlaylist(
                          allPlaylistsAvailable.map(({ node }) => node),
                          false
                        )
                  }
                  checked={
                    allPlaylistsChecked.length >= allPlaylistsAvailable.length
                  }
                  inputProps={{
                    "aria-labelledby": "switch-select-all-playlists",
                  }}
                />
                <PlaylistToggleModal
                  open={verifyModal}
                  callback={() =>
                    this.togglePlaylist(playlistIdsToBeToggled, type)
                  }
                  handleClose={() => this.handleClose()}
                  playlistsArray={playlistsArray}
                />
              </div>
              <div
                className={classes.horizontalContainer}
                style={{
                  alignItems: "center",
                  marginTop: -35,
                }}
              >
                <p
                  style={{
                    fontWeight: "bold",
                    color: "#c361e9",
                    fontSize: 10,
                    marginLeft: "12px",
                  }}
                >
                  (Please proceed carefully)
                </p>
              </div>
            </div>
          )}
          {sortSubType[sortBy].map((item) => (
            <div key={item} className={classes.verticalContainer}>
              <div
                className={classes.horizontalContainer}
                style={{
                  alignItems: "center",
                }}
              >
                <p
                  style={{
                    fontWeight: "bold",
                    color: "#1d1b1b",
                    fontSize: 19,
                    marginLeft: "12px",
                  }}
                >
                  {item}
                </p>
                <div
                  style={{
                    marginRight: 16,
                  }}
                >
                  {sortBy === "Genre" && item !== "Unassigned" && (
                    <Switch
                      edge="end"
                      onChange={() =>
                        this.handleSelectGenre(
                          allPlaybacks,
                          allSchedule,
                          client,
                          allPlaylistsAvailable
                            .filter(({ node }) => node?.genre.includes(item))
                            .map(({ node }) => node),
                          allPlaylistsAvailable.filter(
                            (item2) =>
                              item2.node?.genre?.includes(item) &&
                              !client?.playlistIds?.includes(item2?.node?.id)
                          ).length === 0
                        )
                      }
                      checked={
                        allPlaylistsAvailable.filter(
                          (item2) =>
                            item2.node?.genre?.includes(item) &&
                            !client?.playlistIds?.includes(item2?.node?.id)
                        ).length === 0
                      }
                      inputProps={{
                        "aria-labelledby": "switch-list-label-wifi",
                      }}
                    />
                  )}
                  {sortBy === "Genre" && item === "Unassigned" && (
                    <Switch
                      edge="end"
                      onChange={() =>
                        this.togglePlaylist(
                          allPlaylistsAvailable
                            .filter(({ node }) => node?.genre?.length === 0)
                            .map(({ node }) => node),
                          allPlaylistsAvailable.filter(
                            (item2) =>
                              item2.node?.genre?.length === 0 &&
                              !client?.playlistIds?.includes(item2?.node?.id)
                          ).length === 0
                        )
                      }
                      checked={
                        allPlaylistsAvailable.filter(
                          (item2) =>
                            item2.node?.genre?.length === 0 &&
                            !client?.playlistIds?.includes(item2?.node?.id)
                        ).length === 0
                      }
                      inputProps={{
                        "aria-labelledby": "switch-list-label-wifi",
                      }}
                    />
                  )}
                </div>
              </div>
              <List component="nav" aria-label="Scene List">
                {allPlaylistsAvailable
                  .filter((item2) =>
                    item2.node.name
                      .toLowerCase()
                      .includes(searchField.toLowerCase())
                  )
                  .slice(0, displayNumber)
                  .filter((item2) => sortFilter(item, item2))
                  .filter((item2) => filterFunction(item2))
                  .map(({ node }) => (
                    <PlaylistListView
                      client={client}
                      viewer={viewer}
                      allPlaybacks={allPlaybacks}
                      allSchedule={allSchedule}
                      allAudioClients={allAudioClients}
                      allAudioDevices={allAudioDevices}
                      node={node}
                      checked={allPlaylistsChecked.includes(node.id)}
                      featuredChecked={allPlaylistsFeaturedChecked.includes(
                        node.id
                      )}
                      key={node.id}
                    />
                  ))}
              </List>
            </div>
          ))}

          <Button
            onClick={() =>
              this.handleViewAllPress(allPlaylistsAvailable.length)
            }
            variant="outlined"
            color="primary"
            style={{ width: "100%" }}
          >
            View all
          </Button>
        </div>
      </div>
    );
  }
}

PlaylistList.propTypes = {
  client: PropTypes.object, // eslint-disable-line
  viewer: PropTypes.object.isRequired, // eslint-disable-line
  classes: PropTypes.object.isRequired, // eslint-disable-line
};

export default withStyles(styles)(PlaylistList);
