/**
 * @flow
 * @relayHash a9b506edc19bb062d1265c3a3ed7472d
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ServerInstance = "backup" | "primary" | "%future added value";
export type getDevicePackagesMutationVariables = {|
  publicid: string,
  serverInstance: ServerInstance,
|};
export type getDevicePackagesMutationResponse = {|
  +getDevicePackages: $ReadOnlyArray<?{|
    +appName: ?string,
    +packageName: string,
    +versionCode: ?number,
    +versionName: ?string,
  |}>
|};
export type getDevicePackagesMutation = {|
  variables: getDevicePackagesMutationVariables,
  response: getDevicePackagesMutationResponse,
|};
*/


/*
mutation getDevicePackagesMutation(
  $publicid: String!
  $serverInstance: ServerInstance!
) {
  getDevicePackages(publicid: $publicid, serverInstance: $serverInstance) {
    appName
    packageName
    versionCode
    versionName
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "publicid"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "serverInstance"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "publicid",
        "variableName": "publicid"
      },
      {
        "kind": "Variable",
        "name": "serverInstance",
        "variableName": "serverInstance"
      }
    ],
    "concreteType": "WebkeyInstalledApp",
    "kind": "LinkedField",
    "name": "getDevicePackages",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "appName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "packageName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "versionCode",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "versionName",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "getDevicePackagesMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "getDevicePackagesMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "a9b506edc19bb062d1265c3a3ed7472d",
    "metadata": {},
    "name": "getDevicePackagesMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '0cbe64a560829017637d3f7687eb7db8';

module.exports = node;
