/**
 * @flow
 * @relayHash 70efb4754e2920f471799ca32fb28291
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type syncPlaylistMutationVariables = {|
  playlistId: string
|};
export type syncPlaylistMutationResponse = {|
  +syncPlaylist: ?{|
    +id: string,
    +name: string,
    +genre: ?$ReadOnlyArray<?string>,
    +description: ?string,
    +signedUrl: ?string,
    +tempo: ?string,
  |}
|};
export type syncPlaylistMutation = {|
  variables: syncPlaylistMutationVariables,
  response: syncPlaylistMutationResponse,
|};
*/


/*
mutation syncPlaylistMutation(
  $playlistId: String!
) {
  syncPlaylist(playlistId: $playlistId) {
    id
    name
    genre
    description
    signedUrl
    tempo
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "playlistId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "playlistId",
        "variableName": "playlistId"
      }
    ],
    "concreteType": "Playlist",
    "kind": "LinkedField",
    "name": "syncPlaylist",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "genre",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "description",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "signedUrl",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "tempo",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "syncPlaylistMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "syncPlaylistMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "70efb4754e2920f471799ca32fb28291",
    "metadata": {},
    "name": "syncPlaylistMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '3b66e3ae4a53ad294ae685a8032735df';

module.exports = node;
