/**
 * @flow
 * @relayHash 7fffd55cc6536d132a299c421d794732
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type NPLogFilter = {|
  AND?: ?$ReadOnlyArray<NPLogFilter>,
  OR?: ?$ReadOnlyArray<NPLogFilter>,
  _id_contains?: ?string,
  deviceId_contains?: ?string,
  date_contains?: ?string,
  data_contains?: ?string,
|};
export type PCLogFilter = {|
  AND?: ?$ReadOnlyArray<PCLogFilter>,
  OR?: ?$ReadOnlyArray<PCLogFilter>,
  _id_contains?: ?string,
  deviceId_contains?: ?string,
  date_contains?: ?string,
  data_contains?: ?string,
|};
export type LogsDialogQueryVariables = {|
  input1: NPLogFilter,
  input2: PCLogFilter,
|};
export type LogsDialogQueryResponse = {|
  +viewer: {|
    +allNPLogs: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: {|
          +id: string,
          +deviceId: ?string,
          +date: ?string,
          +data: ?$ReadOnlyArray<?{|
            +eventType: ?string,
            +readableTimeStamp: ?string,
            +songTitleKey: ?string,
            +artistNameKey: ?string,
            +songFileNameKey: ?string,
            +parentM3uName: ?string,
          |}>,
        |}
      |}>
    |},
    +allPCLogs: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: {|
          +id: string,
          +deviceId: ?string,
          +date: ?string,
          +data: ?$ReadOnlyArray<?{|
            +composer: ?string,
            +songMd5: ?string,
            +songTitle: ?string,
            +isrc: ?string,
            +album: ?string,
            +label: ?string,
            +downloadDate: ?string,
            +timesPlayed: ?number,
            +artist: ?string,
            +year: ?number,
            +songFileName: ?string,
          |}>,
        |}
      |}>
    |},
  |}
|};
export type LogsDialogQuery = {|
  variables: LogsDialogQueryVariables,
  response: LogsDialogQueryResponse,
|};
*/


/*
query LogsDialogQuery(
  $input1: NPLogFilter!
  $input2: PCLogFilter!
) {
  viewer {
    allNPLogs(filter: $input1) {
      edges {
        node {
          id
          deviceId
          date
          data {
            eventType
            readableTimeStamp
            songTitleKey
            artistNameKey
            songFileNameKey
            parentM3uName
          }
        }
      }
    }
    allPCLogs(filter: $input2) {
      edges {
        node {
          id
          deviceId
          date
          data {
            composer
            songMd5
            songTitle
            isrc
            album
            label
            downloadDate
            timesPlayed
            artist
            year
            songFileName
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input1"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input2"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deviceId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "date",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Viewer",
    "kind": "LinkedField",
    "name": "viewer",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "filter",
            "variableName": "input1"
          }
        ],
        "concreteType": "NPLogConnection",
        "kind": "LinkedField",
        "name": "allNPLogs",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "NPLogEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "NPLog",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "NPEvent",
                    "kind": "LinkedField",
                    "name": "data",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "eventType",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "readableTimeStamp",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "songTitleKey",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "artistNameKey",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "songFileNameKey",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "parentM3uName",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "filter",
            "variableName": "input2"
          }
        ],
        "concreteType": "PCLogConnection",
        "kind": "LinkedField",
        "name": "allPCLogs",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PCLogEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PCLog",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PCEvent",
                    "kind": "LinkedField",
                    "name": "data",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "composer",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "songMd5",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "songTitle",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isrc",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "album",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "label",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "downloadDate",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "timesPlayed",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "artist",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "year",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "songFileName",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "LogsDialogQuery",
    "selections": (v4/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "LogsDialogQuery",
    "selections": (v4/*: any*/)
  },
  "params": {
    "id": "7fffd55cc6536d132a299c421d794732",
    "metadata": {},
    "name": "LogsDialogQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9f580d145389a53bda31b8b423f77bf0';

module.exports = node;
