import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import compose from "recompose/compose";
import { withSnackbar } from "material-ui-snackbar-provider";
import moment from "moment";
import IconButton from "@material-ui/core/IconButton";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import SceneInfoButton from "./components/sceneInfoButton";
import permissions from "../authentication/isPermited";
import toggleSceneToClientMutation from "../mutations/toggleSceneToClientMutation";
import toggleFeaturedToClientMutation from "../mutations/toggleFeaturedToClientMutation";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Switch from "@material-ui/core/Switch";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import InfoIcon from "@material-ui/icons/Info";
import StarIcon from "@material-ui/icons/Star";
const styles = (theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(1),
    minWidth: 170,
    width: 200,
    maxWidth: 230,
  },
  card: {
    maxWidth: 200,
  },
  media: {
    height: 180,
    padding: 10,
  },
  expand: {
    transform: "rotate(0deg)",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: "#387f86",
  },
  flexGrow: {
    flex: "1 1 auto",
  },
  playIcon: {
    height: 28,
    width: 28,
  },
  typo: {
    marginBottom: 8,
  },
  header: {
    height: 60,
    padding: 10,
  },
  label1: {
    margin: 2,
    opacity: 0.5,
  },
  label2: {
    margin: 2,
  },
  labelsTop: {
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    margin: 4,
    alignItems: "flex-start",
  },
  labelsBottom: {
    position: "absolute",
    margin: "150px 0px 0px 150px",
    opacity: 0.6,
  },
  verticalContainer: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    flex: "auto",
  },
  cardActions: {
    padding: 5,
  },
  info: {
    backgroundColor: "#387f86",
  },
});

class SceneListView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
      imageHash: Date.now(),
      count: 1,
    };
    this.handleExpandClick = this.handleExpandClick.bind(this);
    this.myCallBack = this.myCallBack.bind(this);
    this.toggleScene = this.toggleScene.bind(this);
    this.toggleFeatured = this.toggleFeatured.bind(this);
  }

  handleExpandClick() {
    this.setState({ expanded: !this.state.expanded });
  }

  myCallBack(data) {
    this.setState({
      data,
    });
  }

  toggleScene(checked) {
    const message = checked ? "Scene removed" : "Scene added";
    toggleSceneToClientMutation(
      [this.props.node.id],
      checked,
      this.props.clientId,
      () => this.props.snackbar.showMessage(message)
    );

    this.setState((state) => ({ count: state.count + 1 }));
  }

  toggleFeatured(checkedFeatured) {
    console.log([this.props.node.id], checkedFeatured, this.props.clientId);
    const message = checkedFeatured
      ? "Featured Scene removed."
      : "This Scene is now featured.";
    toggleFeaturedToClientMutation(
      [this.props.node.id],
      checkedFeatured,
      this.props.clientId,
      "scene",
      () => this.props.snackbar.showMessage(message)
    );

    this.setState((state) => ({ count: state.count + 1 }));
  }

  render() {
    const {
      classes,
      node,
      checked,
      featuredChecked,
      viewer,
      allPlaybacks,
      allSchedule,
      allAudioClients,
      allAudioDevices,
      allPlaylists,
    } = this.props;
    const { imageHash } = this.state;
    const defaultImage =
      "https://artwork.activaire.com/playlists/playlist_default.jpg";
    const title = permissions([], [1, 2])
      ? null
      : {
          titleTypographyProps: {
            variant: "body2",
          },
        };
    if (node) {
      const coverImage =
        !node?.image || node.image === ""
          ? defaultImage
          : `${node.image}?${imageHash}`;
      return (
        <ListItem key={node.id}>
          <ListItemAvatar>
            <Avatar alt="S" src={coverImage.replace("artwork", "thumbnails")} />
          </ListItemAvatar>
          <ListItemText
            id="switch-list-label-wifi"
            primary={
              <div
                style={{ dispaly: "flex", flexDirection: "row", fontSize: 16 }}
              >
                <span style={{ fontWeight: "bold" }}>{node.name}</span> ◦{" "}
                <span style={{ color: "grey", fontSize: 14 }}>
                  Last update: {new Date(node.updatedAt).toDateString()}{" "}
                </span>
                {node.isRemoved && (
                  <span style={{ color: "red", fontSize: 14 }}>
                    {"◦ S3 DELETED"}
                  </span>
                )}
              </div>
            }
          />
          <ListItemSecondaryAction>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                width: 220,
                justifyContent: "space-around",
              }}
            >
              <Tooltip title="Toggle featured scene">
                <span>
                  <IconButton
                    aria-label="featured"
                    disabled={!checked}
                    style={{ padding: 0 }}
                    onClick={() =>
                      checked && this.toggleFeatured(featuredChecked)
                    }
                  >
                    <StarIcon
                      fontSize="inherit"
                      style={{ color: featuredChecked ? "#387f86" : "#aaaaaa" }}
                    />
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip
                title={
                  <div>
                    <Typography paragraph className={classes.typo}>
                      <b>Last update: </b>
                      {moment(node.updatedAt).format("LLL")}
                    </Typography>
                    <Typography paragraph className={classes.typo}>
                      <b>Size: </b>
                      {(node.size / 1000000000).toFixed(2)} GB
                    </Typography>
                    <Typography paragraph className={classes.typo}>
                      <b>Tracks: </b>
                      {node.tracks}
                    </Typography>
                  </div>
                }
                F
              >
                <InfoIcon style={{ color: "#757575" }} />
              </Tooltip>
              {permissions([], [1]) && (
                <SceneInfoButton
                  usedInListView
                  viewer={viewer}
                  playlistId={node.id}
                  updatedAt={node.updatedAt}
                  allPlaybacks={allPlaybacks}
                  allSchedule={allSchedule}
                  allAudioClients={allAudioClients}
                  allAudioDevices={allAudioDevices}
                />
              )}
              <Switch
                edge="end"
                onChange={() => this.toggleScene(checked)}
                checked={checked}
                inputProps={{ "aria-labelledby": "switch-list-label-wifi" }}
              />
            </div>
          </ListItemSecondaryAction>
        </ListItem>
      );
    }
    return null;
  }
}

SceneListView.propTypes = {
  classes: PropTypes.object.isRequired,
  viewer: PropTypes.object,
  node: PropTypes.object,
  snackbar: PropTypes.object,
  checked: PropTypes.bool,
  featuredChecked: PropTypes.bool,
  allPlaybacks: PropTypes.array,
  allSchedule: PropTypes.array,
  allAudioClients: PropTypes.array,
  allAudioDevices: PropTypes.array,
};

export default compose(withStyles(styles), withSnackbar())(SceneListView);
