import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import compose from "recompose/compose";
import { withSnackbar } from "material-ui-snackbar-provider";
import _ from "lodash";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Input from "@material-ui/core/Input";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Modal from "@material-ui/core/Modal";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import CropOriginal from "@material-ui/icons/CropOriginal";
import SwapHoriz from "@material-ui/icons/SwapHoriz";
import SettingsPower from "@material-ui/icons/SettingsPower";
import Description from "@material-ui/icons/Description";
import requestMutation from "../../mutations/requestVDScreenshotSyncRebootLogsMutation";
import updateVideoDeviceMutation from "../../mutations/updateVideoDeviceClientFormatProfileMutation";
import updateVideoDeviceDescriptionMutation from "../../mutations/updateVideoDeviceDescriptionMutation";
import DeleteDevice from "./DeleteDevice";
import NewFormat from "./NewFormat";
import permissions from "../../authentication/isPermited";

const styles = (theme) => ({
  root: {
    margin: "4px 0px 4px 0px",
  },
  card: {
    display: "flex",
    flexDirection: "column",
  },
  expansion: {
    flex: "auto",
    boxShadow:
      "0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12)",
  },
  expansionSummary: {
    minHeight: 16,
    height: 24,
  },
  expansionDetails: {
    padding: "8px 8px 8px 8px",
    display: "flex",
    flexDirection: "column",
  },
  actionsContainer: {
    margin: theme.spacing(1),
  },
  button: {
    marginRight: theme.spacing(1),
  },
  button2: {
    flex: "auto",
  },
  horizontalContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  verticalContainer60: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    width: "40%",
  },
  verticalContainer: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
  },
  typo1: {
    flex: "auto",
    display: "flex",
    alignItems: "center",
  },
  typo2: {
    paddingLeft: 4,
  },
  actions: {
    flexWrap: "wrap",
    display: "flex",
    background: "#f7f7f7",
    width: "60%",
  },
  screenshot: {
    width: "60%",
    margin: "auto",
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  paper: {
    margin: "auto",
    width: 700,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: "8px 16px 16px 16px",
  },
  modal: {
    top: "45%",
  },
});

const arraySort = (a, b) => {
  try {
    const nameA = a.toUpperCase(); // ignore upper and lowercase
    const nameB = b.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    // names must be equal
    return 0;
  } catch (e) {
    console.log("UserActions:", e);
  }
};

class DeviceActions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorElClient: null,
      anchorElFormat: null,
      anchorElProfile: null,
      currentClient: null,
      currentFormat: null,
      currentProfile: null,
      modalType: false,
      description: this.props.deviceControl.description,
    };
    this.handleSave = this.handleSave.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleClickListItemClients = this.handleClickListItemClients.bind(
      this
    );
    this.handleClickListItemFormat = this.handleClickListItemFormat.bind(this);
    this.handleClickListItemProfile = this.handleClickListItemProfile.bind(
      this
    );
    this.handleClose = this.handleClose.bind(this);
    this.handleChangeDescription = this.handleChangeDescription.bind(this);
    this.handleData = this.handleData.bind(this);
    this.handleClear = this.handleClear.bind(this);
    this.handleNewFormat = this.handleNewFormat.bind(this);
    this.handleMutation = this.handleMutation.bind(this);
    this.enterPressed = this.enterPressed.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.deviceControl !== nextProps.deviceControl) {
      this.setState({
        anchorElClient: null,
        anchorElFormat: null,
        anchorElProfile: null,
        currentClient: null,
        currentFormat: null,
        currentProfile: null,
        modalType: false,
        description: nextProps.deviceControl.description,
      });
    }
  }

  handleClickListItemClients(event) {
    this.setState({ anchorElClient: event.currentTarget });
  }

  handleClickListItemFormat(event) {
    this.setState({ anchorElFormat: event.currentTarget });
  }

  handleClickListItemProfile(event) {
    this.setState({ anchorElProfile: event.currentTarget });
  }

  handleClose() {
    this.setState({
      anchorElClient: null,
      anchorElFormat: null,
      anchorElProfile: null,
      modalType: false,
    });
  }

  handleSave() {
    const { deviceControl } = this.props;
    const { currentProfile, currentClient, currentFormat } = this.state;
    updateVideoDeviceMutation(
      deviceControl.id,
      currentClient ? currentClient.id : false || deviceControl.clientId,
      currentFormat || deviceControl.format,
      currentProfile || deviceControl.profile,
      () => this.props.snackbar.showMessage("Video Device updated")
    );
  }

  handleDelete() {
    this.setState({ modalType: "delete" });
  }

  handleNewFormat() {
    this.setState({ modalType: "newFormat" });
  }

  handleClear() {
    this.setState({
      anchorElClient: null,
      anchorElFormat: null,
      anchorElProfile: null,
      currentClient: null,
      currentFormat: null,
      currentProfile: null,
    });
  }

  handleMutation(type, message) {
    requestMutation(this.props.deviceControl.id, type, () =>
      this.props.snackbar.showMessage(message)
    );
  }

  handleData(item, type) {
    this.setState({ [type]: item });
    if (type === "currentClient" || type === "currentFormat") {
      this.setState({
        currentProfile: {
          name: "Please Select",
          id: "none",
        },
      });
    }
    this.handleClose();
  }

  handleChangeDescription(event) {
    this.setState({ description: event.target.value });
  }

  enterPressed(event) {
    const { deviceControl } = this.props;
    const { description } = this.state;
    const code = event.keyCode || event.which;
    if (code === 13) {
      updateVideoDeviceDescriptionMutation(deviceControl.id, description, () =>
        this.props.snackbar.showMessage("Video Device description updated")
      );
    }
  }

  render() {
    const { classes, viewer, deviceControl } = this.props;
    const {
      anchorElClient,
      currentClient,
      anchorElFormat,
      currentFormat,
      anchorElProfile,
      currentProfile,
      modalType,
      description,
    } = this.state;
    const allVideoClientsSorted = [...viewer.allVideoClients.edges]
      .filter((item) => item && item.node)
      .sort((a, b) =>
        a.node && b.node ? arraySort(a.node.name, b.node.name) : null
      );
    const allVideoProfilesSorted = [...viewer.allVideoProfiles.edges]
      .filter((item) => item && item.node)
      .sort((a, b) =>
        a.node && b.node ? arraySort(a.node.name, b.node.name) : null
      );
    const defaultImage =
      "https://upload.wikimedia.org/wikipedia/commons/a/ac/No_image_available.svg";

    const selectedProfile = allVideoProfilesSorted.filter((item) =>
      deviceControl.profile
        ? item.node.id === deviceControl.profile.profileId
        : false
    )[0]
      ? allVideoProfilesSorted.filter((item) =>
          deviceControl.profile
            ? item.node.id === deviceControl.profile.profileId
            : false
        )[0].node.name
      : null;

    const videoProfiles = _.uniq(
      viewer.allVideoDevices.edges
        .filter((item) => item && item.node)
        .map((item) => item.node.format)
    )
      .filter((item) => item)
      .sort((a, b) => arraySort(a, b));

    return (
      <div className={classes.root}>
        <Card className={classes.card}>
          <CardContent>
            <div className={classes.horizontalContainer}>
              <Typography className={classes.typo1} variant="h6">
                Device Actions
              </Typography>
              <div className={classes.actions}>
                <Tooltip
                  id="tooltip-bottom"
                  title="Take Screenshot"
                  placement="bottom"
                  enterTouchDelay={200}
                >
                  <Button
                    variant="text"
                    size="small"
                    color="primary"
                    className={classes.button2}
                    onClick={() =>
                      this.handleMutation(
                        "takeScreenShot",
                        "Screenshot performed"
                      )
                    }
                  >
                    <CropOriginal />
                  </Button>
                </Tooltip>
                <Tooltip
                  id="tooltip-bottom"
                  title="Sync"
                  placement="bottom"
                  enterTouchDelay={200}
                >
                  <Button
                    variant="text"
                    size="small"
                    color="primary"
                    className={classes.button2}
                    onClick={() => this.handleMutation("sync", "Syncing...")}
                  >
                    <SwapHoriz />
                  </Button>
                </Tooltip>
                <Tooltip
                  id="tooltip-bottom"
                  title="Reboot"
                  placement="bottom"
                  enterTouchDelay={200}
                >
                  <Button
                    variant="text"
                    size="small"
                    color="primary"
                    className={classes.button2}
                    onClick={() =>
                      this.handleMutation("reboot", "Rebooting...")
                    }
                  >
                    <SettingsPower />
                  </Button>
                </Tooltip>
                <Tooltip
                  id="tooltip-bottom"
                  title="Request Logs"
                  placement="bottom"
                  enterTouchDelay={200}
                >
                  <Button
                    variant="text"
                    size="small"
                    color="primary"
                    className={classes.button2}
                    onClick={() =>
                      this.handleMutation("uploadLogs", "Logs requested...")
                    }
                  >
                    <Description />
                  </Button>
                </Tooltip>
              </div>
            </div>
            <div className={classes.horizontalContainer}>
              <div className={classes.verticalContainer60}>
                {/* First List */}
                <List dense component="nav">
                  <ListItem
                    button
                    aria-haspopup="true"
                    aria-controls="clients-menu"
                    aria-label="Video Client"
                    onClick={this.handleClickListItemClients}
                  >
                    <ListItemText
                      primary="Video Client"
                      secondary={
                        currentClient
                          ? currentClient.name
                          : null ||
                            allVideoClientsSorted.filter(
                              (item) => item.node.id === deviceControl.clientId
                            )[0].node.name
                      }
                    />
                  </ListItem>
                </List>
                <Menu
                  className={classes.menu}
                  id="lock-menu"
                  anchorEl={anchorElClient}
                  open={Boolean(anchorElClient)}
                  onClose={this.handleClose}
                >
                  {allVideoClientsSorted &&
                    allVideoClientsSorted.map((item) => (
                      <MenuItem
                        id={item.node.id}
                        className={classes.menuItem}
                        key={item.node.id}
                        dense
                        selected={item.node.id === deviceControl.clientId}
                        value={item.node.name}
                        onClick={() =>
                          this.handleData(item.node, "currentClient")
                        }
                      >
                        <b>{item.node.name}</b>
                      </MenuItem>
                    ))}
                </Menu>
                {/* Second List */}
                <List dense component="nav">
                  <ListItem
                    button
                    aria-haspopup="true"
                    aria-controls="format-menu"
                    aria-label="Format"
                    onClick={this.handleClickListItemFormat}
                  >
                    <ListItemText
                      primary="Video Format"
                      secondary={currentFormat || deviceControl.format}
                    />
                  </ListItem>
                </List>
                <Menu
                  className={classes.menu}
                  id="lock-menu"
                  anchorEl={anchorElFormat}
                  open={Boolean(anchorElFormat)}
                  onClose={this.handleClose}
                >
                  <MenuItem
                    id="New Format"
                    className={classes.menuItem}
                    dense
                    value={0}
                    onClick={this.handleNewFormat}
                  >
                    <b>Add New Format</b>
                  </MenuItem>
                  <Divider />
                  {videoProfiles.map((item) => (
                    <MenuItem
                      id={item}
                      className={classes.menuItem}
                      key={item}
                      dense
                      selected={item === deviceControl.format}
                      value={item}
                      onClick={() => this.handleData(item, "currentFormat")}
                    >
                      {item}
                    </MenuItem>
                  ))}
                </Menu>
                {/* Third List */}
                <List dense component="nav">
                  <ListItem
                    button
                    aria-haspopup="true"
                    aria-controls="profile-menu"
                    aria-label="Video Profile"
                    onClick={this.handleClickListItemProfile}
                  >
                    <ListItemText
                      primary="Video Profile"
                      secondary={
                        currentProfile
                          ? currentProfile.name
                          : null || selectedProfile
                      }
                    />
                  </ListItem>
                </List>
                <Menu
                  className={classes.menu}
                  id="lock-menu"
                  anchorEl={anchorElProfile}
                  open={Boolean(anchorElProfile)}
                  onClose={this.handleClose}
                >
                  {allVideoProfilesSorted &&
                    allVideoProfilesSorted
                      .filter(
                        (item) =>
                          (currentClient
                            ? item.node.clientId === currentClient.id
                            : item.node.clientId === deviceControl.clientId) &&
                          (currentFormat
                            ? item.node.format === currentFormat
                            : item.node.format === deviceControl.format)
                      )
                      .map((item) => (
                        <MenuItem
                          id={item.node.id}
                          className={classes.menuItem}
                          key={item.node.id}
                          dense
                          selected={
                            deviceControl.profile
                              ? item.node.id === deviceControl.profile.profileId
                              : null
                          }
                          value={item.node.name}
                          onClick={() =>
                            this.handleData(item.node, "currentProfile")
                          }
                        >
                          <b>{`${item.node.name} - ${item.node.format}`}</b>
                        </MenuItem>
                      ))}
                </Menu>
              </div>
              <div className={classes.screenshot}>
                <CardMedia
                  className={classes.media}
                  image={defaultImage}
                  title="Screenshot"
                />
              </div>
            </div>
            <Typography
              className={classes.typo2}
              variant="body2"
              color="textSecondary"
            >
              <b>Description </b>
              <FormControl aria-describedby="description-helper-text">
                <Input
                  value={description}
                  inputProps={{
                    "aria-label": "Description",
                  }}
                  onChange={this.handleChangeDescription}
                  onKeyPress={this.enterPressed}
                />
                <FormHelperText id="description-helper-text">
                  Press ENTER to confirm change
                </FormHelperText>
              </FormControl>
            </Typography>
          </CardContent>
          <Divider />
          <div className={classes.actionsContainer}>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={this.handleSave}
              disabled={currentProfile ? currentProfile.id === "none" : true}
            >
              Save
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={this.handleClear}
            >
              Clear
            </Button>
            {permissions([], [], [1]) && (
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={this.handleDelete}
              >
                Delete
              </Button>
            )}
          </div>
        </Card>
        <Modal
          aria-labelledby="delete-modal"
          aria-describedby="delete-modal"
          open={modalType === "delete"}
          onClose={this.handleClose}
          className={classes.modal}
        >
          <div className={classes.paper}>
            {permissions([], [], [1]) && (
              <DeleteDevice
                deviceId={deviceControl.id}
                deviceName={deviceControl.name}
                handleClose={this.handleClose}
              />
            )}
          </div>
        </Modal>
        <Modal
          open={modalType === "newFormat"}
          aria-labelledby="newFormat-modal"
          aria-describedby="newFormat-modal"
          onClose={this.handleClose}
          className={classes.modal}
        >
          <div className={classes.paper}>
            {permissions([], [], [1]) && (
              <NewFormat
                deviceId={deviceControl.id}
                deviceName={deviceControl.name}
                handleClose={this.handleClose}
              />
            )}
          </div>
        </Modal>
      </div>
    );
  }
}

DeviceActions.defaultProps = {
  appStatus: {},
};

DeviceActions.propTypes = {
  classes: PropTypes.object.isRequired, //eslint-disable-line
  viewer: PropTypes.object.isRequired, //eslint-disable-line
  snackbar: PropTypes.object, //eslint-disable-line
  deviceControl: PropTypes.object.isRequired, //eslint-disable-line
};

export default compose(withStyles(styles), withSnackbar())(DeviceActions);
