/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type _deviceStatusType = "ACTIVE" | "INACTIVE" | "REGISTERED" | "UNREGISTERED" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type dashboard_viewer$ref: FragmentReference;
declare export opaque type dashboard_viewer$fragmentType: dashboard_viewer$ref;
export type dashboard_viewer = {|
  +allUsers: {|
    +edges: ?$ReadOnlyArray<?{|
      +node: {|
        +id: string,
        +status: ?{|
          +online: boolean
        |},
        +profile: ?{|
          +name: ?string
        |},
        +emails: ?$ReadOnlyArray<?{|
          +address: ?string
        |}>,
        +rolesConsole: ?{|
          +roleAccess: ?number,
          +roleAudio: ?number,
          +roleVideo: ?number,
        |},
        +services: ?{|
          +google: ?{|
            +email: ?string
          |},
          +facebook: ?{|
            +email: ?string
          |},
        |},
        +companyName: ?string,
        +serviceType: ?$ReadOnlyArray<?string>,
        +defaultServiceType: ?string,
      |}
    |}>
  |},
  +allAudioDevices: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: {|
        +id: string,
        +name: ?string,
        +appUpTime: ?string,
        +lastOnline: ?number,
        +goLiveDate: ?number,
        +deviceStatus: ?_deviceStatusType,
        +appStatus: ?{|
          +status: ?string,
          +message: ?string,
          +time: ?string,
          +description: ?string,
        |},
        +latitude: ?number,
        +longitude: ?number,
      |}
    |}>
  |},
  +allVideoDevices: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: {|
        +name: ?string,
        +lastOnline: ?number,
      |}
    |}>
  |},
  +allVideoCampaigns: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: {|
        +id: string,
        +name: string,
        +description: ?string,
        +clientId: string,
        +goLiveDate: any,
        +deviceIds: ?$ReadOnlyArray<string>,
        +profileIds: ?$ReadOnlyArray<string>,
        +onHold: boolean,
      |}
    |}>
  |},
  +$refType: dashboard_viewer$ref,
|};
export type dashboard_viewer$data = dashboard_viewer;
export type dashboard_viewer$key = {
  +$data?: dashboard_viewer$data,
  +$fragmentRefs: dashboard_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "email",
    "storageKey": null
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastOnline",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "goLiveDate",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "dashboard_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "UserConnection",
      "kind": "LinkedField",
      "name": "allUsers",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "UserEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "User",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Status",
                  "kind": "LinkedField",
                  "name": "status",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "online",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "UserProfile",
                  "kind": "LinkedField",
                  "name": "profile",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "UserEmails",
                  "kind": "LinkedField",
                  "name": "emails",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "address",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "RolesConsole",
                  "kind": "LinkedField",
                  "name": "rolesConsole",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "roleAccess",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "roleAudio",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "roleVideo",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "UserServices",
                  "kind": "LinkedField",
                  "name": "services",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Google",
                      "kind": "LinkedField",
                      "name": "google",
                      "plural": false,
                      "selections": (v2/*: any*/),
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Facebook",
                      "kind": "LinkedField",
                      "name": "facebook",
                      "plural": false,
                      "selections": (v2/*: any*/),
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "companyName",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "serviceType",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "defaultServiceType",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AudioDeviceConnection",
      "kind": "LinkedField",
      "name": "allAudioDevices",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AudioDeviceEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "AudioDevice",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "appUpTime",
                  "storageKey": null
                },
                (v3/*: any*/),
                (v4/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "deviceStatus",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "AppStatus",
                  "kind": "LinkedField",
                  "name": "appStatus",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "status",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "message",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "time",
                      "storageKey": null
                    },
                    (v5/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "latitude",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "longitude",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "VideoDeviceConnection",
      "kind": "LinkedField",
      "name": "allVideoDevices",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "VideoDeviceEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "VideoDevice",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                (v3/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "VideoCampaignConnection",
      "kind": "LinkedField",
      "name": "allVideoCampaigns",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "VideoCampaignEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "VideoCampaign",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                (v5/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "clientId",
                  "storageKey": null
                },
                (v4/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "deviceIds",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "profileIds",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "onHold",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd82a6bce0932e8757b71a49a4456e2be';

module.exports = node;
