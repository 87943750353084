import { commitMutation } from "react-relay";
import graphql from 'babel-plugin-relay/macro';
import Environment from "../../environment";

const mutation = graphql`
  mutation updateReadStatusMutation(
    $id: String!
    $userId: String!
    $checked: Boolean!
  ) {
    updateReadStatus(id: $id, userId: $userId, checked: $checked) {
      id
    }
  }
`;

export default (id, userId, checked) => {
  const variables = {
    id,
    userId,
    checked,
  };

  commitMutation(Environment, {
    mutation,
    variables,
    onCompleted: () => {},
    onError: (err) => console.error(err), // eslint-disable-line
  });
};
