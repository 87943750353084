import { commitMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import Environment from "../../environment";

const mutation = graphql`
  mutation updateAudioDeviceNameMutation(
    $deviceId: String!
    $newName: String!
    $newDescription: String
  ) {
    updateAudioDeviceName(
      deviceId: $deviceId
      newName: $newName
      newDescription: $newDescription
    ) {
      id
    }
  }
`;

export default (deviceId, newName, newDescription, callback) => {
  const variables = {
    deviceId,
    newName,
    newDescription,
  };

  commitMutation(Environment, {
    mutation,
    variables,
    onCompleted: (response) => {
      const id = response.updateAudioDeviceName.id;
      callback(id);
    },
    onError: (err) => console.error(err), // eslint-disable-line
  });
};
