import React, { Suspense } from "react";
import LoadingPage from "../ui/LoadingPage";
import SongReportingList from "./SongReportingList";

//wrap the component in a function that takes the props as an argument
const SongReporting = (props) => {
  const { match } = props;
  //return the component
  return (
    <Suspense fallback={<LoadingPage />}>
      <SongReportingList routeName={match.route.name} />
    </Suspense>
  );
};

export default SongReporting;
