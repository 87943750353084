/**
 * External dependencies
 */

import React, { useState, useContext } from "react";
import { withStyles } from "@material-ui/core/styles";
import { TreeItem } from "@material-ui/lab";
import { Typography, IconButton } from "@material-ui/core";
import { AddCircle, Edit, Delete } from "@material-ui/icons";

/**
 * Internal dependencies
 */

import MenuModal from "../menuColumn/MenuModal";
import { CurrentRouteContext } from "../../hooks/currentRouteProvider";

const styles = (theme) => ({
  content: {
    color: theme.palette.text.secondary,
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    "$expanded > &": {
      fontWeight: theme.typography.fontWeightRegular,
    },
  },
  group: {},
  expanded: {},
  selected: {},
  label: {
    fontWeight: "inherit",
    color: "inherit",
  },
  labelRoot: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0.5, 0),
  },
  labelIcon: {
    marginRight: theme.spacing(1),
  },
  labelText: {
    fontWeight: "inherit",
    flexGrow: 1,
  },
});

const StyledTreeItem = (props) => {
  const {
    labelText,
    labelicon: LabelIcon,
    labelInfo,
    color,
    bgColor,
    classes,
    route,
    disabled,
    ...other
  } = props;
  const [openModal, setOpenModal] = useState(false);

  const { setCurrentRoute, setRouteDisabled } = useContext(CurrentRouteContext);

  const handleClose = () => {
    setOpenModal(false);
  };
  const handleOpen = (modalType) => {
    setOpenModal(modalType);
  };

  return (
    <TreeItem
      label={
        <div className={classes.labelRoot} style={{ color }}>
          <LabelIcon color="inherit" className={classes.labelIcon} />
          <Typography variant="body2" className={classes.labelText}>
            {labelText}
          </Typography>
          <Typography variant="caption" color="inherit">
            {labelInfo}
          </Typography>
          {!disabled && (
            <IconButton
              edge="end"
              aria-label="edit-route-button"
              size="small"
              onClick={() => handleOpen("createFolder")}
            >
              <AddCircle />
            </IconButton>
          )}
          {!disabled && (
            <IconButton
              edge="end"
              aria-label="edit-route-button"
              size="small"
              onClick={() => handleOpen("editFolder")}
            >
              <Edit />
            </IconButton>
          )}
          {!disabled && (
            <IconButton
              edge="end"
              aria-label="edit-route-button"
              size="small"
              onClick={() => handleOpen("deleteFolder")}
            >
              <Delete />
            </IconButton>
          )}
          {openModal && (
            <MenuModal
              openModal={openModal}
              callbackModal={handleClose}
              route={route}
            />
          )}
        </div>
      }
      style={{
        background: bgColor,
      }}
      classes={{
        root: classes.root,
        content: classes.content,
        expanded: classes.expanded,
        selected: classes.selected,
        group: classes.group,
        label: classes.label,
      }}
      {...other}
      onClick={() => {
        setCurrentRoute(route);
        setRouteDisabled(disabled);
      }}
    />
  );
};

export default withStyles(styles)(StyledTreeItem);
