import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles({
  root: { margin: "16px 16px 8px 16px" },
  horizontalContainer: {
    display: "flex",
    flexWrap: "wrap",
    alignSelf: "center",
  },
  typo: {
    width: "fit-content",
    margin: 8,
  },
  button: {
    width: "fit-content",
    margin: "8px 0px 8px 8px",
  },
});

const PlaylistToggleModal = ({
  open,
  callback,
  handleClose,
  playlistsArray,
}) => {
  const classes = useStyles();
  return (
    <Dialog aria-labelledby="toggle-playlist" open={open}>
      {playlistsArray.map((item) => (
        <div key={item?.playlist?.id} className={classes.root}>
          <Typography className={classes.typo} align="left" variant="h6">
            Playlist <b>{item?.playlist?.name}</b> is still assigned in:
          </Typography>
          {item.smartPlaylists.length > 0 && (
            <>
              <Typography
                className={classes.typo}
                style={{ marginLeft: 16 }}
                align="left"
              >
                Smart Playlists:
              </Typography>
              <ul>
                {item.smartPlaylists.map((smartPlaylist) => (
                  <li>{smartPlaylist?.name}</li>
                ))}
              </ul>
            </>
          )}
          {item.schedules.length > 0 && (
            <>
              <Typography
                className={classes.typo}
                style={{ marginLeft: 16 }}
                align="left"
              >
                Schedules:
              </Typography>
              <ul>
                {item.schedules.map((schedule) => (
                  <li>{schedule?.name}</li>
                ))}
              </ul>
            </>
          )}
        </div>
      ))}
      <div className={classes.horizontalContainer}>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={callback}
        >
          Remove Playlist{playlistsArray.length > 1 && "s"}
        </Button>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          style={{ marginRight: 8 }}
          onClick={handleClose}
        >
          Back
        </Button>
      </div>
    </Dialog>
  );
};

export default PlaylistToggleModal;
