/**
 * External dependencies
 */
import React, { useState, useEffect, useContext } from "react";
import { useLazyLoadQuery } from "react-relay";
import { withStyles } from "@material-ui/core/styles";
import {
  List,
  TextField,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Divider,
  Typography,
} from "@material-ui/core";
import { AddCircle } from "@material-ui/icons";
import _ from "lodash";

/**
 * Internal dependencies
 */

import { SONOS_GET_MENU } from "../../queries/getSonosMenu";
import { QSYS_GET_MENU } from "../../queries/getQsysMenu";
import MenuModal from "../menuColumn/MenuModal";
import TreeList from "./TreeList";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import Tooltip from "@material-ui/core/Tooltip";
import { CurrentClientContext } from "../../hooks/currentClientProvider";

const styles = (theme) => ({
  list: {
    width: "40%",
    height: "100%",
    minWidth: 300,
    padding: 4,
  },
  horizontalContainer: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  menusView: {
    height: "calc(100% - 64px)",
    overflowY: "scroll",
    padding: "0px 8px 0px 8px",
    marginTop: 2,
    borderWidth: 2,
    borderStyle: "solid",
    borderColor: "lightgrey",
    borderRadius: 16,
  },
  textField: {
    width: "100%",
  },
  treeView: {
    color: theme.palette.text.secondary,
    "&:hover > $content": {
      backgroundColor: theme.palette.action.hover,
    },
    "&:focus > $content, &$selected > $content": {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`,
      color: "var(--tree-view-color)",
    },
    "&:focus > $content $label, &:hover > $content $label, &$selected > $content $label":
      {
        backgroundColor: "transparent",
      },
  },
});

const createTree = (schema = []) => {
  if (schema?.length > 0) {
    let schemaArray = [...schema].map((item, index) => {
      return Object.assign({}, item, { orderRank: index });
    });
    for (let i = 0; i < schemaArray.length; i++) {
      schemaArray[i] = Object.assign({ children: [] }, schemaArray[i]);
    }
    let counter1 = 0;
    let counter2 = 0;
    do {
      counter1 = schemaArray.filter((item) => item).length;
      for (let i = 0; i < schemaArray.length; i++) {
        if (schemaArray[i]?.parentId !== 0 && schemaArray[i]) {
          // if found !== undefined means the node is a parent
          const foundChildren = schemaArray.find(
            (item) => item?.parentId === schemaArray[i]?.pathId
          );
          if (!foundChildren) {
            const parentIndex = schemaArray.findIndex((item) =>
              item ? item.pathId === schemaArray[i].parentId : false
            );
            schemaArray[parentIndex]?.children?.push(schemaArray[i]);
            schemaArray[parentIndex]?.children?.sort(
              (a, b) => a?.orderRank - b?.orderRank
            );
            schemaArray[i] = null;
          }
        }
      }
      counter2 = schemaArray.filter((item) => item).length;
    } while (counter1 !== counter2);
    return schemaArray.filter((item) => item);
  } else return [];
};

const SchemaColumn = ({ classes, menu, menuExtension }) => {
  const { audioProvider } = useContext(CurrentClientContext);
  const [refetch, setRefetch] = useState(new Date());
  const loadedQuery =
    audioProvider === "sonos" ? SONOS_GET_MENU : QSYS_GET_MENU;
  const { viewer } = useLazyLoadQuery(
    loadedQuery,
    { menuId: menu.id },
    { fetchPolicy: "store-and-network", fetchKey: refetch }
  );
  const getMenu = viewer?.getSonosMenu || viewer?.getQsysMenu;

  const fullMenuExtension = getMenu?.childrenMenus?.find(
    (item) => item?.id === menuExtension?.id
  );

  const [searchValue, setSearchValue] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [folderList, setFolderList] = useState();
  const [folderListExtension, setFolderListExtension] = useState();

  useEffect(() => {
    const regex = new RegExp(searchValue);

    const schema =
      searchValue === ""
        ? getMenu?.schema
        : getMenu?.schema.map((item) => {
            return Object.assign({}, item, { parentId: 0 });
          });

    if (menuExtension?.id) {
      const fullMenuExtension = getMenu?.childrenMenus?.find(
        (item) => item?.id === menuExtension?.id
      );

      const schemaExtension =
        searchValue === ""
          ? fullMenuExtension?.schema
          : fullMenuExtension?.schema?.map((item) => {
              return Object.assign({}, item, { parentId: 0 });
            });

      setFolderListExtension(
        createTree(
          schemaExtension?.filter((item) => item?.name?.search(regex) !== -1) ||
            []
        )
      );
    }

    setFolderList(
      createTree(
        schema?.filter((item) => item?.name?.search(regex) !== -1) || []
      )
    );
  }, [
    getMenu?.schema,
    searchValue,
    menuExtension?.id,
    fullMenuExtension?.schema,
  ]);

  const handleChange = (event) => {
    setSearchValue(_.escapeRegExp(event.target.value));
  };

  const handleClose = (queryReload) => {
    queryReload && setRefetch(new Date());
    setOpenModal(false);
  };

  return (
    <div className={classes.list}>
      <TextField
        className={classes.textField}
        id="search-menu"
        label="Search path"
        value={searchValue}
        onChange={handleChange}
      />
      <List component="nav" className={classes.menusView} aria-label="contacts">
        <ListItem component="nav" aria-label="create-menu">
          <ListItemText
            primary={
              menuExtension?.id
                ? "Create extension menu folder"
                : "Create basic menu folder"
            }
          />
          <ListItemSecondaryAction>
            <IconButton
              edge="end"
              aria-label="create-menu-button"
              onClick={() => setOpenModal("createFolder")}
            >
              <AddCircle />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
        {menuExtension?.id && audioProvider === "sonos" && (
          <>
            <Divider />
            <div
              className={classes.horizontalContainer}
              style={{ justifyContent: "center" }}
            >
              <Divider />
              <Typography
                variant="body2"
                color="textSecondary"
                style={{ paddingRight: 8 }}
              >
                {"Copy client's registration url for this menu"}
              </Typography>
              <Tooltip
                title="Copy full URL for registration"
                placement="bottom"
                enterTouchDelay={200}
                onClick={() => {
                  navigator.clipboard.writeText(
                    `https://curator.activaire.com/login?loginType=sonos&register=true&exId=${menuExtension?.id}`
                  );
                }}
              >
                <IconButton
                  aria-label="generateNew"
                  className={classes.margin}
                  size="small"
                >
                  <FileCopyIcon fontSize="inherit" />
                </IconButton>
              </Tooltip>
              <Divider />
            </div>
            <Divider />
          </>
        )}

        {menuExtension?.id && folderListExtension && (
          <TreeList
            folderList={folderListExtension}
            menuId={menuExtension?.id}
          />
        )}
        {menuExtension?.id && (
          <>
            <Divider />
            <Typography
              variant="caption"
              color="secondary"
              style={{ paddingLeft: 8 }}
              align="left"
            >
              Info: Menu extension goes above.
            </Typography>
            <Divider />
          </>
        )}
        {folderList && (
          <TreeList
            folderList={folderList}
            disabled={!!menuExtension?.id}
            menuId={menu.id}
          />
        )}
      </List>
      <Divider />
      <MenuModal openModal={openModal} callbackModal={handleClose} />
    </div>
  );
};

export default withStyles(styles)(SchemaColumn);
