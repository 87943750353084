import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import InputAdornment from "@material-ui/core/InputAdornment";
import { useLazyLoadQuery } from "react-relay";
import { ALL_QSYS_DEVICES } from "../../qsys_devices/queries/allQsysDevices";
import ReactTable from "react-table";
import permissions from "../../authentication/isPermited";

const styles = (theme) => ({
  dialog: {
    padding: theme.spacing(2),
    minWidth: 400,
  },
  dialogTitle: {
    marginBottom: theme.spacing(2),
  },
  searchField: {
    margin: theme.spacing(1),
    width: "96%",
  },
  close: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  closeIcon: {
    width: 24,
    height: 24,
  },
  table: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    height: "50vh",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    gap: theme.spacing(1),
    padding: theme.spacing(2),
  },
});

// Filter methods from qsysDeviceTable.js
const caseInsensitiveFilter = (filter, row) => {
  const searchTerm = (filter.value || "").toLowerCase();
  const content = String(row[filter.id] || "").toLowerCase();
  return content.includes(searchTerm);
};

const dateFilter = (filter, row) => {
  const searchTerm = (filter.value || "").toLowerCase();
  if (!searchTerm || !row[filter.id]) return true;
  
  // Convert timestamp to formatted date string for comparison
  const dateString = new Date(parseInt(row[filter.id])).toLocaleString().toLowerCase();
  return dateString.includes(searchTerm);
};

function AddQsysDeviceModal({
  classes,
  open,
  onClose,
  onSelect,
  qsysDeviceIds,
  user,
}) {
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [queryKey, setQueryKey] = useState(0);

  // Reset state and force refetch when modal opens
  React.useEffect(() => {
    if (open) {
      setSelectedDevice(null);
      setSearchQuery("");
      setQueryKey((prev) => prev + 1);
    }
  }, [open]);

  const { viewer } = useLazyLoadQuery(
    ALL_QSYS_DEVICES,
    {},
    {
      fetchPolicy: "store-and-network",
      fetchKey: queryKey,
      networkCacheConfig: { force: true },
    }
  );

  const availableDevices =
    viewer?.allQsysDevices?.filter(
      (device) => !qsysDeviceIds?.includes(device.id)
    ) || [];

  const filteredDevices = searchQuery 
    ? availableDevices.filter((device) => {
        const searchLower = searchQuery.toLowerCase();
        return (
          device.deviceId?.toLowerCase().includes(searchLower) ||
          device.name?.toLowerCase().includes(searchLower) ||
          device.ipAddress?.toLowerCase().includes(searchLower) ||
          device.systemVersion?.toLowerCase().includes(searchLower) ||
          device.currentUser?.emails?.[0]?.address?.toLowerCase().includes(searchLower) ||
          device.currentUser?.profile?.name?.toLowerCase().includes(searchLower) ||
          device.countryCode?.toLowerCase().includes(searchLower) ||
          device.client?.name?.toLowerCase().includes(searchLower)
        );
      })
    : availableDevices;

  const handleSelect = () => {
    if (selectedDevice) {
      onSelect(selectedDevice);
      onClose();
    }
  };

  // Define columns similar to qsysDeviceTable.js
  const columns = [
    {
      Header: "Device ID",
      accessor: "deviceId",
      width: 150,
      filterMethod: caseInsensitiveFilter,
    },
    {
      Header: "Name",
      accessor: "name",
      width: 150,
      filterMethod: caseInsensitiveFilter,
    },
    {
      Header: "IP Address",
      accessor: "ipAddress",
      width: 110,
      filterMethod: caseInsensitiveFilter,
    },
    {
      Header: "System Version",
      accessor: "systemVersion",
      width: 120,
      filterMethod: caseInsensitiveFilter,
    },
    {
      id: "userEmail",
      Header: "Email",
      accessor: (a) => a.currentUser?.emails?.[0]?.address,
      width: 200,
      filterMethod: caseInsensitiveFilter,
    },
    {
      id: "userName",
      Header: "User Name",
      accessor: (a) => a.currentUser?.profile?.name || "No Name",
      width: 150,
      filterMethod: caseInsensitiveFilter,
    },
    {
      Header: "Country",
      accessor: "countryCode",
      width: 80,
      filterMethod: caseInsensitiveFilter,
    },
    {
      id: "lastHeartbeat",
      Header: "Last Heartbeat",
      accessor: "lastHeartbeat",
      Cell: (row) =>
        row.value ? new Date(parseInt(row.value)).toLocaleString() : "N/A",
      sortMethod: (a, b) => {
        if (!a && !b) return 0;
        if (!a) return 1;
        if (!b) return -1;
        return parseInt(a) - parseInt(b);
      },
      width: 160,
      filterMethod: dateFilter,
    },
    {
      id: "buildVersion",
      Header: "Plugin Build Version",
      accessor: (a) => a.plugin?.buildVersion || "N/A",
      width: 150,
      filterMethod: caseInsensitiveFilter,
    },
    {
      id: "dateCreated",
      Header: "Date Created",
      accessor: "dateCreated",
      Cell: (row) =>
        row.value ? new Date(parseInt(row.value)).toLocaleString() : "N/A",
      sortMethod: (a, b) => {
        if (!a && !b) return 0;
        if (!a) return 1;
        if (!b) return -1;
        return parseInt(a) - parseInt(b);
      },
      width: 160,
      filterMethod: dateFilter,
    },
    {
      id: "clientName",
      Header: "Client",
      accessor: (a) => a.client?.name || "None",
      width: 100,
      filterMethod: caseInsensitiveFilter,
    },
  ];

  return (
    <Dialog open={open} maxWidth="lg" fullWidth>
      <div className={classes.close}>
        <IconButton onClick={onClose}>
          <CloseIcon className={classes.closeIcon} />
        </IconButton>
      </div>

      <DialogTitle className={classes.dialogTitle}>
        Select Q-SYS Core to Add
      </DialogTitle>

      <div className={classes.dialog}>
        <TextField
          className={classes.searchField}
          variant="outlined"
          placeholder="Search devices..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />

        <div className={classes.table}>
          <ReactTable
            data={filteredDevices}
            defaultPageSize={10}
            showPageSizeOptions={true}
            pageSizeOptions={[5, 10, 20, 50]}
            filterable
            columns={columns}
            style={{
              fontSize: "13px",
              height: "100%",
            }}
            getTrProps={(state, rowInfo) => {
              const selectedId = rowInfo?.original?.id;
              return {
                onClick: () => rowInfo?.original && setSelectedDevice(rowInfo.original),
                style: {
                  background: selectedId === selectedDevice?.id ? "#DCDCDC" : "#FFFFFF",
                  cursor: "pointer",
                },
              };
            }}
          />
        </div>

        <div className={classes.buttonContainer}>
          <Button
            variant="contained"
            onClick={onClose}
            style={{ backgroundColor: "#808080", color: "white" }}
          >
            Cancel
          </Button>
          {permissions([1]) && (
            <Button
              variant="contained"
              onClick={handleSelect}
              disabled={!selectedDevice || !permissions([1])}
              style={{
                backgroundColor: "#3BABA1",
                color: "white",
              }}
            >
              Add Device
            </Button>
          )}
        </div>
      </div>
    </Dialog>
  );
}

export default withStyles(styles)(AddQsysDeviceModal);
