/**
 * @flow
 * @relayHash 2b80decaeb8fc52bdbf9dec5291d9687
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type createTagProviderData = {|
  name: string,
  description?: ?string,
|};
export type createTagMutationVariables = {|
  input: createTagProviderData
|};
export type createTagMutationResponse = {|
  +createTag: ?{|
    +id: string,
    +name: string,
    +description: ?string,
  |}
|};
export type createTagMutation = {|
  variables: createTagMutationVariables,
  response: createTagMutationResponse,
|};
*/


/*
mutation createTagMutation(
  $input: createTagProviderData!
) {
  createTag(createTagProvider: $input) {
    id
    name
    description
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "createTagProvider",
        "variableName": "input"
      }
    ],
    "concreteType": "Tag",
    "kind": "LinkedField",
    "name": "createTag",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "description",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "createTagMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "createTagMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "2b80decaeb8fc52bdbf9dec5291d9687",
    "metadata": {},
    "name": "createTagMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e5395d7d712c4da892cd3ae277cee399';

module.exports = node;
