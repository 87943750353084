/**
 * External dependencies
 */
import React, { useContext, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Divider,
  ListItemAvatar,
  Avatar,
  Checkbox,
} from "@material-ui/core";

/**
 * Internal dependencies
 */

import { CurrentMenuContext } from "../../hooks/currentMenuProvider";
import toggleSonosPlayablesMutation from "../../../mutations/toggleSonosPlayablesMutation";
import toggleQsysPlayablesMutation from "../../../mutations/toggleQsysPlayablesMutation";
import { CurrentClientContext } from "../../hooks/currentClientProvider";

const styles = {
  root: {},
};

const RouteList = ({ classes, playableList, route, searchValue }) => {
  const { audioProvider } = useContext(CurrentClientContext);
  const [checkedPlaylists, setCheckedPlaylists] = React.useState(
    route?.playlistIds || []
  );
  const [checkedScenes, setCheckedScenes] = React.useState(
    route?.sceneIds || []
  );

  const [checkedMixes, setCheckedMixes] = React.useState(
    route.mixesArray || []
  );

  const { currentMenu, currentMenuExtension } = useContext(CurrentMenuContext);

  const handledMenu = currentMenuExtension || currentMenu;

  useEffect(() => {
    setCheckedPlaylists(route?.playlistIds || []);
    setCheckedScenes(route?.sceneIds || []);
    setCheckedMixes(route?.mixesArray || []);
  }, [route]);

  const handleToggle = (value, typename) => () => {
    // Update old model with separate playlists and scenes -- will be removed
    const currentIndex =
      typename === "Playlist"
        ? checkedPlaylists?.indexOf(value)
        : checkedScenes?.indexOf(value);
    const newChecked =
      typename === "Playlist" ? [...checkedPlaylists] : [...checkedScenes];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    typename === "Playlist"
      ? setCheckedPlaylists(newChecked)
      : setCheckedScenes(newChecked);

    // update new model with mixed playlists and scenes in one array

    const typenameLowerCase = typename.toLowerCase();
    const currentMixIndex = checkedMixes.findIndex(
      (mix) => mix.mixId === value
    );
    const newCheckedMixes = [...checkedMixes];

    if (currentMixIndex === -1) {
      newCheckedMixes.push({ mixId: value, type: typenameLowerCase });
    } else {
      newCheckedMixes.splice(currentMixIndex, 1);
    }

    setCheckedMixes(newCheckedMixes);
    audioProvider === "sonos"
      ? toggleSonosPlayablesMutation(
          handledMenu.id,
          route.pathId,
          typename === "Playlist" ? newChecked : checkedPlaylists,
          typename === "Scene" ? newChecked : checkedScenes,
          newCheckedMixes,
          () => console.log("Done!")
        )
      : toggleQsysPlayablesMutation(
          handledMenu.id,
          route.pathId,
          typename === "Playlist" ? newChecked : checkedPlaylists,
          typename === "Scene" ? newChecked : checkedScenes,
          newCheckedMixes,
          () => console.log("Done!")
        );
  };
  const regex = new RegExp(searchValue, "gi");
  return (
    playableList &&
    playableList
      .filter((item) =>
        searchValue === "" ? true : item?.name?.search(regex) !== -1
      )
      .map((item) => (
        <div key={item.id}>
          <ListItem
            component="nav"
            className={classes.root}
            aria-label="menu-list-item"
          >
            <ListItemAvatar>
              <Avatar alt={item.name} src={item.image || item.cover || ""} />
            </ListItemAvatar>
            <ListItemText primary={item.name} />
            <ListItemSecondaryAction>
              <Checkbox
                edge="end"
                onChange={handleToggle(item.id, item.__typename)}
                checked={
                  checkedMixes?.findIndex((mix) => mix.mixId === item.id) !== -1
                }
                inputProps={{ "aria-labelledby": item.id }}
              />
            </ListItemSecondaryAction>
          </ListItem>
          <Divider />
        </div>
      ))
  );
};

export default withStyles(styles)(RouteList);
