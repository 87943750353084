/**
 * @flow
 * @relayHash f38a73d7c881377e219141dec054e2b9
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ServerInstance = "backup" | "primary" | "%future added value";
export type deleteWebkeyDeviceMutationVariables = {|
  deviceId: string,
  serverInstance: ServerInstance,
|};
export type deleteWebkeyDeviceMutationResponse = {|
  +deleteWebkeyDevice: ?{|
    +id: string
  |}
|};
export type deleteWebkeyDeviceMutation = {|
  variables: deleteWebkeyDeviceMutationVariables,
  response: deleteWebkeyDeviceMutationResponse,
|};
*/


/*
mutation deleteWebkeyDeviceMutation(
  $deviceId: String!
  $serverInstance: ServerInstance!
) {
  deleteWebkeyDevice(deviceId: $deviceId, serverInstance: $serverInstance) {
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "deviceId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "serverInstance"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "deviceId",
        "variableName": "deviceId"
      },
      {
        "kind": "Variable",
        "name": "serverInstance",
        "variableName": "serverInstance"
      }
    ],
    "concreteType": "WebkeyDevice",
    "kind": "LinkedField",
    "name": "deleteWebkeyDevice",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "deleteWebkeyDeviceMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "deleteWebkeyDeviceMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "f38a73d7c881377e219141dec054e2b9",
    "metadata": {},
    "name": "deleteWebkeyDeviceMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '7da617e7a518f0a551650be113309d35';

module.exports = node;
