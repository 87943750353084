import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Tooltip from "@material-ui/core/Tooltip";
import Checkbox from "@material-ui/core/Checkbox";
import CloseIcon from "@material-ui/icons/Close";
import SettingsIcon from "@material-ui/icons/Settings";

import UserDetails from "./userControl/UserDetails";
import UserActions from "./userControl/UserActions";
import UserDevicePermissions from "./userControl/UserDevicePermissions";

const styles = (theme) => ({
  root: theme.mixins.gutters({
    padding: "8px 8px 8px 8px",
    margin: "8px 8px 8px 8px",
    flex: "auto",
    minWidth: "30vw",
    backgroundColor: "#c4c4c4",
  }),
  horizontalContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  verticalContainer: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
  },
  closeIcon: {
    height: 28,
    width: 28,
    color: "#000",
  },
  close: {
    position: "relative",
    direction: "rtl",
  },
  typo1: {
    padding: 0,
  },
  typo2: {
    padding: 0,
    color: "#000",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  expansion: {
    borderRadius: 4,
    marginBottom: 8,
    flex: "auto",
    boxShadow:
      "0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12)",
  },
  expansionDetails: {
    flex: "auto",
  },
  expansionSummary: {
    padding: "0px 24px 0px 0px",
    backgroundColor: "#c4c4c4",
  },
  checked: {
    color: "primary",
  },
});

class UsersControl extends React.Component {
  constructor(props) {
    super(props);
    const userSettings =
      props.viewer.user.consoleSettings &&
      props.viewer.user.consoleSettings.users
        ? props.viewer.user.consoleSettings.users
        : {
            details: false,
            actions: true,
            permissions: true,
            logs: false,
          };
    this.state = {
      ...userSettings,
    };
    this.toggleClose = this.toggleClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const userSettings =
      nextProps.viewer.user.consoleSettings &&
      nextProps.viewer.user.consoleSettings.users
        ? nextProps.viewer.user.consoleSettings.users
        : {
            details: false,
            actions: true,
            permissions: true,
            logs: false,
          };
    this.setState({
      ...userSettings,
    });
  }

  toggleClose() {
    this.props.toggleClose();
  }

  handleChange(name) {
    this.setState({ [name]: !this.state[name] });
  }

  render() {
    const { classes, user, viewer } = this.props;
    const { details, actions, permissions, logs } = this.state;
    return (
      <Paper className={classes.root} elevation={1}>
        <div className={classes.horizontalContainer}>
          <Accordion className={classes.expansion}>
            <AccordionSummary
              className={classes.expansionSummary}
              expandIcon={
                <Tooltip
                  id="tooltip-bottom"
                  title="Options"
                  placement="bottom"
                  enterTouchDelay={200}
                >
                  <SettingsIcon style={{ color: "#000" }} />
                </Tooltip>
              }
            >
              <Typography
                variant="body1"
                color="primary"
                className={classes.typo2}
              >
                User Control
              </Typography>
              <Typography
                className={classes.typo2}
                variant="body1"
                color="textSecondary"
              >
                {`\u00A0- ${
                  user.profile.name ? user.profile.name : user.emails[0].address
                }`}
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.expansionDetails}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={details}
                      onClick={() => this.handleChange("details")}
                      classes={{
                        checked: classes.checked,
                      }}
                      value="User Details"
                    />
                  }
                  label="User Details"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={actions}
                      onClick={() => this.handleChange("actions")}
                      classes={{
                        checked: classes.checked,
                      }}
                      value="User Actions"
                    />
                  }
                  label="User Actions"
                />
              </FormGroup>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={permissions}
                      onClick={() => this.handleChange("permissions")}
                      classes={{
                        checked: classes.checked,
                      }}
                      value="User's Devices"
                    />
                  }
                  label="User's Devices"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={logs}
                      onClick={() => this.handleChange("logs")}
                      classes={{
                        checked: classes.checked,
                      }}
                      value="User's Logs"
                    />
                  }
                  label="User's Logs"
                />
              </FormGroup>
            </AccordionDetails>
          </Accordion>
          <div className={classes.close}>
            <Tooltip
              id="tooltip-bottom"
              title="Close"
              placement="bottom"
              enterTouchDelay={200}
            >
              <IconButton onClick={this.toggleClose}>
                <CloseIcon className={classes.closeIcon} />
              </IconButton>
            </Tooltip>
          </div>
        </div>
        <div>
          {details && (
            <UserDetails user={user} allSonosMenu={viewer.allSonosMenu} />
          )}
          {actions && (
            <UserActions
              viewer={viewer}
              user={user}
              closeCallback={this.toggleClose}
            />
          )}
          {permissions && <UserDevicePermissions viewer={viewer} user={user} />}
        </div>
      </Paper>
    );
  }
}

UsersControl.propTypes = {
  classes: PropTypes.object.isRequired, //eslint-disable-line
  viewer: PropTypes.object.isRequired, //eslint-disable-line
  user: PropTypes.object, //eslint-disable-line
  toggleClose: PropTypes.func, //eslint-disable-line
};

export default withStyles(styles)(UsersControl);
