/**
 * @flow
 * @relayHash 60e045a3615f3d335c9b487a191c42e4
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type crossfadeUpdateMutationVariables = {|
  deviceId: string,
  value: number,
|};
export type crossfadeUpdateMutationResponse = {|
  +crossfadeUpdate: ?{|
    +id: string,
    +crossfade: ?number,
  |}
|};
export type crossfadeUpdateMutation = {|
  variables: crossfadeUpdateMutationVariables,
  response: crossfadeUpdateMutationResponse,
|};
*/


/*
mutation crossfadeUpdateMutation(
  $deviceId: String!
  $value: Int!
) {
  crossfadeUpdate(deviceId: $deviceId, value: $value) {
    id
    crossfade
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "deviceId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "value"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "deviceId",
        "variableName": "deviceId"
      },
      {
        "kind": "Variable",
        "name": "value",
        "variableName": "value"
      }
    ],
    "concreteType": "AudioDevice",
    "kind": "LinkedField",
    "name": "crossfadeUpdate",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "crossfade",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "crossfadeUpdateMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "crossfadeUpdateMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "60e045a3615f3d335c9b487a191c42e4",
    "metadata": {},
    "name": "crossfadeUpdateMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '0f7c0c603f0c89b650fc653c3e7d4334';

module.exports = node;
