import React from "react";
import { QueryRenderer } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import PropTypes from "prop-types";
import axios from "axios";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Environment from "../../environment";
import AudioClientEdit from "./AudioClientEdit";
import editAudioClientMutation from "../mutations/editAudioClientMutation";
import updateAudioClientLogoMutation from "../mutations/updateAudioClientLogoMutation";

const queryClient = graphql`
  query AudioClientEditQuery($ID: ID!) {
    viewer {
      client(_id: $ID) {
        id
        name
        useExplicit
        noVolumeBar
        noPlayPause
      }
    }
  }
`;

const styles = (theme) => ({
  dialog: {
    background: "secondary",
    margin: theme.spacing(3),
  },
  button: {
    marginRight: theme.spacing(1),
  },
  closeIcon: {
    height: 28,
    width: 28,
  },
  close: {
    position: "relative",
    float: "right",
  },
});

class AudioClientEditQuery extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        name: "",
        selectedFile: "",
        useExplicit: false,
      },
    };
    this.handleRequestClose = this.handleRequestClose.bind(this);
    this.myCallBack = this.myCallBack.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.uploadToS3 = this.uploadToS3.bind(this);
  }

  handleRequestClose() {
    this.props.handleRequestClose();
  }

  myCallBack(data) {
    this.setState({
      data,
    });
  }

  handleEdit() {
    editAudioClientMutation(
      this.props.clientId,
      this.state.data.name,
      (this.state.data.selectedFile && this.state.data.selectedFile.name) || "",
      this.state.data.useExplicit,
      this.state.data.noVolumeBar,
      this.state.data.noPlayPause,
      async (id, signedUrl) => {
        if (id === this.props.clientId) {
          console.log("Client info changed successfully");
          console.log(signedUrl);
          if (signedUrl && signedUrl !== "") {
            await this.uploadToS3(this.state.data.selectedFile, signedUrl);
          }
          this.handleRequestClose();
        } else {
          console.log("Edit failed, contact administrator");
          this.handleRequestClose();
        }
      }
    );
  }

  async uploadToS3(file, url) {
    console.log(file, url);
    const s3Bucket = axios.create();
    await s3Bucket.put(url, file).then((response) => {
      if (response.status === 200) {
        updateAudioClientLogoMutation(this.props.clientId, file.name, () =>
          console.log("Client info changed succesfully")
        );
      }
    });
  }

  render() {
    const { classes, currentClient } = this.props;
    return (
      <QueryRenderer
        variables={{ ID: this.props.clientId }}
        environment={Environment}
        query={queryClient}
        render={({ error, props }) => {
          if (error) {
            return <div>{error.message}</div>;
          } else if (props) {
            return (
              <Dialog open={this.props.open} className={classes.dialog}>
                <div className={classes.close}>
                  <IconButton
                    aria-label="Close"
                    className={classes.close}
                    onClick={this.handleRequestClose}
                  >
                    <CloseIcon className={classes.closeIcon} />
                  </IconButton>
                </div>
                <AudioClientEdit
                  logo={currentClient?.node?.logo || ""}
                  data={props.viewer.client}
                  myCallBack={this.myCallBack}
                />
                <div className={classes.dialog}>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={this.handleRequestClose}
                    className={classes.button}
                  >
                    Cancel
                  </Button>
                  <Button
                    disabled={!this.state.data.name}
                    variant="contained"
                    color="primary"
                    onClick={this.handleEdit}
                    className={classes.button}
                  >
                    Save
                  </Button>
                </div>
              </Dialog>
            );
          }
          return <div>{}</div>;
        }}
      />
    );
  }
}

AudioClientEditQuery.propTypes = {
  classes: PropTypes.object.isRequired, //eslint-disable-line
  onRequestClose: PropTypes.func, //eslint-disable-line
  open: PropTypes.bool, //eslint-disable-line
  handleRequestClose: PropTypes.func, //eslint-disable-line
  clientId: PropTypes.string.isRequired,
  myCallBack: PropTypes.func, //eslint-disable-line
};

export default withStyles(styles)(AudioClientEditQuery);
