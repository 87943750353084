/**
 * @flow
 * @relayHash 5681738794d1ad50dee07b3032bc6e4f
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type _ModelMutationType = "CREATED" | "DELETED" | "UPDATED" | "UPDATEDCLIENT" | "%future added value";
export type _deviceStatusType = "ACTIVE" | "INACTIVE" | "REGISTERED" | "UNREGISTERED" | "%future added value";
export type AudioDeviceActiveSubscriptionVariables = {|
  token: string,
  updatedId?: ?string,
  updatedFields?: ?$ReadOnlyArray<?string>,
|};
export type AudioDeviceActiveSubscriptionResponse = {|
  +AudioDeviceActiveSubscription: ?{|
    +mutation: _ModelMutationType,
    +node: {|
      +id: string,
      +lastOnline: ?number,
      +appStatus: ?{|
        +status: ?string
      |},
      +isPlaying: ?boolean,
      +clientId: ?string,
      +macAddress: ?string,
      +name: ?string,
      +currentPlaylistId: ?string,
      +currentSceneId: ?string,
      +tags: ?$ReadOnlyArray<?string>,
      +lastSyncTime: ?string,
      +scheduleIds: ?$ReadOnlyArray<?string>,
      +isOnline: ?boolean,
      +deviceStatus: ?_deviceStatusType,
      +maximumDaysOffline: ?number,
      +maximumSongsOffline: ?number,
    |},
  |}
|};
export type AudioDeviceActiveSubscription = {|
  variables: AudioDeviceActiveSubscriptionVariables,
  response: AudioDeviceActiveSubscriptionResponse,
|};
*/


/*
subscription AudioDeviceActiveSubscription(
  $token: String!
  $updatedId: ID
  $updatedFields: [String]
) {
  AudioDeviceActiveSubscription(token: $token, updatedId: $updatedId, updatedFields: $updatedFields) {
    mutation
    node {
      id
      lastOnline
      appStatus {
        status
      }
      isPlaying
      clientId
      macAddress
      name
      currentPlaylistId
      currentSceneId
      tags
      lastSyncTime
      scheduleIds
      isOnline
      deviceStatus
      maximumDaysOffline
      maximumSongsOffline
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "token"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "updatedFields"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "updatedId"
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "token",
        "variableName": "token"
      },
      {
        "kind": "Variable",
        "name": "updatedFields",
        "variableName": "updatedFields"
      },
      {
        "kind": "Variable",
        "name": "updatedId",
        "variableName": "updatedId"
      }
    ],
    "concreteType": "AudioDeviceSubscriptionPayload",
    "kind": "LinkedField",
    "name": "AudioDeviceActiveSubscription",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "mutation",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "AudioDevice",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastOnline",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AppStatus",
            "kind": "LinkedField",
            "name": "appStatus",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isPlaying",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "clientId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "macAddress",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "currentPlaylistId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "currentSceneId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "tags",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastSyncTime",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "scheduleIds",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isOnline",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "deviceStatus",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "maximumDaysOffline",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "maximumSongsOffline",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AudioDeviceActiveSubscription",
    "selections": (v3/*: any*/),
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "AudioDeviceActiveSubscription",
    "selections": (v3/*: any*/)
  },
  "params": {
    "id": "5681738794d1ad50dee07b3032bc6e4f",
    "metadata": {},
    "name": "AudioDeviceActiveSubscription",
    "operationKind": "subscription",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '4f77a6be73d8acaf0c867bd2f09f80e6';

module.exports = node;
