/**
 * External dependencies
 */
import graphql from "babel-plugin-relay/macro";

export const QSYS_MENUS_SUBSCRIPTION = graphql`
  subscription qsysMenusSubscription(
    $token: String!
    $updatedId: ID
    $updatedFields: [String]
  ) {
    QsysMenuSubscription(
      token: $token
      updatedId: $updatedId
      updatedFields: $updatedFields
    ) {
      mutation
      node {
        id
        name
        description
        schema {
          name
          pathId
          parentId
          coverName
          cover_40
          cover_290
          cover_legacy
          playlistIds
          sceneIds
          mixesArray {
            mixId
            type
          }
          autoPopulateType
          category
          subCategory
          genres
          routeItems
          playableType
        }
        active
        dateCreated
        dateModified
        clientId
      }
    }
  }
`;
