/**
 * @flow
 * @relayHash 8864e3fd7f6f1480ceef926f4f66b18d
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ScheduleFilter = {|
  AND?: ?$ReadOnlyArray<ScheduleFilter>,
  OR?: ?$ReadOnlyArray<ScheduleFilter>,
  _id_contains?: ?string,
  name_contains?: ?string,
  clientId_contains?: ?string,
  playingPlaylists_contains?: ?string,
|};
export type ScheduleEditQueryVariables = {|
  scheduleId: ScheduleFilter
|};
export type ScheduleEditQueryResponse = {|
  +viewer: {|
    +allSchedule: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: {|
          +id: string,
          +name: ?string,
          +size: ?number,
          +injectable: ?boolean,
          +startDate: ?string,
          +endDate: ?string,
          +clientId: ?string,
          +playingPlaylists: ?$ReadOnlyArray<?{|
            +playlistId: ?string,
            +name: ?string,
            +volume: ?string,
            +startTime: ?number,
            +endTime: ?number,
            +startTimeSeconds: ?number,
            +endTimeSeconds: ?number,
            +days: ?$ReadOnlyArray<?boolean>,
            +shuffle: ?boolean,
            +filenames: ?$ReadOnlyArray<?{|
              +mixId: ?string,
              +nrOfSongs: ?string,
            |}>,
          |}>,
        |}
      |}>
    |}
  |}
|};
export type ScheduleEditQuery = {|
  variables: ScheduleEditQueryVariables,
  response: ScheduleEditQueryResponse,
|};
*/


/*
query ScheduleEditQuery(
  $scheduleId: ScheduleFilter!
) {
  viewer {
    allSchedule(filter: $scheduleId) {
      edges {
        node {
          id
          name
          size
          injectable
          startDate
          endDate
          clientId
          playingPlaylists {
            playlistId
            name
            volume
            startTime
            endTime
            startTimeSeconds
            endTimeSeconds
            days
            shuffle
            filenames {
              mixId
              nrOfSongs
            }
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "scheduleId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Viewer",
    "kind": "LinkedField",
    "name": "viewer",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "filter",
            "variableName": "scheduleId"
          }
        ],
        "concreteType": "ScheduleConnection",
        "kind": "LinkedField",
        "name": "allSchedule",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ScheduleEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Schedule",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "size",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "injectable",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "startDate",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endDate",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "clientId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PlayingPlaylist",
                    "kind": "LinkedField",
                    "name": "playingPlaylists",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "playlistId",
                        "storageKey": null
                      },
                      (v1/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "volume",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "startTime",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endTime",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "startTimeSeconds",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endTimeSeconds",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "days",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "shuffle",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Filenames",
                        "kind": "LinkedField",
                        "name": "filenames",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "mixId",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "nrOfSongs",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ScheduleEditQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ScheduleEditQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "id": "8864e3fd7f6f1480ceef926f4f66b18d",
    "metadata": {},
    "name": "ScheduleEditQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '11d9e12580f20ee436aa1c1dbd8dae79';

module.exports = node;
