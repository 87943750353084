/**
 * @flow
 * @relayHash 02f02ceebfd8194b3b9e07566782645e
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SongPlayingQueryVariables = {|
  songId: string
|};
export type SongPlayingQueryResponse = {|
  +viewer: {|
    +getSong: ?{|
      +id: string,
      +artist: ?string,
      +album: ?string,
      +title: ?string,
      +artworkUrl: ?string,
    |}
  |}
|};
export type SongPlayingQuery = {|
  variables: SongPlayingQueryVariables,
  response: SongPlayingQueryResponse,
|};
*/


/*
query SongPlayingQuery(
  $songId: String!
) {
  viewer {
    getSong(songId: $songId) {
      id
      artist
      album
      title
      artworkUrl
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "songId"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Viewer",
    "kind": "LinkedField",
    "name": "viewer",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "songId",
            "variableName": "songId"
          }
        ],
        "concreteType": "Song",
        "kind": "LinkedField",
        "name": "getSong",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "artist",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "album",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "artworkUrl",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SongPlayingQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SongPlayingQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "02f02ceebfd8194b3b9e07566782645e",
    "metadata": {},
    "name": "SongPlayingQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a5306cd2baa961568c2989ccd9bd7355';

module.exports = node;
