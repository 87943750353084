/**
 * External dependencies
 */

import React, { useState, createContext } from "react";
export const CurrentClientContext = createContext();

const ClientContextProvider = ({ children }) => {
  const [currentClient, setCurrentClient] = useState({});
  const [allPlaylists, setAllPlaylists] = useState([]);
  const [allScenes, setAllScenes] = useState([]);
  const [allClients, setAllClients] = useState([]);
  const [allMenus, setAllMenus] = useState([]);
  const [audioProvider, setAudioProvider] = useState("sonos");

  const sorByName = (a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  };

  const playableList = (selection, explicit = true) => {
    const checkIfExplicit = (item) =>
      !explicit ? (item?.explicit ?? false) === explicit : true;
    switch (selection) {
      case "playlist":
        return allPlaylists
          .sort(sorByName)
          .filter((item) => checkIfExplicit(item));
      case "scene":
        return allScenes
          .sort(sorByName)
          .filter((item) => checkIfExplicit(item));
      case "all":
        return allPlaylists
          .concat(allScenes)
          .sort(sorByName)
          .filter((item) => checkIfExplicit(item));
    }
  };

  return (
    <CurrentClientContext.Provider
      value={{
        currentClient,
        setCurrentClient,
        allPlaylists,
        setAllPlaylists,
        allScenes,
        setAllScenes,
        playableList,
        allClients,
        setAllClients,
        allMenus,
        setAllMenus,
        audioProvider,
        setAudioProvider,
      }}
    >
      {children}
    </CurrentClientContext.Provider>
  );
};
export default ClientContextProvider;
