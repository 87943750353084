/**
 * @flow
 * @relayHash f2e61148f67f03f3c65bd903401c5b30
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type MixType = "playlist" | "scene" | "%future added value";
export type ZoneReason = "pause" | "play" | "skip" | "%future added value";
export type allQsysDevicesQueryVariables = {|
  deviceIds?: ?$ReadOnlyArray<?string>
|};
export type allQsysDevicesQueryResponse = {|
  +viewer: {|
    +allQsysDevices: ?$ReadOnlyArray<?{|
      +id: string,
      +name: ?string,
      +description: ?string,
      +dateCreated: string,
      +currentLoginToken: ?string,
      +deviceId: string,
      +systemVersion: ?string,
      +systemIsEmulating: ?boolean,
      +generalSchedule: ?string,
      +isGeneralScheduleActive: ?boolean,
      +coreUptime: ?number,
      +coreDate: ?any,
      +coreTime: ?number,
      +ipAddress: ?string,
      +totalStorage: ?number,
      +availableStorage: ?number,
      +plugin: ?{|
        +version: ?string,
        +buildVersion: ?string,
      |},
      +design: ?{|
        +platform: ?string
      |},
      +currentUser: ?{|
        +id: string,
        +profile: ?{|
          +name: ?string,
          +clientId: ?string,
        |},
        +emails: ?$ReadOnlyArray<?{|
          +address: ?string
        |}>,
      |},
      +countryCode: ?string,
      +userIds: ?$ReadOnlyArray<?{|
        +id: string,
        +profile: ?{|
          +name: ?string
        |},
        +emails: ?$ReadOnlyArray<?{|
          +address: ?string
        |}>,
      |}>,
      +noOfZones: ?number,
      +lastHeartbeat: ?string,
      +client: ?{|
        +id: string,
        +name: ?string,
      |},
      +zones: ?$ReadOnlyArray<?{|
        +zoneId: number,
        +zoneName: string,
        +songCount: ?number,
        +songId: ?string,
        +currentAlbum: ?string,
        +currentTitle: ?string,
        +currentArtist: ?string,
        +currentArtwork: ?string,
        +reason: ?ZoneReason,
        +positionMillis: ?number,
        +durationMillis: ?number,
        +isScheduleActive: ?boolean,
        +schedule: ?string,
        +mixType: ?MixType,
        +mixId: ?string,
        +mixName: ?string,
        +volume: ?number,
      |}>,
    |}>
  |}
|};
export type allQsysDevicesQuery = {|
  variables: allQsysDevicesQueryVariables,
  response: allQsysDevicesQueryResponse,
|};
*/


/*
query allQsysDevicesQuery(
  $deviceIds: [String]
) {
  viewer {
    allQsysDevices(deviceIds: $deviceIds) {
      id
      name
      description
      dateCreated
      currentLoginToken
      deviceId
      systemVersion
      systemIsEmulating
      generalSchedule
      isGeneralScheduleActive
      coreUptime
      coreDate
      coreTime
      ipAddress
      totalStorage
      availableStorage
      plugin {
        version
        buildVersion
      }
      design {
        platform
      }
      currentUser {
        id
        profile {
          name
          clientId
        }
        emails {
          address
        }
      }
      countryCode
      userIds {
        id
        profile {
          name
        }
        emails {
          address
        }
      }
      noOfZones
      lastHeartbeat
      client {
        id
        name
      }
      zones {
        zoneId
        zoneName
        songCount
        songId
        currentAlbum
        currentTitle
        currentArtist
        currentArtwork
        reason
        positionMillis
        durationMillis
        isScheduleActive
        schedule
        mixType
        mixId
        mixName
        volume
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "deviceIds"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "UserEmails",
  "kind": "LinkedField",
  "name": "emails",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "address",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Viewer",
    "kind": "LinkedField",
    "name": "viewer",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "deviceIds",
            "variableName": "deviceIds"
          }
        ],
        "concreteType": "QsysDevice",
        "kind": "LinkedField",
        "name": "allQsysDevices",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "dateCreated",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "currentLoginToken",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "deviceId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "systemVersion",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "systemIsEmulating",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "generalSchedule",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isGeneralScheduleActive",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "coreUptime",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "coreDate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "coreTime",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "ipAddress",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalStorage",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "availableStorage",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Plugin",
            "kind": "LinkedField",
            "name": "plugin",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "version",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "buildVersion",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Design",
            "kind": "LinkedField",
            "name": "design",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "platform",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "currentUser",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "UserProfile",
                "kind": "LinkedField",
                "name": "profile",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "clientId",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "countryCode",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "userIds",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "UserProfile",
                "kind": "LinkedField",
                "name": "profile",
                "plural": false,
                "selections": [
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "noOfZones",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastHeartbeat",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AudioClient",
            "kind": "LinkedField",
            "name": "client",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Zone",
            "kind": "LinkedField",
            "name": "zones",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "zoneId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "zoneName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "songCount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "songId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "currentAlbum",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "currentTitle",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "currentArtist",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "currentArtwork",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "reason",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "positionMillis",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "durationMillis",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isScheduleActive",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "schedule",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "mixType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "mixId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "mixName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "volume",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "allQsysDevicesQuery",
    "selections": (v4/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "allQsysDevicesQuery",
    "selections": (v4/*: any*/)
  },
  "params": {
    "id": "f2e61148f67f03f3c65bd903401c5b30",
    "metadata": {},
    "name": "allQsysDevicesQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e7bb0fad3628c1dbeba471712d787889';

module.exports = node;
