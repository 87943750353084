import { commitMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import Environment from "../../environment";

const mutation = graphql`
  mutation installDevicePackageMutation(
    $devices: [String]!
    $packageId: String!
    $serverInstance: ServerInstance!
  ) {
    installDevicePackage(
      devices: $devices
      packageId: $packageId
      serverInstance: $serverInstance
    ) {
      id
      userId
      clientId
      type
      packageId
      reports {
        deviceid
        state
      }
      date
    }
  }
`;

export default (devices, packageId, serverInstance, callback) => {
  const variables = {
    devices,
    packageId,
    serverInstance,
  };

  commitMutation(Environment, {
    mutation,
    variables,
    onCompleted: (response) => {
      if (response && response.installDevicePackage) {
        callback(response.installDevicePackage);
      }
    },
    onError: (err) => console.error(err), // eslint-disable-line
  });
};
