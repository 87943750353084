import { commitMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import Environment from "../../environment";

const mutation = graphql`
  mutation toggleSceneToClientMutation(
    $sceneIds: [String!]!
    $type: Boolean!
    $clientId: String!
  ) {
    toggleSceneToClient(sceneIds: $sceneIds, type: $type, clientId: $clientId) {
      id
      name
      playlistIds
      playbackIds
      sceneIds
      logo
      signedUrl
      preview {
        previewId
        created
        ending
        isEnded
        isEndless
      }
      viewableCategories {
        name
        subCategories
      }
    }
  }
`;

export default (sceneIds, type, clientId, callback) => {
  const variables = {
    sceneIds,
    type,
    clientId,
  };

  commitMutation(Environment, {
    mutation,
    variables,
    onCompleted: () => {
      callback();
    },
    onError: (err) => console.error(err), // eslint-disable-line
  });
};
