import React, { PureComponent } from "react";
import propTypes from "prop-types";

class InlineError extends PureComponent {
  render() {
    return <span>{this.props.text}</span>;
  }
}
InlineError.propTypes = {
  text: propTypes.string.isRequired,
};
export default InlineError;
