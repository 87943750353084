/**
 * @flow
 * @relayHash 6e35ea9928d53d972f35ad11d1d7d988
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SearchReportsQueryVariables = {||};
export type SearchReportsQueryResponse = {|
  +viewer: {|
    +allSearchReports: ?$ReadOnlyArray<?{|
      +id: string,
      +clientId: ?string,
      +clientName: ?string,
      +userId: ?string,
      +userEmail: ?string,
      +deviceId: ?string,
      +deviceName: ?string,
      +countryCode: ?string,
      +platform: ?string,
      +searchString: ?string,
      +timestamp: ?any,
    |}>
  |}
|};
export type SearchReportsQuery = {|
  variables: SearchReportsQueryVariables,
  response: SearchReportsQueryResponse,
|};
*/


/*
query SearchReportsQuery {
  viewer {
    allSearchReports {
      id
      clientId
      clientName
      userId
      userEmail
      deviceId
      deviceName
      countryCode
      platform
      searchString
      timestamp
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Viewer",
    "kind": "LinkedField",
    "name": "viewer",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "searchReport",
        "kind": "LinkedField",
        "name": "allSearchReports",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "clientId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "clientName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "userId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "userEmail",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "deviceId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "deviceName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "countryCode",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "platform",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "searchString",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "timestamp",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "SearchReportsQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "SearchReportsQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "id": "6e35ea9928d53d972f35ad11d1d7d988",
    "metadata": {},
    "name": "SearchReportsQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '21b0614bb0f887e3a961f44c6b7bd8fb';

module.exports = node;
