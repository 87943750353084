/**
 * @flow
 * @relayHash f5f44bb2179ca2086f7779a9973bcd48
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type allAudioClientsQueryVariables = {||};
export type allAudioClientsQueryResponse = {|
  +viewer: {|
    +allClients: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: {|
          +id: string,
          +name: ?string,
          +playlistIds: ?$ReadOnlyArray<?string>,
          +sceneIds: ?$ReadOnlyArray<?string>,
        |}
      |}>
    |}
  |}
|};
export type allAudioClientsQuery = {|
  variables: allAudioClientsQueryVariables,
  response: allAudioClientsQueryResponse,
|};
*/


/*
query allAudioClientsQuery {
  viewer {
    allClients {
      edges {
        node {
          id
          name
          playlistIds
          sceneIds
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Viewer",
    "kind": "LinkedField",
    "name": "viewer",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AudioClientConnection",
        "kind": "LinkedField",
        "name": "allClients",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AudioClientEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AudioClient",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "playlistIds",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "sceneIds",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "allAudioClientsQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "allAudioClientsQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "id": "f5f44bb2179ca2086f7779a9973bcd48",
    "metadata": {},
    "name": "allAudioClientsQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '4b82d6547ecafd7dab44e5e9de767c18';

module.exports = node;
