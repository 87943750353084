/**
 * External dependencies
 */
import React, { useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TextField, Button, MenuItem } from "@material-ui/core";

/**
 * Internal dependencies
 */

import duplicateSonosMenuMutation from "../../../mutations/duplicateSonosMenuMutation";
import duplicateQsysMenuMutation from "../../../mutations/duplicateQsysMenuMutation";
import { CurrentClientContext } from "../../hooks/currentClientProvider";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: "35vh",
    margin: "auto",
    left: 0,
    right: 0,
    borderRadius: 19,
  },
  form: {
    display: "flex",
    flexDirection: "column",
  },
  textField: {
    margin: 8,
  },
  button: {
    margin: 8,
  },
  switch: {
    margin: 8,
  },
}));

export default function DuplicateMenuModal({ menu, handleClose }) {
  const { audioProvider } = useContext(CurrentClientContext);
  const classes = useStyles();
  const { currentClient, allClients, allMenus } =
    useContext(CurrentClientContext);
  const [selectedClientId, setSelectedClientId] = useState(
    currentClient?.id || ""
  );
  const [parentMenuId, setParentMenuId] = useState(menu?.parentMenuId || null);

  const handleChange = (event) => {
    setSelectedClientId(event.target.value);
  };

  const handleChangeParent = (event) => {
    setParentMenuId(event.target.value);
  };

  const handleDuplicate = () => {
    audioProvider === "sonos"
      ? duplicateSonosMenuMutation(
          menu.id,
          selectedClientId,
          parentMenuId,
          () => {
            handleClose();
          }
        )
      : duplicateQsysMenuMutation(
          menu.id,
          selectedClientId,
          parentMenuId,
          () => {
            handleClose();
          }
        );
  };

  return (
    <>
      <div className={classes.paper}>
        <h2 id="edit-menu-modal-title">
          {menu.parentMenuId
            ? "Duplicate extended menu:"
            : "Duplicate basic menu:"}
        </h2>
        <form className={classes.form} noValidate autoComplete="off">
          <TextField
            id="select-client-id"
            className={classes.textField}
            select
            label="Select"
            value={selectedClientId}
            onChange={handleChange}
            helperText="Select the destination client"
          >
            {allClients.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </TextField>
          {menu.parentMenuId && (
            <TextField
              id="select-menu-id"
              className={classes.textField}
              select
              label="Select"
              value={parentMenuId}
              onChange={handleChangeParent}
              helperText="Select basic menu"
            >
              {allMenus
                .filter((item) => item.clientId === selectedClientId)
                .map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
            </TextField>
          )}
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={handleDuplicate}
          >
            Duplicate menu
          </Button>
        </form>
      </div>
    </>
  );
}
