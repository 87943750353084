/**
 * External dependencies
 */
import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";

import { Check, Clear } from "@material-ui/icons";

/**
 * Internal dependencies
 */

import deleteSonosMenuMutation from "../../../mutations/deleteSonosMenuMutation";
import deleteQsysMenuMutation from "../../../mutations/deleteQsysMenuMutation";
import { CurrentClientContext } from "../../hooks/currentClientProvider";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 500,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: "35vh",
    margin: "auto",
    left: 0,
    right: 0,
    borderRadius: 19,
  },
}));

export default function DeleteMenuModal({ menu, handleClose }) {
  const classes = useStyles();
  const { audioProvider } = useContext(CurrentClientContext);
  const handleDelete = () => {
    audioProvider === "sonos"
      ? deleteSonosMenuMutation(menu.id, () => handleClose())
      : deleteQsysMenuMutation(menu.id, () => handleClose());
  };

  return (
    <>
      <div className={classes.paper}>
        <p>
          Are you sure you want to delete <b>{menu?.name}</b> menu?
        </p>
        <IconButton
          aria-label="set-ok"
          className={classes.button}
          onClick={handleDelete}
        >
          <Check />
        </IconButton>
        <IconButton
          aria-label="set-clear"
          className={classes.button}
          onClick={() => handleClose()}
        >
          <Clear />
        </IconButton>
      </div>
    </>
  );
}
