import React from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { withStyles } from "@material-ui/core/styles";
import compose from "recompose/compose";
import { withSnackbar } from "material-ui-snackbar-provider";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import EditIcon from "@material-ui/icons/Edit";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import ClearIcon from "@material-ui/icons/Clear";
import PlaybackEdit from "./PlaybackEdit";
import PlaybackDelete from "./PlaybackDelete";
import duplicateSmartPlaylistMutation from "../mutations/duplicateSmartPlaylistMutation";
import permissions from "../authentication/isPermited";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import InfoIcon from "@material-ui/icons/Info";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Avatar from "@material-ui/core/Avatar";
import moment from "moment";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
    minWidth: 170,
    width: 200,
    maxWidth: 230,
  },
  card: {
    maxWidth: 200,
  },
  media: {
    height: 180,
    padding: 10,
  },
  expand: {
    transform: "rotate(0deg)",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: "secondary",
  },
  flexGrow: {
    flex: "1 1 auto",
  },
  playIcon: {
    height: 28,
    width: 28,
  },
  typo: {
    marginBottom: 8,
  },
  header: {
    height: 60,
    padding: 10,
  },
  cardActions: {
    padding: 5,
  },
});

class PlaybackListView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
      showPlaybackEdit: false,
      showPlaybackDelete: false,
      anchorElEdit: false,
      responseImage: "",
      imageHash: Date.now(),
    };
    this.handleExpandClick = this.handleExpandClick.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  getData(url) {
    axios
      .get(url)
      .then((response) => {
        this.setState({
          responseImage: response.data,
          imageHash: Date.now(),
        });
      })
      .catch((error) => {});
  }

  componentDidMount() {
    const url = this.props.node.image;
    axios
      .get(url)
      .then((response) => {
        this.setState({
          responseImage: response.data,
          imageHash: Date.now(),
        });
      })
      .catch((error) => setTimeout(() => this.getData(url), 10000));
  }

  handleOpen(data) {
    this.setState({
      [data]: true,
    });
  }

  handleClose() {
    this.setState({
      showPlaybackEdit: false,
      showPlaybackDelete: false,
      imageHash: Date.now(),
    });
  }

  handleExpandClick() {
    this.setState({ expanded: !this.state.expanded });
  }

  render() {
    const { classes, node, allPlaylists, viewer } = this.props;
    const { showPlaybackEdit, showPlaybackDelete, imageHash } = this.state;
    const size = node.size / 1000000000;
    let image =
      node.image !== ""
        ? `${node.image}?${imageHash}`
        : "https://artwork.activaire.com/playlists/playlist_default.jpg";

    return (
      <ListItem key={node.id}>
        <ListItemAvatar>
          <Avatar alt="Remy Sharp" src={image} />
        </ListItemAvatar>
        <ListItemText
          id="switch-list-label-wifi"
          primary={
            <div
              style={{ dispaly: "flex", flexDirection: "row", fontSize: 16 }}
            >
              <span style={{ fontWeight: "bold" }}>{node.name}</span> ◦{" "}
              {node?.playlists?.length === 0 && (
                <span style={{ color: "red" }}>
                  EMPTY - must review contents ◦{" "}
                </span>
              )}
              <span style={{ color: "grey", fontSize: 14 }}>
                Last update:{" "}
                {moment(node.updatedAt || node.createdAt).format(
                  "DD MMM YYYY HH:mm"
                )}
              </span>
            </div>
          }
        />

        <ListItemSecondaryAction>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              width: "200px",
              justifyContent: "space-around",
            }}
          >
            <Tooltip
              title={
                <div>
                  <Typography
                    paragraph
                    type="subheading"
                    className={classes.typo}
                  >
                    Playback details:
                  </Typography>
                  <Typography paragraph className={classes.typo}>
                    Size:
                    <br />
                    {size.toFixed(2)} GB
                  </Typography>
                  <Typography className={classes.typo}>
                    Last update:
                    <br />
                    {moment(node.updatedAt || node.createdAt).format(
                      "DDD MMM YYYY "
                    )}
                  </Typography>
                </div>
              }
            >
              <InfoIcon style={{ color: "#757575" }} />
            </Tooltip>
            {permissions([], [1, 2]) && (
              <Tooltip
                id="tooltip-bottom"
                title="Edit Smart Playlist"
                placement="bottom"
                enterTouchDelay={200}
              >
                <IconButton
                  size="small"
                  onClick={() => this.handleOpen("showPlaybackEdit")}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
            )}
            {permissions([], [1, 2]) && showPlaybackEdit && (
              <PlaybackEdit
                id={node.id}
                handleRequestClose={(data) => this.handleClose(data)}
                open={showPlaybackEdit}
                data={node}
                allPlaylists={allPlaylists}
                viewer={viewer}
              />
            )}
            {permissions([], [1, 2]) && (
              <Tooltip
                id="tooltip-bottom"
                title="Duplicate Smart Playlist"
                placement="bottom"
                enterTouchDelay={200}
              >
                <IconButton
                  size="small"
                  onClick={() =>
                    duplicateSmartPlaylistMutation(node.id, () =>
                      this.props.snackbar.showMessage("Playback Duplicated")
                    )
                  }
                >
                  <FileCopyIcon />
                </IconButton>
              </Tooltip>
            )}
            {permissions([], [1, 2]) && (
              <Tooltip
                id="tooltip-bottom"
                title="Delete Smart Playlist"
                placement="bottom"
                enterTouchDelay={200}
              >
                <IconButton
                  size="small"
                  onClick={() => this.handleOpen("showPlaybackDelete")}
                >
                  <ClearIcon />
                </IconButton>
              </Tooltip>
            )}
            {permissions([], [1, 2]) && (
              <PlaybackDelete
                id={node.id}
                handleRequestClose={(data) => this.handleClose(data)}
                open={showPlaybackDelete}
              />
            )}
          </div>
        </ListItemSecondaryAction>
      </ListItem>
    );
  }
}

PlaybackListView.propTypes = {
  classes: PropTypes.object.isRequired, // eslint-disable-line
  node: PropTypes.object.isRequired, // eslint-disable-line
  snackbar: PropTypes.object, //eslint-disable-line
  allPlaylists: PropTypes.array.isRequired, // eslint-disable-line
  userRoot: PropTypes.object, // eslint-disable-line
  viewer: PropTypes.object, // eslint-disable-line
};

export default compose(withStyles(styles), withSnackbar())(PlaybackListView);
