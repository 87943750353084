/* eslint-disable react/no-array-index-key */
/* eslint-disable class-methods-use-this */

import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import AddCircleOutline from "@material-ui/icons/AddCircleOutline";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import GridList from "@material-ui/core/GridList";
import Checkbox from "@material-ui/core/Checkbox";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { TimePicker } from "antd";
import "antd/es/date-picker/style/index.css";
import "antd/es/time-picker/style/index.css";
import Tooltip from "@material-ui/core/Tooltip";
import moment from "moment";
import createScheduleMutation from "../mutations/createScheduleMutation";

const styles = (theme) => ({
  dialog: {
    background: "secondary",
    margin: theme.spacing(3),
  },
  dialogTitle: {
    padding: "0px 24px 0px 24px",
    minWidth: 230,
    maxWidth: 800,
  },
  verticalContainer: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
  },
  horizontalContainer: {
    display: "flex",
  },
  textField: {
    marginLeft: 8,
    marginTop: 0,
    flex: "auto",
  },
  textField2: {
    marginRight: 8,
    marginTop: 0,
    marginBottom: 0,
    width: 50,
  },
  textField3: {
    marginTop: 0,
    marginRight: theme.spacing(1),
  },
  textField4: {
    marginRight: 8,
    marginTop: 0,
    marginBottom: 0,
    width: 90,
  },
  formControlLabel: {
    margin: "8px 0px 0px 0px",
  },
  button: {
    margin: "8px 8px 8px 8px",
    maxWidth: 140,
  },
  closeIcon: {
    height: 28,
    width: 28,
  },
  closeIcon2: {
    height: 24,
    width: 24,
    alignSelf: "right",
  },
  close: {
    position: "relative",
    float: "right",
  },
  input: {
    display: "none",
  },
  gridList: {
    height: 500,
    width: "auto",
  },
  listItem: {
    height: 65,
    paddingLeft: 4,
  },
  listItemText: {
    maxHeight: "max-content",
    padding: "0px 0px 0px 8px",
  },
  playlistContainer: {
    margin: "0px 8px 8px 8px",
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    maxWidth: 280,
  },
  card: {
    padding: 8,
    margin: 4,
  },
  cardContent: {
    padding: 0,
  },
  cardContainer: {
    flexWrap: "wrap",
    flexDirection: "column",
    height: 400,
    width: 550,
    overflowY: "scroll",
  },
  cardActions: {
    padding: 0,
  },
  typo1: {
    paddingRight: 8,
    alignSelf: "center",
    flex: "auto",
  },
  group: {
    margin: `${theme.spacing(1)}px 0`,
    display: "contents",
  },
  checkbox: {
    width: 24,
    height: 24,
  },
  sizeIcon: {
    fontSize: 20,
  },
});

const arraySort = (a, b) => {
  try {
    const nameA = a.toUpperCase(); // ignore upper and lowercase
    const nameB = b.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    // names must be equal
    return 0;
  } catch (e) {
    console.log("ScheduleNew:", e);
  }
};

const compareBooleanArrays = (a1, a2) => {
  for (let i = 0; i < a1.length; i++) {
    if (a1[i] && a1[i] === a2[i]) return true;
  }
  return false;
};

const checkPlaylistTime = (i, addedPlaylistsTime, days) => {
  addedPlaylistsTime.map((playlist, index) => {
    playlist.error = false;
    if (playlist.startTimeSeconds >= playlist.endTimeSeconds) {
      playlist.error = true;
    }
    if (
      index !== i &&
      !(
        (addedPlaylistsTime[i].startTimeSeconds <= playlist.startTimeSeconds &&
          addedPlaylistsTime[i].endTimeSeconds <= playlist.startTimeSeconds) ||
        (addedPlaylistsTime[i].startTimeSeconds >= playlist.endTimeSeconds &&
          addedPlaylistsTime[i].endTimeSeconds >= playlist.endTimeSeconds)
      ) &&
      compareBooleanArrays(days[i], days[index])
    ) {
      playlist.error = true;
    }
    return playlist;
  });

  return addedPlaylistsTime;
};

const today = new Date();
const over3Years = new Date();
over3Years.setFullYear(today.getFullYear() + 3);

const weekdays = ["MO", "TU", "WE", "TH", "FR", "SA", "SU"];

const dateToStandard = (date) => {
  const arrayOfDate = date.split(/-/);
  const arrayOfDate1 = `${arrayOfDate[1]}/${arrayOfDate[2]}/${arrayOfDate[0]}`;
  return arrayOfDate1;
};

class ScheduleNew extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      data: {
        name: "",
        description: "",
        injectable: false,
        startDate: today.toJSON().slice(0, 10),
        endDate: over3Years.toJSON().slice(0, 10),
      },
      addedPlaylists: [],
      addedPlaylistsVolume: [],
      addedPlaylistsTime: [],
      days: [],
      radioSelect: "all",
      isAll: [],
      nonStop: [],
    };
    this.handleRequestClose = this.handleRequestClose.bind(this);
    this.handleCreate = this.handleCreate.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeSearch = this.handleChangeSearch.bind(this);
    this.handleChangeVolume = this.handleChangeVolume.bind(this);
    this.handleChangeTime = this.handleChangeTime.bind(this);
    this.handleChangeDays = this.handleChangeDays.bind(this);
    this.handleChangeRadio = this.handleChangeRadio.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleClickPlaylist = this.handleClickPlaylist.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleAll = this.handleAll.bind(this);
    this.handleNonstop = this.handleNonstop.bind(this);
  }

  handleRequestClose() {
    this.setState({
      data: {
        search: "",
        description: "",
        name: "",
        injectable: false,
        startDate: today.toJSON().slice(0, 10),
        endDate: over3Years.toJSON().slice(0, 10),
      },
      addedPlaylists: [],
      addedPlaylistsVolume: [],
      addedPlaylistsTime: [],
      days: [],
      radioSelect: "all",
      isAll: [],
      nonStop: [],
    });
    this.props.handleRequestClose();
  }

  handleCreate() {
    const playlists = this.state.addedPlaylists.map((playlist, i) => {
      const item = {
        playlistId: playlist.id,
        name: playlist.name,
        volume: this.state.addedPlaylistsVolume[i].toString(),
        startTime: Math.round(
          this.state.addedPlaylistsTime[i].startTimeSeconds / 60
        ),
        endTime: Math.round(
          this.state.addedPlaylistsTime[i].endTimeSeconds / 60
        ),
        startTimeSeconds: this.state.addedPlaylistsTime[i].startTimeSeconds,
        endTimeSeconds: this.state.addedPlaylistsTime[i].endTimeSeconds,
        days: this.state.days[i],
        shuffle: true, // TODO: this is standard TRUE
        filenames: playlist?.playlists
          ? playlist.playlists.map((file) => {
              const newFile =
                file?.id ||
                this.props.viewer.allPlaylists.edges.find(
                  (item) => item?.node?.id === file.playlistId
                );
              if (newFile) {
                const item2 = {
                  nrOfSongs: file.nrOfSongs.toString(),
                  id: newFile?.id || newFile?.node?.id,
                };
                return item2;
              }
              return {};
            })
          : [{ nrOfSongs: "1", id: playlist.id }],
      };
      return item;
    });
    const totalSizeWODuplicates = Array.from(
      new Set(this.state.addedPlaylists)
    );
    const size2 = totalSizeWODuplicates
      .map((data) => data.size)
      .reduce((total = 0, num) => total + num);
    createScheduleMutation(
      this.state.data.name,
      this.state.data.description,
      this.state.data.injectable,
      dateToStandard(this.state.data.startDate),
      dateToStandard(this.state.data.endDate),
      this.props.clientId,
      size2,
      playlists,
      (id) => console.log(id)
    );
    this.handleRequestClose();
  }

  handleChange(e) {
    this.setState({
      data: { ...this.state.data, [e.target.id]: e.target.value },
    });
  }

  handleChangeSearch(e) {
    this.setState({ [e.target.id]: e.target.value });
  }

  handleChangeVolume(i, value) {
    const addedPlaylistsVolume = [...this.state.addedPlaylistsVolume];
    addedPlaylistsVolume[i] = Number(value.target.value);
    this.setState({ addedPlaylistsVolume });
  }

  handleChangeDays(i, index) {
    const days = [...this.state.days];
    const isAll = [...this.state.isAll];

    days[i][index] = !days[i][index];

    if (days[i].includes(false)) {
      isAll[i] = false;
    } else {
      isAll[i] = true;
    }
    this.setState({ days, isAll });

    const addedPlaylistsTime = checkPlaylistTime(
      i,
      [...this.state.addedPlaylistsTime],
      days
    );

    this.setState({ addedPlaylistsTime });
  }

  handleChangeTime(i, value, value2, type) {
    const { days } = this.state;
    const addedPlaylistsT = [...this.state.addedPlaylistsTime];
    const timeArray = value2.split(":");
    const seconds =
      Number(timeArray[0]) * 3600 +
      Number(timeArray[1]) * 60 +
      Number(timeArray[2]);
    const secondsAdjusted =
      seconds === 0 && type === "endTimeSeconds" ? 1440 * 60 : seconds;
    const nonStop = [...this.state.nonStop];
    addedPlaylistsT[i][type] = secondsAdjusted;

    const addedPlaylistsTime = checkPlaylistTime(i, addedPlaylistsT, days);

    if (
      addedPlaylistsTime[i].startTimeSeconds === 0 &&
      addedPlaylistsTime[i].endTimeSeconds === 1440 * 60
    ) {
      nonStop[i] = true;
    } else {
      nonStop[i] = false;
    }

    this.setState({ addedPlaylistsTime, nonStop });
  }

  handleChangeRadio(event) {
    this.setState({ radioSelect: event.target.value });
  }

  handleClick(e) {
    e.preventDefault();
    const name = e.target.value;
    this.setState({
      data: { ...this.state.data, [name]: !this.state.data.injectable },
    });
  }

  handleClickPlaylist(data) {
    const addedPlaylists = this.state.addedPlaylists;
    const addedPlaylistsVolume = this.state.addedPlaylistsVolume;
    const addedPlaylistsTime = this.state.addedPlaylistsTime;
    const days = this.state.days;
    const isAll = this.state.isAll;
    const nonStop = this.state.nonStop;
    isAll.push(false);
    nonStop.push(false);
    addedPlaylists.push(data);
    addedPlaylistsVolume.push(14);
    days.push([false, false, false, false, false, false, false]);
    const end = addedPlaylistsTime[0]
      ? addedPlaylistsTime[addedPlaylistsTime.length - 1].endTimeSeconds
      : 0;
    addedPlaylistsTime.push({
      startTimeSeconds: end === 1440 * 60 ? 1440 * 60 : end,
      endTimeSeconds: end + 60 * 60 <= 1440 * 60 ? end + 60 * 60 : 1440 * 60,
      error: false,
    });
    this.setState({
      addedPlaylists,
      addedPlaylistsVolume,
      addedPlaylistsTime,
      days,
      isAll,
      nonStop,
    });
  }

  handleDelete(i) {
    const newArray1 = this.state.addedPlaylists;
    const newArray2 = this.state.addedPlaylistsVolume;
    const newArray3 = this.state.addedPlaylistsTime;
    const newArray4 = this.state.days;
    const isAll = this.state.isAll;
    const nonStop = this.state.nonStop;
    isAll.splice(i, 1);
    nonStop.splice(i, 1);
    newArray1.splice(i, 1);
    newArray2.splice(i, 1);
    newArray3.splice(i, 1);
    newArray4.splice(i, 1);
    this.setState({
      addedPlaylists: newArray1,
      addedPlaylistsVolume: newArray2,
      addedPlaylistsTime: newArray3,
      days: newArray4,
      isAll,
      nonStop,
    });
  }

  handleAll(i) {
    const isAll = this.state.isAll;
    const days = this.state.days;

    isAll[i] = !isAll[i];

    if (isAll[i]) {
      days[i] = [true, true, true, true, true, true, true];
    } else {
      days[i] = [false, false, false, false, false, false, false];
    }

    const addedPlaylistsTime = checkPlaylistTime(
      i,
      [...this.state.addedPlaylistsTime],
      days
    );

    this.setState({ addedPlaylistsTime, days, isAll });
  }

  handleNonstop(i) {
    const nonStop = this.state.nonStop;
    const addedPlaylistsT = [...this.state.addedPlaylistsTime];

    nonStop[i] = !nonStop[i];

    if (nonStop[i]) {
      addedPlaylistsT[i].startTimeSeconds = 0;
      addedPlaylistsT[i].endTimeSeconds = 1440 * 60;
    }

    const addedPlaylistsTime = checkPlaylistTime(
      i,
      addedPlaylistsT,
      this.state.days
    );

    this.setState({ nonStop, addedPlaylistsTime });
  }

  render() {
    const { classes, viewer, clientId } = this.props;
    const {
      addedPlaylists,
      addedPlaylistsVolume,
      addedPlaylistsTime,
      days,
      radioSelect,
      isAll,
      nonStop,
    } = this.state;

    const totalSizeWODuplicates = addedPlaylists[0]
      ? Array.from(new Set(addedPlaylists))
      : [];
    const totalSize = addedPlaylists[0]
      ? totalSizeWODuplicates
          .map((data) => data.size)
          .reduce((total = 0, num) => total + num) / 1000000000
      : 0;
    const errorChecker = !!(
      this.state.data.endDate < this.state.data.startDate ||
      addedPlaylists.length < 1 ||
      this.state.data.name === "" ||
      addedPlaylistsTime.filter((playlist) => playlist.error)[0]
    );

    const client = viewer.allClients.edges.find(
      (item) => item?.node?.id === clientId
    );
    const playlistIds = client?.node?.playlistIds || [];
    const playlists =
      viewer.allPlaylists.edges.filter((item) =>
        playlistIds.includes(item?.node?.id)
      ) || [];

    const data = playlists.concat(
      viewer.allPlaybacks.edges
        .filter((item) => item && item.node)
        .filter((item) => clientId === item.node.clientId)
    );
    return (
      <Dialog
        open={this.props.open}
        className={classes.dialog}
        maxWidth={false}
      >
        <div className={classes.close}>
          <IconButton
            aria-label="Close"
            className={classes.close}
            onClick={this.handleRequestClose}
          >
            <CloseIcon className={classes.closeIcon} />
          </IconButton>
        </div>
        <DialogTitle
          className={classes.dialogTitle}
          style={{ marginBottom: 16, align: "center" }}
        >
          Create schedule
        </DialogTitle>
        <div className={classes.horizontalContainer}>
          <form
            className={classes.verticalContainer}
            noValidate
            autoComplete="off"
            style={{
              padding: "0px 16px 16px 16px",
              maxWidth: 600,
              display: "block",
            }}
          >
            <div className={classes.horizontalContainer}>
              <TextField
                id="name"
                label="Name"
                inputProps={{
                  padding: 0,
                }}
                className={classes.textField}
                value={this.state.data.name}
                onChange={this.handleChange}
                margin="normal"
                error={this.state.data.name === ""}
              />
              <FormControlLabel
                id="injectable"
                className={classes.formControlLabel}
                control={
                  <Checkbox
                    checked={this.state.data.injectable}
                    onClick={this.handleClick}
                    value="injectable"
                  />
                }
                label="Injectable?"
              />
            </div>
            <div className={classes.horizontalContainer}>
              <TextField
                id="description"
                label="Description"
                inputProps={{
                  padding: 0,
                }}
                className={classes.textField}
                value={this.state.data.description}
                onChange={this.handleChange}
                margin="normal"
              />
            </div>
            <div className={classes.horizontalContainer}>
              <TextField
                id="startDate"
                label="Start Date"
                type="date"
                className={classes.textField}
                value={this.state.data.startDate}
                onChange={this.handleChange}
                margin="normal"
                error={this.state.data.endDate < this.state.data.startDate}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                id="endDate"
                label="End Date"
                type="date"
                className={classes.textField}
                value={this.state.data.endDate}
                onChange={this.handleChange}
                margin="normal"
                error={this.state.data.endDate < this.state.data.startDate}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <Typography
              color="secondary"
              type="subheading"
              component="h3"
              gutterBottom
              style={{ padding: "16px 0px 0px 8px" }}
            >
              Summary:
            </Typography>
            <Typography
              variant="caption"
              component="p"
              gutterBottom
              style={{ padding: "0px 0px 0px 8px" }}
            >
              {`Total size: ${totalSize.toFixed(2)} GB`}
            </Typography>
            <Typography
              variant="body1"
              color="secondary"
              component="h3"
              style={{ padding: "8px 0px 0px 8px" }}
            >
              Selected:
            </Typography>
            <div className={classes.cardContainer} style={{ flex: "auto" }}>
              {addedPlaylists.map((node, i) => {
                const s = addedPlaylistsTime[i].startTimeSeconds || 0;
                const e =
                  addedPlaylistsTime[i].endTimeSeconds > 1439 * 60 + 59
                    ? 0
                    : addedPlaylistsTime[i].endTimeSeconds || 0;
                const start = new Date(s * 1000).toISOString().substr(11, 8);
                const end = new Date(e * 1000).toISOString().substr(11, 8);

                const errStyle = addedPlaylistsTime[i].error
                  ? {
                      margin: 16,
                      color: "red",
                      border: "solid",
                      borderWidth: "thin",
                    }
                  : { margin: 16 };

                return (
                  <Card
                    className={classes.card}
                    key={i}
                    style={{ marginBottom: "10px", padding: "8px 12px" }}
                    elevation={2}
                  >
                    <CardContent className={classes.cardContent}>
                      <div className={classes.horizontalContainer}>
                        <Typography
                          variant="subtitle1"
                          component="h2"
                          style={{ flex: "auto" }}
                        >
                          {node.name}
                        </Typography>
                        <IconButton
                          style={{ marginTop: "-8px", marginRight: "-12px" }}
                          disableRipple
                          aria-label="Delete"
                          onClick={() => this.handleDelete(i)}
                        >
                          <CloseIcon />
                        </IconButton>
                      </div>
                    </CardContent>
                    <CardActions className={classes.cardActions}>
                      <div className={classes.verticalContainer}>
                        <div className={classes.horizontalContainer}>
                          <TextField
                            style={{ width: "35px", marginRight: "15px" }}
                            id="volume"
                            label="Volume"
                            error={
                              addedPlaylistsVolume[i] < 0 ||
                              addedPlaylistsVolume[i] > 15
                            }
                            value={addedPlaylistsVolume[i]}
                            onChange={(value) =>
                              this.handleChangeVolume(i, value)
                            }
                            type="number"
                            className={classes.textField2}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            margin="normal"
                          />
                          <TimePicker
                            style={errStyle}
                            placeholder="Start time"
                            value={moment(start, "HH:mm:ss")}
                            onChange={(value, value2) =>
                              this.handleChangeTime(
                                i,
                                value,
                                value2,
                                "startTimeSeconds"
                              )
                            }
                            popupStyle={{
                              zIndex: 0,
                            }}
                          />
                          <TimePicker
                            style={errStyle}
                            placeholder="End time"
                            value={moment(end, "HH:mm:ss")}
                            onChange={(value, value2) =>
                              this.handleChangeTime(
                                i,
                                value,
                                value2,
                                "endTimeSeconds"
                              )
                            }
                            popupStyle={{
                              zIndex: 0,
                            }}
                          />
                          <FormControlLabel
                            style={{ marginLeft: "3px" }}
                            key={i}
                            id={i}
                            control={
                              <Checkbox
                                checked={isAll[i]}
                                onChange={() => this.handleAll(i)}
                                disableRipple
                                value="ALL"
                                color="primary"
                              />
                            }
                            label="ALL"
                          />
                          <FormControlLabel
                            key={node}
                            id={node}
                            control={
                              <Checkbox
                                checked={nonStop[i]}
                                onChange={() => this.handleNonstop(i)}
                                disableRipple
                                value="24/7"
                                color="primary"
                              />
                            }
                            label="24/7"
                          />
                        </div>
                        <div style={{ marginLeft: "-12px" }}>
                          {days[i].map((day, index) => (
                            <FormControlLabel
                              key={index}
                              id={index}
                              className={classes.formControlLabel}
                              control={
                                <Checkbox
                                  disableRipple
                                  checked={day}
                                  onClick={() =>
                                    this.handleChangeDays(i, index)
                                  }
                                  value={weekdays[index]}
                                  className={classes.checkbox}
                                  icon={
                                    <CheckBoxOutlineBlankIcon
                                      className={classes.sizeIcon}
                                    />
                                  }
                                  checkedIcon={
                                    <CheckBoxIcon
                                      className={classes.sizeIcon}
                                    />
                                  }
                                />
                              }
                              label={weekdays[index]}
                            />
                          ))}
                        </div>
                      </div>
                    </CardActions>
                  </Card>
                );
              })}
            </div>
          </form>
          <div className={classes.playlistContainer}>
            <div className={classes.verticalContainer}>
              <TextField
                id="search"
                label="Search"
                type="search"
                value={this.state.search}
                onChange={this.handleChangeSearch}
                className={classes.textField3}
                margin="normal"
              />
              <div className={classes.horizontalContainer}>
                <RadioGroup
                  aria-label="gender"
                  name="gender1"
                  className={classes.group}
                  value={radioSelect}
                  onChange={this.handleChangeRadio}
                >
                  <FormControlLabel
                    value="pl"
                    control={<Radio disableRipple />}
                    label="Playlist"
                  />
                  <FormControlLabel
                    value="sp"
                    control={<Radio disableRipple />}
                    label="Smart Playlist"
                  />
                  <FormControlLabel
                    value="all"
                    control={<Radio disableRipple />}
                    label="All"
                  />
                </RadioGroup>
              </div>
              <GridList
                className={classes.gridList}
                spacing={2}
                cellHeight={70}
              >
                <List style={{ width: "auto" }} dense>
                  {data
                    .filter((item) => {
                      if (item.node) {
                        switch (radioSelect) {
                          case "pl":
                            return item.node.clientsId;
                          case "sp":
                            return item.node.clientId;
                          case "all":
                            return item;
                          default:
                            return item;
                        }
                      }
                      return false;
                    })
                    .sort((a, b) =>
                      a.node && b.node
                        ? arraySort(a.node.name, b.node.name)
                        : null
                    )
                    .map(({ node }) =>
                      node.name
                        .toLowerCase()
                        .includes(this.state.search.toLowerCase()) ? (
                        <div key={node.id} style={{ paddingRight: "8px" }}>
                          <ListItem
                            dense
                            disableGutters
                            divider
                            className={classes.listItem}
                            style={{
                              background: node.clientId ? "#FBE9E7" : "#BBDEFB",
                            }}
                          >
                            <ListItemText
                              className={classes.listItemText}
                              primary={node.name}
                              secondary={`${(node.size / 1000000000).toFixed(
                                2
                              )}GB`}
                            />
                            <IconButton
                              color="primary"
                              className={classes.button}
                              component="span"
                              onClick={() => this.handleClickPlaylist(node)}
                            >
                              <AddCircleOutline />
                            </IconButton>
                          </ListItem>
                        </div>
                      ) : null
                    )}
                </List>
              </GridList>
            </div>
          </div>
        </div>
        <Divider />
        <div className={classes.horizontalContainer}>
          <Button
            variant="contained"
            color="secondary"
            onClick={this.handleRequestClose}
            className={classes.button}
          >
            Cancel
          </Button>
          <Button
            disabled={errorChecker}
            variant="contained"
            color="primary"
            onClick={this.handleCreate}
            className={classes.button}
          >
            Create
          </Button>
        </div>
      </Dialog>
    );
  }
}

ScheduleNew.defaultProps = {
  open: false,
};

ScheduleNew.propTypes = {
  classes: PropTypes.object.isRequired, //eslint-disable-line
  viewer: PropTypes.object.isRequired, //eslint-disable-line
  open: PropTypes.bool,
  handleRequestClose: PropTypes.func, //eslint-disable-line
  myCallBack: PropTypes.func, //eslint-disable-line
  clientId: PropTypes.string.isRequired,
};

export default withStyles(styles)(ScheduleNew);
