import { commitMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import Environment from "../../environment";

const mutation = graphql`
  mutation createSonosMenuMutation(
    $clientId: String!
    $name: String!
    $description: String
    $parentMenuId: String
    $explicit: Boolean
  ) {
    createSonosMenu(
      clientId: $clientId
      name: $name
      description: $description
      parentMenuId: $parentMenuId
      explicit: $explicit
    ) {
      __typename
      id
      name
      description
      schema {
        name
        pathId
        parentId
        coverName
        cover_40
        cover_290
        cover_legacy
        playlistIds
        sceneIds
        autoPopulateType
        category
        subCategory
        genres
        routeItems
      }
      active
      explicit
      dateCreated
      dateModified
      clientId
      parentMenuId
    }
  }
`;

export default (
  clientId,
  name,
  description,
  parentMenuId,
  explicit,
  callback
) => {
  const variables = {
    clientId,
    name,
    description,
    parentMenuId,
    explicit,
  };

  commitMutation(Environment, {
    mutation,
    variables,
    onCompleted: (response) => {
      callback();
    },
    onError: (err) => console.error(err), // eslint-disable-line
  });
};
