import { commitMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import Environment from "../../environment";

const mutation = graphql`
  mutation toggleQsysRouteCategoryMutation(
    $menuId: String!
    $pathId: Int!
    $type: String!
    $category: String!
    $subCategory: String
    $routeItems: Int
  ) {
    toggleQsysRouteCategory(
      menuId: $menuId
      pathId: $pathId
      type: $type
      category: $category
      subCategory: $subCategory
      routeItems: $routeItems
    ) {
      __typename
      id
      name
      description
      schema {
        name
        pathId
        parentId
        coverName
        cover_40
        cover_290
        cover_legacy
        playlistIds
        sceneIds
        mixesArray {
          mixId
          type
        }
        autoPopulateType
        category
        subCategory
        genres
        routeItems
      }
      active
      dateCreated
      dateModified
      clientId
    }
  }
`;

export default (
  menuId,
  pathId,
  type,
  category,
  subCategory,
  routeItems,
  callback
) => {
  const variables = {
    menuId,
    pathId,
    type,
    category,
    subCategory,
    routeItems,
  };

  commitMutation(Environment, {
    mutation,
    variables,
    onCompleted: (response) => {
      callback();
    },
    onError: (err) => console.error(err), // eslint-disable-line
  });
};
