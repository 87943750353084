/**
 * @flow
 * @relayHash 5f9ac797a765ae8c8407dbc7578127b2
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type _deviceStatusType = "ACTIVE" | "INACTIVE" | "REGISTERED" | "UNREGISTERED" | "%future added value";
export type rebootAudioDeviceMutationVariables = {|
  deviceId: string
|};
export type rebootAudioDeviceMutationResponse = {|
  +rebootAudioDevice: ?{|
    +id: string,
    +deviceStatus: ?_deviceStatusType,
  |}
|};
export type rebootAudioDeviceMutation = {|
  variables: rebootAudioDeviceMutationVariables,
  response: rebootAudioDeviceMutationResponse,
|};
*/


/*
mutation rebootAudioDeviceMutation(
  $deviceId: String!
) {
  rebootAudioDevice(deviceId: $deviceId) {
    id
    deviceStatus
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "deviceId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "deviceId",
        "variableName": "deviceId"
      }
    ],
    "concreteType": "AudioDevice",
    "kind": "LinkedField",
    "name": "rebootAudioDevice",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "deviceStatus",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "rebootAudioDeviceMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "rebootAudioDeviceMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "5f9ac797a765ae8c8407dbc7578127b2",
    "metadata": {},
    "name": "rebootAudioDeviceMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9b39d61fffd7d52233807cd73a0e49be';

module.exports = node;
