/**
 * @flow
 * @relayHash fcf0a9ede4d54fc0df1ede3017422842
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type createVideoCampaignProviderData = {|
  name: string,
  description?: ?string,
  clientId: string,
  goLiveDate: any,
  deviceIds?: ?$ReadOnlyArray<string>,
  profileIds?: ?$ReadOnlyArray<string>,
  onHold: boolean,
|};
export type editVideoCampaignMutationVariables = {|
  inputId: string,
  input: createVideoCampaignProviderData,
|};
export type editVideoCampaignMutationResponse = {|
  +editVideoCampaign: ?{|
    +id: string
  |}
|};
export type editVideoCampaignMutation = {|
  variables: editVideoCampaignMutationVariables,
  response: editVideoCampaignMutationResponse,
|};
*/


/*
mutation editVideoCampaignMutation(
  $inputId: String!
  $input: createVideoCampaignProviderData!
) {
  editVideoCampaign(id: $inputId, createVideoCampaignProvider: $input) {
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "inputId"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "createVideoCampaignProvider",
        "variableName": "input"
      },
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "inputId"
      }
    ],
    "concreteType": "VideoCampaign",
    "kind": "LinkedField",
    "name": "editVideoCampaign",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "editVideoCampaignMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "editVideoCampaignMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "id": "fcf0a9ede4d54fc0df1ede3017422842",
    "metadata": {},
    "name": "editVideoCampaignMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '37dd1eecfdbd046f6c067aa0795e8862';

module.exports = node;
