import { commitMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import Environment from "../../environment";

const mutation = graphql`
  mutation updateQsysGlobalZoneLimitMutation($userId: String!, $limit: Int!) {
    updateQsysGlobalZoneLimit(userId: $userId, limit: $limit) {
      id
      qsys {
        multiTenant
        globalZoneLimit
      }
    }
  }
`;

const updateQsysGlobalZoneLimit = (userId, limit, callback) => {
  const variables = {
    userId,
    limit,
  };

  commitMutation(Environment, {
    mutation,
    variables,
    optimisticResponse: {
      updateQsysGlobalZoneLimit: {
        id: userId,
        qsys: {
          multiTenant: true,
          globalZoneLimit: limit,
        },
      },
    },
    onCompleted: (response, errors) => {
      if (errors) {
        console.error("Update failed:", errors);
        return;
      }
      callback();
    },
    onError: (err) => console.error(err),
  });
};

export default updateQsysGlobalZoneLimit;
