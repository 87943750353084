/**
 * External dependencies
 */

import React, { useContext } from "react";
import { withStyles } from "@material-ui/core/styles";
import { TreeItem } from "@material-ui/lab";
import { Typography } from "@material-ui/core";

/**
 * Internal dependencies
 */

import { CurrentRouteContext } from "../../hooks/currentRouteProvider";

const styles = (theme) => ({
  content: {
    color: theme.palette.text.secondary,
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    "$expanded > &": {
      fontWeight: theme.typography.fontWeightRegular,
    },
  },
  group: {},
  expanded: {},
  selected: {},
  label: {
    fontWeight: "inherit",
    color: "inherit",
  },
  labelRoot: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0.5, 0),
  },
  labelIcon: {
    marginRight: theme.spacing(1),
  },
  labelText: {
    fontWeight: "inherit",
    flexGrow: 1,
  },
});

const StyledTreeItem = (props) => {
  const {
    labelText,
    labelicon: LabelIcon,
    labelInfo,
    color,
    bgColor,
    classes,
    route,
    ...other
  } = props;

  const { setCurrentRoute } = useContext(CurrentRouteContext);

  return (
    <TreeItem
      label={
        <div className={classes.labelRoot}>
          <LabelIcon color="inherit" className={classes.labelIcon} />
          <Typography variant="body2" className={classes.labelText}>
            {labelText}
          </Typography>
          <Typography variant="caption" color="inherit">
            {labelInfo}
          </Typography>
        </div>
      }
      style={{
        "--tree-view-color": color,
        "--tree-view-bg-color": bgColor,
      }}
      classes={{
        root: classes.root,
        content: classes.content,
        expanded: classes.expanded,
        selected: classes.selected,
        group: classes.group,
        label: classes.label,
      }}
      {...other}
      onClick={() => setCurrentRoute(route)}
    />
  );
};

export default withStyles(styles)(StyledTreeItem);
