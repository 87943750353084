// create react functional component pointing to MenuView.js
import React, { Suspense, useState } from "react";
import ClientModal from "../../sonosAndQsys/components/ClientModal";
import { useLazyLoadQuery } from "react-relay";
import { ALL_CLIENTS } from "../../sonosAndQsys/queries/allAudioClients";
import permissions from "../../authentication/isPermited";
import LoadingPage from "../../ui/LoadingPage";
import { withStyles } from "@material-ui/core/styles";
import ClientContextProvider from "../../sonosAndQsys/hooks/currentClientProvider";
import QsysDeviceTable from "../components/qsysDeviceTable";

const styles = () => ({
  root: {
    display: "flex",
  },
});

const QsysDevicesPage = (props) => {
  const { classes, match } = props;
  const { viewer } = useLazyLoadQuery(
    ALL_CLIENTS,
    {},
    { fetchPolicy: "store-and-network" }
  );
  const [openModal, setOpenModal] = useState(false);
  const allClients = viewer?.allClients.edges
    .filter((item) => item?.node)
    .map(({ node }) => node);

  return (
    permissions([1, 2], [1, 2]) && (
      <div style={{ minHeight: "90vh" }}>
        <Suspense fallback={<LoadingPage />}>
          <ClientContextProvider>
            <ClientModal
              match={match}
              open={openModal}
              onClose={() => setOpenModal(false)}
              classes={classes}
              allClients={allClients}
              viewer={viewer}
            />
            <div
              className={classes.horizontalContainer}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginLeft: "12px",
                marginBottom: "12px",
              }}
            >
              <span
                style={{
                  cursor: "pointer",
                  color: "#3BABA1",
                  fontSize: 16,
                  fontWeight: "bold",
                }}
                onClick={() => setOpenModal(true)}
              >
                Change Client &nbsp;
              </span>
            </div>
            <div className={classes.root}>
              <QsysDeviceTable />
            </div>
          </ClientContextProvider>
        </Suspense>
      </div>
    )
  );
};

export default withStyles(styles)(QsysDevicesPage);
