/**
 * @flow
 * @relayHash 4cf61e6975a8e978e8ec58017ee03630
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type _ModelMutationType = "CREATED" | "DELETED" | "UPDATED" | "UPDATEDCLIENT" | "%future added value";
export type PlaybackSubscriptionVariables = {||};
export type PlaybackSubscriptionResponse = {|
  +PlaybackSubscription: {|
    +mutation: _ModelMutationType,
    +node: {|
      +id: string,
      +name: string,
      +description: ?string,
      +shuffle: ?boolean,
      +image: ?string,
      +size: ?number,
      +createdAt: ?any,
      +updatedAt: ?any,
      +clientId: string,
      +playlists: ?$ReadOnlyArray<?{|
        +playlistId: ?string,
        +name: ?string,
        +nrOfSongs: ?string,
        +filename: ?string,
      |}>,
    |},
  |}
|};
export type PlaybackSubscription = {|
  variables: PlaybackSubscriptionVariables,
  response: PlaybackSubscriptionResponse,
|};
*/


/*
subscription PlaybackSubscription {
  PlaybackSubscription {
    mutation
    node {
      id
      name
      description
      shuffle
      image
      size
      createdAt
      updatedAt
      clientId
      playlists {
        playlistId
        name
        nrOfSongs
        filename
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "PlaybackSubscriptionPayload",
    "kind": "LinkedField",
    "name": "PlaybackSubscription",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "mutation",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Playback",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "shuffle",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "image",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "size",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createdAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "updatedAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "clientId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PlaylistQueue",
            "kind": "LinkedField",
            "name": "playlists",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "playlistId",
                "storageKey": null
              },
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "nrOfSongs",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "filename",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "PlaybackSubscription",
    "selections": (v1/*: any*/),
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "PlaybackSubscription",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "4cf61e6975a8e978e8ec58017ee03630",
    "metadata": {},
    "name": "PlaybackSubscription",
    "operationKind": "subscription",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '4e5f732b8e485dc87e5bf514b00d52c0';

module.exports = node;
