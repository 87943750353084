import { commitMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import Environment from "../../environment";

const mutation = graphql`
  mutation updateVideoDeviceFormatMutation(
    $deviceId: String!
    $newFormat: String!
  ) {
    updateVideoDeviceFormat(deviceId: $deviceId, newFormat: $newFormat) {
      id
    }
  }
`;

export default (deviceId, newFormat, callback) => {
  const variables = {
    deviceId,
    newFormat,
  };

  commitMutation(Environment, {
    mutation,
    variables,
    onCompleted: (response) => {
      const id = response.updateVideoDeviceFormat.id;
      callback(id);
    },
    onError: (err) => console.error(err), // eslint-disable-line
  });
};
