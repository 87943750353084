/* eslint-disable react/prop-types */
import React, { Component } from "react";
import { withRouter } from "found";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";

const styles = (theme) => ({
  card: {
    display: "flex",
    flexDirection: "column",
    background: theme.palette.background.paper,
    margin: 4,
  },
  horizontalContainer: {
    padding: 4,
    display: "flex",
    flexWrap: "wrap",
  },
  verticalContainer: {
    padding: 4,
    display: "flex",
    flexDirection: "column",
  },
});

class AudioDevicesIssuePanel extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    const { classes, viewer } = this.props;
    const appUpTimeDevices = viewer.allAudioDevices.edges
      .filter((item) => item && item.node)
      .filter((item) => item.node.deviceStatus === "ACTIVE")
      .filter((item) =>
        item.node.appUpTime
          ? Number(item.node.appUpTime.split(/:/, 1)[0]) > 100
          : false
      );
    const lastOnlineDevices = viewer.allAudioDevices.edges
      .filter((item) => item && item.node)
      .filter((item) => item.node.deviceStatus === "ACTIVE")
      .filter((item) =>
        item.node.lastOnline
          ? (new Date().getTime() - item.node.lastOnline) / 86400000 > 200
          : false
      );
    const errorOnDevices = viewer.allAudioDevices.edges
      .filter((item) => item && item.node)
      .filter((item) => item.node.deviceStatus === "ACTIVE")
      .filter((item) =>
        item.node.appStatus
          ? item.node.appStatus.message === "not yet initialized"
          : false
      );
    const statusOnDevices = viewer.allAudioDevices.edges
      .filter((item) => item && item.node)
      .filter((item) => item.node.deviceStatus === "ACTIVE")
      .filter((item) =>
        item.node.appStatus ? item.node.appStatus.status !== "SUCCESS" : false
      );
    return (
      <Card className={classes.card} raised>
        <CardHeader
          title="Issue Panel"
          subheader="See below the current errors"
        />
        <Divider />
        <CardContent className={classes.horizontalContainer}>
          <div className={classes.verticalContainer}>
            {appUpTimeDevices.length > 0 && (
              <div
                style={{ background: "#ff6666" }}
                className={classes.horizontalContainer}
              >
                <Typography
                  variant="subtitle1"
                  style={{
                    color: "#ffffff",
                    padding: "3px 2px 2px 8px",
                    flex: "auto",
                  }}
                >
                  {`${appUpTimeDevices.length} - Devices online for more then 100h`}
                </Typography>
                <Button
                  size="medium"
                  style={{ color: "#ffffff" }}
                  className={classes.controls}
                  onClick={() => this.props.router.push("/home/audio_devices")}
                >
                  Details
                </Button>
              </div>
            )}
            {lastOnlineDevices.length > 0 && (
              <div
                style={{ background: "#ff6666" }}
                className={classes.horizontalContainer}
              >
                <Typography
                  variant="subtitle1"
                  style={{
                    color: "#ffffff",
                    padding: "3px 2px 2px 8px",
                    flex: "auto",
                  }}
                >
                  {`${lastOnlineDevices.length} - Devices offline for more then 200 days`}
                </Typography>
                <Button
                  size="medium"
                  style={{ color: "#ffffff" }}
                  className={classes.controls}
                  onClick={() => this.props.router.push("/home/audio_devices")}
                >
                  Details
                </Button>
              </div>
            )}
            {errorOnDevices.length > 0 && (
              <div
                style={{ background: "#ff6666" }}
                className={classes.horizontalContainer}
              >
                <Typography
                  variant="subtitle1"
                  style={{
                    color: "#ffffff",
                    padding: "3px 2px 2px 8px",
                    flex: "auto",
                  }}
                >
                  {`${errorOnDevices.length} - Devices not yet initialized`}
                </Typography>
                <Button
                  size="medium"
                  style={{ color: "#ffffff" }}
                  className={classes.controls}
                  onClick={() => this.props.router.push("/home/audio_devices")}
                >
                  Details
                </Button>
              </div>
            )}
            {statusOnDevices.length > 0 && (
              <div
                style={{ background: "#ff6666" }}
                className={classes.horizontalContainer}
              >
                <Typography
                  variant="subtitle1"
                  style={{
                    color: "#ffffff",
                    padding: "3px 2px 2px 8px",
                    flex: "auto",
                  }}
                >
                  {`${statusOnDevices.length} - Devices with status issues`}
                </Typography>
                <Button
                  size="medium"
                  style={{ color: "#ffffff" }}
                  className={classes.controls}
                  onClick={() => this.props.router.push("/home/audio_devices")}
                >
                  Details
                </Button>
              </div>
            )}
          </div>
        </CardContent>
        <Divider />
      </Card>
    );
  }
}

AudioDevicesIssuePanel.propTypes = {
  classes: PropTypes.object.isRequired, //eslint-disable-line
  viewer: PropTypes.object.isRequired, // eslint-disable-line
};

export default withRouter(withStyles(styles)(AudioDevicesIssuePanel));
