/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type UserSettings_viewer$ref: FragmentReference;
declare export opaque type UserSettings_viewer$fragmentType: UserSettings_viewer$ref;
export type UserSettings_viewer = {|
  +user: {|
    +id: string,
    +createdAt: ?string,
    +services: ?{|
      +google: ?{|
        +accessToken: ?string,
        +idToken: ?string,
        +id: ?string,
        +email: ?string,
        +name: ?string,
        +given_name: ?string,
        +family_name: ?string,
        +picture: ?string,
      |},
      +facebook: ?{|
        +accessToken: ?string,
        +id: ?string,
        +email: ?string,
        +name: ?string,
        +first_name: ?string,
        +last_name: ?string,
        +link: ?string,
        +gender: ?string,
        +locale: ?string,
        +verified: ?boolean,
      |},
    |},
    +emails: ?$ReadOnlyArray<?{|
      +address: ?string,
      +verified: ?boolean,
    |}>,
    +profile: ?{|
      +name: ?string,
      +clientId: ?string,
      +videoClientId: ?string,
    |},
    +status: ?{|
      +online: boolean,
      +lastLogin: ?{|
        +date: ?any,
        +ipAddr: ?string,
        +userAgent: ?string,
      |},
    |},
    +consoleSettings: ?{|
      +audioDevices: ?{|
        +appDetails: ?boolean,
        +logs: ?boolean,
        +nowPlaying: ?boolean,
        +systemDetailsControls: ?boolean,
        +user: ?boolean,
        +tags: ?boolean,
      |},
      +videoDevices: ?{|
        +appDetails: ?boolean,
        +deviceActions: ?boolean,
        +systemDetailsControls: ?boolean,
        +tags: ?boolean,
      |},
      +users: ?{|
        +details: ?boolean,
        +actions: ?boolean,
        +permissions: ?boolean,
        +logs: ?boolean,
      |},
    |},
  |},
  +$refType: UserSettings_viewer$ref,
|};
export type UserSettings_viewer$data = UserSettings_viewer;
export type UserSettings_viewer$key = {
  +$data?: UserSettings_viewer$data,
  +$fragmentRefs: UserSettings_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "accessToken",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "verified",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "appDetails",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "logs",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "systemDetailsControls",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tags",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserSettings_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "createdAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "UserServices",
          "kind": "LinkedField",
          "name": "services",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Google",
              "kind": "LinkedField",
              "name": "google",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "idToken",
                  "storageKey": null
                },
                (v0/*: any*/),
                (v2/*: any*/),
                (v3/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "given_name",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "family_name",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "picture",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Facebook",
              "kind": "LinkedField",
              "name": "facebook",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                (v0/*: any*/),
                (v2/*: any*/),
                (v3/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "first_name",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "last_name",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "link",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "gender",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "locale",
                  "storageKey": null
                },
                (v4/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "UserEmails",
          "kind": "LinkedField",
          "name": "emails",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "address",
              "storageKey": null
            },
            (v4/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "UserProfile",
          "kind": "LinkedField",
          "name": "profile",
          "plural": false,
          "selections": [
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "clientId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "videoClientId",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Status",
          "kind": "LinkedField",
          "name": "status",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "online",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "LastLogin",
              "kind": "LinkedField",
              "name": "lastLogin",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "date",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "ipAddr",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "userAgent",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ConsoleSettings",
          "kind": "LinkedField",
          "name": "consoleSettings",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "AudioDeviceSettings",
              "kind": "LinkedField",
              "name": "audioDevices",
              "plural": false,
              "selections": [
                (v5/*: any*/),
                (v6/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "nowPlaying",
                  "storageKey": null
                },
                (v7/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "user",
                  "storageKey": null
                },
                (v8/*: any*/)
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "VideoDeviceSettings",
              "kind": "LinkedField",
              "name": "videoDevices",
              "plural": false,
              "selections": [
                (v5/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "deviceActions",
                  "storageKey": null
                },
                (v7/*: any*/),
                (v8/*: any*/)
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "UsersSettings",
              "kind": "LinkedField",
              "name": "users",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "details",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "actions",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "permissions",
                  "storageKey": null
                },
                (v6/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = '420dfff380a44d65a415f1ff383af141';

module.exports = node;
