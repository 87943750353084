import { commitMutation } from "react-relay";
import graphql from 'babel-plugin-relay/macro';
import Environment from "../../environment";

const mutation = graphql`
  mutation syncPlaylistMutation($playlistId: String!) {
    syncPlaylist(playlistId: $playlistId) {
      id
      name
      genre
      description
      signedUrl
      tempo
    }
  }
`;

export default (playlistId, callback) => {
  const variables = {
    playlistId,
  };

  commitMutation(Environment, {
    mutation,
    variables,
    onCompleted: () => callback(),
    onError: (err) => console.error(err), // eslint-disable-line
  });
};
