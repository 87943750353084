import { commitMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import Environment from "../../environment";

const mutation = graphql`
  mutation editQsysMenuMutation(
    $menuId: String!
    $name: String!
    $description: String
    $explicit: Boolean
  ) {
    editQsysMenu(
      menuId: $menuId
      name: $name
      description: $description
      explicit: $explicit
    ) {
      id
      name
      description
      schema {
        name
        pathId
        parentId
        coverName
        cover_40
        cover_290
        cover_legacy
        playlistIds
        sceneIds
        autoPopulateType
        category
        subCategory
        genres
        routeItems
      }
      active
      explicit
      dateCreated
      dateModified
      clientId
    }
  }
`;

export default (menuId, name, description, explicit, callback) => {
  const variables = {
    menuId,
    name,
    description,
    explicit,
  };

  commitMutation(Environment, {
    mutation,
    variables,
    onCompleted: (response) => {
      callback();
    },
    onError: (err) => console.error(err), // eslint-disable-line
  });
};
