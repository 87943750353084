import { commitMutation } from "react-relay";
import graphql from 'babel-plugin-relay/macro';
import Environment from "../../environment";

const mutation = graphql`
  mutation deleteUserMutation($inputUserId: String!) {
    deleteUser(userId: $inputUserId) {
      id
    }
  }
`;

export default (userId, callback) => {
  const variables = {
    inputUserId: userId,
  };

  commitMutation(Environment, {
    mutation,
    variables,
    onCompleted: () => {
      callback();
    },
    onError: (err) => console.error(err), // eslint-disable-line
  });
};
