/**
 * @flow
 * @relayHash 589c0d7fca53e7438580500e39635683
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type _AutoPopulateTypes = "CATEGORY" | "GENRE" | "NONE" | "SUBCATEGORY" | "%future added value";
export type _ModelMutationType = "CREATED" | "DELETED" | "UPDATED" | "UPDATEDCLIENT" | "%future added value";
export type _PlayableTypes = "BOTH" | "PLAYLISTS" | "SCENES" | "%future added value";
export type sonosMenusSubscriptionVariables = {|
  token: string,
  updatedId?: ?string,
  updatedFields?: ?$ReadOnlyArray<?string>,
|};
export type sonosMenusSubscriptionResponse = {|
  +SonosMenuSubscription: ?{|
    +mutation: _ModelMutationType,
    +node: {|
      +id: string,
      +name: ?string,
      +description: ?string,
      +schema: ?$ReadOnlyArray<?{|
        +name: string,
        +pathId: number,
        +parentId: number,
        +coverName: ?string,
        +cover_40: ?string,
        +cover_290: ?string,
        +cover_legacy: ?string,
        +playlistIds: ?$ReadOnlyArray<?string>,
        +sceneIds: ?$ReadOnlyArray<?string>,
        +mixesArray: ?$ReadOnlyArray<?{|
          +mixId: string,
          +type: string,
        |}>,
        +autoPopulateType: ?_AutoPopulateTypes,
        +category: ?string,
        +subCategory: ?string,
        +genres: ?$ReadOnlyArray<?string>,
        +routeItems: ?number,
        +playableType: ?_PlayableTypes,
      |}>,
      +active: ?boolean,
      +dateCreated: ?any,
      +dateModified: ?any,
      +clientId: ?string,
    |},
  |}
|};
export type sonosMenusSubscription = {|
  variables: sonosMenusSubscriptionVariables,
  response: sonosMenusSubscriptionResponse,
|};
*/


/*
subscription sonosMenusSubscription(
  $token: String!
  $updatedId: ID
  $updatedFields: [String]
) {
  SonosMenuSubscription(token: $token, updatedId: $updatedId, updatedFields: $updatedFields) {
    mutation
    node {
      id
      name
      description
      schema {
        name
        pathId
        parentId
        coverName
        cover_40
        cover_290
        cover_legacy
        playlistIds
        sceneIds
        mixesArray {
          mixId
          type
        }
        autoPopulateType
        category
        subCategory
        genres
        routeItems
        playableType
      }
      active
      dateCreated
      dateModified
      clientId
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "token"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "updatedFields"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "updatedId"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "token",
        "variableName": "token"
      },
      {
        "kind": "Variable",
        "name": "updatedFields",
        "variableName": "updatedFields"
      },
      {
        "kind": "Variable",
        "name": "updatedId",
        "variableName": "updatedId"
      }
    ],
    "concreteType": "SonosMenuSubscriptionPayload",
    "kind": "LinkedField",
    "name": "SonosMenuSubscription",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "mutation",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "SonosMenu",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Route",
            "kind": "LinkedField",
            "name": "schema",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "pathId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "parentId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "coverName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cover_40",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cover_290",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cover_legacy",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "playlistIds",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "sceneIds",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Mix",
                "kind": "LinkedField",
                "name": "mixesArray",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "mixId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "type",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "autoPopulateType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "category",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "subCategory",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "genres",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "routeItems",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "playableType",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "active",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "dateCreated",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "dateModified",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "clientId",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "sonosMenusSubscription",
    "selections": (v4/*: any*/),
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "sonosMenusSubscription",
    "selections": (v4/*: any*/)
  },
  "params": {
    "id": "589c0d7fca53e7438580500e39635683",
    "metadata": {},
    "name": "sonosMenusSubscription",
    "operationKind": "subscription",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '629620895a7c2f01dffcd53f10dc690d';

module.exports = node;
