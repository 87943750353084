/**
 * @flow
 * @relayHash 2dfa4c0617f328281e25a0d3deb23e4e
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type toggleLogsMutationVariables = {|
  deviceUid: string
|};
export type toggleLogsMutationResponse = {|
  +toggleLogs: ?{|
    +id: string,
    +isLogging: ?boolean,
  |}
|};
export type toggleLogsMutation = {|
  variables: toggleLogsMutationVariables,
  response: toggleLogsMutationResponse,
|};
*/


/*
mutation toggleLogsMutation(
  $deviceUid: ID!
) {
  toggleLogs(deviceUid: $deviceUid) {
    id
    isLogging
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "deviceUid"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "deviceUid",
        "variableName": "deviceUid"
      }
    ],
    "concreteType": "DeviceLog",
    "kind": "LinkedField",
    "name": "toggleLogs",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isLogging",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "toggleLogsMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "toggleLogsMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "2dfa4c0617f328281e25a0d3deb23e4e",
    "metadata": {},
    "name": "toggleLogsMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b45a8f71cd7d603bfefa659083ea9111';

module.exports = node;
