/**
 * @flow
 * @relayHash 7279092ff1c5e31f4b267a75a96e05b3
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type updateUserRoleAndClientMutationVariables = {|
  inputUserId: string,
  inputRoleIds?: ?$ReadOnlyArray<number>,
  inputClientId: string,
  inputVideoClientId: string,
  serviceType?: ?$ReadOnlyArray<string>,
  defaultServiceType?: ?string,
|};
export type updateUserRoleAndClientMutationResponse = {|
  +updateUserRoleAndClient: ?{|
    +id: string,
    +roles: ?$ReadOnlyArray<?string>,
    +createdAt: ?string,
    +apiKey: ?string,
    +services: ?{|
      +password: ?{|
        +bcrypt: ?string
      |},
      +google: ?{|
        +accessToken: ?string,
        +idToken: ?string,
        +id: ?string,
        +email: ?string,
        +name: ?string,
        +given_name: ?string,
        +family_name: ?string,
        +picture: ?string,
      |},
      +facebook: ?{|
        +accessToken: ?string,
        +id: ?string,
        +email: ?string,
        +name: ?string,
        +first_name: ?string,
        +last_name: ?string,
        +link: ?string,
        +gender: ?string,
        +locale: ?string,
        +verified: ?boolean,
      |},
    |},
    +emails: ?$ReadOnlyArray<?{|
      +address: ?string,
      +verified: ?boolean,
    |}>,
    +profile: ?{|
      +name: ?string,
      +clientId: ?string,
      +videoClientId: ?string,
    |},
    +rolesConsole: ?{|
      +roleAccess: ?number,
      +roleAudio: ?number,
      +roleVideo: ?number,
    |},
    +status: ?{|
      +online: boolean,
      +lastLogin: ?{|
        +date: ?any,
        +ipAddr: ?string,
      |},
    |},
    +companyName: ?string,
    +serviceType: ?$ReadOnlyArray<?string>,
    +defaultServiceType: ?string,
  |}
|};
export type updateUserRoleAndClientMutation = {|
  variables: updateUserRoleAndClientMutationVariables,
  response: updateUserRoleAndClientMutationResponse,
|};
*/


/*
mutation updateUserRoleAndClientMutation(
  $inputUserId: String!
  $inputRoleIds: [Int!]
  $inputClientId: String!
  $inputVideoClientId: String!
  $serviceType: [String!]
  $defaultServiceType: String
) {
  updateUserRoleAndClient(userId: $inputUserId, roleIds: $inputRoleIds, clientId: $inputClientId, videoClientId: $inputVideoClientId, serviceType: $serviceType, defaultServiceType: $defaultServiceType) {
    id
    roles
    createdAt
    apiKey
    services {
      password {
        bcrypt
      }
      google {
        accessToken
        idToken
        id
        email
        name
        given_name
        family_name
        picture
      }
      facebook {
        accessToken
        id
        email
        name
        first_name
        last_name
        link
        gender
        locale
        verified
      }
    }
    emails {
      address
      verified
    }
    profile {
      name
      clientId
      videoClientId
    }
    rolesConsole {
      roleAccess
      roleAudio
      roleVideo
    }
    status {
      online
      lastLogin {
        date
        ipAddr
      }
    }
    companyName
    serviceType
    defaultServiceType
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "defaultServiceType"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "inputClientId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "inputRoleIds"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "inputUserId"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "inputVideoClientId"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "serviceType"
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "accessToken",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "verified",
  "storageKey": null
},
v11 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "clientId",
        "variableName": "inputClientId"
      },
      {
        "kind": "Variable",
        "name": "defaultServiceType",
        "variableName": "defaultServiceType"
      },
      {
        "kind": "Variable",
        "name": "roleIds",
        "variableName": "inputRoleIds"
      },
      {
        "kind": "Variable",
        "name": "serviceType",
        "variableName": "serviceType"
      },
      {
        "kind": "Variable",
        "name": "userId",
        "variableName": "inputUserId"
      },
      {
        "kind": "Variable",
        "name": "videoClientId",
        "variableName": "inputVideoClientId"
      }
    ],
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "updateUserRoleAndClient",
    "plural": false,
    "selections": [
      (v6/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "roles",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "createdAt",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "apiKey",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UserServices",
        "kind": "LinkedField",
        "name": "services",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Password",
            "kind": "LinkedField",
            "name": "password",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "bcrypt",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Google",
            "kind": "LinkedField",
            "name": "google",
            "plural": false,
            "selections": [
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "idToken",
                "storageKey": null
              },
              (v6/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "given_name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "family_name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "picture",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Facebook",
            "kind": "LinkedField",
            "name": "facebook",
            "plural": false,
            "selections": [
              (v7/*: any*/),
              (v6/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "first_name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "last_name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "link",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "gender",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "locale",
                "storageKey": null
              },
              (v10/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UserEmails",
        "kind": "LinkedField",
        "name": "emails",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "address",
            "storageKey": null
          },
          (v10/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UserProfile",
        "kind": "LinkedField",
        "name": "profile",
        "plural": false,
        "selections": [
          (v9/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "clientId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "videoClientId",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "RolesConsole",
        "kind": "LinkedField",
        "name": "rolesConsole",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "roleAccess",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "roleAudio",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "roleVideo",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Status",
        "kind": "LinkedField",
        "name": "status",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "online",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "LastLogin",
            "kind": "LinkedField",
            "name": "lastLogin",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "date",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "ipAddr",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "companyName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "serviceType",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "defaultServiceType",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "updateUserRoleAndClientMutation",
    "selections": (v11/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "updateUserRoleAndClientMutation",
    "selections": (v11/*: any*/)
  },
  "params": {
    "id": "7279092ff1c5e31f4b267a75a96e05b3",
    "metadata": {},
    "name": "updateUserRoleAndClientMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '8532c913702bcb3c0ced14fac663ed55';

module.exports = node;
