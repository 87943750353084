import { commitMutation } from "react-relay";
import graphql from 'babel-plugin-relay/macro';
import Environment from "../../environment";

const mutation = graphql`
  mutation removeAPKFromS3Mutation($keyName: String!) {
    removeAPKFromS3(keyName: $keyName)
  }
`;

export default (keyName, callback) => {
  const variables = {
    keyName,
  };

  commitMutation(Environment, {
    mutation,
    variables,
    onCompleted: (response) => {
      if (response && response.removeAPKFromS3) {
        callback(response.removeAPKFromS3);
      }
    },
    onError: (err) => console.error(err), // eslint-disable-line
  });
};
