import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import AudioClients from "../audio_devices/AudioClients";
import React from "react";

export default function ClientModal(props) {
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginLeft: "12px",
          paddingTop: "12px",
          justifyContent: "center",
        }}
      >
        <h2>
          <span>{props.match.route.name}</span>
          <span style={{ fontSize: "18px", color: "grey" }}>
            &nbsp; @ {props.clientId === "ALL" ? "ALL" : props.clientName}
          </span>
        </h2>
      </div>
      <Drawer open={props.open} onClose={props.onClose}>
        <div
          tabIndex={0}
          role="button"
          onClick={props.onClose}
          onKeyDown={props.onClose}
        >
          <div className={props.classes.list}>
            <List dense>
              <ListItem button>
                <ListItemText primary="ALL" onClick={props.onClick} />
              </ListItem>
              <AudioClients
                viewer={props.viewer}
                myCallBack={props.myCallBack}
              />
            </List>
          </div>
        </div>
      </Drawer>
    </>
  );
}
