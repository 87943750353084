/**
 * @flow
 * @relayHash edf105984b15154ebe7e8bee98d586d2
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SongReportingListQueryVariables = {||};
export type SongReportingListQueryResponse = {|
  +viewer: {|
    +allSongsReports: ?$ReadOnlyArray<?{|
      +id: string,
      +name: ?string,
      +filename: ?string,
      +createdAt: ?any,
    |}>
  |}
|};
export type SongReportingListQuery = {|
  variables: SongReportingListQueryVariables,
  response: SongReportingListQueryResponse,
|};
*/


/*
query SongReportingListQuery {
  viewer {
    allSongsReports {
      id
      name
      filename
      createdAt
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Viewer",
    "kind": "LinkedField",
    "name": "viewer",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "SongReportsFile",
        "kind": "LinkedField",
        "name": "allSongsReports",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "filename",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createdAt",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "SongReportingListQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "SongReportingListQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "id": "edf105984b15154ebe7e8bee98d586d2",
    "metadata": {},
    "name": "SongReportingListQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd0af89890865513dc272932e357527de';

module.exports = node;
