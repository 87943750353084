/**
 * External dependencies
 */
import React, { useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  TextField,
  Button,
  Checkbox,
  FormHelperText,
  FormControlLabel,
  IconButton,
} from "@material-ui/core";

import { Check, Clear } from "@material-ui/icons";

/**
 * Internal dependencies
 */

import setActiveMenuMutation from "../../../mutations/setActiveMenuMutation";
import editSonosMenuMutation from "../../../mutations/editSonosMenuMutation";
import { CurrentClientContext } from "../../hooks/currentClientProvider";
import setActiveQsysMenuMutation from "../../../mutations/setActiveQsysMenuMutation";
import editQsysMenuMutation from "../../../mutations/editQsysMenuMutation";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: "35vh",
    margin: "auto",
    left: 0,
    right: 0,
    borderRadius: 19,
  },
  form: {
    display: "flex",
    flexDirection: "column",
  },
  textField: {
    margin: 8,
  },
  button: {
    margin: 8,
  },
  switch: {
    margin: 8,
  },
}));

export default function EditMenuModal({ menu, handleClose }) {
  const classes = useStyles();
  const { currentClient, audioProvider } = useContext(CurrentClientContext);
  const [nameValue, setNameValue] = useState(menu.name);
  const [useExplicit, setUseExplicit] = useState(menu.explicit ?? false);
  const [descriptionValue, setDescriptionValue] = useState(
    menu.description || ""
  );
  const [defaultState, setDefaultState] = useState(menu.active);
  const [askTheQuestion, setAskTheQuestion] = useState(false);

  const handleChange = (event, field) => {
    switch (field) {
      case "name":
        setNameValue(event.target.value);
        break;
      case "description":
        setDescriptionValue(event.target.value);
        break;
    }
  };

  const setAsActive = () => {
    audioProvider === "sonos"
      ? setActiveMenuMutation(menu.id, menu.clientId, () => {
          setAskTheQuestion(false);
          setDefaultState(!defaultState);
        })
      : setActiveQsysMenuMutation(menu.id, menu.clientId, () => {
          setAskTheQuestion(false);
          setDefaultState(!defaultState);
        });
  };

  const editNameDescription = () => {
    audioProvider === "sonos"
      ? editSonosMenuMutation(
          menu.id,
          nameValue,
          descriptionValue,
          useExplicit,
          () => {
            handleClose();
          }
        )
      : editQsysMenuMutation(
          menu.id,
          nameValue,
          descriptionValue,
          useExplicit,
          () => {
            handleClose();
          }
        );
  };

  const handleUseExplicit = () => {
    setUseExplicit(!useExplicit);
  };

  return (
    <>
      <div className={classes.paper}>
        <h2 id="edit-menu-modal-title">Edit menu:</h2>
        <form className={classes.form} noValidate autoComplete="off">
          <TextField
            id="menu-name-edit"
            label="Name"
            onChange={(event) => handleChange(event, "name")}
            value={nameValue}
            className={classes.textField}
          />
          <TextField
            id="menu-description-edit"
            label="Description"
            onChange={(event) => handleChange(event, "description")}
            value={descriptionValue}
            className={classes.textField}
          />
          <div className={classes.switch}>
            {!menu.parentMenuId && (
              <div>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={() => setAskTheQuestion(true)}
                      checked={defaultState}
                      disabled={defaultState || currentClient === "ALL"}
                      name="default"
                      color="primary"
                    />
                  }
                  label="Set default menu"
                />
                <FormHelperText>
                  {currentClient === "ALL"
                    ? "Select a client before setting default"
                    : "Be careful!"}
                </FormHelperText>
              </div>
            )}
            {askTheQuestion && (
              <>
                <p>Are you sure?</p>
                <IconButton
                  aria-label="set-ok"
                  className={classes.button}
                  onClick={setAsActive}
                >
                  <Check />
                </IconButton>
                <IconButton
                  aria-label="set-clear"
                  className={classes.button}
                  onClick={() => setAskTheQuestion(false)}
                >
                  <Clear />
                </IconButton>
              </>
            )}
          </div>
          <FormControlLabel
            id="use-explicit"
            className={classes.formControlLabel}
            style={{ marginLeft: "0px" }}
            control={
              <Checkbox
                checked={useExplicit}
                onClick={handleUseExplicit}
                value="useExplicit"
              />
            }
            label="Explicit"
          />
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={editNameDescription}
          >
            Edit menu
          </Button>
        </form>
      </div>
    </>
  );
}
