import jwt from "jsonwebtoken";
import AudioClientSubscription from "./AudioClientSubscription";
import AudioDeviceSubscription from "./AudioDeviceSubscription";
import AudioDeviceActiveSubscription from "./AudioDeviceActiveSubscription";
import DeviceLogSubscription from "./DeviceLogSubscription";
import DsBlobSubscription from "./DsBlobSubscription";
import EventSubscription from "./EventSubscription";
import MethodSubscription from "./MethodSubscription";
import NotificationSubscription from "./NotificationSubscription";
import PlaybackSubscription from "./PlaybackSubscription";
import PlaylistSubscription from "./PlaylistSubscription";
import ScheduleSubscription from "./ScheduleSubscription";
import TagSubscription from "./TagSubscription";
import UserSubscription from "./UserSubscription";
import VideoCampaignSubscription from "./VideoCampaignSubscription";
import VideoClientSubscription from "./VideoClientSubscription";
import VideoDeviceSubscription from "./VideoDeviceSubscription";
import VideoProfileSubscription from "./VideoProfileSubscription";
import SceneSubscription from "./SceneSubscription";
import NPLogSubscription from "./NPLogSubscription";
import PCLogSubscription from "./PCLogSubscription";

const token = localStorage.getItem("authentication_token");
const user = jwt.decode(token);

export default {
  user,
  token,
  AudioClientSubscription,
  AudioDeviceSubscription,
  AudioDeviceActiveSubscription,
  DeviceLogSubscription,
  DsBlobSubscription,
  EventSubscription,
  MethodSubscription,
  NotificationSubscription,
  PlaybackSubscription,
  PlaylistSubscription,
  ScheduleSubscription,
  TagSubscription,
  UserSubscription,
  VideoCampaignSubscription,
  VideoClientSubscription,
  VideoDeviceSubscription,
  VideoProfileSubscription,
  SceneSubscription,
  NPLogSubscription,
  PCLogSubscription,
};
