const roles = [
  { id: 1, state: "Activaire Admin" },
  { id: 2, state: "Activaire Tenant" },
  { id: 3, state: "Client Admin" },
  { id: 4, state: "Client PU" },
  { id: 5, state: "Client U" },
  { id: 0, state: "None" },
];

export default roles;
