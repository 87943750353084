import React from "react";
import PropTypes from "prop-types";
import { QueryRenderer /*fetchQuery*/ } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import PauseCircleOutlineIcon from "@material-ui/icons/PauseCircleOutline";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import { withSnackbar } from "material-ui-snackbar-provider";
import compose from "recompose/compose";
import Environment from "../../../environment";

const query = graphql`
  query SongPlayingQuery($songId: String!) {
    viewer {
      getSong(songId: $songId) {
        id
        artist
        album
        title
        artworkUrl
      }
    }
  }
`;

const styles = {
  cover: {
    width: 132,
    height: 132,
  },
  playIcon: {
    height: 24,
    width: 24,
  },
  typo1: {
    display: "flex",
    alignItems: "center",
    color: "#222831",
  },
  typo2: {
    paddingLeft: 4,
    color: "#222831",
  },
};

const add3Dots = (string, limit) => {
  var dots = "...";
  if (string.length > limit) {
    string = string.substring(0, limit) + dots;
  }

  return string;
};

const rgbToYIQ = ({ r, g, b }) => {
  return (r * 299 + g * 587 + b * 114) / 1000;
};

const hexToRgb = (hex) => {
  if (!hex || hex === undefined || hex === "") {
    return undefined;
  }

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : undefined;
};

const contrast = (colorHex, threshold = 128) => {
  if (colorHex === undefined) {
    return "#000";
  }

  const rgb = hexToRgb(colorHex);

  if (rgb === undefined) {
    return "#000";
  }

  return rgbToYIQ(rgb) >= threshold ? "#000" : "#fff";
};

class SongPlaying extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorElTags: null,
    };
  }

  render() {
    const { classes, songId, data, play } = this.props;
    const defaultImage = "https://artwork.activaire.com/default.jpeg";
    return (
      <div>
        <QueryRenderer
          variables={{ songId }}
          environment={Environment}
          query={query}
          render={({ error, props }) => {
            if (error) {
              return <div>{error.message}</div>;
            } else if (props) {
              return (
                <>
                  <div
                    style={{
                      backgroundColor: data.vibrant,
                      width: "98%",
                      borderRadius: "10px",
                      minHeight: 150,
                      paddingTop: "12px",
                      display: "flex",
                      flexDirection: "row",
                      paddingLeft: "12px",
                    }}
                  >
                    <div style={{ display: "flex" }}>
                      <CardMedia
                        className={classes.cover}
                        image={
                          props.viewer?.getSong?.artworkUrl || defaultImage
                        }
                        title={props.viewer?.getSong?.title || "default"}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        height: "132",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        marginLeft: "6px",
                      }}
                    >
                      <Typography
                        className={classes.typo1}
                        style={{
                          color: contrast(data.vibrant),
                          fontWeight: "bold",
                        }}
                        variant="h5"
                      >
                        {play ? (
                          <PlayCircleOutlineIcon className={classes.playIcon} />
                        ) : (
                          <PauseCircleOutlineIcon
                            className={classes.playIcon}
                          />
                        )}
                        {props.viewer?.getSong?.title || "Paused"}
                      </Typography>
                      <Typography
                        className={classes.typo2}
                        variant="subtitle1"
                        style={{
                          color: contrast(data.vibrant),
                        }}
                      >
                        <span style={{ fontWeight: "bold" }}>Artist: </span>
                        {props.viewer?.getSong?.artist || ""}
                      </Typography>
                      <Tooltip
                        id="tooltip-bottom"
                        title={props.viewer?.getSong?.album || ""}
                        placement="left-start"
                        enterTouchDelay={200}
                      >
                        <Typography
                          className={classes.typo2}
                          variant="subtitle1"
                          style={{
                            color: contrast(data.vibrant),
                            marginTop: "3px",
                          }}
                        >
                          <span style={{ fontWeight: "bold" }}>Album: </span>
                          {props.viewer?.getSong?.album &&
                            add3Dots(props.viewer?.getSong?.album, 35)}
                        </Typography>
                      </Tooltip>
                    </div>
                  </div>
                </>
              );
            }
          }}
        />
      </div>
    );
  }
}

SongPlaying.defaultProps = {
  usersId: [],
};

SongPlaying.propTypes = {
  classes: PropTypes.object.isRequired, //eslint-disable-line
  viewer: PropTypes.object.isRequired, //eslint-disable-line
  usersId: PropTypes.array, //eslint-disable-line
  deviceId: PropTypes.string.isRequired,
  snackbar: PropTypes.object, //eslint-disable-line
  tagList: PropTypes.array, //eslint-disable-line
};

export default compose(withStyles(styles), withSnackbar())(SongPlaying);
