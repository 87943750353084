/* eslint-disable react/no-array-index-key */
/* eslint-disable class-methods-use-this */

import React from "react";
import { QueryRenderer } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Transition } from "react-transition-group";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import AddCircleOutline from "@material-ui/icons/AddCircleOutline";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { ImageList } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import _ from "lodash";
import { TimePicker } from "antd";
import "antd/es/date-picker/style/index.css";
import "antd/es/time-picker/style/index.css";
import moment from "moment";
import Environment from "../../environment";
import editScheduleMutation from "../mutations/editScheduleMutation";

const Query = graphql`
  query ScheduleEditQuery($scheduleId: ScheduleFilter!) {
    viewer {
      allSchedule(filter: $scheduleId) {
        edges {
          node {
            id
            name
            size
            injectable
            startDate
            endDate
            clientId
            playingPlaylists {
              playlistId
              name
              volume
              startTime
              endTime
              startTimeSeconds
              endTimeSeconds
              days
              shuffle
              filenames {
                mixId
                nrOfSongs
              }
            }
          }
        }
      }
    }
  }
`;

const styles = (theme) => ({
  dialog: {
    background: "secondary",
    margin: theme.spacing(3),
  },
  dialogTitle: {
    padding: "0px 24px 0px 24px",
    minWidth: 230,
    maxWidth: 800,
  },
  verticalContainer: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
  },
  horizontalContainer: {
    display: "flex",
  },
  textField: {
    marginLeft: 8,
    marginTop: 0,
    flex: "auto",
  },
  textField2: {
    marginRight: 8,
    marginTop: 0,
    marginBottom: 0,
    width: 50,
  },
  textField3: {
    marginTop: 0,
    marginRight: theme.spacing(1),
    width: 220,
  },
  textField4: {
    marginRight: 8,
    marginTop: 0,
    marginBottom: 0,
    width: 90,
  },
  formControlLabel: {
    margin: "8px 0px 0px 0px",
  },
  button: {
    margin: "8px 8px 8px 8px",
    maxWidth: 140,
  },
  closeIcon: {
    height: 28,
    width: 28,
  },
  closeIcon2: {
    height: 24,
    width: 24,
    alignSelf: "right",
  },
  close: {
    position: "relative",
    float: "right",
  },
  input: {
    display: "none",
  },
  gridList: {
    height: 500,
    width: "auto",
  },
  listItem: {
    width: 220,
    height: 65,
    paddingLeft: 4,
  },
  listItemText: {
    maxHeight: "max-content",
    padding: "0px 0px 0px 8px",
  },
  playlistContainer: {
    margin: "0px 8px 8px 8px",
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    maxWidth: 280,
  },
  card: {
    padding: 8,
    margin: 4,
  },
  cardContent: {
    padding: 0,
  },
  cardContainer: {
    flex: "auto",
    flexWrap: "wrap",
    flexDirection: "column",
    height: 400,
    overflowY: "scroll",
  },
  cardActions: {
    padding: 0,
  },
  typo1: {
    paddingRight: 8,
    alignSelf: "center",
    flex: "auto",
  },
  group: {
    margin: `${theme.spacing(1)}px 0`,
    display: "contents",
  },
  checkbox: {
    width: 24,
    height: 24,
  },
  sizeIcon: {
    fontSize: 20,
  },
});

const arraySort = (a, b) => {
  try {
    const nameA = a.toUpperCase(); // ignore upper and lowercase
    const nameB = b.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    // names must be equal
    return 0;
  } catch (e) {
    console.log("ScheduleEdit", e);
  }
};

const compareBooleanArrays = (a1, a2) => {
  for (let i = 0; i < a1.length; i++) {
    if (a1[i] && a1[i] === a2[i]) return true;
  }
  return false;
};

const checkPlaylistTime = (i, addedPlaylistsTime, days) => {
  addedPlaylistsTime.map((playlist, index) => {
    playlist.error = false;
    if (playlist.startTimeSeconds >= playlist.endTimeSeconds) {
      playlist.error = true;
    }
    if (
      index !== i &&
      !(
        (addedPlaylistsTime[i].startTimeSeconds <= playlist.startTimeSeconds &&
          addedPlaylistsTime[i].endTimeSeconds <= playlist.startTimeSeconds) ||
        (addedPlaylistsTime[i].startTimeSeconds >= playlist.endTimeSeconds &&
          addedPlaylistsTime[i].endTimeSeconds >= playlist.endTimeSeconds)
      ) &&
      compareBooleanArrays(days[i], days[index])
    ) {
      playlist.error = true;
    }
    return playlist;
  });

  return addedPlaylistsTime;
};

const weekdays = ["MO", "TU", "WE", "TH", "FR", "SA", "SU"];

const standardToDate = (date) => {
  const arrayOfDate = date.split(/\//);
  const arrayOfDate1 = `${arrayOfDate[2]}-${arrayOfDate[0]}-${arrayOfDate[1]}`;
  return arrayOfDate1;
};

const dateToStandard = (date) => {
  const arrayOfDate = date.split(/-/);
  const arrayOfDate1 = `${arrayOfDate[1]}/${arrayOfDate[2]}/${arrayOfDate[0]}`;
  return arrayOfDate1;
};

class ScheduleEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      onEnter: true,
      data: {
        id: this.props.data.id,
        name: this.props.data.name,
        description: this.props.data.description || "",
        injectable: this.props.data.injectable,
        startDate: standardToDate(this.props.data.startDate),
        endDate: standardToDate(this.props.data.endDate),
      },
      addedPlaylists: this.props.addedPlaylists,
      addedPlaylistsVolume: this.props.addedPlaylistsVolume,
      addedPlaylistsTime: this.props.addedPlaylistsTime,
      days: this.props.days,
      radioSelect: "all",
      isAll: this.props.days.map((item) => !item.includes(false)),
      nonStop: this.props.addedPlaylistsTime.map(
        (item, index) =>
          item.startTimeSeconds === 0 && item.endTimeSeconds === 1440 * 60
      ),
    };
    this.handleRequestClose = this.handleRequestClose.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeSearch = this.handleChangeSearch.bind(this);
    this.handleChangeVolume = this.handleChangeVolume.bind(this);
    this.handleChangeTime = this.handleChangeTime.bind(this);
    this.handleChangeDays = this.handleChangeDays.bind(this);
    this.handleChangeRadio = this.handleChangeRadio.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleClickPlaylist = this.handleClickPlaylist.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleAll = this.handleAll.bind(this);
    this.handleNonstop = this.handleNonstop.bind(this);
  }

  loadProps(props) {
    if (props) {
      const { viewer } = this.props;
      const scheduleId = this.props.data.id;
      const schedule = props.viewer.allSchedule.edges.find(
        (item) => item.node && item.node.id === scheduleId
      );
      const addedPlaylistsIds = schedule.node.playingPlaylists.map(
        (item) => item.playlistId
      );
      const addedPlaylists = addedPlaylistsIds.map((item) => {
        const addedPlayback = viewer.allPlaybacks.edges.find(
          (item2) => item2.node && item2.node.id === item
        );
        if (addedPlayback) {
          return addedPlayback.node;
        } else {
          const addedPlaylist = viewer.allPlaylists.edges.find((item2) => {
            return item2?.node?.id === item;
          });
          return addedPlaylist ? addedPlaylist.node : null;
        }
      });
      return this.setState({ addedPlaylists });
    }
  }

  handleRequestClose() {
    this.setState({
      data: {
        search: "",
        name: "",
        description: "",
        injectable: false,
        startDate: "",
        endDate: "",
      },
      addedPlaylists: [],
      addedPlaylistsVolume: [],
      addedPlaylistsTime: [],
      days: [],
      radioSelect: "all",
      onEnter: true,
    });
    this.props.handleRequestClose();
  }

  handleEdit(totalSize) {
    const playlists = this.state.addedPlaylists.map((playlist, i) => {
      const item = {
        playlistId: playlist.playlistId || playlist.id,
        name: playlist.name,
        volume: this.state.addedPlaylistsVolume[i].toString(),
        startTime: Math.round(
          this.state.addedPlaylistsTime[i].startTimeSeconds / 60
        ),
        endTime: Math.round(
          this.state.addedPlaylistsTime[i].endTimeSeconds / 60
        ),
        startTimeSeconds: this.state.addedPlaylistsTime[i].startTimeSeconds,
        endTimeSeconds: this.state.addedPlaylistsTime[i].endTimeSeconds,
        days: this.state.days[i],
        shuffle: true, // TODO: this is standard TRUE
        filenames: playlist?.playlists
          ? playlist.playlists.map((file) => {
              const newFile =
                file?.id ||
                this.props.viewer.allPlaylists.edges.find(
                  (item) => item?.node?.id === file.playlistId
                );
              if (newFile) {
                const item2 = {
                  nrOfSongs: file.nrOfSongs.toString(),
                  id: newFile?.id || newFile?.node?.id,
                };
                return item2;
              }
              return {};
            })
          : [{ nrOfSongs: "1", id: playlist.id }],
      };
      return item;
    });
    editScheduleMutation(
      this.state.data.id,
      this.state.data.name,
      this.state.data.description,
      this.state.data.injectable,
      dateToStandard(this.state.data.startDate),
      dateToStandard(this.state.data.endDate),
      this.props.data.clientId,
      totalSize * 1000000000,
      playlists,
      (playlistId) => console.log("Changes performed")
    );
    this.handleRequestClose();
  }

  handleChange(e) {
    this.setState({
      data: { ...this.state.data, [e.target.id]: e.target.value },
    });
  }

  handleChangeSearch(e) {
    this.setState({ [e.target.id]: e.target.value });
  }

  handleChangeVolume(i, value) {
    const addedPlaylistsVolume = [...this.state.addedPlaylistsVolume];
    addedPlaylistsVolume[i] = Number(value.target.value);
    this.setState({ addedPlaylistsVolume });
  }

  handleChangeDays(i, index) {
    const days = [...this.state.days];
    const isAll = [...this.state.isAll];

    try {
      days[i] = days[i].map((day, iterator) =>
        iterator === index ? !day : day
      );
      if (days[i].includes(false)) {
        isAll[i] = false;
      } else {
        isAll[i] = true;
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.setState({ days, isAll });
    }

    const addedPlaylistsTime = checkPlaylistTime(
      i,
      [...this.state.addedPlaylistsTime],
      days
    );

    this.setState({ addedPlaylistsTime });
  }

  handleChangeTime(i, value, value2, type) {
    const { days } = this.state;
    const addedPlaylistsT = [...this.state.addedPlaylistsTime];
    const timeArray = value2.split(":");
    const seconds =
      Number(timeArray[0]) * 3600 +
      Number(timeArray[1]) * 60 +
      Number(timeArray[2]);
    const secondsAdjusted =
      seconds === 0 && type === "endTimeSeconds" ? 1440 * 60 : seconds;
    const nonStop = this.state.nonStop;
    addedPlaylistsT[i][type] = secondsAdjusted;

    const addedPlaylistsTime = checkPlaylistTime(i, addedPlaylistsT, days);

    if (
      addedPlaylistsTime[i].startTimeSeconds === 0 &&
      addedPlaylistsTime[i].endTimeSeconds === 1440 * 60
    ) {
      nonStop[i] = true;
    } else {
      nonStop[i] = false;
    }
    this.setState({ addedPlaylistsTime, nonStop });
  }

  handleChangeRadio(event) {
    this.setState({ radioSelect: event.target.value });
  }

  handleClick(e) {
    e.preventDefault();
    const name = e.target.value;
    this.setState({
      data: { ...this.state.data, [name]: !this.state.data.injectable },
    });
  }

  handleClickPlaylist(data) {
    const addedPlaylists = [...this.state.addedPlaylists];
    const addedPlaylistsVolume = [...this.state.addedPlaylistsVolume];
    const addedPlaylistsTime = [...this.state.addedPlaylistsTime];
    const days = this.state.days;
    const isAll = this.state.isAll;
    const nonStop = this.state.nonStop;
    isAll.push(false);
    nonStop.push(false);
    addedPlaylists.push(data);
    addedPlaylistsVolume.push(14);
    days.push([false, false, false, false, false, false, false]);

    const end = addedPlaylistsTime[0]
      ? Number(addedPlaylistsTime[addedPlaylistsTime.length - 1].endTimeSeconds)
      : 0;
    addedPlaylistsTime.push({
      startTimeSeconds: end === 1440 * 60 ? 1440 * 60 : end,
      endTimeSeconds: end + 60 * 60 <= 1440 * 60 ? end + 60 * 60 : 1440 * 60,
      error: false,
    });

    this.setState({
      addedPlaylists,
      addedPlaylistsVolume,
      addedPlaylistsTime,
      days,
      isAll,
      nonStop,
    });
  }

  handleDelete(i) {
    const addedPlaylists = [...this.state.addedPlaylists];
    const addedPlaylistsVolume = [...this.state.addedPlaylistsVolume];
    const addedPlaylistsTime = [...this.state.addedPlaylistsTime];
    const isAll = this.state.isAll;
    const nonStop = this.state.nonStop;
    const days = this.state.days;
    isAll.splice(i, 1);
    nonStop.splice(i, 1);
    addedPlaylists.splice(i, 1);
    addedPlaylistsVolume.splice(i, 1);
    addedPlaylistsTime.splice(i, 1);
    days.splice(i, 1);
    this.setState({
      addedPlaylists,
      addedPlaylistsVolume,
      addedPlaylistsTime,
      days,
      isAll,
      nonStop,
    });
  }

  handleAll(i) {
    const isAll = this.state.isAll;
    const days = this.state.days;

    isAll[i] = !isAll[i];

    if (isAll[i]) {
      days[i] = [true, true, true, true, true, true, true];
    } else {
      days[i] = [false, false, false, false, false, false, false];
    }

    const addedPlaylistsTime = checkPlaylistTime(
      i,
      [...this.state.addedPlaylistsTime],
      days
    );

    this.setState({ addedPlaylistsTime, isAll, days });
  }

  handleNonstop(i) {
    const nonStop = this.state.nonStop;
    const addedPlaylistsT = [...this.state.addedPlaylistsTime];

    nonStop[i] = !nonStop[i];

    if (nonStop[i]) {
      addedPlaylistsT[i].startTimeSeconds = 0;
      addedPlaylistsT[i].endTimeSeconds = 1440 * 60;
      addedPlaylistsT[i].startTime = 0;
      addedPlaylistsT[i].endTime = 1440;
    }

    const addedPlaylistsTime = checkPlaylistTime(
      i,
      addedPlaylistsT,
      this.state.days
    );

    this.setState({ nonStop, addedPlaylistsTime });
  }

  render() {
    const { classes, viewer } = this.props;
    const {
      addedPlaylists,
      addedPlaylistsVolume,
      addedPlaylistsTime,
      days,
      radioSelect,
      isAll,
      nonStop,
      onEnter,
    } = this.state;

    const scheduleId = this.props.data.id;

    const errorChecker = !!(
      this.state.data.endDate < this.state.data.startDate ||
      addedPlaylists.length < 1 ||
      this.state.data.name === "" ||
      addedPlaylistsTime.filter((playlist) => playlist.error)[0]
    );

    const data = viewer.allPlaylists.edges
      .filter(
        (item) =>
          item &&
          item.node &&
          item.node.clientsId &&
          item.node.clientsId.includes(this.props.data.clientId)
      )
      .concat(
        viewer.allPlaybacks.edges
          .filter((item) => item && item.node)
          .filter((item) => this.props.data.clientId === item.node.clientId)
      );

    let addedPlaylistsExtended = [];

    addedPlaylists.forEach((item, i) => {
      if (item) {
        item.filename && addedPlaylistsExtended.push(item);
        item.playlists &&
          item.playlists.forEach((item2, i) => {
            addedPlaylistsExtended.push(
              Object.assign({}, item2, { id: item2.playlistId })
            );
          });
      }
    });

    const allPlaylists = viewer.allPlaylists.edges
      .filter((item) => item && item.node)
      .map(({ node }) => node);

    const addedPlaylistsWODuplicates = _.intersectionBy(
      allPlaylists,
      addedPlaylistsExtended,
      "id"
    );

    const totalSize = addedPlaylistsWODuplicates[0]
      ? addedPlaylistsWODuplicates
          .map((item) => item.size)
          .reduce((total = 0, num) => total + num) / 1000000000
      : 0;

    return (
      <QueryRenderer
        variables={{
          scheduleId: {
            _id_contains: scheduleId,
          },
        }}
        environment={Environment}
        query={Query}
        render={({ error, props }) => {
          if (error) {
            return <div>{error.message}</div>;
          } else if (props) {
            return (
              <>
                <Transition in={this.props.open && onEnter} timeout={0}>
                  {(state) => {
                    if (state === "entered") {
                      this.setState({ onEnter: false });
                      this.loadProps(props);
                    }
                  }}
                </Transition>
                <Dialog
                  open={this.props.open}
                  className={classes.dialog}
                  maxWidth={false}
                >
                  <div className={classes.close}>
                    <IconButton
                      aria-label="Close"
                      className={classes.close}
                      onClick={this.handleRequestClose}
                    >
                      <CloseIcon className={classes.closeIcon} />
                    </IconButton>
                  </div>
                  <DialogTitle
                    className={classes.dialogTitle}
                    style={{ marginBottom: 16, align: "center" }}
                  >
                    Edit schedule
                  </DialogTitle>
                  <div className={classes.horizontalContainer}>
                    <form
                      className={classes.verticalContainer}
                      noValidate
                      autoComplete="off"
                      style={{
                        padding: "0px 16px 16px 16px",
                        maxWidth: 600,
                        display: "block",
                      }}
                    >
                      <div className={classes.horizontalContainer}>
                        <TextField
                          id="name"
                          label="Name"
                          inputProps={{
                            padding: 0,
                          }}
                          className={classes.textField}
                          value={this.state.data.name}
                          onChange={this.handleChange}
                          margin="normal"
                          error={this.state.data.name === ""}
                        />
                        <FormControlLabel
                          id="injectable"
                          className={classes.formControlLabel}
                          control={
                            <Checkbox
                              checked={this.state.data.injectable}
                              onClick={this.handleClick}
                              value="injectable"
                            />
                          }
                          label="Injectable?"
                        />
                      </div>
                      <div className={classes.horizontalContainer}>
                        <TextField
                          id="description"
                          label="Description"
                          inputProps={{
                            padding: 0,
                          }}
                          className={classes.textField}
                          value={this.state.data.description}
                          onChange={this.handleChange}
                          margin="normal"
                        />
                      </div>
                      <div className={classes.horizontalContainer}>
                        <TextField
                          id="startDate"
                          label="Start Date"
                          type="date"
                          className={classes.textField}
                          value={this.state.data.startDate}
                          onChange={this.handleChange}
                          margin="normal"
                          error={
                            this.state.data.endDate < this.state.data.startDate
                          }
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                        <TextField
                          id="endDate"
                          label="End Date"
                          type="date"
                          className={classes.textField}
                          value={this.state.data.endDate}
                          onChange={this.handleChange}
                          margin="normal"
                          error={
                            this.state.data.endDate < this.state.data.startDate
                          }
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </div>
                      <Typography
                        color="secondary"
                        type="subheading"
                        component="h3"
                        gutterBottom
                        style={{ padding: "16px 0px 0px 8px" }}
                      >
                        Summary:
                      </Typography>
                      <Typography
                        variant="caption"
                        component="p"
                        gutterBottom
                        style={{ padding: "0px 0px 0px 8px" }}
                      >
                        {`Total size: ${totalSize.toFixed(2)} GB`}
                      </Typography>
                      <Typography
                        variant="body1"
                        color="secondary"
                        component="h3"
                        style={{ padding: "8px 0px 0px 8px" }}
                      >
                        Selected:
                      </Typography>
                      <div className={classes.cardContainer}>
                        {addedPlaylists.map((item, i) => {
                          const s = addedPlaylistsTime[i].startTimeSeconds || 0;
                          const e =
                            addedPlaylistsTime[i].endTimeSeconds >
                            1439 * 60 + 59
                              ? 0
                              : addedPlaylistsTime[i].endTimeSeconds || 0;
                          const start = new Date(s * 1000)
                            .toISOString()
                            .substr(11, 8);
                          const end = new Date(e * 1000)
                            .toISOString()
                            .substr(11, 8);

                          const errStyle = addedPlaylistsTime[i].error
                            ? {
                                margin: 16,
                                color: "red",
                                border: "solid",
                                borderWidth: "thin",
                              }
                            : { margin: 16 };
                          return (
                            <Card
                              className={classes.card}
                              key={i}
                              elevation={2}
                              style={{
                                marginBottom: "10px",
                                padding: "8px 12px",
                              }}
                            >
                              <CardContent className={classes.cardContent}>
                                <div className={classes.horizontalContainer}>
                                  <Typography
                                    variant="subtitle1"
                                    component="h2"
                                    style={{ flex: "auto" }}
                                  >
                                    {(item && item.name) || "Unavailable"}
                                  </Typography>
                                  <IconButton
                                    style={{
                                      marginTop: "-8px",
                                      marginRight: "-12px",
                                    }}
                                    disableRipple
                                    aria-label="Delete"
                                    onClick={() => this.handleDelete(i)}
                                  >
                                    <CloseIcon />
                                  </IconButton>
                                </div>
                              </CardContent>
                              <CardActions className={classes.cardActions}>
                                <div className={classes.verticalContainer}>
                                  <div className={classes.horizontalContainer}>
                                    <TextField
                                      style={{
                                        width: "35px",
                                        marginRight: "15px",
                                      }}
                                      id="volume"
                                      label="Volume"
                                      error={
                                        addedPlaylistsVolume[i] < 0 ||
                                        addedPlaylistsVolume[i] > 15
                                      }
                                      value={addedPlaylistsVolume[i]}
                                      onChange={(value) =>
                                        this.handleChangeVolume(i, value)
                                      }
                                      type="number"
                                      className={classes.textField2}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      margin="normal"
                                    />
                                    <TimePicker
                                      style={errStyle}
                                      placeholder="Start time"
                                      value={moment(start, "HH:mm:ss")}
                                      onChange={(value, value2) =>
                                        this.handleChangeTime(
                                          i,
                                          value,
                                          value2,
                                          "startTimeSeconds"
                                        )
                                      }
                                    />
                                    <TimePicker
                                      style={errStyle}
                                      placeholder="End time"
                                      value={moment(end, "HH:mm:ss")}
                                      onChange={(value, value2) =>
                                        this.handleChangeTime(
                                          i,
                                          value,
                                          value2,
                                          "endTimeSeconds"
                                        )
                                      }
                                    />
                                    <FormControlLabel
                                      style={{ marginLeft: "3px" }}
                                      key={i}
                                      id={i}
                                      control={
                                        <Checkbox
                                          checked={isAll[i]}
                                          onChange={() => this.handleAll(i)}
                                          disableRipple
                                          value="ALL"
                                          color="primary"
                                        />
                                      }
                                      label="ALL"
                                    />
                                    <FormControlLabel
                                      key={item}
                                      id={(item && item.id) || Math.random()}
                                      control={
                                        <Checkbox
                                          checked={nonStop[i]}
                                          onChange={() => this.handleNonstop(i)}
                                          disableRipple
                                          value="ALL"
                                          color="primary"
                                        />
                                      }
                                      label="24/7"
                                    />
                                  </div>

                                  <div style={{ marginLeft: "-12px" }}>
                                    {days[i].map((day, index) => (
                                      <FormControlLabel
                                        key={index}
                                        id={index}
                                        className={classes.formControlLabel}
                                        control={
                                          <Checkbox
                                            disableRipple
                                            checked={day}
                                            onClick={() =>
                                              this.handleChangeDays(i, index)
                                            }
                                            value={weekdays[index]}
                                            className={classes.checkbox}
                                            icon={
                                              <CheckBoxOutlineBlankIcon
                                                className={classes.sizeIcon}
                                              />
                                            }
                                            checkedIcon={
                                              <CheckBoxIcon
                                                className={classes.sizeIcon}
                                              />
                                            }
                                          />
                                        }
                                        label={weekdays[index]}
                                      />
                                    ))}
                                  </div>
                                </div>
                              </CardActions>
                            </Card>
                          );
                        })}
                      </div>
                    </form>
                    <div className={classes.playlistContainer}>
                      <div className={classes.verticalContainer}>
                        <TextField
                          id="search"
                          label="Search"
                          type="search"
                          value={this.state.search}
                          onChange={this.handleChangeSearch}
                          className={classes.textField3}
                          margin="normal"
                        />
                        <div className={classes.horizontalContainer}>
                          <RadioGroup
                            aria-label="gender"
                            name="gender1"
                            className={classes.group}
                            value={radioSelect}
                            onChange={this.handleChangeRadio}
                          >
                            <FormControlLabel
                              value="pl"
                              control={<Radio disableRipple />}
                              label="PL"
                            />
                            <FormControlLabel
                              value="sp"
                              control={<Radio disableRipple />}
                              label="SP"
                            />
                            <FormControlLabel
                              value="all"
                              control={<Radio disableRipple />}
                              label="All"
                            />
                          </RadioGroup>
                        </div>
                        <ImageList
                          className={classes.gridList}
                          gap={2}
                          rowHeight={70}
                        >
                          <List dense>
                            {data
                              .filter((item) => {
                                if (item.node) {
                                  switch (radioSelect) {
                                    case "pl":
                                      return item.node.clientsId;
                                    case "sp":
                                      return item.node.clientId;
                                    case "all":
                                      return item;
                                    default:
                                      return item;
                                  }
                                }
                                return false;
                              })
                              .sort((a, b) =>
                                a.node && b.node
                                  ? arraySort(a.node.name, b.node.name)
                                  : null
                              )
                              .map(({ node }) =>
                                node.name
                                  .toLowerCase()
                                  .includes(this.state.search.toLowerCase()) ? (
                                  <div
                                    key={node.id}
                                    style={{ paddingRight: "8px" }}
                                  >
                                    <ListItem
                                      dense
                                      disableGutters
                                      divider
                                      className={classes.listItem}
                                      style={{
                                        background: node.clientId
                                          ? "#FBE9E7"
                                          : "#BBDEFB",
                                      }}
                                    >
                                      <ListItemText
                                        className={classes.listItemText}
                                        primary={node.name}
                                        secondary={`${(
                                          node.size / 1000000000
                                        ).toFixed(2)}GB`}
                                      />
                                      <IconButton
                                        color="primary"
                                        className={classes.button}
                                        component="span"
                                        onClick={() =>
                                          this.handleClickPlaylist(node)
                                        }
                                      >
                                        <AddCircleOutline />
                                      </IconButton>
                                    </ListItem>
                                  </div>
                                ) : null
                              )}
                          </List>
                        </ImageList>
                      </div>
                    </div>
                  </div>
                  <Divider />
                  <div className={classes.horizontalContainer}>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={this.handleRequestClose}
                      className={classes.button}
                    >
                      Cancel
                    </Button>
                    <Button
                      disabled={errorChecker}
                      variant="contained"
                      color="primary"
                      onClick={() => this.handleEdit(totalSize)}
                      className={classes.button}
                    >
                      Save
                    </Button>
                  </div>
                </Dialog>
              </>
            );
          }
        }}
      />
    );
  }
}

ScheduleEdit.defaultProps = {
  open: false,
};

ScheduleEdit.propTypes = {
  classes: PropTypes.object.isRequired, //eslint-disable-line
  viewer: PropTypes.object.isRequired, //eslint-disable-line
  open: PropTypes.bool,
  handleRequestClose: PropTypes.func, //eslint-disable-line
  myCallBack: PropTypes.func, //eslint-disable-line
  data: PropTypes.object.isRequired, //eslint-disable-line
  addedPlaylists: PropTypes.array.isRequired, //eslint-disable-line
  addedPlaylistsVolume: PropTypes.array.isRequired, //eslint-disable-line
  addedPlaylistsTime: PropTypes.array.isRequired, //eslint-disable-line
  days: PropTypes.array.isRequired, //eslint-disable-line
};

export default withStyles(styles)(ScheduleEdit);
