/* eslint-disable class-methods-use-this */

import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import AddCircleOutline from "@material-ui/icons/AddCircleOutline";
import TextField from "@material-ui/core/TextField";
import GridList from "@material-ui/core/GridList";

const styles = (theme) => ({
  margin: {
    margin: "8px",
    flexWrap: "wrap",
    flexDirection: "column",
    flex: "auto",
  },
  gridList: {
    background: theme.palette.background.paper,
    maxWidth: "350px",
    // minWidth: "350px",
    maxHeight: "43vh",
  },
  listItem: {
    paddingRight: 8,
  },
  listItemText: {
    background: theme.palette.background.paper,
    maxHeight: "max-content",
    padding: 0,
  },
  verticalContainer: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
  },
  textField: {
    margin: "auto",
    width: "auto",
    marginBottom: 1,
  },
});

class PlaybacksNewQuery extends Component {
  constructor() {
    super();
    this.state = {
      search: "",
    };
    this.handleClick = this.handleClick.bind(this);
    this.callBack = this.callBack.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleClick(data) {
    this.callBack(data);
  }

  callBack(newSmartList, newSmartListValues) {
    this.props.callBack(newSmartList, newSmartListValues);
  }

  handleChange(e) {
    this.setState({ [e.target.id]: e.target.value });
  }

  render() {
    const { classes, viewer, clientId } = this.props;
    const { search } = this.state;
    const client = viewer.allClients.edges.find(
      (item) => item?.node?.id === clientId
    );
    const playlistIds = client?.node?.playlistIds || [];
    const data = viewer.allPlaylists.edges.filter((item) =>
      playlistIds.includes(item?.node?.id)
    );
    return (
      <div className={classes.margin}>
        <TextField
          id="search"
          label="Search playlist"
          type="search"
          value={search}
          onChange={this.handleChange}
          className={classes.textField}
          margin="normal"
        />
        <GridList
          className={classes.gridList}
          spacing={2}
          cols={1}
          cellHeight="auto"
        >
          {data.map(({ node }) => {
            return search === "" ? (
              <ListItem
                key={node.id}
                dense
                disableGutters
                divider
                className={classes.listItem}
              >
                <ListItemText
                  className={classes.listItemText}
                  primary={node.name}
                  secondary={`${node.tracks} tracks with size of ${(
                    node.size / 1000000000
                  ).toFixed(2)}GB`}
                />
                <IconButton
                  color="primary"
                  className={classes.button}
                  component="span"
                  onClick={() => this.handleClick(node)}
                >
                  <AddCircleOutline />
                </IconButton>
              </ListItem>
            ) : node.name.toLowerCase().search(search.toLowerCase()) >= 0 ? (
              <ListItem
                key={node.id}
                dense
                disableGutters
                divider
                className={classes.listItem}
              >
                <ListItemText
                  className={classes.listItemText}
                  primary={node.name}
                  secondary={`${node.tracks} tracks with size of ${(
                    node.size / 1000000000
                  ).toFixed(2)}GB`}
                />
                <IconButton
                  color="primary"
                  className={classes.button}
                  component="span"
                  onClick={() => this.handleClick(node)}
                >
                  <AddCircleOutline />
                </IconButton>
              </ListItem>
            ) : null;
          })}
        </GridList>
      </div>
    );
  }
}

PlaybacksNewQuery.propTypes = {
  classes: PropTypes.object.isRequired, //eslint-disable-line
  viewer: PropTypes.object.isRequired, //eslint-disable-line
  clientId: PropTypes.string.isRequired,
  callBack: PropTypes.func.isRequired,
};

export default withStyles(styles)(PlaybacksNewQuery);
