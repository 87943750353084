/* eslint-disable no-unused-vars */
import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { QueryRenderer } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import Environment from "../../environment";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import jwt from "jsonwebtoken";
import ReactTable from "react-table";
import "react-table/react-table.css";
import permissions2 from "../authentication/UIPermissions";

import MethodSubscription from "../subscriptions/MethodSubscription";
import editMethodPermissionMutation from "../mutations/editMethodPermissionMutation";
import roles from "../users/userRoles";
import { Paper } from "@material-ui/core";

const childQueryRender = graphql`
  query PermissionsQuery {
    viewer {
      allMethods {
        edges {
          node {
            id
            displayName
            methodName
            description
            UIElementsID
            roleType
            userType
          }
        }
      }
      allUIElements {
        edges {
          node {
            id
            name
            location
            description
            route
          }
        }
      }
    }
  }
`;

const styles = {
  root: {
    display: "flex",
    flexDirection: "column",
  },
  horizontalContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  verticalContainer: {
    display: "flex",
    flexDirection: "column",
    width: "fit-content",
  },
};

class Permissions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: jwt.decode(localStorage.getItem("authentication_token")),
    };
  }

  shouldComponentUpdate() {
    if (this.props.match.location.pathname === window.location.pathname) {
      return true;
    }
    return false;
  }

  componentDidMount() {
    const { user } = this.state;
    const loginProcedure = async () => {
      if (user) {
        this.subscription1 = await MethodSubscription(user);
      }
    };
    loginProcedure();
  }

  async componentWillUnmount() {
    try {
      await this.subscription1?.dispose();
    } catch (e) {
      console.log("componentWillUnmount Permissions Error:", e);
    }
  }

  render() {
    const { classes, methods } = this.props;
    const tableColumns = (role, roleType) => {
      const columns = roles
        .filter((item) => item.id !== 0)
        .map((item) => ({
          Header: item.state,
          filterable: false,
          sortable: false,
          Cell: (row) => {
            return (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Checkbox
                  checked={row.original.userType.includes(item.id)}
                  disableRipple
                  disabled={item.id === 1}
                  style={{ margin: -8 }}
                  onClick={(event) =>
                    editMethodPermissionMutation(
                      row.original.id,
                      roleType,
                      item.id,
                      event.target.checked ? "add" : "remove",
                      (idReturned) => console.log("checked")
                    )
                  }
                />
              </div>
            );
          },
          maxWidth: 120,
        }));
      return [
        {
          Header: "Expand",
          columns: [
            {
              expander: true,
              Header: () => <div>More</div>,
              width: 65,
              Expander: ({ isExpanded, ...rest }) => (
                <div>
                  {isExpanded ? (
                    <ExpandLessIcon fontSize="small" />
                  ) : (
                    <ExpandMoreIcon fontSize="small" />
                  )}
                </div>
              ),
              style: {
                cursor: "pointer",
                fontSize: 25,
                padding: "0",
                textAlign: "center",
                userSelect: "none",
              },
            },
          ],
        },
        {
          Header: role,
          columns: [
            {
              accessor: "displayName",
              Header: "Method Name",
              width: 250,
            },
            {
              id: "numberOfElements",
              accessor: (a) => a.UIElementsID.length,
              Header: "Number of Elements",
              width: 100,
            },
          ].concat(columns),
        },
      ];
    };

    const tableArray = [
      { header: "Access Role", roleType: "access" },
      { header: "Audio Role", roleType: "audio" },
      { header: "Video Role", roleType: "video" },
    ];

    return permissions2("5cade76e8d3306167367505d", methods) ? (
      <QueryRenderer
        environment={Environment}
        query={childQueryRender}
        render={({ error, props }) => {
          if (error) {
            return <div>{error.message}</div>;
          } else if (props) {
            const { viewer } = props;
            const fullData = viewer.allMethods.edges
              .filter((item) => item && item.node)
              .map((item) => item.node);
            const allElements = viewer.allUIElements.edges
              .filter((item) => item && item.node)
              .map((item) => item.node);
            const columns2 = [
              {
                accessor: "id",
                Header: "Element id",
                width: 250,
              },
              {
                accessor: "name",
                Header: "name",
                width: 250,
              },
              {
                accessor: "location",
                Header: "Location",
                width: 250,
              },
              {
                accessor: "route",
                Header: "Route",
                width: 250,
              },
            ];
            const pageSize = 10;
            const unassignedElements = allElements.filter(
              (item) =>
                fullData.filter((item2) => item2.UIElementsID.includes(item.id))
                  .length === 0
            );
            return (
              <>
                <div className={classes.root}>
                  <h2 style={{ marginLeft: "12px", paddingTop: "12px" }}>
                    {this.props.match.route.name}
                  </h2>
                  <div className={classes.verticalContainer}>
                    {tableArray.map((item) => (
                      <ReactTable
                        key={item.roleType}
                        data={fullData.filter(
                          (item2) => item2.roleType === item.roleType
                        )}
                        columns={tableColumns(item.header, item.roleType)}
                        defaultPageSize={pageSize}
                        showPagination={fullData.length > pageSize}
                        filterable
                        defaultFilterMethod={(filter, row) =>
                          String(row[filter.id])
                            .toLowerCase()
                            .includes(filter.value.toLowerCase())
                        }
                        className="-striped -highlight"
                        style={{
                          display: "block",
                          margin: "8px 0px 8px 8px",
                          fontSize: "13px",
                          flex: "auto",
                          minWidth: "35vw",
                        }}
                        SubComponent={(row) => {
                          const elementsArray = row.original.UIElementsID;
                          const dataSubcomponent = viewer.allUIElements.edges
                            .filter((item2) => item2 && item2.node)
                            .map(({ node }) => node)
                            .filter((item2) =>
                              elementsArray.includes(item2.id)
                            );
                          return (
                            <div style={{ padding: "10px" }}>
                              <Typography
                                variant="subtitle2"
                                style={{ paddingLeft: 8 }}
                                align="left"
                              >
                                <b>Method Name: </b>
                                {row.original.methodName}
                              </Typography>
                              <Typography
                                variant="subtitle2"
                                style={{ paddingLeft: 8 }}
                                align="left"
                              >
                                <b>Description: </b>
                                {row.original.description}
                              </Typography>
                              <ReactTable
                                data={dataSubcomponent}
                                columns={columns2}
                                defaultPageSize={elementsArray.length}
                                showPagination={
                                  dataSubcomponent.length > pageSize
                                }
                                filterable
                                defaultFilterMethod={(filter, row) =>
                                  String(row[filter.id])
                                    .toLowerCase()
                                    .includes(filter.value.toLowerCase())
                                }
                                className="-striped -highlight"
                                style={{
                                  display: "block",
                                  margin: "8px 0px 8px 8px",
                                  fontSize: "13px",
                                  flex: "auto",
                                  minWidth: "35vw",
                                  background: "beige",
                                }}
                              />
                            </div>
                          );
                        }}
                      />
                    ))}
                  </div>
                  <ReactTable
                    data={unassignedElements}
                    columns={columns2}
                    defaultPageSize={unassignedElements.length}
                    showPagination={unassignedElements.length > pageSize}
                    filterable
                    defaultFilterMethod={(filter, row) =>
                      String(row[filter.id])
                        .toLowerCase()
                        .includes(filter.value.toLowerCase())
                    }
                    className="-striped -highlight"
                    style={{
                      display: "flex",
                      margin: "8px 0px 8px 8px",
                      fontSize: "13px",
                      width: "fit-content",
                      background: "beige",
                    }}
                  />
                  <Typography
                    variant="caption"
                    color="secondary"
                    style={{ paddingLeft: 8 }}
                    align="left"
                  >
                    Tip: Hold shift when sorting to multi-sort!
                  </Typography>
                </div>
              </>
            );
          }
          return null;
        }}
      />
    ) : null;
  }
}

Permissions.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Permissions);
