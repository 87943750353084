import React from "react";
import { createRefetchContainer, fetchQuery } from "react-relay";
import graphql from 'babel-plugin-relay/macro';
import { withSnackbar } from "material-ui-snackbar-provider";
import compose from "recompose/compose";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import LinearProgress from "@material-ui/core/LinearProgress";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import ReactTable from "react-table";
import "react-table/react-table.css";
import DeleteIcon from "@material-ui/icons/DeleteRounded";
import CloudDownloadIcon from "@material-ui/icons/CloudDownloadRounded";
import axios from "axios";
import AppInfoParser from "app-info-parser";

import environment from "../../environment";
import addAPKtoS3AndDBMutation from "../mutations/addAPKtoS3AndDBMutation";
import addAPKSourceMutation from "../mutations/addAPKSourceMutation";
import removeAPKFromS3Mutation from "../mutations/removeAPKFromS3Mutation";

const styles = (theme) => ({
  typo: {
    paddingLeft: 8,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  button: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    color: "#ff4c4c",
    "&:hover": {
      background: "#e3fd59",
    },
  },
  button2: {
    margin: theme.spacing(1),
    marginLeft: 30,
    width: 120,
  },
  button3: {
    margin: theme.spacing(1),
    width: 170,
  },
  button4: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    "&:hover": {
      background: "#e3fd59",
    },
  },
  horizontalContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  verticalContainer: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
  },
  text1: {
    alignSelf: "center",
    marginLeft: 16,
  },
  input: {
    display: "none",
    marginLeft: 8,
  },
});

const openRow = "#DCDCDC";

const query = graphql`
  query WebkeyAppManagementRefetchQuery {
    viewer {
      allWebkeyPackages {
        edges {
          node {
            id
            appName
            keyName
            source
            packageName
            description
            versionCode
            versionName
            icon
          }
        }
      }
    }
  }
`;

class WebkeyAppManagement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRow: "",
      webkeyDeviceId: "",
      uploadDialog: false,
      selectedFile: null,
      appName: "",
      appDescription: "",
      showSpinner: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this._refetch = this._refetch.bind(this);
    this.selectRow = this.selectRow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.fileUpload = this.fileUpload.bind(this);
    this.uploadToS3 = this.uploadToS3.bind(this);
    this.enterPressed = this.enterPressed.bind(this);
  }

  handleChange(name, event) {
    this.setState({ [name]: event.target.value });
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.match.location.pathname === window.location.pathname) {
      return true;
    }
    return false;
  }

  selectRow(selectedRow) {
    this.setState({ selectedRow });
  }

  handleClose() {
    this.setState({ uploadDialog: false, selectedFile: null });
  }

  _refetch() {
    this.props.relay.refetch();
  }

  fileUpload() {
    const { selectedFile, appName, appDescription } = this.state;
    this.setState({ uploadDialog: false, showSpinner: true });
    const parser = new AppInfoParser(selectedFile);
    parser
      .parse()
      .then((result) => {
        this.setState({ selectedFile });
        addAPKtoS3AndDBMutation(
          selectedFile.name,
          appName,
          result.package,
          appDescription,
          result.versionCode,
          result.versionName,
          result.icon,
          (url) => this.uploadToS3(selectedFile, url)
        );
      })
      .catch((err) => {
        console.log("err ----> ", err);
      });
  }

  async uploadToS3(file, url) {
    const s3Bucket = axios.create();
    await s3Bucket.put(url, file).then((response) => {
      console.log(response);
      if (response.status === 200) {
        addAPKSourceMutation(file.name, () => {
          fetchQuery(environment, query);
          this.setState({ selectedFile: null, showSpinner: false });
          this.props.snackbar.showMessage("APK uploaded successfully");
        });
      }
    });
  }

  enterPressed(event) {
    const code = event.keyCode || event.which;
    if (code === 13) {
      this.fileUpload();
    }
  }

  render() {
    const { classes, viewer } = this.props;
    const { selectedRow, appName, uploadDialog, showSpinner } = this.state;

    const fixedColumns = [
      {
        Header: "App Name",
        accessor: "appName",
        width: 250,
      },
      {
        Header: "Package Name",
        accessor: "packageName",
        width: 250,
      },
      {
        Header: "Version",
        accessor: "versionName",
        width: 150,
      },
      {
        Header: "Actions",
        width: 320,
        filterable: false,
        sortable: false,
        Cell: (row) => {
          return (
            <div className={classes.horizontalContainer}>
              <Tooltip
                title="Download package"
                placement="bottom"
                enterTouchDelay={200}
                onClick={() =>
                  window.open(row.original.source.replace("s3-us-east-1", "s3"))
                }
              >
                <Button
                  variant="text"
                  size="small"
                  color="primary"
                  className={classes.button4}
                >
                  <CloudDownloadIcon />
                </Button>
              </Tooltip>
              <Tooltip
                title="Delete package"
                placement="bottom"
                enterTouchDelay={200}
                onClick={() =>
                  removeAPKFromS3Mutation(
                    row.original.keyName,
                    (result) => result && fetchQuery(environment, query)
                  )
                }
              >
                <Button
                  variant="text"
                  size="small"
                  color="primary"
                  className={classes.button}
                >
                  <DeleteIcon />
                </Button>
              </Tooltip>
            </div>
          );
        },
      },
    ];

    const allWebkeyPackages = viewer.allWebkeyPackages.edges
      .filter((item) => item && item.node)
      .map(({ node }) => node);

    let renderedData = allWebkeyPackages;

    const pageSize = 20;
    return (
      <div className={classes.verticalContainer}>
        <h2 style={{ marginLeft: "12px" }}>{this.props.match.route.name}</h2>
        <div className={classes.horizontalContainer}>
          <input
            className={classes.input}
            id="upload-app-button"
            type="file"
            onChange={(e) =>
              this.setState({
                selectedFile: e.target.files[0],
                uploadDialog: true,
              })
            }
          />
          <label htmlFor="upload-app-button">
            <Button
              component="span"
              className={classes.button3}
              variant="contained"
              color="primary"
            >
              Upload app
            </Button>
          </label>
        </div>
        <div>
          {showSpinner && (
            <LinearProgress
              style={{
                marginLeft: "10px",
                height: "10px",
                borderRadius: "2px",
              }}
            />
          )}
        </div>

        <div className={classes.horizontalContainer}>
          <ReactTable
            data={renderedData}
            columns={fixedColumns}
            defaultPageSize={pageSize}
            showPagination={renderedData.length > pageSize}
            filterable
            defaultFilterMethod={(filter, row) =>
              String(row[filter.id])
                .toLowerCase()
                .includes(filter.value.toLowerCase())
            }
            className="-striped -highlight"
            style={{
              display: "block",
              margin: "8px 0px 8px 8px",
              fontSize: "13px",
              flex: "auto",
              minWidth: "35vw",
            }}
            getTrProps={(state, rowInfo) => {
              const selectedId = rowInfo ? rowInfo.original.id : null;
              return {
                onClick: () => this.selectRow(rowInfo.original.id),
                style: {
                  background: selectedId === selectedRow ? openRow : "#FFFFFF",
                },
              };
            }}
          />
        </div>
        <Typography
          variant="caption"
          color="secondary"
          className={classes.typo}
          align="left"
        >
          Tip: Hold shift when sorting to multi-sort!
        </Typography>
        <Dialog open={uploadDialog} onClose={this.handleClose} maxWidth="lg">
          <div className={classes.horizontalContainer}>
            <Typography
              variant="h6"
              id="modal-edit-title"
              align="center"
              style={{
                flex: "auto",
                alignSelf: "center",
                marginTop: 8,
              }}
            >
              Name your package
            </Typography>
            <Tooltip title="Close" placement="bottom" enterTouchDelay={200}>
              <IconButton>
                <CloseIcon
                  className={classes.closeIcon}
                  onClick={this.handleClose}
                />
              </IconButton>
            </Tooltip>
          </div>
          <div className={classes.verticalContainer}>
            <DialogContent className={classes.horizontalContainer}>
              <TextField
                id="device-search-field"
                label="App Name"
                className={classes.textField}
                onChange={(event) => this.handleChange("appName", event)}
                onKeyPress={this.enterPressed}
                margin="normal"
              />
              <TextField
                id="device-search-field"
                label="App Description"
                className={classes.textField}
                onChange={(event) => this.handleChange("appDescription", event)}
                onKeyPress={(e) => appName !== "" && this.enterPressed(e)}
                margin="normal"
              />
            </DialogContent>
            <div className={classes.horizontalContainer}>
              <Button
                variant="contained"
                color="primary"
                className={classes.button2}
                disabled={appName === ""}
                onClick={this.fileUpload}
              >
                Upload
              </Button>
              <Button
                variant="contained"
                color="primary"
                className={classes.button2}
                onClick={this.handleClose}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Dialog>
      </div>
    );
  }
}

WebkeyAppManagement.propTypes = {
  classes: PropTypes.object.isRequired, //eslint-disable-line
  viewer: PropTypes.object.isRequired, //eslint-disable-line
};

export default compose(
  withStyles(styles),
  withSnackbar()
)(
  createRefetchContainer(WebkeyAppManagement, {
    viewer: graphql`
      fragment WebkeyAppManagement_viewer on Viewer {
        allWebkeyDevices {
          edges {
            node {
              id
              DisplayName
              Serial
              DeviceNick
              DeviceBrand
              DeviceModel
              WebkeyVersion
              Location
              LastConnected
              AndroidAPI
              AndroidVersion
              Rooted
              Online
            }
          }
        }
        allAudioDevices {
          edges {
            node {
              id
              name
              macAddress
              ethernetMacAddress
              clientId
              webkeyId
            }
          }
        }
        allWebkeyPackages {
          edges {
            node {
              id
              appName
              keyName
              source
              packageName
              description
              versionCode
              versionName
              icon
            }
          }
        }
      }
    `,
  })
);
