/* eslint-disable react/prop-types */
import React, { Component } from "react";
import { withRouter } from "found";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Divider from "@material-ui/core/Divider";
import Chip from "@material-ui/core/Chip";
import FaceIcon from "@material-ui/icons/Face";

const styles = (theme) => ({
  card: {
    display: "flex",
    flexDirection: "column",
    background: theme.palette.background.paper,
    margin: 4,
  },
  horizontalContainer: {
    padding: 4,
    display: "flex",
    flexWrap: "wrap",
  },
  verticalContainer: {
    padding: 4,
    display: "flex",
    flexDirection: "column",
  },
  chip: {
    margin: 1,
  },
});

class OnlineUsers extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    const { classes, viewer } = this.props;
    const onlineUsers = viewer.allUsers.edges.filter(
      (item) => item?.node?.status?.online
    );
    return (
      <Card className={classes.card} raised>
        <CardHeader title="Online users" subheader="See who is online" />
        <Divider />
        <CardContent className={classes.horizontalContainer}>
          <div className={classes.verticalContainer}>
            {onlineUsers.map((item) => (
              <Chip
                key={item.node.id}
                className={classes.chip}
                color="primary"
                icon={<FaceIcon />}
                variant="outlined"
                label={
                  item.node.profile
                    ? `${item.node.profile.name} -  ${item.node.emails[0].address}`
                    : item.node.emails[0].address
                }
              />
            ))}
          </div>
        </CardContent>
        <Divider />
      </Card>
    );
  }
}

OnlineUsers.propTypes = {
  classes: PropTypes.object.isRequired, //eslint-disable-line
  viewer: PropTypes.object.isRequired, // eslint-disable-line
};

export default withRouter(withStyles(styles)(OnlineUsers));
