import { commitMutation } from "react-relay";
import graphql from 'babel-plugin-relay/macro';
import Environment from "../../environmentLogin";

const mutation = graphql`
  mutation verifyEmailMutation($inputemail: String!, $inputtoken: String!) {
    verifyEmail(email: $inputemail, token: $inputtoken) {
      id
    }
  }
`;

export default (email, token, callback) => {
  const variables = {
    inputemail: email,
    inputtoken: token,
  };

  commitMutation(Environment, {
    mutation,
    variables,
    onCompleted: () => {
      callback();
    },
    onError: () => console.error("Invalid token"), // eslint-disable-line
  });
};
