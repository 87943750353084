import { commitMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import Environment from "../../environment";

const mutation = graphql`
  mutation createSonosRouteFolderMutation(
    $menuId: String!
    $name: String!
    $parentId: Int!
    $autoPopulateType: _AutoPopulateTypes!
    $coverName: String
    $cover_40: String
    $cover_290: String
    $cover_legacy: String
    $playableType: _PlayableTypes
  ) {
    createSonosRouteFolder(
      menuId: $menuId
      name: $name
      parentId: $parentId
      autoPopulateType: $autoPopulateType
      coverName: $coverName
      cover_40: $cover_40
      cover_290: $cover_290
      cover_legacy: $cover_legacy
      playableType: $playableType
    ) {
      __typename
      id
      name
      description
      schema {
        name
        pathId
        parentId
        coverName
        cover_40
        cover_290
        cover_legacy
        playlistIds
        sceneIds
        autoPopulateType
        category
        subCategory
        genres
        routeItems
        playableType
      }
      active
      dateCreated
      dateModified
      clientId
    }
  }
`;

export default (
  menuId,
  name,
  parentId,
  autoPopulateType,
  coverName,
  cover_40,
  cover_290,
  cover_legacy,
  playableType,
  callback
) => {
  const variables = {
    menuId,
    name,
    parentId,
    autoPopulateType,
    coverName,
    cover_40,
    cover_290,
    cover_legacy,
    playableType,
  };

  commitMutation(Environment, {
    mutation,
    variables,
    onCompleted: (response) => {
      callback();
    },
    onError: (err) => console.error(err), // eslint-disable-line
  });
};
