import { commitMutation } from "react-relay";
import graphql from 'babel-plugin-relay/macro';
import Environment from "../../environment";

const mutation = graphql`
  mutation editUserSettingsMutation($consoleSettings: InputConsoleSettings) {
    editUserSettings(consoleSettings: $consoleSettings) {
      id
      profile {
        name
      }
      consoleSettings {
        audioDevices {
          appDetails
          logs
          nowPlaying
          systemDetailsControls
          user
          tags
        }
        videoDevices {
          appDetails
          deviceActions
          systemDetailsControls
          tags
        }
        users {
          details
          actions
          permissions
          logs
        }
      }
    }
  }
`;

export default (consoleSettings, callback) => {
  const variables = {
    consoleSettings,
  };

  commitMutation(Environment, {
    mutation,
    variables,
    onCompleted: (response) => {
      callback(response.editUserSettings);
    },
    onError: (err) => console.error(err), // eslint-disable-line
  });
};
