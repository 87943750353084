/**
 * External dependencies
 */
import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";

import { Check, Clear } from "@material-ui/icons";

/**
 * Internal dependencies
 */

import { CurrentMenuContext } from "../../hooks/currentMenuProvider";

import deleteSonosRouteFolderMutation from "../../../mutations/deleteSonosRouteFolderMutation";
import deleteQsysRouteFolderMutation from "../../../mutations/deleteQsysRouteFolderMutation";
import { CurrentClientContext } from "../../hooks/currentClientProvider";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 500,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: "35vh",
    margin: "auto",
    left: 0,
    right: 0,
    borderRadius: 19,
  },
}));

const getPathIds = (route = {}, returnArray = []) => {
  if (returnArray.length === 0) {
    returnArray.push(route.pathId);
    return getPathIds(route, returnArray);
  }
  route.children.forEach((item) => {
    returnArray.push(item.pathId);
    return getPathIds(item, returnArray);
  });
  return returnArray;
};

export default function DeleteRouteModal({ route, handleClose }) {
  const { audioProvider } = useContext(CurrentClientContext);
  const { currentMenu, currentMenuExtension } = useContext(CurrentMenuContext);
  const classes = useStyles();

  const handledMenu = currentMenuExtension || currentMenu;

  const handleDelete = () => {
    audioProvider === "sonos"
      ? deleteSonosRouteFolderMutation(handledMenu.id, getPathIds(route), () =>
          handleClose()
        )
      : deleteQsysRouteFolderMutation(handledMenu.id, getPathIds(route), () =>
          handleClose()
        );
  };

  return (
    <>
      <div className={classes.paper}>
        <p>
          Are you sure you want to delete route from <b>{handledMenu?.name}</b>{" "}
          menu? <br />
          This action will delete all subfolders of current path.
        </p>
        <IconButton
          aria-label="set-ok"
          className={classes.button}
          onClick={handleDelete}
        >
          <Check />
        </IconButton>
        <IconButton
          aria-label="set-clear"
          className={classes.button}
          onClick={() => handleClose()}
        >
          <Clear />
        </IconButton>
      </div>
    </>
  );
}
