const permissions = (elementId, methods) => {
  if (elementId) {
    return (
      methods.filter(({ node }) => node.UIElementsID.includes(elementId))
        .length > 0
    );
  }
  return false;
};

export default permissions;
