import { Environment, Network, RecordSource, Store } from "relay-runtime";
import fetch from "isomorphic-fetch";
import config from "./config";
import persistedQueries from "./relay/persisted-queries"
// Define a function that fetches the results of an operation (query/mutation/etc)
// and returns its results as a Promise:
function fetchQuery(operation, variables) {
  return fetch(config.URL_PATH_LOGIN, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      operationName: operation.name,
      query: persistedQueries[operation.id],
      variables,
    }),
  }).then((response) => response.json());
}

// with the store and network available you can instantiate the actual Environment
const network = Network.create(fetchQuery);
const source = new RecordSource();
const store = new Store(source);

// export the environment from this module
export default new Environment({
  network,
  store,
});
