import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

const styles = (theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  formControlLabel: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  menu: {
    width: 200,
  },
});

class AudioClientStep1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        name: "",
        useExplicit: false,
        noVolumeBar: false,
        noPlayPause: false,
      },
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleUseExplicit = this.handleUseExplicit.bind(this);
    this.handleNoVolumeBar = this.handleNoVolumeBar.bind(this);
    this.handleNoPlayPause = this.handleNoPlayPause.bind(this);
  }

  handleChange(e) {
    this.setState({
      data: { ...this.state.data, [e.target.id]: e.target.value },
    });
    this.props.myCallBack(e.target); //eslint-disable-line
  }

  handleClick(e) {
    e.preventDefault();
    const name = e.target.value;
    this.setState({
      data: { ...this.state.data, [name]: !this.state.data.allowBundle },
    });
    this.props.myCallBack(this.state.data); //eslint-disable-line
  }

  handleUseExplicit(e) {
    console.log(e.target);
    const useExplicit = !this.state.data?.useExplicit;
    this.setState({
      data: { ...this.state.data, useExplicit },
    });
    this.props.myCallBack({ id: e.target.id, value: useExplicit });
  }

  handleNoVolumeBar(e) {
    const noVolumeBar = !this.state.data?.noVolumeBar;
    this.setState({
      data: { ...this.state.data, noVolumeBar },
    });
    this.props.myCallBack({ id: e.target.id, value: noVolumeBar });
  }

  handleNoPlayPause(e) {
    console.log(e.target.id);
    const noPlayPause = !this.state.data?.noPlayPause;
    this.setState({
      data: { ...this.state.data, noPlayPause },
    });
    this.props.myCallBack({ id: e.target.id, value: noPlayPause });
  }

  render() {
    const { classes } = this.props;
    return (
      <form className={classes.container} noValidate autoComplete="off">
        <TextField
          id="name"
          label="Name"
          className={classes.textField}
          value={this.state.data.name}
          onChange={this.handleChange}
          margin="normal"
        />
        <FormControlLabel
          id="useExplicit"
          className={classes.formControlLabel}
          control={
            <Checkbox
              id="useExplicit"
              checked={this.state.data.useExplicit}
              onClick={this.handleUseExplicit}
              value={this.state.data.useExplicit}
            />
          }
          label="Use explicit content for this Client?"
        />
        <FormControlLabel
          id="noVolumeBar"
          className={classes.formControlLabel}
          control={
            <Checkbox
              id="noVolumeBar"
              checked={this.state.data.noVolumeBar}
              onClick={this.handleNoVolumeBar}
              value="noVolumeBar"
            />
          }
          label="Remove volume bar for Curator users"
        />
        <FormControlLabel
          id="noPlayPause"
          className={classes.formControlLabel}
          control={
            <Checkbox
              id="noPlayPause"
              checked={this.state.data.noPlayPause}
              onClick={this.handleNoPlayPause}
              value="noPlayPause"
            />
          }
          label="Remove play/pause button for users"
        />
      </form>
    );
  }
}

AudioClientStep1.propTypes = {
  classes: PropTypes.object.isRequired, //eslint-disable-line
};

export default withStyles(styles)(AudioClientStep1);
