import { commitMutation } from "react-relay";
import graphql from 'babel-plugin-relay/macro';
import Environment from "../../environment";

const mutation = graphql`
  mutation updateAudioDeviceMutation(
    $inputid: String!
    $updateAudioDeviceProvider: updateAudioDeviceProviderData!
  ) {
    updateAudioDevice(
      id: $inputid
      updateAudioDeviceProvider: $updateAudioDeviceProvider
    ) {
      id
      remoteVolume
      currentPlaylistId
      currentSceneId
      scheduleIds
      remoteVolumeMode
      remotePlaylistMode
    }
  }
`;

export default (
  id2,
  remoteVolume,
  currentPlaylistId,
  currentSceneId,
  scheduleIds,
  remoteVolumeMode,
  remotePlaylistMode,
  callback
) => {
  const variables = {
    inputid: id2,
    updateAudioDeviceProvider: {
      remoteVolume,
      currentPlaylistId,
      currentSceneId,
      scheduleIds,
      remoteVolumeMode,
      remotePlaylistMode,
    },
  };

  commitMutation(Environment, {
    mutation,
    variables,
    onCompleted: () => callback(),
    onError: (err) => console.error(err), // eslint-disable-line
  });
};
