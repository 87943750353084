import { commitMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import Environment from "../../environment";

const mutation = graphql`
  mutation updateVideoDeviceDescriptionMutation(
    $inputDeviceId: String!
    $inputNewDescription: String!
  ) {
    updateVideoDeviceDescription(
      deviceId: $inputDeviceId
      newDescription: $inputNewDescription
    ) {
      id
    }
  }
`;

export default (deviceId, newDescription, callback) => {
  const variables = {
    inputDeviceId: deviceId,
    inputNewDescription: newDescription,
  };

  commitMutation(Environment, {
    mutation,
    variables,
    onCompleted: () => {
      callback();
    },
    onError: (err) => console.error(err), // eslint-disable-line
  });
};
