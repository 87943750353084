/* eslint-disable no-underscore-dangle, no-return-assign */

import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { withStyles } from "@material-ui/core/styles";
import compose from "recompose/compose";
import { withSnackbar } from "material-ui-snackbar-provider";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import Tooltip from "@material-ui/core/Tooltip";
import CloseIcon from "@material-ui/icons/Close";
import ReactTable from "react-table";
import checkboxHOC from "react-table/lib/hoc/selectTable";
import "react-table/react-table.css";
import editVideoCampaignMutation from "../mutations/editVideoCampaignMutation";

const CheckboxTable = checkboxHOC(ReactTable);

const styles = (theme) => ({
  root: {
    width: "90%",
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    flex: "auto",
    minWidth: 100,
  },
  textField2: {
    marginLeft: 2 * theme.spacing(1),
  },
  horizontalContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  verticalContainer: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
  },
  step0: {
    margin: "8px auto 8px 16px",
    minWidth: 166,
  },
  step1: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    flex: "auto",
    minWidth: 100,
  },
  button: {
    margin: "8px 8px 8px 8px",
    maxWidth: 140,
  },
  close: {
    position: "relative",
    direction: "rtl",
  },
  closeIcon: {
    height: 28,
    width: 28,
  },
  card: {
    flex: "auto",
    margin: "2px 16px 2px 16px",
  },
  cardContent: {
    padding: "8px 8px 8px 16px",
  },
});

function getSteps() {
  return [
    "Select Client",
    "Set Campaign name",
    "Select Devices",
    "Upload Video Formats",
    "Select Go live date",
  ];
}

class editCampaign extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: 0,
      selectedCampaign: this.props.selectedCampaign,
      selectAll: false,
      goLiveTime: "",
      goLiveDate: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeDate = this.handleChangeDate.bind(this);
    this.handleChangeProfile = this.handleChangeProfile.bind(this);
    this.handleChangeTime = this.handleChangeTime.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.toggleSelection = this.toggleSelection.bind(this);
    this.toggleAll = this.toggleAll.bind(this);
    this.isSelected = this.isSelected.bind(this);
  }

  handleChange(event, name) {
    const { activeStep } = this.state;
    if (activeStep === 0) {
      this.setState({
        selectedCampaign: {
          ...this.state.selectedCampaign,
          [name]: event.target.value,
          deviceIds: [],
          profileIds: [],
        },
        selectAll: false,
      });
    } else {
      this.setState({
        selectedCampaign: {
          ...this.state.selectedCampaign,
          [name]: event.target.value,
        },
      });
    }
  }

  handleChangeDate(event) {
    this.setState({
      goLiveDate: event.target.value,
    });
  }

  handleChangeProfile(event, index) {
    const tempArray = [...this.state.selectedCampaign.profileIds];
    tempArray.splice(index, 1, event.target.value);

    this.setState({
      selectedCampaign: {
        ...this.state.selectedCampaign,
        profileIds: tempArray,
      },
    });
  }

  handleChangeTime(value) {
    // const timeArray = value.target.value.split(/:/, 2);
    this.setState({
      goLiveTime: value.target.value,
    });
  }

  handleNext() {
    const { activeStep, selectedCampaign, goLiveDate, goLiveTime } = this.state;
    const date = new Date(`${goLiveDate}T${goLiveTime}:00`);

    if (activeStep === 2) {
      const data = this.props.viewer.allVideoDevices.edges
        .filter((item) => item && item.node)
        .map((device) => device.node);

      const filteredData = [...data].filter(
        (device) => device.clientId === this.state.selectedCampaign.clientId
      );

      const blankArray = _.uniq(
        _.intersectionBy(
          filteredData,
          selectedCampaign.deviceIds.map((item) => ({ id: item })),
          "id"
        ).map((item) => item.format)
      ).map(() => "");

      this.setState({
        selectedCampaign: {
          ...this.state.selectedCampaign,
          profileIds:
            this.state.selectedCampaign.profileIds.length === blankArray.length
              ? this.state.selectedCampaign.profileIds
              : blankArray,
        },
      });
    }

    if (activeStep === 4) {
      editVideoCampaignMutation(
        selectedCampaign.id,
        selectedCampaign.name,
        selectedCampaign.description,
        selectedCampaign.clientId,
        date,
        selectedCampaign.deviceIds,
        selectedCampaign.profileIds,
        false,
        () => {
          this.props.handleClose();
          return this.props.snackbar.showMessage(
            "Video Campaign edit successfull."
          );
        }
      );
    }
    this.setState({ activeStep: activeStep + 1 });
  }

  handleBack() {
    const { activeStep } = this.state;
    this.setState({
      activeStep: activeStep - 1,
    });
  }

  toggleSelection(bruteKey /* , shift, row */) {
    const key = bruteKey.replace("select-", "");
    const { selectedCampaign } = this.state;
    let selection = [...this.state.selectedCampaign.deviceIds];
    const keyIndex = selection.indexOf(key);
    if (keyIndex >= 0) {
      selection = [
        ...selection.slice(0, keyIndex),
        ...selection.slice(keyIndex + 1),
      ];
    } else {
      selection.push(key);
    }

    const data = this.props.viewer.allVideoDevices.edges
      .filter((item) => item && item.node)
      .map((device) => device.node);

    const filteredData = [...data].filter(
      (device) => device.clientId === this.state.selectedCampaign.clientId
    );

    const blankArray = _.uniq(
      _.intersectionBy(
        filteredData,
        selectedCampaign.deviceIds.map((item) => ({ id: item })),
        "id"
      ).map((item) => item.format)
    ).map(() => "");

    this.setState({
      selectedCampaign: {
        ...this.state.selectedCampaign,
        deviceIds: selection,
        profileIds:
          this.state.selectedCampaign.profileIds.length === blankArray.length
            ? this.state.selectedCampaign.profileIds
            : blankArray,
      },
    });
  }

  toggleAll() {
    const { selectedCampaign } = this.state;
    const selectAll = !this.state.selectAll;
    const deviceIds = [];
    if (selectAll) {
      const wrappedInstance = this.checkboxTable.getWrappedInstance();
      const currentRecords = wrappedInstance.getResolvedState().sortedData;
      currentRecords.forEach((item) => {
        deviceIds.push(item._original.id);
      });
    }

    const data = this.props.viewer.allVideoDevices.edges
      .filter((item) => item && item.node)
      .map((device) => device.node);

    const filteredData = [...data].filter(
      (device) => device.clientId === this.state.selectedCampaign.clientId
    );

    const blankArray = _.uniq(
      _.intersectionBy(
        filteredData,
        selectedCampaign.deviceIds.map((item) => ({ id: item })),
        "id"
      ).map((item) => item.format)
    ).map(() => "");

    this.setState({
      selectAll,
      selectedCampaign: {
        ...this.state.selectedCampaign,
        deviceIds,
        profileIds:
          this.state.selectedCampaign.profileIds.length === blankArray.length
            ? this.state.selectedCampaign.profileIds
            : blankArray,
      },
    });
  }

  isSelected(key) {
    return this.state.selectedCampaign.deviceIds.includes(key);
  }

  render() {
    const { classes, viewer } = this.props;
    const {
      selectedCampaign,
      activeStep,
      selectAll,
      goLiveDate,
      goLiveTime,
    } = this.state;
    const { toggleSelection, toggleAll, isSelected } = this;
    const steps = getSteps();

    const checkboxProps = {
      selectAll,
      isSelected,
      toggleSelection,
      toggleAll,
      selectType: "checkbox",
    };

    const data = this.props.viewer.allVideoDevices.edges
      .filter((item) => item && item.node)
      .map((item) => item.node);

    const filteredData = [...data].filter(
      (device) => device.clientId === this.state.selectedCampaign.clientId
    );
    const allTagsFiltered = this.props.viewer.allTags.edges.filter(
      (item) => item && item.node
    );

    const fixedColumns = [
      {
        id: "isPlaying",
        Header: "Playing",
        accessor: (a) => (a.isPlaying ? "Yes" : "No"),
        maxWidth: 70,
      },
      {
        Header: "Name",
        accessor: "name",
        maxWidth: 700,
      },
      {
        Header: "Format",
        accessor: "format",
        maxWidth: 70,
      },
      {
        Header: "Currently Playing",
        accessor: "currentlyPlaying",
        maxWidth: 400,
      },
      {
        id: "tags",
        Header: "Device Tags",
        accessor: (a) =>
          a.tags
            ? a.tags
                .map(
                  (tag) =>
                    allTagsFiltered.filter((item) => item.node.id === tag)[0]
                      .node.name
                )
                .join(", ")
            : null,
        maxWidth: 200,
      },
    ];

    return (
      <div>
        <div className={classes.horizontalContainer}>
          <Typography
            variant="subtitle1"
            id="modal-edit-title"
            align="center"
            style={{
              flex: "auto",
              alignSelf: "center",
            }}
          >
            {`Video Campaign Wizzard - ${selectedCampaign.name}`}
          </Typography>
          <Tooltip
            id="tooltip-bottom"
            title="Close"
            placement="bottom"
            enterTouchDelay={200}
          >
            <IconButton aria-label="Close">
              <CloseIcon
                className={classes.closeIcon}
                onClick={this.props.handleClose}
              />
            </IconButton>
          </Tooltip>
        </div>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <Divider />
        <div className={classes.verticalContainer}>
          {activeStep === 0 && (
            <FormControl className={classes.step0}>
              <InputLabel>Video Client</InputLabel>
              <Select
                native
                autoWidth
                value={selectedCampaign.clientId}
                onChange={(event) => this.handleChange(event, "clientId")}
              >
                <option value="" />
                {viewer.allVideoClients.edges
                  .filter((item) => item && item.node)
                  .map((item) => (
                    <option key={item.node.id} value={item.node.id}>
                      {item.node.name}
                    </option>
                  ))}
              </Select>
            </FormControl>
          )}
          {activeStep === 1 && (
            <div className={classes.horizontalContainer}>
              <TextField
                id="name"
                label="Name"
                className={classes.textField}
                value={selectedCampaign.name}
                onChange={(event) => this.handleChange(event, "name")}
                margin="normal"
              />
              <TextField
                id="description"
                label="Description"
                className={classes.textField}
                value={selectedCampaign.description}
                onChange={(event) => this.handleChange(event, "description")}
                margin="normal"
              />
            </div>
          )}
          {activeStep === 2 && (
            <CheckboxTable
              ref={(r) => (this.checkboxTable = r)}
              keyField="id"
              data={filteredData}
              columns={fixedColumns}
              filterable
              showPageSizeOptions={false}
              defaultFilterMethod={(filter, row) =>
                String(row[filter.id])
                  .toLowerCase()
                  .includes(filter.value.toLowerCase())
              }
              defaultPageSize={10}
              className="-striped -highlight"
              style={{
                display: "block",
                fontSize: "12px",
                flex: "auto",
                margin: 4,
              }}
              {...checkboxProps}
            />
          )}
          {activeStep === 3 && (
            <div
              className={classes.verticalContainer}
              style={{ paddingBottom: 6 }}
            >
              {_.uniq(
                _.intersectionBy(
                  filteredData,
                  selectedCampaign.deviceIds.map((item) => ({ id: item })),
                  "id"
                ).map((item) => item.format)
              ).map((item, i) => (
                <div className={classes.card} key={item}>
                  <FormControl className={classes.step0}>
                    <InputLabel shrink>{`Format ${item}`}</InputLabel>
                    <Select
                      native
                      autoWidth
                      value={selectedCampaign.profileIds[i]}
                      error={selectedCampaign.profileIds[i] === ""}
                      onChange={(event) => this.handleChangeProfile(event, i)}
                    >
                      <option value={selectedCampaign.profileIds[i]}>
                        {
                          viewer.allVideoProfiles.edges
                            .filter((profile) => profile && profile.node)
                            .filter(
                              (profile) =>
                                profile.node.id ===
                                selectedCampaign.profileIds[i]
                            )
                            .map((profile) => profile.node.name)[0]
                        }
                      </option>
                      {viewer.allVideoProfiles.edges
                        .filter((profile) => profile && profile.node)
                        .filter(
                          (profile) =>
                            profile.node.id !== selectedCampaign.profileIds[i]
                        )
                        .filter(
                          (profile) =>
                            profile.node.clientId === selectedCampaign.clientId
                        )
                        .filter((profile) => profile.node.format === item)
                        .map((profile) => (
                          <option key={profile.node.id} value={profile.node.id}>
                            {profile.node.name}
                          </option>
                        ))}
                    </Select>
                  </FormControl>
                </div>
              ))}
            </div>
          )}
          {activeStep === 4 && (
            <div className={classes.horizontalContainer}>
              <TextField
                id="startDate"
                label="Start Date"
                className={classes.textField2}
                type="date"
                value={goLiveDate}
                onChange={(value) => this.handleChangeDate(value)}
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                id="goLiveTime"
                label="Start Time"
                className={classes.textField2}
                type="time"
                value={goLiveTime}
                onChange={(value) => this.handleChangeTime(value)}
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 900, // 15 min
                }}
              />
            </div>
          )}
          {activeStep !== 5 ? (
            <div className={classes.horizontalContainer}>
              <Button
                disabled={activeStep === 0}
                onClick={this.handleBack}
                className={classes.backButton}
              >
                Back
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={this.handleNext}
                disabled={
                  (activeStep === 0 && selectedCampaign.clientId === "") ||
                  (activeStep === 1 && selectedCampaign.name === "") ||
                  (activeStep === 2 &&
                    selectedCampaign.deviceIds.length === 0) ||
                  (activeStep === 3 &&
                    selectedCampaign.profileIds.filter(
                      (profile) => profile === ""
                    ).length !== 0) ||
                  (activeStep === 4 && (goLiveTime === "" || goLiveDate === ""))
                }
              >
                {activeStep === steps.length - 1 ? "Finish" : "Next"}
              </Button>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

editCampaign.propTypes = {
  classes: PropTypes.object.isRequired, //eslint-disable-line
  viewer: PropTypes.object, //eslint-disable-line
  selectedCampaign: PropTypes.object.isRequired, //eslint-disable-line
  snackbar: PropTypes.object, //eslint-disable-line
  handleClose: PropTypes.func, //eslint-disable-line
};

export default compose(withStyles(styles), withSnackbar())(editCampaign);
