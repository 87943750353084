/* eslint-disable no-unused-vars */
/* eslint-disable
no-return-assign,
arrow-body-style */

import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import SyncIcon from "@material-ui/icons/Sync";
import DeleteIcon from "@material-ui/icons/Delete";
import ClearIcon from "@material-ui/icons/Clear";
import CreateIcon from "@material-ui/icons/Create";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import ReactTable from "react-table";
import "react-table/react-table.css";
import ScheduleEdit from "./ScheduleEdit";
import ScheduleAssign from "./ScheduleAssign";
import deleteScheduleMutation from "../mutations/deleteScheduleMutation";
import duplicateScheduleMutation from "../mutations/duplicateScheduleMutation";
import permissions from "../authentication/isPermited";
import AddCircleIcon from "@material-ui/icons/AddCircle";
const styles = {
  root: {
    width: "100%",
  },
  button: {
    margin: 0,
  },
  horizontalContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  typo3: {
    alignSelf: "center",
    paddingLeft: 16,
  },
};

const openRow = "#65c8d0";

class ScheduleList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showEditSchedule: false,
      showAssignSchedule: false,
      scheduleAssignID: "",
      scheduleAssignClientID: "",
      data: {
        name: "",
        injectable: false,
        startDate: new Date().toJSON().slice(0, 10),
        endDate: new Date().toJSON().slice(0, 10),
      },
      addedPlaylists: [],
      addedPlaylistsVolume: [],
      addedPlaylistsTime: [],
      days: [],
      ask: [],
      expanded: {},
    };
    this.handleClose = this.handleClose.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleAssign = this.handleAssign.bind(this);
    this.handleAsk = this.handleAsk.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  handleEdit(open, newState, scheduleId) {
    this.setState({
      [open]: true,
      ...newState,
    });
  }

  handleAssign(open, id, clientId) {
    this.setState({
      [open]: true,
      scheduleAssignID: id,
      scheduleAssignClientID: clientId,
    });
  }

  handleAsk(row, operation) {
    const ask = [...this.state.ask];
    if (operation === "push") {
      ask.push(row.index);
    } else if (operation === "splice") {
      const index = ask.indexOf(row.index);
      if (index > -1) {
        ask.splice(index, 1);
      }
    }
    this.setState({
      ask,
    });
  }

  handleClose() {
    this.setState({
      showEditSchedule: false,
      showAssignSchedule: false,
    });
  }

  handleDelete(row) {
    const expanded = this.state.expanded;
    const ask = this.state.ask;
    delete expanded[row.index];
    delete ask[row.index];
    this.setState({ expanded, ask });
    deleteScheduleMutation(row.original.id);
  }

  render() {
    const { viewer, classes, clientId } = this.props;
    const {
      showEditSchedule,
      showAssignSchedule,
      ask,
      scheduleAssignID,
      scheduleAssignClientID,
    } = this.state;
    const data =
      clientId !== ""
        ? viewer.allSchedule.edges
            .filter((item) => item && item.node)
            .filter((item) =>
              item && item.node ? item.node.clientId === clientId : false
            )
            .map(({ node }) => node)
        : viewer.allSchedule.edges
            .filter((item) => item && item.node)
            .map(({ node }) => node);

    const pageSize = 20;

    const columns = [
      {
        Header: "Name",
        accessor: "name",
        minWidth: 250,
      },
      {
        Header: "Start Date",
        accessor: "startDate",
        width: 150,
      },
      {
        Header: "End Date",
        accessor: "endDate",
        width: 150,
      },
      {
        id: "injectable",
        Header: "Mode",
        accessor: (d) => (d.injectable ? "Injectable" : "Normal"),
        width: 100,
      },
    ];

    const columns2 = [
      {
        Header: "Playlist Name",
        accessor: "name",
        minWidth: 200,
      },
      {
        id: "startTime",
        Header: "Start Time",
        accessor: (c) => {
          const hour = Math.floor(c.startTime / 60);
          const minute = Math.round(
            (c.startTime / 60 - Math.floor(c.startTime / 60)) * 60
          );
          return `${hour.toLocaleString("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false,
          })}:${minute.toLocaleString("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false,
          })}`;
        },
        width: 100,
      },
      {
        id: "endTime",
        Header: "End Time",
        accessor: (e) => {
          const hour = Math.floor(e.endTime / 60);
          const minute = Math.round(
            (e.endTime / 60 - Math.floor(e.endTime / 60)) * 60
          );
          return `${hour.toLocaleString("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false,
          })}:${minute.toLocaleString("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false,
          })}`;
        },
        width: 100,
      },
      {
        Header: "Volume",
        accessor: "volume",
        width: 90,
      },
      {
        id: "shuffle",
        Header: "Shuffle",
        accessor: (b) => (b.shuffle ? "Yes" : "No"),
        width: 70,
      },
      {
        id: "days",
        Header: "Days",
        accessor: (a) => {
          const weekday = ["Mo ", "Tu ", "We ", "Th ", "Fr ", "Sa ", "Su "];
          a.days.map((day, i) => (day ? weekday[i] : (weekday[i] = "")));
          return weekday;
        },
        width: 170,
      },
    ];

    return (
      <div>
        {showEditSchedule && (
          <ScheduleEdit
            handleRequestClose={this.handleClose}
            open={showEditSchedule}
            data={this.state.data}
            addedPlaylists={this.state.addedPlaylists}
            addedPlaylistsVolume={this.state.addedPlaylistsVolume}
            addedPlaylistsTime={this.state.addedPlaylistsTime}
            days={this.state.days}
            viewer={viewer}
          />
        )}
        {showAssignSchedule && (
          <ScheduleAssign
            handleRequestClose={this.handleClose}
            open={showAssignSchedule}
            viewer={viewer}
            scheduleID={scheduleAssignID}
            clientId={scheduleAssignClientID}
          />
        )}
        <ReactTable
          data={data}
          columns={columns}
          expanded={this.state.expanded}
          onExpandedChange={(expanded) => this.setState({ expanded })}
          getTrProps={(state, rowInfo) => {
            const rowIndex = rowInfo ? rowInfo.index : null;
            return {
              style: {
                background: rowIndex % 2 === 0 ? "#F0F0F0" : "#FFFFFF",
              },
            };
          }}
          defaultPageSize={pageSize}
          showPagination
          filterable
          defaultFilterMethod={(filter, row) =>
            String(row[filter.id])
              .toLowerCase()
              .includes(filter.value.toLowerCase())
          }
          style={{
            fontSize: "13px",
          }}
          SubComponent={(row) => {
            const playingPlaylists = row.original.playingPlaylists;
            const newState = {
              data: {
                id: row.original.id,
                name: row.original.name,
                description: row.original.description,
                injectable: row.original.injectable,
                startDate: row.original.startDate,
                endDate: row.original.endDate,
                clientId: row.original.clientId,
              },
              addedPlaylists: playingPlaylists.map((playlist) => ({
                playlistId: playlist.playlistId,
                name: playlist.name,
                filenames: playlist.filenames,
              })),
              addedPlaylistsVolume: playingPlaylists.map(
                (playlist) => playlist.volume
              ),
              addedPlaylistsTime: playingPlaylists.map((playlist) => ({
                startTime: playlist.startTime,
                endTime: playlist.endTime,
                startTimeSeconds: playlist.startTimeSeconds,
                endTimeSeconds: playlist.endTimeSeconds,
                error: false,
              })),
              days: playingPlaylists.map((playlist) => playlist.days),
            };
            return (
              <div
                style={{
                  padding: "0px 2px 2px 2px",
                  background: openRow,
                }}
              >
                <div className={classes.horizontalContainer}>
                  {permissions([], [1, 2]) && (
                    <Tooltip
                      id="tooltip-bottom"
                      title="Duplicate Schedule"
                      placement="bottom"
                      enterTouchDelay={200}
                    >
                      <Button
                        variant="text"
                        size="small"
                        aria-label="duplicate"
                        className={classes.button}
                        onClick={() =>
                          duplicateScheduleMutation(row.original.id, () =>
                            console.log("done")
                          )
                        }
                      >
                        <FileCopyIcon />
                      </Button>
                    </Tooltip>
                  )}
                  {permissions([], [1, 2]) && (
                    <Tooltip
                      id="tooltip-bottom"
                      title="Edit Schedule"
                      placement="bottom"
                      enterTouchDelay={200}
                    >
                      <Button
                        variant="text"
                        size="small"
                        aria-label="edit"
                        className={classes.button}
                        onClick={() =>
                          this.handleEdit(
                            "showEditSchedule",
                            newState,
                            row.original.id
                          )
                        }
                      >
                        <CreateIcon />
                      </Button>
                    </Tooltip>
                  )}
                  {permissions([], [1, 2]) && (
                    <Tooltip
                      id="tooltip-bottom"
                      title="Assign schedule to devices"
                      placement="bottom"
                      enterTouchDelay={200}
                    >
                      <Button
                        variant="text"
                        size="small"
                        aria-label="assign"
                        className={classes.button}
                        onClick={() =>
                          this.handleAssign(
                            "showAssignSchedule",
                            row.original.id,
                            row.original.clientId
                          )
                        }
                      >
                        <AddCircleIcon />
                      </Button>
                    </Tooltip>
                  )}
                  {permissions([], [1, 2]) &&
                    ask.filter((item) => item === row.index).length === 0 && (
                      <Tooltip
                        id="tooltip-bottom"
                        title="Delete devices"
                        placement="bottom"
                        enterTouchDelay={200}
                      >
                        <Button
                          variant="text"
                          size="small"
                          aria-label="delete"
                          className={classes.button}
                          onClick={() => this.handleAsk(row, "push")}
                        >
                          <DeleteIcon />
                        </Button>
                      </Tooltip>
                    )}
                  {permissions([], [1, 2]) &&
                    ask.filter((item) => item === row.index).length === 1 && (
                      <div className={classes.horizontalContainer}>
                        <Typography
                          className={classes.typo3}
                          variant="subtitle1"
                          color="textSecondary"
                          align="center"
                        >
                          Are you sure?
                        </Typography>
                        <Tooltip
                          id="tooltip-bottom"
                          title="Cancel Delete"
                          placement="bottom"
                          enterTouchDelay={200}
                        >
                          <Button
                            variant="text"
                            size="small"
                            aria-label="delete"
                            className={classes.button}
                            onClick={() => this.handleAsk(row, "splice")}
                          >
                            <ClearIcon />
                          </Button>
                        </Tooltip>
                        <Tooltip
                          id="tooltip-bottom"
                          title="Delete Schedule"
                          placement="bottom"
                          enterTouchDelay={200}
                        >
                          <Button
                            variant="text"
                            size="small"
                            aria-label="delete"
                            className={classes.button}
                            onClick={() => this.handleDelete(row)}
                          >
                            <DeleteIcon />
                          </Button>
                        </Tooltip>
                      </div>
                    )}
                </div>
                <ReactTable
                  data={row.original.playingPlaylists}
                  className="-striped -highlight"
                  style={{
                    padding: "0px",
                    background: "#ffffff",
                  }}
                  columns={columns2}
                  defaultPageSize={row.original.playingPlaylists.length}
                  showPagination={false}
                />
              </div>
            );
          }}
        />
      </div>
    );
  }
}

ScheduleList.propTypes = {
  viewer: PropTypes.object.isRequired, //eslint-disable-line
  classes: PropTypes.object.isRequired, //eslint-disable-line
  clientId: PropTypes.string.isRequired, //eslint-disable-line
};

export default withStyles(styles)(ScheduleList);
