import { commitMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import Environment from "../../environment";

const mutation = graphql`
  mutation deleteAllEventsMutation {
    deleteAllEvents {
      id
    }
  }
`;

export default (callback) => {
  const variables = {
    input: {},
  };

  commitMutation(Environment, {
    mutation,
    variables,
    onCompleted: () => callback,
    onError: (err) => console.error(err), // eslint-disable-line
  });
};
