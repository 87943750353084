/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type _deviceStatusType = "ACTIVE" | "INACTIVE" | "REGISTERED" | "UNREGISTERED" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type Users_viewer$ref: FragmentReference;
declare export opaque type Users_viewer$fragmentType: Users_viewer$ref;
export type Users_viewer = {|
  +allClients: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: {|
        +id: string,
        +name: ?string,
      |}
    |}>
  |},
  +allVideoClients: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: {|
        +id: string,
        +name: string,
      |}
    |}>
  |},
  +allAudioDevices: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: {|
        +id: string,
        +isPlaying: ?boolean,
        +isOnline: ?boolean,
        +publicIpAddress: ?string,
        +name: ?string,
        +clientId: ?string,
        +usersId: ?$ReadOnlyArray<?{|
          +id: string
        |}>,
        +deviceStatus: ?_deviceStatusType,
      |}
    |}>
  |},
  +allUsers: {|
    +edges: ?$ReadOnlyArray<?{|
      +node: {|
        +id: string,
        +roles: ?$ReadOnlyArray<?string>,
        +createdAt: ?string,
        +apiKey: ?string,
        +services: ?{|
          +password: ?{|
            +bcrypt: ?string
          |},
          +google: ?{|
            +accessToken: ?string,
            +idToken: ?string,
            +id: ?string,
            +email: ?string,
            +name: ?string,
            +given_name: ?string,
            +family_name: ?string,
            +picture: ?string,
          |},
          +facebook: ?{|
            +accessToken: ?string,
            +id: ?string,
            +email: ?string,
            +name: ?string,
            +first_name: ?string,
            +last_name: ?string,
            +link: ?string,
            +gender: ?string,
            +locale: ?string,
            +verified: ?boolean,
          |},
        |},
        +emails: ?$ReadOnlyArray<?{|
          +address: ?string,
          +verified: ?boolean,
        |}>,
        +profile: ?{|
          +name: ?string,
          +clientId: ?string,
          +videoClientId: ?string,
          +sonosPermit: ?boolean,
          +qsysPermit: ?boolean,
        |},
        +rolesConsole: ?{|
          +roleAccess: ?number,
          +roleAudio: ?number,
          +roleVideo: ?number,
        |},
        +sonos: ?{|
          +songCount: ?number,
          +lastPlayedTimestamp: ?any,
          +extensionMenuId: ?string,
          +hideVolumeBar: ?boolean,
        |},
        +qsys: ?{|
          +pin: ?string,
          +multiTenant: ?boolean,
          +globalZoneLimit: ?number,
          +hideVolumeBar: ?boolean,
        |},
        +serviceType: ?$ReadOnlyArray<?string>,
        +defaultServiceType: ?string,
        +status: ?{|
          +online: boolean,
          +lastLogin: ?{|
            +date: ?any,
            +ipAddr: ?string,
          |},
        |},
        +companyName: ?string,
        +qsysData: ?{|
          +qsysDeviceIds: ?$ReadOnlyArray<?string>,
          +noOfCores: ?number,
          +noOfZones: ?number,
        |},
      |}
    |}>
  |},
  +allSonosMenu: ?$ReadOnlyArray<?{|
    +id: string,
    +name: ?string,
    +description: ?string,
    +clientId: ?string,
    +childrenMenus: ?$ReadOnlyArray<?{|
      +id: string,
      +name: ?string,
      +description: ?string,
    |}>,
  |}>,
  +user: {|
    +id: string,
    +consoleSettings: ?{|
      +users: ?{|
        +details: ?boolean,
        +actions: ?boolean,
        +permissions: ?boolean,
        +logs: ?boolean,
      |}
    |},
    +roles: ?$ReadOnlyArray<?string>,
    +companyName: ?string,
    +rolesConsole: ?{|
      +roleAccess: ?number,
      +roleAudio: ?number,
      +roleVideo: ?number,
    |},
  |},
  +$refType: Users_viewer$ref,
|};
export type Users_viewer$data = Users_viewer;
export type Users_viewer$key = {
  +$data?: Users_viewer$data,
  +$fragmentRefs: Users_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  (v0/*: any*/),
  (v1/*: any*/)
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "clientId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "roles",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "accessToken",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "verified",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "RolesConsole",
  "kind": "LinkedField",
  "name": "rolesConsole",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "roleAccess",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "roleAudio",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "roleVideo",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hideVolumeBar",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "companyName",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Users_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AudioClientConnection",
      "kind": "LinkedField",
      "name": "allClients",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AudioClientEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "AudioClient",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": (v2/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "VideoClientConnection",
      "kind": "LinkedField",
      "name": "allVideoClients",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "VideoClientEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "VideoClient",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": (v2/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AudioDeviceConnection",
      "kind": "LinkedField",
      "name": "allAudioDevices",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AudioDeviceEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "AudioDevice",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "isPlaying",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "isOnline",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "publicIpAddress",
                  "storageKey": null
                },
                (v1/*: any*/),
                (v3/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "User",
                  "kind": "LinkedField",
                  "name": "usersId",
                  "plural": true,
                  "selections": [
                    (v0/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "deviceStatus",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserConnection",
      "kind": "LinkedField",
      "name": "allUsers",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "UserEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "User",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v4/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "createdAt",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "apiKey",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "UserServices",
                  "kind": "LinkedField",
                  "name": "services",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Password",
                      "kind": "LinkedField",
                      "name": "password",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "bcrypt",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Google",
                      "kind": "LinkedField",
                      "name": "google",
                      "plural": false,
                      "selections": [
                        (v5/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "idToken",
                          "storageKey": null
                        },
                        (v0/*: any*/),
                        (v6/*: any*/),
                        (v1/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "given_name",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "family_name",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "picture",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Facebook",
                      "kind": "LinkedField",
                      "name": "facebook",
                      "plural": false,
                      "selections": [
                        (v5/*: any*/),
                        (v0/*: any*/),
                        (v6/*: any*/),
                        (v1/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "first_name",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "last_name",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "link",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "gender",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "locale",
                          "storageKey": null
                        },
                        (v7/*: any*/)
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "UserEmails",
                  "kind": "LinkedField",
                  "name": "emails",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "address",
                      "storageKey": null
                    },
                    (v7/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "UserProfile",
                  "kind": "LinkedField",
                  "name": "profile",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    (v3/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "videoClientId",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "sonosPermit",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "qsysPermit",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                (v8/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "SonosObject",
                  "kind": "LinkedField",
                  "name": "sonos",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "songCount",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "lastPlayedTimestamp",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "extensionMenuId",
                      "storageKey": null
                    },
                    (v9/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "QsysObject",
                  "kind": "LinkedField",
                  "name": "qsys",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "pin",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "multiTenant",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "globalZoneLimit",
                      "storageKey": null
                    },
                    (v9/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "serviceType",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "defaultServiceType",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Status",
                  "kind": "LinkedField",
                  "name": "status",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "online",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "LastLogin",
                      "kind": "LinkedField",
                      "name": "lastLogin",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "date",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "ipAddr",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                (v10/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "QsysData",
                  "kind": "LinkedField",
                  "name": "qsysData",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "qsysDeviceIds",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "noOfCores",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "noOfZones",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SonosMenu",
      "kind": "LinkedField",
      "name": "allSonosMenu",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        (v11/*: any*/),
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "SonosMenu",
          "kind": "LinkedField",
          "name": "childrenMenus",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            (v11/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "ConsoleSettings",
          "kind": "LinkedField",
          "name": "consoleSettings",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "UsersSettings",
              "kind": "LinkedField",
              "name": "users",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "details",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "actions",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "permissions",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "logs",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        (v4/*: any*/),
        (v10/*: any*/),
        (v8/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = '710e290d28b4a9e374040a393e931a2a';

module.exports = node;
