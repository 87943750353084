/**
 * @flow
 * @relayHash 7c89c5950e2c179cffa4508f29bb21d9
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type _ModelMutationType = "CREATED" | "DELETED" | "UPDATED" | "UPDATEDCLIENT" | "%future added value";
export type SceneSubscriptionVariables = {||};
export type SceneSubscriptionResponse = {|
  +SceneSubscription: {|
    +mutation: _ModelMutationType,
    +node: {|
      +id: string,
      +name: string,
      +description: ?string,
      +image: ?string,
      +size: ?number,
      +createdAt: ?any,
      +playlists: ?$ReadOnlyArray<?{|
        +playlistId: ?string
      |}>,
    |},
  |}
|};
export type SceneSubscription = {|
  variables: SceneSubscriptionVariables,
  response: SceneSubscriptionResponse,
|};
*/


/*
subscription SceneSubscription {
  SceneSubscription {
    mutation
    node {
      id
      name
      description
      image
      size
      createdAt
      playlists {
        playlistId
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "SceneSubscriptionPayload",
    "kind": "LinkedField",
    "name": "SceneSubscription",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "mutation",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Scene",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "image",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "size",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createdAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PlaylistQueueScenes",
            "kind": "LinkedField",
            "name": "playlists",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "playlistId",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "SceneSubscription",
    "selections": (v0/*: any*/),
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "SceneSubscription",
    "selections": (v0/*: any*/)
  },
  "params": {
    "id": "7c89c5950e2c179cffa4508f29bb21d9",
    "metadata": {},
    "name": "SceneSubscription",
    "operationKind": "subscription",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '423921b6a1d3c8671b2b721d8cfe7f24';

module.exports = node;
