/**
 * @flow
 * @relayHash 5fa24515a1d9b7817b856c2ccc34ca2d
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ServerInstance = "backup" | "primary" | "%future added value";
export type getAllDeviceCollectionMutationVariables = {|
  serverInstance: ServerInstance
|};
export type getAllDeviceCollectionMutationResponse = {|
  +getAllDeviceCollection: $ReadOnlyArray<?{|
    +id: string,
    +DisplayName: string,
    +Serial: string,
    +DeviceNick: ?string,
    +DeviceBrand: ?string,
    +DeviceModel: ?string,
    +WebkeyVersion: ?string,
    +Location: ?string,
    +LastConnected: ?number,
    +AndroidAPI: ?number,
    +AndroidVersion: ?string,
    +Rooted: ?boolean,
    +Online: ?boolean,
  |}>
|};
export type getAllDeviceCollectionMutation = {|
  variables: getAllDeviceCollectionMutationVariables,
  response: getAllDeviceCollectionMutationResponse,
|};
*/


/*
mutation getAllDeviceCollectionMutation(
  $serverInstance: ServerInstance!
) {
  getAllDeviceCollection(serverInstance: $serverInstance) {
    id
    DisplayName
    Serial
    DeviceNick
    DeviceBrand
    DeviceModel
    WebkeyVersion
    Location
    LastConnected
    AndroidAPI
    AndroidVersion
    Rooted
    Online
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "serverInstance"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "serverInstance",
        "variableName": "serverInstance"
      }
    ],
    "concreteType": "WebkeyDevice",
    "kind": "LinkedField",
    "name": "getAllDeviceCollection",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "DisplayName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "Serial",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "DeviceNick",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "DeviceBrand",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "DeviceModel",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "WebkeyVersion",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "Location",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "LastConnected",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "AndroidAPI",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "AndroidVersion",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "Rooted",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "Online",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "getAllDeviceCollectionMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "getAllDeviceCollectionMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "5fa24515a1d9b7817b856c2ccc34ca2d",
    "metadata": {},
    "name": "getAllDeviceCollectionMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '2de2e112ed4882d7ddb1705263a66034';

module.exports = node;
