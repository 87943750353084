export const playableTypes = [
  {
    value: "BOTH",
    label: "BOTH",
  },
  {
    value: "PLAYLISTS",
    label: "PLAYLISTS",
  },
  {
    value: "SCENES",
    label: "SCENES",
  },
];
