import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import _ from "lodash";
import ReactTable from "react-table";
import CastConnectedIcon from "@material-ui/icons/CastConnected";
import { Tooltip } from "@material-ui/core";
const styles = {
  info: {
    backgroundColor: "#387f86",
  },
  dialog: {
    // height: 400,
  },
};

class PlaylistInfoDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      fullWidth: true,
      maxWidth: "sm",
    };
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleClickOpen() {
    this.setState({ open: true });
  }

  handleClose() {
    this.setState({ open: false });
  }

  render() {
    const {
      classes,
      playlistId,
      allPlaybacks,
      allSchedule,
      allAudioClients,
      allAudioDevices,
    } = this.props;
    const { open, maxWidth } = this.state;

    const playbacksUsignPlaylist = allPlaybacks.filter((item) =>
      item.playlists
        .map((item2) => item2 && item2.playlistId)
        .includes(playlistId)
    );

    const scheduleUsingPlaylists = allSchedule.filter((item) =>
      item.playingPlaylists
        .map((item2) => item2.playlistId)
        .includes(playlistId)
    );

    const scheduleUsingPlaybacks = allSchedule.filter(
      (item) =>
        playbacksUsignPlaylist.filter((item2) =>
          item.playingPlaylists
            .map((item3) => item3.playlistId)
            .includes(item2.id)
        ).length > 0
    );

    const scheduleAffectedByPlaylist = _.union(
      scheduleUsingPlaylists,
      scheduleUsingPlaybacks
    );
    const devicesUsingPlaylist = allAudioDevices.filter(
      (item) => item.currentPlaylistId === playlistId
    );

    const devicesUsingSchedule = allAudioDevices.filter(
      (item) =>
        scheduleAffectedByPlaylist.filter((item2) =>
          item.scheduleIds ? item.scheduleIds.includes(item2.id) : false
        ).length > 0
    );

    const devicesAffectedByPlaylist = _.unionBy(
      devicesUsingPlaylist,
      devicesUsingSchedule,
      "id"
    );

    const dataTable = devicesAffectedByPlaylist.map((item) => {
      const newItem = {};
      newItem.deviceName = item ? item.name : "N/A";
      const clientName = allAudioClients.filter(
        (item2) => item2.id === item.clientId
      )[0];
      newItem.clientName = clientName ? clientName.name : "N/A";
      const scheduleList = allSchedule.filter((item2) =>
        item.scheduleIds.includes(item2.id)
      );
      newItem.scheduleName = scheduleList.map((item2) => item2.name).join(", ");
      return newItem;
    });

    const columns = [
      {
        Header: "Client",
        accessor: "clientName",
        minWidth: 200,
      },
      {
        Header: "Device",
        accessor: "deviceName",
        width: 200,
      },
    ];

    return (
      <React.Fragment>
        {this.props.usedInListView ? (
          <Tooltip
            id="tooltip-bottom"
            title="View clients & devices using this playlist"
            placement="bottom"
            enterTouchDelay={200}
          >
            <CastConnectedIcon
              onClick={this.handleClickOpen}
              style={{ color: "#757575", cursor: "pointer" }}
            />
          </Tooltip>
        ) : (
          <IconButton
            aria-label="Info"
            className={classes.info}
            onClick={this.handleClickOpen}
          >
            <InfoIcon />
          </IconButton>
        )}
        <Dialog
          className={classes.dialog}
          fullWidth
          maxWidth={maxWidth}
          open={open}
          onClose={this.handleClose}
          aria-labelledby="max-width-dialog-title"
        >
          <DialogTitle id="max-width-dialog-title">Playlist usage</DialogTitle>
          <DialogContent>
            {devicesAffectedByPlaylist.length > 0 ? (
              <ReactTable
                data={dataTable}
                className="-striped -highlight"
                style={{
                  fontSize: "13px",
                  padding: "0px",
                  background: "#ffffff",
                }}
                columns={columns}
                defaultPageSize={10}
                filterable
              />
            ) : (
              "(Not used by any device)"
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

PlaylistInfoDialog.propTypes = {
  classes: PropTypes.object.isRequired, // eslint-disable-line
  viewer: PropTypes.object.isRequired, // eslint-disable-line
  playlistId: PropTypes.string.isRequired,
  allPlaybacks: PropTypes.array.isRequired, // eslint-disable-line
  allSchedule: PropTypes.array.isRequired, // eslint-disable-line
  allAudioClients: PropTypes.array.isRequired, // eslint-disable-line
  allAudioDevices: PropTypes.array.isRequired, // eslint-disable-line
};

export default withStyles(styles)(PlaylistInfoDialog);
