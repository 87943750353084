/**
 * @flow
 * @relayHash fee7ad33df9b1eced1ccb1b69ce34a19
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type _ModelMutationType = "CREATED" | "DELETED" | "UPDATED" | "UPDATEDCLIENT" | "%future added value";
export type typeRoleEnum = "access" | "audio" | "video" | "%future added value";
export type MethodSubscriptionVariables = {||};
export type MethodSubscriptionResponse = {|
  +MethodSubscription: {|
    +mutation: _ModelMutationType,
    +node: ?{|
      +id: string,
      +displayName: string,
      +methodName: string,
      +description: ?string,
      +UIElementsID: $ReadOnlyArray<?string>,
      +roleType: typeRoleEnum,
      +userType: $ReadOnlyArray<?number>,
    |},
  |}
|};
export type MethodSubscription = {|
  variables: MethodSubscriptionVariables,
  response: MethodSubscriptionResponse,
|};
*/


/*
subscription MethodSubscription {
  MethodSubscription {
    mutation
    node {
      id
      displayName
      methodName
      description
      UIElementsID
      roleType
      userType
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "MethodSubscriptionPayload",
    "kind": "LinkedField",
    "name": "MethodSubscription",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "mutation",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Method",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "displayName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "methodName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "UIElementsID",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "roleType",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "userType",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "MethodSubscription",
    "selections": (v0/*: any*/),
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "MethodSubscription",
    "selections": (v0/*: any*/)
  },
  "params": {
    "id": "fee7ad33df9b1eced1ccb1b69ce34a19",
    "metadata": {},
    "name": "MethodSubscription",
    "operationKind": "subscription",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f8a66aec5eb0110fef7958ee96a44df7';

module.exports = node;
