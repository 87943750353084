/* eslint-disable react/prop-types */
import React, { Component } from "react";
import { withRouter } from "found";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";

const styles = (theme) => ({
  card: {
    display: "flex",
    flexDirection: "column",
    background: theme.palette.background.paper,
    margin: 4,
  },
  horizontalContainer: {
    padding: 4,
    display: "flex",
    flexWrap: "wrap",
  },
  verticalContainer: {
    padding: 4,
    display: "flex",
    flexDirection: "column",
  },
});

class UserIssuePanel extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    const { classes, viewer } = this.props;
    return (
      <Card className={classes.card} raised>
        <CardHeader
          title="Issue Panel"
          subheader="See below the current errors"
        />
        <Divider />
        <CardContent className={classes.horizontalContainer}>
          <div className={classes.verticalContainer}>
            {viewer.allUsers.edges
              .filter((item) => item.node)
              .filter((item) =>
                !item.node.rolesConsole
                  ? true
                  : !item.node.rolesConsole.roleAccess
              ).length > 0 && (
              <div
                style={{ background: "#ff6666" }}
                className={classes.horizontalContainer}
              >
                <Typography
                  variant="subtitle1"
                  style={{
                    color: "#ffffff",
                    padding: "3px 2px 2px 8px",
                    flex: "auto",
                  }}
                >
                  {`${
                    viewer.allUsers.edges
                      .filter((item) => item.node)
                      .filter((item) =>
                        !item.node.rolesConsole
                          ? true
                          : !item.node.rolesConsole.roleAccess
                      ).length
                  } - Users with no access to A-Console`}
                </Typography>
                <Button
                  size="medium"
                  style={{ color: "#ffffff" }}
                  className={classes.controls}
                  onClick={() => this.props.router.push("/home/users")}
                >
                  Details
                </Button>
              </div>
            )}
          </div>
        </CardContent>
        <Divider />
      </Card>
    );
  }
}

UserIssuePanel.propTypes = {
  classes: PropTypes.object.isRequired, //eslint-disable-line
  viewer: PropTypes.object.isRequired, // eslint-disable-line
};

export default withRouter(withStyles(styles)(UserIssuePanel));
