/**
 * @flow
 * @relayHash 4fc26ae1637865be39a9905d5dad8f65
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type updateVideoDeviceNameMutationVariables = {|
  deviceId: string,
  newName: string,
  newDescription?: ?string,
|};
export type updateVideoDeviceNameMutationResponse = {|
  +updateVideoDeviceName: ?{|
    +id: string
  |}
|};
export type updateVideoDeviceNameMutation = {|
  variables: updateVideoDeviceNameMutationVariables,
  response: updateVideoDeviceNameMutationResponse,
|};
*/


/*
mutation updateVideoDeviceNameMutation(
  $deviceId: String!
  $newName: String!
  $newDescription: String
) {
  updateVideoDeviceName(deviceId: $deviceId, newName: $newName, newDescription: $newDescription) {
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "deviceId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "newDescription"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "newName"
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "deviceId",
        "variableName": "deviceId"
      },
      {
        "kind": "Variable",
        "name": "newDescription",
        "variableName": "newDescription"
      },
      {
        "kind": "Variable",
        "name": "newName",
        "variableName": "newName"
      }
    ],
    "concreteType": "VideoDevice",
    "kind": "LinkedField",
    "name": "updateVideoDeviceName",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "updateVideoDeviceNameMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "updateVideoDeviceNameMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "id": "4fc26ae1637865be39a9905d5dad8f65",
    "metadata": {},
    "name": "updateVideoDeviceNameMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '2197a87121dd38f8fa1c155b65944917';

module.exports = node;
