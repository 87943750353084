import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import compose from "recompose/compose";
import { withSnackbar } from "material-ui-snackbar-provider";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import jwt from "jsonwebtoken";
import verifyEmailMutation from "../mutations/verifyEmailMutation";
import LoginLogo from "./LoginLogo";

const paperWidth = 375;

const styles = (theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    width: paperWidth,
  },
  root: theme.mixins.gutters({
    position: "relative",
    margin: "auto",
    marginTop: 100,
    width: paperWidth,
    paddingTop: 16,
    paddingBottom: 16,
    background: theme.palette.background.paper,
  }),
  button: {
    "&:hover": {
      background: "linear-gradient(45deg, #387f86 30%, #00bce4 90%)",
    },
    width: paperWidth,
    marginTop: 16,
    marginBottom: 25,
  },
  typography: {
    width: paperWidth,
  },
});

class VerifyEmail extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillMount() {
    localStorage.removeItem("user_id");
    localStorage.removeItem("authentication_token");
  }

  componentDidMount() {
    const link = window.location.href;
    const pos = link.lastIndexOf("/");
    const token = link.slice(pos + 1);
    const user = jwt.decode(token);
    if (user) {
      verifyEmailMutation(user.email, token, () => {
        setTimeout(() => this.props.router.replace("/login"), 10000);
      });
      return setTimeout(
        () => this.props.snackbar.showMessage("Redirecting..."),
        8000
      );
    }
    return this.props.snackbar.showMessage(
      "Invalid link. Please request another one."
    );
  }

  render() {
    const { classes } = this.props;
    return (
      <LoginLogo>
        <div>
          <Paper className={classes.root} elevation={10}>
            <Typography variant="h6" color="secondary" align="center">
              You verified your e-mail. Thank you!
            </Typography>
            <Typography variant="h6" color="secondary" align="center">
              Our admin needs to verify you also.
            </Typography>
          </Paper>
        </div>
      </LoginLogo>
    );
  }
}

VerifyEmail.propTypes = {
  classes: PropTypes.object.isRequired, //eslint-disable-line
  snackbar: PropTypes.object, //eslint-disable-line
  router: PropTypes.object, //eslint-disable-line
};

export default compose(withStyles(styles), withSnackbar())(VerifyEmail);
