import { commitMutation } from "react-relay";
import graphql from 'babel-plugin-relay/macro';
import Environment from "../../environment";

const mutation = graphql`
  mutation editVideoProfileMutation(
    $inputId: String!
    $input: videoProfileProviderData!
  ) {
    editVideoProfile(id: $inputId, videoProfileProvider: $input) {
      id
      name
      format
      volume
      url
      syncTime
      rebootTime
      clientId
    }
  }
`;

export default (
  id2,
  name,
  format,
  volume,
  url,
  syncTime,
  rebootTime,
  callback
) => {
  const variables = {
    inputId: id2,
    input: {
      name,
      format,
      volume,
      url,
      syncTime,
      rebootTime,
    },
  };

  commitMutation(Environment, {
    mutation,
    variables,
    onCompleted: (response) => {
      const id = response.editVideoProfile.id;
      callback(id);
    },
    onError: (err) => console.error(err), // eslint-disable-line
  });
};
