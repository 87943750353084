import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import DeleteIcon from "@material-ui/icons/Delete";
import _ from "lodash";
import compose from "recompose/compose";
import { withSnackbar } from "material-ui-snackbar-provider";
import updateAudioDeviceUsersMutation from "../../mutations/updateAudioDeviceUsersMutation";
import updateAudioDeviceClientMutation from "../../mutations/updateAudioDeviceClientMutation";

const styles = (theme) => ({
  root: {
    margin: "4px 0px 4px 0px",
    "&$disabled": {
      background: "#cccccc",
      color: "#666666",
    },
  },
  card: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "6px",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  content: {
    padding: "16px 16px 0px 16px",
    background: "#f9f9f9",
  },
  expansion: {
    flex: "auto",
    boxShadow:
      "0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12)",
  },
  expansionSummary: {
    minHeight: 16,
    height: 24,
  },
  expansionDetails: {
    padding: "8px 8px 8px 8px",
    display: "flex",
    flexDirection: "column",
  },
  actionsContainer: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  button: {
    marginRight: theme.spacing(1),
  },
  button2: {
    marginRight: theme.spacing(1),
    alignSelf: "center",
  },
  horizontalContainer: {
    display: "flex",
    flexWrap: "wrap",
    flex: "auto",
  },
  verticalContainer: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    flex: "auto",
  },
  volume: {
    minWidth: 250,
    paddingLeft: 8,
  },
  typo1: {
    display: "flex",
    alignItems: "center",
    color: "#222831",
  },
  typo2: {
    paddingLeft: 4,
    color: "#222831",
  },
  typo3: {
    paddingLeft: 4,
    position: "absolute",
    zIndex: 1,
    alignSelf: "center",
    color: "#222831",
  },
  progress: {
    height: 23,
  },
  menuItem: {
    fontSize: "0.85rem",
    boxSizing: "border-box",
  },
  list: {
    flex: "auto",
    marginRight: 8,
    padding: 0,
  },
  list2: {
    padding: 0,
  },
  secondaryText: {
    color: "#222831",
    fontWeight: "lighter",
  },
  primaryText: {
    color: "#222831",
    fontSize: "22px",
  },
  disabled: {},
});

const arraySort = (a, b) => {
  try {
    const nameA = a ? a.toUpperCase() : "N/A"; // ignore upper or lowercase
    const nameB = b ? b.toUpperCase() : "N/A"; // ignore upper or lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    // names must be equal
    return 0;
  } catch (e) {
    console.log("DeviceControl Users:", e);
  }
};

class User extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentClient: this.props.clientId,
      anchorEl: null,
      anchorElUsers: null,
      userList: this.props.usersId || [],
    };
    this.handleClose = this.handleClose.bind(this);
    this.handleClickListItemClient = this.handleClickListItemClient.bind(this);
    this.handleClickListItemUser = this.handleClickListItemUser.bind(this);
    this.handleData = this.handleData.bind(this);
    this.handleDataUser = this.handleDataUser.bind(this);
    this.handleDeleteUser = this.handleDeleteUser.bind(this);
    this.handleConfirmClient = this.handleConfirmClient.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      this.props.deviceId !== nextProps.deviceId ||
      this.props.clientId !== nextProps.clientId ||
      nextState !== this.state ||
      this.props.usersId !== nextProps.usersId
    ) {
      if (this.props.deviceId !== nextProps.deviceId) {
        this.setState({
          currentClient: nextProps.clientId,
          userList: nextProps.usersId || [],
        });
      }
      if (this.props.usersId !== nextProps.usersId) {
        this.setState({
          userList: nextProps.usersId || [],
        });
      }
      return true;
    }
    return false;
  }

  handleClose() {
    this.setState({ anchorEl: null, anchorElUsers: null });
  }

  handleClickListItemClient(event) {
    this.setState({ anchorEl: event.currentTarget });
  }

  handleClickListItemUser(event) {
    this.setState({ anchorElUsers: event.currentTarget });
  }

  handleData(client) {
    this.setState({ currentClient: client });
    this.handleClose();
  }

  handleDataUser(item) {
    const { userList } = this.state;
    const newArray = [item].concat(userList);
    updateAudioDeviceUsersMutation(
      this.props.deviceId,
      item.id,
      "ADD",
      (responseId) =>
        responseId ? this.setState({ userList: newArray }) : null
    );
    this.handleClose();
  }

  handleDeleteUser(item) {
    const newArray = [...this.state.userList];
    if (_.intersectionBy([item], newArray, "id")) {
      updateAudioDeviceUsersMutation(
        this.props.deviceId,
        item.id,
        "REMOVE",
        (responseId) => {
          if (responseId) {
            this.setState({ userList: _.pullAllBy(newArray, [item], "id") });
          }
          return this.props.snackbar.showMessage("User removed from list");
        }
      );
    }
  }

  handleConfirmClient() {
    const currentClient = this.state.currentClient;
    updateAudioDeviceClientMutation(this.props.deviceId, currentClient, () => {
      this.props.snackbar.showMessage("Client changed successfully");
    });
  }

  render() {
    const { currentClient, anchorEl, anchorElUsers, userList } = this.state;
    const { classes, viewer, clientId } = this.props;
    const allClientsSorted = [
      ...viewer.allClients?.edges?.filter((item) => item && item.node),
    ].sort((a, b) =>
      a.node && b.node ? arraySort(a.node.name, b.node.name) : null
    );
    const allUsersSorted = [
      ...viewer.allUsers?.edges?.filter((item) => item && item.node),
    ].sort((a, b) =>
      a.node && b.node && a.node.profile && b.node.profile
        ? arraySort(a.node.profile.name, b.node.profile.name)
        : null
    );

    const clientName = allClientsSorted.find(
      (item) => item && item.node && item.node.id === currentClient
    );

    return (
      <div className={classes.root}>
        <Card className={classes.card}>
          <CardContent className={classes.content}>
            <Typography className={classes.typo1} variant="h6">
              User & Client Details
            </Typography>
            <div className={classes.horizontalContainer}>
              <List dense component="nav" className={classes.list}>
                <ListItem
                  button
                  aria-haspopup="true"
                  aria-controls="clients-menu"
                  aria-label="Clients"
                  onClick={this.handleClickListItemClient}
                >
                  <ListItemText
                    classes={{
                      secondary: classes.secondaryText,
                      primary: classes.primaryText,
                    }}
                    primary={
                      currentClient
                        ? clientName && clientName.node
                          ? clientName.node.name
                          : "Choose Client"
                        : "Choose Client"
                    }
                    secondary="Client select"
                  />
                </ListItem>
              </List>
              <Menu
                id="lock-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={this.handleClose}
              >
                {allClientsSorted &&
                  allClientsSorted.map((item) => (
                    <MenuItem
                      id={item.node.id}
                      className={classes.menuItem}
                      key={item.node.id}
                      dense
                      selected={item.node.id === (clientId ? clientId : "none")}
                      value={item.node.name}
                      onClick={() => this.handleData(item.node.id)}
                    >
                      <b>{item.node.name}</b>
                    </MenuItem>
                  ))}
              </Menu>
              <Button
                classes={{ root: classes.root, disabled: classes.disabled }}
                variant="contained"
                color="primary"
                className={classes.button2}
                onClick={this.handleConfirmClient}
                disabled={clientId === (currentClient ? currentClient : "none")}
              >
                Confirm
              </Button>
            </div>
            <div className={classes.verticalContainer}>
              <List dense component="nav" className={classes.list2}>
                <ListItem
                  button
                  aria-haspopup="true"
                  aria-controls="users-menu"
                  aria-label="Users"
                  onClick={this.handleClickListItemUser}
                >
                  <ListItemText
                    classes={{
                      secondary: classes.secondaryText,
                      primary: classes.primaryText,
                    }}
                    primary="Users"
                    secondary="Click here to add users"
                  />
                </ListItem>
              </List>
              <Menu
                id="lock-menu"
                anchorEl={anchorElUsers}
                open={Boolean(anchorElUsers)}
                onClose={this.handleClose}
              >
                {viewer.allUsers &&
                  allUsersSorted.map((user) => {
                    return user.node &&
                      user.node.profile &&
                      user.node.profile.clientId === clientId ? (
                      <MenuItem
                        id={user.node.id}
                        className={classes.menuItem}
                        key={user.node.id}
                        dense
                        disabled={userList.find(
                          (item) => user.node && item.id === user.node.id
                        )}
                        value={user.node && user.node.name}
                        onClick={() => this.handleDataUser(user.node)}
                      >
                        <b>
                          {user.node.profile.name
                            ? user.node.profile.name
                            : "N/A"}
                        </b>
                        {`\u00A0/\u00A0${user.node.emails[0].address}`}
                      </MenuItem>
                    ) : null;
                  })}
              </Menu>
            </div>
          </CardContent>
          <Accordion className={classes.expansion}>
            <AccordionSummary
              className={classes.expansionSummary}
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography className={classes.none}>User list</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.expansionDetails}>
              <Divider />
              <List dense>
                {userList.map((user) => {
                  return (
                    <ListItem key={user.id}>
                      <ListItemText
                        primary={user && user.profile && user.profile.name}
                        secondary={user.emails[0].address}
                      />
                      <ListItemSecondaryAction>
                        <Tooltip
                          id="tooltip-left"
                          title="Remove User"
                          placement="left"
                          enterTouchDelay={200}
                        >
                          <IconButton
                            aria-label="Delete"
                            onClick={() => this.handleDeleteUser(user)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </ListItemSecondaryAction>
                    </ListItem>
                  );
                })}
              </List>
            </AccordionDetails>
          </Accordion>
        </Card>
      </div>
    );
  }
}

User.defaultProps = {
  usersId: [],
};

User.propTypes = {
  classes: PropTypes.object.isRequired,
  viewer: PropTypes.object.isRequired,
  clientId: PropTypes.string.isRequired,
  usersId: PropTypes.array,
  deviceId: PropTypes.string.isRequired,
  snackbar: PropTypes.object,
};

export default compose(withStyles(styles), withSnackbar())(User);
