import { commitMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import Environment from "../../environment";

const mutation = graphql`
  mutation updateAudioDeviceGoLiveDateMutation(
    $deviceId: String!
    $goLiveDate: Float!
  ) {
    updateAudioDeviceGoLiveDate(deviceId: $deviceId, goLiveDate: $goLiveDate) {
      id
    }
  }
`;

export default (deviceId, goLiveDate, callback) => {
  const variables = {
    deviceId,
    goLiveDate,
  };

  commitMutation(Environment, {
    mutation,
    variables,
    onCompleted: (response) => {
      const id = response.updateAudioDeviceGoLiveDate.id;
      callback(id);
    },
    onError: (err) => console.error(err), // eslint-disable-line
  });
};
