import { commitMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import Environment from "../../environment";

const mutation = graphql`
  mutation updateVideoDeviceProfileMutation(
    $deviceId: String!
    $profile: ProfileDetailsInput!
    $sync: Boolean!
  ) {
    updateVideoDeviceProfile(
      deviceId: $deviceId
      profile: $profile
      sync: $sync
    ) {
      id
      name
      uid
      description
      macAddress
      currentlyPlaying
      volume
      lastSync
      lanMacAddress
      internalIp
      publicIp
      lastOnline
      appVersion
      reboot
      takeScreenShot
      sync
      format
      screenShot
      uploadLogs
      model
      serialNumber
      usedStorage
      totalStorage
      availableStorage
      hdmiStatus
      downloading
      appUpTime
      isPlaying
      clientId
      profile {
        profileId
        name
        format
        volume
        url
        syncTime
        rebootTime
        clientId
      }
    }
  }
`;

export default (deviceId, profile, sync, callback) => {
  const variables = {
    deviceId,
    profile,
    sync,
  };

  commitMutation(Environment, {
    mutation,
    variables,
    onCompleted: (response) => {
      const id = response.updateVideoDeviceProfile.id;
      callback(id);
    },
    onError: (err) => console.error(err), // eslint-disable-line
  });
};
