import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import compose from "recompose/compose";
import { withSnackbar } from "material-ui-snackbar-provider";
import TextField from "@material-ui/core/TextField";
import Input from "@material-ui/core/Input";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import FingerprintIcon from "@material-ui/icons/Fingerprint";
import Validator from "validator";
import InlineError from "../ui/inlineError";
import forgotPasswordMutation from "../mutations/forgotPasswordMutation";
import LoginLogo from "./LoginLogo";

const paperWidth = 270;

const styles = (theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    width: paperWidth,
  },
  root: theme.mixins.gutters({
    position: "relative",
    margin: "auto",
    marginTop: 100,
    width: paperWidth,
    paddingTop: 16,
    paddingBottom: 16,
    background: theme.palette.background.paper,
  }),
  button: {
    "&:hover": {
      background: "linear-gradient(45deg, #387f86 30%, #00bce4 90%)",
    },
    width: paperWidth,
    marginTop: 16,
    marginBottom: 25,
  },
  typography: {
    width: paperWidth,
  },
});

class ForgotPass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submit: false,
      data: {
        email: "",
      },
      errors: {},
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentWillMount() {
    localStorage.removeItem("user_id");
    localStorage.removeItem("authentication_token");
  }

  handleClick(event) {
    event.preventDefault();
    const { email } = this.state.data;
    const errors = this.validate(this.state.data);
    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      forgotPasswordMutation(email, (id) => {
        if (id !== null) {
          this.setState({
            submit: true,
          });
          this.props.router.replace("/login");
          this.props.snackbar.showMessage(
            "Verify your email address and follow the link"
          );
        } else {
          this.setState({ errors: { email: "*invalid e-mail address" } });
        }
      });
    }
  }

  validate() {
    const errors = {};
    if (!Validator.isEmail(this.state.data.email))
      errors.email = "*invalid e-mail address";
    return errors;
  }

  handleChange(e) {
    e.preventDefault();
    this.setState({
      data: { ...this.state.data, [e.target.id]: e.target.value },
    });
  }

  render() {
    const { classes } = this.props; //eslint-disable-line
    return (
      <LoginLogo>
        <div>
          <Paper className={classes.root} elevation={10}>
            <TextField
              id="email"
              label="Email"
              className={classes.textField}
              value={this.state.data.email}
              onChange={this.handleChange}
              margin="normal"
            />
            <Typography variant="caption" color="error">
              {this.state.errors.email && (
                <InlineError text={this.state.errors.email} />
              )}
            </Typography>
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              onClick={this.handleClick}
            >
              <Input
                type="submit"
                onSubmit={this.handleClick}
                value="RESET PASSWORD"
                disableUnderline
                style={{ fontSize: "large" }}
              />
              <FingerprintIcon />
            </Button>
          </Paper>
        </div>
      </LoginLogo>
    );
  }
}

ForgotPass.propTypes = {
  classes: PropTypes.object.isRequired, //eslint-disable-line
  snackbar: PropTypes.object, //eslint-disable-line
  router: PropTypes.object, //eslint-disable-line
};

export default compose(withStyles(styles), withSnackbar())(ForgotPass);
