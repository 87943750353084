import { commitMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import Environment from "../../environment";

const mutation = graphql`
  mutation activateAudioDeviceMutation(
    $deviceId: String!
    $deviceName: String!
    $deviceTags: [String]
    $deviceGoLiveDate: Float
  ) {
    activateAudioDevice(
      deviceId: $deviceId
      deviceName: $deviceName
      deviceTags: $deviceTags
      deviceGoLiveDate: $deviceGoLiveDate
    ) {
      id
    }
  }
`;

export default (
  deviceId,
  deviceName,
  deviceTags,
  deviceGoLiveDate,
  callback
) => {
  const variables = {
    deviceId,
    deviceName,
    deviceTags,
    deviceGoLiveDate,
  };

  commitMutation(Environment, {
    mutation,
    variables,
    onCompleted: () => {
      callback();
    },
    onError: (err) => console.error(err), // eslint-disable-line
  });
};
