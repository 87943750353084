import { commitMutation } from "react-relay";
import graphql from 'babel-plugin-relay/macro';
import Environment from "../../environment";

const mutation = graphql`
  mutation deleteTaskMutation($taskId: String!) {
    deleteTask(taskId: $taskId) {
      id
    }
  }
`;

export default (taskId, callback) => {
  const variables = {
    taskId,
  };

  const configs = [
    {
      type: "NODE_DELETE",
      deletedIDFieldName: "id",
    },
  ];

  commitMutation(Environment, {
    mutation,
    variables,
    configs,
    onCompleted: (response) => {
      if (response && response.deleteTask) {
        callback(response.deleteTask);
      }
    },
    onError: (err) => console.error(err), // eslint-disable-line
  });
};
