import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import CardContent from "@material-ui/core/CardContent";
import Tooltip from "@material-ui/core/Tooltip";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import ZoneCapModal from "./zoneCapModal";

const styles = (theme) => ({
  root: {
    margin: "4px 0px 4px 0px",
  },
  card: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "6px",
  },
  content: {
    background: "#f9f9f9",
  },
  typo1: {
    display: "flex",
    alignItems: "center",
    color: "#222831",
    fontSize: "16px",
  },
  typo2: {
    paddingLeft: 4,
    color: "#222831",
    fontWeight: "lighter",
    letterSpacing: "0.06em",
    marginBottom: "3px",
  },
  horizontalContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  verticalContainer: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    margin: 4,
  },
  editIcon: {
    height: 16,
    width: 16,
  },
});

function DeviceControlDetails(props) {
  const { classes, device } = props;
  const [open, setOpen] = React.useState(false);
  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <CardContent className={classes.content}>
          <Typography className={classes.typo1} variant="h6">
            Device Control Details
          </Typography>
          <div className={classes.horizontalContainer}>
            <div className={classes.verticalContainer}>
              <Typography
                className={classes.typo2}
                variant="body2"
                color="textSecondary"
              >
                <b>Device ID: </b>
                {device?.deviceId}
              </Typography>
              <Typography
                className={classes.typo2}
                variant="body2"
                color="textSecondary"
              >
                <b>Device IP: </b>
                {device?.ipAddress}
              </Typography>
              <Typography
                className={classes.typo2}
                variant="body2"
                color="textSecondary"
              >
                <b>System Version: </b>
                {device?.systemVersion}
              </Typography>
              <Typography
                className={classes.typo2}
                variant="body2"
                color="textSecondary"
              >
                <b>Country: </b>
                {device?.countryCode}
              </Typography>
              <Typography
                className={classes.typo2}
                variant="body2"
                color="textSecondary"
              >
                <b>General Schedule Active: </b>
                {device?.isGeneralScheduleActive ? "Yes" : "No"}
              </Typography>
              <Typography
                className={classes.typo2}
                variant="body2"
                color="textSecondary"
              >
                <b>General Schedule: </b>
                {device?.generalSchedule || "N/A"}
              </Typography>
              <Typography
                className={classes.typo2}
                variant="body2"
                color="textSecondary"
              >
                <b>Last Heartbeat: </b>
                {device?.lastHeartbeat
                  ? new Date(parseInt(device.lastHeartbeat)).toLocaleString()
                  : "N/A"}
              </Typography>
              <Typography
                className={classes.typo2}
                variant="body2"
                color="textSecondary"
              >
                <b>Date Created: </b>
                {device?.dateCreated
                  ? new Date(parseInt(device.dateCreated)).toLocaleString()
                  : "N/A"}
              </Typography>
            </div>
            <div className={classes.verticalContainer}>
              <Typography
                className={classes.typo2}
                variant="body2"
                color="textSecondary"
              >
                <b>Total Storage: </b>
                {(device?.totalStorage / 1024 / 1024 / 1024).toFixed(2)} GB
              </Typography>
              <Typography
                className={classes.typo2}
                variant="body2"
                color="textSecondary"
              >
                <b>Available storage: </b>
                {(device?.availableStorage / 1024 / 1024 / 1024).toFixed(2)} GB
              </Typography>
              <Typography
                className={classes.typo2}
                variant="body2"
                color="textSecondary"
              >
                <b>Core uptime: </b>
                {(device?.coreUptime / 60 / 60 / 24).toFixed(2)} days
              </Typography>
              <Typography
                className={classes.typo2}
                variant="body2"
                color="textSecondary"
              >
                <b>Model: </b>
                {device?.design?.platform || "N/A"}
              </Typography>
              <Typography
                className={classes.typo2}
                variant="body2"
                color="textSecondary"
              >
                <b>Plugin Version: </b>
                {device?.plugin?.version || "N/A"}
              </Typography>
              <Typography
                className={classes.typo2}
                variant="body2"
                color="textSecondary"
              >
                <b>Plugin Build Version: </b>
                {device?.plugin?.buildVersion || "N/A"}
              </Typography>
              <div
                className={classes.horizontalContainer}
                style={{ alignItems: "center" }}
              >
                <Typography
                  className={classes.typo2}
                  variant="body2"
                  color="textSecondary"
                >
                  <b>Zone cap: </b>
                  {device?.noOfZones}
                </Typography>
                <IconButton onClick={() => setOpen(true)} color="primary">
                  <Tooltip
                    id="tooltip-bottom"
                    title="Set maximum number of zones"
                    placement="bottom"
                    enterTouchDelay={200}
                  >
                    <EditIcon className={classes.editIcon} />
                  </Tooltip>
                </IconButton>
              </div>
            </div>
          </div>
          <ZoneCapModal
            open={open}
            close={() => setOpen(false)}
            device={device}
          />
        </CardContent>
      </Card>
    </div>
  );
}

export default withStyles(styles)(DeviceControlDetails);
