/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type WebkeyAppManagement_viewer$ref: FragmentReference;
declare export opaque type WebkeyAppManagement_viewer$fragmentType: WebkeyAppManagement_viewer$ref;
export type WebkeyAppManagement_viewer = {|
  +allWebkeyDevices: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: {|
        +id: string,
        +DisplayName: string,
        +Serial: string,
        +DeviceNick: ?string,
        +DeviceBrand: ?string,
        +DeviceModel: ?string,
        +WebkeyVersion: ?string,
        +Location: ?string,
        +LastConnected: ?number,
        +AndroidAPI: ?number,
        +AndroidVersion: ?string,
        +Rooted: ?boolean,
        +Online: ?boolean,
      |}
    |}>
  |},
  +allAudioDevices: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: {|
        +id: string,
        +name: ?string,
        +macAddress: ?string,
        +ethernetMacAddress: ?string,
        +clientId: ?string,
        +webkeyId: ?string,
      |}
    |}>
  |},
  +allWebkeyPackages: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: {|
        +id: string,
        +appName: string,
        +keyName: string,
        +source: string,
        +packageName: ?string,
        +description: ?string,
        +versionCode: ?number,
        +versionName: ?string,
        +icon: ?string,
      |}
    |}>
  |},
  +$refType: WebkeyAppManagement_viewer$ref,
|};
export type WebkeyAppManagement_viewer$data = WebkeyAppManagement_viewer;
export type WebkeyAppManagement_viewer$key = {
  +$data?: WebkeyAppManagement_viewer$data,
  +$fragmentRefs: WebkeyAppManagement_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "WebkeyAppManagement_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "WebkeyDeviceConnection",
      "kind": "LinkedField",
      "name": "allWebkeyDevices",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "WebkeyDeviceEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "WebkeyDevice",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "DisplayName",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "Serial",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "DeviceNick",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "DeviceBrand",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "DeviceModel",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "WebkeyVersion",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "Location",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "LastConnected",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "AndroidAPI",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "AndroidVersion",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "Rooted",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "Online",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AudioDeviceConnection",
      "kind": "LinkedField",
      "name": "allAudioDevices",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AudioDeviceEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "AudioDevice",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "macAddress",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "ethernetMacAddress",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "clientId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "webkeyId",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "WebkeyPackageConnection",
      "kind": "LinkedField",
      "name": "allWebkeyPackages",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "WebkeyPackageEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "WebkeyPackage",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "appName",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "keyName",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "source",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "packageName",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "description",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "versionCode",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "versionName",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "icon",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e7d4a5b26ccc8fc3fe0e03029615f69d';

module.exports = node;
