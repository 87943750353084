import { commitMutation } from "react-relay";
import graphql from 'babel-plugin-relay/macro';
import Environment from "../../environment";

const mutation = graphql`
  mutation editTagMutation($inputId: String!, $input: createTagProviderData!) {
    editTag(id: $inputId, createTagProvider: $input) {
      id
      name
      description
    }
  }
`;

export default (id2, name, description, callback) => {
  const variables = {
    inputId: id2,
    input: {
      name,
      description,
    },
  };

  commitMutation(Environment, {
    mutation,
    variables,
    onCompleted: (response) => {
      const id = response.editTag.id;
      callback(id);
    },
    onError: (err) => console.error(err), // eslint-disable-line
  });
};
