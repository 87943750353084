import React, { Suspense } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import { useLazyLoadQuery } from "react-relay";
import { ALL_QSYS_DEVICES } from "../../qsys_devices/queries/allQsysDevices";
import LoadingPage from "../../ui/LoadingPage";
import AddQsysDeviceModal from "./AddQsysDeviceModal";
import addUserToQsysDeviceMutation from "../../mutations/addUserToQsysDeviceMutation";
import removeUserFromQsysDeviceMutation from "../../mutations/removeUserFromQsysDeviceMutation";
import permissions from "../../authentication/isPermited";
const styles = (theme) => ({
  deviceCard: {
    marginTop: 8,
    backgroundColor: "#f5f5f5",
  },
  deviceContent: {
    padding: "8px 16px",
    "&:last-child": {
      paddingBottom: "8px",
    },
  },
  zoneContainer: {
    marginTop: 4,
    marginLeft: 16,
    display: "flex",
    flexWrap: "wrap",
    gap: "8px",
  },
  deviceInfo: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  zoneChip: {
    backgroundColor: "#e0e0e0",
  },
  addButton: {
    marginBottom: theme.spacing(2),
    backgroundColor: "#3BABA1",
    color: "white",
    "&:hover": {
      backgroundColor: "#2d8c84",
    },
  },
});

function QsysDevicesContent({ classes, qsysDeviceIds, userId, user }) {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const variables = {
    deviceIds: qsysDeviceIds?.length > 0 ? qsysDeviceIds : [null],
  };

  const { viewer } = useLazyLoadQuery(ALL_QSYS_DEVICES, variables, {
    fetchPolicy: "store-and-network",
  });

  const qsysDevices = viewer?.allQsysDevices || [];

  const handleAddDevice = (device) => {
    addUserToQsysDeviceMutation(device.id, userId, () => {
      setIsModalOpen(false);
    });
  };

  const handleRemoveDevice = (device) => {
    removeUserFromQsysDeviceMutation(device.id, userId, () => {});
  };

  return (
    <div>
      {permissions([1]) && (
        <Button
          variant="contained"
          className={classes.addButton}
          onClick={() => setIsModalOpen(true)}
        >
          View Q-SYS Cores
        </Button>
      )}

      <AddQsysDeviceModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSelect={handleAddDevice}
        qsysDeviceIds={qsysDeviceIds}
        user={user}
      />

      <Divider style={{ marginTop: 16, marginBottom: 16 }} />
      <Typography variant="subtitle2" color="textSecondary" gutterBottom>
        Assigned Q-SYS Devices and Zones
      </Typography>

      {qsysDevices?.length === 0 ? (
        <Typography
          variant="body2"
          color="textSecondary"
          style={{ fontStyle: "italic", marginTop: 8 }}
        >
          No Q-SYS devices assigned
        </Typography>
      ) : (
        qsysDevices?.map((device) => (
          <Card key={device.id} className={classes.deviceCard}>
            <CardContent className={classes.deviceContent}>
              <div className={classes.deviceInfo}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <div>
                    <Typography variant="body2">
                      <b>{device.name || device.deviceId}</b>
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {device.ipAddress}
                    </Typography>
                  </div>
                  {permissions([1]) && (
                    <Tooltip title="Remove user access to this device">
                      <IconButton
                        onClick={() => handleRemoveDevice(device)}
                        disabled={!permissions([1])}
                        style={{ marginLeft: 8 }}
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  )}
                </div>
              </div>
              {device.zones?.length > 0 && (
                <div className={classes.zoneContainer}>
                  {device.zones.map((zone) => (
                    <Chip
                      key={zone.zoneId}
                      label={zone.zoneName}
                      className={classes.zoneChip}
                      title={`Mix: ${zone.mixName || "None"}`}
                    />
                  ))}
                </div>
              )}
            </CardContent>
          </Card>
        ))
      )}
    </div>
  );
}

function UserQsysDevices(props) {
  return (
    <Suspense fallback={<LoadingPage />}>
      <QsysDevicesContent {...props} />
    </Suspense>
  );
}

UserQsysDevices.propTypes = {
  classes: PropTypes.object.isRequired,
  qsysDeviceIds: PropTypes.arrayOf(PropTypes.string),
  userId: PropTypes.string.isRequired,
  onDeviceAdded: PropTypes.func,
};

export default withStyles(styles)(UserQsysDevices);
