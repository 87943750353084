/**
 * External dependencies
 */
import React, { useEffect, useContext } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Divider,
  Checkbox,
} from "@material-ui/core";

/**
 * Internal dependencies
 */

import { CurrentMenuContext } from "../../hooks/currentMenuProvider";
import toggleRouteCategoryMutation from "../../../mutations/toggleRouteCategoryMutation";
import flattenArrays from "../utils/flattenArrays";
import toggleQsysRouteCategoryMutation from "../../../mutations/toggleQsysRouteCategoryMutation";
import { CurrentClientContext } from "../../hooks/currentClientProvider";

const styles = {
  root: {},
};

const RouteListCategory = ({ classes, playableList, route, searchValue }) => {
  const [checked, setChecked] = React.useState([""]);
  const { audioProvider } = useContext(CurrentClientContext);
  const { currentMenu, currentMenuExtension } = useContext(CurrentMenuContext);

  const handledMenu = currentMenuExtension || currentMenu;

  useEffect(() => {
    route.category && route.category !== ""
      ? setChecked([route.category])
      : setChecked([]);
  }, [route]);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
    let routeItems = 0;

    if (newChecked[0]) {
      routeItems = playableList.filter((item) =>
        item?.newCategory
          ?.map((item2) => item2.category)
          .includes(newChecked[0])
      ).length;
    }
    audioProvider === "sonos"
      ? toggleRouteCategoryMutation(
          handledMenu.id,
          route.pathId,
          "category",
          newChecked[0] || "",
          "",
          routeItems,
          () => console.log("Done!")
        )
      : toggleQsysRouteCategoryMutation(
          handledMenu.id,
          route.pathId,
          "category",
          newChecked[0] || "",
          "",
          routeItems,
          () => console.log("Done!")
        );
  };

  const listOfCategories =
    (playableList && [
      ...new Set(
        flattenArrays(
          playableList
            .filter((item) => item?.newCategory?.length > 0)
            .map((item) =>
              flattenArrays(item.newCategory.map((item2) => item2?.category))
            ) || []
        )
      ),
    ]) ||
    [];
  const regex = new RegExp(searchValue, "gi");
  return listOfCategories
    .filter((item) => (searchValue === "" ? true : item?.search(regex) !== -1))
    .map((item) => (
      <div key={item}>
        <ListItem
          component="nav"
          className={classes.root}
          aria-label="menu-list-item"
        >
          <ListItemText primary={item} />
          <ListItemSecondaryAction>
            <Checkbox
              edge="end"
              onChange={handleToggle(item)}
              checked={checked.indexOf(item) !== -1}
              disabled={checked.length === 1 && checked[0] !== item}
              inputProps={{ "aria-labelledby": item }}
            />
          </ListItemSecondaryAction>
        </ListItem>
        <Divider />
      </div>
    ));
};

export default withStyles(styles)(RouteListCategory);
