import { commitMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";

import Environment from "../../environment";

const mutation = graphql`
  mutation updateReadStatusForAllMutation($userId: String!) {
    updateReadStatusForAll(userId: $userId) {
      id
    }
  }
`;

export default (userId) => {
  const variables = {
    userId,
  };

  commitMutation(Environment, {
    mutation,
    variables,
    onCompleted: () => {},
    onError: (err) => console.error(err), // eslint-disable-line
  });
};
