import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import CircularProgress from "@material-ui/core/CircularProgress";

import AudioClientStep1 from "./AudioClientStep1";
import createAudioClientMutation from "../mutations/createAudioClientMutation";

const styles = (theme) => ({
  dialog: {
    background: "secondary",
    minWidth: "900px",
  },
  stepper: {
    width: "400px",
  },
  dialogTitle: {
    padding: "0px 24px 0px 24px",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  button: {
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  resetContainer: {
    marginTop: 0,
    padding: theme.spacing(3),
  },
  closeIcon: {
    height: 28,
    width: 28,
  },
  close: {
    position: "relative",
    float: "right",
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  root: {
    display: "flex",
    alignItems: "center",
  },
});

function getSteps() {
  return ["Insert new client data", "Confirm create"];
}

class AudioClientCreate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: 0,
      sizeLimit: 10000000000,
      allowBundle: false,
      checked: [],
      pleaseWait: false,
      name: "",
      useExplicit: false,
      noVolumeBar: false,
      noPlayPause: false,
    };
    this.handleRequestClose = this.handleRequestClose.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.myCallBack = this.myCallBack.bind(this);
    this.myCallBack2 = this.myCallBack2.bind(this);
    this.callBack = this.callBack.bind(this);
  }

  myCallBack(data) {
    if (data.allowBundle !== undefined) {
      this.setState({
        allowBundle: !data.allowBundle,
      });
    }
    const id = data.id;
    let value = data.value;
    if (id === "sizeLimit") {
      value *= 1000000000;
    }
    this.setState({
      [id]: value,
    });
  }

  myCallBack2(data) {
    this.setState({
      checked: data,
    });
  }

  handleRequestClose() {
    if (this.state.activeStep === 0) {
      this.handleReset();
      this.props.handleRequestClose();
    }
    if (this.state.activeStep === 1) {
      this.setState((prevState) => ({ activeStep: 0 }));
    }
  }

  handleNext() {
    if (this.state.activeStep === 1) {
      this.setState({
        pleaseWait: true,
      });
      createAudioClientMutation(
        this.state.name,
        this.state.checked.map((data) => data.id),
        this.state.useExplicit,
        this.state.noVolumeBar,
        this.state.noPlayPause,
        (id) => (id ? closeProcedure(id) : alert("Client was not created"))
      );
      const closeProcedure = (id) => {
        localStorage.setItem("audioClientId", id);
        this.callBack(id);
        this.handleReset();
        this.props.handleRequestClose();
      };
    } else {
      this.setState({
        activeStep: this.state.activeStep + 1,
      });
    }
  }

  handleReset() {
    this.setState({
      activeStep: 0,
      name: undefined,
      checked: [],
      pleaseWait: false,
    });
  }

  callBack(e) {
    this.props.myCallBack(e); // eslint-disable-line
  }

  render() {
    const { classes } = this.props;
    const steps = getSteps();
    const { activeStep, name, pleaseWait, useExplicit } = this.state;
    return (
      <Dialog open={this.props.open} className={classes.dialog}>
        <div className={classes.close}>
          <IconButton
            aria-label="Close"
            className={classes.close}
            onClick={this.handleRequestClose}
          >
            <CloseIcon className={classes.closeIcon} />
          </IconButton>
        </div>
        <DialogTitle className={classes.dialogTitle}>
          Create new audio client
        </DialogTitle>
        <div className={classes.stepper}>
          <Stepper activeStep={activeStep} orientation="vertical">
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
                <StepContent>
                  {activeStep === 0 ? (
                    <AudioClientStep1 myCallBack={this.myCallBack} />
                  ) : null}
                  {activeStep === 1 ? (
                    <Paper elevation={0}>
                      <Typography variant="h6" component="h1" gutterBottom>
                        New client details
                      </Typography>
                      <Typography component="p" gutterBottom>
                        {`Client name: ${name}`}
                      </Typography>
                      <Typography component="p" gutterBottom>
                        {useExplicit
                          ? "Client will use explicit content"
                          : "Explicit content is not available for this client"}
                      </Typography>
                    </Paper>
                  ) : null}
                  <div className={classes.actionsContainer}>
                    <div className={classes.root}>
                      {this.state.activeStep !== 0 ? (
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={this.handleRequestClose}
                          className={classes.button}
                        >
                          Back
                        </Button>
                      ) : null}
                      <div className={classes.wrapper}>
                        <Button
                          disabled={!this.state.name || pleaseWait}
                          variant="contained"
                          color="primary"
                          onClick={this.handleNext}
                          className={classes.button}
                        >
                          {activeStep === steps.length - 1 ? "Finish" : "Next"}
                        </Button>
                        {pleaseWait && (
                          <CircularProgress
                            size={24}
                            className={classes.buttonProgress}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </StepContent>
              </Step>
            ))}
          </Stepper>
        </div>
      </Dialog>
    );
  }
}

AudioClientCreate.propTypes = {
  classes: PropTypes.object.isRequired, //eslint-disable-line
  onRequestClose: PropTypes.func, //eslint-disable-line
  selectedValue: PropTypes.string, //eslint-disable-line
  open: PropTypes.bool, //eslint-disable-line
  handleRequestClose: PropTypes.func, //eslint-disable-line
};

export default withStyles(styles)(AudioClientCreate);
