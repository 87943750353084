import { commitMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import Environment from "../../environment";

const mutation = graphql`
  mutation editMethodPermissionMutation(
    $id: ID!
    $typeRole: typeRoleEnum!
    $userType: Int!
    $typeOperation: typeOperationEnum!
  ) {
    editMethodPermission(
      id: $id
      typeRole: $typeRole
      userType: $userType
      typeOperation: $typeOperation
    ) {
      id
      displayName
      methodName
      description
      UIElementsID
      userType
      roleType
    }
  }
`;

export default (id, typeRole, userType, typeOperation, callback) => {
  const variables = {
    id,
    typeRole,
    userType,
    typeOperation,
  };

  commitMutation(Environment, {
    mutation,
    variables,
    onCompleted: (response) => {
      try {
        const id = response.editMethodPermission.id;
        callback(id);
      } catch (e) {
        console.log(e);
      }
    },
    onError: (err) => console.error(err), // eslint-disable-line
  });
};
