import { commitMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import Environment from "../../environment";

const mutation = graphql`
  mutation editAudioClientMutation(
    $inputid: String!
    $audioClientProvider: audioClientProviderData!
  ) {
    editAudioClient(id: $inputid, audioClientProvider: $audioClientProvider) {
      id
      name
      playlistIds
      playbackIds
      sceneIds
      logo
      signedUrl
      preview {
        previewId
        created
        ending
        isEnded
        isEndless
      }
      viewableCategories {
        name
        subCategories
      }
      useExplicit
      noVolumeBar
      noPlayPause
    }
  }
`;

export default (
  id2,
  name,
  logoFilename,
  useExplicit,
  noVolumeBar,
  noPlayPause,
  callback
) => {
  const variables = {
    inputid: id2,
    audioClientProvider: {
      name,
      logoFilename,
      useExplicit,
      noVolumeBar,
      noPlayPause,
    },
    clientMutationId: "",
  };

  commitMutation(Environment, {
    mutation,
    variables,
    onCompleted: (response) => {
      const id = response?.editAudioClient?.id;
      const signedUrl =
        response &&
        response.editAudioClient &&
        response.editAudioClient.signedUrl;
      callback(id, signedUrl);
    },
    onError: (err) => console.error(err), // eslint-disable-line
  });
};
