/* eslint-disable
no-unused-expressions,
no-return-assign,
react/no-array-index-key */

import React from "react";
import { createFragmentContainer } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ReactTable from "react-table";
import "react-table/react-table.css";
import VideoClients from "./VideoClients";
import VideoDevicesFilter from "./VideoDevicesFilter";
import VideoDeviceControl from "./VideoDeviceControl";
import permissions from "../authentication/isPermited";
import permissions2 from "../authentication/UIPermissions";

const styles = {
  root: {
    display: "flex",
    flexDirection: "column",
    margin: "0px 0px 0px 0px",
  },
  content: {
    flex: "0 1 auto",
  },
  typo: {
    padding: 0,
  },
  typoClient: {
    padding: "0px 0px 0px 0px",
    color: "secondary",
  },
  button: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    border: "1px solid #e3fd59",
    background: "#ECEFF1",
    marginLeft: "9px",
    "&:hover": {
      background: "#e3fd59",
    },
    minWidth: 150,
    margin: 5,
    marginBottom: 9,
  },
  horizontalContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  horizontalContainer2: {
    display: "flex",
    flexWrap: "wrap",
  },
};

const openRow = "#DCDCDC";

class VideoDevices extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      left: false,
      clientId: "ALL",
      anchorEl: null,
      deviceControlId: "none",
      deviceControl: {},
      selectedRow: "",
      allColumns: [
        {
          Header: "Description",
          accessor: "description",
          maxWidth: 100,
        },
        {
          Header: "MAC Address",
          accessor: "macAddress",
          maxWidth: 100,
        },
        {
          Header: "Volume",
          accessor: "volume",
          maxWidth: 70,
        },
        {
          Header: "MAC Adress",
          accessor: "macAddress",
          maxWidth: 100,
        },
        {
          Header: "Last Sync",
          accessor: "lastSync",
          maxWidth: 100,
        },
        {
          Header: "Internal IP",
          accessor: "internalIp",
          maxWidth: 100,
        },
        {
          Header: "Public IP",
          accessor: "publicIp",
          maxWidth: 100,
        },
        {
          Header: "Last Online",
          accessor: "lastOnline",
          maxWidth: 100,
        },
        {
          Header: "Model",
          accessor: "model",
          maxWidth: 100,
        },
        {
          Header: "Serial Number",
          accessor: "serialNumber",
          maxWidth: 100,
        },
        {
          Header: "Used Storage",
          accessor: "usedStorage",
          maxWidth: 100,
        },
        {
          Header: "Total Storage",
          accessor: "totalStorage",
          maxWidth: 100,
        },
        {
          Header: "Available Storage",
          accessor: "availableStorage",
          maxWidth: 100,
        },
        {
          Header: "Up time",
          accessor: "appUpTime",
          maxWidth: 100,
        },
        {
          Header: "HDMI Status",
          accessor: "hdmiStatus",
          maxWidth: 100,
        },
      ],
      addedColumns: [],
    };
    this.toggleDrawer = this.toggleDrawer.bind(this);
    this.myCallBack = this.myCallBack.bind(this);
    this.handleAddColumsMenu = this.handleAddColumsMenu.bind(this);
    this.handleAddColumns = this.handleAddColumns.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.myCallBack2 = this.myCallBack2.bind(this);
    this.handleControlToggle = this.handleControlToggle.bind(this);
    this.toggleClose = this.toggleClose.bind(this);
    this.selectRow = this.selectRow.bind(this);
  }

  shouldComponentUpdate() {
    if (this.props.match.location.pathname === window.location.pathname) {
      return true;
    }
    return false;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state.deviceControlId !== "none") {
      const deviceControl = nextProps.viewer.allVideoDevices.edges
        .filter((item) => item && item.node)
        .filter((item) => item.node.deviceStatus === "INACTIVE")
        .filter((item) =>
          item.node.id === this.state.deviceControlId ? item.node : null
        );
      if (deviceControl[0] && deviceControl[0].node) {
        this.setState({ deviceControl: deviceControl[0].node });
      } else {
        this.setState({ deviceControlId: "none" });
      }
    }
  }

  selectRow(userId) {
    this.setState({ selectedRow: userId });
  }

  handleControlToggle(device) {
    this.setState({
      deviceControlId: device.id,
      deviceControl: device,
    });
  }

  toggleClose() {
    this.setState({
      deviceControlId: "none",
    });
  }

  myCallBack(data) {
    this.setState({
      clientId: data,
    });
    return data;
  }

  toggleDrawer(open) {
    return this.setState({
      left: open,
    });
  }

  handleAddColumsMenu(e) {
    this.setState({ anchorEl: e.currentTarget });
  }

  handleAddColumns(column, index) {
    const { allColumns, addedColumns } = this.state;
    addedColumns.push(column);
    allColumns.splice(index, 1);
    this.setState({ addedColumns, allColumns });
    if (!allColumns[0]) {
      this.setState({ anchorEl: null });
    }
  }

  handleClose() {
    this.setState({ anchorEl: null });
  }
  // eslint-disable-next-line
  myCallBack2(column, index) {
    const { allColumns, addedColumns } = this.state;
    allColumns.push(column);
    addedColumns.splice(index, 1);
    this.setState({ addedColumns, allColumns });
  }

  render() {
    const { classes, viewer, methods } = this.props;
    const {
      anchorEl,
      clientId,
      allColumns,
      addedColumns,
      deviceControl,
      deviceControlId,
      selectedRow,
    } = this.state;

    const allTagsFiltered = this.props.viewer.allTags.edges.filter(
      (item) => item && item.node
    );

    const fixedColumns = [
      {
        id: "isPlaying",
        Header: "Playing",
        accessor: (a) => (a.isPlaying ? "Yes" : "No"),
        maxWidth: 70,
      },
      {
        Header: "Name",
        accessor: "name",
        maxWidth: 700,
      },
      {
        Header: "Format",
        accessor: "format",
        maxWidth: 70,
      },
      {
        Header: "Version",
        accessor: "appVersion",
        maxWidth: 70,
      },
      {
        Header: "Currently Playing",
        accessor: "currentlyPlaying",
        maxWidth: 400,
      },
      {
        id: "sync",
        Header: "In sync",
        accessor: (a) => (a.sync ? "Yes" : "No"),
        maxWidth: 70,
      },
      {
        id: "tags",
        Header: "Device Tags",
        accessor: (a) =>
          a.tags
            ? a.tags
                .map(
                  (tag) =>
                    allTagsFiltered.filter((item) => item.node.id === tag)[0]
                      .node.name
                )
                .join(", ")
            : null,
        maxWidth: 200,
      },
    ];

    const fullData = this.props.viewer.allVideoDevices.edges
      .filter((item) => item && item.node)
      .filter((item) => item.node.deviceStatus === "INACTIVE")
      .map(({ node }) => node);

    const filteredData = fullData.filter((node) => node.clientId === clientId);
    const deviceIds = filteredData.map((device) => ({
      deviceId_contains: device.id,
    }));
    const data = clientId === "ALL" ? fullData : filteredData;
    const pageSize = 20;
    return permissions2("5caca3a03630643c2a42381f", methods) ? (
      <div className={classes.root}>
        <div className={classes.horizontalContainer}>
          {permissions([], [], [1, 2]) && (
            <Button
              variant="contained"
              size="small"
              className={classes.button}
              onClick={() => this.toggleDrawer(true)}
            >
              <Typography
                variant="subtitle1"
                color="secondary"
                className={classes.typo}
                align="center"
                component="div"
              >
                {"Client Select : "}
                {clientId === "ALL"
                  ? "ALL"
                  : viewer.allVideoClients.edges
                      .filter((item) => item.node)
                      .filter((item) => item.node.id === clientId)[0].node.name}
              </Typography>
            </Button>
          )}
          {permissions([], [], [1, 2]) && allColumns[0] ? (
            <Button
              variant="contained"
              size="small"
              className={classes.button}
              onClick={this.handleAddColumsMenu}
            >
              <Typography
                variant="subtitle1"
                color="secondary"
                className={classes.typo}
                align="center"
              >
                Add Columns
              </Typography>
            </Button>
          ) : null}
          {permissions([], [], [1, 2]) && (
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={this.handleClose}
            >
              {allColumns.map((column, index) => (
                <MenuItem
                  key={index}
                  onClick={() => this.handleAddColumns(column, index)}
                >
                  {`${column.Header}`}
                </MenuItem>
              ))}
            </Menu>
          )}
          {permissions([], [], [1, 2]) && (
            <VideoDevicesFilter
              addedColumns={addedColumns}
              callback={this.myCallBack2}
            />
          )}
        </div>
        {permissions([], [], [1, 2]) && (
          <Drawer
            open={this.state.left}
            onClose={() => this.toggleDrawer(false)}
          >
            <div
              tabIndex={0}
              role="button"
              onClick={() => this.toggleDrawer(false)}
              onKeyDown={() => this.toggleDrawer(false)}
            >
              <div className={classes.list}>
                <List dense>
                  <ListItem button>
                    <ListItemText
                      primary="ALL"
                      onClick={() => this.setState({ clientId: "ALL" })}
                    />
                  </ListItem>
                  <VideoClients
                    viewer={this.props.viewer}
                    myCallBack={this.myCallBack}
                  />
                </List>
              </div>
            </div>
          </Drawer>
        )}
        <div className={classes.horizontalContainer2}>
          <ReactTable
            data={data}
            columns={fixedColumns.concat(addedColumns)}
            defaultPageSize={pageSize}
            showPagination={data.length > pageSize}
            filterable
            defaultFilterMethod={(filter, row) =>
              String(row[filter.id])
                .toLowerCase()
                .includes(filter.value.toLowerCase())
            }
            className="-striped -highlight"
            style={{
              display: "block",
              margin: "8px 0px 8px 8px",
              fontSize: "13px",
              flex: "auto",
              minWidth: "35vw",
            }}
            getTrProps={(state, rowInfo) => {
              const selectedId = rowInfo ? rowInfo.original.id : null;
              return {
                onClick: () => this.selectRow(rowInfo.original.id),
                style: {
                  background: selectedId === selectedRow ? openRow : "#FFFFFF",
                },
              };
            }}
            getTdProps={(state, rowInfo /* , column, instance */) => ({
              onClick: (e, handleOriginal) => {
                e.preventDefault();
                // console.log('A Td Element was clicked!');
                // console.log('it produced this event:', e);
                // console.log('It was in this column:', column);
                // console.log('It has this ROW:', rowInfo.original);
                // console.log('It has this id:', rowInfo.original.id);
                // console.log('It was in this table instance:', instance);
                rowInfo ? this.handleControlToggle(rowInfo.original) : null;
                handleOriginal ? handleOriginal() : null;
              },
            })}
          />
          {deviceControlId !== "none" && deviceControlId ? (
            <VideoDeviceControl
              clientId={clientId}
              viewer={viewer}
              deviceControl={deviceControl}
              toggleClose={this.toggleClose}
              deviceIds={deviceIds}
            />
          ) : null}
        </div>
        <Typography
          variant="caption"
          color="secondary"
          className={classes.typo}
          style={{ paddingLeft: 8 }}
          align="left"
        >
          Tip: Hold shift when sorting to multi-sort!
        </Typography>
      </div>
    ) : null;
  }
}

VideoDevices.propTypes = {
  classes: PropTypes.object.isRequired, //eslint-disable-line
  viewer: PropTypes.object.isRequired, //eslint-disable-line
};

export default withStyles(styles)(
  createFragmentContainer(VideoDevices, {
    viewer: graphql`
      fragment VideoDevicesInactive_viewer on Viewer {
        allTags {
          edges {
            node {
              id
              name
              description
            }
          }
        }
        allVideoClients {
          edges {
            node {
              id
              name
            }
          }
        }
        allVideoDevices {
          edges {
            node {
              id
              name
              uid
              description
              macAddress
              currentlyPlaying
              volume
              lastSync
              lanMacAddress
              internalIp
              publicIp
              lastOnline
              appVersion
              reboot
              takeScreenShot
              sync
              format
              screenShot
              uploadLogs
              model
              serialNumber
              usedStorage
              totalStorage
              availableStorage
              hdmiStatus
              downloading
              appUpTime
              isPlaying
              clientId
              tags
              profile {
                profileId
                name
                format
                volume
                url
                syncTime
                rebootTime
                clientId
              }
              deviceStatus
            }
          }
        }
        allVideoProfiles {
          edges {
            node {
              id
              name
              format
              volume
              url
              syncTime
              rebootTime
              clientId
            }
          }
        }
        user {
          id
          consoleSettings {
            videoDevices {
              appDetails
              deviceActions
              systemDetailsControls
              tags
            }
          }
        }
      }
    `,
  })
);
