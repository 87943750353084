/**
 * @flow
 * @relayHash 6e6efd13424113f764128b42573d33bc
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ServerInstance = "backup" | "primary" | "%future added value";
export type customActionDeviceMutationVariables = {|
  devices: $ReadOnlyArray<?string>,
  customAction: string,
  serverInstance: ServerInstance,
|};
export type customActionDeviceMutationResponse = {|
  +customActionDevice: ?{|
    +id: string,
    +userId: ?string,
    +clientId: ?string,
    +type: ?string,
    +packageId: ?string,
    +reports: ?$ReadOnlyArray<?{|
      +deviceid: ?string,
      +state: ?string,
    |}>,
    +date: ?any,
  |}
|};
export type customActionDeviceMutation = {|
  variables: customActionDeviceMutationVariables,
  response: customActionDeviceMutationResponse,
|};
*/


/*
mutation customActionDeviceMutation(
  $devices: [String]!
  $customAction: String!
  $serverInstance: ServerInstance!
) {
  customActionDevice(devices: $devices, customAction: $customAction, serverInstance: $serverInstance) {
    id
    userId
    clientId
    type
    packageId
    reports {
      deviceid
      state
    }
    date
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "customAction"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "devices"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "serverInstance"
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "customAction",
        "variableName": "customAction"
      },
      {
        "kind": "Variable",
        "name": "devices",
        "variableName": "devices"
      },
      {
        "kind": "Variable",
        "name": "serverInstance",
        "variableName": "serverInstance"
      }
    ],
    "concreteType": "WebkeyTask",
    "kind": "LinkedField",
    "name": "customActionDevice",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "userId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "type",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "packageId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "WebkeyReport",
        "kind": "LinkedField",
        "name": "reports",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "deviceid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "state",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "date",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "customActionDeviceMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "customActionDeviceMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "id": "6e6efd13424113f764128b42573d33bc",
    "metadata": {},
    "name": "customActionDeviceMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b01cf7628780f6e328a694f6d89ff1c9';

module.exports = node;
