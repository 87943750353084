/**
 * @flow
 * @relayHash 7845c11a65ef8de7b623d82f45a4ba2b
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type videoProfileProviderData = {|
  name: string,
  format: string,
  volume?: ?number,
  url?: ?string,
  syncTime?: ?string,
  rebootTime?: ?string,
  clientId?: ?string,
|};
export type newVideoProfileMutationVariables = {|
  input: videoProfileProviderData
|};
export type newVideoProfileMutationResponse = {|
  +newVideoProfile: ?{|
    +id: string,
    +name: string,
    +format: string,
    +volume: ?number,
    +url: ?string,
    +syncTime: ?string,
    +rebootTime: ?string,
    +clientId: ?string,
  |}
|};
export type newVideoProfileMutation = {|
  variables: newVideoProfileMutationVariables,
  response: newVideoProfileMutationResponse,
|};
*/


/*
mutation newVideoProfileMutation(
  $input: videoProfileProviderData!
) {
  newVideoProfile(videoProfileProvider: $input) {
    id
    name
    format
    volume
    url
    syncTime
    rebootTime
    clientId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "videoProfileProvider",
        "variableName": "input"
      }
    ],
    "concreteType": "VideoProfile",
    "kind": "LinkedField",
    "name": "newVideoProfile",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "format",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "volume",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "url",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "syncTime",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "rebootTime",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "newVideoProfileMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "newVideoProfileMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "7845c11a65ef8de7b623d82f45a4ba2b",
    "metadata": {},
    "name": "newVideoProfileMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '20873e0b3ca821d53cbaa46c85e5d7f2';

module.exports = node;
