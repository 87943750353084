import { commitMutation } from "react-relay";
import graphql from 'babel-plugin-relay/macro';
import Environment from "../../environment";

const mutation = graphql`
  mutation updateNewLocationMutation(
    $deviceId: String!
    $latitude: String
    $longitude: String
  ) {
    updateNewLocation(
      deviceId: $deviceId
      latitude: $latitude
      longitude: $longitude
    ) {
      id
      latitude
      longitude
    }
  }
`;

export default (deviceId, latitude, longitude, callback) => {
  const variables = {
    deviceId,
    latitude,
    longitude,
  };

  commitMutation(Environment, {
    mutation,
    variables,
    onCompleted: () => callback(),
    onError: (err) => console.error(err), // eslint-disable-line
  });
};
