import { requestSubscription } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import { ConnectionHandler } from "relay-runtime";
import Environment from "../../environment";

const subscription = graphql`
  subscription UserSubscription(
    $token: String!
    $updatedId: ID
    $updatedFields: [String]
  ) {
    UserSubscription(
      token: $token
      updatedId: $updatedId
      updatedFields: $updatedFields
    ) {
      mutation
      node {
        id
        createdAt
        roles
        services {
          password {
            bcrypt
          }
          google {
            accessToken
            idToken
            id
            email
            name
            given_name
            family_name
            picture
          }
          facebook {
            accessToken
            id
            email
            name
            first_name
            last_name
            link
            gender
            locale
            verified
          }
        }
        emails {
          address
          verified
        }
        profile {
          name
          clientId
          videoClientId
          sonosPermit
          qsysPermit
        }
        rolesConsole {
          roleAccess
          roleAudio
          roleVideo
        }
        status {
          online
          lastLogin {
            date
            ipAddr
          }
        }
        qsys {
          pin
          multiTenant
          globalZoneLimit
          hideVolumeBar
        }
        sonos {
          hideVolumeBar
        }
        apiKey
        companyName
        serviceType
        defaultServiceType
      }
    }
  }
`;

const UserSubscription = (token, updatedId, updatedFields, onNext) => {
  const subscriptionConfig = {
    subscription,
    variables: {
      token,
      updatedId,
      updatedFields,
    },
    refetch: (options) => console.log(options),
    updater: (store) => {
      const getRootField = store.getRootField("UserSubscription");
      const mutation = getRootField.getValue("mutation");
      const node = getRootField.getLinkedRecord("node");
      switch (mutation) {
        case "CREATED":
          try {
            const clientProxy = store.get("client:root:viewer:allUsers");
            const newEdge = ConnectionHandler.createEdge(
              store,
              clientProxy,
              node,
              "UserEdge"
            );
            ConnectionHandler.insertEdgeBefore(clientProxy, newEdge);
          } catch (e) {
            console.log("User subscription error :", e);
          }
          break;
        case "UPDATED":
          break;
        case "DELETED":
          {
            const nodeId = node.getDataID();
            store.delete(nodeId);
          }
          break;
        default:
          console.log("Something is wrong"); //eslint-disable-line
      }
    },
    onNext,
    onCompleted: () => console.log("done"),
    onError: (error) => console.log("An error occured:", error),
  };
  return requestSubscription(Environment, subscriptionConfig);
};

export default UserSubscription;
