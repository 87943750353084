/**
 * @flow
 * @relayHash 1e30b8505c951683917c72a05560de0c
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type updateAudioDeviceClientMutationVariables = {|
  inputDeviceId: string,
  inputClientId: string,
|};
export type updateAudioDeviceClientMutationResponse = {|
  +updateAudioDeviceClient: ?{|
    +id: string
  |}
|};
export type updateAudioDeviceClientMutation = {|
  variables: updateAudioDeviceClientMutationVariables,
  response: updateAudioDeviceClientMutationResponse,
|};
*/


/*
mutation updateAudioDeviceClientMutation(
  $inputDeviceId: String!
  $inputClientId: String!
) {
  updateAudioDeviceClient(deviceId: $inputDeviceId, clientId: $inputClientId) {
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "inputClientId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "inputDeviceId"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "clientId",
        "variableName": "inputClientId"
      },
      {
        "kind": "Variable",
        "name": "deviceId",
        "variableName": "inputDeviceId"
      }
    ],
    "concreteType": "AudioDevice",
    "kind": "LinkedField",
    "name": "updateAudioDeviceClient",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "updateAudioDeviceClientMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "updateAudioDeviceClientMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "id": "1e30b8505c951683917c72a05560de0c",
    "metadata": {},
    "name": "updateAudioDeviceClientMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '219be3463fb29404e03c9de1572e0232';

module.exports = node;
