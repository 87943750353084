import React from "react";
import PropTypes from "prop-types";
import { createRefetchContainer } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import { withStyles } from "@material-ui/core/styles";
import { withSnackbar } from "material-ui-snackbar-provider";
import compose from "recompose/compose";
import moment from "moment";
import AppBar from "@material-ui/core/AppBar";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import SyncIcon from "@material-ui/icons/Sync";
import DeleteIcon from "@material-ui/icons/Delete";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import QueueMusicIcon from "@material-ui/icons/QueueMusic";
import ViewQuiltIcon from "@material-ui/icons/ViewQuilt";
import AllInclusiveIcon from "@material-ui/icons/AllInclusive";
import WatchIcon from "@material-ui/icons/Watch";
import Loupe from "@material-ui/icons/Loupe";
import Typography from "@material-ui/core/Typography";

import SM from "../subscriptions/index";
import SceneList from "./SceneList";
import PlaylistList from "./PlaylistList";
import PlaybackList from "./PlaybackList";
import ScheduleList from "./ScheduleList";
import AudioClientCreate from "./AudioClientCreate";
import AudioClientEditQuery from "./AudioClientEditQuery";
import AudioClientDelete from "./AudioClientDelete";
import PlaybacksNew from "./PlaybacksNew";
import ScheduleNew from "./ScheduleNew";
import permissions2 from "../authentication/UIPermissions";
import permissions from "../authentication/isPermited";

import removePreviewKEYMutation from "../mutations/removePreviewKEYMutation";
import generatePreviewKEYMutation from "../mutations/generatePreviewKEYMutation";

import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import AudioClients from "../audio_devices/AudioClients";
import extendPreviewTokenMutation from "../mutations/extendPreviewTokenMutation";

const TabContainer = (props) => (
  <div
    style={{
      padding: 5,
      flex: "auto",
    }}
  >
    {props.children}
  </div>
);

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const styles = (theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    backgroundColor: theme.palette.background.paper,
  },
  clientHeader: {
    display: "flex",
    flexWrap: "wrap",
    flex: 1,
  },
  list: {
    width: 250,
  },
  listFull: {
    width: "auto",
  },
  typo: {
    padding: 0,
  },
  button: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    marginLeft: "9px",
    "&:hover": {
      background: "#e3fd59",
    },
    minWidth: 150,
    margin: 5,
    marginBottom: 9,
  },
  horizontalContainer: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
});

class PlaybackTabsQuery extends React.Component {
  constructor() {
    super();
    this.state = {
      value: 0,
      left: false,
      clientId: localStorage.getItem("audioClientId"),
      showAudioClientCreate: false,
      showAudioClientEdit: false,
      showAudioClientDelete: false,
      showNewSmartPlaylist: false,
      showNewSchedule: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.toggleDrawer = this.toggleDrawer.bind(this);
    this.myCallBack = this.myCallBack.bind(this);
    this.onButtonClick = this.onButtonClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  shouldComponentUpdate() {
    if (this.props.match.location.pathname === window.location.pathname) {
      return true;
    }
    return false;
  }

  componentDidMount() {
    const loginProcedure = async () => {
      if (SM.token) {
        this.subscription1 = await SM.PlaylistSubscription(SM.token);
        this.subscription2 = await SM.SceneSubscription(SM.token);
      }
    };
    loginProcedure();
  }

  async componentWillUnmount() {
    try {
      await this.subscription1?.dispose();
      await this.subscription2?.dispose();
    } catch (e) {
      console.log("componentWillUnmount PlaybackTabs Error:", e);
    }
  }

  onButtonClick(data) {
    this.setState({
      [data]: true,
    });
  }

  myCallBack(clientId) {
    this.setState({
      clientId,
    });
    localStorage.setItem("audioClientId", clientId);
    return clientId;
  }

  toggleDrawer(open) {
    return this.setState({
      left: open,
    });
  }

  handleChange(event, value) {
    this.setState({ value });
  }

  handleClose() {
    this.setState({
      showAudioClientCreate: false,
      showAudioClientEdit: false,
      showAudioClientDelete: false,
      showNewSmartPlaylist: false,
      showNewSchedule: false,
    });
  }

  render() {
    const { classes, methods, viewer } = this.props;
    const {
      value,
      clientId,
      showAudioClientCreate,
      showAudioClientEdit,
      showAudioClientDelete,
      showNewSmartPlaylist,
      showNewSchedule,
      left,
    } = this.state;

    const currentClient =
      clientId === "ALL"
        ? null
        : viewer.allClients.edges
            .filter((item) => item && item.node)
            .find((item) => item.node.id === clientId);

    localStorage.setItem("audioClientId", clientId);

    return permissions2("5cac9e763630643c2a423819", methods) ? (
      <div
        className={classes.root}
        style={{ display: "flex", flexDirection: "column" }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginLeft: "12px",
            justifyContent: "center",
          }}
        >
          <h2>
            <span>{this.props.match.route.name}</span>
            <span style={{ fontSize: "18px", color: "grey" }}>
              &nbsp; @{" "}
              {clientId === "ALL"
                ? "ALL"
                : currentClient && currentClient.node.name}
            </span>
          </h2>
        </div>
        <div
          className={classes.clientHeader}
          style={{
            display: "flex",
            height: "60px",
            flexDirection: "row",
            alignItems: "center",
            marginLeft: "12px",
          }}
        >
          {permissions([], [1, 2]) && (
            <>
              <span
                style={{
                  cursor: "pointer",
                  color: "#3BABA1",
                  fontSize: 16,
                  fontWeight: "bold",
                }}
                onClick={() => this.toggleDrawer(true)}
              >
                Change Client &nbsp;
              </span>{" "}
              |
            </>
          )}
          {permissions([], [1]) ? (
            <>
              {clientId !== "ALL" ? (
                <p>
                  <span
                    style={{
                      cursor: "pointer",
                      color: "#3BABA1",
                      fontSize: 16,
                      fontWeight: "bold",
                    }}
                    onClick={() => this.onButtonClick("showAudioClientEdit")}
                  >
                    &nbsp;Edit Client
                  </span>{" "}
                  |&nbsp;
                </p>
              ) : null}
            </>
          ) : null}
          {permissions([], [1]) ? (
            <>
              {clientId !== "ALL" ? (
                <p>
                  <span
                    style={{
                      cursor: "pointer",
                      color: "#3BABA1",
                      fontSize: 16,
                      fontWeight: "bold",
                    }}
                    onClick={() => this.onButtonClick("showAudioClientDelete")}
                  >
                    &nbsp;Delete Client
                  </span>{" "}
                  |&nbsp;
                </p>
              ) : null}
            </>
          ) : null}
          {value === 1 && permissions([], [1, 2]) ? (
            <p>
              <span
                style={{
                  cursor: "pointer",
                  color: "#3BABA1",
                  fontSize: 16,
                  fontWeight: "bold",
                }}
                onClick={() => this.onButtonClick("showNewSmartPlaylist")}
              >
                &nbsp;New Smart Playlist
              </span>{" "}
              |&nbsp;
            </p>
          ) : null}

          {value === 3 && permissions([], [1, 2]) ? (
            <p>
              <span
                style={{ cursor: "pointer", color: "#3BABA1", fontSize: 16 }}
                onClick={() => this.onButtonClick("showNewSchedule")}
              >
                &nbsp;New Schedule
              </span>{" "}
              |&nbsp;
            </p>
          ) : null}
        </div>
        <div
          style={{
            display: "flex",
            flex: "1",
            justifyContent: "flex-end",
          }}
        ></div>
        {permissions([], [1]) && (
          <AudioClientCreate
            open={showAudioClientCreate}
            viewer={viewer}
            handleRequestClose={this.handleClose}
            myCallBack={this.myCallBack}
          />
        )}
        {permissions([], [1]) && (
          <AudioClientEditQuery
            handleRequestClose={this.handleClose}
            open={showAudioClientEdit}
            clientId={clientId}
            currentClient={currentClient || {}}
          />
        )}
        {permissions([], [1]) && (
          <AudioClientDelete
            handleRequestClose={this.handleClose}
            open={showAudioClientDelete}
            clientId={clientId}
            myCallBack={this.myCallBack}
          />
        )}
        {permissions([], [1, 2]) && (
          <PlaybacksNew
            viewer={viewer}
            handleRequestClose={this.handleClose}
            open={showNewSmartPlaylist}
            clientId={clientId}
          />
        )}
        {permissions([], [1, 2]) && (
          <ScheduleNew
            handleRequestClose={this.handleClose}
            open={showNewSchedule}
            clientId={clientId}
            viewer={viewer}
          />
        )}
        <Drawer open={left} onClose={() => this.toggleDrawer(false)}>
          <div
            tabIndex={0}
            role="button"
            onClick={() => this.toggleDrawer(false)}
            onKeyDown={() => this.toggleDrawer(false)}
          >
            <div className={classes.list}>
              <List dense>
                {permissions([], [1]) && (
                  <ListItem
                    button
                    divider
                    onClick={() => this.onButtonClick("showAudioClientCreate")}
                  >
                    <Loupe color="primary" />
                    <ListItemText primary="CREATE NEW CLIENT" />
                  </ListItem>
                )}
                <ListItem button>
                  <ListItemText
                    primary="ALL"
                    onClick={() =>
                      this.setState({
                        clientId: "ALL",
                      })
                    }
                  />
                </ListItem>
                <AudioClients viewer={viewer} myCallBack={this.myCallBack} />
              </List>
            </div>
          </div>
        </Drawer>
        {currentClient && permissions([], [1]) && (
          <div style={{ marginLeft: 12 }}>
            <div className={classes.horizontalContainer}>
              <Typography
                className={classes.typo2}
                variant="body2"
                color="textSecondary"
              >
                <b>Preview API Key: </b>
                {(!currentClient?.node?.preview?.isEnded &&
                  currentClient?.node?.preview?.previewId) ||
                  "None"}
              </Typography>
              {currentClient?.node?.preview?.previewId && (
                <>
                  <Tooltip
                    title="Copy full URL for preview"
                    placement="bottom"
                    enterTouchDelay={200}
                    onClick={() => {
                      navigator.clipboard.writeText(
                        `https://preview.curator.activaire.com/${currentClient?.node?.preview?.previewId}`
                      );
                    }}
                  >
                    <IconButton
                      aria-label="generateNew"
                      className={classes.margin}
                      size="small"
                    >
                      <FileCopyIcon fontSize="inherit" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    title="Extend URL with unlimited amount of time"
                    placement="bottom"
                    enterTouchDelay={200}
                    onClick={() => {
                      extendPreviewTokenMutation(clientId, () =>
                        console.log("Action completed")
                      );
                    }}
                  >
                    <IconButton
                      aria-label="generateNew"
                      className={classes.margin}
                      size="small"
                    >
                      <AllInclusiveIcon
                        fontSize="inherit"
                        color={
                          currentClient?.node?.preview?.isEndless
                            ? "primary"
                            : "inherit"
                        }
                      />
                    </IconButton>
                  </Tooltip>
                </>
              )}
              <Tooltip
                title="Generate new API Key"
                placement="bottom"
                enterTouchDelay={200}
                onClick={() =>
                  generatePreviewKEYMutation(clientId, () =>
                    console.log("New API Key generated")
                  )
                }
              >
                <IconButton
                  aria-label="generateNew"
                  className={classes.margin}
                  size="small"
                >
                  <SyncIcon fontSize="inherit" />
                </IconButton>
              </Tooltip>
              <Tooltip
                title="Remove API Key"
                placement="bottom"
                enterTouchDelay={200}
                onClick={() =>
                  removePreviewKEYMutation(clientId, () =>
                    console.log("API Key Removed")
                  )
                }
              >
                <IconButton
                  aria-label="generateNew"
                  className={classes.margin}
                  size="small"
                >
                  <DeleteIcon fontSize="inherit" />
                </IconButton>
              </Tooltip>
            </div>
            <Typography
              className={classes.typo2}
              variant="body2"
              color="textSecondary"
            >
              {!currentClient?.node?.preview?.isEnded &&
                (currentClient?.node?.preview?.isEndless ? (
                  <>
                    <b>This key doesn't expire</b>
                  </>
                ) : (
                  <>
                    <b>Key will expire on: </b>

                    {moment(currentClient?.node?.preview?.ending).format(
                      "YYYY-MM-DD"
                    )}
                  </>
                ))}
            </Typography>
          </div>
        )}
        <AppBar
          position="static"
          style={{
            backgroundColor: "transparent",
            boxShadow: "none",
            marginLeft: "12px",
            marginTop: "24px",
            marginBottom: "26px",
          }}
        >
          <Breadcrumbs aria-label="breadcrumb">
            &nbsp;
            <Typography
              color="textPrimary"
              onClick={() => this.setState({ value: 0 })}
              className={classes.link}
              style={{
                cursor: "pointer",
                display: "flex",
                fontSize: "18px",
                color: this.state.value === 0 ? "#000" : "grey",
                fontWeight: this.state.value === 0 ? "bold" : "normal",
              }}
            >
              <QueueMusicIcon style={{ color: "#000" }} />
              &nbsp;Playlist
            </Typography>
            &nbsp;
            <Typography
              color="textPrimary"
              onClick={() => this.setState({ value: 1 })}
              className={classes.link}
              style={{
                cursor: "pointer",
                display: "flex",
                fontSize: "18px",
                color: this.state.value === 1 ? "#000" : "grey",
                fontWeight: this.state.value === 1 ? "bold" : "normal",
              }}
            >
              <ViewQuiltIcon className={classes.icon} />
              &nbsp;Smart playlist
            </Typography>
            &nbsp;
            <Typography
              color="textPrimary"
              onClick={() => this.setState({ value: 2 })}
              className={classes.link}
              style={{
                cursor: "pointer",
                display: "flex",
                fontSize: "18px",
                color: this.state.value === 2 ? "#000" : "grey",
                fontWeight: this.state.value === 2 ? "bold" : "normal",
              }}
            >
              <AllInclusiveIcon className={classes.icon} />
              &nbsp;Scene
            </Typography>
            &nbsp;
            <Typography
              color="textPrimary"
              onClick={() => this.setState({ value: 3 })}
              className={classes.link}
              style={{
                cursor: "pointer",
                display: "flex",
                fontSize: "18px",
                color: this.state.value === 3 ? "#000" : "grey",
                fontWeight: this.state.value === 3 ? "bold" : "normal",
              }}
            >
              <WatchIcon className={classes.icon} />
              &nbsp;Schedule
            </Typography>
          </Breadcrumbs>
        </AppBar>
        {value === 0 && (
          <TabContainer>
            {clientId === "ALL" ? (
              <PlaylistList viewer={viewer} client={{}} />
            ) : (
              <PlaylistList
                viewer={viewer}
                client={currentClient && currentClient.node}
              />
            )}
          </TabContainer>
        )}
        {value === 1 && (
          <TabContainer>
            {clientId === "ALL" ? (
              <PlaybackList viewer={viewer} clientId="" />
            ) : (
              <PlaybackList viewer={viewer} clientId={clientId} />
            )}
          </TabContainer>
        )}
        {value === 2 && (
          <TabContainer>
            {clientId === "ALL" ? (
              <SceneList clientId="" viewer={viewer} />
            ) : (
              <SceneList clientId={clientId} viewer={viewer} />
            )}
          </TabContainer>
        )}
        {value === 3 && (
          <TabContainer>
            {clientId === "ALL" ? (
              <ScheduleList clientId="" viewer={viewer} />
            ) : (
              <ScheduleList clientId={clientId} viewer={viewer} />
            )}
          </TabContainer>
        )}
      </div>
    ) : null;
  }
}

PlaybackTabsQuery.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(
  withStyles(styles),
  withSnackbar()
)(
  createRefetchContainer(PlaybackTabsQuery, {
    viewer: graphql`
      fragment PlaybackTabsQuery_viewer on Viewer {
        allClients {
          edges {
            node {
              id
              name
              playlistIds
              playbackIds
              sceneIds
              featuredIds
              logo
              preview {
                previewId
                created
                ending
                isEnded
                isEndless
              }
              viewableCategories {
                name
                subCategories
              }
              useExplicit
            }
          }
        }
        allPlaylists {
          edges {
            node {
              id
              name
              description
              cover
              tracks
              size
              failed
              updatedAt
              createdAt
              signedUrl
              genre
              subGenre
              tempo
              clientsId
              duration
              files {
                status
              }
            }
          }
        }
        allPlaybacks {
          edges {
            node {
              id
              name
              description
              shuffle
              image
              size
              createdAt
              updatedAt
              playlists {
                playlistId
                name
                nrOfSongs
                filename
              }
              clientId
            }
          }
        }
        allSchedule {
          edges {
            node {
              id
              name
              description
              size
              injectable
              startDate
              endDate
              clientId
              playingPlaylists {
                playlistId
                name
                volume
                startTime
                endTime
                startTimeSeconds
                endTimeSeconds
                days
                shuffle
                filenames {
                  mixId
                  nrOfSongs
                }
              }
            }
          }
        }
        allAudioDevices {
          edges {
            node {
              id
              name
              scheduleIds
              clientId
              deviceStatus
              currentPlaylistId
            }
          }
        }
        allCategories {
          edges {
            node {
              id
              name
              subCategories
              sortOrder
            }
          }
        }
        allScenes {
          edges {
            node {
              id
              name
              description
              image
              size
              createdAt
              updatedAt
              playlists {
                playlistId
              }
              tracks
              newCategory {
                category
                subCategories
              }
            }
          }
        }
      }
    `,
  })
);
