import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";
import moment from "moment";

const styles = (theme) => ({
  root: {
    margin: "4px 0px 4px 0px",
  },
  card: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "6px",
    zIndex: 9999,
  },
  content: {
    background: "#f9f9f9",
  },
  expansion: {
    flex: "auto",
    boxShadow:
      "0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12)",
  },
  expansionSummary: {
    minHeight: 16,
    height: 24,
  },
  expansionDetails: {
    padding: "8px 8px 8px 8px",
    display: "flex",
    flexDirection: "column",
  },
  actionsContainer: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  button: {
    marginRight: theme.spacing(1),
  },
  horizontalContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  verticalContainer: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    margin: 4,
  },
  volume: {
    minWidth: 250,
    paddingLeft: 5,
  },
  typo1: {
    display: "flex",
    alignItems: "center",
    color: "#222831",
    margin: "-5px 0 8px 0",
    letterSpacing: "0.05em",
  },
  typo2: {
    paddingLeft: 4,
    color: "#222831",
    fontWeight: "lighter",
    letterSpacing: "0.06em",
    marginBottom: "2px",
  },
  typo3: {
    paddingLeft: 4,
    paddingTop: 2,
    position: "absolute",
    zIndex: 1,
    letterSpacing: "0.05em",
  },
  progress: {
    borderRadius: "5px",
    height: 30,
    minWidth: 500,
  },
  progressRoot: {
    background: "#c4c4c4",
  },
  bar: {
    background: "#ff4000",
  },
});

class AppDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes, deviceControl } = this.props;
    const {
      appStatus,
      appUpTime,
      appVersion,
      m3uFilesOnDevice,
      songFilesOnDevice,
      totalSdStorage,
      usedSdStorage,
      availableSdStorage,
      appVersionDS,
    } = deviceControl;

    return (
      <div className={classes.root}>
        <Card className={classes.card}>
          <CardContent className={classes.content}>
            <Typography className={classes.typo1} variant="h6">
              Application Details
            </Typography>
            <div
              className={classes.horizontalContainer}
              style={{ marginBottom: "10px" }}
            >
              <div className={classes.verticalContainer}>
                <Typography
                  className={classes.typo2}
                  variant="body2"
                  color="textSecondary"
                >
                  <b>App Uptime: </b>
                  {appUpTime}
                </Typography>
                <Typography
                  className={classes.typo2}
                  variant="body2"
                  color="textSecondary"
                >
                  <b>Last sync: </b>
                  {moment(appStatus ? appStatus.time : 0).format("lll")}
                </Typography>

                <Typography
                  className={classes.typo2}
                  variant="body2"
                  color="textSecondary"
                  style={{ width: "300px" }}
                >
                  <b>Message: </b>
                  {appStatus ? appStatus.message : "N/A"}
                </Typography>
                <Typography
                  className={classes.typo2}
                  variant="body2"
                  color="textSecondary"
                >
                  <b>Details: </b>
                  {appStatus ? appStatus.description : "N/A"}
                </Typography>
                <Typography
                  className={classes.typo2}
                  variant="body2"
                  color="textSecondary"
                >
                  <b>Status: </b>
                  {appStatus ? appStatus.status : "N/A"}
                </Typography>
              </div>
              <div className={classes.verticalContainer}>
                <Typography
                  className={classes.typo2}
                  variant="body2"
                  color="textSecondary"
                >
                  <b>App Version: </b>
                  {appVersionDS ? appVersionDS : null}
                </Typography>

                <Typography
                  className={classes.typo2}
                  variant="body2"
                  color="textSecondary"
                >
                  <b>Songs on device: </b>
                  {songFilesOnDevice}
                </Typography>
                <Typography
                  className={classes.typo2}
                  variant="body2"
                  color="textSecondary"
                >
                  <b>Number of playlists: </b>
                  {m3uFilesOnDevice}
                </Typography>
              </div>
            </div>

            <div className={classes.horizontalContainer}>
              <Typography
                style={{
                  marginBottom: "-3px",
                  paddingLeft: "6px",
                }}
                fontWeight="fontWeightBold"
                className={classes.typo1}
                variant="subtitle1"
                color="textSecondary"
              >
                Device Storage
              </Typography>
            </div>
            <div className={classes.horizontalContainer}>
              <div className={classes.volume}>
                <Typography
                  className={classes.typo3}
                  variant="subtitle1"
                  color="textSecondary"
                >
                  {`Storage ${usedSdStorage ? usedSdStorage.toFixed(2) : 0}GB/${
                    totalSdStorage ? totalSdStorage.toFixed(2) : 0
                  }GB (Available:${
                    availableSdStorage ? availableSdStorage.toFixed(2) : 0
                  }GB)`}
                </Typography>
                <LinearProgress
                  classes={{ root: classes.progressRoot, bar: classes.bar }}
                  color="primary"
                  variant="determinate"
                  value={
                    usedSdStorage && totalSdStorage
                      ? (usedSdStorage / totalSdStorage) * 100
                      : 0
                  }
                  className={classes.progress}
                />
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
    );
  }
}

AppDetails.defaultProps = {
  appStatus: {},
};

AppDetails.propTypes = {
  classes: PropTypes.object,
  appStatus: PropTypes.object,
  appUpTime: PropTypes.string,
  appVersion: PropTypes.string,
  m3uFilesOnDevice: PropTypes.number,
  songFilesOnDevice: PropTypes.number,
  totalSdStorage: PropTypes.number,
  usedSdStorage: PropTypes.number,
  availableSdStorage: PropTypes.number,
};

export default withStyles(styles)(AppDetails);
