/**
 * @flow
 * @relayHash cbc0be0ddeba13264c9fad00502ceafa
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AUTH_PROVIDER_EMAIL = {|
  email: string,
  password: string,
|};
export type signinUserMutationVariables = {|
  input?: ?AUTH_PROVIDER_EMAIL
|};
export type signinUserMutationResponse = {|
  +signinUser: {|
    +token: ?string,
    +user: ?{|
      +id: string,
      +profile: ?{|
        +clientId: ?string,
        +videoClientId: ?string,
      |},
    |},
  |}
|};
export type signinUserMutation = {|
  variables: signinUserMutationVariables,
  response: signinUserMutationResponse,
|};
*/


/*
mutation signinUserMutation(
  $input: AUTH_PROVIDER_EMAIL
) {
  signinUser(email: $input) {
    token
    user {
      id
      profile {
        clientId
        videoClientId
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "email",
        "variableName": "input"
      }
    ],
    "concreteType": "SigninPayload",
    "kind": "LinkedField",
    "name": "signinUser",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "token",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UserProfile",
            "kind": "LinkedField",
            "name": "profile",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "clientId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "videoClientId",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "signinUserMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "signinUserMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "cbc0be0ddeba13264c9fad00502ceafa",
    "metadata": {},
    "name": "signinUserMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '24b4c3dae05ec9e4574ffc02138fba20';

module.exports = node;
