import { commitMutation } from "react-relay";
import graphql from 'babel-plugin-relay/macro';
import Environment from "../../environment";

const mutation = graphql`
  mutation newVideoProfileMutation($input: videoProfileProviderData!) {
    newVideoProfile(videoProfileProvider: $input) {
      id
      name
      format
      volume
      url
      syncTime
      rebootTime
      clientId
    }
  }
`;

export default (
  name,
  format,
  volume,
  url,
  syncTime,
  rebootTime,
  clientId,
  callback
) => {
  const variables = {
    input: {
      name,
      format,
      volume,
      url,
      syncTime,
      rebootTime,
      clientId,
    },
  };

  commitMutation(Environment, {
    mutation,
    variables,
    onCompleted: (response) => {
      const id = response.newVideoProfile.id;
      callback(id);
    },
    onError: (err) => console.error(err), // eslint-disable-line
  });
};
