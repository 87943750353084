/**
 * @flow
 * @relayHash 439d8edffb1f20b4668af63c338f97b5
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type updateVideoDeviceDescriptionMutationVariables = {|
  inputDeviceId: string,
  inputNewDescription: string,
|};
export type updateVideoDeviceDescriptionMutationResponse = {|
  +updateVideoDeviceDescription: ?{|
    +id: string
  |}
|};
export type updateVideoDeviceDescriptionMutation = {|
  variables: updateVideoDeviceDescriptionMutationVariables,
  response: updateVideoDeviceDescriptionMutationResponse,
|};
*/


/*
mutation updateVideoDeviceDescriptionMutation(
  $inputDeviceId: String!
  $inputNewDescription: String!
) {
  updateVideoDeviceDescription(deviceId: $inputDeviceId, newDescription: $inputNewDescription) {
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "inputDeviceId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "inputNewDescription"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "deviceId",
        "variableName": "inputDeviceId"
      },
      {
        "kind": "Variable",
        "name": "newDescription",
        "variableName": "inputNewDescription"
      }
    ],
    "concreteType": "VideoDevice",
    "kind": "LinkedField",
    "name": "updateVideoDeviceDescription",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "updateVideoDeviceDescriptionMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "updateVideoDeviceDescriptionMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "439d8edffb1f20b4668af63c338f97b5",
    "metadata": {},
    "name": "updateVideoDeviceDescriptionMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '4bf615fdbdb03f3e1daac600896e518e';

module.exports = node;
