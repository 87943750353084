/* eslint-disable no-unused-vars */
import React from "react";
import PropTypes from "prop-types";
import axios from "axios";
import _ from "lodash";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import PlaybacksNewQuery from "./PlaybacksNewQuery";
import editSmartPlaylistMutation from "../mutations/editSmartPlaylistMutation";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
const styles = (theme) => ({
  dialog: {
    background: "secondary",
    margin: theme.spacing(3),
  },
  verticalContainer: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
  },
  list: {
    flexWrap: "wrap",
    flexDirection: "column",
    height: 150,
    width: "300px",
    overflowY: "scroll",
  },
  horizontalContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    margin: "8px 8px 0px 8px",
    width: 250,
  },
  textField2: {
    width: 20,
    margin: 0,
  },
  formControlLabel: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 230,
  },
  button: {
    margin: "8px 8px 8px 8px",
    maxWidth: 140,
  },
  button2: {
    margin: "0px 8px 0px 8px",
    maxWidth: 140,
  },
  buttonSmall: {},
  dialogTitle: {
    padding: "0px 24px 0px 24px",
    minWidth: 230,
    maxWidth: 800,
  },
  closeIcon: {
    height: 28,
    width: 28,
  },
  close: {
    position: "relative",
    float: "right",
  },
  listItemSecondaryAction: {
    display: "flex",
  },
  input: {
    display: "none",
  },
  imgPreview: {
    textAlign: "center",
    marginTop: 8,
    marginLeft: 8,
  },
  img: {
    height: 180,
    maxWidth: 280,
  },
});

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

class PlaybackEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        name: this.props.data.name,
        description: this.props.data.description,
        shuffle: true,
      },
      editSmartList: this.props.data.playlists.map((playlist) => {
        const found = this.props.allPlaylists.find(
          (item) => item && item.node.id === playlist.playlistId
        );
        if (found) {
          return found.node;
        }
        return playlist;
      }),
      editSmartListValues: this.props.data.playlists.map(
        (playlist) => playlist.nrOfSongs
      ),
      file: "",
      imagePreviewUrl: this.props.data.image,
      imageChanged: false,
    };
    this.handleRequestClose = this.handleRequestClose.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.myCallBack = this.myCallBack.bind(this);
    this.handleLeft = this.handleLeft.bind(this);
    this.handleRight = this.handleRight.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.fileUpload = this.fileUpload.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  handleRequestClose(data) {
    this.props.handleRequestClose(data);
  }

  onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      this.state.editSmartList,
      result.source.index,
      result.destination.index
    );

    this.setState({
      editSmartList: items,
    });

    const amountValues = [
      ...this.state.editSmartListValues.map((item) => item.toString()),
    ];

    // Swapping stuff, don't mess with this, it took me hours to figure out.

    amountValues.splice(result.source.index, 1);

    amountValues.splice(
      result.destination.index,
      0,
      this.state.editSmartListValues[result.source.index].toString()
    );

    this.setState({ editSmartListValues: amountValues });
  }
  handleEdit() {
    const { editSmartList, editSmartListValues, file, data, imageChanged } =
      this.state;
    const imageName =
      file.name && imageChanged
        ? `${data.name}.${
            file.name.split(".")[file.name.split(".").length - 1]
          }`
        : this.props.data.image;
    const size = _.uniq(editSmartList, "filename")
      .map((items) => items.size)
      .reduce((total = 0, num) => total + num);
    const playlists = editSmartList.map((playlist, i) => {
      const item = {
        id: playlist.id,
        nrOfSongs: editSmartListValues[i].toString(),
        name: playlist.name,
        filename: playlist.filename,
      };
      return item;
    });

    editSmartPlaylistMutation(
      this.props.data.id,
      data.name,
      data.description,
      data.shuffle,
      size,
      this.props.data.clientId,
      playlists,
      imageName,
      (signedUrl) => {
        if (file !== "" && signedUrl) {
          const options = {
            headers: {
              "Content-Type": file.type,
            },
          };
          axios.put(signedUrl, file, options).then((response) => {
            console.log(response);
            return this.props.handleRequestClose();
          });
        } else {
          this.props.handleRequestClose();
        }
      }
    );
  }

  handleChange(e) {
    this.setState({
      data: { ...this.state.data, [e.target.id]: e.target.value },
    });
  }
  handleClick(e) {
    e.preventDefault();
    const name = e.target.value;
    this.setState({
      data: { ...this.state.data, [name]: !this.state.data.shuffle },
    });
  }

  myCallBack(data) {
    const editSmartList = [...this.state.editSmartList, data];
    const editSmartListValues = [...this.state.editSmartListValues, 1];
    this.setState({ editSmartList, editSmartListValues });
  }

  handleRight(i) {
    const value = Number(this.state.editSmartListValues[i]);
    const newArray = this.state.editSmartListValues;
    newArray[i] = value + 1;
    this.setState({ editSmartListValues: newArray });
  }

  handleLeft(i) {
    const value = Number(this.state.editSmartListValues[i]);
    if (value > 0) {
      const newArray = this.state.editSmartListValues;
      newArray[i] = value - 1;
      this.setState({ editSmartListValues: newArray });
    }
  }

  handleDelete(i) {
    const newArray1 = this.state.editSmartList;
    const newArray2 = this.state.editSmartListValues;
    newArray1.splice(i, 1);
    newArray2.splice(i, 1);
    this.setState({
      editSmartList: newArray1,
      editSmartListValues: newArray2,
    });
  }

  fileUpload(e) {
    e.preventDefault();

    const reader = new FileReader();
    const file = e.target.files[0];

    reader.onloadend = () => {
      this.setState({
        file,
        imagePreviewUrl: reader.result,
        imageChanged: true,
      });
    };

    reader.readAsDataURL(file);
  }

  render() {
    const { classes, data, open, viewer } = this.props;
    const { editSmartList, editSmartListValues, imagePreviewUrl } = this.state;
    const sumTracks = editSmartList[0]
      ? _.uniq(editSmartList, "filename")
          .map(
            (list) => list.files.filter((item2) => item2.status === 1).length
          )
          .reduce((total = 0, num) => total + num)
      : 0;
    const totalSize = editSmartList[0]
      ? _.uniq(editSmartList, "filename")
          .map((item) => item.size)
          .reduce((total = 0, num) => total + num) / 1000000000
      : 0;
    let imagePreview = null;
    if (imagePreviewUrl) {
      imagePreview = (
        <img className={classes.img} src={imagePreviewUrl} alt="" />
      );
    } else {
      imagePreview = (
        <div className={classes.previewText} style={{ padding: 8 }}>
          No image
        </div>
      );
    }
    return (
      <Dialog open={open} className={classes.dialog} maxWidth="lg">
        <div className={classes.close}>
          <IconButton
            aria-label="Close"
            className={classes.close}
            onClick={() => this.props.handleRequestClose()}
          >
            <CloseIcon className={classes.closeIcon} />
          </IconButton>
        </div>
        <DialogTitle
          className={classes.dialogTitle}
          style={{ marginBottom: 16, align: "center" }}
        >
          Edit smart playlist
        </DialogTitle>
        <Divider />
        <div
          style={{
            maxHeight: "580px",
            width: "85%",
            display: "flex",
            flexDirection: "row",
            alignSelf: "center",
            justifyContent: "space-between",
          }}
        >
          <div style={{ minWidth: 260 }}>
            <Typography
              variant="body1"
              color="secondary"
              component="h3"
              style={{
                paddingLeft: 8,
                marginTop: "24px",
                color: "#000",
                fontWeight: "bold",
              }}
            >
              Available playlists
            </Typography>
            <PlaybacksNewQuery
              clientId={data.clientId}
              callBack={this.myCallBack}
              viewer={viewer}
            />
          </div>
          <form
            className={classes.verticalContainer}
            autoComplete="off"
            style={{ padding: "0px 8px 8px 8px", width: "500px" }}
          >
            <Typography
              variant="body1"
              color="secondary"
              component="h3"
              style={{
                paddingLeft: 8,
                marginTop: "24px",
                color: "#000",
                fontWeight: "bold",
              }}
            >
              Selected playlists
            </Typography>
            <DragDropContext onDragEnd={this.onDragEnd}>
              <div style={{ maxWidth: "450px", marginTop: "24px" }}>
                <Droppable droppableId="droppable">
                  {(provided, snapshot) => (
                    <List
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      className={classes.list}
                      style={{ minWidth: "450px", minHeight: "400px" }}
                    >
                      {editSmartList.map((node, i) => (
                        <Draggable key={i} draggableId={String(i)} index={i}>
                          {(provided, snapshot) => (
                            <div
                              key={i}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <ListItem key={i} button>
                                <ListItemText
                                  primary={node.name}
                                  style={{ marginRight: 140, fontSize: "12px" }}
                                />
                                <ListItemSecondaryAction
                                  className={classes.listItemSecondaryAction}
                                >
                                  <IconButton
                                    disableRipple
                                    aria-label="Left"
                                    className={classes.buttonSmall}
                                    onClick={() => this.handleLeft(i)}
                                    fontSize="small"
                                  >
                                    <ChevronLeftIcon />
                                  </IconButton>
                                  <Typography
                                    variant="subtitle1"
                                    color="secondary"
                                    component="h3"
                                    style={{ margin: "13px 5px 0px 8px" }}
                                  >
                                    {editSmartListValues[i]}
                                  </Typography>
                                  <IconButton
                                    disableRipple
                                    aria-label="Right"
                                    className={classes.buttonSmall}
                                    onClick={() => this.handleRight(i)}
                                    fontSize="small"
                                  >
                                    <ChevronRightIcon />
                                  </IconButton>
                                  <IconButton
                                    disableRipple
                                    aria-label="Delete"
                                    className={classes.buttonSmall}
                                    onClick={() => this.handleDelete(i)}
                                    fontSize="small"
                                  >
                                    <CloseIcon />
                                  </IconButton>

                                  <IconButton
                                    disableRipple
                                    aria-label="Delete"
                                    className={classes.buttonSmall}
                                    onClick={() => ""}
                                    fontSize="small"
                                  >
                                    <DragIndicatorIcon
                                      className={classes.buttonSmall}
                                    />
                                  </IconButton>
                                </ListItemSecondaryAction>
                              </ListItem>
                            </div>
                          )}
                        </Draggable>
                      ))}
                    </List>
                  )}
                </Droppable>
              </div>
            </DragDropContext>
          </form>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              minWidth: "230px",
              marginLeft: "24px",
            }}
          >
            <Typography
              variant="body1"
              color="secondary"
              component="h3"
              style={{
                paddingLeft: 8,
                marginTop: "24px",
                color: "#000",
                fontWeight: "bold",
              }}
            >
              Details
            </Typography>

            <TextField
              id="name"
              label="Name"
              className={classes.textField}
              value={this.state.data.name}
              onChange={this.handleChange}
              margin="normal"
            />
            <TextField
              id="description"
              label="Description"
              className={classes.textField}
              value={this.state.data.description}
              onChange={this.handleChange}
              margin="normal"
            />
            <FormControlLabel
              id="shuffle"
              className={classes.formControlLabel}
              control={
                <Checkbox
                  checked={this.state.data.shuffle}
                  onClick={this.handleClick}
                  value="shuffle"
                />
              }
              label="Enable shuffle?"
            />
            <input
              accept="image/*"
              className={classes.input}
              id="raised-button-file"
              type="file"
              onChange={this.fileUpload}
            />
            <label htmlFor="raised-button-file">
              <Button
                component="span"
                className={classes.button2}
                variant="contained"
                color="primary"
              >
                Upload image
              </Button>
            </label>
            <div className={classes.imgPreview}>{imagePreview}</div>
            <Typography
              color="secondary"
              type="subheading"
              component="h3"
              gutterBottom
              style={{ padding: "8px 0px 0px 8px" }}
            >
              Summary:
            </Typography>
            <Typography
              variant="caption"
              component="p"
              style={{ padding: "0px 0px 0px 8px" }}
            >
              {`Total live tracks: ${sumTracks} songs`}
            </Typography>
            <Typography
              variant="caption"
              component="p"
              gutterBottom
              style={{ padding: "0px 0px 0px 8px" }}
            >
              {`Total size: ${totalSize.toFixed(2)} GB`}
            </Typography>
          </div>
        </div>
        <Divider />
        <div className={classes.horizontalContainer}>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => this.props.handleRequestClose()}
            className={classes.button}
          >
            Cancel
          </Button>
          <Button
            disabled={editSmartList.length < 2 || this.state.data.name === ""}
            variant="contained"
            color="primary"
            onClick={this.handleEdit}
            className={classes.button}
          >
            Save
          </Button>
        </div>
      </Dialog>
    );
  }
}

PlaybackEdit.propTypes = {
  classes: PropTypes.object.isRequired, //eslint-disable-line
  viewer: PropTypes.object.isRequired, //eslint-disable-line
  handleRequestClose: PropTypes.func, //eslint-disable-line
  open: PropTypes.bool, //eslint-disable-line
  data: PropTypes.object, //eslint-disable-line
  myCallBack: PropTypes.func, //eslint-disable-line
  allPlaylists: PropTypes.array, //eslint-disable-line
};

export default withStyles(styles)(PlaybackEdit);
