/**
 * @flow
 * @relayHash 294e52c0c5e2ed50e8869cc7ac49b05a
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type createScheduleProviderData = {|
  name: string,
  description?: ?string,
  injectable: boolean,
  startDate: string,
  endDate: string,
  clientId: string,
  size: number,
  playingPlaylists: $ReadOnlyArray<?PlayingPlaylistProviderData>,
|};
export type PlayingPlaylistProviderData = {|
  playlistId: string,
  name: string,
  volume?: ?string,
  startTime: number,
  endTime: number,
  startTimeSeconds: number,
  endTimeSeconds: number,
  days?: ?$ReadOnlyArray<?boolean>,
  shuffle: boolean,
  filenames?: ?$ReadOnlyArray<?filenamesProviderData>,
|};
export type filenamesProviderData = {|
  nrOfSongs?: ?string,
  id?: ?string,
|};
export type editScheduleMutationVariables = {|
  inputId: string,
  input: createScheduleProviderData,
|};
export type editScheduleMutationResponse = {|
  +editSchedule: ?{|
    +id: string,
    +name: ?string,
    +description: ?string,
    +injectable: ?boolean,
    +startDate: ?string,
    +endDate: ?string,
    +clientId: ?string,
    +size: ?number,
    +playingPlaylists: ?$ReadOnlyArray<?{|
      +playlistId: ?string,
      +name: ?string,
      +volume: ?string,
      +startTime: ?number,
      +endTime: ?number,
      +startTimeSeconds: ?number,
      +endTimeSeconds: ?number,
      +days: ?$ReadOnlyArray<?boolean>,
      +shuffle: ?boolean,
      +filenames: ?$ReadOnlyArray<?{|
        +mixId: ?string,
        +nrOfSongs: ?string,
      |}>,
    |}>,
  |}
|};
export type editScheduleMutation = {|
  variables: editScheduleMutationVariables,
  response: editScheduleMutationResponse,
|};
*/


/*
mutation editScheduleMutation(
  $inputId: String!
  $input: createScheduleProviderData!
) {
  editSchedule(id: $inputId, createScheduleProvider: $input) {
    id
    name
    description
    injectable
    startDate
    endDate
    clientId
    size
    playingPlaylists {
      playlistId
      name
      volume
      startTime
      endTime
      startTimeSeconds
      endTimeSeconds
      days
      shuffle
      filenames {
        mixId
        nrOfSongs
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "inputId"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "createScheduleProvider",
        "variableName": "input"
      },
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "inputId"
      }
    ],
    "concreteType": "Schedule",
    "kind": "LinkedField",
    "name": "editSchedule",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "description",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "injectable",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "startDate",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "endDate",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "size",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "PlayingPlaylist",
        "kind": "LinkedField",
        "name": "playingPlaylists",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "playlistId",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "volume",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "startTime",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "endTime",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "startTimeSeconds",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "endTimeSeconds",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "days",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "shuffle",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Filenames",
            "kind": "LinkedField",
            "name": "filenames",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "mixId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "nrOfSongs",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "editScheduleMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "editScheduleMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "id": "294e52c0c5e2ed50e8869cc7ac49b05a",
    "metadata": {},
    "name": "editScheduleMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e93d10408124a2ab3ee3b9f0bfb25c5b';

module.exports = node;
