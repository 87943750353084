/**
 * @flow
 * @relayHash 52687bb7a978382180de407a1e125e23
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type videoProfileProviderData = {|
  name: string,
  format: string,
  volume?: ?number,
  url?: ?string,
  syncTime?: ?string,
  rebootTime?: ?string,
  clientId?: ?string,
|};
export type editVideoProfileMutationVariables = {|
  inputId: string,
  input: videoProfileProviderData,
|};
export type editVideoProfileMutationResponse = {|
  +editVideoProfile: ?{|
    +id: string,
    +name: string,
    +format: string,
    +volume: ?number,
    +url: ?string,
    +syncTime: ?string,
    +rebootTime: ?string,
    +clientId: ?string,
  |}
|};
export type editVideoProfileMutation = {|
  variables: editVideoProfileMutationVariables,
  response: editVideoProfileMutationResponse,
|};
*/


/*
mutation editVideoProfileMutation(
  $inputId: String!
  $input: videoProfileProviderData!
) {
  editVideoProfile(id: $inputId, videoProfileProvider: $input) {
    id
    name
    format
    volume
    url
    syncTime
    rebootTime
    clientId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "inputId"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "inputId"
      },
      {
        "kind": "Variable",
        "name": "videoProfileProvider",
        "variableName": "input"
      }
    ],
    "concreteType": "VideoProfile",
    "kind": "LinkedField",
    "name": "editVideoProfile",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "format",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "volume",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "url",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "syncTime",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "rebootTime",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "editVideoProfileMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "editVideoProfileMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "id": "52687bb7a978382180de407a1e125e23",
    "metadata": {},
    "name": "editVideoProfileMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f7883d7ba2d7c6dcbd7287d607151b1d';

module.exports = node;
