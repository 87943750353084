/**
 * External dependencies
 */
import React, { useContext, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";

/**
 * Internal dependencies
 */

import createSonosMenuMutation from "../../../mutations/createSonosMenuMutation";
import createQsysMenuMutation from "../../../mutations/createQsysMenuMutation";
import { CurrentClientContext } from "../../hooks/currentClientProvider";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 500,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: "35vh",
    margin: "auto",
    left: 0,
    right: 0,
    borderRadius: 19,
  },
  form: {
    display: "flex",
    flexDirection: "column",
  },
  textField: {
    margin: 8,
  },
  button: {
    margin: 8,
  },
  switch: {
    margin: 8,
  },
}));

export default function CreateMenuModal({ handleClose, extendedMenu }) {
  const { audioProvider } = useContext(CurrentClientContext);
  const classes = useStyles();
  const [nameValue, setNameValue] = useState("");
  const [useExplicit, setUseExplicit] = useState(false);
  const [descriptionValue, setDescriptionValue] = useState("");

  const handleChange = (event, field) => {
    switch (field) {
      case "name":
        setNameValue(event.target.value);
        break;
      case "description":
        setDescriptionValue(event.target.value);
        break;
    }
  };

  const createMenu = () => {
    const parentMenuId = extendedMenu?.id || null;
    const clientId = localStorage.getItem("audioClientId");
    audioProvider === "sonos"
      ? createSonosMenuMutation(
          clientId,
          nameValue,
          descriptionValue,
          parentMenuId,
          useExplicit,
          () => {
            handleClose();
          }
        )
      : createQsysMenuMutation(
          clientId,
          nameValue,
          descriptionValue,
          parentMenuId,
          useExplicit,
          () => {
            handleClose();
          }
        );
  };

  const handleUseExplicit = () => {
    setUseExplicit(!useExplicit);
  };

  return (
    <>
      <div className={classes.paper}>
        <h3 id="edit-menu-modal-title">
          {extendedMenu ? (
            <>Extension for &quot;{extendedMenu.name}&quot;:</>
          ) : (
            "Create basic menu:"
          )}
        </h3>
        <form className={classes.form} noValidate autoComplete="off">
          <TextField
            id="menu-name-edit"
            label="Name"
            onChange={(event) => handleChange(event, "name")}
            value={nameValue}
            className={classes.textField}
            error={nameValue.length < 4}
            helperText="Name must contain 3 or more characters"
            onKeyPress={(event) => event.keyCode === 13 && createMenu()}
          />
          <TextField
            id="menu-description-edit"
            label="Description"
            onChange={(event) => handleChange(event, "description")}
            value={descriptionValue}
            className={classes.textField}
            onKeyPress={(event) => event.keyCode === 13 && createMenu()}
          />
          <FormControlLabel
            id="use-explicit"
            className={classes.formControlLabel}
            style={{ marginLeft: "0px" }}
            control={
              <Checkbox
                checked={useExplicit}
                onClick={handleUseExplicit}
                value="useExplicit"
              />
            }
            label="Explicit"
          />
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            disabled={nameValue.length < 4}
            onClick={createMenu}
          >
            {extendedMenu ? "Create extension" : "Create basic menu"}
          </Button>
        </form>
      </div>
    </>
  );
}
