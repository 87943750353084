/**
 * @flow
 * @relayHash df565221c0b323eea0e07ca9c2b280db
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type _AutoPopulateTypes = "CATEGORY" | "GENRE" | "NONE" | "SUBCATEGORY" | "%future added value";
export type setActiveMenuMutationVariables = {|
  menuId: string,
  clientId: string,
|};
export type setActiveMenuMutationResponse = {|
  +setActiveMenu: ?{|
    +id: string,
    +name: ?string,
    +description: ?string,
    +schema: ?$ReadOnlyArray<?{|
      +name: string,
      +pathId: number,
      +parentId: number,
      +coverName: ?string,
      +cover_40: ?string,
      +cover_290: ?string,
      +cover_legacy: ?string,
      +playlistIds: ?$ReadOnlyArray<?string>,
      +sceneIds: ?$ReadOnlyArray<?string>,
      +autoPopulateType: ?_AutoPopulateTypes,
      +category: ?string,
      +subCategory: ?string,
      +genres: ?$ReadOnlyArray<?string>,
      +routeItems: ?number,
    |}>,
    +active: ?boolean,
    +dateCreated: ?any,
    +dateModified: ?any,
    +clientId: ?string,
  |}
|};
export type setActiveMenuMutation = {|
  variables: setActiveMenuMutationVariables,
  response: setActiveMenuMutationResponse,
|};
*/


/*
mutation setActiveMenuMutation(
  $menuId: String!
  $clientId: String!
) {
  setActiveMenu(menuId: $menuId, clientId: $clientId) {
    id
    name
    description
    schema {
      name
      pathId
      parentId
      coverName
      cover_40
      cover_290
      cover_legacy
      playlistIds
      sceneIds
      autoPopulateType
      category
      subCategory
      genres
      routeItems
    }
    active
    dateCreated
    dateModified
    clientId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "clientId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "menuId"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "clientId",
        "variableName": "clientId"
      },
      {
        "kind": "Variable",
        "name": "menuId",
        "variableName": "menuId"
      }
    ],
    "concreteType": "SonosMenu",
    "kind": "LinkedField",
    "name": "setActiveMenu",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "description",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Route",
        "kind": "LinkedField",
        "name": "schema",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "pathId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "parentId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "coverName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "cover_40",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "cover_290",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "cover_legacy",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "playlistIds",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "sceneIds",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "autoPopulateType",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "category",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "subCategory",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "genres",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "routeItems",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "active",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "dateCreated",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "dateModified",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "setActiveMenuMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "setActiveMenuMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "id": "df565221c0b323eea0e07ca9c2b280db",
    "metadata": {},
    "name": "setActiveMenuMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f79330884898e85eb8a045e46683883c';

module.exports = node;
