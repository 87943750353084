/**
 * @flow
 * @relayHash d95d15d3b2e0ea3ec47f50c628577994
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type sendSonosMenuToDeviceMutationVariables = {|
  userId: string
|};
export type sendSonosMenuToDeviceMutationResponse = {|
  +sendSonosMenuToDevice: {|
    +id: string,
    +sonos: ?{|
      +extensionMenuId: ?string
    |},
  |}
|};
export type sendSonosMenuToDeviceMutation = {|
  variables: sendSonosMenuToDeviceMutationVariables,
  response: sendSonosMenuToDeviceMutationResponse,
|};
*/


/*
mutation sendSonosMenuToDeviceMutation(
  $userId: String!
) {
  sendSonosMenuToDevice(userId: $userId) {
    id
    sonos {
      extensionMenuId
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "userId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "userId",
        "variableName": "userId"
      }
    ],
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "sendSonosMenuToDevice",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "SonosObject",
        "kind": "LinkedField",
        "name": "sonos",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "extensionMenuId",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "sendSonosMenuToDeviceMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "sendSonosMenuToDeviceMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "d95d15d3b2e0ea3ec47f50c628577994",
    "metadata": {},
    "name": "sendSonosMenuToDeviceMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c0b3f347d0130592c4eae2ff3dc8b38c';

module.exports = node;
