import React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";

import { withStyles } from "@material-ui/core/styles";
import compose from "recompose/compose";
import { withSnackbar } from "material-ui-snackbar-provider";

const styles = (theme) => ({
  root: {},
  horizontalContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  verticalContainer: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  button: {
    width: "fit-content",
    margin: "8px 0px 8px 8px",
  },
  typo: {
    width: "fit-content",
    margin: 8,
  },
});

class BatchInstallModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
    this.handleClose = this.handleClose.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state !== nextState) {
      return true;
    }
    return false;
  }

  handleClose() {
    this.setState({ open: false });
  }

  render() {
    const { classes, devicesStats } = this.props;
    return (
      <div className={classes.root}>
        <Typography className={classes.typo} align="left">
          {`Online devices in Control: ${
            devicesStats?.filter((item) => item.Online).length
          }`}
        </Typography>
        <Typography className={classes.typo} align="left">
          {`Online devices in Recovery: ${
            devicesStats?.filter((item) => item.onlineRecovery).length
          }`}
        </Typography>
      </div>
    );
  }
}

BatchInstallModal.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(withStyles(styles), withSnackbar())(BatchInstallModal);
