/**
 * @flow
 * @relayHash 2ed7833e545644ae339b9bf45aeaa43d
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type _AutoPopulateTypes = "CATEGORY" | "GENRE" | "NONE" | "SUBCATEGORY" | "%future added value";
export type _PlayableTypes = "BOTH" | "PLAYLISTS" | "SCENES" | "%future added value";
export type getSonosMenuQueryVariables = {|
  menuId: string
|};
export type getSonosMenuQueryResponse = {|
  +viewer: {|
    +getSonosMenu: ?{|
      +id: string,
      +name: ?string,
      +description: ?string,
      +schema: ?$ReadOnlyArray<?{|
        +name: string,
        +pathId: number,
        +parentId: number,
        +coverName: ?string,
        +cover_40: ?string,
        +cover_290: ?string,
        +cover_legacy: ?string,
        +playlistIds: ?$ReadOnlyArray<?string>,
        +sceneIds: ?$ReadOnlyArray<?string>,
        +mixesArray: ?$ReadOnlyArray<?{|
          +mixId: string,
          +type: string,
        |}>,
        +autoPopulateType: ?_AutoPopulateTypes,
        +category: ?string,
        +subCategory: ?string,
        +genres: ?$ReadOnlyArray<?string>,
        +routeItems: ?number,
        +playableType: ?_PlayableTypes,
      |}>,
      +active: ?boolean,
      +dateCreated: ?any,
      +dateModified: ?any,
      +clientId: ?string,
      +parentMenuId: ?string,
      +explicit: ?boolean,
      +childrenMenus: ?$ReadOnlyArray<?{|
        +id: string,
        +name: ?string,
        +description: ?string,
        +schema: ?$ReadOnlyArray<?{|
          +name: string,
          +pathId: number,
          +parentId: number,
          +coverName: ?string,
          +cover_40: ?string,
          +cover_290: ?string,
          +cover_legacy: ?string,
          +playlistIds: ?$ReadOnlyArray<?string>,
          +sceneIds: ?$ReadOnlyArray<?string>,
          +mixesArray: ?$ReadOnlyArray<?{|
            +mixId: string,
            +type: string,
          |}>,
          +autoPopulateType: ?_AutoPopulateTypes,
          +category: ?string,
          +subCategory: ?string,
          +genres: ?$ReadOnlyArray<?string>,
          +routeItems: ?number,
          +playableType: ?_PlayableTypes,
        |}>,
        +explicit: ?boolean,
        +active: ?boolean,
        +dateCreated: ?any,
        +dateModified: ?any,
        +clientId: ?string,
        +parentMenuId: ?string,
      |}>,
    |}
  |}
|};
export type getSonosMenuQuery = {|
  variables: getSonosMenuQueryVariables,
  response: getSonosMenuQueryResponse,
|};
*/


/*
query getSonosMenuQuery(
  $menuId: String!
) {
  viewer {
    getSonosMenu(menuId: $menuId) {
      id
      name
      description
      schema {
        name
        pathId
        parentId
        coverName
        cover_40
        cover_290
        cover_legacy
        playlistIds
        sceneIds
        mixesArray {
          mixId
          type
        }
        autoPopulateType
        category
        subCategory
        genres
        routeItems
        playableType
      }
      active
      dateCreated
      dateModified
      clientId
      parentMenuId
      explicit
      childrenMenus {
        id
        name
        description
        schema {
          name
          pathId
          parentId
          coverName
          cover_40
          cover_290
          cover_legacy
          playlistIds
          sceneIds
          mixesArray {
            mixId
            type
          }
          autoPopulateType
          category
          subCategory
          genres
          routeItems
          playableType
        }
        explicit
        active
        dateCreated
        dateModified
        clientId
        parentMenuId
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "menuId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "Route",
  "kind": "LinkedField",
  "name": "schema",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "pathId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "parentId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "coverName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cover_40",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cover_290",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cover_legacy",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "playlistIds",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sceneIds",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Mix",
      "kind": "LinkedField",
      "name": "mixesArray",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "mixId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "autoPopulateType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "category",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "subCategory",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "genres",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "routeItems",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "playableType",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "active",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dateCreated",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dateModified",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "clientId",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "parentMenuId",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "explicit",
  "storageKey": null
},
v11 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Viewer",
    "kind": "LinkedField",
    "name": "viewer",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "menuId",
            "variableName": "menuId"
          }
        ],
        "concreteType": "SonosMenu",
        "kind": "LinkedField",
        "name": "getSonosMenu",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "SonosMenu",
            "kind": "LinkedField",
            "name": "childrenMenus",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v10/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "getSonosMenuQuery",
    "selections": (v11/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "getSonosMenuQuery",
    "selections": (v11/*: any*/)
  },
  "params": {
    "id": "2ed7833e545644ae339b9bf45aeaa43d",
    "metadata": {},
    "name": "getSonosMenuQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'eec52ac7094ba1d73b4b6bd98babb767';

module.exports = node;
