import { commitMutation } from "react-relay";
import graphql from 'babel-plugin-relay/macro';
import Environment from "../../environment";

const mutation = graphql`
  mutation deleteDevicePackageMutation(
    $devices: [String]!
    $packageName: String!
    $serverInstance: ServerInstance!
  ) {
    deleteDevicePackage(
      devices: $devices
      packageName: $packageName
      serverInstance: $serverInstance
    ) {
      id
      userId
      clientId
      type
      packageId
      reports {
        deviceid
        state
      }
      date
    }
  }
`;

export default (devices, packageName, serverInstance, callback) => {
  const variables = {
    devices,
    packageName,
    serverInstance,
  };

  commitMutation(Environment, {
    mutation,
    variables,
    onCompleted: (response) => {
      if (response && response.deleteDevicePackage) {
        callback(response.deleteDevicePackage);
      }
    },
    onError: (err) => console.error(err), // eslint-disable-line
  });
};
