/**
 * @flow
 * @relayHash f8e17149fb1753dc513a515667f621b6
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type updateReadStatusMutationVariables = {|
  id: string,
  userId: string,
  checked: boolean,
|};
export type updateReadStatusMutationResponse = {|
  +updateReadStatus: ?{|
    +id: string
  |}
|};
export type updateReadStatusMutation = {|
  variables: updateReadStatusMutationVariables,
  response: updateReadStatusMutationResponse,
|};
*/


/*
mutation updateReadStatusMutation(
  $id: String!
  $userId: String!
  $checked: Boolean!
) {
  updateReadStatus(id: $id, userId: $userId, checked: $checked) {
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "checked"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userId"
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "checked",
        "variableName": "checked"
      },
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "userId",
        "variableName": "userId"
      }
    ],
    "concreteType": "Notification",
    "kind": "LinkedField",
    "name": "updateReadStatus",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "updateReadStatusMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "updateReadStatusMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "id": "f8e17149fb1753dc513a515667f621b6",
    "metadata": {},
    "name": "updateReadStatusMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '91fb7a4b6450b278007882965d75809b';

module.exports = node;
