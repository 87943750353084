/**
 * @flow
 * @relayHash 9a534c9fccec50fa75f8083b65cd0420
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type addAPKtoS3AndDBMutationVariables = {|
  keyName: string,
  appName: string,
  packageName?: ?string,
  description?: ?string,
  versionCode?: ?number,
  versionName?: ?string,
  icon?: ?string,
|};
export type addAPKtoS3AndDBMutationResponse = {|
  +addAPKtoS3AndDB: ?string
|};
export type addAPKtoS3AndDBMutation = {|
  variables: addAPKtoS3AndDBMutationVariables,
  response: addAPKtoS3AndDBMutationResponse,
|};
*/


/*
mutation addAPKtoS3AndDBMutation(
  $keyName: String!
  $appName: String!
  $packageName: String
  $description: String
  $versionCode: Int
  $versionName: String
  $icon: String
) {
  addAPKtoS3AndDB(keyName: $keyName, appName: $appName, packageName: $packageName, description: $description, versionCode: $versionCode, versionName: $versionName, icon: $icon)
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "appName"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "description"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "icon"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "keyName"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "packageName"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "versionCode"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "versionName"
},
v7 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "appName",
        "variableName": "appName"
      },
      {
        "kind": "Variable",
        "name": "description",
        "variableName": "description"
      },
      {
        "kind": "Variable",
        "name": "icon",
        "variableName": "icon"
      },
      {
        "kind": "Variable",
        "name": "keyName",
        "variableName": "keyName"
      },
      {
        "kind": "Variable",
        "name": "packageName",
        "variableName": "packageName"
      },
      {
        "kind": "Variable",
        "name": "versionCode",
        "variableName": "versionCode"
      },
      {
        "kind": "Variable",
        "name": "versionName",
        "variableName": "versionName"
      }
    ],
    "kind": "ScalarField",
    "name": "addAPKtoS3AndDB",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "addAPKtoS3AndDBMutation",
    "selections": (v7/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v0/*: any*/),
      (v4/*: any*/),
      (v1/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "addAPKtoS3AndDBMutation",
    "selections": (v7/*: any*/)
  },
  "params": {
    "id": "9a534c9fccec50fa75f8083b65cd0420",
    "metadata": {},
    "name": "addAPKtoS3AndDBMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '62528bb2dd2e8ced7532b8c62eb3e2cd';

module.exports = node;
