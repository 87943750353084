import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import compose from "recompose/compose";
import { withSnackbar } from "material-ui-snackbar-provider";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Input from "@material-ui/core/Input";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import SyncIcon from "@material-ui/icons/Sync";
import DeleteIcon from "@material-ui/icons/Delete";
import ClearIcon from "@material-ui/icons/Clear";
import Tooltip from "@material-ui/core/Tooltip";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "@material-ui/core/Button";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import editUsernameMutation from "../../mutations/editUsernameMutation";
import generateAPIKEYMutation from "../../mutations/generateAPIKEYMutation";
import removeAPIKEYMutation from "../../mutations/removeAPIKEYMutation";
import generateQsysPinMutation from "../../mutations/generateQsysPinMutation";
import removeQsysPinMutation from "../../mutations/removeQsysPinMutation";
import toggleSonosMutation from "../../mutations/toggleSonosMutation";
import assignSonosMenuToUserMutation from "../../mutations/assignSonosMenuToUserMutation";
import sendSonosMenuToDeviceMutation from "../../mutations/sendSonosMenuToDeviceMutation";
import permissions from "../../authentication/isPermited";
import toggleQsysMutation from "../../mutations/toggleQsysMutation";
import toggleQsysMultiTenantMutation from "../../mutations/toggleQsysMultiTenantMutation";
import InfoIcon from "@material-ui/icons/Info";
import updateQsysGlobalZoneLimitMutation from "../../mutations/updateQsysGlobalZoneLimitMutation";
import toggleHideSonosVolumeBarMutation from "../../mutations/toggleHideSonosVolumeBarMutation";
import toggleQsysHideVolumeBarMutation from "../../mutations/toggleHideQsysVolumeBarMutation";
import UserQsysDevices from "./UserQsysDevices";

const styles = (theme) => ({
  root: {
    margin: "4px 0px 4px 0px",
    paddingBottom: 12,
  },
  card: {
    display: "flex",
    flexDirection: "column",
  },
  button: {
    margin: 12,
  },
  horizontalContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  horizontalContainer2: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  verticalContainer: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    margin: 4,
  },
  typo1: {
    paddingBottom: 4,
    display: "flex",
    alignItems: "center",
  },
  typo2: {
    paddingLeft: 0,
  },
  typo3: {
    paddingLeft: 0,
    flex: "auto",
  },
  typo4: {
    paddingRight: 16,
  },
  list: {
    flex: "auto",
    marginRight: 8,
    padding: 0,
  },
  menuItem: {
    fontSize: "0.85rem",
    boxSizing: "border-box",
  },
  input: {
    margin: "0px 0px 8px 8px",
    padding: "0px",
    width: 150,
  },
  margin: {
    margin: theme.spacing(1),
  },
});

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

class UserDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: this.props.user.profile.name,
      sendMenuButtonDisabled: false,
      extensionMenuId: this.props.user?.sonos?.extensionMenuId,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSonos = this.handleSonos.bind(this);
    this.handleQsys = this.handleQsys.bind(this);
    this.changeUsername = this.changeUsername.bind(this);
    this.handleChangeExtensionMenu = this.handleChangeExtensionMenu.bind(this);
    this.handleSendMenuToDevice = this.handleSendMenuToDevice.bind(this);
    this.handleQsysMultiTenant = this.handleQsysMultiTenant.bind(this);
    this.handleGlobalZoneLimitChange =
      this.handleGlobalZoneLimitChange.bind(this);
    this.handleHideSonosVolumeBar = this.handleHideSonosVolumeBar.bind(this);
    this.handleQsysHideVolumeBar = this.handleQsysHideVolumeBar.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.user.profile.name !== nextProps.user.profile.name) {
      this.setState({ username: nextProps.user.profile.name });
    }
    if (
      this.props.user?.sonos?.extensionMenuId !==
      nextProps.user.sonos?.extensionMenuId
    ) {
      this.setState({ extensionMenuId: nextProps.user.sonos?.extensionMenuId });
    }
  }

  changeUsername() {
    editUsernameMutation(this.props.user.id, this.state.username, () =>
      this.props.snackbar.showMessage("Username updated.")
    );
  }

  handleChange(name, event) {
    this.setState({
      [name]: event.target.value,
    });
  }

  handleSonos() {
    const { user } = this.props;
    toggleSonosMutation(user.id, !user?.profile?.sonosPermit, () =>
      this.props.snackbar.showMessage("Sonos permit updated")
    );
  }

  handleQsys() {
    const { user } = this.props;
    toggleQsysMutation(user.id, !user?.profile?.qsysPermit, () =>
      this.props.snackbar.showMessage("Qsys permit updated")
    );
  }

  handleChangeExtensionMenu(event) {
    const { user } = this.props;
    this.setState({
      extensionMenuId: event.target.value,
    });
    assignSonosMenuToUserMutation(user.id, event.target.value, () =>
      this.props.snackbar.showMessage("Sonos extension menu updated")
    );
  }

  handleSendMenuToDevice(event) {
    const { user } = this.props;
    this.setState({
      sendMenuButtonDisabled: true,
    });
    sendSonosMenuToDeviceMutation(user.id, () => {
      this.setState({
        sendMenuButtonDisabled: false,
      });
      return this.props.snackbar.showMessage("Sonos menu sent to device");
    });
  }

  handleQsysMultiTenant(event) {
    const { user } = this.props;
    const newValue = event.target.checked;

    toggleQsysMultiTenantMutation(user.id, newValue, () =>
      this.props.snackbar.showMessage("Qsys multi-tenant updated")
    );
  }

  handleGlobalZoneLimitChange = (event) => {
    const { user } = this.props;
    const value = Math.min(Math.max(0, parseInt(event.target.value) || 0), 128);

    updateQsysGlobalZoneLimitMutation(user.id, value, () => {
      this.props.snackbar.showMessage("Global zone limit updated");
      // Force a re-render
      this.forceUpdate();
    });
  };

  handleHideSonosVolumeBar = (event) => {
    const { user } = this.props;
    const newValue = event.target.checked;

    toggleHideSonosVolumeBarMutation(user.id, newValue, () =>
      this.props.snackbar.showMessage("Sonos volume bar hidden")
    );
    };

  handleQsysHideVolumeBar = (event) => {
    const { user } = this.props;
    const newValue = event.target.checked;

    toggleQsysHideVolumeBarMutation(user.id, newValue, () =>
      this.props.snackbar.showMessage("Qsys volume bar hidden")
    );
  };

  render() {
    const { classes, user, snackbar, allSonosMenu } = this.props;
    const { username, extensionMenuId, sendMenuButtonDisabled } = this.state;
    const menusByClient =
      allSonosMenu?.filter((item) => item.clientId === user.profile.clientId) ||
      [];
    let menuList = [];
    for (let i = 0; i < menusByClient.length; i++) {
      if (menusByClient[i].childrenMenus.length > 0) {
        menusByClient[i].childrenMenus.forEach((item) => {
          const newItem = Object.assign({}, item);
          newItem.basicMenuName = menusByClient[i].name;
          menuList.push(newItem);
        });
      }
    }

    console.log(user);

    return (
      <div className={classes.root}>
        <Card className={classes.card}>
          <CardContent>
            <Typography className={classes.typo1} variant="h6">
              User Details
            </Typography>
            <Divider />
            <Typography
              component={"div"}
              className={classes.typo2}
              variant="body2"
              color="textSecondary"
              style={{
                paddingLeft: 4,
                display: "flex",
                alignItems: "center",
              }}
            >
              <b>Name </b>
              <Input
                value={username}
                className={classes.input}
                onChange={(event) => this.handleChange("username", event)}
                margin="dense"
                inputProps={{
                  "aria-label": "Description",
                }}
              />
              <IconButton
                color="primary"
                disabled={user.profile.name === username}
                // className={classes.button}
                onClick={this.changeUsername}
              >
                <EditIcon />
              </IconButton>
            </Typography>

            <Typography
              className={classes.typo2}
              variant="body2"
              color="textSecondary"
              style={{ paddingLeft: 4 }}
            >
              <b>Last seen online </b>
              {user.status.lastLogin && user.status.lastLogin.date}
            </Typography>
            <Typography
              className={classes.typo2}
              variant="body2"
              color="textSecondary"
              style={{ paddingLeft: 4 }}
            >
              <b>Created At </b>
              {Number(user?.createdAt)
                ? new Date(Number(user?.createdAt))?.toISOString()
                : "Undefined"}
            </Typography>
            <div className={classes.horizontalContainer}>
              <div className={classes.verticalContainer}>
                <Typography
                  className={classes.typo2}
                  variant="body2"
                  color="textSecondary"
                >
                  <b>Email </b>
                  {user.emails[0].address}
                </Typography>
              </div>
              <div className={classes.verticalContainer}>
                <Typography
                  className={classes.typo2}
                  variant="body2"
                  color="textSecondary"
                >
                  <b>Verified? </b>
                  {user.emails[0].verified ? "Yes" : "No"}
                </Typography>
              </div>
            </div>
            <Divider />
            <div
              className={classes.horizontalContainer}
              style={{ flex: "auto" }}
            >
              <Typography
                className={classes.typo2}
                variant="body2"
                color="textSecondary"
                style={{ paddingLeft: 4 }}
              >
                <b>{"Logins used:\u00A0\u00A0"}</b>
              </Typography>
              <Typography
                className={classes.typo3}
                variant="body2"
                color="textSecondary"
              >
                <b>Email </b>
                {user.services.password ? "Yes" : "No"}
              </Typography>
              <Typography
                className={classes.typo3}
                variant="body2"
                color="textSecondary"
              >
                <b>Facebook </b>
                {user.services.facebook ? "Yes" : "No"}
              </Typography>
              <Typography
                className={classes.typo3}
                variant="body2"
                color="textSecondary"
              >
                <b>Google </b>
                {user.services.google ? "Yes" : "No"}
              </Typography>
            </div>
            <Divider />
            <div className={classes.horizontalContainer2}>
              <Typography
                className={classes.typo2}
                variant="body2"
                color="textSecondary"
              >
                <b>API Key: </b>
                {user && user.apiKey ? user.apiKey : "None"}
              </Typography>
              <Tooltip
                title="Generate new API Key"
                placement="bottom"
                enterTouchDelay={200}
                onClick={() =>
                  generateAPIKEYMutation(user.id, () =>
                    snackbar.showMessage("New API Key generated")
                  )
                }
              >
                <IconButton
                  aria-label="generateNew"
                  className={classes.margin}
                  size="small"
                >
                  <SyncIcon fontSize="inherit" />
                </IconButton>
              </Tooltip>
              <Tooltip
                title="Remove API Key"
                placement="bottom"
                enterTouchDelay={200}
                onClick={() =>
                  removeAPIKEYMutation(user.id, () =>
                    snackbar.showMessage("API Key removed")
                  )
                }
              >
                <IconButton
                  aria-label="generateNew"
                  className={classes.margin}
                  size="small"
                >
                  <DeleteIcon fontSize="inherit" />
                </IconButton>
              </Tooltip>
            </div>
            <Divider />
            <div className={classes.horizontalContainer2}>
              <FormControlLabel
                control={
                  <Switch
                    checked={user?.profile?.qsysPermit}
                    onChange={this.handleQsys}
                    disabled={!permissions([1])}
                    name="checkedC"
                    color="primary"
                  />
                }
                label="Toggle QSYS"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={user?.qsys?.hideVolumeBar}
                    onChange={this.handleQsysHideVolumeBar} 
                    disabled={!permissions([1])}
                    name="checkedC"
                    color="primary"
                  />
                }
                label="Hide QSYS Volume Bar"
              />  
              <Typography
                className={classes.typo2}
                variant="body2"
                color="textSecondary"
              >
                <b>QSYS PIN: </b>
                {user?.qsys?.pin ? user?.qsys?.pin : "None"}
              </Typography>
              <Tooltip
                title="Generate new Q-SYS PIN"
                placement="bottom"
                enterTouchDelay={200}
                onClick={() =>
                  generateQsysPinMutation(user.id, () =>
                    snackbar.showMessage("New PIN generated")
                  )
                }
              >
                <IconButton
                  aria-label="generateNew"
                  className={classes.margin}
                  size="small"
                >
                  <SyncIcon fontSize="inherit" />
                </IconButton>
              </Tooltip>
              <Tooltip
                title="Remove PIN"
                placement="bottom"
                enterTouchDelay={200}
                onClick={() =>
                  removeQsysPinMutation(user.id, () =>
                    snackbar.showMessage("PIN removed")
                  )
                }
              >
                <IconButton
                  aria-label="generateNew"
                  className={classes.margin}
                  size="small"
                >
                  <DeleteIcon fontSize="inherit" />
                </IconButton>
              </Tooltip>
            </div>
            {user?.profile?.qsysPermit && (
              <div
                className={classes.horizontalContainer2}
                style={{ marginBottom: 10 }}
              >
                <Tooltip
                  title="Total number of active zones held by user in all Q-SYS cores"
                  placement="bottom"
                >
                  <Typography
                    className={classes.typo2}
                    variant="body2"
                    color="textSecondary"
                    style={{ paddingRight: 16 }}
                  >
                    <b>Total Q-SYS active cores: </b>
                    {user?.qsysData?.noOfCores || 0}
                  </Typography>
                </Tooltip>
                <Tooltip
                  title="Total number of active zones held by user in all Q-SYS zones"
                  placement="bottom"
                >
                  <Typography
                    className={classes.typo2}
                    variant="body2"
                    color="textSecondary"
                  >
                    <b>Total Q-SYS active zones: </b>
                    {user?.qsysData?.noOfZones || 0}
                  </Typography>
                </Tooltip>
              </div>
            )}
            <Divider style={{ marginBottom: 10 }} />
            <div
              className={classes.horizontalContainer2}
              style={{ marginBottom: 15 }}
            >
              <FormControlLabel
                control={
                  <Switch
                    checked={user?.qsys?.multiTenant}
                    onChange={this.handleQsysMultiTenant}
                    disabled={!permissions([1]) || !user?.profile?.qsysPermit}
                    name="checkedC"
                    color="primary"
                  />
                }
                label="Multi-tenant"
              />
              <Tooltip
                title="By checking this you will enable the user to control multiple Q-SYS devices."
                placement="bottom"
              >
                <IconButton size="small" style={{ marginLeft: 8, padding: 4 }}>
                  <InfoIcon fontSize="small" />
                </IconButton>
              </Tooltip>
              {user?.qsys?.multiTenant && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: 24,
                  }}
                >
                  <TextField
                    label="Global Zone Limit"
                    type="number"
                    value={user?.qsys?.globalZoneLimit || 0}
                    onChange={this.handleGlobalZoneLimitChange}
                    disabled={!permissions([1]) || !user?.profile?.qsysPermit}
                    InputProps={{
                      inputProps: {
                        min: 0,
                        max: 128,
                      },
                    }}
                    style={{
                      width: 130,
                      marginRight: 8,
                    }}
                    size="small"
                  />
                  <Tooltip
                    title="Maximum number of zones this user can control across all cores"
                    placement="bottom"
                  >
                    <IconButton size="small" style={{ padding: 4 }}>
                      <InfoIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </div>
              )}
            </div>
            {user?.qsys?.multiTenant && (
              <>
                <UserQsysDevices
                  qsysDeviceIds={user?.qsysData?.qsysDeviceIds}
                  userId={user.id}
                  user={user}
                />
                <Divider style={{ marginTop: 10 }} />
              </>
            )}
            {permissions([1]) && (
              <div className={classes.horizontalContainer2}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={user?.profile?.sonosPermit}
                      onChange={this.handleSonos}
                      name="checkedB"
                      color="primary"
                    />
                  }
                  label="Toggle Sonos"
                />
                {user?.profile?.sonosPermit && (
                  <FormControlLabel
                    control={
                      <Switch
                        checked={user?.sonos?.hideVolumeBar}
                        onChange={this.handleHideSonosVolumeBar}
                        name="checkedC"
                        color="primary"
                    />
                  }
                    label="Hide Sonos Volume Bar"
                  />
                )}

                <Divider orientation="vertical" flexItem />
                <TextField
                  labelId="select-populate-type"
                  style={{ minWidth: 250, marginTop: -8, marginLeft: 24 }}
                  label="Select menu extension"
                  value={extensionMenuId}
                  select
                  onChange={this.handleChangeExtensionMenu}
                  disabled={menuList.length === 0}
                  helperText={
                    menuList.length === 0
                      ? "No menu extensions available"
                      : null
                  }
                >
                  {extensionMenuId && (
                    <StyledMenuItem value={null}>
                      <ListItemIcon>
                        <ClearIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary="Clear menu extension" />
                    </StyledMenuItem>
                  )}
                  {menuList.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      <b>{item.basicMenuName}</b>
                      &nbsp;{"-"}&nbsp;
                      {item.name}
                    </MenuItem>
                  ))}
                </TextField>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.handleSendMenuToDevice}
                  className={classes.button}
                  disabled={sendMenuButtonDisabled}
                >
                  Send menu to device
                </Button>
              </div>
            )}
            <Divider />
          </CardContent>
        </Card>
      </div>
    );
  }
}

UserDetails.defaultProps = {};

UserDetails.propTypes = {
  classes: PropTypes.object.isRequired, //eslint-disable-line
  user: PropTypes.object.isRequired, //eslint-disable-line
};

export default compose(withStyles(styles), withSnackbar())(UserDetails);
