/* eslint-disable react/prop-types */
import React, { Component } from "react";
import { withRouter } from "found";
import PropTypes from "prop-types";
import moment from "moment";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import { Doughnut } from "react-chartjs-2";

const styles = (theme) => ({
  card: {
    display: "flex",
    flexDirection: "column",
    background: theme.palette.background.paper,
    margin: 4,
  },
  horizontalContainer: {
    padding: 4,
    display: "flex",
    flexWrap: "wrap",
  },
  verticalContainer: {
    padding: 4,
    display: "flex",
    flexDirection: "column",
  },
});

class AudioDevicesDoughnut extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    const { classes, viewer } = this.props;
    const onlineDevices = viewer.allAudioDevices.edges
      .filter((item) => item && item.node)
      .filter((item) => item.node.deviceStatus === "ACTIVE")
      .filter((item) => {
        const lastOnline = moment(item.node.lastOnline);
        const isOffline = lastOnline.isBefore(
          moment().subtract("90", "second")
        );
        return item.node.lastOnline ? !isOffline : false;
      }).length;
    const offlineDevices = viewer.allAudioDevices.edges
      .filter((item) => item && item.node)
      .filter((item) => item.node.deviceStatus === "ACTIVE")
      .filter((item) => {
        const lastOnline = moment(item.node.lastOnline);
        const goLiveDate = item.node.goLiveDate
          ? moment(item.node.goLiveDate)
          : moment();
        const neverOnline = item.node.goLiveDate
          ? lastOnline.isBefore(goLiveDate)
          : false;
        const isOffline = lastOnline.isBefore(
          moment().subtract("90", "second")
        );
        return item.node.lastOnline ? isOffline && !neverOnline : true;
      }).length;
    const devicesNeverOnline = viewer.allAudioDevices.edges
      .filter((item) => item && item.node)
      .filter((item) => item.node.deviceStatus === "ACTIVE")
      .filter((item) => {
        const lastOnline = moment(item.node.lastOnline);
        const goLiveDate = moment(item.node.goLiveDate);
        const neverOnline = lastOnline.isBefore(goLiveDate);
        return item.node.goLiveDate ? neverOnline : false;
      }).length;
    const doughnut = {
      labels: ["Online", "Offline", "Never Online"],
      datasets: [
        {
          data: [onlineDevices, offlineDevices, devicesNeverOnline],
          backgroundColor: ["#00A86B", "#ff6666", "#a6a6a6"],
          hoverBackgroundColor: ["#50C878", "#ff9999", "#d8d8d8"],
        },
      ],
    };
    return (
      <Card className={classes.card} raised>
        <CardHeader
          title="Connection Status"
          subheader="Connectivity status for all enrolled devices"
        />
        <CardContent className={classes.horizontalContainer}>
          <Doughnut data={doughnut} width={320} height={320} />
        </CardContent>
      </Card>
    );
  }
}

AudioDevicesDoughnut.propTypes = {
  classes: PropTypes.object.isRequired, //eslint-disable-line
  viewer: PropTypes.object.isRequired, // eslint-disable-line
};

export default withRouter(withStyles(styles)(AudioDevicesDoughnut));
