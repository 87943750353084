/**
 * External dependencies
 */
import React, { useState, useEffect, useContext } from "react";
import { withStyles } from "@material-ui/core/styles";
import { TreeView } from "@material-ui/lab";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  ArrowDropDown,
  ArrowRight,
  Folder,
  FolderOpen,
} from "@material-ui/icons";
import schemaReorderMutation from "../../../mutations/schemaReorderMutation";
import editGenreSonosRouteFolderOrderMutation from "../../../mutations/editGenreSonosRouteFolderOrderMutation";
import editNoneSonosRoutePlayablesOrderMutation from "../../../mutations/editNoneSonosRoutePlayablesOrderMutation";

/**
 * Internal dependencies
 */
import StyledTreeItem from "./StyledTreeItem";
import RenderPlayables from "./RenderPlayables";
import editGenreQsysRouteFolderOrderMutation from "../../../mutations/editGenreQsysRouteFolderOrderMutation";
import editNoneQsysRoutePlayablesOrderMutation from "../../../mutations/editNoneQsysRoutePlayablesOrderMutation";
import schemaQsysReorderMutation from "../../../mutations/schemaQsysReorderMutation";
import { CurrentClientContext } from "../../hooks/currentClientProvider";

const styles = () => {};

const renderTree = (list = [], disabled, menuId, droppableId) =>
  list.map((item) => {
    const children =
      item?.children?.length > 0
        ? renderTree(
            item.children,
            disabled,
            menuId,
            `${droppableId}_${item.pathId}`
          )
        : null;
    let labelInfo = "";
    if (item?.routeItems) {
      labelInfo = `(${item.routeItems})`;
    } else if (item?.autoPopulateType === "NONE") {
      labelInfo = item?.mixesArray
        ? `(${item.mixesArray?.length || 0})`
        : `(${(item.playlistIds?.length || 0) + (item.sceneIds?.length || 0)})`;
    }
    return (
      <Draggable
        key={item.pathId}
        draggableId={item.pathId.toString()}
        index={item.orderRank}
        isDragDisabled={disabled}
      >
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <StyledTreeItem
              key={item.pathId}
              nodeId={`${item.pathId}`}
              labelText={item.name}
              labelInfo={labelInfo}
              labelicon={disabled ? Folder : FolderOpen}
              color={disabled ? "#8f8f8f" : "#5c5c5c"}
              route={item}
              disabled={disabled}
            >
              {children && (
                <Droppable
                  droppableId={`${droppableId}_${item.pathId}`}
                  type={`droppable_${item.pathId}`}
                >
                  {(provided) => (
                    <div ref={provided.innerRef}>
                      {children}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              )}
              <RenderPlayables
                route={item}
                menuId={menuId}
                disabled={disabled}
                path={`${droppableId}_${item.pathId}`}
              />
            </StyledTreeItem>
          </div>
        )}
      </Draggable>
    );
  });

const TreeList = ({ classes, folderList, disabled, menuId }) => {
  const { audioProvider } = useContext(CurrentClientContext);
  const [folderListOrdered, setFolderListOrdered] = useState(folderList);
  useEffect(() => {
    setFolderListOrdered(folderList);
  }, [folderList]);

  const arrayMove = (arr, fromIndex, toIndex) => {
    const element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
  };

  const update = (objectParam, newValue, path) => {
    let object = objectParam;
    while (path.length > 1) {
      object = object[path.shift()];
    }
    object[path.shift()] = newValue;
  };

  const reorder = (fullPath, source, destination, newFolderList) => {
    const indexArray = [];
    let childrenArray = [...folderListOrdered];
    for (let i = 1; i < fullPath.length; i++) {
      indexArray[i - 1] = childrenArray.findIndex(
        (item) => item.pathId === fullPath[i]
      );
      if (i < fullPath.length) {
        childrenArray = childrenArray[indexArray[i - 1]]?.children;
      }
    }
    const from = childrenArray.findIndex((item) => item.orderRank === source);
    const to = childrenArray.findIndex(
      (item) => item.orderRank === destination
    );
    arrayMove(childrenArray, from, to);

    if (indexArray.length > 0) {
      const path = [];
      for (let i = 0; i < indexArray.length; i++) {
        path.push(indexArray[i]);
        path.push("children");
      }
      update(newFolderList, childrenArray, path);
    }
  };
  const reorderGenre = (fullPath, source, destination, newFolderList) => {
    const indexArray = [];
    let genreArray = [...folderListOrdered];
    for (let i = 1; i < fullPath.length; i++) {
      indexArray[i - 1] = genreArray.findIndex(
        (item) => item.pathId === fullPath[i]
      );
      if (i < fullPath.length - 1) {
        genreArray = genreArray[indexArray[i - 1]]?.children;
      }
    }
    genreArray = [...genreArray[indexArray[indexArray.length - 1]]?.genres];
    arrayMove(genreArray, source, destination);

    if (indexArray.length > 0) {
      const path = [];
      for (let i = 0; i < indexArray.length; i++) {
        path.push(indexArray[i]);
        if (i < indexArray.length - 1) {
          path.push("children");
        } else {
          path.push("genres");
        }
      }
      update(newFolderList, genreArray, path);
      setFolderListOrdered(newFolderList.filter((item) => item));
    }
  };

  const reorderPlayables = (fullPath, source, destination, newFolderList) => {
    const indexArray = [];
    let mixesArray = [...folderListOrdered];
    for (let i = 1; i < fullPath.length; i++) {
      indexArray[i - 1] = mixesArray.findIndex(
        (item) => item.pathId === fullPath[i]
      );
      if (i < fullPath.length - 1) {
        mixesArray = mixesArray[indexArray[i - 1]]?.children;
      }
    }
    mixesArray = [...mixesArray[indexArray[indexArray.length - 1]]?.mixesArray];
    arrayMove(mixesArray, source, destination);

    if (indexArray.length > 0) {
      const path = [];
      for (let i = 0; i < indexArray.length; i++) {
        path.push(indexArray[i]);
        if (i < indexArray.length - 1) {
          path.push("children");
        } else {
          path.push("mixesArray");
        }
      }
      update(newFolderList, mixesArray, path);
      setFolderListOrdered(newFolderList.filter((item) => item));
    }
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const source = result?.source?.index;
    const destination = result?.destination?.index;
    const newFolderList = [...folderListOrdered];

    if (result?.type === "root") {
      const fromIndex = newFolderList.findIndex(
        (item) => item.orderRank === source
      );
      const toIndex = newFolderList.findIndex(
        (item) => item.orderRank === destination
      );
      arrayMove(newFolderList, fromIndex, toIndex);
    }

    if (result?.destination?.droppableId) {
      const fullPath = result.destination.droppableId
        .split("_")
        .map((item) => Number(item));
      if (result?.type?.startsWith("GENRE")) {
        reorderGenre(fullPath, source, destination, newFolderList);
        audioProvider === "sonos"
          ? editGenreSonosRouteFolderOrderMutation(
              menuId,
              fullPath[fullPath.length - 1],
              source,
              destination,
              () => console.log("done")
            )
          : editGenreQsysRouteFolderOrderMutation(
              menuId,
              fullPath[fullPath.length - 1],
              source,
              destination,
              () => console.log("done")
            );
        return;
      }
      if (result?.type?.startsWith("PLAYABLES")) {
        reorderPlayables(fullPath, source, destination, newFolderList);
        audioProvider === "sonos"
          ? editNoneSonosRoutePlayablesOrderMutation(
              menuId,
              fullPath[fullPath.length - 1],
              source,
              destination,
              () => console.log("done")
            )
          : editNoneQsysRoutePlayablesOrderMutation(
              menuId,
              fullPath[fullPath.length - 1],
              source,
              destination,
              () => console.log("done")
            );
        return;
      }
      reorder(fullPath, source, destination, newFolderList);
    }
    setFolderListOrdered(newFolderList.filter((item) => item));
    audioProvider === "sonos"
      ? schemaReorderMutation(menuId, source, destination, () => {
          console.log("done");
        })
      : schemaQsysReorderMutation(menuId, source, destination, () => {
          console.log("done");
        });
  };
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <TreeView
        className={classes.root}
        defaultExpanded={[]}
        defaultCollapseIcon={<ArrowDropDown />}
        defaultExpandIcon={<ArrowRight />}
        defaultEndIcon={<div style={{ width: 24 }} />}
        disableSelection={disabled}
        {...(disabled && { selected: "", defaultSelected: "" })}
      >
        <Droppable droppableId="0" type="root">
          {(provided) => (
            <div ref={provided.innerRef}>
              {folderListOrdered &&
                renderTree(folderListOrdered, disabled, menuId, "0")}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </TreeView>
    </DragDropContext>
  );
};

export default withStyles(styles)(TreeList);
