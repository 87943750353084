/**
 * @flow
 * @relayHash 84fa6a0d48c166a998ea64458a89eeb6
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type updateAudioDeviceProviderData = {|
  remoteVolume?: ?number,
  currentPlaylistId?: ?string,
  currentSceneId?: ?string,
  scheduleIds?: ?$ReadOnlyArray<?string>,
  remoteVolumeMode?: ?boolean,
  remotePlaylistMode?: ?boolean,
|};
export type updateAudioDeviceMutationVariables = {|
  inputid: string,
  updateAudioDeviceProvider: updateAudioDeviceProviderData,
|};
export type updateAudioDeviceMutationResponse = {|
  +updateAudioDevice: ?{|
    +id: string,
    +remoteVolume: ?number,
    +currentPlaylistId: ?string,
    +currentSceneId: ?string,
    +scheduleIds: ?$ReadOnlyArray<?string>,
    +remoteVolumeMode: ?boolean,
    +remotePlaylistMode: ?boolean,
  |}
|};
export type updateAudioDeviceMutation = {|
  variables: updateAudioDeviceMutationVariables,
  response: updateAudioDeviceMutationResponse,
|};
*/


/*
mutation updateAudioDeviceMutation(
  $inputid: String!
  $updateAudioDeviceProvider: updateAudioDeviceProviderData!
) {
  updateAudioDevice(id: $inputid, updateAudioDeviceProvider: $updateAudioDeviceProvider) {
    id
    remoteVolume
    currentPlaylistId
    currentSceneId
    scheduleIds
    remoteVolumeMode
    remotePlaylistMode
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "inputid"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "updateAudioDeviceProvider"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "inputid"
      },
      {
        "kind": "Variable",
        "name": "updateAudioDeviceProvider",
        "variableName": "updateAudioDeviceProvider"
      }
    ],
    "concreteType": "AudioDevice",
    "kind": "LinkedField",
    "name": "updateAudioDevice",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "remoteVolume",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "currentPlaylistId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "currentSceneId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "scheduleIds",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "remoteVolumeMode",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "remotePlaylistMode",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "updateAudioDeviceMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "updateAudioDeviceMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "84fa6a0d48c166a998ea64458a89eeb6",
    "metadata": {},
    "name": "updateAudioDeviceMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c39361e15fbdd273546b9fa8d03fa657';

module.exports = node;
