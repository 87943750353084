import React from "react";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import { SnackbarProvider } from "material-ui-snackbar-provider";
import "./media/index.css";
import { BrowserRouter } from "./client/routes";
import ScrollUpButton from "react-scroll-up-button"; //Add this line here
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import "./fonts/Tondo_Rg.ttf";
import packageInfo from "../package.json";

const theme = createTheme({
  typography: {
    useNextVariants: true,
    fontFamily: "Tondo, sans-serif, -apple-system, system-ui",
  },

  palette: {
    type: "light",
    primary: {
      main: "#3BABA1",
    },
    secondary: {
      main: "#65c8d0",
    },
    error: {
      main: "#f44336",
    },
    background: {
      main: "#19555b",
      image: "/media/10.jpg",
    },
  },
  overrides: {
    MuiSwitch: {
      track: {
        opacity: 1,
        backgroundColor: "#c4c4c4",
      },
    },
  },
});

const production = process.env.NODE_ENV === "production";

Bugsnag.start({
  apiKey: "c858bfaec29dc8a2c134dad33847768a",
  plugins: [new BugsnagPluginReact()],
  enabledReleaseStages: ["production"],
  appVersion: packageInfo.version,
});

const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);

const BugsnagWrapper = ({ children }) =>
  production ? <ErrorBoundary>{children}</ErrorBoundary> : <>{children}</>;

const App = () => (
  <BugsnagWrapper>
    <MuiThemeProvider theme={theme}>
      <SnackbarProvider
        snackbarProps={{
          autoHideDuration: 5000,
          anchorOrigin: { vertical: "top", horizontal: "right" },
        }}
      >
        <ScrollUpButton />
        <BrowserRouter />
      </SnackbarProvider>
    </MuiThemeProvider>
  </BugsnagWrapper>
);

export default App;
