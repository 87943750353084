/**
 * @flow
 * @relayHash f66547a6ad2032aae6af54da601ea3a9
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type WebkeyAppManagementRefetchQueryVariables = {||};
export type WebkeyAppManagementRefetchQueryResponse = {|
  +viewer: {|
    +allWebkeyPackages: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: {|
          +id: string,
          +appName: string,
          +keyName: string,
          +source: string,
          +packageName: ?string,
          +description: ?string,
          +versionCode: ?number,
          +versionName: ?string,
          +icon: ?string,
        |}
      |}>
    |}
  |}
|};
export type WebkeyAppManagementRefetchQuery = {|
  variables: WebkeyAppManagementRefetchQueryVariables,
  response: WebkeyAppManagementRefetchQueryResponse,
|};
*/


/*
query WebkeyAppManagementRefetchQuery {
  viewer {
    allWebkeyPackages {
      edges {
        node {
          id
          appName
          keyName
          source
          packageName
          description
          versionCode
          versionName
          icon
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Viewer",
    "kind": "LinkedField",
    "name": "viewer",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "WebkeyPackageConnection",
        "kind": "LinkedField",
        "name": "allWebkeyPackages",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "WebkeyPackageEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "WebkeyPackage",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "appName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "keyName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "source",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "packageName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "description",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "versionCode",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "versionName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "icon",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "WebkeyAppManagementRefetchQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "WebkeyAppManagementRefetchQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "id": "f66547a6ad2032aae6af54da601ea3a9",
    "metadata": {},
    "name": "WebkeyAppManagementRefetchQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '6694623ad2740e5ac541a9cdc013f163';

module.exports = node;
