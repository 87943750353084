/**
 * @flow
 * @relayHash 0f3ae77971ee31788d47d724a99803c8
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type _ModelMutationType = "CREATED" | "DELETED" | "UPDATED" | "UPDATEDCLIENT" | "%future added value";
export type AudioClientSubscriptionVariables = {||};
export type AudioClientSubscriptionResponse = {|
  +AudioClientSubscription: {|
    +mutation: _ModelMutationType,
    +node: {|
      +id: string,
      +name: ?string,
      +playlistIds: ?$ReadOnlyArray<?string>,
      +playbackIds: ?$ReadOnlyArray<?string>,
      +sceneIds: ?$ReadOnlyArray<?string>,
      +featuredIds: ?$ReadOnlyArray<?string>,
      +logo: ?string,
      +viewableCategories: ?$ReadOnlyArray<?{|
        +name: string,
        +subCategories: ?$ReadOnlyArray<?string>,
      |}>,
      +useExplicit: ?boolean,
      +noVolumeBar: ?boolean,
      +noPlayPause: ?boolean,
      +preview: ?{|
        +previewId: ?string,
        +created: ?any,
        +ending: ?any,
        +isEnded: ?boolean,
        +isEndless: ?boolean,
      |},
    |},
  |}
|};
export type AudioClientSubscription = {|
  variables: AudioClientSubscriptionVariables,
  response: AudioClientSubscriptionResponse,
|};
*/


/*
subscription AudioClientSubscription {
  AudioClientSubscription {
    mutation
    node {
      id
      name
      playlistIds
      playbackIds
      sceneIds
      featuredIds
      logo
      viewableCategories {
        name
        subCategories
      }
      useExplicit
      noVolumeBar
      noPlayPause
      preview {
        previewId
        created
        ending
        isEnded
        isEndless
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "AudioClientSubscriptionPayload",
    "kind": "LinkedField",
    "name": "AudioClientSubscription",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "mutation",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "AudioClient",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "playlistIds",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "playbackIds",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "sceneIds",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "featuredIds",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "logo",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ViewableCategory",
            "kind": "LinkedField",
            "name": "viewableCategories",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "subCategories",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "useExplicit",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "noVolumeBar",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "noPlayPause",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Preview",
            "kind": "LinkedField",
            "name": "preview",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "previewId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "created",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "ending",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isEnded",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isEndless",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AudioClientSubscription",
    "selections": (v1/*: any*/),
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "AudioClientSubscription",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "0f3ae77971ee31788d47d724a99803c8",
    "metadata": {},
    "name": "AudioClientSubscription",
    "operationKind": "subscription",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd43149b69c0be2901664edada3ed446c';

module.exports = node;
