import { commitMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import Environment from "../../environment";

const mutation = graphql`
  mutation downloadSongReportMutation($reportId: String!) {
    downloadSongReport(reportId: $reportId)
  }
`;

export default (reportId, callback) => {
  const variables = {
    reportId,
  };

  commitMutation(Environment, {
    mutation,
    variables,
    onCompleted: (response) => {
      callback(response);
    },
    onError: (err) => console.error(err), // eslint-disable-line
  });
};
