/**
 * @flow
 * @relayHash 312787beba7405277812fffd1d9730e9
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type _ModelMutationType = "CREATED" | "DELETED" | "UPDATED" | "UPDATEDCLIENT" | "%future added value";
export type NPLogSubscriptionVariables = {||};
export type NPLogSubscriptionResponse = {|
  +NPLogSubscription: {|
    +mutation: _ModelMutationType,
    +node: {|
      +id: string,
      +date: ?string,
      +deviceId: ?string,
    |},
  |}
|};
export type NPLogSubscription = {|
  variables: NPLogSubscriptionVariables,
  response: NPLogSubscriptionResponse,
|};
*/


/*
subscription NPLogSubscription {
  NPLogSubscription {
    mutation
    node {
      id
      date
      deviceId
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "NPLogSubscriptionPayload",
    "kind": "LinkedField",
    "name": "NPLogSubscription",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "mutation",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "NPLog",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "date",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "deviceId",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "NPLogSubscription",
    "selections": (v0/*: any*/),
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "NPLogSubscription",
    "selections": (v0/*: any*/)
  },
  "params": {
    "id": "312787beba7405277812fffd1d9730e9",
    "metadata": {},
    "name": "NPLogSubscription",
    "operationKind": "subscription",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '0780b949fbdd2f947dfa885f7eff8681';

module.exports = node;
