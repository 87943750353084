/**
 * @flow
 * @relayHash 95bbeef3866ff209b0af3470d5f26c88
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type _AutoPopulateTypes = "CATEGORY" | "GENRE" | "NONE" | "SUBCATEGORY" | "%future added value";
export type toggleRouteGenresMutationVariables = {|
  menuId: string,
  pathId: number,
  genres?: ?$ReadOnlyArray<?string>,
  routeItems?: ?number,
|};
export type toggleRouteGenresMutationResponse = {|
  +toggleRouteGenres: ?{|
    +__typename: string,
    +id: string,
    +name: ?string,
    +description: ?string,
    +schema: ?$ReadOnlyArray<?{|
      +name: string,
      +pathId: number,
      +parentId: number,
      +coverName: ?string,
      +cover_40: ?string,
      +cover_290: ?string,
      +cover_legacy: ?string,
      +playlistIds: ?$ReadOnlyArray<?string>,
      +sceneIds: ?$ReadOnlyArray<?string>,
      +mixesArray: ?$ReadOnlyArray<?{|
        +mixId: string,
        +type: string,
      |}>,
      +autoPopulateType: ?_AutoPopulateTypes,
      +category: ?string,
      +subCategory: ?string,
      +genres: ?$ReadOnlyArray<?string>,
      +routeItems: ?number,
    |}>,
    +active: ?boolean,
    +dateCreated: ?any,
    +dateModified: ?any,
    +clientId: ?string,
  |}
|};
export type toggleRouteGenresMutation = {|
  variables: toggleRouteGenresMutationVariables,
  response: toggleRouteGenresMutationResponse,
|};
*/


/*
mutation toggleRouteGenresMutation(
  $menuId: String!
  $pathId: Int!
  $genres: [String]
  $routeItems: Int
) {
  toggleRouteGenres(menuId: $menuId, pathId: $pathId, genres: $genres, routeItems: $routeItems) {
    __typename
    id
    name
    description
    schema {
      name
      pathId
      parentId
      coverName
      cover_40
      cover_290
      cover_legacy
      playlistIds
      sceneIds
      mixesArray {
        mixId
        type
      }
      autoPopulateType
      category
      subCategory
      genres
      routeItems
    }
    active
    dateCreated
    dateModified
    clientId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "genres"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "menuId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "pathId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "routeItems"
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "genres",
        "variableName": "genres"
      },
      {
        "kind": "Variable",
        "name": "menuId",
        "variableName": "menuId"
      },
      {
        "kind": "Variable",
        "name": "pathId",
        "variableName": "pathId"
      },
      {
        "kind": "Variable",
        "name": "routeItems",
        "variableName": "routeItems"
      }
    ],
    "concreteType": "SonosMenu",
    "kind": "LinkedField",
    "name": "toggleRouteGenres",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      (v4/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "description",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Route",
        "kind": "LinkedField",
        "name": "schema",
        "plural": true,
        "selections": [
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "pathId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "parentId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "coverName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "cover_40",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "cover_290",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "cover_legacy",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "playlistIds",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "sceneIds",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Mix",
            "kind": "LinkedField",
            "name": "mixesArray",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "mixId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "type",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "autoPopulateType",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "category",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "subCategory",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "genres",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "routeItems",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "active",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "dateCreated",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "dateModified",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "toggleRouteGenresMutation",
    "selections": (v5/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "toggleRouteGenresMutation",
    "selections": (v5/*: any*/)
  },
  "params": {
    "id": "95bbeef3866ff209b0af3470d5f26c88",
    "metadata": {},
    "name": "toggleRouteGenresMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '655dbce5e001ffccbf9e8fc5026f93f4';

module.exports = node;
