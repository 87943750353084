/**
 * @flow
 * @relayHash 261d56a48d35e7e87c16d4092f441480
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type requestLogsMutationVariables = {|
  inputid: string,
  inputType: string,
|};
export type requestLogsMutationResponse = {|
  +requestLogs: ?{|
    +id: string,
    +requestPCLogs: ?boolean,
    +requestNPLogs: ?boolean,
  |}
|};
export type requestLogsMutation = {|
  variables: requestLogsMutationVariables,
  response: requestLogsMutationResponse,
|};
*/


/*
mutation requestLogsMutation(
  $inputid: String!
  $inputType: String!
) {
  requestLogs(id: $inputid, type: $inputType) {
    id
    requestPCLogs
    requestNPLogs
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "inputType"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "inputid"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "inputid"
      },
      {
        "kind": "Variable",
        "name": "type",
        "variableName": "inputType"
      }
    ],
    "concreteType": "AudioDevice",
    "kind": "LinkedField",
    "name": "requestLogs",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "requestPCLogs",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "requestNPLogs",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "requestLogsMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "requestLogsMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "id": "261d56a48d35e7e87c16d4092f441480",
    "metadata": {},
    "name": "requestLogsMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a383ded9904d92ff8a7f08983291ed35';

module.exports = node;
