import { commitMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import Environment from "../../environment";

const mutation = graphql`
  mutation toggleFeaturedToClientMutation(
    $featuredIds: [String!]!
    $type: Boolean!
    $clientId: String!
    $mixType: String!
  ) {
    toggleFeaturedToClient(
      featuredIds: $featuredIds
      type: $type
      clientId: $clientId
      mixType: $mixType
    ) {
      id
      featuredIds
    }
  }
`;

export default (featuredIds, type, clientId, mixType, callback) => {
  const variables = {
    featuredIds,
    type,
    clientId,
    mixType,
  };

  commitMutation(Environment, {
    mutation,
    variables,
    onCompleted: () => {
      callback();
    },
    onError: (err) => console.error(err), // eslint-disable-line
  });
};
