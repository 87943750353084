import { commitMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import Environment from "../../environment";

const mutation = graphql`
  mutation createTagMutation($input: createTagProviderData!) {
    createTag(createTagProvider: $input) {
      id
      name
      description
    }
  }
`;

export default (name, description, callback) => {
  const variables = {
    input: {
      name,
      description,
    },
  };

  commitMutation(Environment, {
    mutation,
    variables,
    onCompleted: (response) => {
      if (response.createTag) {
        const id = response.createTag.id;
        callback(id);
      } else {
        callback("Tag duplicated");
      }
    },
    onError: (err) => console.error(err), // eslint-disable-line
  });
};
