import { commitMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import Environment from "../../environment";

const mutation = graphql`
  mutation editSmartPlaylistMutation(
    $input: createSmartPlaylistProviderData!
    $input2: ID!
    $imageName: String!
  ) {
    editSmartPlaylist(
      editSmartPlaylistProvider: $input
      smartPlaylistId: $input2
      imageName: $imageName
    ) {
      id
      name
      description
      shuffle
      image
      size
      createdAt
      updatedAt
      clientId
      playlists {
        playlistId
        name
        nrOfSongs
        filename
      }
      signedUrl
    }
  }
`;

export default (
  smartPlaylistId,
  name,
  description,
  shuffle,
  size,
  clientId,
  playlists,
  imageName,
  callback
) => {
  const variables = {
    input: {
      name,
      description,
      shuffle,
      size,
      clientId,
      playlists,
    },
    input2: smartPlaylistId,
    imageName,
  };

  commitMutation(Environment, {
    mutation,
    variables,
    onCompleted: (response) => {
      const signedUrl = response.editSmartPlaylist.signedUrl;
      callback(signedUrl);
    },
    onError: (err) => console.error(err), // eslint-disable-line
  });
};
