/**
 * @flow
 * @relayHash 66b35b0bc94c96683ce5f034bedf5e76
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ServerInstance = "backup" | "primary" | "%future added value";
export type refreshWebkeyMutationVariables = {|
  deviceId: string,
  serverInstance: ServerInstance,
|};
export type refreshWebkeyMutationResponse = {|
  +refreshWebkey: {|
    +id: string,
    +webkeyId: ?string,
    +webkeyBackupId: ?string,
  |}
|};
export type refreshWebkeyMutation = {|
  variables: refreshWebkeyMutationVariables,
  response: refreshWebkeyMutationResponse,
|};
*/


/*
mutation refreshWebkeyMutation(
  $deviceId: String!
  $serverInstance: ServerInstance!
) {
  refreshWebkey(deviceId: $deviceId, serverInstance: $serverInstance) {
    id
    webkeyId
    webkeyBackupId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "deviceId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "serverInstance"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "deviceId",
        "variableName": "deviceId"
      },
      {
        "kind": "Variable",
        "name": "serverInstance",
        "variableName": "serverInstance"
      }
    ],
    "concreteType": "AudioDevice",
    "kind": "LinkedField",
    "name": "refreshWebkey",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "webkeyId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "webkeyBackupId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "refreshWebkeyMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "refreshWebkeyMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "66b35b0bc94c96683ce5f034bedf5e76",
    "metadata": {},
    "name": "refreshWebkeyMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '40f2a077c03be2979021f7940d74f509';

module.exports = node;
