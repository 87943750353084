/**
 * @flow
 * @relayHash 280f4af3e583d36df04f47131d438ad0
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type getTaskStatusMutationVariables = {|
  taskId: string
|};
export type getTaskStatusMutationResponse = {|
  +getTaskStatus: ?{|
    +id: string,
    +userId: ?string,
    +clientId: ?string,
    +packageId: ?string,
    +type: ?string,
    +reports: ?$ReadOnlyArray<?{|
      +deviceid: ?string,
      +state: ?string,
    |}>,
    +date: ?any,
  |}
|};
export type getTaskStatusMutation = {|
  variables: getTaskStatusMutationVariables,
  response: getTaskStatusMutationResponse,
|};
*/


/*
mutation getTaskStatusMutation(
  $taskId: String!
) {
  getTaskStatus(taskId: $taskId) {
    id
    userId
    clientId
    packageId
    type
    reports {
      deviceid
      state
    }
    date
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "taskId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "taskId",
        "variableName": "taskId"
      }
    ],
    "concreteType": "WebkeyTask",
    "kind": "LinkedField",
    "name": "getTaskStatus",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "userId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "packageId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "type",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "WebkeyReport",
        "kind": "LinkedField",
        "name": "reports",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "deviceid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "state",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "date",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "getTaskStatusMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "getTaskStatusMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "280f4af3e583d36df04f47131d438ad0",
    "metadata": {},
    "name": "getTaskStatusMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd0128f7edeabd26feca4d0b0831b7c7b';

module.exports = node;
