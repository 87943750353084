import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import CardContent from "@material-ui/core/CardContent";
import DeviceControlZoneItem from "./deviceControlZoneItem";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import addQsysDeviceZoneMutation from "../../mutations/addQsysDeviceZoneMutation";
import permissions from "../../authentication/isPermited";
const styles = (theme) => ({
  root: {
    margin: "4px 0px 4px 0px",
  },
  card: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "6px",
  },
  content: {
    background: "#eeeeee",
  },
  typo1: {
    display: "flex",
    alignItems: "center",
    color: "#222831",
    fontSize: "16px",
  },
  typo2: {
    paddingLeft: 4,
    color: "#222831",
    fontWeight: "lighter",
    letterSpacing: "0.06em",
    marginBottom: "3px",
  },
  horizontalContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  verticalContainer: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    margin: 4,
  },
  button: {
    marginLeft: theme.spacing(1),
    width: 200,
    alignSelf: "center",
  },
  textField: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
  },
});
function DeviceControlZones(props) {
  const { classes, device } = props;
  const [newZoneName, setNewZoneName] = React.useState("");

  const handleAddNewZone = () => {
    if (newZoneName === "") return;
    addQsysDeviceZoneMutation(device.id, newZoneName, () => setNewZoneName(""));
  };
  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <CardContent className={classes.content}>
          <Typography className={classes.typo1} variant="h6">
            Device Control Zones
          </Typography>
          {permissions([1], [1]) && (
            <div className={classes.verticalContainer}>
              <TextField
                id="outlined-name"
                label="Zone name"
                className={classes.textField}
                value={newZoneName}
                onChange={(event) => setNewZoneName(event.target.value)}
                margin="normal"
                variant="outlined"
              />
              <Button
                variant="outlined"
                color="secondary"
                disabled={device?.noOfZones <= (device?.zones?.length || 0)}
                onClick={() => handleAddNewZone()}
                className={classes.button}
              >
                Add new zone
              </Button>
            </div>
          )}
          <div className={classes.verticalContainer}>
            <Typography
              className={classes.typo2}
              variant="body2"
              color="textSecondary"
            >
              <b>Active zones: </b>
              {device?.zones?.length || 0}
            </Typography>
            {device?.zones?.map((zone) => (
              <DeviceControlZoneItem
                deviceId={device?.id}
                zone={zone}
                key={zone.id}
              />
            ))}
          </div>
        </CardContent>
      </Card>
    </div>
  );
}

export default withStyles(styles)(DeviceControlZones);
