import React from "react";
import { QueryRenderer } from "react-relay";
import graphql from 'babel-plugin-relay/macro';
import PropTypes from "prop-types";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
// import _ from 'lodash';

import { withStyles } from "@material-ui/core/styles";
import compose from "recompose/compose";
import { withSnackbar } from "material-ui-snackbar-provider";

import Environment from "../../environment";
import customActionDeviceMutation from "../mutations/customActionDeviceMutation";

const Query = graphql`
  query CustomActionsModalQuery {
    viewer {
      allWebkeyCustomActions {
        edges {
          node {
            id
            name
            actionCode
          }
        }
      }
    }
  }
`;

const styles = (theme) => ({
  root: {},
  horizontalContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  verticalContainer: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  button: {
    width: "fit-content",
    margin: "8px 0px 8px 8px",
  },
  typo: {
    width: "fit-content",
    margin: 8,
  },
});

class BatchInstallModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      searchField: "",
      selectedAction: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.selectItem = this.selectItem.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.triggerCustomAction = this.triggerCustomAction.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state !== nextState) {
      return true;
    }
    return false;
  }

  handleChange(name, event) {
    this.setState({ [name]: event.target.value.toLowerCase() });
  }

  selectItem(item) {
    this.setState({
      open: true,
      selectedAction: item,
    });
  }

  handleClose() {
    this.setState({ open: false });
  }

  triggerCustomAction(item) {
    customActionDeviceMutation(
      [this.props.webkeyId],
      item.actionCode,
      this.props.instance,
      (response) => {
        return this.props.snackbar.showMessage(
          "Custom action executed successfully"
        );
      }
    );
    this.setState({ open: false });
    this.props.callback();
  }

  render() {
    const { classes, webkeyId } = this.props;
    const { searchField, selectedAction, open } = this.state;
    // const uniqPackages = _.uniqBy(packages, 'packageName');

    return (
      <QueryRenderer
        variables={{}}
        environment={Environment}
        query={Query}
        render={({ error, props }) => {
          console.log("here");
          if (error) {
            return <div>{error.message}</div>;
          } else if (props) {
            const allWebkeyCustomActions = props.viewer.allWebkeyCustomActions.edges
              .filter((item) => item && item.node)
              .map(({ node }) => node);
            return (
              <div className={classes.root}>
                <DialogContent className={classes.verticalContainer}>
                  <Dialog
                    onClose={() => this.setState({ open: false })}
                    aria-labelledby="run custom action"
                    open={open}
                  >
                    <Typography className={classes.typo} align="left">
                      {`Run custom action ${selectedAction.name} on ${webkeyId}?`}
                    </Typography>
                    <div className={classes.horizontalContainer}>
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        onClick={() => this.triggerCustomAction(selectedAction)}
                      >
                        Yes
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        onClick={this.handleClose}
                      >
                        No
                      </Button>
                    </div>
                  </Dialog>
                  <TextField
                    id="device-search-field"
                    label="Search"
                    className={classes.textField}
                    value={searchField}
                    onChange={(event) =>
                      this.handleChange("searchField", event)
                    }
                    margin="normal"
                  />
                  <List dense>
                    {allWebkeyCustomActions
                      .filter(
                        (item) =>
                          item.name &&
                          item.name.toLowerCase().includes(searchField)
                      )
                      .map((item) => (
                        <ListItem
                          key={item.id}
                          selected={selectedAction === item.name}
                        >
                          <ListItemText
                            primary={item.name}
                            secondary={item.actionCode}
                          />
                          <ListItemSecondaryAction>
                            <IconButton
                              edge="end"
                              aria-label="Uninstall App"
                              onClick={() => this.selectItem(item)}
                            >
                              <ArrowForwardIcon />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                      ))}
                  </List>
                </DialogContent>
              </div>
            );
          }
          return (
            <DialogContent style={{ padding: 8 }}>Please wait...</DialogContent>
          );
        }}
      />
    );
  }
}

BatchInstallModal.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(withStyles(styles), withSnackbar())(BatchInstallModal);
