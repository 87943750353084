import { commitMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import environment from "../../environment";

const mutation = graphql`
  mutation addUserToQsysDeviceMutation($deviceId: String!, $userId: String!) {
    addUserToQsysDevice(deviceId: $deviceId, userId: $userId) {
      id
      deviceId
      name
      description
      ipAddress
      systemVersion
      generalSchedule
      isGeneralScheduleActive
      systemIsEmulating
      coreUptime
      coreDate
      coreTime
      dateCreated
      userIds {
        id
        profile {
          name
        }
        emails {
          address
        }
      }
      noOfZones
      lastHeartbeat
      client {
        id
        name
      }
    }
  }
`;

function addUserToQsysDeviceMutation(
  deviceId,
  userId,
  callback,
  errorCallback
) {
  const variables = {
    deviceId,
    userId,
  };

  commitMutation(environment, {
    mutation,
    variables,
    updater: (store) => {
      const root = store.getRoot();
      const viewer = root.getLinkedRecord("viewer");
      const allQsysDevices = viewer.getLinkedRecords("allQsysDevices") || [];
      const newDevice = store.getRootField("addUserToQsysDevice");

      if (newDevice) {
        viewer.setLinkedRecords(
          [...allQsysDevices, newDevice],
          "allQsysDevices"
        );

        // Update user's qsysData
        const user = store.get(userId);
        if (user) {
          const qsysData = user.getOrCreateLinkedRecord("qsysData");
          const currentCores = qsysData.getValue("noOfCores") || 0;
          const currentZones = qsysData.getValue("noOfZones") || 0;
          const currentDeviceIds = qsysData.getValue("qsysDeviceIds") || [];

          qsysData.setValue(currentCores + 1, "noOfCores");
          qsysData.setValue(
            currentZones + (newDevice.getLinkedRecords("zones")?.length || 0),
            "noOfZones"
          );
          qsysData.setValue(
            [...currentDeviceIds, newDevice.getValue("id")],
            "qsysDeviceIds"
          );
        }
      }
    },
    onCompleted: (response, errors) => {
      if (errors) {
        errorCallback?.(errors);
        return;
      }
      if (callback) {
        callback();
      }
    },
    onError: (err) => {
      errorCallback?.(err);
      console.error(err);
    },
  });
}

export default addUserToQsysDeviceMutation;
