import _ from "lodash";

export const checkPlaylistsBeforeRemove = (
  playlistsInput = [],
  allPlaybacks = [],
  allSchedule = [],
  clientId
) => {
  const clientSmartPlaylists = allPlaybacks.filter(
    (item) => clientId === item.clientId
  );
  const clientSchedules = allSchedule.filter(
    (item) => clientId === item.clientId
  );
  const playlistsArray = [];
  playlistsInput.forEach((playlist) => {
    const smartPlaylists =
      clientSmartPlaylists.filter((item) => {
        const playlists = [...item.playlists].map((item2) => item2.playlistId);
        return playlists.includes(playlist.id);
      }) || [];

    const schedules =
      clientSchedules.filter((item) => {
        const mixIds = [...item.playingPlaylists].map(
          (item2) => item2.playlistId
        );
        return (
          mixIds.includes(playlist.id) ||
          _.intersection(
            smartPlaylists.map((item2) => item2.id),
            mixIds
          )?.length > 0
        );
      }) || [];
    if (smartPlaylists.length > 0 || schedules.length > 0) {
      playlistsArray.push({
        playlist,
        smartPlaylists,
        schedules,
      });
    }
  });
  return playlistsArray;
};
