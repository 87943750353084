import { commitMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import Environment from "../../environment";

const mutation = graphql`
  mutation createAudioClientMutation($input: audioClientProviderData!) {
    createAudioClient(audioClientProvider: $input) {
      id
      name
      playlistIds
      playbackIds
      sceneIds
      logo
      useExplicit
      noVolumeBar
      noPlayPause
    }
  }
`;

export default (
  name,
  playlistIds,
  useExplicit,
  noVolumeBar,
  noPlayPause,
  callback
) => {
  const variables = {
    input: {
      name,
      playlistIds,
      useExplicit,
      noVolumeBar,
      noPlayPause,
    },
  };

  commitMutation(Environment, {
    mutation,
    variables,
    onCompleted: (response) => {
      const id = response?.createAudioClient?.id;
      callback(id);
    },
    onError: (err) => console.error(err),
  });
};
