/**
 * External dependencies
 */
import graphql from "babel-plugin-relay/macro";

export const QSYS_ALL_MENUS = graphql`
  query allQsysMenusQuery($clientId: String) {
    viewer {
      allQsysMenu {
        id
        name
        description
        active
        clientId
        parentMenuId
        explicit
        childrenMenus {
          id
          name
          description
          parentMenuId
          explicit
        }
      }
      allPlaylists(clientId: $clientId) {
        edges {
          node {
            __typename
            id
            name
            description
            cover
            genre
            explicit
            category {
              category
              subCategories
            }
          }
        }
      }
      allScenes(clientId: $clientId) {
        edges {
          node {
            __typename
            id
            name
            description
            image
            category
            subCategory
            genres
            explicit
            newCategory {
              category
              subCategories
            }
          }
        }
      }
    }
  }
`;
