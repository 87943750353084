import { requestSubscription } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import { ConnectionHandler } from "relay-runtime";
import Environment from "../../environment";

const subscription = graphql`
  subscription PlaybackSubscription {
    PlaybackSubscription {
      mutation
      node {
        id
        name
        description
        shuffle
        image
        size
        createdAt
        updatedAt
        clientId
        playlists {
          playlistId
          name
          nrOfSongs
          filename
        }
      }
    }
  }
`;

export default (user, onNext) => {
  const subscriptionConfig = {
    subscription,
    updater: (store) => {
      const getRootField = store.getRootField("PlaybackSubscription");
      const mutation = getRootField.getValue("mutation");
      const node = getRootField.getLinkedRecord("node");
      switch (mutation) {
        case "CREATED":
          {
            const clientProxy = store.get("client:root:viewer:allPlaybacks");
            if (clientProxy) {
              const newEdge = ConnectionHandler.createEdge(
                store,
                clientProxy,
                node,
                "PlaybackEdge"
              );
              ConnectionHandler.insertEdgeBefore(clientProxy, newEdge);
            }
          }
          break;
        case "UPDATED":
          break;
        case "DELETED":
          {
            const nodeId = node.getDataID();
            store.delete(nodeId);
          }
          break;
        default:
          console.log("Something is wrong"); //eslint-disable-line
      }
    },
    onNext,
    onCompleted: () => console.log("done"),
    onError: (error) => console.log("An error occured:", error),
  };
  return requestSubscription(Environment, subscriptionConfig);
};
