/**
 * @flow
 * @relayHash 536cb339711ad848a7d52b81ad292058
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type requestVDScreenshotSyncRebootLogsMutationVariables = {|
  inputid: string,
  inputType: string,
|};
export type requestVDScreenshotSyncRebootLogsMutationResponse = {|
  +requestVDScreenshotSyncRebootLogs: ?{|
    +id: string,
    +reboot: ?boolean,
    +takeScreenShot: ?boolean,
    +sync: ?boolean,
    +uploadLogs: ?boolean,
  |}
|};
export type requestVDScreenshotSyncRebootLogsMutation = {|
  variables: requestVDScreenshotSyncRebootLogsMutationVariables,
  response: requestVDScreenshotSyncRebootLogsMutationResponse,
|};
*/


/*
mutation requestVDScreenshotSyncRebootLogsMutation(
  $inputid: String!
  $inputType: String!
) {
  requestVDScreenshotSyncRebootLogs(id: $inputid, type: $inputType) {
    id
    reboot
    takeScreenShot
    sync
    uploadLogs
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "inputType"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "inputid"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "inputid"
      },
      {
        "kind": "Variable",
        "name": "type",
        "variableName": "inputType"
      }
    ],
    "concreteType": "VideoDevice",
    "kind": "LinkedField",
    "name": "requestVDScreenshotSyncRebootLogs",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "reboot",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "takeScreenShot",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "sync",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "uploadLogs",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "requestVDScreenshotSyncRebootLogsMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "requestVDScreenshotSyncRebootLogsMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "id": "536cb339711ad848a7d52b81ad292058",
    "metadata": {},
    "name": "requestVDScreenshotSyncRebootLogsMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '7fb0074cbb2ee44b65d2cedd3d73afae';

module.exports = node;
