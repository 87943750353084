import { commitMutation } from "react-relay";
import graphql from 'babel-plugin-relay/macro';
import Environment from "../../environment";

const mutation = graphql`
  mutation statusAudioDeviceMutation(
    $inputid: String!
    $inputStatus: _deviceStatusType!
  ) {
    statusAudioDevice(id: $inputid, deviceStatus: $inputStatus) {
      id
      deviceStatus
    }
  }
`;

export default (id, deviceStatus, callback) => {
  const variables = {
    inputid: id,
    inputStatus: deviceStatus,
  };

  commitMutation(Environment, {
    mutation,
    variables,
    onCompleted: () => {
      callback();
    },
    onError: (err) => console.error(err), // eslint-disable-line
  });
};
