import { commitMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import Environment from "../../environment";

const mutation = graphql`
  mutation updateVideoDeviceClientFormatProfileMutation(
    $inputDeviceId: String!
    $inputClientId: String!
    $inputFormat: String!
    $inputProfile: ProfileDetailsInput!
  ) {
    updateVideoDeviceClientFormatProfile(
      deviceId: $inputDeviceId
      clientId: $inputClientId
      format: $inputFormat
      profile: $inputProfile
    ) {
      id
    }
  }
`;

export default (deviceId, clientId, format, profile, callback) => {
  const variables = {
    inputDeviceId: deviceId,
    inputClientId: clientId,
    inputFormat: format,
    inputProfile: profile,
  };

  commitMutation(Environment, {
    mutation,
    variables,
    onCompleted: () => {
      callback();
    },
    onError: (err) => console.error(err), // eslint-disable-line
  });
};
