/**
 * @flow
 * @relayHash 256cfce189b9e47643d8ad8916f81414
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type blacklistSongMutationVariables = {|
  deviceId: string
|};
export type blacklistSongMutationResponse = {|
  +blacklistSong: ?string
|};
export type blacklistSongMutation = {|
  variables: blacklistSongMutationVariables,
  response: blacklistSongMutationResponse,
|};
*/


/*
mutation blacklistSongMutation(
  $deviceId: String!
) {
  blacklistSong(deviceId: $deviceId)
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "deviceId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "deviceId",
        "variableName": "deviceId"
      }
    ],
    "kind": "ScalarField",
    "name": "blacklistSong",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "blacklistSongMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "blacklistSongMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "256cfce189b9e47643d8ad8916f81414",
    "metadata": {},
    "name": "blacklistSongMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'eac18e055639f0435569fb90df2aff22';

module.exports = node;
