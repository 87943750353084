/* eslint-disable no-underscore-dangle, no-return-assign */

import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Divider from "@material-ui/core/Divider";
import ReactTable from "react-table";
import checkboxHOC from "react-table/lib/hoc/selectTable";
import "react-table/react-table.css";
import compose from "recompose/compose";
import { withSnackbar } from "material-ui-snackbar-provider";

import assignScheduleMultipleMutation from "../mutations/assignScheduleMultipleMutation";

const CheckboxTable = checkboxHOC(ReactTable);

const styles = (theme) => ({
  dialog: {
    background: "secondary",
    margin: theme.spacing(3),
  },
  dialogTitle: {
    padding: "0px 24px 0px 24px",
    minWidth: 230,
    maxWidth: 800,
  },
  verticalContainer: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
  },
  horizontalContainer: {
    display: "flex",
  },
  button: {
    margin: "8px 8px 8px 8px",
    maxWidth: 140,
  },
  closeIcon: {
    height: 28,
    width: 28,
  },
  close: {
    position: "relative",
    float: "right",
  },
  title: {
    margin: 0,
  },
  table: {
    overflowY: "auto",
    maxHeight: 500,
  },
});

class ScheduleAssign extends React.Component {
  constructor(props) {
    super(props);

    const data = this.props.viewer.allAudioDevices.edges
      .filter((item) => item && item.node)
      .filter((item) => item.node.deviceStatus === "ACTIVE")
      .map((item) => item.node);

    const filteredData = data.filter(
      (item) =>
        item.scheduleIds && item.scheduleIds.includes(this.props.scheduleID)
    );

    const selectionState = filteredData.map((item) => item.id);

    this.state = {
      selection: selectionState,
      initialSelection: selectionState,
      selectAll: false,
    };
    this.handleRequestClose = this.handleRequestClose.bind(this);
    this.toggleSelection = this.toggleSelection.bind(this);
    this.toggleAll = this.toggleAll.bind(this);
    this.isSelected = this.isSelected.bind(this);
    this.handleAssign = this.handleAssign.bind(this);
  }

  handleRequestClose() {
    this.props.handleRequestClose();
  }

  toggleSelection(bruteKey /* , shift, row */) {
    const key = bruteKey.replace("select-", "");
    let selection = [...this.state.selection];
    const keyIndex = selection.indexOf(key);
    if (keyIndex >= 0) {
      selection = [
        ...selection.slice(0, keyIndex),
        ...selection.slice(keyIndex + 1),
      ];
    } else {
      selection.push(key);
    }
    this.setState({ selection });
  }

  toggleAll() {
    const selectAll = !this.state.selectAll;
    const selection = [];
    if (selectAll) {
      const wrappedInstance = this.checkboxTable.getWrappedInstance();
      const currentRecords = wrappedInstance.getResolvedState().sortedData;
      currentRecords.forEach((item) => {
        selection.push(item._original.id);
      });
    }
    this.setState({ selectAll, selection });
  }

  isSelected(key) {
    return this.state.selection.includes(key);
  }

  handleAssign() {
    assignScheduleMultipleMutation(
      this.props.scheduleID,
      this.state.selection,
      () => this.props.snackbar.showMessage("Schedule assigned to device(s)")
    );
    this.props.handleRequestClose();
  }

  render() {
    const { classes, open, viewer, clientId } = this.props;

    const columns = [
      {
        id: "name",
        Header: "Name",
        accessor: (a) => a.name,
        width: 400,
      },
    ];

    const { toggleSelection, toggleAll, isSelected } = this;
    const { selectAll } = this.state;

    const checkboxProps = {
      selectAll,
      isSelected,
      toggleSelection,
      toggleAll,
      selectType: "checkbox",
    };

    const data = viewer.allAudioDevices.edges
      .filter((item) => item && item.node)
      .filter((item) => item.node.clientId === clientId)
      .filter((item) => item.node.deviceStatus === "ACTIVE")
      .map((item) => item.node);
    return (
      <Dialog open={open} className={classes.dialog} maxWidth="lg">
        <div className={classes.title}>
          <div className={classes.close}>
            <IconButton
              aria-label="Close"
              className={classes.close}
              onClick={this.handleRequestClose}
            >
              <CloseIcon className={classes.closeIcon} />
            </IconButton>
          </div>
          <DialogTitle
            className={classes.dialogTitle}
            style={{ marginTop: 16, align: "center" }}
          >
            Assign schedule to devices
          </DialogTitle>
        </div>
        <div className={classes.table}>
          <CheckboxTable
            ref={(r) => (this.checkboxTable = r)}
            keyField="id"
            data={data}
            columns={columns}
            filterable
            showPagination={false}
            defaultPageSize={data.length}
            defaultFilterMethod={(filter, row) =>
              String(row[filter.id])
                .toLowerCase()
                .includes(filter.value.toLowerCase())
            }
            className="-striped -highlight"
            style={{
              display: "block",
              fontSize: "12px",
              flex: "auto",
            }}
            {...checkboxProps}
          />
        </div>
        <Divider />
        <div className={classes.horizontalContainer}>
          <Button
            variant="contained"
            color="secondary"
            onClick={this.handleRequestClose}
            className={classes.button}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={this.handleAssign}
            className={classes.button}
          >
            Save
          </Button>
        </div>
      </Dialog>
    );
  }
}

ScheduleAssign.defaultProps = {
  open: false,
  clientId: "",
};

ScheduleAssign.propTypes = {
  classes: PropTypes.object.isRequired, //eslint-disable-line
  viewer: PropTypes.object.isRequired, //eslint-disable-line
  clientId: PropTypes.string,
  open: PropTypes.bool,
  scheduleID: PropTypes.string.isRequired,
  handleRequestClose: PropTypes.func, //eslint-disable-line
  snackbar: PropTypes.object, //eslint-disable-line
};

export default compose(withStyles(styles), withSnackbar())(ScheduleAssign);
