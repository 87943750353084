/**
 * External dependencies
 */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Modal,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Divider,
  Typography,
} from "@material-ui/core";

/**
 * Internal dependencies
 */

import toggleCategoriesMutation from "../../mutations/toggleCategoriesMutation";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  form: {
    display: "flex",
    flexDirection: "column",
  },
  paper: {
    width: 700,
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: "5vh",
    borderRadius: 19,
    maxHeight: 800,
  },
  horizontalContainer: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  verticalContainer: {
    display: "flex",
    flexDirection: "column",
  },
}));

export default function CategoriesModal({
  openModal,
  callbackModal,
  allCategories,
  currentClient,
}) {
  const classes = useStyles();
  const [checked, setChecked] = React.useState(
    currentClient?.viewableCategories || []
  );

  useEffect(() => {
    setChecked(currentClient?.viewableCategories || []);
  }, [currentClient]);

  const handleClose = () => {
    callbackModal();
  };

  const handleChange = (event, category) => {
    toggleCategoriesMutation(
      currentClient.id,
      category,
      event.target.name,
      event.target.checked,
      (currentClient) => setChecked(currentClient?.viewableCategories || [])
    );
  };

  const handleChangeAll = (event, category) => {
    toggleCategoriesMutation(
      currentClient.id,
      event.target.name,
      null,
      event.target.checked,
      (currentClient) => setChecked(currentClient?.viewableCategories || [])
    );
  };

  return (
    <div>
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="categories-modal"
        aria-describedby="set-client-categories"
        className={classes.modal}
      >
        <div className={classes.paper}>
          <h3 id="edit-menu-modal-title">
            Set client viewable categories and subcategories:
          </h3>
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              overflowX: "auto",
            }}
          >
            {allCategories
              .sort((a, b) => a?.sortOrder - b?.sortOrder)
              .map((category) => {
                const checkedCategories = checked?.find(
                  (item) => item.name === category.name
                );
                return (
                  <React.Fragment key={category.name}>
                    <div
                      className={classes.verticalContainer}
                      style={{ paddingLeft: 8, paddingRight: 8, minWidth: 170 }}
                    >
                      <FormControlLabel
                        key={category.name}
                        style={{ alignSelf: "self-start" }}
                        control={
                          <Checkbox
                            checked={
                              checkedCategories?.subCategories?.length > 0 ||
                              false
                            }
                            onChange={handleChangeAll}
                            name={category.name}
                            size="small"
                          />
                        }
                        label={
                          <h5 id="edit-menu-modal-title">{category.name}</h5>
                        }
                      />
                      <div
                        className={classes.verticalContainer}
                        style={{
                          maxHeight: 600,
                          overflowY: "auto",
                          paddingLeft: 12,
                        }}
                      >
                        <FormGroup>
                          {category.subCategories.map((subCategory) => (
                            <FormControlLabel
                              key={subCategory}
                              control={
                                <Checkbox
                                  checked={
                                    checkedCategories?.subCategories?.includes(
                                      subCategory
                                    ) || false
                                  }
                                  onChange={(event) =>
                                    handleChange(event, category.name)
                                  }
                                  name={subCategory}
                                  size="small"
                                />
                              }
                              label={
                                <Typography
                                  style={{
                                    ...useStyles.formControlLabel,
                                    fontSize: 12,
                                  }}
                                >
                                  {subCategory}
                                </Typography>
                              }
                            />
                          ))}
                        </FormGroup>
                      </div>
                    </div>
                    <Divider orientation="vertical" flexItem />
                  </React.Fragment>
                );
              })}
          </div>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={() => callbackModal()}
          >
            Close
          </Button>
        </div>
      </Modal>
    </div>
  );
}

CategoriesModal.propTypes = {
  openModal: PropTypes.bool.isRequired,
  callbackModal: PropTypes.func.isRequired, // eslint-disable-line
  allCategories: PropTypes.array.isRequired, // eslint-disable-line
  currentClient: PropTypes.object.isRequired, // eslint-disable-line
};
