import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";

const styles = (theme) => ({
  root: {
    margin: "4px 0px 4px 0px",
  },
  card: {
    display: "flex",
    flexDirection: "column",
  },
  expansion: {
    flex: "auto",
    boxShadow:
      "0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12)",
  },
  expansionSummary: {
    minHeight: 16,
    height: 24,
  },
  expansionDetails: {
    padding: "8px 8px 8px 8px",
    display: "flex",
    flexDirection: "column",
  },
  actionsContainer: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  button: {
    marginRight: theme.spacing(1),
  },
  horizontalContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  verticalContainer: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    margin: 4,
  },
  volume: {
    minWidth: 250,
    paddingLeft: 8,
  },
  typo1: {
    display: "flex",
    alignItems: "center",
  },
  typo2: {
    paddingLeft: 4,
  },
  typo3: {
    paddingLeft: 4,
    position: "absolute",
    zIndex: 1,
    alignSelf: "center",
  },
  progress: {
    height: 23,
  },
});

class AppDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      classes,
      appUpTime,
      appVersion,
      volume,
      currentlyPlaying,
      totalStorage,
      usedStorage,
      availableStorage,
      lastSync,
    } = this.props;
    return (
      <div className={classes.root}>
        <Card className={classes.card}>
          <CardContent className={classes.content}>
            <Typography className={classes.typo1} variant="h6">
              Application Details
            </Typography>
            <Typography
              className={classes.typo2}
              variant="body2"
              color="textSecondary"
            >
              <b>Currently Playing </b>
              {currentlyPlaying}
            </Typography>
            <div className={classes.horizontalContainer}>
              <div className={classes.verticalContainer}>
                <Typography
                  className={classes.typo2}
                  variant="body2"
                  color="textSecondary"
                >
                  <b>Volume </b>
                  {volume}
                </Typography>
                <Typography
                  className={classes.typo2}
                  variant="body2"
                  color="textSecondary"
                >
                  <b>Last Sync </b>
                  {lastSync}
                </Typography>
              </div>
              <div className={classes.verticalContainer}>
                <Typography
                  className={classes.typo2}
                  variant="body2"
                  color="textSecondary"
                >
                  <b>App Version </b>
                  {appVersion}
                </Typography>
                <Typography
                  className={classes.typo2}
                  variant="body2"
                  color="textSecondary"
                >
                  <b>App Uptime </b>
                  {appUpTime}
                </Typography>
              </div>
            </div>
            <div className={classes.volume}>
              <Typography
                className={classes.typo3}
                variant="subtitle1"
                color="textSecondary"
              >
                {`Storage  ${Number(usedStorage).toFixed(2)}GB/${Number(
                  totalStorage
                ).toFixed(2)}GB (Available:${Number(availableStorage).toFixed(
                  2
                )}GB)`}
              </Typography>
              <LinearProgress
                color="primary"
                variant="determinate"
                value={(Number(usedStorage) / Number(totalStorage)) * 100}
                className={classes.progress}
              />
            </div>
          </CardContent>
        </Card>
      </div>
    );
  }
}

AppDetails.defaultProps = {
  appStatus: {},
};

AppDetails.propTypes = {
  classes: PropTypes.object.isRequired, //eslint-disable-line
  appStatus: PropTypes.object, //eslint-disable-line
  appUpTime: PropTypes.string.isRequired,
  appVersion: PropTypes.string.isRequired,
  volume: PropTypes.number.isRequired,
  currentlyPlaying: PropTypes.string.isRequired,
  totalStorage: PropTypes.string.isRequired,
  usedStorage: PropTypes.string.isRequired,
  availableStorage: PropTypes.string.isRequired,
  lastSync: PropTypes.string.isRequired,
};

export default withStyles(styles)(AppDetails);
