import React, { Component } from "react";
import { withRouter } from "found";
import { RelayEnvironmentProvider } from "relay-hooks";
import isAuthenticated from "./authentication/isAuthenticated";

import environment from "../environment";

class AppPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      auth: isAuthenticated(),
    };
  }

  componentWillMount() {
    const { auth } = this.state;
    if (!auth) {
      this.props.router.replace("/login");
    }
  }

  render() {
    const { children } = this.props;
    return (
      <RelayEnvironmentProvider environment={environment}>
        <div>{children}</div>
      </RelayEnvironmentProvider>
    );
  }
}

export default withRouter(AppPage);
