import { commitMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import Environment from "../../environment";

const mutation = graphql`
  mutation editSonosRouteFolderMutation(
    $menuId: String!
    $pathId: Int!
    $name: String!
    $autoPopulateType: _AutoPopulateTypes!
    $coverName: String
    $cover_40: String
    $cover_290: String
    $cover_legacy: String
    $playableType: _PlayableTypes
    $routeItems: Int
  ) {
    editSonosRouteFolder(
      menuId: $menuId
      pathId: $pathId
      name: $name
      autoPopulateType: $autoPopulateType
      coverName: $coverName
      cover_40: $cover_40
      cover_290: $cover_290
      cover_legacy: $cover_legacy
      playableType: $playableType
      routeItems: $routeItems
    ) {
      __typename
      id
      name
      description
      schema {
        name
        pathId
        parentId
        coverName
        cover_40
        cover_290
        cover_legacy
        playlistIds
        sceneIds
        autoPopulateType
        category
        subCategory
        genres
        routeItems
        playableType
      }
      active
      dateCreated
      dateModified
      clientId
    }
  }
`;

export default (
  menuId,
  pathId,
  name,
  autoPopulateType,
  coverName,
  cover_40,
  cover_290,
  cover_legacy,
  playableType,
  routeItems,
  callback
) => {
  const variables = {
    menuId,
    pathId,
    name,
    autoPopulateType,
    coverName,
    cover_40,
    cover_290,
    cover_legacy,
    playableType,
    routeItems,
  };

  commitMutation(Environment, {
    mutation,
    variables,
    onCompleted: (response) => {
      callback(response?.editSonosRouteFolder);
    },
    onError: (err) => console.error(err), // eslint-disable-line
  });
};
