import { commitMutation } from "react-relay";
import graphql from 'babel-plugin-relay/macro';
import Environment from "../../environment";

const mutation = graphql`
  mutation getDevicePackagesMutation(
    $publicid: String!
    $serverInstance: ServerInstance!
  ) {
    getDevicePackages(publicid: $publicid, serverInstance: $serverInstance) {
      appName
      packageName
      versionCode
      versionName
    }
  }
`;

export default (publicid, serverInstance, callback) => {
  const variables = {
    publicid,
    serverInstance,
  };

  commitMutation(Environment, {
    mutation,
    variables,
    onCompleted: (response) => {
      if (response && response.getDevicePackages) {
        callback(response.getDevicePackages);
      }
    },
    onError: (err) => console.error(err), // eslint-disable-line
  });
};
