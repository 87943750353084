/**
 * @flow
 * @relayHash 8f396a593f4580be0eb3e56b457513bf
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type _ModelMutationType = "CREATED" | "DELETED" | "UPDATED" | "UPDATEDCLIENT" | "%future added value";
export type PlaylistSubscriptionVariables = {||};
export type PlaylistSubscriptionResponse = {|
  +PlaylistSubscription: {|
    +mutation: _ModelMutationType,
    +node: {|
      +id: string,
      +name: string,
      +bucket: string,
      +description: ?string,
      +cover: ?string,
      +tracks: ?number,
      +size: ?number,
      +failed: ?number,
      +updatedAt: ?any,
      +createdAt: ?any,
      +signedUrl: ?string,
      +genre: ?$ReadOnlyArray<?string>,
      +subGenre: ?$ReadOnlyArray<?string>,
      +tempo: ?string,
      +clientsId: ?$ReadOnlyArray<?string>,
      +duration: ?number,
    |},
  |}
|};
export type PlaylistSubscription = {|
  variables: PlaylistSubscriptionVariables,
  response: PlaylistSubscriptionResponse,
|};
*/


/*
subscription PlaylistSubscription {
  PlaylistSubscription {
    mutation
    node {
      id
      name
      bucket
      description
      cover
      tracks
      size
      failed
      updatedAt
      createdAt
      signedUrl
      genre
      subGenre
      tempo
      clientsId
      duration
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "PlaylistSubscriptionPayload",
    "kind": "LinkedField",
    "name": "PlaylistSubscription",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "mutation",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Playlist",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "bucket",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "cover",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "tracks",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "size",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "failed",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "updatedAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createdAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "signedUrl",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "genre",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "subGenre",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "tempo",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "clientsId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "duration",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "PlaylistSubscription",
    "selections": (v0/*: any*/),
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "PlaylistSubscription",
    "selections": (v0/*: any*/)
  },
  "params": {
    "id": "8f396a593f4580be0eb3e56b457513bf",
    "metadata": {},
    "name": "PlaylistSubscription",
    "operationKind": "subscription",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '872029231fbbf7053439ba94a7ec0488';

module.exports = node;
