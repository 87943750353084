/**
 * @flow
 * @relayHash de7505dc3c297841d164d5b2335ebaab
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type typeOperationEnum = "add" | "remove" | "%future added value";
export type typeRoleEnum = "access" | "audio" | "video" | "%future added value";
export type editMethodPermissionMutationVariables = {|
  id: string,
  typeRole: typeRoleEnum,
  userType: number,
  typeOperation: typeOperationEnum,
|};
export type editMethodPermissionMutationResponse = {|
  +editMethodPermission: ?{|
    +id: string,
    +displayName: string,
    +methodName: string,
    +description: ?string,
    +UIElementsID: $ReadOnlyArray<?string>,
    +userType: $ReadOnlyArray<?number>,
    +roleType: typeRoleEnum,
  |}
|};
export type editMethodPermissionMutation = {|
  variables: editMethodPermissionMutationVariables,
  response: editMethodPermissionMutationResponse,
|};
*/


/*
mutation editMethodPermissionMutation(
  $id: ID!
  $typeRole: typeRoleEnum!
  $userType: Int!
  $typeOperation: typeOperationEnum!
) {
  editMethodPermission(id: $id, typeRole: $typeRole, userType: $userType, typeOperation: $typeOperation) {
    id
    displayName
    methodName
    description
    UIElementsID
    userType
    roleType
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "typeOperation"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "typeRole"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userType"
},
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "typeOperation",
        "variableName": "typeOperation"
      },
      {
        "kind": "Variable",
        "name": "typeRole",
        "variableName": "typeRole"
      },
      {
        "kind": "Variable",
        "name": "userType",
        "variableName": "userType"
      }
    ],
    "concreteType": "Method",
    "kind": "LinkedField",
    "name": "editMethodPermission",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "displayName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "methodName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "description",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "UIElementsID",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "userType",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "roleType",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "editMethodPermissionMutation",
    "selections": (v4/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "editMethodPermissionMutation",
    "selections": (v4/*: any*/)
  },
  "params": {
    "id": "de7505dc3c297841d164d5b2335ebaab",
    "metadata": {},
    "name": "editMethodPermissionMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e411b0a04e350922fae3f187198de9df';

module.exports = node;
