/**
 * External dependencies
 */
import React, { useState, Suspense } from "react";
import { useLazyLoadQuery } from "react-relay";
import { withStyles } from "@material-ui/core/styles";
import jwt from "jsonwebtoken";

/**
 * Internal dependencies
 */

import MenuColumn from "../components/menuColumn/MenuColumn";
import SchemaColumn from "../components/schemaColumn/SchemaColumn";
import RouteColumn from "../components/routeColumn/RouteColumn";
import permissions2 from "../../authentication/UIPermissions";

import ClientContextProvider from "../hooks/currentClientProvider";
import MenuContextProvider, {
  CurrentMenuContext,
} from "../hooks/currentMenuProvider";
import RouteContextProvider, {
  CurrentRouteContext,
} from "../hooks/currentRouteProvider";

import { ALL_CLIENTS } from "../queries/allAudioClients";
import ClientModal from "../components/ClientModal";
import LoadingPage from "../../ui/LoadingPage";

const styles = () => ({
  root: {
    display: "flex",
    height: "calc(100vh - 128px)",
  },
});

const MenuView = (props) => {
  const { classes, match, audioProvider } = props;
  const viewerGlobal = props.viewer;
  const [openModal, setOpenModal] = useState(false);

  const { viewer } = useLazyLoadQuery(
    ALL_CLIENTS,
    {},
    { fetchPolicy: "store-and-network" }
  );

  const allClients = viewer?.allClients.edges
    .filter((item) => item?.node)
    .map(({ node }) => node);

  const user = jwt.decode(localStorage.getItem("authentication_token"));

  const methods = viewerGlobal?.allMethods.edges
    .filter((item) => item && item.node)
    .filter(
      ({ node }) =>
        (node.roleType === "access" &&
          node.userType.includes(user?.roleAccess)) ||
        (node.roleType === "audio" &&
          node.userType.includes(user?.roleAudio)) ||
        (node.roleType === "video" && node.userType.includes(user?.roleVideo))
    );

  return (
    permissions2("61138c2c74598f0ed69db4c7", methods) && (
      <div style={{ minHeight: "90vh" }}>
        <Suspense fallback={<LoadingPage />}>
          <ClientContextProvider>
            <ClientModal
              match={match}
              open={openModal}
              onClose={() => setOpenModal(false)}
              classes={classes}
              allClients={allClients}
              viewer={viewer}
            />
            <div
              className={classes.horizontalContainer}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginLeft: "12px",
              }}
            >
              <span
                style={{
                  cursor: "pointer",
                  color: "#3BABA1",
                  fontSize: 16,
                  fontWeight: "bold",
                }}
                onClick={() => setOpenModal(true)}
              >
                Change Client &nbsp;
              </span>
            </div>
            <div className={classes.root}>
              <MenuContextProvider>
                <RouteContextProvider>
                  <MenuColumn
                    allClients={allClients}
                    audioProvider={audioProvider}
                  />
                  <CurrentMenuContext.Consumer>
                    {({ currentMenu, currentMenuExtension }) => {
                      return (
                        currentMenu && (
                          <SchemaColumn
                            menu={currentMenu}
                            menuExtension={currentMenuExtension}
                            audioProvider={audioProvider}
                          />
                        )
                      );
                    }}
                  </CurrentMenuContext.Consumer>
                  <CurrentRouteContext.Consumer>
                    {({ currentRoute, routeDisabled }) => {
                      return (
                        currentRoute && (
                          <RouteColumn
                            currentRoute={currentRoute}
                            disabled={routeDisabled}
                            audioProvider={audioProvider}
                          />
                        )
                      );
                    }}
                  </CurrentRouteContext.Consumer>
                </RouteContextProvider>
              </MenuContextProvider>
            </div>
          </ClientContextProvider>
        </Suspense>
      </div>
    )
  );
};

export default withStyles(styles)(MenuView);
