import { commitMutation } from "react-relay";
import graphql from 'babel-plugin-relay/macro';
import Environment from "../../environmentLogin";

const mutation = graphql`
  mutation handleGoogleMutation(
    $inputname: String!
    $inputemail: String!
    $inputGoogleData: GoogleData!
  ) {
    handleGoogle(
      name: $inputname
      email: $inputemail
      googleData: $inputGoogleData
    ) {
      token
      user {
        id
        profile {
          clientId
          videoClientId
        }
      }
    }
  }
`;

export default (name, email, googleData, callback) => {
  const variables = {
    inputname: name,
    inputemail: email,
    inputGoogleData: googleData,
  };

  commitMutation(Environment, {
    mutation,
    variables,
    onCompleted: (response) => {
      if (response && response.handleGoogle && response.handleGoogle.token) {
        const id = response.handleGoogle.user.id;
        const audioClientId = response.handleGoogle.user.profile.clientId;
        const videoClientId =
          response.handleGoogle.user.profile.videoClientId || "none";
        const token = response.handleGoogle.token;
        callback(id, token, audioClientId, videoClientId);
      } else {
        callback("error");
      }
    },
    onError: (err) => console.error(err),
  });
};
