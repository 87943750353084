import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";

const styles = (theme) => ({
  root: {
    margin: "4px 0px 4px 0px",
  },
  card: {
    display: "flex",
    flexDirection: "column",
  },
  expansion: {
    flex: "auto",
    boxShadow:
      "0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12)",
  },
  expansionSummary: {
    minHeight: 16,
    height: 24,
  },
  expansionDetails: {
    padding: "8px 8px 8px 8px",
    display: "flex",
    flexDirection: "column",
  },
  actionsContainer: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  button: {
    marginRight: theme.spacing(1),
  },
  horizontalContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  verticalContainer: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    margin: 4,
  },
  typo1: {
    display: "flex",
    alignItems: "center",
  },
  typo2: {
    paddingLeft: 4,
  },
});

class SystemDetailsControls extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      classes,
      model,
      lastOnline,
      serialNumber,
      lanMacAddress,
      macAddress,
      internalIp,
      publicIp,
      hdmiStatus,
    } = this.props;
    const lastOnlineISO = new Date(lastOnline).toISOString();
    return (
      <div className={classes.root}>
        <Card className={classes.card}>
          <CardContent className={classes.content}>
            <Typography className={classes.typo1} variant="h6">
              System Details & Controls
            </Typography>
            <div className={classes.horizontalContainer}>
              <div className={classes.verticalContainer}>
                <Typography
                  className={classes.typo2}
                  variant="body2"
                  color="textSecondary"
                >
                  <b>Model </b>
                  {model}
                </Typography>
                <Typography
                  className={classes.typo2}
                  variant="body2"
                  color="textSecondary"
                >
                  <b>LAN MAC Address </b>
                  {lanMacAddress}
                </Typography>
                <Typography
                  className={classes.typo2}
                  variant="body2"
                  color="textSecondary"
                >
                  <b>MAC Address </b>
                  {macAddress}
                </Typography>
                <Typography
                  className={classes.typo2}
                  variant="body2"
                  color="textSecondary"
                >
                  <b>Serial Number </b>
                  {serialNumber}
                </Typography>
              </div>
              <div className={classes.verticalContainer}>
                <Typography
                  className={classes.typo2}
                  variant="body2"
                  color="textSecondary"
                >
                  <b>Device internal IP </b>
                  {internalIp}
                </Typography>
                <Typography
                  className={classes.typo2}
                  variant="body2"
                  color="textSecondary"
                >
                  <b>Device public IP </b>
                  {publicIp}
                </Typography>
                <Typography
                  className={classes.typo2}
                  variant="body2"
                  color="textSecondary"
                >
                  <b>Last Online </b>
                  {lastOnlineISO}
                </Typography>
                <Typography
                  className={classes.typo2}
                  variant="body2"
                  color="textSecondary"
                >
                  <b>HDMI Status </b>
                  {hdmiStatus ? "Connected" : "Disconnected"}
                </Typography>
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
    );
  }
}

SystemDetailsControls.defaultProps = {
  serialNumber: "N/A",
  lanMacAddress: "N/A",
  macAddress: "N/A",
};

SystemDetailsControls.propTypes = {
  classes: PropTypes.object.isRequired, //eslint-disable-line
  model: PropTypes.string.isRequired,
  lastOnline: PropTypes.number.isRequired,
  serialNumber: PropTypes.string,
  lanMacAddress: PropTypes.string,
  macAddress: PropTypes.string,
  internalIp: PropTypes.string.isRequired,
  publicIp: PropTypes.string.isRequired,
  hdmiStatus: PropTypes.bool.isRequired,
};

export default withStyles(styles)(SystemDetailsControls);
