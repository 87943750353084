import { commitMutation } from "react-relay";
import graphql from 'babel-plugin-relay/macro';
import Environment from "../../environment";

const mutation = graphql`
  mutation requestBlobMutation($size: Int!) {
    requestBlob(size: $size) {
      id
    }
  }
`;

export default (size, callback) => {
  const variables = {
    size,
  };

  commitMutation(Environment, {
    mutation,
    variables,
    onCompleted: () => {
      console.log("Done");
    },
    onError: (err) => console.error(err), // eslint-disable-line
  });
};
