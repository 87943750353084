/**
 * @flow
 * @relayHash 0692fae8dde407cdcfc626b883dabad2
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type deleteQsysDeviceMutationVariables = {|
  deviceId: string
|};
export type deleteQsysDeviceMutationResponse = {|
  +deleteQsysDevice: ?string
|};
export type deleteQsysDeviceMutation = {|
  variables: deleteQsysDeviceMutationVariables,
  response: deleteQsysDeviceMutationResponse,
|};
*/


/*
mutation deleteQsysDeviceMutation(
  $deviceId: String!
) {
  deleteQsysDevice(deviceId: $deviceId)
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "deviceId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "deviceId",
        "variableName": "deviceId"
      }
    ],
    "kind": "ScalarField",
    "name": "deleteQsysDevice",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "deleteQsysDeviceMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "deleteQsysDeviceMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "0692fae8dde407cdcfc626b883dabad2",
    "metadata": {},
    "name": "deleteQsysDeviceMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '78b2fa3e63e8cf9876259a1c407cf7c6';

module.exports = node;
