import { commitMutation } from "react-relay";
import graphql from 'babel-plugin-relay/macro';
import Environment from "../../environment";

const mutation = graphql`
  mutation getTaskStatusMutation($taskId: String!) {
    getTaskStatus(taskId: $taskId) {
      id
      userId
      clientId
      packageId
      type
      reports {
        deviceid
        state
      }
      date
    }
  }
`;

export default (taskId, callback) => {
  const variables = {
    taskId,
  };

  commitMutation(Environment, {
    mutation,
    variables,
    onCompleted: (response) => {
      if (response && response.getTaskStatus) {
        callback(response.getTaskStatus);
      }
    },
    onError: (err) => console.error(err), // eslint-disable-line
  });
};
