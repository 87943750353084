import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import compose from "recompose/compose";
import { withSnackbar } from "material-ui-snackbar-provider";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import deleteVideoCampaignMutation from "../mutations/deleteVideoCampaignMutation";

const styles = {
  horizontalContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  button: {
    margin: "8px 8px 8px 8px",
    maxWidth: 140,
  },
};

class deleteVideoCampaign extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleDelete = this.handleDelete.bind(this);
  }

  handleDelete() {
    deleteVideoCampaignMutation(this.props.selectedCampaign.id, () => {
      this.props.handleClose();
      return this.props.snackbar.showMessage("Video Campaign deleted.");
    });
  }

  render() {
    const { classes, selectedCampaign } = this.props;
    return (
      <div>
        <Typography variant="subtitle1" id="modal-edit-title">
          Are you sure you want to delete this Campaign:
        </Typography>
        <Typography variant="h6" id="modal-edit-title">
          {`${selectedCampaign.name}`}
        </Typography>
        <Divider />
        <div className={classes.horizontalContainer}>
          <Button
            variant="contained"
            color="primary"
            onClick={this.handleDelete}
            className={classes.button}
          >
            Delete
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={this.props.handleClose}
            className={classes.button}
          >
            Cancel
          </Button>
        </div>
      </div>
    );
  }
}

deleteVideoCampaign.propTypes = {
  classes: PropTypes.object.isRequired, //eslint-disable-line
  selectedCampaign: PropTypes.object.isRequired, //eslint-disable-line
  snackbar: PropTypes.object, //eslint-disable-line
  handleClose: PropTypes.func, //eslint-disable-line
};

export default compose(withStyles(styles), withSnackbar())(deleteVideoCampaign);
