import { commitMutation } from "react-relay";
import graphql from 'babel-plugin-relay/macro';
import Environment from "../../environment";

const mutation = graphql`
  mutation editVideoCampaignMutation(
    $inputId: String!
    $input: createVideoCampaignProviderData!
  ) {
    editVideoCampaign(id: $inputId, createVideoCampaignProvider: $input) {
      id
    }
  }
`;

export default (
  id2,
  name,
  description,
  clientId,
  goLiveDate,
  deviceIds,
  profileIds,
  onHold,
  callback
) => {
  const variables = {
    inputId: id2,
    input: {
      name,
      description,
      clientId,
      goLiveDate,
      deviceIds,
      profileIds,
      onHold,
    },
  };

  commitMutation(Environment, {
    mutation,
    variables,
    onCompleted: (response) => {
      const id = response.editVideoCampaign.id;
      callback(id);
    },
    onError: (err) => console.error(err), // eslint-disable-line
  });
};
