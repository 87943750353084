/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import { QueryRenderer } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import { withStyles } from "@material-ui/core/styles";
import compose from "recompose/compose";
import { withSnackbar } from "material-ui-snackbar-provider";
import PropTypes from "prop-types";
import moment from "moment";
import * as XLSX from "xlsx";
import Drawer from "@material-ui/core/Drawer";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import TextField from "@material-ui/core/TextField";
import Radio from "@material-ui/core/Radio";
import FormLabel from "@material-ui/core/FormLabel";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import PauseIcon from "@material-ui/icons/Pause";
import ReactTable from "react-table";
import "react-table/react-table.css";
import CircularProgress from "@material-ui/core/CircularProgress";

import AudioClients from "../audio_devices/AudioClients";
import updateAudioDeviceGoLiveDateMutation from "../mutations/updateAudioDeviceGoLiveDateMutation";
import permissions2 from "../authentication/UIPermissions";
import neverOnlineImg from "../../media/neverOnline.png";
import aOnlineImg from "../../media/aonline.png";
import aOfflineImg from "../../media/aoffline.png";

import Environment from "../../environment";

const queryClient = graphql`
  query AudioReportsQuery {
    viewer {
      allClients {
        edges {
          node {
            id
            name
          }
        }
      }
      allUsers {
        edges {
          node {
            id
            profile {
              name
              clientId
            }
            emails {
              address
            }
          }
        }
      }
      allAudioDevices {
        edges {
          node {
            id
            isPlaying
            name
            appStatus {
              status
              message
              time
              description
            }
            lastSyncTime
            clientId
            appUpTime
            currentM3uName
            make
            model
            wlan
            appVersion
            volume
            uid
            description
            volume
            remoteVolume
            remoteVolumeMode
            requestPCLogs
            requestNPLogs
            lastOnline
            currentPlaylistId
            currentSongId
            currentAlbum
            currentArtist
            currentArtwork
            remotePlaylistMode
            isOnline
            currentMd5
            currentSongFilename
            serialNumber
            lan
            IP_local
            IP_public
            totalSdStorage
            usedSdStorage
            availableSdStorage
            m3uFilesOnDevice
            songFilesOnDevice
            latitude
            longitude
            skipCount
            skipDisabled
            likedSong
            scheduleIds
            usersId {
              id
              profile {
                name
                clientId
              }
              emails {
                address
              }
            }
            playlistsId
            playbackIds
            tags
            goLiveDate
            deviceStatus
            serialNumber
          }
        }
      }
    }
  }
`;

const styles = (theme) => ({
  icon: {
    fontSize: 16,
  },
  group: {
    display: "flex",
    flexDirection: "row",
  },
  formControl: {
    margin: theme.spacing(2),
  },
  formControl2: {
    margin: theme.spacing(2),
    width: "auto",
    minWidth: 150,
  },
  buttonReport: {
    marginRight: theme.spacing(1),
    alignSelf: "center",
  },
  buttonReportContainer: {
    display: "flex",
    alignSelf: "center",
    flex: "auto",
    flexDirection: "row-reverse",
    marginRight: 8,
  },
  buttonClient: {
    marginRight: theme.spacing(1),
    alignSelf: "center",
  },
  buttonClientContainer: {},
  horizontalContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  verticalContainer: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
  },
  dateContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
});

const filterColumns = [
  {
    Header: "Make",
    accessor: "make",
    maxWidth: 100,
  },
  {
    Header: "Model",
    accessor: "model",
    maxWidth: 100,
  },
  {
    Header: "Last Sync",
    accessor: "lastSyncTime",
    maxWidth: 200,
  },
  {
    Header: "Device Id",
    accessor: "id",
    maxWidth: 200,
  },
  {
    Header: "App Description",
    accessor: "appStatus.description",
    maxWidth: 100,
  },
  {
    Header: "App Message",
    accessor: "appStatus.message",
    maxWidth: 100,
  },
  {
    Header: "App Time",
    accessor: "appStatus.time",
    maxWidth: 100,
  },
  {
    Header: "App Status",
    accessor: "appStatus.status",
    maxWidth: 100,
  },
];

class AudioReports extends Component {
  constructor(props) {
    super(props);

    this.state = {
      client: {
        label: "All clients",
        value: null,
        clearableValue: false,
      },
      clientId: localStorage.getItem("audioClientId"),
      filter: [],
      selectedOption: "online",
      goLiveDateAssign: [],
    };
    this.handleOptionChange = this.handleOptionChange.bind(this);
    this.handleChangeGoLiveDate = this.handleChangeGoLiveDate.bind(this);
    this.handleChangeFilters = this.handleChangeFilters.bind(this);
    this.handleExport = this.handleExport.bind(this);
    this.toggleDrawer = this.toggleDrawer.bind(this);
    this.myCallBack = this.myCallBack.bind(this);
    this.enterPressed = this.enterPressed.bind(this);
  }

  shouldComponentUpdate() {
    return this.props.match.location.pathname === window.location.pathname;
  }

  myCallBack(data) {
    this.setState({
      clientId: data,
    });
    return data;
  }

  handleOptionChange(changeEvent) {
    this.setState({
      selectedOption: changeEvent.target.value,
    });
  }

  handleChangeGoLiveDate(data, deviceId) {
    const check = this.state.goLiveDateAssign.filter(
      (item) => item.id === deviceId
    );
    if (check[0]) {
      const newArray = this.state.goLiveDateAssign.map((item) => {
        if (item.id === deviceId) {
          return { id: deviceId, date: data.target.value };
        }
        return item;
      });
      this.setState({
        goLiveDateAssign: newArray,
      });
    } else {
      const newArray = this.state.goLiveDateAssign;
      newArray.push({
        id: deviceId,
        date: data.target.value,
      });
      this.setState({
        goLiveDateAssign: newArray,
      });
    }
  }

  handleChangeFilters(data) {
    this.setState({ filter: data.target.value });
  }

  toggleDrawer(open) {
    return this.setState({
      left: open,
    });
  }

  enterPressed(event, deviceId) {
    const code = event.keyCode || event.which;
    if (code === 13) {
      updateAudioDeviceGoLiveDateMutation(
        deviceId,
        moment(event.target.value).unix() * 1000,
        () => this.props.snackbar.showMessage("Go live date updated")
      );
    }
  }

  handleExport(data, logType, client) {
    const { filter } = this.state;
    const date = moment().format("YYYYMMDDHHMMSS");
    const newData = data.map((header) => {
      const unixLastOnline =
        header.lastOnline > 0
          ? moment(header.lastOnline).format("DD-MM-YYYY")
          : "N/A";
      const unixGoLiveDate =
        header.goLiveDate > 0
          ? moment(header.goLiveDate).format("DD-MM-YYYY")
          : "N/A";
      const standard = {
        Name: header.name,
        "Last Online": unixLastOnline,
        "Go Live Date": unixGoLiveDate,
        "IP Address": header.IP_public,
      };
      const filterObj = {};
      for (let i = 0; i < filter.length; i += 1) {
        const label = filterColumns.filter(
          (item) => item.accessor === filter[i]
        )[0].Header;
        const check = filter[i].split(".");
        if (check.length === 1) {
          Object.assign(filterObj, {
            [label]: header[filter[i]],
          });
        } else {
          Object.assign(filterObj, {
            [label]: header[check[0]] ? header[check[0]][check[1]] : "",
          });
        }
      }
      return Object.assign(standard, filterObj);
    });
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(newData);
    ws["!cols"] = [];
    const n = 4 + filter.length;
    for (let i = 0; i < n; i += 1) {
      ws["!cols"][i] = { width: 28 };
    }
    XLSX.utils.book_append_sheet(wb, ws, "LogData");
    XLSX.writeFile(wb, `${date}--${client}--${logType}.xlsx`);
  }

  render() {
    const { classes, methods } = this.props;
    const { client, filter, selectedOption, clientId, goLiveDateAssign } =
      this.state;
    return permissions2("5cac9fa53630643c2a42381b", methods) ? (
      <QueryRenderer
        variables={{}}
        environment={Environment}
        query={queryClient}
        render={({ error, props }) => {
          if (error) {
            console.log(error);
          } else if (props && props.viewer) {
            const allDevices = props.viewer.allAudioDevices.edges
              .filter((item) => item && item.node)
              .filter((item) => item.node.deviceStatus === "ACTIVE")
              .filter((item) =>
                clientId === "ALL" ? true : item.node.clientId === clientId
              )
              .map(({ node }) => node);

            const columns = [
              {
                Header: "",
                id: 1,
                width: 50,
                accessor: (d) =>
                  moment(d.lastOnline).isBefore(
                    moment().subtract("90", "second")
                  ),
                Cell: (props) => {
                  const lastOnline = moment(props.original.lastOnline);
                  const goLiveDate = moment(props.original.goLiveDate);
                  const isOffline = lastOnline.isBefore(
                    moment().subtract("90", "second")
                  );
                  const neverOnline = props.original.goLiveDate
                    ? lastOnline.isBefore(goLiveDate)
                    : false;
                  if (neverOnline) {
                    return (
                      <img
                        style={{ cursor: "pointer" }}
                        width="30"
                        height="30"
                        src={neverOnlineImg}
                        alt=""
                      />
                    );
                  }
                  return !isOffline && !!props.original.lastOnline ? (
                    <img
                      style={{ cursor: "pointer" }}
                      width="30"
                      height="30"
                      src={aOnlineImg}
                      alt=""
                    />
                  ) : (
                    <img
                      style={{ cursor: "pointer" }}
                      width="30"
                      height="30"
                      src={aOfflineImg}
                      alt=""
                    />
                  );
                },
              },
              {
                Header: "Playing",
                accessor: "isPlaying",
                width: 70,
                Cell: (props) =>
                  props.original.isPlaying ? (
                    <span style={{ cursor: "pointer" }}>
                      <PlayArrowIcon className={classes.icon} />
                    </span>
                  ) : (
                    <span style={{ cursor: "pointer" }}>
                      <PauseIcon className={classes.icon} />
                    </span>
                  ),
              },
              {
                Header: "Name",
                accessor: "name",
                minWidth: 70,
                Cell: (props) => (
                  <span style={{ cursor: "pointer" }}>
                    {props.original.name}
                  </span>
                ),
              },
              {
                id: "lastOnline",
                Header: "Last Online",
                accessor: "lastOnline",
                minWidth: 70,
                sortMethod: (a, b) => {
                  const first = a || 0;
                  const second = b || 0;
                  return first > second ? 1 : -1;
                },
                Cell: (props) => {
                  const unixTime = props.original.lastOnline;
                  const date =
                    unixTime > 0
                      ? moment(unixTime).format("DD-MM-YYYY")
                      : "N/A";
                  return <span style={{ cursor: "pointer" }}>{date}</span>;
                },
              },
              {
                id: "goLiveDate",
                Header: "Go Live Date",
                accessor: "goLiveDate",
                width: 230,
                sortMethod: (a, b) => {
                  const first = a || 0;
                  const second = b || 0;
                  return first > second ? 1 : -1;
                },
                Cell: (props) => {
                  const unixTime = props.original.goLiveDate;
                  const deviceId = props.original.id;
                  const check = goLiveDateAssign.filter(
                    (item) => item.id === deviceId
                  );
                  const date = () => {
                    if (check[0]) {
                      return check[0].date;
                    }
                    if (unixTime && unixTime > 0) {
                      return moment(unixTime).format("YYYY-MM-DDThh:mm");
                    }
                    return "";
                  };
                  return (
                    <span style={{ cursor: "pointer" }}>
                      <form className={classes.dateContainer} noValidate>
                        <TextField
                          label="Hit Enter to commit change"
                          type="datetime-local"
                          onKeyPress={(event) =>
                            date() !== ""
                              ? this.enterPressed(event, deviceId)
                              : null
                          }
                          onChange={(event) =>
                            this.handleChangeGoLiveDate(event, deviceId)
                          }
                          className={classes.textField}
                          error={date() === ""}
                          value={date()}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </form>
                    </span>
                  );
                },
              },
              {
                Header: "IP Address",
                accessor: "shipped",
                minWidth: 70,
                Cell: (props) => {
                  const exceptIPs = [
                    { ip: "184.74.207.243", name: "Activaire NY Office" },
                    { ip: "82.208.183.160", name: "Activaire BU Office" },
                  ];
                  const ip =
                    exceptIPs.filter(
                      (item) => item.ip === props.original.IP_public
                    ).length > 0
                      ? exceptIPs.filter(
                          (item) => item.ip === props.original.IP_public
                        )[0].name
                      : props.original.IP_public;
                  return <span style={{ cursor: "pointer" }}>{ip}</span>;
                },
              },
            ].concat(
              filterColumns
                .filter((item) => filter.includes(item.accessor))
                .map((item) =>
                  Object.assign({}, item, {
                    Cell: (props) => {
                      const accessor = item.accessor.split(".");
                      if (accessor.length === 2) {
                        return (
                          <span style={{ cursor: "pointer" }}>
                            {props.original[accessor[0]]
                              ? props.original[accessor[0]][accessor[1]]
                              : ""}
                          </span>
                        );
                      }
                      return (
                        <span style={{ cursor: "pointer" }}>
                          {props.original[accessor[0]]}
                        </span>
                      );
                    },
                  })
                )
            );

            const devicesOnline = allDevices.filter((item) => {
              const lastOnline = moment(item.lastOnline);
              const isOffline = lastOnline.isBefore(
                moment().subtract("90", "second")
              );
              return item.lastOnline ? !isOffline : false;
            });

            const devicesOffline = allDevices.filter((item) => {
              const lastOnline = moment(item.lastOnline);
              const goLiveDate = item.goLiveDate
                ? moment(item.goLiveDate)
                : moment();
              const neverOnline = item.goLiveDate
                ? lastOnline.isBefore(goLiveDate)
                : false;
              const isOffline = lastOnline.isBefore(
                moment().subtract("90", "second")
              );
              return item.lastOnline ? isOffline && !neverOnline : true;
            });

            const devicesNeverOnline = allDevices.filter((item) => {
              const lastOnline = moment(item.lastOnline);
              const goLiveDate = moment(item.goLiveDate);
              const neverOnline = lastOnline.isBefore(goLiveDate);
              return item.goLiveDate ? neverOnline : false;
            });

            const devicesFiltered = {
              online: devicesOnline,
              offline: devicesOffline,
              neverOnline: devicesNeverOnline,
            };

            const devices = (
              client.value
                ? devicesFiltered[selectedOption].filter(
                    (d) => d.clientId === client.value
                  )
                : devicesFiltered[selectedOption]
            ).sort((a, b) => {
              if (!a.clientId && b.clientId) return -1;
              if (!b.clientId && a.clientId) return 1;
              return 0;
            });

            const thereAreUnregisteredDevices =
              devices.filter((x) => !x.clientId).length > 0;
            const parsedColumns = thereAreUnregisteredDevices
              ? columns
              : columns.filter((x) => x.Header !== "Registered");

            const currentClient =
              clientId === "ALL"
                ? null
                : props.viewer.allClients.edges
                    .filter((item) => item && item.node)
                    .filter((item) => item.node.id === clientId)[0].node.name;

            return (
              <>
                <div className={classes.verticalContainer}>
                  <h2 style={{ marginLeft: "12px", paddingTop: "12px" }}>
                    <span>{this.props.match.route.name}</span>
                    <span style={{ fontSize: "18px", color: "grey" }}>
                      &nbsp; @{" "}
                      {clientId === "ALL"
                        ? "ALL"
                        : currentClient && currentClient}
                    </span>
                  </h2>
                  <div
                    className={classes.buttonClientContainer}
                    style={{ marginLeft: "12px" }}
                  >
                    <>
                      |&nbsp;&nbsp;
                      <span
                        style={{
                          cursor: "pointer",
                          color: "#3BABA1",
                          fontSize: 16,
                          fontWeight: "bold",
                        }}
                        onClick={() => this.toggleDrawer(true)}
                      >
                        Change Client &nbsp;
                      </span>{" "}
                      |
                    </>
                  </div>
                  <div className={classes.horizontalContainer}>
                    <FormControl
                      component="fieldset"
                      className={classes.formControl}
                    >
                      <FormLabel component="legend">
                        {`Selection(${
                          (client.value
                            ? allDevices.filter(
                                (d) => d.clientId === client.value
                              )
                            : allDevices
                          ).length
                        })`}
                      </FormLabel>
                      <RadioGroup
                        aria-label="Selection"
                        name="devices-select"
                        className={classes.group}
                        value={selectedOption}
                        onChange={this.handleOptionChange}
                      >
                        Paper
                        <FormControlLabel
                          value="online"
                          control={<Radio />}
                          label={`Online(${
                            (client.value
                              ? devicesOnline.filter(
                                  (d) => d.clientId === client.value
                                )
                              : devicesOnline
                            ).length
                          })`}
                        />
                        <FormControlLabel
                          value="offline"
                          control={<Radio />}
                          label={`Offline(${
                            (client.value
                              ? devicesOffline.filter(
                                  (d) => d.clientId === client.value
                                )
                              : devicesOffline
                            ).length
                          })`}
                        />
                        <FormControlLabel
                          value="neverOnline"
                          control={<Radio />}
                          label={`Never online(${
                            (client.value
                              ? devicesNeverOnline.filter(
                                  (d) => d.clientId === client.value
                                )
                              : devicesNeverOnline
                            ).length
                          })`}
                        />
                      </RadioGroup>
                    </FormControl>
                    <div className={classes.buttonReportContainer}>
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.buttonReport}
                        onClick={() =>
                          this.handleExport(
                            devices,
                            selectedOption,
                            client.label
                          )
                        }
                      >
                        Export Report
                      </Button>
                      <FormControl className={classes.formControl2}>
                        <InputLabel>Select your filters</InputLabel>
                        <Select
                          multiple
                          value={filter}
                          onChange={this.handleChangeFilters}
                          inputProps={{
                            name: "age",
                          }}
                        >
                          {filterColumns.map((item) => (
                            <MenuItem key={item.accessor} value={item.accessor}>
                              {item.Header}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                  <Drawer
                    open={this.state.left}
                    onClose={() => this.toggleDrawer(false)}
                  >
                    <div
                      tabIndex={0}
                      role="button"
                      onClick={() => this.toggleDrawer(false)}
                      onKeyDown={() => this.toggleDrawer(false)}
                    >
                      <div className={classes.list}>
                        <List dense>
                          <ListItem button>
                            <ListItemText
                              primary="ALL"
                              onClick={() => this.setState({ clientId: "ALL" })}
                            />
                          </ListItem>
                          <AudioClients
                            viewer={props.viewer}
                            myCallBack={this.myCallBack}
                          />
                        </List>
                      </div>
                    </div>
                  </Drawer>
                  <ReactTable
                    loading={!allDevices.length}
                    className="-striped -highlight"
                    data={devices}
                    filterable
                    defaultFilterMethod={(filters, row) =>
                      String(row[filters.id])
                        .toLowerCase()
                        .includes(filters.value.toLowerCase())
                    }
                    columns={parsedColumns}
                    style={{
                      display: "block",
                      margin: "8px 0px 8px 8px",
                      height: "800px",
                      width: "100%",
                    }}
                  />
                </div>
              </>
            );
          }
          return (
            <div
              style={{
                zIndex: "-1",
                position: "absolute",
                top: "50%",
                left: "50%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography
                variant="h4"
                color="#fafafa"
                align="center"
                style={{ color: "#3BABA1" }}
              >
                Fetching data...
              </Typography>
              <CircularProgress
                size={70}
                style={{
                  color: "#3BABA1",
                  marginTop: "40px",
                  alignSelf: "center",
                }}
              />
            </div>
          );
        }}
      />
    ) : null;
  }
}

AudioReports.propTypes = {
  classes: PropTypes.object.isRequired,
  snackbar: PropTypes.object,
};

export default compose(withStyles(styles), withSnackbar())(AudioReports);
