/**
 * @flow
 * @relayHash b36ddbaf2e2c6f59a013f4cc78b98388
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type _ModelMutationType = "CREATED" | "DELETED" | "UPDATED" | "UPDATEDCLIENT" | "%future added value";
export type UserSubscriptionVariables = {|
  token: string,
  updatedId?: ?string,
  updatedFields?: ?$ReadOnlyArray<?string>,
|};
export type UserSubscriptionResponse = {|
  +UserSubscription: {|
    +mutation: _ModelMutationType,
    +node: ?{|
      +id: string,
      +createdAt: ?string,
      +roles: ?$ReadOnlyArray<?string>,
      +services: ?{|
        +password: ?{|
          +bcrypt: ?string
        |},
        +google: ?{|
          +accessToken: ?string,
          +idToken: ?string,
          +id: ?string,
          +email: ?string,
          +name: ?string,
          +given_name: ?string,
          +family_name: ?string,
          +picture: ?string,
        |},
        +facebook: ?{|
          +accessToken: ?string,
          +id: ?string,
          +email: ?string,
          +name: ?string,
          +first_name: ?string,
          +last_name: ?string,
          +link: ?string,
          +gender: ?string,
          +locale: ?string,
          +verified: ?boolean,
        |},
      |},
      +emails: ?$ReadOnlyArray<?{|
        +address: ?string,
        +verified: ?boolean,
      |}>,
      +profile: ?{|
        +name: ?string,
        +clientId: ?string,
        +videoClientId: ?string,
        +sonosPermit: ?boolean,
        +qsysPermit: ?boolean,
      |},
      +rolesConsole: ?{|
        +roleAccess: ?number,
        +roleAudio: ?number,
        +roleVideo: ?number,
      |},
      +status: ?{|
        +online: boolean,
        +lastLogin: ?{|
          +date: ?any,
          +ipAddr: ?string,
        |},
      |},
      +qsys: ?{|
        +pin: ?string,
        +multiTenant: ?boolean,
        +globalZoneLimit: ?number,
        +hideVolumeBar: ?boolean,
      |},
      +sonos: ?{|
        +hideVolumeBar: ?boolean
      |},
      +apiKey: ?string,
      +companyName: ?string,
      +serviceType: ?$ReadOnlyArray<?string>,
      +defaultServiceType: ?string,
    |},
  |}
|};
export type UserSubscription = {|
  variables: UserSubscriptionVariables,
  response: UserSubscriptionResponse,
|};
*/


/*
subscription UserSubscription(
  $token: String!
  $updatedId: ID
  $updatedFields: [String]
) {
  UserSubscription(token: $token, updatedId: $updatedId, updatedFields: $updatedFields) {
    mutation
    node {
      id
      createdAt
      roles
      services {
        password {
          bcrypt
        }
        google {
          accessToken
          idToken
          id
          email
          name
          given_name
          family_name
          picture
        }
        facebook {
          accessToken
          id
          email
          name
          first_name
          last_name
          link
          gender
          locale
          verified
        }
      }
      emails {
        address
        verified
      }
      profile {
        name
        clientId
        videoClientId
        sonosPermit
        qsysPermit
      }
      rolesConsole {
        roleAccess
        roleAudio
        roleVideo
      }
      status {
        online
        lastLogin {
          date
          ipAddr
        }
      }
      qsys {
        pin
        multiTenant
        globalZoneLimit
        hideVolumeBar
      }
      sonos {
        hideVolumeBar
      }
      apiKey
      companyName
      serviceType
      defaultServiceType
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "token"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "updatedFields"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "updatedId"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "accessToken",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "verified",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hideVolumeBar",
  "storageKey": null
},
v9 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "token",
        "variableName": "token"
      },
      {
        "kind": "Variable",
        "name": "updatedFields",
        "variableName": "updatedFields"
      },
      {
        "kind": "Variable",
        "name": "updatedId",
        "variableName": "updatedId"
      }
    ],
    "concreteType": "UserSubscriptionPayload",
    "kind": "LinkedField",
    "name": "UserSubscription",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "mutation",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createdAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "roles",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UserServices",
            "kind": "LinkedField",
            "name": "services",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Password",
                "kind": "LinkedField",
                "name": "password",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "bcrypt",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Google",
                "kind": "LinkedField",
                "name": "google",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "idToken",
                    "storageKey": null
                  },
                  (v3/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "given_name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "family_name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "picture",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Facebook",
                "kind": "LinkedField",
                "name": "facebook",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v3/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "first_name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "last_name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "link",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "gender",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "locale",
                    "storageKey": null
                  },
                  (v7/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UserEmails",
            "kind": "LinkedField",
            "name": "emails",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "address",
                "storageKey": null
              },
              (v7/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UserProfile",
            "kind": "LinkedField",
            "name": "profile",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "clientId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "videoClientId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "sonosPermit",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "qsysPermit",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "RolesConsole",
            "kind": "LinkedField",
            "name": "rolesConsole",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "roleAccess",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "roleAudio",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "roleVideo",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Status",
            "kind": "LinkedField",
            "name": "status",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "online",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "LastLogin",
                "kind": "LinkedField",
                "name": "lastLogin",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "date",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "ipAddr",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "QsysObject",
            "kind": "LinkedField",
            "name": "qsys",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "pin",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "multiTenant",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "globalZoneLimit",
                "storageKey": null
              },
              (v8/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "SonosObject",
            "kind": "LinkedField",
            "name": "sonos",
            "plural": false,
            "selections": [
              (v8/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "apiKey",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "companyName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "serviceType",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "defaultServiceType",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "UserSubscription",
    "selections": (v9/*: any*/),
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "UserSubscription",
    "selections": (v9/*: any*/)
  },
  "params": {
    "id": "b36ddbaf2e2c6f59a013f4cc78b98388",
    "metadata": {},
    "name": "UserSubscription",
    "operationKind": "subscription",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'acc45e9ebe8378548413c934738cfec9';

module.exports = node;
