import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import TextField from "@material-ui/core/TextField";
import editQsysZoneNameMutation from "../../mutations/editQsysZoneNameMutation";

const styles = (theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dialogContent: {
    padding: theme.spacing(2), // This adds padding of 16px (8px * 2)
  },
});
const DeviceControlZoneItemEdit = (props) => {
  const { classes, open, deviceId, zone: initialZone, handleClose } = props;
  const [zone, setZone] = React.useState(initialZone || {});

  // Reset zone state when modal opens with new zone
  React.useEffect(() => {
    if (open && initialZone) {
      setZone(initialZone);
    }
  }, [open, initialZone]);

  const handleSave = () => {
    if (zone.zoneName) {
      editQsysZoneNameMutation(deviceId, zone.zoneId, zone.zoneName, () => {
        handleClose(false); // Pass false to explicitly close
      });
    }
  };

  return (
    <Dialog
      open={Boolean(open)}
      onClose={() => handleClose(false)} // Pass false to explicitly close
      aria-labelledby="responsive-dialog-title"
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle id="max-width-dialog-title">
        Edit zone name
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={() => handleClose(false)} // Pass false to explicitly close
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent className={classes.dialogContent}>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Zone Name"
          type="text"
          fullWidth
          value={zone.zoneName || ""}
          onChange={(event) => {
            setZone({ ...zone, zoneName: event.target.value });
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSave} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(DeviceControlZoneItemEdit);
