import { commitMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import Environment from "../../environment";

const mutation = graphql`
  mutation editGenreQsysRouteFolderOrderMutation(
    $menuId: String!
    $pathId: Int!
    $sourceIndex: Int!
    $destinationIndex: Int!
  ) {
    editGenreQsysRouteFolderOrder(
      menuId: $menuId
      pathId: $pathId
      sourceIndex: $sourceIndex
      destinationIndex: $destinationIndex
    ) {
      __typename
      id
      name
      description
      schema {
        name
        pathId
        parentId
        coverName
        cover_40
        cover_290
        cover_legacy
        playlistIds
        sceneIds
        autoPopulateType
        category
        subCategory
        genres
        routeItems
        playableType
      }
      active
      dateCreated
      dateModified
      clientId
    }
  }
`;

export default (menuId, pathId, sourceIndex, destinationIndex, callback) => {
  const variables = {
    menuId,
    pathId,
    sourceIndex,
    destinationIndex,
  };

  commitMutation(Environment, {
    mutation,
    variables,
    onCompleted: (response) => {
      callback(response?.editGenreQsysRouteFolderOrder);
    },
    onError: (err) => console.error(err), // eslint-disable-line
  });
};
