import { commitMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import Environment from "../../environment";

const mutation = graphql`
  mutation updateUserRoleAndClientMutation(
    $inputUserId: String!
    $inputRoleIds: [Int!]
    $inputClientId: String!
    $inputVideoClientId: String!
    $serviceType: [String!]
    $defaultServiceType: String
  ) {
    updateUserRoleAndClient(
      userId: $inputUserId
      roleIds: $inputRoleIds
      clientId: $inputClientId
      videoClientId: $inputVideoClientId
      serviceType: $serviceType
      defaultServiceType: $defaultServiceType
    ) {
      id
      roles
      createdAt
      apiKey
      services {
        password {
          bcrypt
        }
        google {
          accessToken
          idToken
          id
          email
          name
          given_name
          family_name
          picture
        }
        facebook {
          accessToken
          id
          email
          name
          first_name
          last_name
          link
          gender
          locale
          verified
        }
      }
      emails {
        address
        verified
      }
      profile {
        name
        clientId
        videoClientId
      }
      rolesConsole {
        roleAccess
        roleAudio
        roleVideo
      }
      status {
        online
        lastLogin {
          date
          ipAddr
        }
      }
      companyName
      serviceType
      defaultServiceType
    }
  }
`;

export default (
  userId,
  roleIds,
  clientId,
  videoClientId,
  serviceType,
  defaultServiceType,
  callback
) => {
  const variables = {
    inputUserId: userId,
    inputRoleIds: roleIds,
    inputClientId: clientId,
    inputVideoClientId: videoClientId,
    serviceType,
    defaultServiceType,
  };

  commitMutation(Environment, {
    mutation,
    variables,
    onCompleted: (data) => {
      callback(data.updateUserRoleAndClient);
    },
    onError: (err) => console.error(err), // eslint-disable-line
  });
};
