/**
 * @flow
 * @relayHash 7d2dde568396b1ff8e72d4cbaab6a248
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AuthProviderSignupData = {|
  email?: ?AUTH_PROVIDER_EMAIL
|};
export type AUTH_PROVIDER_EMAIL = {|
  email: string,
  password: string,
|};
export type createUserMutationVariables = {|
  inputname: string,
  input: AuthProviderSignupData,
|};
export type createUserMutationResponse = {|
  +createUser: ?{|
    +id: string
  |}
|};
export type createUserMutation = {|
  variables: createUserMutationVariables,
  response: createUserMutationResponse,
|};
*/


/*
mutation createUserMutation(
  $inputname: String!
  $input: AuthProviderSignupData!
) {
  createUser(name: $inputname, authProvider: $input) {
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "inputname"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "authProvider",
        "variableName": "input"
      },
      {
        "kind": "Variable",
        "name": "name",
        "variableName": "inputname"
      }
    ],
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "createUser",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "createUserMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "createUserMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "id": "7d2dde568396b1ff8e72d4cbaab6a248",
    "metadata": {},
    "name": "createUserMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '0508052743aa72f03dd9c457a129551e';

module.exports = node;
