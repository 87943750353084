const LOCAL_IP = "127.0.0.1";
const PUBLIC_IP = "console.activaire.com";
const PROD = process.env.NODE_ENV === "production";
const SUBSCRIPTION_PATH = PROD
  ? `wss://${PUBLIC_IP}/ws`
  : `ws://${LOCAL_IP}:8080/graphql`;
const URL_PATH = PROD
  ? `https://${PUBLIC_IP}/graphql`
  : `http://${LOCAL_IP}:8080/graphql`;
const UPLOAD_PATH = `http://${LOCAL_IP}:8080/graphql`;
const URL_PATH_LOGIN = PROD
  ? `https://${PUBLIC_IP}/api/login`
  : `http://${LOCAL_IP}:8080/login`;
const AUTH_TOKEN = localStorage.getItem("authentication_token");
const USER_ID = localStorage.getItem("user_id");
const GOOGLE_CLIENT_ID =
  "266267900441-b1d8a3p1osuv1rdb2ngf22qhesg83b16.apps.googleusercontent.com";
const GOOGLE_MAPS_KEY = "AIzaSyBpyi0M4nHvNEasCCwBK_gG4xBHXHU2W6I";
const FACEBOOK_CLIENT_ID = "1818197905075490";
const VIDEO_FORMATS = [
  "1L",
  "1P",
  "1X2L",
  "1X2P",
  "1X3L",
  "1X3P",
  "2X2L",
  "2X2P",
  "2X3L",
  "2X3P",
  "3X2L",
  "3X2P",
  "3X3L",
  "3X3P",
  "LED",
  "OTHER",
];
const WEBKEY_URL = "https://dashboard.activaire.com/";
const WEBKEY_URL_BACKUP = "https://recoveryservice.activaire.com/";

export default {
  SUBSCRIPTION_PATH,
  URL_PATH,
  URL_PATH_LOGIN,
  AUTH_TOKEN,
  USER_ID,
  GOOGLE_CLIENT_ID,
  GOOGLE_MAPS_KEY,
  FACEBOOK_CLIENT_ID,
  UPLOAD_PATH,
  VIDEO_FORMATS,
  WEBKEY_URL,
  WEBKEY_URL_BACKUP,
};
