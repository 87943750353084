import React from "react";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import { withStyles } from "@material-ui/core/styles";
import editQsysDeviceNoOfZonesMutation from "../../mutations/editQsysDeviceNoOfZonesMutation";

const styles = (theme) => ({
  dialog: {
    background: "secondary",
    margin: theme.spacing(3),
  },
  textField: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    width: 100,
  },
  closeIcon: {
    height: 24,
    color: "#000000",
    width: 24,
  },
  close: {
    position: "relative",
    direction: "rtl",
    marginTop: 6,
  },
  verticalContainer: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  dialogTitle: {
    textAlign: "center",
  },
});

const ZoneCapModal = (props) => {
  const { classes, open, close, device } = props;
  const [noOfZones, setNoOfZones] = React.useState(device?.noOfZones || 0);
  return (
    <Dialog open={open} className={classes.dialog}>
      <div className={classes.close}>
        <IconButton aria-label="Close" className={classes.close}>
          <CloseIcon className={classes.closeIcon} onClick={() => close()} />
        </IconButton>
      </div>
      <DialogTitle className={classes.dialogTitle}>
        <p style={{ fontSize: 18 }}>
          Edit maximum number of zones on device (maximum of 128)
        </p>
      </DialogTitle>
      <div className={classes.verticalContainer}>
        <TextField
          className={classes.textField}
          id="no-of-zones"
          label="Add zone cap"
          type="number"
          InputLabelProps={{
            shrink: true,
          }}
          value={noOfZones}
          onChange={(event) => {
            let value = event.target.value <= 128 ? event.target.value : 128;
            value = value >= 0 ? value : 0;
            setNoOfZones(value);
          }}
        />
      </div>
      <div className={classes.dialog}>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => close()}
          className={classes.button}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() =>
            editQsysDeviceNoOfZonesMutation(device.id, Number(noOfZones), () =>
              close()
            )
          }
          className={classes.button}
        >
          Confirm Change
        </Button>
      </div>
    </Dialog>
  );
};

export default withStyles(styles)(ZoneCapModal);
