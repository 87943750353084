/**
 * @flow
 * @relayHash 4e98670bd2aeb90b553e924800fa3166
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type typeRoleEnum = "access" | "audio" | "video" | "%future added value";
export type PermissionsQueryVariables = {||};
export type PermissionsQueryResponse = {|
  +viewer: {|
    +allMethods: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: {|
          +id: string,
          +displayName: string,
          +methodName: string,
          +description: ?string,
          +UIElementsID: $ReadOnlyArray<?string>,
          +roleType: typeRoleEnum,
          +userType: $ReadOnlyArray<?number>,
        |}
      |}>
    |},
    +allUIElements: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: {|
          +id: string,
          +name: string,
          +location: ?string,
          +description: ?string,
          +route: string,
        |}
      |}>
    |},
  |}
|};
export type PermissionsQuery = {|
  variables: PermissionsQueryVariables,
  response: PermissionsQueryResponse,
|};
*/


/*
query PermissionsQuery {
  viewer {
    allMethods {
      edges {
        node {
          id
          displayName
          methodName
          description
          UIElementsID
          roleType
          userType
        }
      }
    }
    allUIElements {
      edges {
        node {
          id
          name
          location
          description
          route
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Viewer",
    "kind": "LinkedField",
    "name": "viewer",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "MethodConnection",
        "kind": "LinkedField",
        "name": "allMethods",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "MethodEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Method",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "displayName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "methodName",
                    "storageKey": null
                  },
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "UIElementsID",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "roleType",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "userType",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UIElementConnection",
        "kind": "LinkedField",
        "name": "allUIElements",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UIElementEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UIElement",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "location",
                    "storageKey": null
                  },
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "route",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "PermissionsQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "PermissionsQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "id": "4e98670bd2aeb90b553e924800fa3166",
    "metadata": {},
    "name": "PermissionsQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '65b107fcb4cf43467a5e2a197b87afad';

module.exports = node;
