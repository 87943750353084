import { commitMutation } from "react-relay";
import graphql from 'babel-plugin-relay/macro';
import Environment from "../../environment";

const mutation = graphql`
  mutation editUsernameMutation($userId: String!, $username: String!) {
    editUsername(userId: $userId, username: $username) {
      id
      profile {
        name
      }
    }
  }
`;

export default (userId, username, callback) => {
  const variables = {
    userId,
    username,
  };

  commitMutation(Environment, {
    mutation,
    variables,
    onCompleted: () => {
      callback();
    },
    onError: (err) => console.error(err), // eslint-disable-line
  });
};
