/**
 * @flow
 * @relayHash 87c47a028f18a206ee649e131559a956
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type _deviceStatusType = "ACTIVE" | "INACTIVE" | "REGISTERED" | "UNREGISTERED" | "%future added value";
export type VideoDevicesQueryVariables = {||};
export type VideoDevicesQueryResponse = {|
  +viewer: {|
    +allTags: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: {|
          +id: string,
          +name: string,
          +description: ?string,
        |}
      |}>
    |},
    +allVideoClients: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: {|
          +id: string,
          +name: string,
        |}
      |}>
    |},
    +allVideoDevices: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: {|
          +id: string,
          +name: ?string,
          +uid: ?string,
          +description: ?string,
          +macAddress: ?string,
          +currentlyPlaying: ?string,
          +volume: ?number,
          +lastSync: ?string,
          +lanMacAddress: ?string,
          +internalIp: ?string,
          +publicIp: ?string,
          +lastOnline: ?number,
          +appVersion: ?string,
          +reboot: ?boolean,
          +takeScreenShot: ?boolean,
          +sync: ?boolean,
          +format: ?string,
          +uploadLogs: ?boolean,
          +model: ?string,
          +serialNumber: ?string,
          +usedStorage: ?string,
          +totalStorage: ?string,
          +availableStorage: ?string,
          +hdmiStatus: ?boolean,
          +downloading: ?string,
          +appUpTime: ?string,
          +isPlaying: ?boolean,
          +clientId: ?string,
          +tags: ?$ReadOnlyArray<?string>,
          +profile: ?{|
            +profileId: ?string,
            +name: ?string,
            +format: ?string,
            +volume: ?number,
            +url: ?string,
            +syncTime: ?string,
            +rebootTime: ?string,
            +clientId: ?string,
          |},
          +deviceStatus: ?_deviceStatusType,
        |}
      |}>
    |},
    +allVideoProfiles: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: {|
          +id: string,
          +name: string,
          +format: string,
          +volume: ?number,
          +url: ?string,
          +syncTime: ?string,
          +rebootTime: ?string,
          +clientId: ?string,
        |}
      |}>
    |},
    +user: {|
      +id: string,
      +consoleSettings: ?{|
        +videoDevices: ?{|
          +appDetails: ?boolean,
          +deviceActions: ?boolean,
          +systemDetailsControls: ?boolean,
          +tags: ?boolean,
        |}
      |},
    |},
  |}
|};
export type VideoDevicesQuery = {|
  variables: VideoDevicesQueryVariables,
  response: VideoDevicesQueryResponse,
|};
*/


/*
query VideoDevicesQuery {
  viewer {
    allTags {
      edges {
        node {
          id
          name
          description
        }
      }
    }
    allVideoClients {
      edges {
        node {
          id
          name
        }
      }
    }
    allVideoDevices {
      edges {
        node {
          id
          name
          uid
          description
          macAddress
          currentlyPlaying
          volume
          lastSync
          lanMacAddress
          internalIp
          publicIp
          lastOnline
          appVersion
          reboot
          takeScreenShot
          sync
          format
          uploadLogs
          model
          serialNumber
          usedStorage
          totalStorage
          availableStorage
          hdmiStatus
          downloading
          appUpTime
          isPlaying
          clientId
          tags
          profile {
            profileId
            name
            format
            volume
            url
            syncTime
            rebootTime
            clientId
          }
          deviceStatus
        }
      }
    }
    allVideoProfiles {
      edges {
        node {
          id
          name
          format
          volume
          url
          syncTime
          rebootTime
          clientId
        }
      }
    }
    user {
      id
      consoleSettings {
        videoDevices {
          appDetails
          deviceActions
          systemDetailsControls
          tags
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "volume",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "format",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "clientId",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tags",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "syncTime",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rebootTime",
  "storageKey": null
},
v10 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Viewer",
    "kind": "LinkedField",
    "name": "viewer",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "TagConnection",
        "kind": "LinkedField",
        "name": "allTags",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TagEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Tag",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  (v1/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "VideoClientConnection",
        "kind": "LinkedField",
        "name": "allVideoClients",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "VideoClientEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "VideoClient",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  (v1/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "VideoDeviceConnection",
        "kind": "LinkedField",
        "name": "allVideoDevices",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "VideoDeviceEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "VideoDevice",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "uid",
                    "storageKey": null
                  },
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "macAddress",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "currentlyPlaying",
                    "storageKey": null
                  },
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lastSync",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lanMacAddress",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "internalIp",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "publicIp",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lastOnline",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "appVersion",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "reboot",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "takeScreenShot",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "sync",
                    "storageKey": null
                  },
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "uploadLogs",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "model",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "serialNumber",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "usedStorage",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totalStorage",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "availableStorage",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hdmiStatus",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "downloading",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "appUpTime",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isPlaying",
                    "storageKey": null
                  },
                  (v5/*: any*/),
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProfileDetails",
                    "kind": "LinkedField",
                    "name": "profile",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "profileId",
                        "storageKey": null
                      },
                      (v1/*: any*/),
                      (v4/*: any*/),
                      (v3/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/),
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "deviceStatus",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "VideoProfileConnection",
        "kind": "LinkedField",
        "name": "allVideoProfiles",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "VideoProfileEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "VideoProfile",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  (v1/*: any*/),
                  (v4/*: any*/),
                  (v3/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ConsoleSettings",
            "kind": "LinkedField",
            "name": "consoleSettings",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "VideoDeviceSettings",
                "kind": "LinkedField",
                "name": "videoDevices",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "appDetails",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "deviceActions",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "systemDetailsControls",
                    "storageKey": null
                  },
                  (v6/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "VideoDevicesQuery",
    "selections": (v10/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "VideoDevicesQuery",
    "selections": (v10/*: any*/)
  },
  "params": {
    "id": "87c47a028f18a206ee649e131559a956",
    "metadata": {},
    "name": "VideoDevicesQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f11dad1dbe64ce22dc543e17e1cab737';

module.exports = node;
