/**
 * @flow
 * @relayHash e111dda3b26c17229a2316ccd92ae831
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type allSonosMenusQueryVariables = {|
  clientId?: ?string
|};
export type allSonosMenusQueryResponse = {|
  +viewer: {|
    +allSonosMenu: ?$ReadOnlyArray<?{|
      +id: string,
      +name: ?string,
      +description: ?string,
      +active: ?boolean,
      +clientId: ?string,
      +parentMenuId: ?string,
      +explicit: ?boolean,
      +childrenMenus: ?$ReadOnlyArray<?{|
        +id: string,
        +name: ?string,
        +description: ?string,
        +parentMenuId: ?string,
        +explicit: ?boolean,
      |}>,
    |}>,
    +allPlaylists: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: {|
          +__typename: string,
          +id: string,
          +name: string,
          +description: ?string,
          +cover: ?string,
          +genre: ?$ReadOnlyArray<?string>,
          +explicit: ?boolean,
          +category: ?$ReadOnlyArray<?{|
            +category: ?string,
            +subCategories: ?$ReadOnlyArray<?string>,
          |}>,
        |}
      |}>
    |},
    +allScenes: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: {|
          +__typename: string,
          +id: string,
          +name: string,
          +description: ?string,
          +image: ?string,
          +category: ?string,
          +subCategory: ?string,
          +genres: ?$ReadOnlyArray<?string>,
          +explicit: ?boolean,
          +newCategory: ?$ReadOnlyArray<?{|
            +category: ?string,
            +subCategories: ?$ReadOnlyArray<?string>,
          |}>,
        |}
      |}>
    |},
  |}
|};
export type allSonosMenusQuery = {|
  variables: allSonosMenusQueryVariables,
  response: allSonosMenusQueryResponse,
|};
*/


/*
query allSonosMenusQuery(
  $clientId: String
) {
  viewer {
    allSonosMenu {
      id
      name
      description
      active
      clientId
      parentMenuId
      explicit
      childrenMenus {
        id
        name
        description
        parentMenuId
        explicit
      }
    }
    allPlaylists(clientId: $clientId) {
      edges {
        node {
          __typename
          id
          name
          description
          cover
          genre
          explicit
          category {
            category
            subCategories
          }
        }
      }
    }
    allScenes(clientId: $clientId) {
      edges {
        node {
          __typename
          id
          name
          description
          image
          category
          subCategory
          genres
          explicit
          newCategory {
            category
            subCategories
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "clientId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "parentMenuId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "explicit",
  "storageKey": null
},
v6 = [
  {
    "kind": "Variable",
    "name": "clientId",
    "variableName": "clientId"
  }
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "category",
  "storageKey": null
},
v9 = [
  (v8/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "subCategories",
    "storageKey": null
  }
],
v10 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Viewer",
    "kind": "LinkedField",
    "name": "viewer",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "SonosMenu",
        "kind": "LinkedField",
        "name": "allSonosMenu",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "active",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "clientId",
            "storageKey": null
          },
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "SonosMenu",
            "kind": "LinkedField",
            "name": "childrenMenus",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v6/*: any*/),
        "concreteType": "PlaylistConnection",
        "kind": "LinkedField",
        "name": "allPlaylists",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PlaylistEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Playlist",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v1/*: any*/),
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cover",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "genre",
                    "storageKey": null
                  },
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SceneCategory",
                    "kind": "LinkedField",
                    "name": "category",
                    "plural": true,
                    "selections": (v9/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v6/*: any*/),
        "concreteType": "SceneConnection",
        "kind": "LinkedField",
        "name": "allScenes",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SceneEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Scene",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v1/*: any*/),
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "image",
                    "storageKey": null
                  },
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "subCategory",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "genres",
                    "storageKey": null
                  },
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SceneCategory",
                    "kind": "LinkedField",
                    "name": "newCategory",
                    "plural": true,
                    "selections": (v9/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "allSonosMenusQuery",
    "selections": (v10/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "allSonosMenusQuery",
    "selections": (v10/*: any*/)
  },
  "params": {
    "id": "e111dda3b26c17229a2316ccd92ae831",
    "metadata": {},
    "name": "allSonosMenusQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd4e45360663407438169670d066f79dd';

module.exports = node;
