import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import Paper from "@material-ui/core/Paper";

const styles = {
  root: {},
  chip: {
    marginLeft: "12px",
  },
};

class VideoDevicesFilter extends React.Component {
  constructor(props) {
    super();
    this.state = {
      chipData: props.addedColumns,
    };
    this.handleDelete = this.handleDelete.bind(this);
  }

  handleDelete(data, index) {
    const chipData = [...this.state.chipData];
    const chipToDelete = chipData.indexOf(data);
    chipData.splice(chipToDelete, 1);
    this.props.callback(data, index);
  }

  render() {
    const { classes, addedColumns } = this.props;
    const { chipData } = this.state;
    return (
      <Paper className={classes.root} elevation={0}>
        {addedColumns
          ? chipData.map((data, index) => {
              const avatar = null;
              return (
                <Chip
                  key={data.Header}
                  avatar={avatar}
                  label={data.Header}
                  onDelete={() => this.handleDelete(data, index)}
                  className={classes.chip}
                />
              );
            })
          : null}
      </Paper>
    );
  }
}

VideoDevicesFilter.propTypes = {
  classes: PropTypes.object.isRequired, //eslint-disable-line
  addedColumns: PropTypes.array, //eslint-disable-line
  callback: PropTypes.func, //eslint-disable-line
};

export default withStyles(styles)(VideoDevicesFilter);
