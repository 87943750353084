import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import compose from "recompose/compose";
import { withSnackbar } from "material-ui-snackbar-provider";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import deleteVideoDeviceMutation from "../../mutations/deleteVideoDeviceMutation";

const styles = {
  horizontalContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  button: {
    margin: "8px 8px 8px 8px",
    maxWidth: 140,
  },
};

class DeleteDevice extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleDelete = this.handleDelete.bind(this);
  }

  handleDelete() {
    deleteVideoDeviceMutation(this.props.deviceId, "INACTIVE", () => {
      this.props.handleClose();
      return this.props.snackbar.showMessage("Video Device blacklisted.");
    });
  }

  render() {
    const { classes, deviceName } = this.props;
    return (
      <div>
        <Typography variant="subtitle1" id="modal-edit-title">
          Are you sure you want to blacklist this Device:
        </Typography>
        <Typography variant="h6" id="modal-edit-title">
          {deviceName}
        </Typography>
        <Divider />
        <div className={classes.horizontalContainer}>
          <Button
            variant="contained"
            color="primary"
            onClick={this.handleDelete}
            className={classes.button}
          >
            Yes
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={this.props.handleClose}
            className={classes.button}
          >
            Cancel
          </Button>
        </div>
      </div>
    );
  }
}

DeleteDevice.propTypes = {
  classes: PropTypes.object.isRequired, //eslint-disable-line
  deviceId: PropTypes.string.isRequired,
  deviceName: PropTypes.string.isRequired,
  snackbar: PropTypes.object, //eslint-disable-line
  handleClose: PropTypes.func, //eslint-disable-line
};

export default compose(withStyles(styles), withSnackbar())(DeleteDevice);
