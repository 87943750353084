import { commitMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import Environment from "../../environment";

const mutation = graphql`
  mutation customActionDeviceMutation(
    $devices: [String]!
    $customAction: String!
    $serverInstance: ServerInstance!
  ) {
    customActionDevice(
      devices: $devices
      customAction: $customAction
      serverInstance: $serverInstance
    ) {
      id
      userId
      clientId
      type
      packageId
      reports {
        deviceid
        state
      }
      date
    }
  }
`;

export default (devices, customAction, serverInstance, callback) => {
  const variables = {
    devices,
    customAction,
    serverInstance,
  };

  commitMutation(Environment, {
    mutation,
    variables,
    onCompleted: (response) => {
      if (response && response.customActionDevice) {
        callback(response.customActionDevice);
      }
    },
    onError: (err) => console.error(err), // eslint-disable-line
  });
};
