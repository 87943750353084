import React from "react";
import PropTypes from "prop-types";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

import { withStyles } from "@material-ui/core/styles";
import compose from "recompose/compose";
import { withSnackbar } from "material-ui-snackbar-provider";

import customActionDeviceMutation from "../mutations/customActionDeviceMutation";

const styles = (theme) => ({
  root: {},
  horizontalContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  verticalContainer: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  button: {
    width: "fit-content",
    margin: "8px 0px 8px 8px",
  },
  typo: {
    width: "fit-content",
    margin: 8,
  },
});

class BatchInstallModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      searchField: "",
      customAction: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.selectItem = this.selectItem.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.triggerCustomAction = this.triggerCustomAction.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state !== nextState) {
      return true;
    }
    return false;
  }

  handleChange(name, event) {
    this.setState({ [name]: event.target.value.toLowerCase() });
  }

  selectItem(item) {
    this.setState({
      open: true,
      customAction: item,
    });
  }

  handleClose() {
    this.setState({ open: false });
  }

  triggerCustomAction() {
    console.log();
    customActionDeviceMutation(
      this.props.webkeyIds,
      this.state.customAction.actionCode,
      this.props.instance,
      (response) => {
        return this.props.snackbar.showMessage(
          "Custom action executed successfully"
        );
      }
    );
    this.setState({ open: false });
    this.props.callback();
  }

  render() {
    const { classes, actions, webkeyIds } = this.props;
    const { searchField, customAction, open } = this.state;

    return (
      <div className={classes.root}>
        <DialogContent className={classes.verticalContainer}>
          <Dialog
            onClose={() => this.setState({ open: false })}
            aria-labelledby="Action Run"
            open={open}
          >
            <Typography className={classes.typo} align="left">
              {`Are you sure you want to run action ${customAction.name} on ${webkeyIds.length} devices?`}
            </Typography>
            <div className={classes.horizontalContainer}>
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={() => this.triggerCustomAction()}
              >
                Yes
              </Button>
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={this.handleClose}
              >
                No
              </Button>
            </div>
          </Dialog>
          <TextField
            id="device-search-field"
            label="Search"
            className={classes.textField}
            value={searchField}
            onChange={(event) => this.handleChange("searchField", event)}
            margin="normal"
          />
          <List dense>
            {actions
              .filter(
                (item) =>
                  (item.name &&
                    item.name.toLowerCase().includes(searchField)) ||
                  (item.actionCode &&
                    item.actionCode.toLowerCase().includes(searchField))
              )
              .map((item) => (
                <ListItem key={item.actionCode}>
                  <ListItemText
                    primary={item.name}
                    secondary={`actionCode:\u00A0${item.actionCode}`}
                  />
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="Run action"
                      onClick={() => this.selectItem(item)}
                    >
                      <ArrowForwardIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
          </List>
        </DialogContent>
      </div>
    );
  }
}

BatchInstallModal.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(withStyles(styles), withSnackbar())(BatchInstallModal);
