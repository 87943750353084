import React from "react";
import { createBrowserRouter, makeRouteConfig, Route, Redirect } from "found";
import jwt from "jsonwebtoken";
import AppPage from "./AppPage";
import Drawer from "./miniDrawer";
import LoginForm from "./authentication/loginform";
import VerifyEmail from "./authentication/verifyEmail";
import Register from "./authentication/register";
import ForgotPass from "./authentication/forgotpass";
import ResetPass from "./authentication/resetPass";
import Dashboard from "./dashboard/dashboard";
import AudioDevices from "./audio_devices/AudioDevices";
import AudioDeviceRegistration from "./audio_devices_registration/AudioDeviceRegistration";
import AudioReports from "./audio_reports/AudioReports";
import SongReporting from "./song_reporting/SongReporting";
import VideoDevices from "./video_devices/VideoDevices";
import VideoDevicesInactive from "./video_devices_inactive/VideoDevicesInactive";
import VideoProfiles from "./video_profiles/VideoProfiles";
import PlaybackTabsQuery from "./playbacks/PlaybackTabsQuery";
import Users from "./users/Users";
import Tags from "./tags/Tags";
import VideoCampaigns from "./video_campaigns/VideoCampaigns";
import Events from "./events/Events";
import Permissions from "./permissions/Permissions";
import DeviceLogs from "./deviceLogs/DeviceLogs";
import Sonos from "./sonosAndQsys/pages/Sonos";
import Qsys from "./sonosAndQsys/pages/Qsys";
import WebkeyDashboard from "./webkey/WebkeyDashboard";
import WebkeyAppManagement from "./webkey/WebkeyAppManagement";
import WebkeyTasks from "./webkey/WebkeyTasks";
import UploadMusic from "./uploadMusic/UploadMusic";
import "./global.css";
import SearchReports from "./search_reports/SearchReports";
import QsysDevices from "./qsys_devices";

export const user = jwt.decode(localStorage.getItem("authentication_token"));
const routeConfig = makeRouteConfig(
  <Route>
    <Route path="/login" Component={LoginForm} />
    <Route path="/register" Component={Register} />
    <Route path="/whatismypass" Component={ForgotPass} />
    <Route path="/resetpassword*" Component={ResetPass} />
    <Route path="/verify/*" Component={VerifyEmail} />
    <Route path="/" Component={AppPage}>
      <Redirect from="/" to="/home/audio_devices" />
      <Redirect from="/home/dashboard" to="/home/audio_devices" />
      <Route path="/home" Component={Drawer} userRoot={user}>
        <Route
          path="/dashboard"
          Component={Dashboard}
          name="Dashboard"
          userRoot={user}
        />
        <Route
          path="/control_dashboard"
          Component={WebkeyDashboard}
          name="Activaire Control Dashboard"
          userRoot={user}
          instance="primary"
        />
        <Route
          path="/recovery_dashboard"
          Component={WebkeyDashboard}
          name="Activaire Recovery Dashboard"
          userRoot={user}
          instance="backup"
        />
        <Route
          path="/control_apps"
          Component={WebkeyAppManagement}
          name="Activaire Control App Management"
          userRoot={user}
        />
        <Route
          path="/control_tasks"
          Component={WebkeyTasks}
          name="Activaire Control Tasks"
          userRoot={user}
        />
        <Route path="/users" Component={Users} name="Users" userRoot={user} />
        <Route
          path="/events"
          Component={Events}
          name="Events"
          userRoot={user}
        />
        <Route
          path="/audio_devices_registration"
          Component={AudioDeviceRegistration}
          name="Audio Device Registration"
          userRoot={user}
        />
        <Route
          path="/audio_devices"
          Component={AudioDevices}
          name="Audio Devices"
          userRoot={user}
        />
        <Route
          path="/qsys_devices"
          Component={QsysDevices}
          name="Qsys Devices"
          userRoot={user}
        />
        <Route
          path="/playbacks"
          Component={PlaybackTabsQuery}
          name="Playbacks"
          userRoot={user}
        />
        <Route
          path="/audio_reports"
          Component={AudioReports}
          name="Audio Reports"
          userRoot={user}
        />
        <Route
          path="/search_reports"
          Component={SearchReports}
          name="Search Reports"
          userRoot={user}
        />
        <Route
          path="/song_reporting"
          Component={SongReporting}
          name="Song Reporting"
          userRoot={user}
        />
        <Route
          path="/video_devices"
          Component={VideoDevices}
          name="Video Devices"
          userRoot={user}
        />
        <Route
          path="/blacklist_video_devices"
          Component={VideoDevicesInactive}
          name="Blacklisted Video Devices"
          userRoot={user}
        />
        <Route
          path="/video_profiles"
          Component={VideoProfiles}
          name="Video Profiles"
          userRoot={user}
        />
        <Route path="/tags" Component={Tags} name="Tags" userRoot={user} />
        <Route
          path="/video_campaigns"
          Component={VideoCampaigns}
          name="Video Campaigns"
          userRoot={user}
        />
        <Route
          path="/permissions"
          Component={Permissions}
          name="Permissions"
          userRoot={user}
        />
        <Route
          path="/sonos"
          Component={Sonos}
          name="Sonos Menu"
          userRoot={user}
        />
        <Route path="/qsys" Component={Qsys} name="Qsys Menu" userRoot={user} />
        <Route
          path="/devicelogs"
          Component={DeviceLogs}
          name="Device Logs"
          userRoot={user}
        />
        <Route
          path="/upload-music"
          Component={UploadMusic}
          name="Upload Music"
          userRoot={user}
        />
      </Route>
    </Route>
    <Route path="*" Component={LoginForm} />
  </Route>
);

export const BrowserRouter = createBrowserRouter({
  routeConfig,

  renderError: ({ error }) => (
    <div>{error.status === 404 ? "Not found" : "Error"}</div>
  ),
});
