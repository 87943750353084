/**
 * @flow
 * @relayHash ff8dbcb195b1d4468ec5eaab6126fd7b
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type forgotPasswordMutationVariables = {|
  inputemail: string
|};
export type forgotPasswordMutationResponse = {|
  +forgotPassword: ?{|
    +id: string
  |}
|};
export type forgotPasswordMutation = {|
  variables: forgotPasswordMutationVariables,
  response: forgotPasswordMutationResponse,
|};
*/


/*
mutation forgotPasswordMutation(
  $inputemail: String!
) {
  forgotPassword(email: $inputemail) {
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "inputemail"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "email",
        "variableName": "inputemail"
      }
    ],
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "forgotPassword",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "forgotPasswordMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "forgotPasswordMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "ff8dbcb195b1d4468ec5eaab6126fd7b",
    "metadata": {},
    "name": "forgotPasswordMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '92f2383f0e1be7af9973262bbbb7725a';

module.exports = node;
