import { commitMutation } from "react-relay";
import graphql from 'babel-plugin-relay/macro';
import Environment from "../../environment";

const mutation = graphql`
  mutation requestLogsMutation($inputid: String!, $inputType: String!) {
    requestLogs(id: $inputid, type: $inputType) {
      id
      requestPCLogs
      requestNPLogs
    }
  }
`;

export default (id, logType, callback) => {
  const variables = {
    inputid: id,
    inputType: logType,
    clientMutationId: "",
  };

  commitMutation(Environment, {
    mutation,
    variables,
    onCompleted: (response) => {
      const returnedId = response.requestLogs.id;
      callback(returnedId);
    },
    onError: (err) => console.error(err), // eslint-disable-line
  });
};
