import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import deleteSmartPlaylistMutation from "../mutations/deleteSmartPlaylistMutation";

const styles = (theme) => ({
  dialog: {
    background: "secondary",
    margin: theme.spacing(3),
  },
  button: {
    marginRight: theme.spacing(1),
  },
  closeIcon: {
    height: 28,
    width: 28,
  },
  close: {
    position: "relative",
    float: "right",
  },
  dialogTitle: {
    padding: "0px 24px 24px 24px",
    minWidth: 200,
  },
  formControl: {
    margin: theme.spacing(3),
    minWidth: 150,
  },
  typo: {
    marginBottom: 8,
    padding: "0px 24px 0px 24px",
  },
});

class PlaybackDelete extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSnackbar: false,
      message: null,
    };
    this.handleRequestClose = this.handleRequestClose.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  handleRequestClose(data) {
    this.props.handleRequestClose(data);
  }

  handleDelete() {
    deleteSmartPlaylistMutation(this.props.id);
    this.handleRequestClose("Playlist deleted");
  }

  render() {
    const { classes } = this.props;
    return (
      <Dialog open={this.props.open} className={classes.dialog}>
        <div className={classes.close}>
          <IconButton aria-label="Close" className={classes.close}>
            <CloseIcon
              className={classes.closeIcon}
              onClick={() => this.handleRequestClose("Action canceled")}
            />
          </IconButton>
        </div>
        <DialogTitle className={classes.dialogTitle}>Are you sure?</DialogTitle>
        <div className={classes.dialog}>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => this.handleRequestClose("Action canceled")}
            className={classes.button}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={this.handleDelete}
            className={classes.button}
          >
            Delete
          </Button>
        </div>
      </Dialog>
    );
  }
}

PlaybackDelete.propTypes = {
  classes: PropTypes.object.isRequired, //eslint-disable-line
  id: PropTypes.string.isRequired,
  handleRequestClose: PropTypes.func, //eslint-disable-line
  open: PropTypes.bool, //eslint-disable-line
  data: PropTypes.object, //eslint-disable-line
  myCallBack: PropTypes.func, //eslint-disable-line
};

export default withStyles(styles)(PlaybackDelete);
