import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import React, { useContext, useEffect } from "react";
import { CurrentClientContext } from "../hooks/currentClientProvider";
import AudioClients from "../../audio_devices/AudioClients";

export default function ClientModal({
  allClients,
  match,
  open,
  onClose,
  viewer,
  classes,
}) {
  const { currentClient, setCurrentClient } = useContext(CurrentClientContext);

  useEffect(() => {
    if (allClients.length > 0) {
      const clientId = localStorage.getItem("audioClientId");
      if (clientId === "ALL") {
        setCurrentClient("ALL");
      }
      if (clientId !== "ALL") {
        const client = allClients.find((item) => item.id === clientId);
        setCurrentClient(client);
      }
    }
  }, [allClients]);

  const handleChange = (event) => {
    const clientId = event?.target?.textContent || event;
    localStorage.setItem("audioClientId", clientId);
    clientId === "ALL"
      ? setCurrentClient("ALL")
      : setCurrentClient(allClients.find((item) => item.id === clientId));
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginLeft: "12px",
          paddingTop: "12px",
          justifyContent: "center",
        }}
      >
        <h2>
          <span>{match.route.name}</span>
          <span style={{ fontSize: "18px", color: "grey" }}>
            &nbsp; @ {currentClient === "ALL" ? "ALL" : currentClient?.name}
          </span>
        </h2>
      </div>
      <Drawer open={open} onClose={onClose}>
        <div tabIndex={0} role="button" onClick={onClose} onKeyDown={onClose}>
          <div className={classes.list}>
            <List dense>
              <ListItem button>
                <ListItemText primary="ALL" onClick={handleChange} />
              </ListItem>
              <AudioClients viewer={viewer} myCallBack={handleChange} />
            </List>
          </div>
        </div>
      </Drawer>
    </>
  );
}
